import styled from '@emotion/styled'
import { Box } from '@mui/material'

export const SearchBarWrapper = styled(Box)<{ val?: string }>`
  display: flex;
  justify-content: center;
  cursor: text;

  .search-icon {
    position: absolute;
    left: 10px;
    top: 9px;
    z-index: 1;
    transition: width 0.3s ease-in-out;

    svg {
      transition: width 0.3s ease-in-out;

      path {
        fill: #998d96;
      }
    }
  }

  .clear-btn {
    position: absolute;
    right: 0;
    top: 2px;
    transition: width 0.3s ease-in-out;

    svg {
      transition: width 0.3s ease-in-out;

      path {
        fill: #fff;
      }
    }
  }

  .search-bar {
    width: 100%;
    border-radius: 28px;
    height: 40px;
  }

  .MuiOutlinedInput-input {
    border-radius: 4px;
    border: 1px solid #ffa9fc;
    background: #372a36;
    height: 24px;
    padding: ${({ val }) => (typeof val !== 'undefined' && val.length > 0 ? '8px 40px 8px 8px' : '8px 40px 8px 40px')};
    color: #fff;
    font-family: 'Quicksand', sans-serif;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    transition: all 0.3s ease-in-out;

    &::placeholder {
      color: #867083;
      opacity: 1;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }

    &:focus {
      border-color: #ffa9fc;
    }
  }

  fieldset {
    border: none;
  }
`
