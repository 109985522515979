import { Dispatch, FC, SetStateAction } from 'react'
import { Box, Grid, Typography } from '@mui/material'
import dayjs from 'dayjs'

/* Utils */
import { CPISeverity, Insight } from '../../../../../models'

/* Components */
import { HeaderBackground, HistorySectionContent } from '../main-section/mainSection.styles'
import { HistorySectionButton } from './historySection.styles'
import { RightIconButton } from '../../../../components/svg'
import CpiDetails from '../main-section/components/CpiDetails'

interface Props {
  results: Insight[]
  getHeaderBackground: (text: string) => string
  selectedInsight: Insight | null
  setSelectedInsight: Dispatch<SetStateAction<Insight | null>>
  toLink: (res: Insight) => void
  search: string
}

const HistorySection: FC<Props> = ({
  results,
  getHeaderBackground,
  selectedInsight,
  setSelectedInsight,
  toLink,
  search
}) => {
  const highlightMatch = (text: string) => {
    if (!search) return text

    const regex = new RegExp(`(${search})`, 'gi')
    return text.split(regex).map((part, index) =>
      regex.test(part) ? (
        <span key={index} style={{ color: '#FFA9FC', fontWeight: '700' }}>
          {part}
        </span>
      ) : (
        part
      )
    )
  }

  if (!results.length) return null
  return (
    <>
      {results.map((result) => (
        <Grid item xs={12} key={result.id}>
          <HistorySectionContent
            sx={{
              position: 'relative',
              minHeight: '162px',
              height: '100%',
              border: `1px solid ${selectedInsight && selectedInsight.id === result.id ? '#DEBDD9' : 'transparent'}`,
              background: `${
                selectedInsight && selectedInsight.id === result.id
                  ? '#483646 !important'
                  : 'linear-gradient(0deg, rgba(255, 169, 252, 0.05) 0%, rgba(255, 169, 252, 0.05) 100%), #1e1a1d !important'
              }`
            }}
            bg={getHeaderBackground(result.sub_type)}
            onClick={() => setSelectedInsight(result)}
          >
            <Box className="main-section-header">
              <HeaderBackground bg={getHeaderBackground(result.sub_type)} />
              <img src={result.data?.card_icon || ''} alt="" />
              <Typography className="header-text">{result.data?.header || ''}</Typography>
              <Typography className="header-date">
                {dayjs(result.data?.insight_date || undefined).format('MMM D, YYYY hh:mm A')}
              </Typography>
            </Box>
            <Box className="content-inner">
              <Typography className="performance-title">{highlightMatch(result.data?.title || '')}</Typography>
              <CpiDetails
                displayName={result.data?.cpi_display_name || ''}
                severity={result.data?.section || CPISeverity.All}
                instances={result.data?.instances || []}
              />
              <Typography className="performance-description">
                {highlightMatch(result.data?.description || '')}
              </Typography>
              <HistorySectionButton onClick={() => toLink(result)}>
                <RightIconButton />
              </HistorySectionButton>
            </Box>
          </HistorySectionContent>
        </Grid>
      ))}
    </>
  )
}

export default HistorySection
