import React, { FC, useState } from 'react'
import { ActionWrapper, DeselectNotificationWrapper } from './deselectNotification.styles'
import { CircularProgress, Typography } from '@mui/material'

/* Utils */
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks'
import { closeModal, modalPropsSelector } from '../../../../../store/modals/modalSlice'
import { ThemeButton } from '../../../buttons'
import { openToast, ToastVariant } from '../../../../../store/toasts/toastSlice'

const DeselectNotification: FC = () => {
  const dispatch = useAppDispatch()
  const modalProps = useAppSelector(modalPropsSelector)

  const [loading, setLoading] = useState(false)

  const handleClose = () => {
    dispatch(closeModal())
  }

  const saveSelection = () => {
    try {
      setLoading(true)
      if (modalProps?.props?.callback) {
        modalProps.props.callback()
      }
    } catch (err) {
      dispatch(
        openToast({
          variant: ToastVariant.error,
          props: {
            text: 'Failed deleting user',
            description:
              (err as any).response?.data?.message && (err as any).response?.data?.message.includes('not authorized')
                ? 'Only Account Owners can delete users.'
                : (err as any).response?.data?.message || 'There was a problem while deleting user account.'
          }
        })
      )
    } finally {
      setLoading(false)
      handleClose()
    }
  }

  return (
    <DeselectNotificationWrapper>
      <Typography className="title">
        Deselecting “{modalProps?.props?.integrationName || modalProps?.props?.integrationInstance?.integration_name}”{' '}
      </Typography>

      <Typography className="description">
        Deselecting “{modalProps?.props?.integrationName || modalProps?.props?.integrationInstance?.integration_name}”
        will permanently delete the preserved instance data. You won’t be able to view or access this data anymore. Are
        you sure you want to move forward?{' '}
      </Typography>

      <ActionWrapper>
        <ThemeButton themevariant="tertiary" onClick={handleClose}>
          Go Back
        </ThemeButton>
        <ThemeButton
          themevariant="primary"
          endIcon={loading && <CircularProgress size="20px" />}
          onClick={saveSelection}
        >
          Save Selection
        </ThemeButton>
      </ActionWrapper>
    </DeselectNotificationWrapper>
  )
}

export default DeselectNotification