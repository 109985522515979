import { FormControl, Select } from '@mui/material'
import styled from '@emotion/styled/macro'

const ITEM_HEIGHT = 32
const ITEM_PADDING_TOP = 8

export const CategoryMenuProps = {
  PaperProps: {
    sx: {
      background: 'linear-gradient(0deg, rgba(255, 169, 252, 0.14), rgba(255, 169, 252, 0.14)), #1E1A1D',
      boxShadow: '0 8px 12px 6px rgba(0, 0, 0, 0.15), 0 4px 4px rgba(0, 0, 0, 0.3)',
      borderRadius: '4px',
      minWidth: '130px',

      '.MuiMenuItem-root': {
        height: '32px',
        paddingLeft: '16px',
        paddingRight: '16px',

        img: {
          marginRight: '8px'
        },

        '&:hover': {
          background: 'rgba(208, 188, 255, 0.16) !important'
        },

        '.MuiCheckbox-root': {
          paddingRight: '19px',
          paddingLeft: 0,

          svg: {
            path: {
              fill: '#d0c3cc'
            }
          }
        },

        '.MuiListItemText-root .MuiTypography-root': {
          fontFamily: '"Quicksand", sans-serif',
          fontWeight: 400,
          fontSize: '14px',
          lineHeight: '20px',
          letterSpacing: '0.25px',
          color: '#d0c3cc'
        },
        display: 'flex',
        alignItems: 'center',
        width: '100%',

        '.image-wrapper': {
          display: 'flex',

          '> img': {
            width: '18px',
            height: '18px',
            boxShadow: '0 4px 4px 0 rgba(0, 0, 0, 0.3), 0 8px 12px 6px rgba(0, 0, 0, 0.15)',
            margin: '-4.5px',
            borderRadius: '50%',

            '&:nth-of-type(1)': {
              zIndex: 10
            },
            '&:nth-of-type(2)': {
              zIndex: 9
            },
            '&:nth-of-type(3)': {
              zIndex: 8
            },
            '&:nth-of-type(4)': {
              zIndex: 7
            },
            '&:nth-of-type(5)': {
              zIndex: 6
            },
            '&:nth-of-type(6)': {
              zIndex: 5
            },
            '&:nth-of-type(7)': {
              zIndex: 4
            }
          }
        },

        '.textWrapper': {
          marginLeft: '13px'
        }
      },

      '&::-webkit-scrollbar': {
        display: 'none'
      }
    },
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP
    }
  },
  anchorOrigin: {
    vertical: 'bottom' as const,
    horizontal: 'left' as const
  },
  transformOrigin: {
    vertical: 'top' as const,
    horizontal: 'left' as const
  },
  variant: 'menu' as const,
  getContentAnchorEl: null
}

export const BenchmarkingSelect = styled(Select)<{ isselected?: string; noborder?: string }>`
  min-width: 113px;
  height: 32px;
  gap: 8px;

  color: #e9e0e4;
  font-family: Quicksand, sans-serif;
  font-size: 11px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.5px;

  border-radius: 8px;
  border: 1px solid #ffa9fc;
  background: #131013;

  ${({ isselected }) =>
    isselected === 'true' &&
    `
    background: rgba(208, 188, 255, 0.16);
  `};

  > * {
    border: none !important;
    outline: none !important;
  }

  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border: none !important;
    background: #131013;
  }

  &.Mui-disabled {
    .MuiSelect-outlined {
      -webkit-text-fill-color: rgba(255, 255, 255, 0.6) !important;
    }
  }

  svg {
    path {
      fill: #d0c3cc;
    }
  }
`

export const BenchmarkingFormControl = styled(FormControl)`
  border-radius: 8px;
  border: none;
  outline: none;
  min-width: 113px;
  height: 32px;
  margin-bottom: 24px;

  .MuiSelect-icon {
    top: calc(50% - 0.7em);
  }
`
