import { Box } from '@mui/material'
import styled from '@emotion/styled/macro'

export const Wrapper = styled(Box)<{ isReverse: boolean }>`
  border-radius: 2px;
  border: 1px solid #7c7579;
  background: #1e1a1d;
  height: 32px;
  width: 100%;
  box-sizing: content-box;
  position: relative;
  margin-bottom: 32px;

  .bar-block {
    height: 32px;
    background: ${({ isReverse }) =>
      isReverse
        ? 'linear-gradient(270deg, rgb(120, 26, 126) 0%, rgb(130, 85, 185) 100%);'
        : 'linear-gradient(90deg, #781A7E 0.01%, #8E9AFF 100%)'};
  }
`

export const IndustryAverageMarker = styled(Box)<{ left: number }>`
  border: 1px solid #fff;
  background: linear-gradient(180deg, #fff 0%, rgba(255, 255, 255, 0.4) 100%);
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.3), 0 2px 6px 2px rgba(0, 0, 0, 0.15);
  position: absolute;
  left: ${({ left }) => `${left}%`};
  top: 0;
  width: 8px;
  height: 32px;
  box-sizing: border-box;
  z-index: 11;

  &:hover {
    cursor: pointer;
  }
`

export const SLAMarker = styled(Box)<{ left: number }>`
  background: linear-gradient(180deg, #f09543 0%, rgba(240, 149, 67, 0.4) 100%);
  stroke-width: 2px;
  stroke: #f09543;
  width: 32px;
  height: 32px;
  position: absolute;
  left: ${({ left }) => `calc(${left}% - 16px)`};
  top: 0;
  z-index: 11;
  box-sizing: border-box;
  border: 2px solid #f09543;
  outline: none;
  filter: drop-shadow(0px 2px 3px rgba(0, 0, 0, 0.3)) drop-shadow(0px 6px 10px rgba(0, 0, 0, 0.15));
  border-radius: 50%;

  &:hover {
    stroke: #ffd2aa;
    border: 2px solid #ffd2aa;
    cursor: pointer;
  }
`

export const LowRange = styled(Box)<{ left?: number; right?: number }>`
  height: 32px;
  position: absolute;
  top: 0;
  z-index: 10;
  box-sizing: border-box;

  ${({ left }) =>
    typeof left !== 'undefined' &&
    `
      
      left: ${left}%;
      
      &:hover {
        border: 1px solid #c7ccff;
        background: linear-gradient(180deg, #8e9aff 0%, rgba(142, 154, 255, 0.4) 100%);
      }
  `};

  ${({ right }) =>
    typeof right !== 'undefined' &&
    `
    
      right: ${right}%;
      
      &:hover {
        border: 1px solid #ffa9fc;
        background: linear-gradient(180deg, #781a7e 0%, rgba(120, 26, 126, 0.4) 100%);
      }
  `};

  &:hover {
    box-shadow: 0 0 12px 0 rgba(255, 255, 255, 0.3);
    cursor: pointer;
  }
`

export const HighRange = styled(Box)<{ left?: number; right?: number }>`
  height: 32px;
  position: absolute;
  top: 0;
  z-index: 10;
  box-sizing: border-box;

  ${({ left }) =>
    typeof left !== 'undefined' &&
    `
     
      left: ${left}%;
      
      &:hover {
        border: 1px solid #ffa9fc;
        background: linear-gradient(180deg, #781a7e 0%, rgba(120, 26, 126, 0.4) 100%);
      }
  `};

  ${({ right }) =>
    typeof right !== 'undefined' &&
    `
    
      right: ${right}%;
      
      &:hover {
        border: 1px solid #c7ccff;
        background: linear-gradient(180deg, #8e9aff 0%, rgba(142, 154, 255, 0.4) 100%);
      }
  `};

  &:hover {
    box-shadow: 0 0 12px 0 rgba(255, 255, 255, 0.3);
    cursor: pointer;
  }
`

export const MarkContainer = styled(Box)`
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
`

export const Mark = styled(Box)<{ left: number }>`
  position: absolute;
  left: ${({ left }) => `${Number(left) > 50 ? `calc(${left}% - 16px)` : `${left}%`}`};
  top: 6px;

  color: #d0c3cc;
  font-family: Quicksand, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.5px;
`
