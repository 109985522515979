import { ToggleButton } from '@mui/material'
import styled from '@emotion/styled/macro'

export const StyledToggleButton = styled(ToggleButton)`
  border-radius: 100px;
  border: 1px solid #998d96;

  &:hover {
    background: #544152;
    color: #ffa9fc;
    border: 1px solid #998d96;
    box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.15), 0 1px 2px 0 rgba(0, 0, 0, 0.3);
  }

  &.Mui-selected {
    background: #ffa9fc;
    border: 1px solid #998d96;
    color: #590060;

    &:hover {
      background: #ec85ec;
      color: #590060;
    }
  }

  &:focus {
    border: 1px solid #fff;
  }

  &.Mui-disabled {
    color: #7f747c;
    background: #483646;
  }
`
