import { FC } from 'react'
import { Backdrop, CircularProgress } from '@mui/material'

interface Props {
  open: boolean
  handleClose: () => void
}

const CoverageLoading: FC<Props> = ({ open, handleClose }) => (
  <Backdrop
    sx={{
      color: '#fff',
      zIndex: 10000000 + 1,
      width: 'calc(100% - 64px)',
      marginLeft: '64px',

      '.MuiCircularProgress-root': {
        boxShadow: `inset 0 0 0 ${(12 / 44) * 12}px #1E1A1D`,
        borderRadius: '50%',

        svg: {
          circle: {
            stroke: '#FFA9FC'
          }
        }
      }
    }}
    open={open}
    onClick={handleClose}
  >
    <CircularProgress color="inherit" />
  </Backdrop>
)

export default CoverageLoading
