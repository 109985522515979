import { TextField as MuiTextField } from '@mui/material'
import styled from '@emotion/styled/macro'

export const TextField = styled(MuiTextField)`
  .MuiFormHelperText-root {
    font-family: 'Quicksand', sans-serif;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.4px;
    color: #ffb4ab;
  }

  .Mui-focused {
    fieldset {
      border-color: #ffa9fc !important;
    }
  }

  .MuiInputLabel-root.Mui-focused {
    color: #ffa9fc !important;
  }

  .MuiInputAdornment-root {
    .MuiTypography-root {
      font-family: 'Quicksand', sans-serif;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.25px;
      color: #ffffff;
    }
  }

  .MuiInputBase-input {
    color: #e9e0e4;
  }
`
