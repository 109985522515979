import { Box } from '@mui/material'
import styled from '@emotion/styled/macro'

export const PizzaChartWrapper = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  max-height: 203px;

  img {
    position: absolute;
    width: 434px;
    height: 418px;
    top: 147px;
    border-radius: 50%;
  }

  svg#chart {
    margin-bottom: 17px;
  }
`

export const SetupWrapper = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column wrap;
  position: absolute;
  z-index: 1;

  .setup-title {
    color: #fff;
    text-align: center;
    font-family: Quicksand, sans-serif;
    font-size: 45px;
    font-weight: 400;
    line-height: 52px;
    max-width: 334px;
    margin-bottom: 21px;
  }
`
