export const openInNewTab = (url: string) => {
  window.open(url, '_blank', 'noreferrer')
}

export const quartersOptions = ['Q1 2023', 'Q2 2023', 'Q3 2023', 'Q4 2023']

export const subtractMonths = (date: Date, months: number) => {
  const d = new Date(date)
  d.setMonth(d.getMonth() - months)

  return d
}
