const AuditLogoutIcon = () => (
  <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.75 6.5L11.6925 7.5575L12.8775 8.75H6.75V10.25H12.8775L11.6925 11.435L12.75 12.5L15.75 9.5L12.75 6.5ZM3.75 4.25H9V2.75H3.75C2.925 2.75 2.25 3.425 2.25 4.25V14.75C2.25 15.575 2.925 16.25 3.75 16.25H9V14.75H3.75V4.25Z"
      fill="#FFA9FC"
    />
  </svg>
)

export default AuditLogoutIcon
