import React from 'react'
import { Box, Typography } from '@mui/material'

/* Utils */
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks'
import { closeModal, modalPropsSelector } from '../../../../../store/modals/modalSlice'

/* Components */
import { Actions, Container, Content } from './UpdateWeights.styles'
import { ThemeButton } from '../../../buttons'
import LockIcon from '../../../svg/LockIcon'

const UpdateWeights = () => {
  const dispatch = useAppDispatch()
  const modalProps = useAppSelector(modalPropsSelector)
  const form = modalProps?.props?.weightsData

  const handleClose = () => {
    dispatch(closeModal())
  }

  const cancelChanges = () => {
    if (modalProps?.props?.callback) {
      handleClose()
      modalProps.props.callback()
    }
  }

  if (!form) return <Box />
  return (
    <Container>
      <Content>
        <Typography className="title">Are You Sure?</Typography>
        <Typography className="subtitle">Please confirm new values for the following items:</Typography>

        {Object.keys(form.framework).length ? (
          <Box className="framework">
            <img src={form.framework.icon} alt={form.framework.label} width={24} height={24} />
            <Typography className="text">{form.framework.label}</Typography>
          </Box>
        ) : (
          ''
        )}

        {form.categories.length ? (
          <>
            <Box className="header">
              <Typography className="name">Category</Typography>
              <Typography className="value">Weights</Typography>
            </Box>
            <Box className="content-category">
              {form.categories.map((category) => {
                return (
                  <Box className="content-item" key={category.id}>
                    <Typography className="name">{category.name}</Typography>
                    <Typography className="value">{category.weight}%</Typography>
                  </Box>
                )
              })}
            </Box>
          </>
        ) : (
          ''
        )}

        {form.categoryCpis.length ? (
          <>
            <Box className="header">
              <Typography className="name">Cyber Performance Indicator</Typography>
              <Typography className="value">Weights</Typography>
            </Box>
            <Box className="content-cpi">
              {form.categoryCpis.map((cpi: any) => {
                return (
                  <Box className="content-item">
                    <Box className="cpi-block">
                      <Typography className="cpi">{cpi?.displayName}</Typography>
                      <Typography className="name">{cpi?.title}</Typography>
                    </Box>
                    <Typography className="value">{cpi?.weight}%</Typography>
                  </Box>
                )
              })}
            </Box>
          </>
        ) : (
          ''
        )}

        <Actions>
          <ThemeButton themevariant="tertiary" onClick={handleClose}>
            Cancel
          </ThemeButton>
          <ThemeButton themevariant="primary" type="button" onClick={() => cancelChanges()}>
            <>
              <LockIcon />
              Confirm and Save
            </>
          </ThemeButton>
        </Actions>
      </Content>
    </Container>
  )
}

export default UpdateWeights
