import React from 'react'

const ColumnActiveIcon = () => (
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="40" height="40" rx="20" fill="#590060" />
    <path d="M11 13V27H29V13H11ZM16.33 25H13V15H16.33V25ZM21.67 25H18.34V15H21.67V25ZM27 25H23.67V15H27V25Z"
          fill="white" />
  </svg>
)

export default ColumnActiveIcon
