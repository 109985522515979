const AuditLoginIcon = () => (
  <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.25 5.75L7.2 6.8L9.15 8.75H1.5V10.25H9.15L7.2 12.2L8.25 13.25L12 9.5L8.25 5.75ZM15 14.75H9V16.25H15C15.825 16.25 16.5 15.575 16.5 14.75V4.25C16.5 3.425 15.825 2.75 15 2.75H9V4.25H15V14.75Z"
      fill="#FFA9FC"
    />
  </svg>
)

export default AuditLoginIcon
