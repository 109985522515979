import { Box } from '@mui/material'
import styled from '@emotion/styled/macro'

export const Container = styled(Box)`
  width: 100%;
  height: 100%;
`

export const Content = styled(Box)`
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 27px;
  padding: 36px;

  @media (min-width: 1440px) {
    padding-top: 20px;
    gap: 21px;
  }

  @media (min-width: 1920px) {
    padding-top: 20px;
    gap: 47px;
  }

  .history-content {
    min-height: 645px;
    overflow-y: scroll;

    ::-webkit-scrollbar {
      width: 4px;
      background: transparent;
    }

    ::-webkit-scrollbar-track {
      box-shadow: none;
      background: transparent;
    }

    ::-webkit-scrollbar-thumb {
      background-color: #fff;
      border-radius: 8px;
    }
  }
`

export const NoResultsWrapper = styled(Box)`
  position: relative;
  width: 100%;
  height: 100%;
  padding: 32px 0 0 36px;

  .no-results-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 24px;
    z-index: 2;
    position: relative;

    .no-results-text {
      color: #fff;
      width: 514px;
      font-family: Quicksand, sans-serif;
      font-size: 45px;
      font-style: normal;
      font-weight: 400;
      line-height: 52px;
    }
  }

  .svg-wrapper {
    position: absolute;
    right: 0;
    bottom: 0px;
    z-index: 1;
  }
`

export const dateRangePickerPopperSx = {
  '.MuiPaper-root': {
    position: 'absolute',

    display: 'flex',
    background: 'transparent',

    '.MuiPickersToolbar-root': {
      display: 'none'
    },
    '.MuiPickersLayout-root': {
      borderRadius: '8px',
      background: '#573f56',
      display: 'flex',
      flexDirection: 'column',

      '.MuiPickersCalendarHeader-root': {
        '.MuiPickersCalendarHeader-labelContainer': {
          '.MuiPickersCalendarHeader-label': {
            color: '#d0c3cc',
            textAlign: 'center',
            fontFamily: "'Quicksand', sans-serif",
            fontSize: '14px',
            fontStyle: 'normal',
            fontWeight: 500,
            lineHeight: '20px',
            letterSpacing: '0.1px'
          }
        },

        '.MuiPickersArrowSwitcher-root': {
          '.MuiButtonBase-root': {
            color: '#d0c3cc',

            '&.Mui-disabled': {
              opacity: 0.26
            }
          }
        }
      },

      '.MuiDayCalendar-root': {
        '.MuiDayCalendar-header': {
          '.MuiTypography-root': {
            color: '#e9e0e4',
            textAlign: 'center',
            fontFamily: "'Quicksand', sans-serif",
            fontSize: '12px',
            fontStyle: 'normal',
            fontWeight: 500,
            lineHeight: '16px',
            letterSpacing: '0.5px'
          }
        },

        '.MuiDayCalendar-monthContainer': {
          '.MuiDayCalendar-weekContainer': {
            '.MuiDateRangePickerDay-hiddenDayFiller': {
              background: 'transparent !important'
            }
          },

          '.MuiDateRangePickerDay-root': {
            '.MuiButtonBase-root': {
              color: '#e9e0e4',
              textAlign: 'center',
              fontFamily: "'Quicksand', sans-serif",
              fontSize: '14px',
              fontStyle: 'normal',
              fontWeight: 400,
              lineHeight: '20px',
              letterSpacing: '0.25px',

              '&.Mui-selected': {
                borderRadius: '50%',
                color: '#590060',
                background: '#ffa9fc'
              },

              '&.Mui-disabled': {
                color: '#d0c3cc',
                opacity: 0.38
              }
            },

            '&.MuiDateRangePickerDay-root.MuiDateRangePickerDay-rangeIntervalDayHighlight': {
              background: 'rgba(106, 83, 113, 1)'
            }
          }
        }
      },

      '.MuiDialogActions-root': {
        '.MuiButton-root': {
          color: '#ffa9fc',
          textAlign: 'center',
          fontFamily: "'Quicksand', sans-serif",
          fontSize: '14px',
          fontStyle: 'normal',
          fontWeight: 500,
          lineHeight: '20px',
          letterSpacing: '0.1px',

          '&:first-of-type': {
            textTransform: 'capitalize'
          }
        }
      }
    }
  }
}
