import React, { FC } from 'react'
import { EmptyStateContainer } from './EmptyState.style'
import { ThemeButton } from '../../../../components/buttons'
import EmptyStateLogo from '../../../../components/svg/EmptyStateLogo'
import { Box } from '@mui/material'

interface Props {
  clear: () => void
}

const EmptyState: FC<Props> = ({ clear }) => {
  return (
    <EmptyStateContainer>
      <h2>Sorry, we couldn’t find anything.</h2>

      <ThemeButton themevariant="primary" type="button" onClick={clear}>
        Try a new search
      </ThemeButton>

      <Box className="image-wrapper">
        <EmptyStateLogo />
      </Box>
    </EmptyStateContainer>
  )
}

export default EmptyState
