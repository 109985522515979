import styled from '@emotion/styled'
import { Box } from '@mui/material'

export const Wrapper = styled(Box)`
  border: 1px solid #4d444b;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 64px 24px;

  @media (min-height: 801px) {
    min-height: 544px;
  }

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .title {
      margin-top: 32px;
      color: #fff;
      font-family: 'Quicksand', sans-serif;
      font-size: 24px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      margin-bottom: 8px;
    }

    .description {
      color: #fff;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;

      .colored {
        color: #ffa9fc;
        display: inline-block;
        margin-left: 3px;
        margin-right: 3px;
      }
    }
  }
`
