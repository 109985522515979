import { Box, FormControl, Select } from '@mui/material'
import styled from '@emotion/styled/macro'

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 0

export const Wrapper = styled(Box)`
  display: flex;
  align-items: center;
  gap: 8px;
`

export const AuditLogFormControl = styled(FormControl)<{ isselected?: string }>`
  border: 1px solid #998d96;
  background: transparent;
  outline: none;
  min-width: 120px;
  height: 40px;
  border-radius: 100px;

  .label-text {
    font-family: Quicksand, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 142.857% */
    letter-spacing: 0.1px;
    color: white;
  }

  .MuiOutlinedInput-root {
    background: transparent;
    border: 0;
    color: #d0c3cc;
    text-align: center;
    font-family: Quicksand, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.1px;
  }

  &:hover {
    border-radius: 100px;
    background: rgba(234, 221, 255, 0.08);

    .MuiOutlinedInput-root {
      color: #ffa9fc;
    }
  }

  svg {
    top: calc(50% - 0.6em);
    right: 16px;

    path {
      fill: #ffa9fc;
    }
  }

  .MuiSelect-outlined {
    width: auto;
  }

  fieldset {
    padding: 0;
  }
`

export const AuditLogSingleFormControl = styled(FormControl)<{ isselected: string; isopen: string }>`
  background: transparent;
  outline: none;
  border-radius: 100px;
  border: 1px solid #998d96;

  &:hover {
    cursor: default !important;
  }

  .MuiOutlinedInput-root {
    background: transparent;
    border: 0;
    min-width: unset;
    padding: 10px 16px;

    &:hover {
      cursor: default !important;
      border-radius: 100px;
      background: rgba(234, 221, 255, 0.08);

      .MuiSelect-select {
        cursor: default !important;
      }

      .MuiListItemText-root {
        cursor: default !important;

        .MuiTypography-root {
          cursor: default;
          color: #ffa8fc;
        }
      }
    }

    .MuiSelect-select.MuiSelect-outlined {
      padding-left: 0;
      padding-right: 16px;
    }

    .MuiListItemText-root {
      .MuiTypography-root {
        color: #fff;
        text-align: center;
        font-family: Quicksand, sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0.1px;
      }
    }

    ${({ isopen }) =>
      isopen === 'true' &&
      `
        cursor: default !important;
        border-radius: 100px;
        background: rgba(234, 221, 255, 0.08);
  
        .MuiSelect-select {
          cursor: default !important;
        }

        .MuiListItemText-root {
          cursor: default !important;
  
          .MuiTypography-root {
            cursor: default;
            color: #ffa8fc;
          }
        }
    `};
  }

  svg {
    top: calc(50% - 0.6em);
    right: 10px;

    path {
      fill: #ffa9fc;
    }
  }

  ${({ isselected }) =>
    isselected === 'true' &&
    `
      border-radius: 100px;
      border: 1px solid #FFA9FC;
      background: rgba(208, 188, 255, 0.16);
      
      .MuiOutlinedInput-root { 
        .MuiListItemText-root {
          .MuiTypography-root {
            color: #FFA9FC;
          }
        }
      }
    `}
`

export const AuditLogSelect = styled(Select)<{ isselected?: string; noborder?: string }>`
  min-width: 113px;
  height: 40px;
  background: transparent;
  border-radius: 100px;
  border: 1px solid #998d96;
  gap: 8px;

  ${({ noborder }) =>
    noborder === 'true' &&
    `
    border-radius: 0;
    border: 0;
    border-color: transparent;
    background: transparent;
  `};

  color: #fff;
  text-align: center;
  font-family: Quicksand, sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.1px;

  ${({ isselected }) =>
    isselected === 'true' &&
    `
    background: #6C586A;
  `};

  > * {
    border: none !important;
    outline: none !important;
  }

  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border: none !important;
  }

  svg {
    color: #d0c3cc;
  }
`

export const CategoryMenuProps = {
  PaperProps: {
    sx: {
      background: '#573F56',
      boxShadow: '0 4px 4px 0 rgba(0, 0, 0, 0.30), 0 8px 12px 6px rgba(0, 0, 0, 0.15)',
      borderRadius: '8px',
      minWidth: '288px',

      '.MuiMenuItem-root': {
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        minWidth: '288px',
        height: '48px',
        paddingLeft: '8px',
        paddingRight: '16px',

        img: {
          marginRight: '8px'
        },

        '&:hover': {
          background: 'transparent'
        },

        '&.reset-button-wrapper': {
          justifyContent: 'flex-end',
          alignItems: 'center',

          '&.Mui-disabled': {
            opacity: '0.38',

            '.MuiButton-root.reset-button': {
              color: '#fff'
            }
          },

          '&:hover': {
            '.MuiButton-root.reset-button': {
              background: 'rgba(234, 221, 255, 0.08)'
            }
          },

          '.MuiButton-root.reset-button': {
            borderRadius: '100px',
            background: 'transparent',
            display: 'flex',
            padding: '10px 16px',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '8px',
            color: '#FFA9FC',
            textAlign: 'center',
            fontFamily: '"Quicksand", sans-serif',
            fontSize: '14px',
            fontStyle: 'normal',
            fontWeight: 500,
            lineHeight: '20px',
            letterSpacing: '0.1px',
            textTransform: 'none'
          }
        },

        '.MuiCheckbox-root': {
          marginRight: '8px',

          '&.Mui-checked': {
            svg: {
              path: {
                fill: '#FFA9FC'
              }
            }
          },

          '&:hover': {
            borderRadius: '100px',
            background: 'rgba(202, 196, 208, 0.12)'
          },

          svg: {
            width: '18px',
            height: '18px',

            path: {
              fill: '#D0C3CC'
            }
          }
        },

        '.MuiListItemText-root .MuiTypography-root': {
          fontFamily: '"Quicksand", sans-serif',
          fontWeight: 500,
          fontSize: '16px',
          lineHeight: '24px',
          letterSpacing: '0.1px',
          color: '#FFF',
          fontStyle: 'normal'
        }
      },

      '&::-webkit-scrollbar': {
        width: '4px',
        background: 'transparent'
      },

      '&::-webkit-scrollbar-track': {
        boxShadow: 'none',
        background: 'transparent'
      },

      '&::-webkit-scrollbar-thumb': {
        backgroundColor: '#FBD8F5',
        borderRadius: '8px'
      }
    },
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP
    }
  },
  anchorOrigin: {
    vertical: 'bottom' as const,
    horizontal: 'left' as const
  },
  transformOrigin: {
    vertical: 'top' as const,
    horizontal: 'left' as const
  },
  variant: 'menu' as const
}
