import { Dispatch, FC, SetStateAction, SyntheticEvent, useMemo, useState } from 'react'
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material'
import moment from 'moment'

/* Components */
import IntegrationCardContent from './IntegrationCardContent'
import { IntegrationCardContainer } from './integrationCard.styles'
import NewIntegrationMarker from './NewIntegrationMarker'
import { IntegrationInstance, IntegrationStatusMessage, ManifestIntegration } from '../../../../../models'

interface Props {
  integration: ManifestIntegration
  instances: IntegrationInstance[]
  handleOpenDetails: () => void
  setTabValue: Dispatch<SetStateAction<number>>
  detailsOpen: boolean
  setSelectedInstance: Dispatch<SetStateAction<IntegrationInstance | null>>
}

const IntegrationCard: FC<Props> = ({
  integration,
  setTabValue,
  detailsOpen,
  handleOpenDetails,
  instances,
  setSelectedInstance
}) => {
  const [expanded, setExpanded] = useState<string | false>(false)

  const hasInstances = useMemo(() => !!integration && integration.instances > 0, [integration])

  const integrationInstances = instances.filter((n) => n.integration_name === integration.name)

  const isIntegrationNew = () => {
    if (integration) {
      const a = moment(integration.published_at)
      const b = moment()
      const diff = Math.abs(a.diff(b, 'days')) + 1

      if (diff < 30) {
        return true
      }
    }

    return false
  }

  const handleChange = (panel: string) => (event: SyntheticEvent, newExpanded: boolean) => {
    if (!hasInstances) {
      event.preventDefault()
      return
    }
    setExpanded(newExpanded ? panel : false)
  }

  const hasFailed = integrationInstances.find(
    (n) => n.syncStatus && n.syncStatus.status === IntegrationStatusMessage.failed
  )

  return (
    <IntegrationCardContainer expanded={expanded ? 'true' : 'false'} hasFailed={Boolean(hasFailed)}>
      {isIntegrationNew() ? <NewIntegrationMarker expanded={!!expanded} /> : null}
      <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
        <AccordionSummary aria-control="panel1a-content" id="panel1a-header">
          <IntegrationCardContent
            withInstance
            expanded={expanded ? 'true' : 'false'}
            hasFailed={Boolean(hasFailed)}
            integration={integration}
            setTabValue={setTabValue}
            handleOpenDetails={handleOpenDetails}
            detailsOpen={detailsOpen}
            setSelectedInstance={setSelectedInstance}
          />
        </AccordionSummary>
        {hasInstances &&
          integrationInstances.length > 0 &&
          integrationInstances.map((n, i) => (
            <AccordionDetails key={i}>
              <IntegrationCardContent
                expanded={expanded ? 'true' : 'false'}
                hasFailed={n.syncStatus && n.syncStatus.status === IntegrationStatusMessage.failed}
                integration={integration}
                instance={n}
                handleOpenDetails={handleOpenDetails}
                setTabValue={setTabValue}
                detailsOpen={detailsOpen}
                setSelectedInstance={setSelectedInstance}
              />
            </AccordionDetails>
          ))}
      </Accordion>
    </IntegrationCardContainer>
  )
}

export default IntegrationCard
