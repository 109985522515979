const Hashtag = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <path
      d="M13.6667 6.66675L14 5.33341H11.3333L12 2.66675H10.6667L10 5.33341H7.33333L8 2.66675H6.66667L6 5.33341H3.33333L3 6.66675H5.66667L5 9.33341H2.33333L2 10.6667H4.66667L4 13.3334H5.33333L6 10.6667H8.66667L8 13.3334H9.33333L10 10.6667H12.6667L13 9.33341H10.3333L11 6.66675H13.6667ZM9 9.33341H6.33333L7 6.66675H9.66667L9 9.33341Z"
      fill="#D0C3CC"
    />
  </svg>
)

export default Hashtag
