import { FC, useEffect, useState } from 'react'
import { Box, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'

/* Utils */
import {
  DataStatus,
  IntegrationConfigSyncStatus,
  IntegrationStatusMessage,
  PerformanceScoreCategoryDetailsDataSources,
  PerformanceScoreCpi,
  PerformanceScoreDataSource
} from '../../../../../../../models'
import { fetchSyncStatus, formatCPIDisplayNameForURL } from '../../../../../../../lib/utils'
import { formatPerformanceUnit } from '../../../../utils'

/* Components */
import {
  CategoryPerformanceScoreWrapper,
  CPICell,
  CPITableContainer,
  FooterCell,
  RecentFindingCell
} from '../../../categoryPage.styles'
import PerformanceWidget from './PerformanceWidget'
import { CategoryHoverTooltip } from '../../../components/CategoryHoverTooltip'
import { OverPerformIcon } from '../../../../../../components/svg'
import DataSourceErrorTooltip from '../../../../../../components/data-source-error-tooltip'
import { DataSourceError } from '../../../../../dashboard/sections/category-activity/components/DataSources'

const getIntegrationLogoShort = (name: string) => {
  if (name) {
    return `/icons/circle/${name}`
  }

  return '/icons/circle/crowdstrike_falcon_icon.svg'
}

interface Props {
  containersMaxWidth: number
  categoryName: string
  rows: PerformanceScoreCpi[]
  performanceValue: number
  lastUpdatePerformance: string
}

const PerformanceScore: FC<Props> = ({
  containersMaxWidth,
  rows: cpis,
  performanceValue,
  categoryName,
  lastUpdatePerformance
}) => {
  const navigate = useNavigate()
  const [dataSourceErrors, setDataSourceErrors] = useState<DataSourceError[]>([])

  const getStatus = async (dataSource: PerformanceScoreCategoryDetailsDataSources) => {
    const integrationConfig = dataSource.integrationConfig
    const id = integrationConfig?.id || ''
    const res: IntegrationConfigSyncStatus | null = await fetchSyncStatus(id)
    const current: DataSourceError[] = [...dataSourceErrors]

    if (res && res.status && res.status === IntegrationStatusMessage.failed) {
      const payload: DataSourceError = {
        id,
        status: res.status,
        errorMessage: res.errorMessage
      }
      setDataSourceErrors([...current, payload])
    }
  }

  useEffect(() => {
    if (cpis.length > 0) {
      cpis.forEach((cpi) => {
        if (cpi.dataSources.length > 0) {
          cpi.dataSources.forEach((dataSource) => {
            getStatus(dataSource)
          })
        }
      })
    }
  }, [cpis])

  const toLink = (name: string) => {
    navigate(`/library/cpis/${formatCPIDisplayNameForURL(name)}`)
  }

  return (
    <>
      <CategoryPerformanceScoreWrapper sx={{ minWidth: containersMaxWidth, width: '943px' }}>
        <Box className="header-block">
          <Typography className="title">Performance Score</Typography>
          <Typography className="date">Last Update: {lastUpdatePerformance}</Typography>
        </Box>
        <Box className="chips-wrapper">
          <Box className="chip">
            <Box className="chip-circle pink" /> <Typography>Performance</Typography>
          </Box>
          <Box className="chip">
            <Box className="chip-circle blue" /> <Typography>SLA</Typography>
          </Box>
        </Box>
        <PerformanceWidget value={performanceValue} categoryName={categoryName} />
        <Typography className="table-title">Calculation Details</Typography>
        <CPITableContainer>
          <Table aria-label="category performance score table">
            <TableHead>
              <TableRow>
                <TableCell>CPI</TableCell>
                <TableCell>Data Source</TableCell>
                <TableCell>SLA</TableCell>
                <TableCell>Most Recent Finding</TableCell>
                <TableCell>Weight</TableCell>
                <TableCell>Score</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {cpis.map((row, index) => {
                let rowFailed = null
                let hasRowFailed = false
                const cpiSourcesIds = row.dataSources.map((n) => !!n.integrationConfig && n.integrationConfig.id)
                const failedSourceTest = dataSourceErrors.find((n) => cpiSourcesIds.includes(n.id))

                const failedDataSource = row.dataSources.find(
                  (n) => !!n.integrationConfig && n.integrationConfig.id === failedSourceTest?.id
                )

                if (failedDataSource) {
                  rowFailed = failedDataSource
                  hasRowFailed = true
                }

                return (
                  <TableRow
                    key={row.cpiName}
                    sx={{
                      '&:hover': {
                        background:
                          'linear-gradient(0deg, rgba(255, 169, 252, 0.11) 0%, rgba(255, 169, 252, 0.11) 100%) !important'
                      },
                      '&:last-child td, &:last-child th': { border: 0 }
                    }}
                  >
                    <CPICell component="th" scope="row">
                      <Box className={`cpi-cell-wrapper ${hasRowFailed && 'is-failed'}`}>
                        {hasRowFailed && rowFailed ? (
                          <DataSourceErrorTooltip
                            key={index}
                            displayName={rowFailed.display_name}
                            errorMessage={rowFailed.integrationConfig.syncStatus?.errorMessage as string}
                            logoPath={getIntegrationLogoShort(rowFailed.logo_path)}
                            instanceName={rowFailed.integrationConfig.integration_name}
                            id={rowFailed.integrationConfig.id}
                            logoSize={23}
                            cpiName={row.cpiName}
                            shouldDisablePortal={false}
                            chip
                          />
                        ) : (
                          <Box className="cpi-chip" onClick={() => toLink(row.cpiName)}>
                            {row.cpiName}
                          </Box>
                        )}
                        <Box className="cpi-title" onClick={() => toLink(row.cpiName)}>
                          {row.cpiTitle}
                        </Box>
                      </Box>
                    </CPICell>
                    <TableCell>
                      <CategoryHoverTooltip
                        title={
                          <>
                            {row.dataSources.map((item: PerformanceScoreDataSource, index: number) => {
                              return (
                                <Box className="tooltip-wrapper" key={index}>
                                  <Box>
                                    <img
                                      src={`/icons/circle/${item.logo_path}`}
                                      alt={`${item.logo_path}`}
                                      width={12}
                                      height={12}
                                    />
                                  </Box>
                                  <Typography className="tooltip-text">{item.display_name}</Typography>
                                </Box>
                              )
                            })}
                          </>
                        }
                        placement="bottom-start"
                      >
                        <Box className="icons-wrapper">
                          {[...row.dataSources].slice(0, 2).map((item: PerformanceScoreDataSource, index: number) => {
                            return (
                              <Box key={index}>
                                <img
                                  src={`/icons/circle/${item.logo_path}`}
                                  alt={`${item.logo_path}`}
                                  width={12}
                                  height={12}
                                />
                              </Box>
                            )
                          })}
                          {row.dataSources.length > 2 && (
                            <Typography className="icons-text">+{row.dataSources.length - 2}</Typography>
                          )}
                        </Box>
                      </CategoryHoverTooltip>
                    </TableCell>
                    <TableCell>
                      {row.sla}
                      {formatPerformanceUnit(row.unit, true)}
                    </TableCell>
                    <RecentFindingCell
                      status={
                        row.status === DataStatus.Initializing
                          ? DataStatus.Initializing
                          : +row.score >= 100
                          ? 'healthy'
                          : 'problematic'
                      }
                    >
                      {row.status === DataStatus.Initializing ? (
                        <Typography component="span">Initializing</Typography>
                      ) : (
                        <CategoryHoverTooltip title={`Severity: ${row.section}`} placement="bottom-start">
                          <Typography component="span" sx={{ whiteSpace: 'nowrap' }}>
                            {row.recentFinding}
                          </Typography>
                        </CategoryHoverTooltip>
                      )}
                    </RecentFindingCell>
                    <TableCell>{row.weight}%</TableCell>
                    <TableCell>
                      <Box className="score-block">
                        {row.status === DataStatus.Initializing ? '--' : row.score}%
                        {row.score - 100 > 0 && (
                          <Box className="over-perform">
                            <OverPerformIcon />
                          </Box>
                        )}
                      </Box>
                    </TableCell>
                  </TableRow>
                )
              })}
              <TableRow>
                <FooterCell colSpan={6} className="no-border">
                  <Box className="last-tr">
                    Resulting Score:{' '}
                    <Typography component="span" className="performance-footer-score">
                      {performanceValue}%
                    </Typography>
                  </Box>
                </FooterCell>
              </TableRow>
            </TableBody>
          </Table>
        </CPITableContainer>
      </CategoryPerformanceScoreWrapper>
    </>
  )
}

export default PerformanceScore
