import { FC, useMemo, useState } from 'react'
import { Checkbox, ListItemText, MenuItem, OutlinedInput, SelectProps, Typography } from '@mui/material'
import { InstanceCheckedIcon, InstanceUncheckedIcon, SelectDropdownIcon } from '../../../components/svg'
import { CategoryMenuProps, ComingSoonChip, LibraryFormControl, LibrarySelect, Wrapper } from './onyxAISelect.styles'
import ResetSearch from '../../../components/form-components/ResetSearch'

export interface LabelOption {
  name: string
  label: string
  icon?: JSX.Element
}

interface Props extends SelectProps {
  options: LabelOption[]
  value: any
  handleChange: any
  handleReset: any
  minWidth?: string
  iscustom?: string
  label?: string
}

const OnyxAISelect: FC<Props> = ({
  label,
  options,
  handleChange,
  iscustom,
  handleReset,
  placeholder,
  minWidth,
  value,
  ...rest
}) => {
  const [anchorEl, setAnchorEl] = useState(null)

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget)
  }

  const renderValue = (): string => {
    if (rest.multiple) {
      const pruned = value.filter((s: string) => s !== '')
      const option = options.find((n) => n.name === pruned[0])

      if (option) {
        if (pruned.length === 1) {
          return option.label
        }

        if (placeholder && pruned.length === 0) {
          return placeholder
        }

        return `${option.label} +${pruned.length - 1}`
      }
    } else {
      const option = options.find((option) => option.name === value)

      if (option) {
        return (option as any).label || placeholder
      }
    }

    return placeholder || ''
  }

  const svgClassname = useMemo(() => {
    let base = 'MuiSelect-icon MuiSelect-iconOutlined MuiSelect-iconClosed'
    if (anchorEl) {
      base = base.replaceAll('MuiSelect-iconClosed', 'MuiSelect-iconOpen')
    } else {
      base = base.replaceAll('MuiSelect-iconOpen', 'MuiSelect-iconClosed')
    }

    return base
  }, [anchorEl])

  return (
    <Wrapper>
      {label && <Typography className="label-text">{label}</Typography>}
      <LibraryFormControl
        sx={{ minWidth }}
        isselected={anchorEl || (placeholder !== 'Sort' && value.length > 0 && value[0] !== '') ? 'true' : 'false'}
        iscustom={iscustom}
      >
        <LibrarySelect
          isselected={placeholder !== 'Sort' && value.length > 1 ? 'true' : 'false'}
          iscustom={iscustom}
          labelId="library-select-label"
          id="library-select"
          displayEmpty
          MenuProps={{
            ...CategoryMenuProps,
            PaperProps: {
              ...CategoryMenuProps.PaperProps,
              sx: {
                ...CategoryMenuProps.PaperProps.sx,
                display: anchorEl ? 'block' : 'none'
              }
            },
            anchorEl,
            disableAutoFocusItem: true,
            autoFocus: false,
            open: Boolean(anchorEl),
            onClose: () => setAnchorEl(null)
          }}
          IconComponent={() => <SelectDropdownIcon className={svgClassname} />}
          renderValue={() => renderValue()}
          inputProps={{ 'aria-label': 'Without label' }}
          {...rest}
          value={value}
          onChange={handleChange}
          input={<OutlinedInput onMouseDown={handleClick} />}
          sx={{ minWidth }}
        >
          {options.map((option) => (
            <MenuItem
              disableRipple
              autoFocus={false}
              key={option.name}
              value={option.name}
              disabled={option.name === 'predictions'}
              onClick={() => {
                if (!rest.multiple) {
                  setAnchorEl(null)
                }
              }}
              sx={{ position: 'relative' }}
            >
              <Checkbox
                checked={rest.multiple ? value.indexOf(option.name) > -1 : value === option.name}
                icon={<InstanceUncheckedIcon />}
                checkedIcon={<InstanceCheckedIcon />}
              />
              {option.icon && option.icon}
              <ListItemText primary={option.label} />
              {option.name === 'predictions' && (
                <ComingSoonChip>
                  <svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 8 8" fill="none">
                    <path
                      d="M6.54545 2.90909L7 1.90909L8 1.45455L7 1L6.54545 0L6.09091 1L5.09091 1.45455L6.09091 1.90909L6.54545 2.90909ZM3.81818 3.09091L2.90909 1.09091L2 3.09091L0 4L2 4.90909L2.90909 6.90909L3.81818 4.90909L5.81818 4L3.81818 3.09091ZM6.54545 5.09091L6.09091 6.09091L5.09091 6.54545L6.09091 7L6.54545 8L7 7L8 6.54545L7 6.09091L6.54545 5.09091Z"
                      fill="white"
                    />
                  </svg>
                  <Typography className="chip-text">Coming Soon</Typography>
                </ComingSoonChip>
              )}
            </MenuItem>
          ))}

          <ResetSearch resetData={handleReset} disabled={!value.length || (value.length === 1 && value[0] === '')} />
        </LibrarySelect>
      </LibraryFormControl>
    </Wrapper>
  )
}

export default OnyxAISelect
