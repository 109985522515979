import React from 'react'

const TrendsFilterIconDefault = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      d="M5.702 4.5H18.202L11.9395 12.375L5.702 4.5ZM2.2645 4.0125C4.7895 7.25 9.452 13.25 9.452 13.25V20.75C9.452 21.4375 10.0145 22 10.702 22H13.202C13.8895 22 14.452 21.4375 14.452 20.75V13.25C14.452 13.25 19.102 7.25 21.627 4.0125C22.2645 3.1875 21.677 2 20.6395 2H3.252C2.2145 2 1.627 3.1875 2.2645 4.0125Z"
      fill="#FFA9FC"
    />
  </svg>
)

export default TrendsFilterIconDefault
