import styled from '@emotion/styled/macro'
import { Box } from '@mui/material'

export const Wrapper = styled(Box)`
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: -200px;
    width: 500px;

    .title {
    color: #ffa9fc;
    text-align: center;
    text-shadow: 0px 1px 3px rgba(0, 0, 0, 0.15), 0px 1px 2px rgba(0, 0, 0, 0.3);
    font-family: Quicksand, sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 16px;
  }

  .description {
    max-width: 537px;
    color: #fff;
    text-align: center;
    text-shadow: 0px 1px 3px rgba(0, 0, 0, 0.15), 0px 1px 2px rgba(0, 0, 0, 0.3);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 0 auto;
    z-index: 2;
    position: relative;
  }

  .action-wrapper {
    margin-top: 24px;
    display: flex;
    justify-content: center;
  }
`

export const EmptyLogo = styled(Box)`
  position: fixed;
  bottom: -5px;
  right: 0;
  z-index: 1;

  svg {
    z-index: 1;

    path {
      fill: #4d444b;
    }
  }
`
