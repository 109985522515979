import styled from '@emotion/styled/macro'
import { Box } from '@mui/material'

export const Container = styled(Box)`
  display: flex;
  align-items: center;
  gap: 24px;
  margin-top: 8px;

  .total-info {
    display: flex;
    align-items: center;
    gap: 28px;

    .name {
      color: #d0c3cc;
      font-family: 'Quicksand', sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0.5px;
    }

    .value {
      color: #e9e0e4;
      font-family: 'Quicksand', sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: 0.1px;
    }

    .not-valid {
      color: #ff8a80;
    }
  }
`

export const Notification = styled(Box)<{ isValid?: boolean; hidePanel?: boolean }>`
  border-radius: 2px;
  background: ${({ isValid }) => (isValid ? '#4d444b' : 'rgba(255, 138, 128, 0.20)')};
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.3), 0px 2px 6px 2px rgba(0, 0, 0, 0.15);
  width: ${({ isValid }) => (isValid ? '380px' : '410px')};
  padding: 7px 12px;
  display: flex;
  align-items: center;
  gap: 8px;
  opacity: ${({ hidePanel }) => (hidePanel ? '0' : '1')};

  .info {
    color: #fff;
    font-family: 'Quicksand', sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.4px;
  }

  .underline {
    text-decoration: underline;
    cursor: pointer;
  }
`
