import { FC, SetStateAction, Dispatch, useState } from 'react'
import { Box, FormControl, TextField, IconButton, Typography } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'

/* Utils */
import { SSMAssetType, SSMNistPillar, Vendor } from '../../../../../models'
import { DrawerProps } from '../../SSM'

/* Components */
import { Container } from './addCustomVendor.styles'
import { ThemeButton } from '../../../../components/buttons'
import SearchCustomVendors from './SearchCustomVendors'

interface Props {
  handleAddCustomVendor: (vendorId: string, nist: SSMNistPillar, asset: SSMAssetType) => void
  nist: SSMNistPillar
  asset: SSMAssetType
  handleClose: () => void
  isAddingCustom: boolean
  customVendorOptions: Vendor[]
  setCustomVendorOptions: Dispatch<SetStateAction<Vendor[]>>
  customProductName: string
  setCustomProductName: Dispatch<SetStateAction<string>>
  drawerProps: DrawerProps
}

const AddCustomVendor: FC<Props> = ({
  handleAddCustomVendor,
  isAddingCustom,
  handleClose,
  nist,
  customVendorOptions,
  setCustomVendorOptions,
  asset,
  customProductName,
  setCustomProductName,
  drawerProps
}) => {
  const [value, setValue] = useState<string>('')
  const [vendorName, setVendorName] = useState('')
  const [vendorId, setVendorId] = useState('')

  const handleReset = () => {
    setCustomVendorOptions([])
    setValue('')
    setVendorName('')
    setCustomProductName('')
    setVendorId('')
  }

  return (
    <Container className="add-custom-vendor-container">
      <Box className="header">
        <Typography className="header-text">Add New Product or Vendor</Typography>
        <IconButton
          className="close-popper-icon"
          onClick={() => {
            handleClose()
            setVendorName('')
            setVendorId('')
            setCustomVendorOptions([])
          }}
        >
          <CloseIcon />
        </IconButton>
      </Box>
      <Box className="inputs-container">
        <FormControl className="form-control" variant="standard">
          <Typography>Vendor Name</Typography>
          <TextField
            value={vendorName}
            placeholder="Enter vendor name..."
            onChange={(e) => {
              setVendorName(e.target.value)
            }}
            InputProps={{
              endAdornment: vendorName.length > 0 && (
                <IconButton onClick={() => setVendorName('')} className="end-adornment-close-icon">
                  <CloseIcon width={24} />
                </IconButton>
              )
            }}
          />
        </FormControl>
        <FormControl className="form-control" variant="standard">
          <Typography>Product Name</Typography>
          <TextField
            value={customProductName}
            placeholder="Enter product name..."
            onChange={(e) => {
              setCustomProductName(e.target.value)
            }}
            InputProps={{
              endAdornment: customProductName.length > 0 && (
                <IconButton onClick={() => setCustomProductName('')} className="end-adornment-close-icon">
                  <CloseIcon width={24} />
                </IconButton>
              )
            }}
          />
        </FormControl>
        <SearchCustomVendors
          nist={nist}
          setVendorId={setVendorId}
          asset={asset}
          handleAdd={setVendorId}
          isAddingCustom={isAddingCustom}
          customVendorOptions={customVendorOptions}
          setCustomVendorOptions={setCustomVendorOptions}
          drawerProps={drawerProps}
          value={value}
          setValue={setValue}
        />
      </Box>
      <Box className="divider-actions" />
      <Box className="action-wrapper">
        <ThemeButton
          themevariant="primary"
          sx={{ padding: '0 !important', minWidth: '84px' }}
          onClick={handleReset}
          disabled={isAddingCustom || (!customProductName && !vendorName && !value && !customVendorOptions.length)}
        >
          Reset
        </ThemeButton>
        <ThemeButton
          themevariant="primary"
          sx={{ padding: '0 !important', minWidth: '100px' }}
          onClick={() => handleAddCustomVendor(vendorId, nist, asset)}
          disabled={isAddingCustom || !customVendorOptions.length || !value.length || !vendorId}
        >
          OK
        </ThemeButton>
      </Box>
    </Container>
  )
}

export default AddCustomVendor
