import { forwardRef, Dispatch, SetStateAction, ChangeEvent } from 'react'
import { LocalizationProvider } from '@mui/x-date-pickers-pro'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs'
import { Button, ClickAwayListener, DialogActions, TextField, Typography, PopperPlacementType } from '@mui/material'
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth'
import dayjs, { Dayjs } from 'dayjs'

/* Components */
import { Container, DateRangePickerContainer, dateRangePickerPopperSx } from './singleDatePicker.styles'

type Ref = HTMLDivElement | null

interface Props {
  name?: string
  open: boolean
  anchorEl: any
  setAnchorEl: any
  setOpen: any
  sourceOptions: any
  source: any
  inputRef: any
  value: string
  setValue: Dispatch<SetStateAction<string>>
  title: string
  disableFuture?: boolean
  placement?: PopperPlacementType
  minDate?: Dayjs
  handleChange?: () => void
}

const SingleDatePicker = forwardRef<Ref, Props>(
  (
    {
      open,
      handleChange,
      placement,
      anchorEl,
      minDate,
      setAnchorEl,
      setOpen,
      disableFuture = true,
      inputRef,
      value,
      title,
      setValue
    },
    ref
  ) => {
    return (
      <Container>
        <ClickAwayListener onClickAway={() => setOpen(false)}>
          <DateRangePickerContainer className="date-range-picker-container" ref={ref}>
            <Typography className="label-text">{title}</Typography>

            <TextField
              className="text-field"
              value={dayjs(value).format('MM/DD/YYYY')}
              onChange={(event: ChangeEvent<HTMLInputElement>) => {
                setValue(event.target.value)
              }}
              InputProps={{
                endAdornment: <CalendarMonthIcon className="calendar-icon" />
              }}
              onMouseDown={(e) => {
                setOpen(!open)
                setAnchorEl(e.currentTarget)
              }}
            />

            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                value={dayjs(value)}
                minDate={minDate}
                disableHighlightToday={true}
                open={open}
                onChange={(newValue) => {
                  setValue(dayjs(newValue as any).format('YYYY/MM/DD'))
                }}
                disableFuture={disableFuture}
                inputRef={inputRef.current}
                onAccept={(val) => {
                  setValue(dayjs(val as any).format('YYYY/MM/DD'))
                  if (handleChange) {
                    handleChange()
                  }
                  return val
                }}
                slots={{
                  actionBar: () => (
                    <DialogActions>
                      <Button
                        aria-expanded={open ? 'true' : undefined}
                        onClick={() => {
                          setAnchorEl(null)
                          setOpen(false)
                        }}
                      >
                        Cancel
                      </Button>
                      <Button
                        aria-expanded={open ? 'true' : undefined}
                        onClick={() => {
                          setAnchorEl(null)
                          setOpen(false)
                          // handleSearch()
                        }}
                      >
                        Ok
                      </Button>
                    </DialogActions>
                  ),
                  field: () => <></>
                }}
                slotProps={{
                  actionBar: {
                    actions: ['cancel', 'accept']
                  },
                  popper: {
                    sx: {
                      ...dateRangePickerPopperSx
                    },
                    placement: placement ?? 'bottom-start',
                    anchorEl
                  }
                }}
              />
            </LocalizationProvider>
          </DateRangePickerContainer>
        </ClickAwayListener>
      </Container>
    )
  }
)

export default SingleDatePicker
