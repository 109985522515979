import { FC, MouseEvent } from 'react'
import { Box, Grid, IconButton, InputAdornment, Link as MuiLink } from '@mui/material'
import { Visibility, VisibilityOff } from '@mui/icons-material'

/* Components */
import ControlledTextField from '../../../form-components/ControlledTextField'
import {
  SentinelInputWrapper,
  SentinelInstructionsContent,
  SentinelInstructionsTitle,
  SentinelInstructionsTitleWrapper,
  SentinelInstructionsWrapper
} from './sentinelIntegration.styles'
import { ExitToLinkIcon } from '../../../svg'

interface Props {
  activeStep: number
  showPassword: boolean
  handleClickShowPassword: () => void
  handleMouseDownPassword: (event: MouseEvent<HTMLButtonElement>) => void
}

const SentinelIntegrationStep2Manual: FC<Props> = ({
  activeStep,
  showPassword,
  handleClickShowPassword,
  handleMouseDownPassword
}) => (
  <Box sx={{ display: 'flex' }}>
    <SentinelInstructionsWrapper>
      <SentinelInstructionsTitleWrapper className="wrapper-manual">
        <SentinelInstructionsTitle>Instructions</SentinelInstructionsTitle>
        <SentinelInstructionsContent>
          <MuiLink href="https://google.com" rel="noopener noreferrer" target="_blank" className="external-link">
            <ExitToLinkIcon /> Go to Documentation
          </MuiLink>
        </SentinelInstructionsContent>
      </SentinelInstructionsTitleWrapper>
    </SentinelInstructionsWrapper>
    {activeStep === 1 && (
      <>
        <Grid container spacing={0} sx={{ pl: '72px' }}>
          <Grid item xs={12}>
            <Grid container spacing={0}>
              <Grid item xs={12}>
                <SentinelInputWrapper>
                  <ControlledTextField
                    data-private
                    id="client-id"
                    name="client_id"
                    label="Client ID *"
                    type="text"
                    variant="filled"
                  />
                </SentinelInputWrapper>
              </Grid>
              <Grid item xs={12}>
                <SentinelInputWrapper>
                  <ControlledTextField
                    data-private
                    id="tenant-id"
                    name="tenant_id"
                    label="Tenant ID *"
                    type="text"
                    variant="filled"
                  />
                </SentinelInputWrapper>
              </Grid>
              <Grid item xs={12}>
                <SentinelInputWrapper>
                  <ControlledTextField
                    data-private
                    id="secret"
                    name="secret"
                    label="Secret *"
                    variant="filled"
                    type={showPassword ? 'text' : 'password'}
                    autoComplete="current-password"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle Client Secret visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                  />
                </SentinelInputWrapper>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </>
    )}
  </Box>
)

export default SentinelIntegrationStep2Manual
