import { FC } from 'react'
import { useAuth } from '@frontegg/react'
import { Actions, Content, Header, Wrapper } from '../userProfile.styles'
import { Box, CircularProgress, IconButton, Typography } from '@mui/material'
import { CloseChipIcon } from '../../../../svg'
import { ThemeButton } from '../../../../buttons'
import {
  closeModal,
  modalPropsSelector,
  ModalType,
  openModal,
  setModalProps
} from '../../../../../../store/modals/modalSlice'
import { useAppDispatch, useAppSelector } from '../../../../../../store/hooks'
import axios from '../../../../../../lib/axios'
import { Form, Formik } from 'formik'
import ControlledRadioGroup from '../../../../form-components/ControlledRadioGroup'
import { ownerRole, roleOptions } from '../../invite-user/InviteUser'
import { closeToast, openToast, ToastVariant } from '../../../../../../store/toasts/toastSlice'
import * as Yup from 'yup'

const ChangeRole: FC = () => {
  const dispatch = useAppDispatch()
  const modalProps = useAppSelector(modalPropsSelector)
  const { user: authUser } = useAuth()

  const handleClose = () => {
    dispatch(closeModal())
  }

  const handleSave = async (values: { role: string }) => {
    if (!modalProps.props) return
    if (!modalProps.props.user) return

    if (values.role === 'owner') {
      dispatch(openModal({ type: ModalType.transferOwnership, props: modalProps.props }))
      return
    }

    try {
      const res = await axios.post(`/api/v1/identity/users/${modalProps.props.user.id}/role`, {
        role: values.role
      })

      if (res.status === 200) {
        dispatch(
          setModalProps({
            open: false,
            props: {
              ...modalProps.props,
              user: {
                ...modalProps.props.user,
                role: values.role
              }
            }
          })
        )
        dispatch(
          openToast({
            variant: ToastVariant.success,
            props: {
              text: 'Role Updated',
              description: `The role for ${modalProps.props.user.email} has been updated.`
            }
          })
        )
      }
    } catch (e) {
      console.error(e)
      dispatch(
        openToast({
          variant: ToastVariant.error,
          props: {
            text: 'Failed changing role',
            description: (e as any).response?.data?.message ?? 'There was a problem while updating the role for user.'
          }
        })
      )
    } finally {
      setTimeout(() => {
        dispatch(closeToast())
      }, 5000)
    }
  }

  const getOptions = () => {
    if (modalProps && modalProps.props && modalProps.props.user) {
      if (modalProps.props.user.id === authUser?.id) {
        return roleOptions
      }
      if ((authUser?.roles[0].key || '') === 'admin') {
        return roleOptions
      }
    }

    return [ownerRole, ...roleOptions]
  }

  if (!modalProps.props || !modalProps.props.user) {
    return <div>No user found.</div>
  }
  return (
    <Formik
      initialValues={{
        role: modalProps.props.user.role === 'Manager' ? 'cpi_manager' : modalProps.props.user.role.toLowerCase()
      }}
      validationSchema={Yup.object({
        role: Yup.string().required('Required')
      })}
      onSubmit={handleSave}
    >
      {({ isSubmitting, isValid }) => (
        <Form autoComplete="off">
          <Wrapper>
            <Header>
              <Typography className="title">Change Role</Typography>
              <IconButton onClick={handleClose}>
                <CloseChipIcon />
              </IconButton>
            </Header>
            <Content userrole="">
              <Box className="roles-wrapper">
                <Typography className="roles-title">
                  Assign Role <span>*</span>
                </Typography>
                <ControlledRadioGroup name="role" options={getOptions()} />
              </Box>
            </Content>
            <Actions>
              <ThemeButton
                themevariant="primary"
                type="submit"
                disabled={isSubmitting || !isValid}
                endIcon={isSubmitting && <CircularProgress size="20px" />}
              >
                Save
              </ThemeButton>
            </Actions>
          </Wrapper>
        </Form>
      )}
    </Formik>
  )
}

export default ChangeRole
