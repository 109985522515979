import { FC, SVGProps } from 'react'



const CloseChipIcon: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <mask
      id="mask0_4129_41529"
      style={{ maskType: 'alpha' }}
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="24"
      height="24"
    >
      <rect width="24" height="24" fill={props.color || "#D9D9D9"} />
    </mask>
    <g mask="url(#mask0_4129_41529)">
      <path
        d="M12 13.325L7.14999 18.175C6.96665 18.3583 6.74599 18.45 6.48799 18.45C6.22932 18.45 6.00832 18.3583 5.82499 18.175C5.64165 17.9917 5.54999 17.7707 5.54999 17.512C5.54999 17.254 5.64165 17.0333 5.82499 16.85L10.7 11.975L5.82499 7.09999C5.64165 6.93333 5.54999 6.72066 5.54999 6.46199C5.54999 6.20399 5.64165 5.98333 5.82499 5.79999C6.00832 5.61666 6.22932 5.52499 6.48799 5.52499C6.74599 5.52499 6.96665 5.61666 7.14999 5.79999L12 10.675L16.85 5.82499C17.0333 5.64166 17.2543 5.54999 17.513 5.54999C17.771 5.54999 17.9917 5.64166 18.175 5.82499C18.3583 6.00833 18.45 6.22899 18.45 6.48699C18.45 6.74566 18.3583 6.96666 18.175 7.14999L13.325 12L18.2 16.875C18.3667 17.0583 18.454 17.275 18.462 17.525C18.4707 17.775 18.3833 17.9917 18.2 18.175C18.0167 18.3583 17.796 18.45 17.538 18.45C17.2793 18.45 17.0583 18.3583 16.875 18.175L12 13.325Z"
        fill={props.color || "#EDEDED"}
      />
    </g>
  </svg>
)

export default CloseChipIcon
