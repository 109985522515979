import React from 'react'

const AnalyzeIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
    <path
      d="M19.5013 8L20.4413 5.94L22.5013 5L20.4413 4.06L19.5013 2L18.5613 4.06L16.5013 5L18.5613 5.94L19.5013 8Z"
      fill="#590060"
    />
    <path
      d="M8.00128 8L8.94128 5.94L11.0013 5L8.94128 4.06L8.00128 2L7.06128 4.06L5.00128 5L7.06128 5.94L8.00128 8Z"
      fill="#590060"
    />
    <path
      d="M19.5013 13.5L18.5613 15.56L16.5013 16.5L18.5613 17.44L19.5013 19.5L20.4413 17.44L22.5013 16.5L20.4413 15.56L19.5013 13.5Z"
      fill="#590060"
    />
    <path
      d="M17.2113 10.12L14.3813 7.29C14.1813 7.1 13.9313 7 13.6713 7C13.4113 7 13.1613 7.1 12.9613 7.29L1.79128 18.46C1.40128 18.85 1.40128 19.48 1.79128 19.87L4.62128 22.7C4.82128 22.9 5.07128 23 5.33128 23C5.59128 23 5.84128 22.9 6.04128 22.71L17.2113 11.54C17.6013 11.15 17.6013 10.51 17.2113 10.12ZM13.6713 9.42L15.0813 10.83L13.9113 12L12.5013 10.59L13.6713 9.42ZM5.33128 20.59L3.92128 19.18L11.0913 12L12.5013 13.41L5.33128 20.59Z"
      fill="#590060"
    />
  </svg>
)

export default AnalyzeIcon