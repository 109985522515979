import { FC } from 'react'
import { Box, Typography } from '@mui/material'

/* Components */
import { Container, FrameworkSelectCardContainer, Header, Content } from './styles/frameworkSelectCard.styles'
import { ThemeButton } from '../../../components/buttons'

interface Props {
  content: string
  title: string
  headerIcon: string
  selected: boolean
  handleChange: () => void
  handleViewDetails: () => void
}

const FrameworkSelectCard: FC<Props> = ({ content, title, headerIcon, selected, handleChange, handleViewDetails }) => {
  return (
    <Container>
      <FrameworkSelectCardContainer isSelected={selected ? 'true' : 'false'} onClick={handleChange}>
        <Header className="select-card-header">
          <Box className="header-framework">
            <img src={headerIcon} alt={`${title} Framework`} width="40px" height="40px" />
            <Typography className="header-title">{title}</Typography>
          </Box>
          {selected && (
            <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
              <path
                d="M13.9271 26.7158L6.02491 18.8136L3.33398 21.4855L13.9271 32.0787L36.6673 9.33848L33.9953 6.6665L13.9271 26.7158Z"
                fill="#FFA9FC"
              />
            </svg>
          )}
        </Header>
        <Content className="select-card-content">
          <Typography className="content-text">{content}</Typography>
        </Content>
      </FrameworkSelectCardContainer>
      <ThemeButton themevariant="secondary" onClick={handleViewDetails}>
        View Details
      </ThemeButton>
    </Container>
  )
}

export default FrameworkSelectCard
