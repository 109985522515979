import styled from '@emotion/styled/macro'
import { Box, DialogActions } from '@mui/material'

export const Wrapper = styled(Box)`
  padding: 24px;

  .description {
    color: #e9e0e4;
    font-family: 'Quicksand', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.5px;
    width: 350px;
  }

  .description-line {
    color: #e9e0e4;
    font-family: 'Quicksand', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.5px;
    margin-bottom: 32px;
  }
`

export const Header = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;

  .header-block {
    display: flex;
    align-items: center;
    gap: 16px;
  }

  .title {
    font-family: 'Quicksand', sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px;
    color: #e9e0e4;
  }
`

export const Actions = styled(DialogActions)`
  margin-top: 24px;
  padding: 0;
`
