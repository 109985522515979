import styled from '@emotion/styled/macro'
import { Box } from '@mui/material'

export const Wrapper = styled(Box)<{
  line?: number
  paddingBottom?: number
  marginTop?: number
  scrollHeight?: number
  normalHeight?: string
}>`
  height: ${({ line, normalHeight, scrollHeight }) =>
    normalHeight === 'true'
      ? '100vh'
      : line === 1
      ? 'calc(100vh - 100px)'
      : scrollHeight
      ? `calc(100vh - ${scrollHeight}px - 40px)`
      : 'calc(100vh - 200px)'};
  padding-bottom: ${({ paddingBottom }) => (typeof paddingBottom !== 'undefined' ? `${paddingBottom}px` : '40px')};
  overflow-y: auto;
  overflow-x: hidden;

  ${({ normalHeight }) =>
    normalHeight === 'true'
      ? `
    margin-top: 80px;
    `
      : null};

  margin-top: ${({ marginTop }) => (marginTop ? `${marginTop}px !important` : '0')};

  ::-webkit-scrollbar {
    width: 4px;
    background: transparent;
  }

  ::-webkit-scrollbar-track {
    box-shadow: none;
    background: #4d444b;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #ffa9fc;
    border-radius: 16px;
  }
`
