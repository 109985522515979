import { FC } from 'react'
import { Box, Typography } from '@mui/material'

/* Utils */
import { CategoryActivityItem, PerformanceStatus } from '../../../../../../models'

/* Components */
import { CategoryWrapper } from './category.styles'
import CategoryChart from './CategoryChart'
import DataSources from './DataSources'
import AvailableCategory from './AvailableCategory'
import EmptyDataCategory from './EmptyDataCategory'
import { OverPerformIcon } from '../../../../../components/svg'

interface Props {
  category: CategoryActivityItem
  handleCategoryClick: (category: string) => void
  handleCpiRedirect: (category: CategoryActivityItem) => void
}

const Category: FC<Props> = ({ category, handleCategoryClick, handleCpiRedirect }) => {
  const dataSources = category.status === PerformanceStatus.active ? category.dataSources : []

  if (category.status === PerformanceStatus.active && !category.data?.length) {
    return <EmptyDataCategory category={category} handleCategoryClick={handleCategoryClick} dataSources={dataSources} />
  }
  if (category.status !== PerformanceStatus.active) {
    return (
      <AvailableCategory
        category={category}
        handleCategoryClick={handleCategoryClick}
        handleCpiRedirect={handleCpiRedirect}
      />
    )
  }

  const lastValue = category.data.length > 0 ? category.data[category.data.length - 1].yValue : 0
  const remainder = +(lastValue - 100)

  return (
    <CategoryWrapper titlelength={category.name.length}>
      <Box className="header" onClick={() => handleCategoryClick(category.name)}>
        <Typography className={`${remainder > 0 ? '' : 'category-margin'} category-title`}>{category.name}</Typography>

        {remainder > 0 && (
          <Box className="over-perform">
            <OverPerformIcon />
            <Typography className="value">
              <b>{remainder}%</b> Over Performing
            </Typography>
          </Box>
        )}
      </Box>

      <CategoryChart category={category} />
      <DataSources dataSources={dataSources} />
    </CategoryWrapper>
  )
}

export default Category
