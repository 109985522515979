import { FC, SyntheticEvent } from 'react'

/* Utils */
import { CPIManifestCategory, IChartDataCategory } from '../../../../../models'

/* Components */
import HeaderComponent from '../../../../components/header/HeaderComponent'
import { Tab } from '@mui/material'
import NavigationSelectField from '../../components/NavigationSelectField'
import { urlToCategoryCapitalized } from '../../utils'
import { IntegrationsTabs } from '../../../settings/integrations/integrations.styles'
import { a11yProps } from '../../library-details/components/LibraryDetailsTabPanel'
import ToolbarComponent from '../../../../components/header/ToolbarComponent'

interface Props {
  category: CPIManifestCategory
  categoryName: string
  activeCategories: IChartDataCategory[]
  tabLabels: string[]
  tabValue: number
  handleTabChange: (event: SyntheticEvent, newValue: number) => void
  loading?: boolean
}

const CategoryHeader: FC<Props> = ({
  categoryName,
  activeCategories,
  loading,
  tabLabels,
  tabValue,
  handleTabChange
}) => {
  const breadcrumbs = [{ name: 'Dashboard', link: '/' }, { name: categoryName }]

  return (
    <>
      <HeaderComponent breadcrumbs={breadcrumbs}>
        <NavigationSelectField
          label=""
          options={activeCategories}
          placeholder="Category"
          value={urlToCategoryCapitalized(categoryName)}
          minWidth="160px !important"
          noborder="true"
          version="category"
          disabled={loading}
        />
      </HeaderComponent>

      <ToolbarComponent align="end">
        <IntegrationsTabs
          value={tabValue}
          onChange={handleTabChange}
          aria-label={`Category tabs`}
          TabIndicatorProps={{
            style: { display: 'none' }
          }}
          isdetails="true"
        >
          {tabLabels.map((label, index) => {
            return <Tab label={label} {...a11yProps(index)} key={index} />
          })}
        </IntegrationsTabs>
      </ToolbarComponent>
    </>
  )
}

export default CategoryHeader
