import { Box } from '@mui/material'
import styled from '@emotion/styled/macro'

export const MainSectionContent = styled(Box)<{ bg: string }>`
  width: 718px;
  border-radius: 16px;
  background: linear-gradient(0deg, rgba(255, 169, 252, 0.05) 0%, rgba(255, 169, 252, 0.05) 100%), #1e1a1d;

  .main-section-header {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 12.47px 20px 12px 16px;
    border-radius: 16px 16px 0 0;
    position: relative;
    z-index: 1;

    img {
      z-index: 10;
    }

    .header-background {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 53px;
      z-index: 0;
    }

    .header-text {
      z-index: 1;
      color: #fff;
      font-family: Quicksand, sans-serif;
      font-size: 22px;
      font-style: normal;
      font-weight: 400;
      line-height: 28px;
      margin-left: 12px;
      flex-grow: 1;
    }

    .header-date {
      z-index: 1;
      color: #fff;
      font-family: Quicksand, sans-serif;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 16px;
      letter-spacing: 0.5px;
    }
  }
`

export const HistorySectionContent = styled(Box)<{ bg: string }>`
  width: 545px;
  border-radius: 16px;
  background: linear-gradient(0deg, rgba(255, 169, 252, 0.05) 0%, rgba(255, 169, 252, 0.05) 100%), #1e1a1d;
  cursor: pointer;
  z-index: 1;

  @media (max-width: 1440px) {
    width: 100%;
  }

  .main-section-header {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 6px 20px 12px 16px;
    border-radius: 16px;
    position: relative;
    z-index: 1;

    img {
      z-index: 10;
    }

    .header-text {
      z-index: 1;
      color: #fff;
      font-family: Quicksand, sans-serif;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 16px;
      margin-left: 8px;
      letter-spacing: 0.5px;
      flex-grow: 1;
    }

    .header-date {
      z-index: 1;
      color: #fff;
      font-family: Quicksand, sans-serif;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 16px;
      letter-spacing: 0.5px;
    }
  }

  .content-inner {
    padding: 0 16px 13px;
    width: 100%;

    .performance-title {
      margin-bottom: 12.5px;
      color: #e9e0e4;
      font-family: Quicksand, sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0.25px;
    }

    .performance-description {
      color: #d0c3cc;
      font-family: Quicksand, sans-serif;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;
      letter-spacing: 0.4px;
      margin-top: 12px;
      margin-right: 24px;
    }
  }
`

export const HeaderBackground = styled(Box)<{ bg: string }>`
  width: 543px;
  height: 34px;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 16px 16px 0 0;
  background: ${({ bg }) => `url(${bg})`};
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  z-index: 0;

  @media (max-width: 1440px) {
    width: 100%;
  }
`

export const ContentInner = styled(Box)`
  padding: 10px 16px 13px;
  width: 100%;

  .performance-text {
    margin-bottom: 12.5px;
    color: #e9e0e4;
    font-family: Quicksand, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.1px;
  }

  .main-performance-text {
    color: #d0c3cc;
    font-family: Quicksand, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.25px;
    margin-bottom: 32px;
  }
`

export const InsightsImageWrapper = styled(Box)`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`
