import { Box } from '@mui/material'
import styled from '@emotion/styled/macro'
import { NistFunction } from '../../../models'
import { getCpiCategoryColors } from '../../../lib/utils'

export const NistContainer = styled(Box)<{ fn: NistFunction | '' }>`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  border: 1px solid #998d96;
  background: #8e9aff;
  padding: 4px 8px;
  gap: 8px;
  width: fit-content;
  cursor: pointer;

  ${({ fn }) =>
    fn === NistFunction.Identify &&
    `
      background: #8E9AFF;
    `}

  ${({ fn }) =>
    fn === NistFunction.Protect &&
    `
      background: #C77EDA;
    `}
  
  ${({ fn }) =>
    fn === NistFunction.Detect &&
    `
      background: #FFDE6A;
    `}
  
  ${({ fn }) =>
    fn === NistFunction.Respond &&
    `
      background: #FF7F6B;
    `}
  
  ${({ fn }) =>
    fn === NistFunction.Recover &&
    `
      background: #99FDC0;
    `}
  
  ${({ fn }) =>
    fn === NistFunction.Govern &&
    `
      background: #FF9E47;
    `}
  
  img {
    border-radius: 50%;
  }

  .MuiTypography-root {
    color: #000;
    text-align: center;
    font-family: Quicksand, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.1px;
  }
`

export const OnyxiaContainer = styled(Box)<{ bgcolor?: string; textcolor?: string; categoryId?: string }>`
  display: flex;
  height: 30px;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.15), 0 1px 2px 0 rgba(0, 0, 0, 0.3);
  padding: 6px 16px;

  > .MuiTypography-root {
    text-align: center;
    font-family: Quicksand, sans-serif;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0.5px;

    ${({ textcolor }) => {
      if (textcolor) {
        return `
          color: ${textcolor};
        `
      }
    }};
  }

  ${({ categoryId, bgcolor }) => {
    if (bgcolor) {
      return `
        background: ${bgcolor};
      `
    } else {
      const colors = getCpiCategoryColors(categoryId || '')

      return `
        background: ${colors.background};
      
        > .MuiTypography-root {
          color: ${colors.color};
        }
      `
    }
  }};
`
