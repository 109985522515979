import React, { FC, SVGProps } from 'react'

type Props = SVGProps<SVGSVGElement>

const SSMEditCellIcon: FC<Props> = ({ ...props }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" fill="none" {...props}>
    <path
      d="M2.46666 18.9997H5.9384L16.1777 8.76036L12.706 5.28862L2.46666 15.5279V18.9997ZM4.31825 16.2963L12.706 7.90862L13.5577 8.76036L5.16999 17.1481H4.31825V16.2963Z"
      fill="#590060"
    />
    <path
      d="M16.6962 2.6038C16.3351 2.24274 15.7519 2.24274 15.3908 2.6038L13.6966 4.29801L17.1683 7.76975L18.8625 6.07554C19.2236 5.71448 19.2236 5.13123 18.8625 4.77017L16.6962 2.6038Z"
      fill="#590060"
    />
  </svg>
)

export default SSMEditCellIcon
