import { endOfWeek, startOfWeek } from 'date-fns'
import dayjs from 'dayjs'
import * as d3 from 'd3'
import {
  AnalysisDataItem,
  AnalysisDataResponse,
  AnalysisDataResponseBoth,
  AnalyzerChartData,
  AnalyzerData,
  AnalyzerDataExtended,
  AnalyzerDataValue,
  AnalyzerDataValueExtended,
  AnalyzerDataValueFilled,
  CPIFrequency,
  CPITimePeriod,
  DummyObjectAca,
  IntegrationInstance
} from '../../../models'
import { dateFormat } from '../../../lib/utils'
import {
  convertDateToUTCString,
  getUtcDateAndTime,
  generateStringDatesArray,
  getSelectedPeriodNumber,
  getRangeFromToFormatted,
  getTodayTimeUTC,
  fillDatesBetweenRange
} from '../library/utils'

export const formatData = (
  data: AnalyzerData,
  firstSource: AnalyzerChartData,
  secondSource: AnalyzerChartData
): AnalyzerDataExtended => {
  const valuesObject: any = {}

  for (const [key, value] of Object.entries(data)) {
    if (key === firstSource.id) {
      valuesObject.firstSource = {
        id: key,
        values: value
      }
    }
    if (key === secondSource.id) {
      valuesObject.secondSource = {
        id: key,
        values: value
      }
    }
  }

  valuesObject.values = {}
  const formattedValues = []

  /* 1, 2 */
  if (valuesObject?.firstSource?.id && valuesObject?.secondSource?.id) {
    valuesObject.firstSource.values.forEach((firstSourceValue: any) => {
      valuesObject.values[firstSourceValue.xValue] = {
        y1Value: firstSourceValue.yValue
      }
    })
    valuesObject.secondSource.values.forEach((secondSourceValue: any) => {
      valuesObject.values[secondSourceValue.xValue] = {
        ...valuesObject.values[secondSourceValue.xValue],
        y2Value: secondSourceValue.yValue
      }
    })

    for (const [key, value] of Object.entries(valuesObject.values)) {
      formattedValues.push({
        xValue: key,
        y1Value: (value as any).y1Value,
        y2Value: (value as any).y2Value
      })
    }
  }
  /* 1, !2 */
  if (valuesObject?.firstSource?.id && !valuesObject?.secondSource?.id) {
    valuesObject.firstSource.values.forEach((firstSourceValue: any) => {
      valuesObject.values[firstSourceValue.xValue] = {
        y1Value: firstSourceValue.yValue
      }
    })

    for (const [key, value] of Object.entries(valuesObject.values)) {
      formattedValues.push({
        xValue: key,
        y1Value: (value as any).y1Value
      })
    }
  }
  /* !1, 2 */
  if (valuesObject?.secondSource?.id && !valuesObject?.firstSource?.id) {
    valuesObject.secondSource.values.forEach((secondSourceValue: any) => {
      valuesObject.values[secondSourceValue.xValue] = {
        y2Value: secondSourceValue.yValue
      }
    })

    for (const [key, value] of Object.entries(valuesObject.values)) {
      formattedValues.push({
        xValue: key,
        y2Value: (value as any).y2Value
      })
    }
  }
  valuesObject.values = formattedValues

  return { values: valuesObject.values }
}

export const buildEmptyData = () => {
  const today = dayjs().format(dateFormat.customLabel)
  const end = today
  const start = dayjs(today).subtract(7, 'days')
  const startFormatted = dayjs(start).format(dateFormat.customLabel)
  const data = fillDatesBetweenRange(new Date(startFormatted), new Date(end))
  const dataFormatted = data.map((n) => ({
    xValue: n.slice(0, 10),
    yValue: 0
  }))
  const d = {
    emptyDataPlaceholder: dataFormatted
  }
  const firstSource = {
    id: 'emptyDataPlaceholder',
    integrationName: 'emptyDataPlaceholder',
    instanceName: 'emptyDataPlaceholder',
    status: 'ready',
    values: dataFormatted
  }

  return {
    data: d,
    months: 1,
    firstSource,
    secondSource: firstSource
  }
}

export const reverseMapPeriodValue = (value: string) => {
  switch (value) {
    case '1 month':
      return CPITimePeriod.Month
    case '3 months':
      return CPITimePeriod.ThreeMonths
    case '6 months':
      return CPITimePeriod.SixMonths
    case 'Past year':
      return CPITimePeriod.PastYear
    case '1 week':
    default:
      return CPITimePeriod.Week
  }
}

export const filterData = (months: number, data: any) => {
  const today = new Date()

  return data.values.filter((item: any) => {
    const itemDate = new Date(item.xValue)
    const utcItemDate = new Date(Date.UTC(itemDate.getUTCFullYear(), itemDate.getUTCMonth(), itemDate.getUTCDate()))
    const utcStartDate = new Date(Date.UTC(today.getUTCFullYear(), today.getUTCMonth() - months, 1))

    return utcItemDate >= utcStartDate
  })
}

export const findUniqueMonths = (
  filteredData: AnalyzerDataValue[],
  timePeriod: CPITimePeriod,
  data?: AnalyzerDataExtended,
  analysis = false
): string[] => {
  const uniqueMonths: string[] = []

  if (timePeriod === CPITimePeriod.Week) {
    filteredData.forEach((item: any) => {
      const date = new Date(item.xValue)
      const d = getUtcDateAndTime(item.xValue).labelFormatDate
      const year = date.getFullYear()
      const month = date.getMonth() + 1
      const day = date.getDate()
      const monthKey = `${year}-${month.toString().padStart(2, '0')}-${day}`

      if (analysis) {
        if (!uniqueMonths.includes(d)) {
          uniqueMonths.push(d)
          return
        }
      }

      if (!uniqueMonths.includes(monthKey)) {
        uniqueMonths.push(monthKey)
      }
    })
  } else if (timePeriod === CPITimePeriod.Month && data) {
    const grouped = groupByWeeks(data)

    return grouped.values.map((n: any) => n.xValue)
  } else {
    filteredData.forEach((item: any) => {
      const date = new Date(item.xValue)
      const year = date.getFullYear()
      const month = date.getMonth() + 1
      const monthKey = `${year}-${month.toString().padStart(2, '0')}`

      if (!uniqueMonths.includes(monthKey)) {
        uniqueMonths.push(monthKey)
      }
    })
  }

  return uniqueMonths
}

export const groupByWeeks = (data: AnalyzerDataExtended): AnalyzerDataExtended => {
  const groupedData: {
    [key: string]: {
      xValue: string
      y1Value: number
      y2Value: number
      start: string
      end: string
    }
  } = {}

  data.values.forEach((entry) => {
    const date = new Date(entry.xValue)
    const year = date.getFullYear()

    const startOfWeekDate = startOfWeek(date, { weekStartsOn: 1 })
    const startDay = startOfWeekDate.getDate()
    const startMonth = startOfWeekDate.getMonth() + 1

    const endOfWeekDate = endOfWeek(date, { weekStartsOn: 1 })
    const endYear = endOfWeekDate.getFullYear()
    const endDay = endOfWeekDate.getDate()
    const endMonth = endOfWeekDate.getMonth() + 1
    const groupKey = `${year}-W${startMonth < 10 ? '0' : ''}${startMonth}-${startDay < 10 ? '0' : ''}${startDay}`

    if (!groupedData[groupKey]) {
      groupedData[groupKey] = {
        xValue: entry.xValue,
        y1Value: entry.y1Value,
        y2Value: entry.y2Value,
        start: `${year}-${startMonth < 10 ? '0' : ''}${startMonth}-${startDay < 10 ? '0' : ''}${startDay}`,
        end: `${endYear}-${endMonth < 10 ? '0' : ''}${endMonth}-${endDay < 10 ? '0' : ''}${endDay}`
      }
    }
  })

  const result: AnalyzerDataValueExtended[] = []
  for (const key in groupedData) {
    const prunedXValue = key.replace('W', '')
    const xValue = dayjs(prunedXValue).format('YYYY-MM-DD')

    result.push({
      xValue: xValue || '',
      y1Value: groupedData[key].y1Value,
      y2Value: groupedData[key].y2Value,
      start: groupedData[key].start || '',
      end: groupedData[key].end || ''
    })
  }

  return { ...data, values: result }
}

export const filterDateByDay = (data: AnalyzerDataValueExtended[], day: string): AnalyzerDataValueExtended[] => {
  const currentDate = new Date(day).getDate()

  return data.filter((e) => {
    const thisDate = new Date(e.xValue).getDate()

    return thisDate === currentDate
  })
}

export const filterDateByWeek = (data: AnalyzerDataValueExtended[], day: string): AnalyzerDataValueExtended[] => {
  const date = new Date(day)
  const startOfWeekDate = startOfWeek(date, { weekStartsOn: 1 }) // 1 means Monday
  const endOfWeekDate = endOfWeek(date, { weekStartsOn: 1 }) // 1 means Monday
  const startTime = new Date(startOfWeekDate).getTime()
  const endTime = new Date(endOfWeekDate).getTime()

  return data.filter((e) => {
    const currentDayTime = new Date(e.xValue).getTime()

    return currentDayTime >= startTime && currentDayTime < endTime
  })
}

export const filterDateByMonth = (data: AnalyzerDataValueExtended[], month: string): AnalyzerDataValueExtended[] => {
  let d = month
  const curDat = data.filter((n) => n.xValue.includes(month))
  if (!curDat.length) return []
  const currentDate = convertDateToUTCString(new Date(curDat[0].xValue))
  if (currentDate) d = currentDate
  const currentMonth = new Date(d).getMonth()
  const currentYear = new Date(d).getFullYear()

  return data.filter((e) => {
    const thisMonth = new Date(e.xValue).getMonth()
    const thisYear = new Date(e.xValue).getFullYear()

    return thisMonth === currentMonth && thisYear === currentYear
  })
}

export interface D3PositionAnalyzer {
  x: number
  y1: number
  y2: number
  value?: number
  date?: string
  monthIndex?: number
  dummy?: boolean
}

export const getPathAndPositions = (
  uniqueMonths: string[],
  stripeWidth: number,
  height: number,
  filteredData: AnalyzerDataValueExtended[],
  timePeriod: CPITimePeriod,
  yAxisDomain: [number, number],
  isReady: boolean,
  firstSource: AnalyzerChartData,
  secondSource: AnalyzerChartData,
  paddingTop?: number
): [string, string, Array<D3PositionAnalyzer>] => {
  let yellowPathStr = ``
  let bluePathStr = ``
  const positions: D3PositionAnalyzer[] = []

  for (let i = 0; i < uniqueMonths.length; i++) {
    const stripeWidthStart = stripeWidth * i
    const stripeWidthEnd = stripeWidth * (i + 1)

    let groupedMonths: AnalyzerDataValueExtended[] = []
    if (timePeriod === CPITimePeriod.Week) {
      const currentDate = getUtcDateAndTime(uniqueMonths[i])
      groupedMonths = filterDateByDay(filteredData, currentDate.utcFormattedDate)
    } else if (timePeriod === CPITimePeriod.Month) {
      groupedMonths = filterDateByWeek(filteredData, uniqueMonths[i])
    } else {
      const month = dayjs(uniqueMonths[i]).format('YYYY-MM')
      groupedMonths = filterDateByMonth(filteredData, month)
    }

    if (!groupedMonths.length) {
      positions.push({
        x: 0,
        y1: 0,
        y2: 0,
        value: 0
      })
      yellowPathStr += `M0 ${height}`
      bluePathStr += `M0 ${height}`
      continue
    }

    const stripeMiddle = !isReady && i === 0 ? 0 : stripeWidth / (groupedMonths.length * 2)

    const xScaleEach = d3
      .scaleBand()
      .domain(groupedMonths.map((d) => d.xValue))
      .range([stripeWidthStart, stripeWidthEnd])
      .padding(0)
    const yScaleEach = d3
      .scaleLinear()
      .domain(yAxisDomain)
      .nice()
      .range([height, paddingTop ?? 0])

    groupedMonths.forEach((each, index: number) => {
      let startString = 'L'
      if (i === 0 && index === 0) {
        startString = 'M'
      }
      const add = isReady ? stripeMiddle : stripeMiddle * 2
      const position = {
        x: (xScaleEach(each.xValue) || 0) + add,
        y1: yScaleEach(each.y1Value),
        y2: yScaleEach(each.y2Value),
        value: each.y1Value,
        monthIndex: i,
        date: dayjs(each.xValue).format('YYYY-MM-DD')
      }
      positions.push(position)
      yellowPathStr += `${startString}${position.x} ${position.y1}`
      bluePathStr += `${startString}${position.x} ${position.y2}`
    })

    if (!firstSource.values || !firstSource.values.length) {
      yellowPathStr = ''
    }
    if (!secondSource.values || !secondSource.values.length) {
      bluePathStr = ''
    }
  }

  return [yellowPathStr, bluePathStr, positions]
}

export const findNearestXIndex = (array: any, targetX: any) => {
  let minDiff = Infinity
  let nearestObject = null

  for (let i = 0; i < array.length; i++) {
    const diff = Math.abs(array[i].x - targetX)
    if (diff < minDiff) {
      minDiff = diff
      nearestObject = {
        x: array[i].x,
        y1: array[i].y1,
        y2: array[i].y2,
        value: array[i].value,
        index: i,
        monthIndex: array[i].monthIndex
      }
    }
  }

  return nearestObject
}

export function generateDatesArray(startDate: Date, endDate: Date): DummyObjectAca[] {
  const datesArray: DummyObjectAca[] = []
  const currentDate: Date = new Date(startDate)

  // eslint-disable-next-line no-unmodified-loop-condition
  while (currentDate <= endDate) {
    datesArray.push({
      xValue: new Date(currentDate).toISOString(),
      yValue: 0,
      dummy: true
    })
    currentDate.setDate(currentDate.getDate() + 1)
  }

  return datesArray
}

export const formatXLabelCoverage = ({
  date,
  selectedTimePeriod,
  selectedFrequency,
  filteredData,
  dataStartsAt
}: {
  date: string
  selectedTimePeriod: CPITimePeriod
  selectedFrequency: CPIFrequency
  filteredData: any[]
  dataStartsAt: string
}) => {
  if (selectedTimePeriod === CPITimePeriod.Week) {
    return dayjs(date).format('MMM D')
  }
  if (selectedTimePeriod === CPITimePeriod.Month) {
    const d = filterDateByWeek(filteredData, date)
    if (d.length > 0) {
      const dataStartTime = new Date(dataStartsAt).getTime()
      const currentStartTime = new Date(d[0].xValue).getTime()
      const utcDateStart = getUtcDateAndTime(d[0].xValue)
      const utcDateEnd = getUtcDateAndTime(d[d.length - 1].xValue)
      const rangeFrom = utcDateStart.labelFormatDate
      const rangeTo = utcDateEnd.labelFormatDate
      const rangeFromMonth = utcDateStart.month
      const rangeFromYear = utcDateStart.year
      const rangeToMonth = utcDateEnd.month
      const rangeToYear = utcDateEnd.year
      const isSameYear = rangeFromYear === rangeToYear
      const isSameMonth = rangeFromMonth === rangeToMonth
      let rangeFromFormatted = dayjs(rangeFrom).format(`MMM D${!isSameYear ? ', YYYY' : ''}`)
      let rangeToFormatted = dayjs(rangeTo).format(`${!isSameMonth ? 'MMM ' : ''}D${!isSameYear ? ', YYYY' : ''}`)
      const rangeToFormat = rangeFromMonth === rangeToMonth ? 'D' : 'MMM D'
      rangeFromFormatted = dayjs(rangeFrom).format('MMM D')
      rangeToFormatted = dayjs(rangeTo).format(rangeToFormat)
      const todayTime = getTodayTimeUTC()
      const endDayTime = new Date(rangeTo).getTime()

      if (currentStartTime < dataStartTime && selectedFrequency === CPIFrequency.Daily) {
        rangeFromFormatted = dayjs(dataStartsAt).format('MMM D')
      }

      if (todayTime < endDayTime) {
        rangeToFormatted = dayjs(todayTime).format(rangeToFormat)
      }
      if (rangeFrom === rangeTo) {
        rangeToFormatted = ''
      }
      const today = new Date()
      const todayDate = today.getUTCDate()
      const todayMonth = today.getUTCMonth() + 1
      const todayYear = today.getUTCFullYear()
      if (utcDateStart.day === todayDate && todayMonth === utcDateStart.month && todayYear === utcDateStart.year) {
        rangeToFormatted = ''
      }

      if (!rangeToFormatted) return rangeFromFormatted
      return `${rangeFromFormatted}-${rangeToFormatted}`
    }

    return dayjs(date).format('MMM DD')
  }
  if (selectedTimePeriod === CPITimePeriod.ThreeMonths || selectedTimePeriod === CPITimePeriod.SixMonths) {
    if (selectedFrequency === CPIFrequency.Weekly) {
      const groupedDays = filterDateByMonth(filteredData, date)

      if (groupedDays.length < 1) return dayjs(date).format('MMM DD')

      const { rangeFromFormatted, rangeToFormatted } = getRangeFromToFormatted(
        selectedFrequency,
        groupedDays[0].xValue,
        (groupedDays as any)[0].start,
        (groupedDays as any)[groupedDays.length - 1].end
      )

      return `${rangeFromFormatted}-${rangeToFormatted}`
    }

    const currentDate = convertDateToUTCString(new Date(date))

    /*
     * This used to be range from-to, but changed because:
     * ONY-2585 */
    return dayjs(currentDate).format(`MMM 'YY`)
  }
  if (selectedTimePeriod === CPITimePeriod.PastYear) {
    return dayjs(date).format("MMM 'YY")
  }

  return ''
}

export const fillBlankDataCoverage = (
  values: AnalyzerDataValue[],
  selectedTimePeriod: CPITimePeriod
): AnalyzerDataValueFilled[] => {
  const [initialValue, finalValue] = [values[0], values.slice(-1)[0]]
  const date = new Date(initialValue.xValue)
  date.setDate(date.getDate() - 1)
  const subtractedDate = date
  const [initialMonth, finalMonth] = [new Date(subtractedDate).getMonth(), new Date(finalValue.xValue).getMonth()]
  const fMonth = finalMonth === 0 ? 12 : finalMonth
  const selectedMonths = parseInt(getSelectedPeriodNumber(selectedTimePeriod))
  const monthDiff = initialMonth === finalMonth ? selectedMonths : fMonth - initialMonth
  let dummyArray: DummyObjectAca[] = []

  if (selectedMonths >= monthDiff) {
    const fakeMonthsGap = selectedMonths === monthDiff ? selectedMonths - 1 : selectedMonths - monthDiff
    const initDate = new Date(initialValue.xValue)

    if (selectedTimePeriod === CPITimePeriod.Month) {
      const full = 35
      const existingStripes = Math.ceil(values.length / 7)
      const stripesGap = 5 - existingStripes

      if (stripesGap <= 0) return values
      const startValue = values[0].xValue
      const length = values.length
      const remainder = full - length
      const start = dayjs(startValue).subtract(remainder, 'days')
      const formattedStart = dayjs(start).format('YYYY-MM-DD')
      const formattedEnd = dayjs(subtractedDate).format('YYYY-MM-DD')
      const result = generateStringDatesArray(formattedStart, formattedEnd)
      const res = result.map((n) => ({ ...n, xValue: n.xValue.slice(0, 10) }))

      return [...res, ...values].sort((a, b) => (new Date(b.xValue).getTime() >= new Date(a.xValue).getTime() ? -1 : 1))
    } else {
      const dataUntilMonths = initDate.getMonth() - fakeMonthsGap
      const startDate: Date = new Date(initDate.getFullYear(), dataUntilMonths, new Date().getDate())
      const endDate: Date = new Date(subtractedDate)
      dummyArray = generateDatesArray(startDate, endDate)
    }
  }
  ;(values as any) = [...dummyArray, ...values].map((n) => ({
    ...n,
    xValue: dayjs(n.xValue).format(dateFormat.customLabel)
  }))

  return values
}

export interface TableSortingOptions {
  label: string
  value: 'ascending' | 'descending'
}

export type TableSortingOptionValue = '' | 'ascending' | 'descending'

export const getIntegrationLogo = (name: string, type?: string) => {
  if (type === 'rounded') {
    return `/icons/rounded/${name}_icon.svg`
  }
  if (type === 'square') {
    if (name.includes('rapid7')) {
      return `/icons/square/rapid7_square.png`
    }
    return `/icons/square/${name}_icon.svg`
  }
  if (name) {
    return `/icons/circle/${name}_icon.svg`
  }

  return '/icons/circle/crowdstrike_falcon_icon.svg'
}

export const formatAnalysisData = (
  analysisData: AnalysisDataResponse | null,
  instances: IntegrationInstance[],
  firstSource: string,
  secondSource: string
) => {
  if (analysisData) {
    const arr: AnalysisDataItem[] = []

    for (const [key, value] of Object.entries(analysisData)) {
      if (!value || !key) continue

      if (key === 'both') {
        const n = value as unknown as AnalysisDataResponseBoth[]
        const firstSourceInstance = instances.find((instance) => instance.id === firstSource)
        const secondSourceInstance = instances.find((instance) => instance.id === secondSource)

        n.forEach((val) => {
          let firstSourcePartId = ''
          if (firstSourceInstance) {
            const part = firstSourceInstance.id.split('-')
            firstSourcePartId = part[0]
          }
          let secondSourcePartId = ''
          if (secondSourceInstance) {
            const part = secondSourceInstance.id.split('-')
            secondSourcePartId = part[0]
          }
          const firstSelector = `${firstSourceInstance?.integration_name}_${firstSourcePartId}`
          const secondSelector = `${secondSourceInstance?.integration_name}_${secondSourcePartId}`
          const firstPart = val[firstSelector]
          const secondPart = val[secondSelector]

          if (firstSourceInstance && secondSourceInstance) {
            arr.push({
              ...val,
              coverage: {
                integrationName: 'Both',
                instanceName: '',
                src: [
                  getIntegrationLogo(firstSourceInstance?.integration_name),
                  getIntegrationLogo(secondSourceInstance?.integration_name)
                ]
              },
              serial_number: firstPart ? firstPart.serial_number : '',
              original_serial_number1: firstPart ? firstPart.serial_number : '',
              original_serial_number2: secondPart ? secondPart.serial_number : '',
              host_name1: firstPart ? firstPart.host_name : '',
              host_name2: secondPart ? secondPart.host_name : '',
              last_seen1: firstPart ? firstPart.last_seen : '',
              last_seen2: secondPart ? secondPart.last_seen : '',
              operating_system1: firstPart ? firstPart.operating_system : '',
              operating_system2: secondPart ? secondPart.operating_system : '',
              mac_address1: firstPart ? firstPart.mac_address : '',
              mac_address2: secondPart ? secondPart.mac_address : '',
              key: 'both'
            })
          }
        })
      } else {
        if (key === firstSource) {
          value.forEach((val) => {
            const firstSourceInstance = instances.find((instance) => instance.id === firstSource)

            if (firstSourceInstance) {
              const test = {
                coverage: {
                  integrationName: `${firstSourceInstance.integration_name} Only`,
                  instanceName: firstSourceInstance.name,
                  src: [getIntegrationLogo(firstSourceInstance.integration_name)]
                },
                serial_number: val.serial_number,
                original_serial_number1: val.serial_number,
                original_serial_number2: '',
                host_name1: val.host_name,
                host_name2: '',
                last_seen1: val.last_seen,
                last_seen2: '',
                operating_system1: val.operating_system,
                operating_system2: '',
                mac_address1: val.mac_address || '',
                mac_address2: '',
                key: 'first'
              }
              arr.push(test)
            }
          })
        }
        if (key === secondSource) {
          value.forEach((val) => {
            const secondSourceInstance = instances.find((instance) => instance.id === secondSource)

            if (secondSourceInstance) {
              arr.push({
                ...val,
                coverage: {
                  integrationName: `${secondSourceInstance.integration_name} Only`,
                  instanceName: secondSourceInstance.name,
                  src: [getIntegrationLogo(secondSourceInstance?.integration_name || '')]
                },
                serial_number: val.serial_number,
                original_serial_number1: '',
                original_serial_number2: val.serial_number,
                host_name1: '',
                host_name2: val.host_name,
                last_seen1: '',
                last_seen2: val.last_seen,
                operating_system1: '',
                operating_system2: val.operating_system,
                mac_address1: '',
                mac_address2: val.mac_address || '',
                key: 'second'
              })
            }
          })
        }
      }
    }

    return arr
  }

  return []
}

export const downloadFile = (data: string, fileName: string) => {
  const url = window.URL.createObjectURL(new Blob([data]))
  const link = document.createElement('a')
  link.href = url
  link.setAttribute('download', fileName)
  document.body.appendChild(link)
  link.click()
  link.remove()
}

export const getInstanceNamesForCsv = (instances: IntegrationInstance[]) => {
  const firstNameParts = instances[0].name.split('_')
  const firstName = firstNameParts.map((c) => c.charAt(0).toUpperCase() + c.slice(1)).join('')
  const secondNameParts = instances[1].name.split('_')
  const secondName = secondNameParts.map((c) => c.charAt(0).toUpperCase() + c.slice(1)).join('')

  return [firstName, secondName]
}

export const getDownloadCsvProps = (instances: IntegrationInstance[]) => {
  const [firstName, secondName] = getInstanceNamesForCsv(instances)

  return `OnyxiaACA_${firstName}_${secondName}_${dayjs().format('YYYY_MM_DD_HH_mm_ss')}.csv`
}
