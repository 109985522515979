import { FC, SyntheticEvent, useState } from 'react'
import { AccordionSummary } from '@mui/material'
import { ExpandMore } from '@mui/icons-material'
import { ToastSuccessIcon, ToastErrorIcon } from '../../../svg'
import {
  CrowdstrikeAccordion,
  CrowdstrikeToastTitle,
  CrowdstrikeToastWrapper,
  CrowdstrikeToastMessage,
  CrowdstrikeAccordionDetails
} from './crowdstrikeIntegration.styles'

interface Props {
  variant: 'success' | 'error'
  title: string
  message: string
  sx?: Record<string, unknown>
}

const CrowdstrikeIntegrationToast: FC<Props> = ({ variant, message, title, sx }) => {
  const [expanded, setExpanded] = useState<string | false>(false)

  const handleChange = (panel: string) => (event: SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false)
  }

  return (
    <CrowdstrikeToastWrapper success={variant === 'success'} sx={sx}>
      {variant === 'success' ? <ToastSuccessIcon /> : <ToastErrorIcon />}
      <CrowdstrikeAccordion
        success={variant === 'success'}
        expanded={expanded === 'panel1'}
        onChange={handleChange('panel1')}
        isopen={expanded}
      >
        <AccordionSummary expandIcon={<ExpandMore />} aria-controls="panel1-content" id="panel1-header">
          <CrowdstrikeToastTitle success={variant === 'success'}>{title}</CrowdstrikeToastTitle>
          {!expanded && (
            <CrowdstrikeToastMessage success={variant === 'success'}>Click to expand</CrowdstrikeToastMessage>
          )}
        </AccordionSummary>
        <CrowdstrikeAccordionDetails>
          <CrowdstrikeToastMessage success={variant === 'success'}>{message}</CrowdstrikeToastMessage>
        </CrowdstrikeAccordionDetails>
      </CrowdstrikeAccordion>
    </CrowdstrikeToastWrapper>
  )
}

export default CrowdstrikeIntegrationToast
