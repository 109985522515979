import { FC } from 'react'
import { Box, Typography } from '@mui/material'

/* Utils */
import { CompetitorAnalysisData, CompetitorAnalysisPerformanceMetric } from '../../../../../../../models'
import { useAppSelector } from '../../../../../../../store/hooks'
import { accountSettingsSelector } from '../../../../../../../store/user/userSlice'

/* Components */
import { DetailedComparisonWrapper } from './detailedComparison.styles'
import { AnalysisGroupsWrapper, CompetitorAnalysisLegend } from './competitorAnalysis.styles'
import CompetitorAnalysisItem from './competitor-analysis-components/CompetitorAnalysisItem'

interface Props {
  data: CompetitorAnalysisData
  sla?: number
  unit: string
  active: boolean
  showMessage: boolean
  healthDirection: string
}

const CompetitorAnalysis: FC<Props> = ({ data, sla, unit, active, healthDirection, showMessage }) => {
  const accountSettings = useAppSelector(accountSettingsSelector)

  const industrySortFunc = (a: CompetitorAnalysisPerformanceMetric, b: CompetitorAnalysisPerformanceMetric) => {
    return a.industry_name < b.industry_name ? -1 : 0
  }

  return (
    <DetailedComparisonWrapper>
      <Typography className="detailed-comparison-title">Competitor Analysis</Typography>
      <Typography className="detailed-comparison-description">
        Deep dive into SLA data from the highest or lowest performers in each industry:
      </Typography>
      <CompetitorAnalysisLegend>
        <Box className="legend-item">
          <Box className="legend-color blue" />
          <Typography className="legend-text">Low Performers Range</Typography>
        </Box>
        <Box className="legend-item">
          <Box className="legend-color purple" />
          <Typography className="legend-text">Top Performers Range</Typography>
        </Box>
        <Box className="legend-item">
          <Box className="legend-color white" />
          <Typography className="legend-text">Industry Average</Typography>
        </Box>
        <Box className="legend-item">
          <Box className="legend-color orange" />
          <Typography className="legend-text">{accountSettings?.name}</Typography>
        </Box>
      </CompetitorAnalysisLegend>

      {showMessage ? (
        <Box className="no-category">
          <img src="/info-panel.svg" alt="info" />
          This CPI is recalculating to reflect an updated SLA; your company data will display momentarily.
        </Box>
      ) : (
        ''
      )}

      <AnalysisGroupsWrapper>
        {data.performance_metrics.sort(industrySortFunc).map((item) => (
          <CompetitorAnalysisItem
            key={item.industry_id}
            item={item}
            sla={sla}
            unit={unit}
            active={active}
            name={data.cpi_name}
            healthDirection={healthDirection}
          />
        ))}
      </AnalysisGroupsWrapper>
    </DetailedComparisonWrapper>
  )
}

export default CompetitorAnalysis
