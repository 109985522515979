import React, { Dispatch, FC, Ref, SetStateAction, SyntheticEvent, useRef, useState } from 'react'
import { Box, Button, DialogActions, OutlinedInput, Tab, Typography } from '@mui/material'
import { useLocation } from 'react-router-dom'
import { LocalizationProvider } from '@mui/x-date-pickers-pro'
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker'
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs'
import dayjs, { Dayjs } from 'dayjs'
import {
  AuditLogFormControl,
  AuditLogSelect,
  CategoryMenuProps
} from '../../library/library-details/sections/trends-tab/components/TrendsChartSelectField.styles'

/* Utils */
import { a11yProps } from '../../library/library-details/components/LibraryDetailsTabPanel'

/* Components */
import { DateRangePickerContainer, HeaderContainer } from './insightsHeader.styles'
import { LibraryDetailsHoverTooltip } from '../../library/library-details/components/LibraryDetailsTooltip'
import { IntegrationsTabs } from '../../settings/integrations/integrations.styles'
import InsightsSearch from './InsightsSearch'
import OnyxAISelect from './OnyxAISelect'
import { LabelOption } from '../../library/components/LibrarySelectLabelField'
import { dateRangePickerPopperSx } from '../onyxAIInsights.styles'
import { SelectDropdownIcon } from '../../../components/svg'
import { dateFormat } from '../../../../lib/utils'
import ToolbarComponent from '../../../components/header/ToolbarComponent'
import { ClearFilters } from '../../library/library.styles'
import ClearIcon from '../../../components/svg/ClearIcon'

interface Props {
  tabValue: number
  handleTabChange: (event: SyntheticEvent, newValue: number) => void
  title: string
  tabLabels: string[]
  parents?: string[]
  hideIntegrationsTabs?: boolean
  loading?: boolean
  handleSearch: any
  search: string
  setSearch: any
  dateValue: [Dayjs | string, Dayjs | string]
  setDateValue: Dispatch<SetStateAction<[Dayjs | string, Dayjs | string]>>
  sourceOptions: LabelOption[]
  source: string
  setSource: Dispatch<SetStateAction<string>>
  handleChangeSource: (newSource: string) => void
  handleChangeDate: (newDateValue: [Dayjs, Dayjs]) => void
  handleClear: () => void
}

const InsightsHeader: FC<Props> = ({
  tabValue,
  handleTabChange,
  title,
  loading,
  tabLabels,
  hideIntegrationsTabs,
  search,
  setSearch,
  handleSearch,
  dateValue,
  setDateValue,
  sourceOptions,
  source,
  setSource,
  handleChangeSource,
  handleChangeDate,
  handleClear
}) => {
  const location = useLocation()
  const [open, setOpen] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null)
  const c1 = dayjs(dateValue[0]).format(dateFormat.customLabel)
  const c2 = dayjs(dateValue[1]).format(dateFormat.customLabel)
  const n1 = c1.slice(5, 7)
  const n2 = c2.slice(5, 7)
  const isSame = dayjs(dateValue[0]).format('MMM DD') === dayjs(dateValue[1]).format('MMM DD')
  const t2 = !isSame ? dayjs(dateValue[1]).format(n1 === n2 ? 'DD, YYYY' : 'MMM DD, YYYY') : null
  const t1 = t2 ? dayjs(dateValue[0]).format('MMM DD') : dayjs(dateValue[0]).format('MMM DD, YYYY')
  const ref = useRef()
  const inputRef = useRef<Ref<HTMLInputElement> | undefined>()

  const areFiltersClearable = () => {
    return search.length || source || dateValue.filter(Boolean).length
  }

  return (
    <ToolbarComponent>
      <HeaderContainer>
        <Box className="header-content-container">
          <Box className="search-container">
            <InsightsSearch search={search} setSearch={setSearch} handleSearch={handleSearch} placeholder="Search..." />
          </Box>

          <Box className="date-select-container">
            <OnyxAISelect
              label="Type"
              options={sourceOptions}
              placeholder="None"
              value={source}
              handleChange={(e: any) => {
                handleChangeSource(e.target.value)
              }}
              handleReset={() => setSource('')}
              iscustom="true"
            />

            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DateRangePickerContainer className="date-range-picker-container" ref={ref}>
                <Typography className="label-text">Date</Typography>

                <AuditLogFormControl
                  is_opened={anchorEl ? 'true' : 'false'}
                  isselected={Boolean(anchorEl) || (!!dateValue[0] && !!dateValue[1]) ? 'true' : 'false'}
                  is_disabled="false"
                >
                  <AuditLogSelect
                    isselected={Boolean(anchorEl) || (!!dateValue[0] && !!dateValue[1]) ? 'true' : 'false'}
                    labelId="insights-date-select-label"
                    id="insights-date-select"
                    displayEmpty
                    MenuProps={{
                      ...CategoryMenuProps,
                      PaperProps: {
                        ...CategoryMenuProps.PaperProps,
                        sx: {
                          ...CategoryMenuProps.PaperProps.sx,
                          display: 'none'
                        },
                        style: { maxHeight: 'unset' }
                      },
                      anchorEl,
                      open: Boolean(anchorEl),
                      onClose: () => setAnchorEl(null)
                    }}
                    sx={{
                      '& .MuiSelect-icon': { transform: anchorEl ? 'rotate(180deg)' : 'rotate(0deg)' }
                    }}
                    IconComponent={(props) => <SelectDropdownIcon {...props} />}
                    renderValue={(selected) => {
                      if (Array.isArray(selected)) {
                        const pruned = selected.filter((s: string) => s !== '')
                        if (pruned.length === 0) {
                          return 'None'
                        }
                        if (pruned.length === 1) {
                          return pruned[0]
                        }

                        return `${pruned[0]} +${pruned.length - 1}`
                      }
                      if ((selected as string).includes('Invalid Date')) return 'None'

                      return selected || 'None'
                    }}
                    inputProps={{ 'aria-label': 'Without label' }}
                    value={`${t2 ? `${t1}-${t2}` : `${t1}`}`}
                    input={
                      <OutlinedInput
                        ref={inputRef}
                        onMouseDown={(e: any) => {
                          setOpen(!open)
                          setAnchorEl((e as any).currentTarget)
                        }}
                      />
                    }
                  />
                </AuditLogFormControl>
                <DateRangePicker
                  calendars={1}
                  value={typeof dateValue[0] === 'string' ? [dayjs(), dayjs()] : (dateValue as [Dayjs, Dayjs])}
                  disableHighlightToday={true}
                  open={open}
                  onChange={(newValue) => {
                    setDateValue(newValue as [Dayjs, Dayjs])
                  }}
                  disableFuture
                  inputRef={inputRef.current}
                  onAccept={(val) => {
                    handleChangeDate(val as [Dayjs, Dayjs])
                    return val
                  }}
                  slots={{
                    actionBar: () => (
                      <DialogActions>
                        <Button
                          aria-expanded={open ? 'true' : undefined}
                          onClick={() => {
                            setAnchorEl(null)
                            setOpen(false)
                          }}
                        >
                          Cancel
                        </Button>
                        <Button
                          aria-expanded={open ? 'true' : undefined}
                          onClick={() => {
                            setAnchorEl(null)
                            setOpen(false)
                            handleSearch()
                          }}
                        >
                          Ok
                        </Button>
                      </DialogActions>
                    ),
                    field: () => <></>
                  }}
                  slotProps={{
                    actionBar: {
                      actions: ['cancel', 'accept']
                    },
                    popper: {
                      sx: { ...dateRangePickerPopperSx },
                      anchorEl
                    }
                  }}
                />
              </DateRangePickerContainer>
            </LocalizationProvider>

            <ClearFilters onClick={handleClear} disabled={!areFiltersClearable()}>
              <ClearIcon />
              Clear
            </ClearFilters>
          </Box>
        </Box>

        {!hideIntegrationsTabs && (
          <IntegrationsTabs
            value={tabValue}
            onChange={handleTabChange}
            aria-label={`${title} tabs`}
            TabIndicatorProps={{
              style: { display: 'none' }
            }}
            isdetails="true"
          >
            {tabLabels.map((label, index) => {
              if (label === 'Data Sources' && location.pathname === '/asset-coverage-analyzer') {
                return (
                  <LibraryDetailsHoverTooltip key={index} title="Enabled data sources to analyze" arrow>
                    <Tab label={label} {...a11yProps(index)} />
                  </LibraryDetailsHoverTooltip>
                )
              }

              return <Tab label={label} {...a11yProps(index)} key={index} disabled={loading} />
            })}
          </IntegrationsTabs>
        )}
      </HeaderContainer>
    </ToolbarComponent>
  )
}

export default InsightsHeader
