import { Box, Typography } from '@mui/material'
import styled from '@emotion/styled/macro'

export const DeleteInstanceWrapper = styled(Box)<{ step: number }>`
  background: linear-gradient(0deg, rgba(255, 169, 252, 0.11), rgba(255, 169, 252, 0.11)), #1e1a1d;
  border-radius: 8px;
  padding: 24px 24px 18px;

  ${({ step }) =>
    step === 2 &&
    `
     width: 506px;
     margin: 0 auto;
  `};

  .input-wrapper {
    font-family: 'Quicksand', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.5px;
    color: #e9e0e4;
  }

  .cpi-wrapper {
    display: flex;
    align-items: center;
    gap: 16px;
    border-radius: 16px;
    background: linear-gradient(0deg, rgba(255, 169, 252, 0.11) 0%, rgba(255, 169, 252, 0.11) 100%);
    padding: 12px 16px;
    margin-bottom: 24px;

    .cpi-name {
      border-radius: 18px;
      background: #debdd9;
      font-family: 'Quicksand', sans-serif;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 16px;
      letter-spacing: 0.5px;
      color: #3f293e;
      padding: 2px 8px;
    }

    .cpi-title {
      max-width: 228px;
      font-family: 'Quicksand', sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0.5px;
      color: white;
      display: block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .cpi-desc {
      font-family: 'Quicksand', sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0.5px;
      color: white;
      margin-left: 22px;
    }
  }

  .instance-wrapper {
    border-radius: 16px;
    background: linear-gradient(0deg, rgba(255, 169, 252, 0.11) 0%, rgba(255, 169, 252, 0.11) 100%);

    .instance-block {
      display: flex;
      align-items: center;
      gap: 16px;
      padding: 8px 16px;
      border-bottom: 1px solid #4d444b;

      &:last-child {
        border-bottom: none;
      }

      .instance-name {
        width: 220px;
        color: white;
        font-family: 'Quicksand', sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0.5px;
        display: flex;
        align-items: center;
        gap: 16px;
      }

      .instance-status {
        width: 80px;
        font-family: 'Quicksand', sans-serif;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
        letter-spacing: 0.4px;
        display: flex;
        align-items: center;
        gap: 6px;
      }

      .instance-desc {
        font-family: 'Quicksand', sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0.5px;
      }
    }
  }
`

export const ImgWrapper = styled(Box)<{ isActive: boolean }>`
  img {
    border-radius: 100%;
    border: ${({ isActive }) => (isActive ? '1px solid #AED581' : '1px solid #998D96')};
  }
`

export const CircleStatus = styled(Box)<{ isActive: boolean }>`
  width: 8px;
  height: 8px;
  display: flex;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.3);
  border-radius: 100%;
  background: ${({ isActive }) => (isActive ? '#AED581' : '#998D96')};
`

export const DeleteInstanceTitle = styled(Typography)`
  font-family: 'Quicksand', sans-serif;
  font-weight: 400;
  font-size: 24px;
  line-height: 32px;
  color: #e9e0e4;
  mix-blend-mode: normal;
  margin-bottom: 12px;
`

export const DeleteInstanceSubtitle = styled(Typography)`
  font-family: 'Quicksand', sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.25px;
  color: #e9e0e4;
  mix-blend-mode: normal;
  margin-bottom: 16px;
`

export const DeleteInstanceActionsWrapper = styled(Box)`
  display: flex;
  justify-content: flex-end;
  margin-top: 28px;
  margin-bottom: 8px;

  .MuiCircularProgress-root {
    color: #690005;
  }

  .MuiButtonBase-root:first-of-type {
    margin-right: 8px;
  }

  .MuiButtonBase-root:last-of-type {
    background: #ffb4ab;
    color: #690005;

    svg {
      path {
        fill: #690005;
      }
    }

    &.Mui-disabled {
      background: rgba(227, 227, 227, 0.12);
      color: #e9e0e4;

      svg {
        path {
          fill: #e9e0e4;
        }
      }
    }
  }
`
