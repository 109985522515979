import { Box, Typography } from '@mui/material'
import styled from '@emotion/styled/macro'

export const LoadingDeleteInstanceWrapper = styled(Box)`
  background: linear-gradient(0deg, rgba(255, 169, 252, 0.05), rgba(255, 169, 252, 0.05)), #1e1a1d;
  padding: 28px;
  min-height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const DeleteInstanceWrapper = styled(Box)`
  background: linear-gradient(0deg, rgba(255, 169, 252, 0.05), rgba(255, 169, 252, 0.05)), #1e1a1d;
  padding: 28px;
`

export const DeleteInstanceTitle = styled(Typography)`
  font-family: 'Quicksand', sans-serif;
  font-weight: 400;
  font-size: 24px;
  line-height: 32px;
  color: #e9e0e4;
  margin-bottom: 10px;
`

export const DeleteInstanceSubtitle = styled(Typography)`
  font-family: 'Quicksand', sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.5px;
  color: #e9e0e4;
  margin-bottom: 26px;
`

export const InfoTooltipWrapper = styled(Box)`
  .header {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-bottom: 3px;

    .title {
      font-family: 'Quicksand', sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0.5px;
      color: white;
    }
  }

  .description {
    font-family: 'Quicksand', sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.4px;
    color: white;
  }

  .small {
    width: 220px;
  }
`

export const CPILoadingWrapper = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`

export const DeleteInstanceCPICardWrapper = styled(Box)<{ noBg: boolean }>`
  width: 100%;
  border-radius: 16px;
  background: ${({ noBg }) =>
    noBg ? '' : 'linear-gradient(0deg, rgba(255, 169, 252, 0.11) 0%, rgba(255, 169, 252, 0.11) 100%)'};
  margin-bottom: 29px;
`

export const DeleteInstanceCPICard = styled(Box)<{ justifyCenter: boolean; step: number }>`
  display: flex;
  align-items: center;
  border-bottom: 1px solid #4d444b;
  padding: 13px;
  justify-content: ${({ justifyCenter }) => (justifyCenter ? 'flex-start' : ' space-between')};

  &:last-child {
    border-bottom: none;
  }

  .cpi {
    border-radius: 18px;
    background: #debdd9;
    box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.15), 0px 1px 2px 0px rgba(0, 0, 0, 0.3);
    padding: 2px 8px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-family: 'Quicksand', sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0.5px;
    color: #3f293e;
    margin-left: 16px;
    white-space: nowrap;
    margin-right: 18px;
  }

  .cpi-empty {
    width: 100px;
  }

  .name {
    font-family: 'Quicksand', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.5px;
    color: white;
    width: ${({ step }) => (step === 1 ? '405px' : '')};
  }

  .label {
    font-family: 'Quicksand', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.1px;
    color: white;
    margin-left: 4px;
    margin-right: 16px;
    white-space: nowrap;
  }

  .disabled-label {
    opacity: 0.2;
  }
`

export const CPIChip = styled(Box)<{ justifyCenter: boolean }>`
  display: flex;
  align-items: center;
  justify-content: ${({ justifyCenter }) => (justifyCenter ? 'flex-start' : ' space-between')};
  margin-bottom: 14px;

  &:last-child {
    margin-bottom: 0;
  }

  .cpi {
    border-radius: 18px;
    background: #debdd9;
    box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.15), 0px 1px 2px 0px rgba(0, 0, 0, 0.3);
    padding: 2px 8px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-family: 'Quicksand', sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0.5px;
    color: #3f293e;
    white-space: nowrap;
    margin-right: 10px;
  }

  .name {
    font-family: 'Quicksand', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.5px;
    color: white;
    width: 405px;
  }

  .label {
    font-family: 'Quicksand', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.1px;
    color: white;
    margin-left: 4px;
    margin-right: 16px;
    white-space: nowrap;
  }

  .disabled-label {
    opacity: 0.2;
  }
`

export const DeleteInstanceActionsWrapper = styled(Box)`
  display: flex;
  justify-content: flex-end;
  margin-top: 12px;
  margin-bottom: 8px;

  .MuiCircularProgress-root {
    color: #690005;
  }

  .MuiButtonBase-root:first-of-type {
    margin-right: 8px;
  }

  .MuiButtonBase-root:last-of-type {
    background: #ffb4ab;
    color: #690005;

    svg {
      path {
        fill: #690005;
      }
    }
  }
`
