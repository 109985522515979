import { Box } from '@mui/material'
import styled from '@emotion/styled/macro'

export const Container = styled(Box)`
  position: relative;
  display: flex;
  align-items: center;
  gap: 24px;
  flex-basis: 50%;
  width: 100%;
`

export const DateRangePickerContainer = styled(Box)`
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 4px;
  width: 100%;

  .label-text {
    overflow: hidden;
    color: #fff;
    text-overflow: ellipsis;
    font-family: Quicksand, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .text-field {
    border-radius: 4px;
    border: 1px solid #998d96;
    display: flex;
    height: 40px;
    align-items: center;
    gap: 8px;
    align-self: stretch;
    overflow: hidden;
    //width: 341px;
    width: 100%;
    padding: 0;

    .MuiOutlinedInput-root {
      //width: 341px;
      width: 100%;
      height: 40px;
      overflow: hidden;
      color: #fff;
      text-overflow: ellipsis;
      font-family: Quicksand, sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      padding-right: 8px;

      input {
        width: 100%;
        padding-left: 8px;
      }
      flex-grow: 1;

      .calendar-icon {
        cursor: pointer;
      }
    }

    fieldset {
      border: none;
    }
  }

  .calendar-container {
    background: linear-gradient(0deg, rgba(255, 169, 252, 0.11), rgba(255, 169, 252, 0.11)), #1e1a1d;
    box-shadow: 0 4px 8px 3px rgba(0, 0, 0, 0.15), 0 1px 3px rgba(0, 0, 0, 0.3);
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    z-index: 1000000000;
    top: 80px;
    position: absolute;
  }

  .calendar-header {
    background: linear-gradient(0deg, rgba(255, 169, 252, 0.12), rgba(255, 169, 252, 0.12)), #1e1a1d;
    box-shadow: 0 6px 10px 4px rgba(0, 0, 0, 0.15), 0 2px 3px rgba(0, 0, 0, 0.3);
    border-radius: 4px 4px 0 0;

    .calendar-header__inner {
      display: flex;
      justify-content: space-around;
      align-items: center;
      max-width: 679px;
      padding: 16px 24px;

      fieldset {
        border: 1px solid #998d96;
        border-radius: 4px;
      }

      input {
        font-family: 'Quicksand', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.5px;
        color: #d0c3cc;
        -webkit-text-fill-color: unset;
      }

      label {
        font-family: 'Quicksand', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 23px;
        letter-spacing: 0.4px;
        color: #d0c3cc;
      }
    }
  }

  .calendar-content {
    display: flex;
    align-items: flex-start;

    .MuiPickerStaticWrapper-content {
      width: 660px;
      border-radius: unset;
      background: transparent;

      .MuiDateRangePickerViewDesktop-container {
        width: 330px;

        /* Month Selector */

        .MuiPickersArrowSwitcher-root {
          button svg {
            fill: #d0c3cc;
          }

          .MuiTypography-root {
            font-family: 'Quicksand', sans-serif;
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            letter-spacing: 0.1px;
            color: #d0c3cc;
          }
        }

        &.MuiDateRangePickerViewDesktop-container {
          border-right: 1px solid #444746;
        }

        .MuiDayPicker-weekContainer {
          .MuiDateRangePickerDay-root:first-of-type:not(.MuiDateRangePickerDay-rangeIntervalDayHighlightStart, .MuiDateRangePickerDay-rangeIntervalDayHighlightEnd),
          .MuiDateRangePickerDay-root:last-of-type {
            border-radius: 0 !important;
          }
        }
      }
    }

    .MuiDateRangePickerDay-root {
      .MuiButtonBase-root.MuiPickersDay-root {
        font-family: 'Quicksand', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.25px;
        color: #e9e0e4;

        &.MuiPickersDay-dayOutsideMonth {
          color: #4d444b;
        }
      }

      &.MuiDateRangePickerDay-rangeIntervalDayHighlight {
        background: #573f56;

        .MuiButtonBase-root.MuiPickersDay-root {
          color: #d0c3cc;
        }

        &.MuiDateRangePickerDay-rangeIntervalDayHighlightStart,
        &.MuiDateRangePickerDay-rangeIntervalDayHighlightEnd {
          .MuiDateRangePickerDay-rangeIntervalPreview {
            .MuiButtonBase-root.MuiPickersDay-root {
              background: #ffa9fc;
              color: #590060;

              &.MuiPickersDay-today {
                border: 1px solid #ffa9fc;
                color: #ffa9fc;
              }
            }
          }
        }
      }

      .MuiButtonBase-root.MuiPickersDay-root.MuiPickersDay-today {
        border: 1px solid #ffa9fc;
      }
    }

    .calendar-picker-wrapper {
      width: 140px;
    }

    .range-selector-wrapper {
      display: flex;
      flex-flow: column wrap;
      align-items: flex-start;
      margin-top: 5px;

      & > .MuiButtonBase-root {
        padding: 4px 24px 4px 16px;
        width: 100%;
        height: 48px;
        background: transparent;
        font-family: 'Quicksand', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.5px;
        text-transform: none;
        justify-content: flex-start;
        color: #e6e1e5;
      }
    }
  }

  .calendar-footer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 9px 12px 11px 0;
    border-top: 1px solid #444746;

    & > .MuiButtonBase-root {
      min-width: 69px;
      min-height: 40px;
      border-radius: 100px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: transparent;
      gap: 8px;
      font-family: 'Quicksand', sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.1px;
      color: #ffa9fc;
      text-transform: none;

      &:first-of-type {
        margin-right: 8px;
      }

      &:nth-of-type(2) {
        width: 45px;
        min-width: unset;
      }
    }
  }
`

export const dateRangePickerPopperSx = {
  '.MuiPaper-root': {
    // position: 'absolute',
    display: 'flex',
    background: 'transparent',

    '@media (max-height: 800px)': {
      '.MuiDateCalendar-root': {
        height: '280px !important',
        maxHeight: '280px !important'
      }
    },

    '.MuiPickersToolbar-root': {
      display: 'none'
    },
    '.MuiPickersLayout-root': {
      borderRadius: '8px',
      background: '#573f56',
      display: 'flex',
      flexDirection: 'column',

      '.MuiPickersYear-root button': {
        background: 'transparent',
        color: '#D0C3CC',

        '&[aria-checked="true"]': {
          background: '#FFA9FC',
          color: '#66006E'
        },

        '&:disabled': {
          color: '#998D96'
        }
      },
      '.MuiYearCalendar-root': {
        '::-webkit-scrollbar': {
          width: '6px !important',
          background: '#998d96'
        },

        '::-webkit-scrollbar-track': {
          width: '6px !important',
          background: '#998d96',
          borderRadius: '4px'
        },

        '::-webkit-scrollbar-thumb': {
          width: '6px !important',
          background: '#ffa9fc',
          borderRadius: '8px'
        }
      },
      '.MuiPickersCalendarHeader-root': {
        '.MuiPickersCalendarHeader-switchViewIcon': {
          color: '#FFA9FC'
        },
        '.MuiPickersCalendarHeader-labelContainer': {
          '.MuiPickersCalendarHeader-label': {
            color: '#d0c3cc',
            textAlign: 'center',
            fontFamily: "'Quicksand', sans-serif",
            fontSize: '14px',
            fontStyle: 'normal',
            fontWeight: 500,
            lineHeight: '20px',
            letterSpacing: '0.1px'
          }
        },

        '.MuiPickersArrowSwitcher-root': {
          '.MuiButtonBase-root': {
            color: '#d0c3cc',

            '&.Mui-disabled': {
              opacity: 0.26
            }
          }
        }
      },

      '@media (max-height: 800px)': {
        '.MuiPickersSlideTransition-root.MuiDayCalendar-slideTransition': {
          minHeight: '192px !important'
        }
      },

      '.MuiDayCalendar-root': {
        '.MuiDayCalendar-header': {
          '.MuiTypography-root': {
            color: '#e9e0e4',
            textAlign: 'center',
            fontFamily: "'Quicksand', sans-serif",
            fontSize: '12px',
            fontStyle: 'normal',
            fontWeight: 500,
            lineHeight: '16px',
            letterSpacing: '0.5px'
          }
        },

        '.MuiDayCalendar-monthContainer': {
          '.MuiDayCalendar-weekContainer': {
            '.MuiDateRangePickerDay-hiddenDayFiller': {
              background: 'transparent !important'
            }
          },

          '.MuiDateRangePickerDay-root': {
            '.MuiButtonBase-root': {
              color: '#e9e0e4',
              textAlign: 'center',
              fontFamily: "'Quicksand', sans-serif",
              fontSize: '14px',
              fontStyle: 'normal',
              fontWeight: 400,
              lineHeight: '20px',
              letterSpacing: '0.25px',

              '&.Mui-selected': {
                borderRadius: '50%',
                color: '#590060',
                background: '#ffa9fc'
              },

              '&.Mui-disabled': {
                color: '#d0c3cc',
                opacity: 0.38
              }
            },

            '&.MuiDateRangePickerDay-root.MuiDateRangePickerDay-rangeIntervalDayHighlight': {
              background: 'rgba(106, 83, 113, 1)'
            }
          }
        }
      },

      '.MuiDialogActions-root': {
        '.MuiButton-root': {
          color: '#ffa9fc',
          textAlign: 'center',
          fontFamily: "'Quicksand', sans-serif",
          fontSize: '14px',
          fontStyle: 'normal',
          fontWeight: 500,
          lineHeight: '20px',
          letterSpacing: '0.1px',

          '&:first-of-type': {
            textTransform: 'capitalize'
          }
        }
      }
    }
  },

  '.MuiPaper-root.MuiPickersPopper-paper': {
    borderRadius: '16px',
    background: '#573F56',

    '.MuiPickersArrowSwitcher-root': {
      '.MuiButtonBase-root': {
        color: '#D0C3CC'
      }
    },

    '.MuiPickersCalendarHeader-label': {
      color: '#D0C3CC',
      fontFamily: '"Quicksand",sans-serif',
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: 500,
      lineHeight: '20px',
      letterSpacing: '0.1px'
    },

    '.MuiDayCalendar-header': {
      '.MuiTypography-root.MuiTypography-caption.MuiDayCalendar-weekDayLabel': {
        color: '#D0C3CC',
        textAlign: 'center',
        fontFamily: '"Quicksand",sans-serif',
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: '24px',
        letterSpacing: '0.1px'
      }
    },

    '.MuiButtonBase-root.MuiPickersDay-root': {
      color: '#D0C3CC',
      textAlign: 'center',
      fontFamily: '"Quicksand",sans-serif',
      fontSize: '16px',
      fontStyle: 'normal',
      fontWeight: 500,
      lineHeight: '24px',
      letterSpacing: '0.1px',

      '&.Mui-selected': {
        background: '#FFA9FC',
        color: '#66006E'
      },
      '&:not(.Mui-selected)': {
        '&:hover': {
          background: '#867083',
          borderColor: '#867083'
        }
      },
      '&.MuiPickersDay-today': {
        background: 'transparent',
        border: '1px solid #FFA9FC',
        color: '#FFA9FC'
      },
      '&.Mui-disabled': {
        color: '#998D96'
      }
    },

    '.MuiButton-root': {
      color: '#ffa9fc',
      fontFamily: 'Quicksand',
      fontSize: '14px',
      lineHeight: '20px',
      letterSpacing: '0.1px',
      margin: '0'
    }
  }
}
