import React from 'react'
import App from './ui/App/App'
import './styles/index.css'
import './styles/typography.css'
import reportWebVitals from './reportWebVitals'
import { BrowserRouter as Router } from 'react-router-dom'
import { FronteggProvider } from '@frontegg/react'
import * as ReactDOMClient from 'react-dom/client'
import { Provider } from 'react-redux'
import { store } from './store/store'
import config from './config'
import { LicenseInfo } from '@mui/x-license-pro'
import LogRocket from 'logrocket'

LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_PRO as string)
if (['production', 'staging'].includes(process.env.REACT_APP_NODE_ENV as string)) {
  const privateFieldNames = [
    'base_url',
    'client_id',
    'client_secret',
    'password',
    'confirm_password',
    'email',
    'tenant_id',
    'secret',
    'api_key'
  ]
  const findKeys = (obj: any, keysToFind: string[], foundKeys = []) => {
    for (const key in obj) {
      if (keysToFind.includes(key)) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        foundKeys.push({ key, value: obj[key] })
      }
      if (typeof obj[key] === 'object') {
        findKeys(obj[key], keysToFind, foundKeys)
      }
    }
    return foundKeys
  }

  LogRocket.init(process.env.REACT_APP_LOGROCKET as string, {
    dom: {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      rateLimitCount: 2048,
      rateLimitPeriod: 5000,
    },
    network: {
      requestSanitizer: (request) => {
        if (request.body) {
          const requestBody = JSON.parse(request.body)
          const foundKeys = findKeys(requestBody, privateFieldNames)
          if (foundKeys.length) delete request.body
        }
        return request
      },
      responseSanitizer: (response) => {
        if (response.body) {
          const responseBody = JSON.parse(response.body as string)
          const foundKeys = findKeys(responseBody, privateFieldNames)
          if (foundKeys.length) delete response.body
        }
        return response
      }
    }
  })
}

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const container = document.getElementById('root')!
const root = ReactDOMClient.createRoot(container)

root.render(
  <Provider store={store}>
    <>
      <Router>
        <FronteggProvider contextOptions={config.auth} hostedLoginBox={true} tracing={true}>
          <App />
        </FronteggProvider>
      </Router>
    </>
  </Provider>
)

reportWebVitals()
