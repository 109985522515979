import * as d3 from 'd3'

export const raiseElements = (svg: d3.Selection<SVGSVGElement | null, unknown, null, undefined>) => {
  svg.selectAll('.sla-tooltip-wrapper').raise()
  svg.selectAll('.cartesian-grid-horizontal').raise()
  svg.selectAll('.sla-line-left').raise()
  svg.selectAll('.sla-line-right').raise()
  svg.selectAll('.recent-performance-line-right').raise()
  svg.selectAll('.filled-area-stroke').raise()
  svg.selectAll('.event-indicator-count').raise()
  svg.selectAll('.add-event-button').raise()
  svg.selectAll('.count-tooltip').raise()
  svg.selectAll('.add-button-tooltip').raise()
  svg.selectAll('.add-button').raise()
  svg.selectAll('.add-button-rect-hor').raise()
  svg.selectAll('.add-button-rect-ver').raise()
  svg.selectAll('.line-top').raise()
  svg.selectAll('.line-bottom').raise()
  svg.selectAll('.hover-circle-outer').raise()
  svg.selectAll('.hover-circle-inner').raise()
  svg.selectAll('.data-line').raise()
  svg.selectAll('.y-axis').raise()
  svg.selectAll('.sla').raise()
}

export const removeElements = (
  svg: d3.Selection<SVGSVGElement | null, unknown, null, undefined>,
  tooltip: d3.Selection<d3.BaseType, unknown, HTMLElement, any>
) => {
  svg.selectAll('.y-axis').remove()
  svg.selectAll('.sla').remove()
  svg.selectAll('.data-line').remove()
  svg.selectAll('.cartesian-grid-horizontal').remove()
  svg.selectAll('.horizontal-grid-line').remove()
  svg.selectAll('.filled-area-stroke').remove()
  svg.selectAll('.sla-line-left').remove()
  svg.selectAll('.sla-line-right').remove()
  svg.selectAll('.cursor-line').remove()
  svg.selectAll('.hover-circle-inner').remove()
  svg.selectAll('.count-tooltip').remove()
  svg.selectAll('.hover-circle-outer').remove()
  svg.selectAll('.event-indicator-count').remove()
  svg.selectAll('.add-event-button').remove()
  svg.selectAll('.recent-performance-line-right').remove()
  tooltip.style('display', 'none')
}
