import { FC, FormEvent, useState } from 'react'
import { Box, CircularProgress, TextField } from '@mui/material'
import axios from '../../../../../lib/axios'

/* Utils */
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks'
import { closeModal, modalPropsSelector } from '../../../../../store/modals/modalSlice'
import { closeToast, openToast, ToastVariant } from '../../../../../store/toasts/toastSlice'

/* Components */
import { ThemeButton } from '../../../buttons'
import { DeleteIcon } from '../../../svg'
import {
  DeleteInstanceActionsWrapper,
  DeleteInstanceSubtitle,
  DeleteInstanceTitle,
  DeleteInstanceWrapper
} from './deactivateCpi.styles'

const DeactivateCpi: FC = () => {
  const modalProps = useAppSelector(modalPropsSelector)
  const dispatch = useAppDispatch()
  const [loading, setLoading] = useState(false)
  const [value, setValue] = useState('')
  const [disabled, setDisabled] = useState(true)

  const handleClose = () => {
    dispatch(closeModal())
    if (modalProps?.props?.closeback) {
      modalProps.props.closeback()
    }
  }

  const handleDelete = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    setLoading(true)

    try {
      const res = await axios.delete(`/api/v3/cpis/${modalProps?.props?.cpiName || ''}`)
      if (res.status === 202) {
        dispatch(closeModal())
        dispatch(
          openToast({
            variant: ToastVariant.success,
            props: {
              text: 'CPI Deleted',
              description: 'Your CPI Configuration was deleted.'
            }
          })
        )
        if (modalProps?.props?.callback) {
          modalProps.props.callback()
        }
      }
    } catch (e) {
      console.error(e)
      dispatch(
        openToast({
          variant: ToastVariant.error,
          props: {
            text: 'Error',
            description: `Failed deactivating CPI.${(e as any).response?.data?.message ?? ''}`
          }
        })
      )
    } finally {
      setLoading(false)
      setTimeout(() => {
        dispatch(closeToast())
      }, 5000)
    }
  }

  return (
    <DeleteInstanceWrapper>
      <form onSubmit={handleDelete}>
        <DeleteInstanceTitle>Are You Sure?</DeleteInstanceTitle>
        <DeleteInstanceSubtitle>
          If you deactivate this indicator, you may loose the historical trend data permanently, even if you activate it
          again later. Type “DELETE” to confirm:
        </DeleteInstanceSubtitle>
        <Box className="input-wrapper">
          <TextField
            variant="outlined"
            label='Type "DELETE" to confirm'
            value={value}
            autoComplete="off"
            onChange={(e) => {
              if (e.target.value === 'DELETE') {
                setDisabled(false)
              } else {
                setDisabled(true)
              }
              setValue(e.target.value)
            }}
            fullWidth
            sx={{ marginTop: '8px' }}
          />
        </Box>
        <DeleteInstanceActionsWrapper>
          <ThemeButton themevariant="tertiary" onClick={handleClose}>
            Go Back
          </ThemeButton>
          <ThemeButton
            themevariant="primary"
            type="submit"
            endIcon={loading && <CircularProgress size="20px" />}
            disabled={disabled}
          >
            <>
              <DeleteIcon />
              Confirm
            </>
          </ThemeButton>
        </DeleteInstanceActionsWrapper>
      </form>
    </DeleteInstanceWrapper>
  )
}

export default DeactivateCpi
