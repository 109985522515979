import { FC } from 'react'
import { Box } from '@mui/material'
import { ThemeButton } from '../../components/buttons'
import { RightIconButton } from '../../components/svg'
import { Wrapper, Title, Description } from './settingsListItem.styles'

interface Props {
  title: string
  description: string
  buttonText: string
  handleClick: () => void
  hasPermission: boolean
}

const SettingsListItem: FC<Props> = ({ title, description, buttonText, handleClick, hasPermission }) => (
  <Wrapper onClick={handleClick} isdisabled={!hasPermission ? 'true' : 'false'}>
    <Box className="title-wrapper">
      <Title>{title}</Title>
      <Description>{description}</Description>
    </Box>
    <ThemeButton
      themevariant="secondary"
      onClick={handleClick}
      sx={{
        whiteSpace: 'nowrap',

        svg: {
          path: {
            fill: '#FFA9FC'
          }
        }
      }}
      disabled={!hasPermission}
    >
      <>
        <RightIconButton />
        {buttonText}
      </>
    </ThemeButton>
  </Wrapper>
)

export default SettingsListItem
