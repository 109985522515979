import React, { FC, useEffect, useMemo, useState } from 'react'
import { Box, CircularProgress, Grid, Typography } from '@mui/material'
import debounce from 'lodash.debounce'
import dayjs, { Dayjs } from 'dayjs'
import { useNavigate } from 'react-router-dom'

/* Utils */
import axios from '../../../lib/axios'
import { breadcrumbs, dateFormat } from '../../../lib/utils'
import { CPIFrequency, CPISeverity, Insight } from '../../../models'
import { LabelOption } from '../library/components/LibrarySelectLabelField'

/* Components */
import { Container, Content, NoResultsWrapper } from './onyxAIInsights.styles'
import InsightsHeader from './components/InsightsHeader'
import MainSection from './sections/main-section'
import HistorySection from './sections/history-section'
import useSearchLabel from '../../../hooks/useSearchLabel'
import { ThemeButton } from '../../components/buttons'
import { payloadToEnumTimePeriod } from '../library/utils'
import NoResultsSvg from './components/NoResultsSvg'
import HeaderComponent from '../../components/header/HeaderComponent'
import ContentWrapper from '../../components/header/ContentWrapper'

const headerBackgroundVariants = {
  green: '/insights/green-insights-bg.png',
  red: '/insights/red-insights-bg.png'
}

const sourceOptions: LabelOption[] = [
  {
    name: 'cpi_trend',
    label: 'Data Trends',
    icon: (
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path
          d="M21 8.5C19.55 8.5 18.74 9.94 19.07 11.01L15.52 14.57C15.22 14.48 14.78 14.48 14.48 14.57L11.93 12.02C12.27 10.95 11.46 9.5 10 9.5C8.55 9.5 7.73 10.94 8.07 12.02L3.51 16.57C2.44 16.24 1 17.05 1 18.5C1 19.6 1.9 20.5 3 20.5C4.45 20.5 5.26 19.06 4.93 17.99L9.48 13.43C9.78 13.52 10.22 13.52 10.52 13.43L13.07 15.98C12.73 17.05 13.54 18.5 15 18.5C16.45 18.5 17.27 17.06 16.93 15.98L20.49 12.43C21.56 12.76 23 11.95 23 10.5C23 9.4 22.1 8.5 21 8.5Z"
          fill="white"
        />
        <path d="M15 9.5L15.94 7.43L18 6.5L15.94 5.57L15 3.5L14.08 5.57L12 6.5L14.08 7.43L15 9.5Z" fill="white" />
        <path d="M3.5 11.5L4 9.5L6 9L4 8.5L3.5 6.5L3 8.5L1 9L3 9.5L3.5 11.5Z" fill="white" />
      </svg>
    )
  },
  {
    name: 'cpi_sla_optimization',
    label: 'CPI SLA Optimization',
    icon: (
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path
          d="M9.59016 13C10.1402 13 10.5902 13.45 10.5902 14C10.5902 14.55 10.1402 15 9.59016 15C9.04016 15 8.59016 14.55 8.59016 14C8.59016 13.45 9.04016 13 9.59016 13ZM9.59016 11C7.93016 11 6.59016 12.34 6.59016 14C6.59016 15.66 7.93016 17 9.59016 17C11.2502 17 12.5902 15.66 12.5902 14C12.5902 12.34 11.2502 11 9.59016 11ZM18.0902 9L19.1802 6.59L21.5902 5.5L19.1802 4.41L18.0902 2L17.0002 4.41L14.5902 5.5L17.0002 6.59L18.0902 9ZM20.8702 12.72L20.0902 11L19.3102 12.72L17.5902 13.5L19.3102 14.28L20.0902 16L20.8702 14.28L22.5902 13.5L20.8702 12.72ZM15.8402 14C15.8402 13.88 15.8402 13.75 15.8302 13.63L17.7702 12.16L15.2702 7.83L13.0302 8.77C12.8302 8.64 12.6102 8.51 12.3902 8.4L12.0902 6H7.09016L6.79016 8.41C6.57016 8.52 6.36016 8.65 6.15016 8.78L3.91016 7.83L1.41016 12.16L3.35016 13.63C3.34016 13.75 3.34016 13.88 3.34016 14C3.34016 14.12 3.34016 14.25 3.35016 14.37L1.41016 15.84L3.91016 20.17L6.15016 19.23C6.35016 19.36 6.57016 19.49 6.79016 19.6L7.09016 22H12.0902L12.3902 19.59C12.6102 19.48 12.8202 19.36 13.0302 19.22L15.2702 20.16L17.7702 15.83L15.8302 14.36C15.8402 14.25 15.8402 14.12 15.8402 14ZM14.4202 17.64L12.6902 16.91C12.1302 17.51 11.3902 17.95 10.5602 18.14L10.3202 20H8.86016L8.63016 18.14C7.80016 17.95 7.06016 17.51 6.50016 16.91L4.77016 17.64L4.04016 16.37L5.53016 15.24C5.41016 14.85 5.35016 14.44 5.35016 14.01C5.35016 13.58 5.41016 13.17 5.53016 12.78L4.04016 11.65L4.77016 10.38L6.50016 11.11C7.06016 10.51 7.80016 10.07 8.63016 9.88L8.86016 8H10.3302L10.5602 9.86C11.3902 10.05 12.1302 10.49 12.6902 11.09L14.4202 10.36L15.1502 11.63L13.6602 12.76C13.7802 13.15 13.8402 13.56 13.8402 13.99C13.8402 14.42 13.7802 14.83 13.6602 15.22L15.1502 16.35L14.4202 17.64Z"
          fill="white"
        />
      </svg>
    )
  },
  {
    name: 'predictions',
    label: 'CPI Data Trend Prediction',
    icon: (
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <circle cx="12" cy="12" r="7.75" fill="white" fillOpacity="0.2" stroke="white" strokeWidth="0.5" />
        <ellipse opacity="0.4" cx="12" cy="10.5" rx="7" ry="6.5" fill="white" />
        <path
          d="M17.408 8.80957C18.1186 10.1411 18.8878 13.4562 16.2797 16.0642"
          stroke="white"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path d="M2.5 20L3 18L5 17.5L3 17L2.5 15L2 17L0 17.5L2 18L2.5 20Z" fill="white" />
        <path d="M19.5 6L20 4L22 3.5L20 3L19.5 1L19 3L17 3.5L19 4L19.5 6Z" fill="white" />
        <path
          d="M6.5 12L8.22333 8.205L12 6.5L8.22333 4.795L6.5 1L4.81333 4.795L1 6.5L4.81333 8.205L6.5 12Z"
          fill="white"
        />
        <path
          d="M5.25 21C5.25 20.0335 6.0335 19.25 7 19.25H16C16.9665 19.25 17.75 20.0335 17.75 21V21.25H5.25V21Z"
          fill="white"
          stroke="white"
          strokeWidth="0.5"
        />
      </svg>
    )
  }
]

const OnyxAIInsights: FC = () => {
  const navigate = useNavigate()
  const [key, setKey] = useState('key1')
  const [initialLoad, setInitialLoad] = useState(false)
  const [searching, setSearching] = useState(false)
  const [dateValue, setDateValue] = useState<[Dayjs | string, Dayjs | string]>(['', ''])
  const [results, setResults] = useState<Insight[]>([])
  const [source, setSource] = useState<string>('')
  const [selectedInsight, setSelectedInsight] = useState<Insight | null>(null)
  const [height, setHeight] = useState('645px')

  const convertedOptions = useMemo(
    () =>
      results.map((item) => ({
        label: item.data?.cpi_display_name || '',
        name: item.data?.cpi_name || ''
      })),
    []
  )

  useEffect(() => {
    fetchInsights()
  }, [])

  const fetchInsights = () => {
    axios
      .post('/api/v3/insights/search', {
        // type: 'cpi_trend'
      })
      .then((res) => {
        setResults(res.data.data)
        setSelectedInsight(res.data.data[0])
      })
      .catch((e) => e)
      .finally(() => setInitialLoad(true))
  }

  useEffect(() => {
    setTimeout(() => {
      getHeight()
    }, 200)
  }, [selectedInsight, results])

  const getHeight = () => {
    const mainSection = document.getElementById('mainSection')
    const offset = 0
    if (mainSection) {
      setHeight(mainSection.clientHeight + offset + 'px')
    }
  }

  const {
    search,
    setSearch
    // labelOptions,
    // highlightMatch
  } = useSearchLabel(convertedOptions, null, true)

  const handleSearch = async ({
    newValue = search,
    sourceType,
    newDateValue
  }: {
    newValue?: string
    sourceType?: string
    newDateValue?: [Dayjs, Dayjs]
  }) => {
    const val = newValue || ''
    setSearching(true)

    const dValue = newDateValue || dateValue

    const dateFrom = dayjs(dValue[0]).format(dateFormat.customLabel) || ''
    const dateTo = dayjs(dValue[1]).format(dateFormat.customLabel) || ''

    try {
      const payload: any = {
        type: sourceType || source || ''
      }
      if (payload.type === '') {
        delete payload.type
      }
      if (val !== '') {
        payload.term = val
      }
      if (dateFrom !== 'Invalid Date') {
        payload.date_from = dateFrom
      }
      if (dateTo !== 'Invalid Date') {
        payload.date_to = dateTo
      }
      const res = await axios.post('/api/v3/insights/search', payload)
      setResults(res.data.data)
      setSelectedInsight(res.data.data[0])
    } catch (err) {
      console.error(err)
    } finally {
      setSearching(false)
    }
  }

  const debouncedSearch = useMemo(() => debounce(handleSearch, 200), [search, dateValue, source])

  const handleChangeSource = (newSource: string) => {
    setSource(newSource)
    debouncedSearch({ newValue: search, sourceType: newSource })
  }

  const handleChangeDate = (newDateValue: [Dayjs, Dayjs]) => {
    debouncedSearch({ newValue: search, newDateValue })
  }

  const toLink = (res: Insight) => {
    const payload = JSON.parse(res.data?.redirection_params || '').redirection_params

    if (!payload.time_period) {
      navigate(`/library/cpis/${res.data?.cpi_display_name.toLowerCase()}`, {
        state: {
          dates: {
            dateFrom: payload.date_from,
            dateTo: payload.date_to
          },
          frequency: payload.frequency || CPIFrequency.Monthly,
          severity: payload.section || CPISeverity.High
        }
      })
    } else {
      navigate(`/library/cpis/${res.data?.cpi_display_name.toLowerCase()}`, {
        state: {
          timePeriod: payloadToEnumTimePeriod(payload.time_period),
          frequency: payload.frequency || CPIFrequency.Monthly,
          severity: payload.section || CPISeverity.High
        }
      })
    }
  }

  const getHeaderBackground = (text: string) => {
    if (text.includes('red')) return headerBackgroundVariants.red
    if (text.includes('green')) return headerBackgroundVariants.green
    if (text.includes('improvement')) return headerBackgroundVariants.green

    return headerBackgroundVariants.red
  }

  const handleClear = () => {
    setKey(new Date().toISOString())
    setDateValue(['', ''])
    setSearch('')
    setSource('')
    fetchInsights()
  }

  return (
    <Container>
      <HeaderComponent breadcrumbs={breadcrumbs.insights} />

      <InsightsHeader
        key={key}
        tabValue={0}
        handleTabChange={() => {
          //
        }}
        title="OnyxAI Insights"
        tabLabels={[]}
        hideIntegrationsTabs
        search={search}
        setSearch={setSearch}
        handleSearch={debouncedSearch}
        dateValue={dateValue}
        setDateValue={setDateValue}
        sourceOptions={sourceOptions}
        source={source}
        setSource={setSource}
        handleChangeSource={handleChangeSource}
        handleChangeDate={handleChangeDate}
        handleClear={handleClear}
      />

      <ContentWrapper line={1} paddingBottom={100}>
        {initialLoad ? (
          <>
            {results.length > 0 && (
              <Content>
                {results.length > 0 && (
                  <Box className="history-content" sx={{ width: '560px !important', height: height }}>
                    <Grid container spacing={2}>
                      <HistorySection
                        search={search}
                        results={results}
                        getHeaderBackground={getHeaderBackground}
                        selectedInsight={selectedInsight}
                        setSelectedInsight={setSelectedInsight}
                        toLink={toLink}
                      />
                    </Grid>
                  </Box>
                )}

                {results.length > 0 && selectedInsight && (
                  <>
                    <MainSection
                      insight={selectedInsight}
                      handleExploreData={toLink}
                      getHeaderBackground={getHeaderBackground}
                    />
                  </>
                )}
              </Content>
            )}

            {!searching && initialLoad && !results.length && (
              <NoResultsWrapper>
                <Box className="no-results-content">
                  <Typography className="no-results-text">Sorry, we couldn’t find anything.</Typography>
                  <ThemeButton themevariant="primary" onClick={handleClear}>
                    Clear filters and try a new search
                  </ThemeButton>
                </Box>
                <Box className="svg-wrapper">
                  <NoResultsSvg />
                </Box>
              </NoResultsWrapper>
            )}
          </>
        ) : (
          <Box
            sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', marginTop: '40px' }}
          >
            <CircularProgress size="36px" />
          </Box>
        )}
      </ContentWrapper>
    </Container>
  )
}

export default OnyxAIInsights
