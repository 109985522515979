import styled from '@emotion/styled/macro'
import { Box } from '@mui/material'

export const Wrapper = styled(Box)<{ type?: string; noBg?: boolean; borderbottom?: string }>`
  border-bottom: ${({ noBg, borderbottom }) =>
    borderbottom && borderbottom === 'true' ? '1px solid #4d444b' : noBg ? 'none' : '1px solid #4d444b'};
  background: ${({ noBg }) =>
    noBg
      ? 'transparent'
      : 'radial-gradient(155.07% 155.07% at 50% 100%, rgba(255, 169, 252, 0.15) 0%, rgba(255, 169, 252, 0) 66.2%),   #1e1a1d;'};
  height: 100px;
  padding-left: 24px;
  padding-right: 24px;
  position: static;
  top: 0;
  width: -webkit-fill-available;
  width: -moz-available;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 1000;

  ${({ type }) =>
    type === 'dashboard' &&
    `
    left: 72px
  `};
`
export const HeaderBreadcrumbsWrapper = styled(Box)`
  display: flex;
  align-items: center;
  gap: 8px;

  .link-block {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  .text {
    font-family: 'Quicksand', sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
    color: white;
    text-transform: capitalize;
  }

  .link {
    color: #998d96;
    text-decoration: none;

    &:hover {
      color: white;
    }
  }
`
