import React from 'react'

const AssetAnalyzer = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
    <path
      d="M1.66699 16.6585L7.91699 10.4001L11.2503 13.7335L17.1587 7.0918L18.3337 8.2668L11.2503 16.2335L7.91699 12.9001L2.91699 17.9085L1.66699 16.6585ZM2.91699 12.9085L7.91699 7.90013L11.2503 11.2335L18.3337 3.2668L17.1587 2.0918L11.2503 8.73346L7.91699 5.40013L1.66699 11.6585L2.91699 12.9085Z"
      fill="#D0C3CC"
    />
  </svg>
)

export default AssetAnalyzer
