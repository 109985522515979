import { ButtonGroup, Popper } from '@mui/material'
import styled from '@emotion/styled/macro'

export const StyledSplitButton = styled(ButtonGroup)<{ disabled: boolean }>`
  box-shadow: none;
  background: transparent;
  height: 40px;
  z-index: 1;
  box-sizing: border-box;

  ${({ disabled }) =>
    disabled &&
    `
      background: #483646;
      color: #7F747C;
      border: none;
      border-radius: 100px;
  `};

  .main-button {
    height: 40px;
    gap: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px 16px;
    background: transparent;
    text-transform: none;
    color: #ffa9fc;
    text-align: center;
    font-family: Quicksand, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 510;
    line-height: normal;
    border-radius: 100px;
    border: 1px solid #998d96 !important;
    z-index: 2;
    box-sizing: border-box;

    &:hover {
      border-color: #998d96;
      background: #544152;
      color: #ec85ec;
    }

    &:active,
    &:focus {
      border: 1px solid #998d96;
      background: #590060;
      color: #fff;
    }

    ${({ disabled }) =>
      disabled &&
      `
          background: #483646;
          color: #7F747C;
          border: none;
      `};
  }

  .dropdown-button {
    padding: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100px;
    color: #ffa9fc;
    background: transparent;
    border: 1px solid #998d96 !important;
    border-left: none !important;

    &:hover {
      border-color: #998d96;
      background: #544152;
      color: #ec85ec;
    }

    &:active,
    &:focus {
      border: 1px solid #998d96;
      background: #590060;
      color: #fff;
    }

    &[aria-expanded='true'] {
      background: rgba(89, 0, 96, 1);

      &:hover {
        color: #ffa9fc;
      }
    }

    ${({ disabled }) =>
      disabled &&
      `
          background: #483646;
          color: #7F747C;
          border: none;
      `};
  }
`

export const StyledPopper = styled(Popper)`
  .MuiPaper-root {
    border-radius: 4px;
    border: 1px solid #4d444b;
    background: #372a36;
    box-shadow: 0 8px 12px 6px rgba(0, 0, 0, 0.15), 0 4px 4px 0 rgba(0, 0, 0, 0.3);
    width: 250px;

    .MuiList-root {
      padding: 8px;

      .MuiMenuItem-root {
        height: 40px;
        border-radius: 4px;

        &.Mui-selected {
          background: #573f56;
        }

        &:hover {
          background: #573f56;
        }
      }
    }
  }
`
