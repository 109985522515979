import React, { Dispatch, FC, SetStateAction, useState } from 'react'

// Components
import { StyledInputWrapper, Wrapper } from './CreateLabels.styles'
import { Box, TextField, Typography } from '@mui/material'
import { Form, Formik } from 'formik'
import { ThemeButton } from '../../../../../../../components/buttons'
import SaveSvg from '../../../../../../../components/svg/labels/SaveSvg'
import DeleteSvg from '../../../../../../../components/svg/labels/DeleteSvg'
import { CustomLabelI, DateRangeI } from '../../../../../../../../models'
import axios from '../../../../../../../../lib/axios'
import CalendarDatePicker from '../components/CalendarDatePicker'
import dayjs from 'dayjs'
import { getUtcDateAndTime } from '../../../../../utils'

interface Props {
  setLoading: Dispatch<SetStateAction<boolean>>
  editLabel: CustomLabelI
  updateMode: (newMode: string) => void
  updateLabels: () => void
  dateRange: DateRangeI
}

const EditLabel: FC<Props> = ({ updateMode, editLabel, dateRange, updateLabels, setLoading }) => {
  const [form, setForm] = useState(editLabel?.data)

  const onInputChange = (e: any) => {
    setForm((prevState: any) => ({
      ...prevState,
      [e.target.name]: e.target.value
    }))
  }

  const isValid = () => {
    return form.title.length && form.title.length <= 20 && form.description.length && form.description.length <= 50
  }

  const onSubmit = async () => {
    try {
      setLoading(true)
      if (!form.title) form.title = 'Custom Label'
      const utcDate = getUtcDateAndTime(dayjs(form.date).format('YYYY-MM-DD'))
      const d = utcDate?.isoFormattedDate || ''
      await axios.put(`/api/v1/labels/cpis/custom/${editLabel.id}`, { ...form, date: d })
      await updateLabels()
      setTimeout(async () => {
        await updateMode('show-0')
        await setLoading(false)
      }, 1000)
    } catch (e) {
      setLoading(false)
      console.error(e)
    }
  }

  const deleteLabel = async () => {
    try {
      setLoading(true)
      await axios.delete(`/api/v1/labels/cpis/custom/${editLabel.id}`)
      setTimeout(async () => {
        await updateLabels()
        await updateMode('show-0')
        await setLoading(false)
      }, 1000)
    } catch (e) {
      setLoading(false)
      console.error(e)
    }
  }

  return (
    <Wrapper>
      <Formik initialValues={form} onSubmit={onSubmit}>
        {({ isSubmitting }) => (
          <Form autoComplete="off">
            <CalendarDatePicker dateRange={dateRange} setForm={setForm} editDate={editLabel.data?.date} />

            <Box className="line" />

            <Typography className="sub-title">Add a title and description to your label:</Typography>

            <StyledInputWrapper>
              <TextField value={form.title} placeholder="Title" name="title" onInput={(e) => onInputChange(e)} />
              <Typography className={`help ${form.title?.length > 20 ? 'text-danger' : ''}`}>
                {form.title.length}/20
              </Typography>
            </StyledInputWrapper>

            <StyledInputWrapper>
              <TextField
                value={form.description}
                placeholder="Add a description"
                multiline
                rows={2}
                name="description"
                onInput={(e) => onInputChange(e)}
              />
              <Typography className={`help ${form.description?.length > 50 ? 'text-danger' : ''}`}>
                {form.description.length}/50
              </Typography>
            </StyledInputWrapper>

            <Box className="drawer-actions edit-actions">
              <ThemeButton
                themevariant="primary"
                type="submit"
                className="edit-button save-btn"
                disabled={!isValid() || isSubmitting}
              >
                <>
                  <SaveSvg />
                  Save Changes
                </>
              </ThemeButton>
              <ThemeButton
                themevariant="secondary"
                type="button"
                className="edit-button"
                disabled={isSubmitting}
                onClick={() => deleteLabel()}
              >
                <>
                  <DeleteSvg />
                  Delete Label
                </>
              </ThemeButton>
            </Box>
          </Form>
        )}
      </Formik>
    </Wrapper>
  )
}

export default EditLabel
