import React, { useEffect, useMemo, useState } from 'react'

/* Utils */
import { closeToast, openToast, ToastVariant } from '../../../store/toasts/toastSlice'
import { useAppDispatch } from '../../../store/hooks'
import axios from '../../../lib/axios'
import { useAuth } from '@frontegg/react'
import { useCallbackPrompt } from '../../../hooks/useCallbackPrompt'
import { frameworkDefaultOptions } from './framework.constants'
import { CategoriesCpisWeightI, CategoriesWeightI, FormWeightI } from '../../../models'
import {
  categorySortFuncNistWeights,
  categorySortFuncOnyxiaWeights
} from '../dashboard/components/Benchmarking/benchmarking.constants'
import { ModalType, openModal } from '../../../store/modals/modalSlice'

/* Components */
import { Container, ContentWrapperMain, FrameworkSection } from './frameworkConfiguration.styles'
import { Box, CircularProgress, Typography, useMediaQuery } from '@mui/material'
import { MaterialUISwitch } from '../settings/account-management/account-management.styles'
import { ThemeButton } from '../../components/buttons'
import { InfoIconFilled, RevertBackIcon } from '../../components/svg'
import FrameworkSelectField from './components/FrameworkSelectField'
import CategoryWeights from './components/CategoryWeights'
import CategorySection from './components/CategorySection'
import { LoadingWrapper } from '../dashboard/dashboard.styles'
import { Notification } from './components/styles/TotalPercentageNotify.styles'
import { setAccountSettings } from '../../../store/user/userSlice'
import HeaderComponent from '../../components/header/HeaderComponent'
import { breadcrumbs } from '../../../lib/utils'
import { LibraryDetailsHoverTooltip } from '../library/library-details/components/LibraryDetailsTooltip'
import ContentWrapper from '../../components/header/ContentWrapper'

const FrameworkConfigurationDeprecated = () => {
  const smallBreakpoint = useMediaQuery('(max-width:1440px)')
  const dispatch = useAppDispatch()
  const { user: authUser } = useAuth()
  const [tenant, setTenant] = useState({} as any)
  const [loading, setLoading] = useState(true)
  const [isChanged, setIsChanged] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [isFormValid, setIsFormValid] = useState(true)
  const [categoryCpis, setCategoryCpis] = useState<CategoriesCpisWeightI[]>([])
  const [categories, setCategories] = useState<CategoriesWeightI[]>([])
  const [form, setForm] = useState<FormWeightI>({
    framework: {},
    categoryFramework: {},
    categories: categories,
    categoryCpis: categoryCpis
  })
  const [defaultFramework, setDefaultFramework] = useState({
    value: ''
  })
  const [showPrompt, confirmNavigation, cancelNavigation] = useCallbackPrompt(isChanged)

  const canEdit = useMemo(() => {
    const permissions = ['owner', 'admin', 'manager', 'cpi_manager']
    return permissions.includes(authUser?.roles[0].key || '')
  }, [authUser])

  const cpiCategoriesOrdered = useMemo(() => {
    return form.categoryCpis.sort(
      form.framework.value === 'onyxia' ? categorySortFuncOnyxiaWeights : categorySortFuncNistWeights
    )
  }, [form.categoryCpis])

  useEffect(() => {
    if (showPrompt) {
      dispatch(
        openModal({
          type: ModalType.cancelChangesWeight,
          props: { callback: cancelChanges, confirmNavigation, cancelNavigation }
        })
      )
    }
  }, [showPrompt])

  useEffect(() => {
    if (!isEdit) {
      fetchAccountData()
    }
  }, [isEdit])

  const fetchAccountData = async () => {
    try {
      const { data } = await axios.get('/api/v3/tenant')
      setTenant(data.data)
      const findItem = frameworkDefaultOptions.find((item) => item.value === data.data.cyFramework)
      if (findItem) {
        setDefaultFramework(findItem)
        setForm((prevState) => ({
          ...prevState,
          framework: findItem,
          categoryFramework: findItem
        }))

        await fetchCategories(findItem.value)
      }
    } catch (err) {
      console.error(err)
    }
  }

  const fetchCategories = async (frameworkName: string) => {
    try {
      const resp = await axios.get(`/api/v3/frameworks/categories/weights?framework=${frameworkName}`)
      setCategories(resp.data.data.categories)
      setForm((prevState) => ({
        ...prevState,
        categories: resp.data.data.categories,
        categoryCpis: []
      }))

      resp.data.data.categories.forEach(async (category: CategoriesCpisWeightI) => {
        await fetchCpis(category.id, frameworkName)
      })
    } catch (err) {
      console.error(err)
    } finally {
      setLoading(false)
    }
  }

  const fetchCpis = async (id: string, name: string) => {
    try {
      const resp = await axios.get(`/api/v3/frameworks/cpis/${id}/weights?framework=${name}`)
      setCategoryCpis((prevState) => [...prevState, resp.data.data])
      setForm((prevState) => ({
        ...prevState,
        categoryCpis: [...prevState.categoryCpis, resp.data.data]
      }))
    } catch (err) {
      console.error(err)
    }
  }

  const handleSwitch = (e: any) => {
    if (!canEdit) return
    if (!isFormValid) return

    if (isChanged && !e.target.checked) {
      const tempForm = {
        framework: {},
        categoryFramework: {},
        categories: [],
        categoryCpis: []
      } as FormWeightI

      if (form.framework.isEdit) {
        tempForm.framework = form.framework
      }
      form.categories.forEach((item) => {
        if (item.isEdit) tempForm.categories.push(item)
      })
      form.categoryCpis.forEach((item) => {
        item.cpis.forEach((i) => {
          if (i.isEdit) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            tempForm.categoryCpis.push(i)
          }
        })
      })

      return dispatch(
        openModal({
          type: ModalType.updateWeights,
          props: { weightsData: tempForm, callback: () => submitForm() }
        })
      )
    }

    setIsEdit(e.target.checked)
  }

  const changeHandler = (e: React.ChangeEvent<any>) => {
    setIsChanged(true)
    const findItem = {
      ...frameworkDefaultOptions.find((item) => item.value === e.target.value),
      isEdit: true
    }
    setForm((prevState) => ({
      ...prevState,
      [e.target.name]: findItem
    }))
  }

  const cancelModal = () => {
    dispatch(openModal({ type: ModalType.cancelChangesWeight, props: { callback: cancelChanges } }))
  }

  const cancelChanges = () => {
    setIsChanged(false)
    setForm({
      framework: { ...defaultFramework },
      categoryFramework: { ...defaultFramework },
      categories: categories,
      categoryCpis: categoryCpis
    })

    reFetchDataByFramework(defaultFramework?.value)
    dispatch(
      openToast({
        variant: ToastVariant.success,
        props: {
          text: 'Changes discarded',
          description: ''
        }
      })
    )

    setTimeout(() => {
      dispatch(closeToast())
    }, 3000)
  }

  const revertDefaultValuesModal = () => {
    dispatch(openModal({ type: ModalType.revertDefaultValuesWeight, props: { callback: revertDefaultValues } }))
  }

  const revertDefaultValues = () => {
    const temp = { ...form }

    const totalItems = temp.categories.length
    const baseValue = Math.floor(100 / totalItems)
    const remainder = 100 % totalItems
    for (let i = 0; i < totalItems; i++) {
      temp.categories[i].weight = baseValue + (i < remainder ? 1 : 0)
    }

    temp.categoryCpis.map((category) => {
      const totalItems = category.cpis.length
      const baseValue = Math.floor(100 / totalItems)
      const remainder = 100 % totalItems
      for (let i = 0; i < totalItems; i++) {
        category.cpis[i].weight = baseValue + (i < remainder ? 1 : 0)
      }
      return category
    })

    setForm(temp)
    Promise.all([updateFramework(), updateCategories(), updateCpisFunction(true)]).then(() => {
      dispatch(
        openToast({
          variant: ToastVariant.success,
          props: {
            text: 'Values reverted to default configuration',
            description: ''
          }
        })
      )

      setTimeout(() => {
        dispatch(closeToast())
      }, 3000)
    })
  }

  const resetData = () => {
    setLoading(true)
    setIsChanged(false)
    setCategoryCpis([])
    setCategories([])
    setForm({
      framework: {},
      categoryFramework: {},
      categories: [],
      categoryCpis: []
    })
    setTimeout(() => {
      setIsEdit(false)
    }, 1000)
  }

  const submitForm = () => {
    Promise.all([updateFramework(), updateCategories(), updateCpisFunction(false)])
      .then((res) => {
        resetData()
        if (!res[0]) {
          dispatch(
            openToast({
              variant: ToastVariant.success,
              props: {
                text: 'Framework Configuration successfully updated.',
                description: ''
              }
            })
          )
        } else if (
          typeof res[0] === 'string' &&
          res[0].includes('industry') &&
          res[0].toLowerCase().includes('required')
        ) {
          dispatch(
            openToast({
              variant: ToastVariant.error,
              props: {
                text: 'Failed updating Framework Configuration.',
                description:
                  'You need to have industry selected in order to update framework. Please go to Settings / Account Management to update your industry.'
              }
            })
          )
        }
      })
      .catch((e) => {
        console.error(e)
        dispatch(
          openToast({
            variant: ToastVariant.error,
            props: {
              text: 'Failed to update Framework Configuration.',
              description: (e as any).response?.data?.message || ''
            }
          })
        )
      })
      .finally(() => {
        setTimeout(() => {
          dispatch(closeToast())
        }, 6000)
      })
  }

  const updateFramework = async () => {
    try {
      const data = {
        ...tenant,
        cyFramework: form.framework.value
      }
      const resp = await axios.put('/api/v3/tenant', data)
      dispatch(setAccountSettings(resp.data.data))
      localStorage.setItem('framework', JSON.stringify(resp.data.data.cyFramework))
    } catch (err) {
      console.error(err)
      return (err as any).response?.data?.message
    }
  }

  const updateCategories = async () => {
    try {
      const data = {
        categories: form.categories
      }
      await axios.post(`/api/v3/frameworks/categories/weights?framework=${form.categoryFramework.value}`, data)
    } catch (err) {
      console.error(err)
    }
  }

  const updateCpisFunction = (needCheck: boolean) => {
    let editedCpiCategories = form.categoryCpis
    if (!needCheck) editedCpiCategories = form.categoryCpis.filter((item) => item.isEdit)

    editedCpiCategories.forEach((category) => {
      updateCpis(category)
    })
  }

  const updateCpis = async (category: CategoriesCpisWeightI) => {
    try {
      const data = {
        cpis: category.cpis
      }
      await axios.post(`/api/v3/frameworks/cpis/${category.id}/weights?framework=${form.categoryFramework.value}`, data)
    } catch (err) {
      console.error(err)
    }
  }

  const reFetchDataByFramework = (name: string) => {
    setLoading(true)
    fetchCategories(name)
  }

  const checkOrder = (a: { value: any }, b: { value: any }) => {
    if (a.value === form.framework.value && b.value !== form.framework.value) {
      return -1
    } else if (a.value !== form.framework.value && b.value === form.framework.value) {
      return 1
    } else return 0
  }

  return (
    <Container>
      <HeaderComponent breadcrumbs={breadcrumbs.frameworksConfiguration}>
        <Box className="header-actions">
          {isEdit ? (
            <>
              <Box className="revert-section">
                <RevertBackIcon />
                <Typography className="title" onClick={revertDefaultValuesModal}>
                  Revert to Default Values
                </Typography>
              </Box>

              <ThemeButton themevariant="secondary" type="button" disabled={!isChanged} onClick={cancelModal}>
                Cancel Changes
              </ThemeButton>
            </>
          ) : (
            ''
          )}

          <Box className={`${smallBreakpoint ? 'small-screen' : ''} panel-block`}>
            <LibraryDetailsHoverTooltip
              arrow
              placement="bottom"
              key={'switch'}
              title={isEdit ? 'Lock to prevent further changes.' : 'Unlock to make changes'}
            >
              <MaterialUISwitch
                disabled={!canEdit}
                sx={{ m: 1 }}
                checked={isEdit}
                onChange={handleSwitch}
                inputProps={{ 'aria-label': 'controlled' }}
              />
            </LibraryDetailsHoverTooltip>
          </Box>
        </Box>
      </HeaderComponent>

      <ContentWrapper line={1}>
        <ContentWrapperMain>
          {loading ? (
            <LoadingWrapper>
              <CircularProgress size="40px" color="primary" />
            </LoadingWrapper>
          ) : (
            <>
              <FrameworkSection>
                <Typography className="title">Framework</Typography>
                {['owner', 'admin'].includes(authUser?.roles[0].key || '') ? (
                  <Typography className="sub-title">You’re currently using this Framework</Typography>
                ) : (
                  <Box className="info-message">
                    <Notification isValid={true} hidePanel={false}>
                      <InfoIconFilled />
                      <Typography className="info">Only workspace owners can modify the framework</Typography>
                    </Notification>
                  </Box>
                )}

                {isEdit ? (
                  <FrameworkSelectField
                    disabled={false}
                    disabledNew={!['owner', 'admin'].includes(authUser?.roles[0].key || '')}
                    name="framework"
                    className="select-box"
                    options={frameworkDefaultOptions.sort(checkOrder)}
                    placeholder="Select framework"
                    labeltext="Filter by: "
                    value={form.framework.value}
                    handleChange={changeHandler}
                    minWidth="75px !important"
                  />
                ) : (
                  <Box className="framework">
                    <img src={form.framework.icon} alt={form.framework.label} width={24} height={24} />
                    <Typography className="text">{form.framework.label}</Typography>
                  </Box>
                )}
              </FrameworkSection>

              {form.categories?.length ? (
                <CategoryWeights
                  setIsFormValid={setIsFormValid}
                  isEdit={isEdit}
                  form={form}
                  setForm={setForm}
                  setIsChanged={setIsChanged}
                  reFetchDataByFramework={reFetchDataByFramework}
                />
              ) : (
                ''
              )}

              {cpiCategoriesOrdered.map((category, index) => {
                return (
                  <CategorySection
                    setIsFormValid={setIsFormValid}
                    isEdit={isEdit}
                    category={category}
                    categoryCpis={categoryCpis}
                    form={form}
                    setForm={setForm}
                    setIsChanged={setIsChanged}
                    key={index}
                  />
                )
              })}
            </>
          )}
        </ContentWrapperMain>
      </ContentWrapper>
    </Container>
  )
}

export default FrameworkConfigurationDeprecated
