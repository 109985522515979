const AssistedSetupIcon = () => (
  <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13.4525 5.0475L10.2725 12.645L9.0425 9.4575L8.4275 9.2175L5.855 8.22L13.4525 5.0475ZM16.25 2.25L2.75 7.8975V8.6325L7.88 10.62L9.86 15.75H10.595L16.25 2.25Z"
      fill="#FBD8F5"
    />
  </svg>
)

export default AssistedSetupIcon
