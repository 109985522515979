import React, { FC, ReactElement } from 'react'
import { Wrapper } from './ToolbarComponent.styles'

interface Props {
  children?: ReactElement
  align?: string
}

const ToolbarComponent: FC<Props> = ({ children, align }) => {
  return <Wrapper align={align}>{children}</Wrapper>
}

export default ToolbarComponent
