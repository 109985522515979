import React, { FC, ReactNode } from 'react'
import { Box, Stepper as MuiStepper, Step, StepLabel, Typography } from '@mui/material'
import styled from '@emotion/styled/macro'
import { styled as muiStyled } from '@mui/material/styles'
import { StepIconProps } from '@mui/material/StepIcon'

const Stepper = styled(MuiStepper)`
  .MuiStepConnector-root {
    color: #998d96;
  }

  .MuiStep-root {
    .MuiStepLabel-root {
      .MuiStepLabel-label {
        color: #e9e0e4;
        font-family: Quicksand, sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: 0.1px;
      }

      svg {
        circle {
          color: #943798;
        }
        path {
          fill: #943798;
        }
      }
    }
  }
`

const IconWrapper = muiStyled('div')<{
  ownerState: { completed?: boolean; active?: boolean; icon: number }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
}>(({ theme, ownerState }) => ({
  zIndex: 1,
  width: 24,
  height: 24,
  display: 'flex',
  borderRadius: '50%',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'relative',

  '.text': {
    color: '#e9e0e4',
    fontFamily: "'Quicksand', sans-serif",
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '20px',
    letterSpacing: '0.1px',
    position: 'absolute',
    top: '2px',
    left: '9px'
  },

  ...(!ownerState.active && {
    background: `url("/stepper/inactive-step.svg") no-repeat`,
    backgroundSize: 'cover',

    '.text': {
      color: '#342F32',
      fontFamily: "'Quicksand', sans-serif",
      fontSize: '14px',
      fontWeight: 500,
      lineHeight: '20px',
      letterSpacing: '0.1px',
      position: 'absolute',
      top: '2px',
      left: '8px'
    }
  }),
  ...(ownerState.active && {
    background: `url("/stepper/active-step.svg") no-repeat`,
    backgroundSize: 'cover',

    ...(ownerState.icon === 2 && {
      '.text': {
        color: '#e9e0e4',
        fontFamily: "'Quicksand', sans-serif",
        fontSize: '14px',
        fontWeight: 500,
        lineHeight: '20px',
        letterSpacing: '0.1px',
        position: 'absolute',
        top: '2px',
        left: '8px'
      }
    })
  }),
  ...(ownerState.completed && {
    background: `url("/stepper/step-completed.svg") no-repeat`,
    backgroundSize: 'cover'
  })
}))

function CustomStepIconComponent(props: StepIconProps) {
  const { active, completed, icon, className } = props

  return (
    <IconWrapper ownerState={{ completed, active, icon: icon as number }} className={className}>
      {!props.completed && <Typography className="text">{icon}</Typography>}
    </IconWrapper>
  )
}

interface Props {
  steps: string[]
  activeStep: number
}

const CustomizeReportStepper: FC<Props> = ({ steps, activeStep }) => (
  <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
    <Box sx={{ width: '516px' }}>
      <Stepper activeStep={activeStep}>
        {steps.map((label) => {
          const stepProps: { completed?: boolean } = {}
          const labelProps: {
            optional?: ReactNode
          } = {}
          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps} StepIconComponent={CustomStepIconComponent}>
                {label}
              </StepLabel>
            </Step>
          )
        })}
      </Stepper>
    </Box>
  </Box>
)

export default CustomizeReportStepper
