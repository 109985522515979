import { FC, Fragment } from 'react'
import { Box, Typography } from '@mui/material'
import { tooltipClasses } from '@mui/material/Tooltip'

/* Utils */
import { CPILibraryIntegration, PerformanceScoreCategoryDetailsDataSources } from '../../../models'

/* Components */
import { Container } from './dataSources.styles'
import ArrowUpTooltip from '../svg/ArrowUpTooltip'
import { FrameworksTooltip } from '../../pages/framework-configuration/components/FrameworksTooltip'
import { DataSourceTooltip } from '../../pages/dashboard/sections/category-activity/components/DataSourceTooltip'

interface Props {
  dataSources: PerformanceScoreCategoryDetailsDataSources[] | CPILibraryIntegration[]
}

const DataSources: FC<Props> = ({ dataSources }) => {
  const sources = dataSources.length > 3 ? dataSources.slice(0, 3) : dataSources

  const getLogoUrl = (source: PerformanceScoreCategoryDetailsDataSources | CPILibraryIntegration) => {
    if (Object.hasOwn(source, 'logo_path')) {
      return `/icons/circle/${(source as PerformanceScoreCategoryDetailsDataSources).logo_path}`
    } else {
      return `/icons/circle/${(source as CPILibraryIntegration).logo_url}`
    }
  }

  return (
    <Container className="data-sources-container">
      {sources.map((source, index) => (
        <Fragment key={index}>
          <FrameworksTooltip
            title={
              <>
                <Box className="tooltip-wrapper">
                  <ArrowUpTooltip />
                  <Typography className="tooltip-text">{source.display_name}</Typography>
                </Box>
              </>
            }
            sx={{
              [`& .${tooltipClasses.tooltip}`]: {
                width: 'fit-content !important'
              }
            }}
          >
            <img src={getLogoUrl(source)} alt="" width={24} height={24} className="data-source-image" />
          </FrameworksTooltip>
        </Fragment>
      ))}

      {dataSources.length > 3 && (
        <DataSourceTooltip
          title={
            <>
              <Box className="tooltip-list">
                {dataSources.map((source, index) => (
                  <Box className="tooltip-wrapper" key={index}>
                    <img src={getLogoUrl(source)} alt="" width={18} height={18} />
                    <Typography className="tooltip-title">{source.display_name}</Typography>
                  </Box>
                ))}
              </Box>
            </>
          }
        >
          <Typography className="sources-count-text">+ {dataSources.length - 3}</Typography>
        </DataSourceTooltip>
      )}
    </Container>
  )
}

export default DataSources
