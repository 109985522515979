import { FC, useCallback, useEffect, useRef, useState } from 'react'
import { Form, Formik } from 'formik'
import { useNavigate, useParams } from 'react-router-dom'
import { Box, CircularProgress, IconButton, InputAdornment, Typography } from '@mui/material'
import axios from '../../../../lib/axios'

/* Utils */
import { defaultValues } from './setupIntegration.constants'
import { useAppDispatch } from '../../../../store/hooks'

/* Components */
import { EditIntegrationContainer, EditIntegrationsTitle, IntegrationsContainer } from './integrations.styles'
import { ManifestIntegration, ManifestIntegrationConfigSchema } from '../../../../models'
import { ThemeButton } from '../../../components/buttons'
import {
  IntegrationsCheckIcon,
  IntegrationsFailIcon,
  RightIconButton,
  SaveIconButton,
  VisibilityOffIcon,
  VisibilityOnIcon
} from '../../../components/svg'
import ControlledTextField from '../../../components/form-components/ControlledTextField'
import { closeToast, openToast, ToastVariant } from '../../../../store/toasts/toastSlice'
import ReactMarkdown from 'react-markdown'
import { stringOfNumberedListToArray } from '../../../../lib/utils'
import { EnlargedImage } from './components'
import rehypeRaw from 'rehype-raw'
import ControlledTrendsSelectField from '../../../components/form-components/ControlledTrendsSelectField'

export enum TestStatus {
  unchecked = 'unchecked',
  successful = 'successful',
  failed = 'failed'
}

interface SchemaItem {
  name: string
  label: string
  default: string | null
  required: boolean
}

interface Props {
  setupType: 'add' | 'edit'
}

const SetupIntegrationInstance: FC<Props> = ({ setupType }) => {
  const params = useParams()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const closeNotification = useRef<any>(null)
  const [integration, setIntegration] = useState<ManifestIntegration | null>(null)
  const [integrationLoading, setIntegrationLoading] = useState(true)
  const [loading, setLoading] = useState(false)
  const [testing, setTesting] = useState(false)
  const [saving, setSaving] = useState(false)
  const [dataLoading, setDataLoading] = useState(true)
  const [testStatus, setTestStatus] = useState<TestStatus>(TestStatus.unchecked)
  const [initialValues, setInitialValues] = useState<Record<string, string>>(defaultValues)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [configSchema, setSchema] = useState<SchemaItem[] | null>(null)
  const [instructions, setInstructions] = useState<RegExpMatchArray | null>(null)
  const [visibleValues, setVisibleValues] = useState<Record<string, boolean>>({})

  useEffect(() => {
    if (initialValues) {
      const valuesArr = Object.keys(initialValues)
      const valuesObj: Record<string, boolean> = {}
      valuesArr.forEach((key) => (valuesObj[key] = false))
      setVisibleValues(valuesObj)
    }
  }, [initialValues])

  const stepsImages: { [key: string]: number[] } = {
    wiz: [2, 3, 4, 5],
    microsoft_sentinel: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16],
    microsoft_defender365: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
    microsoft_defendervm: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
    crowdstrike_falcon: [1, 2, 3, 4, 5, 6, 7],
    crowdstrike_spotlight: [2, 3, 4, 5],
    knowbe4_kmsat: [1, 2, 3, 4],
    qualys_vm: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
    rapid7_vm: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
    tenable_vm: [2, 3, 4, 5, 6, 7, 8, 9, 10, 12, 13, 14, 15, 16, 17],
    microsoft_entraid: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
    google_workspace: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20],
    orca: [1, 2, 3, 4, 5, 6, 7, 8, 9],
    microsoft_defenderforcloud: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
    cofense_phishme: [1, 2, 3, 4, 5],
    proofpoint_sat: [2, 3, 4, 5],
    sentinelone_singularity: [1, 2, 3, 4, 5, 6, 7, 8],
    okta: [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23],
    microsoft_intune: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
    cisco_duo: [1, 2, 3, 4, 5],
    cisco_umbrella: [1, 2, 3, 4],
    jamf_pro: [1, 2, 3, 4, 5, 6, 7, 8],
    jumpcloud: [1, 2, 3, 4, 5],
    cloudflare: [1, 2, 3, 4, 5, 6, 7, 8, 9],
    mimecast: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
    rapid7_appsec: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
    vmware_workspaceone: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
    checkmarx_one: [1, 2, 3, 4, 5, 6, 7, 8, 9],
    knowbe4_phisher: [2, 3, 4, 5, 6, 7],
    proofpoint_tap: [1, 2, 3, 4]
  }

  useEffect(() => {
    if (!integration) return

    if (integration?.documentation?.path) {
      import(`./instructions/${integration?.documentation?.path}_instructions.md`)
        .then((res) => {
          fetch(res.default)
            .then((res) => res.text())
            .then((res) => {
              setInstructions(stringOfNumberedListToArray(res, integration.name))
            })
            .catch((err) => console.error(err))
            .finally(() => {
              setIntegrationLoading(false)
            })
        })
        .catch((err) => console.error(err))
    } else {
      setTimeout(() => {
        setIntegrationLoading(false)
      }, 200)
    }
  }, [integration])

  const fetchIntegrations = useCallback(async () => {
    try {
      const res = await axios.get('/api/v3/integrations/supported')

      if (res.status === 200) {
        const { integrations } = res.data
        const integration = integrations.find((n: ManifestIntegration) => n.name === params.integrationName)
        setIntegration(integration)

        let currentName = integration.name
        if (integration.name === 'microsoft_defender365') {
          currentName = integration.display_name.toLowerCase().replaceAll(' ', '_')
        }
        const instanceValues: Record<string, string> = {
          name: currentName,
          description: integration.description || ''
        }
        if (integration.config.schema) {
          integration.config.schema.forEach((n: Record<string, any>) => {
            instanceValues[n.name] = ''
          })
        }
        setInitialValues(instanceValues)
      }
    } catch (e) {
      console.error(e)
    }
  }, [])

  const fetchInstanceConfig = useCallback(async () => {
    try {
      const res = await axios.get(`/api/v3/integrations/config/${params.instanceId}`)

      if (res.status === 200) {
        const { data: instance } = res.data
        const { config } = instance
        let instanceValues: Record<string, string> = {
          name: instance.name,
          description: instance.description || ''
        }
        if (config.schema) {
          config.schema.forEach((n: Record<string, any>) => {
            instanceValues[n.name] = ''
          })
        } else {
          // const regex = /^\*.*\*$/
          instanceValues = {
            ...instanceValues,
            ...config
          }

          // for (const [key, value] of Object.entries(config)) {
          // const test = regex.test(value as string)
          // if (test) {
          //   instanceValues[key] = ''
          // }
          // }
        }
        setInitialValues(instanceValues)
      }
    } catch (e) {
      console.error(e)
    }
  }, [])

  const fetchData = async () => {
    setDataLoading(true)

    try {
      await fetchIntegrations()
      if (setupType === 'edit' && params.instanceId) {
        await fetchInstanceConfig()
      }
    } catch (e) {
      console.error(e)
    } finally {
      setDataLoading(false)
    }
  }

  useEffect(() => {
    fetchData().catch((e) => e)
  }, [setupType, params.instanceId])

  useEffect(() => {
    if (integration && integration.config.schema) {
      const result = integration.config.schema
      const items: SchemaItem[] = []
      result.forEach((n) => {
        const item = {
          name: n.name,
          label: n.title,
          default: n.default ?? null,
          required: n.required
        }
        items.push(item)
      })
      setSchema(items)

      if (setupType === 'add') {
        const initialValues: Record<string, string> = {}
        if (integration.config.schema) {
          integration.config.schema.forEach((n) => {
            initialValues[n.name] = ''
          })
        }
        setInitialValues(initialValues)
      }
    }
  }, [integration])

  const handleSubmit = async (values: Record<string, string>) => {
    setLoading(true)
    setTesting(true)
    const configValues = {
      ...values
    }
    delete configValues.integrationName
    delete configValues.description
    delete configValues.name

    try {
      const payload = {
        integrationName: integration?.name || '',
        description: values.description,
        name: values.name,
        config: configValues
      }

      let res
      if (setupType === 'add') {
        res = await axios.post(`/api/v3/integrations/${integration?.name || ''}/check_connection`, payload)
      } else {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        delete payload.description
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        delete payload.integrationName
        res = await axios.post(`/api/v3/integrations/config/${params.instanceId}/check_connection`, payload)
      }

      if (res.data.status === 'failed') {
        setTestStatus(TestStatus.failed)
        dispatch(
          openToast({
            variant: ToastVariant.error,
            props: {
              text: 'Test Failed',
              description: 'Failed to verify credentials, please make sure you have the correct information.'
            }
          })
        )
      } else {
        setTestStatus(TestStatus.successful)
        dispatch(
          openToast({
            variant: ToastVariant.success,
            props: {
              text: 'Test Successful',
              description: 'Click on Save & Exit to finish configuring your instance.'
            }
          })
        )
      }
    } catch (e) {
      console.error(e)
      setTestStatus(TestStatus.failed)
      dispatch(
        openToast({
          variant: ToastVariant.error,
          props: {
            text: 'Test Failed',
            description: (e as any).response?.data?.message || 'Please double check your configuration'
          }
        })
      )
    } finally {
      setLoading(false)
      setTesting(false)
      closeNotification.current = setTimeout(() => {
        dispatch(closeToast())
      }, 5000)
    }
  }

  const handleSave = async (values: Record<string, string>) => {
    clearTimeout(closeNotification.current)
    setLoading(true)
    setSaving(true)
    const configValues = {
      ...values
    }
    delete configValues.integrationName
    delete configValues.description
    delete configValues.name

    try {
      if (setupType === 'edit') {
        const payload = {
          name: values.name,
          description: values.description,
          config: configValues
        }

        await axios.put(`/api/v3/integrations/config/${params.instanceId}`, payload)
        dispatch(
          openToast({
            variant: ToastVariant.success,
            props: {
              text: 'Instance Created',
              description: `Your ${integration?.display_name} instance was changed.`
            }
          })
        )
        navigate('/settings/integrations')
      } else {
        const payload = {
          integrationName: integration?.name || '',
          description: values.description,
          name: values.name,
          config: configValues
        }

        await axios.post('/api/v3/integrations/config', payload)
        dispatch(
          openToast({
            variant: ToastVariant.success,
            props: {
              text: 'Instance Created',
              description: `Your ${integration?.display_name} instance was created.`
            }
          })
        )
        navigate('/settings/integrations')
      }
    } catch (e) {
      console.error(e)
      setTestStatus(TestStatus.failed)
      dispatch(
        openToast({
          variant: ToastVariant.error,
          props: {
            text: 'Failed Saving Configuration',
            description: (e as any).response?.data?.message || 'Please double check your configuration'
          }
        })
      )
    } finally {
      setLoading(false)
      setSaving(false)
      setTimeout(() => {
        dispatch(closeToast())
      }, 5000)
    }
  }

  const sortIntegrationsSchemaByOrder = (items: ManifestIntegrationConfigSchema[]) => {
    return items.sort((a, b) => ((a as any).order > (b as any).order ? 1 : -1))
  }

  if (!integration || dataLoading) {
    return <IntegrationsContainer />
  }

  return (
    <IntegrationsContainer hasPadding={true}>
      <EditIntegrationsTitle>
        {setupType === 'add' ? 'Add' : 'Edit'} {integration?.display_name} Integration Instance
      </EditIntegrationsTitle>
      <Formik initialValues={initialValues} onSubmit={handleSubmit} validateOnMount>
        {({ isSubmitting, isValid, values }) => (
          <Form autoComplete="off">
            <EditIntegrationContainer testStatus={testStatus}>
              <Box id="left-side" className="left">
                <Box className="info">
                  <ControlledTextField
                    name="name"
                    label="Name"
                    required
                    onBlur={() => testStatus === TestStatus.failed && setTestStatus(TestStatus.unchecked)}
                  />
                  <ControlledTextField
                    name="description"
                    label="Description"
                    multiline
                    rows={4}
                    className="text-area"
                    onBlur={() => testStatus === TestStatus.failed && setTestStatus(TestStatus.unchecked)}
                  />
                  {integration.config.schema &&
                    sortIntegrationsSchemaByOrder(integration.config.schema).map((n) => {
                      if (n.enum && n.enum.length > 0) {
                        return (
                          <ControlledTrendsSelectField
                            options={n.enum}
                            placeholder={n.title}
                            label="Region"
                            labeltext={n.title}
                            name={n.name}
                            disabled={false}
                            multiple={false}
                            fullWidth
                          />
                        )
                      }
                      return (
                        <ControlledTextField
                          data-private
                          key={n.name}
                          name={n.name}
                          label={n.title}
                          required={n.required}
                          onBlur={() => testStatus === TestStatus.failed && setTestStatus(TestStatus.unchecked)}
                          autoComplete="new-password"
                          type={n.type === 'integer' ? 'number' : visibleValues[n.name] ? 'text' : 'password'}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle Client Secret visibility"
                                  onClick={() =>
                                    setVisibleValues((pre) => ({ ...pre, [n.name]: !visibleValues[n.name] }))
                                  }
                                  edge="end"
                                >
                                  {!visibleValues[n.name] ? <VisibilityOffIcon /> : <VisibilityOnIcon />}
                                </IconButton>
                              </InputAdornment>
                            )
                          }}
                        />
                      )
                    })}
                </Box>
                <Box className="test">
                  <Typography className="test-source-text">Test Source</Typography>
                  <ThemeButton
                    type="submit"
                    disabled={isSubmitting || !isValid || loading || !integration?.config?.schema}
                    themevariant="quaternary"
                    endIcon={testing && <CircularProgress size="24px" color="secondary" />}
                  >
                    <>
                      {testStatus === TestStatus.unchecked && (
                        <>
                          <RightIconButton />
                          Test The Source
                        </>
                      )}
                      {testStatus === TestStatus.successful && (
                        <>
                          <IntegrationsCheckIcon />
                          Test Successful
                        </>
                      )}
                      {testStatus === TestStatus.failed && (
                        <>
                          <IntegrationsFailIcon />
                          Test Failed
                        </>
                      )}
                    </>
                  </ThemeButton>
                </Box>
                <Box className="actions">
                  <ThemeButton
                    themevariant="tertiary"
                    type="button"
                    sx={{ paddingLeft: '8px !important', paddingRight: '8px !important' }}
                    onClick={() => {
                      navigate(-1)
                    }}
                  >
                    <>
                      <RightIconButton style={{ transform: 'rotate(180deg)' }} /> Discard Changes
                    </>
                  </ThemeButton>
                  <ThemeButton
                    type="button"
                    themevariant="quaternary"
                    onClick={() => handleSave(values)}
                    disabled={
                      testStatus !== TestStatus.successful ||
                      isSubmitting ||
                      !isValid ||
                      loading ||
                      !integration?.config?.schema
                    }
                    endIcon={saving && <CircularProgress size="24px" color="secondary" />}
                  >
                    <>
                      <SaveIconButton />
                      Save & Exit
                    </>
                  </ThemeButton>
                </Box>
              </Box>

              <Box id="right-side" className="right">
                <Typography className="instructions-text">Instructions</Typography>
                <br />
                {integrationLoading ? (
                  ''
                ) : (
                  <>
                    {!instructions?.length ? (
                      <Typography className="instructions-text">N/A</Typography>
                    ) : (
                      <Typography className="instructions-text">
                        Following these steps will successfully integrate your {integration.display_name} platform with
                        Onyxia.
                      </Typography>
                    )}
                  </>
                )}

                {instructions?.map((instruction, index) => (
                  <Box className="instructions-inner" key={instruction}>
                    <ReactMarkdown rehypePlugins={[rehypeRaw]}>{instruction}</ReactMarkdown>
                    {stepsImages[integration.documentation.path] &&
                      stepsImages[integration.documentation.path].includes(++index) && (
                        <EnlargedImage index={index} integration={integration} />
                      )}
                  </Box>
                ))}
              </Box>
            </EditIntegrationContainer>
          </Form>
        )}
      </Formik>
    </IntegrationsContainer>
  )
}

export default SetupIntegrationInstance
