/* eslint-disable no-unused-vars */
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../store'
import { CPIManifest, FormWeightI, IntegrationInstance, TableData, User, UserRole, Vulnerability } from '../../models'

export enum ModalType {
  jira = 'jira',
  auth0 = 'auth0',
  jiraTicket = 'jiraTicket',
  crowdstrike = 'crowdstrike',
  mssentinel = 'mssentinel',
  onboardingForm = 'onboardingForm',
  startOnboarding = 'startOnboarding',
  inviteUser = 'inviteUser',
  deleteUsers = 'deleteUsers',
  userProfile = 'userProfile',
  changeName = 'changeName',
  changeEmail = 'changeEmail',
  changeRole = 'changeRole',
  deleteAccount = 'deleteAccount',
  deleteIntegrationInstance = 'deleteIntegrationInstance',
  deactivateCpi = 'deactivateCpi',
  transferOwnership = 'transferOwnership',
  deselectNotification = 'deselectNotification',
  zoomIn = 'zoomIn',
  deleteDeactivation = 'deleteDeactivation',
  ssoSupport = 'ssoSupport',
  ssoVerificationFailed = 'ssoVerificationFailed',
  ssoDelete = 'ssoDelete',
  cancelChangesWeight = 'cancelChangesWeight',
  revertDefaultValuesWeight = 'revertDefaultValuesWeight',
  updateWeights = 'updateWeights'
}

export enum ModalVariant {
  add = 'add',
  edit = 'edit',
  view = 'view',
  delete = 'delete'
}

interface IZoomIn {
  name?: string
  url?: string
}

export interface ModalProps {
  vulnerability?: Vulnerability
  task?: TableData
  integrationInstance?: IntegrationInstance
  cpiSupportedAndUserConfiguredList?: IntegrationInstance[]
  manifestData?: CPIManifest | null
  cpiName?: string
  integrationName?: string
  users?: User[]
  roles?: UserRole[]
  user?: User
  zoomIn?: IZoomIn
  callback?: () => void
  connection?: any
  isVerified?: boolean
  weightsData?: FormWeightI
  closeback?: () => void
  confirmNavigation?: any
  cancelNavigation?: any
  isAnalyzer?: boolean
}

export interface ModalState {
  type?: ModalType
  open: boolean
  variant: ModalVariant
  props?: ModalProps
}

const initialState: ModalState = {
  type: undefined,
  open: false,
  variant: ModalVariant.add,
  props: undefined
}

export const modalSlice = createSlice({
  name: 'Modals',
  initialState,
  reducers: {
    openModal: (
      state: ModalState,
      action: PayloadAction<{
        type?: ModalType
        variant?: ModalVariant
        props?: ModalProps
      }>
    ) => {
      const { type, variant, props } = action.payload

      state.type = type
      state.open = true
      state.variant = variant ?? ModalVariant.add
      state.props = props
    },
    closeModal: (state: ModalState) => {
      state.type = undefined
      state.open = false
    },
    setModalProps: (
      state: ModalState,
      action: PayloadAction<{
        type?: ModalType
        variant?: ModalVariant
        props?: ModalProps
        open: boolean
      }>
    ) => {
      const { type, variant, props, open } = action.payload

      state.type = type
      state.open = open
      state.variant = variant ?? ModalVariant.add
      state.props = props
    }
  }
})

export const modalPropsSelector = (state: RootState): ModalState => state.modals

export const { openModal, closeModal, setModalProps } = modalSlice.actions

export default modalSlice.reducer
