import React, { ChangeEvent, Dispatch, FC, SetStateAction, useEffect, useState } from 'react'

/* Utils */
import { FormWeightI } from '../../../../models'
import { useAuth } from '@frontegg/react'

/* Components */
import { Container, FrameworkSection } from './styles/CategoryWeights.styles'
import { Box, InputAdornment, TextField, Typography } from '@mui/material'
import TotalPercentageNotify from './TotalPercentageNotify'
import FrameworkSelectField from './FrameworkSelectField'
import { frameworkDefaultOptions } from '../framework.constants'

interface Props {
  isEdit: boolean
  form: FormWeightI
  setForm: Dispatch<SetStateAction<any>>
  setIsChanged: Dispatch<SetStateAction<boolean>>
  setIsFormValid: Dispatch<SetStateAction<boolean>>
  reFetchDataByFramework: (name: string) => void
}

const CategoryWeights: FC<Props> = ({
  isEdit,
  form,
  setForm,
  setIsChanged,
  setIsFormValid,
  reFetchDataByFramework
}) => {
  const { user: authUser } = useAuth()
  const [isValid, setIsValid] = useState(false)
  const [total, setTotal] = useState(100)

  useEffect(() => {
    setIsFormValid(isValid)
  }, [isValid])

  useEffect(() => {
    let sum = 0
    form.categories.forEach((item) => {
      sum += +item.weight
    })

    setTotal(Math.round(sum))
    setIsValid(Math.round(sum) === 100)
  }, [form])

  const handleInput = (e: ChangeEvent<HTMLInputElement>) => {
    const updatedCategories = form.categories.map((categoryItem) => {
      if (categoryItem.id === e.target.name) {
        return {
          ...categoryItem,
          weight: +e.target.value,
          isEdit: true
        }
      }
      return categoryItem
    })

    const updatedForm = {
      ...form,
      categories: updatedCategories
    }

    setIsChanged(true)
    setForm(updatedForm)
  }

  const changeHandler = (e: React.ChangeEvent<any>) => {
    setIsChanged(true)
    const findItem = {
      ...frameworkDefaultOptions.find((item) => item.value === e.target.value),
      isEdit: true
    }
    setForm((prevState: any) => ({
      ...prevState,
      categoryFramework: findItem
    }))

    reFetchDataByFramework(findItem?.value || '')
  }

  const checkOrder = (a: { value: any }, b: { value: any }) => {
    if (a.value === form.categoryFramework.value && b.value !== form.categoryFramework.value) {
      return -1
    } else if (a.value !== form.categoryFramework.value && b.value === form.categoryFramework.value) {
      return 1
    } else return 0
  }

  return (
    <Container>
      <Typography className="title">Category Weights</Typography>

      <FrameworkSection>
        <Typography className="sub-title">For this Framework : </Typography>
        {isEdit ? (
          <FrameworkSelectField
            disabled={false}
            disabledNew={!['owner', 'admin'].includes(authUser?.roles[0].key || '')}
            name="framework"
            className="select-box"
            options={frameworkDefaultOptions.sort(checkOrder)}
            placeholder="Select framework"
            labeltext="Filter by: "
            value={form.categoryFramework.value}
            handleChange={changeHandler}
            minWidth="75px !important"
          />
        ) : (
          <Box className="framework">
            <img src={form.framework.icon} alt={form.framework.label} width={24} height={24} />
            <Typography className="text">{form.framework.label}</Typography>
          </Box>
        )}
      </FrameworkSection>

      <Box className="header">
        <Typography className="name">Category</Typography>
        <Typography className="value">Weights</Typography>
      </Box>

      <Box className="content">
        {form.categories.map((category) => {
          return (
            <Box className="content-item" key={category.id}>
              <Typography className="name">{category.name}</Typography>
              {isEdit ? (
                <TextField
                  disabled={!['owner', 'admin'].includes(authUser?.roles[0].key || '')}
                  variant="outlined"
                  name={category.id}
                  required
                  className={
                    String(category.weight).length === 3
                      ? 'length3'
                      : String(category.weight).length === 2
                      ? 'length2'
                      : 'length1'
                  }
                  inputProps={{ min: 1, value: category.weight ? category.weight : '', max: 100 }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="start" className="percentage">
                        %
                      </InputAdornment>
                    )
                  }}
                  onChange={handleInput}
                />
              ) : (
                <Typography className="value">{category.weight}%</Typography>
              )}
            </Box>
          )
        })}

        {isEdit ? (
          <>
            <Box className="line" />
            <TotalPercentageNotify isValid={isValid} show={false} total={total} hidePanel={true} />
          </>
        ) : (
          ''
        )}
      </Box>
    </Container>
  )
}

export default CategoryWeights
