import Tooltip, { tooltipClasses, TooltipProps } from '@mui/material/Tooltip'
import styled from '@emotion/styled/macro'

const BenchmarkRangeTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    width: '200px',
    borderRadius: '8px',
    border: '1px solid #998D96',
    background: '#4D444B',
    boxShadow: '0 1px 2px 0 rgba(0, 0, 0, 0.30), 0 1px 3px 1px rgba(0, 0, 0, 0.15)',
    padding: '16px'
  },

  '.tooltip-value': {
    '.MuiTypography-root': {
      color: '#FFF',
      fontFamily: '"Quicksand", sans-serif',
      fontWeight: 400,
      fontStyle: 'normal',
      textAlign: 'start',
      wordBreak: 'break-word',
      whiteSpace: 'pre-wrap'
    },

    '.tooltip-title': {
      fontSize: '24px',
      lineHeight: '32px',
      marginBottom: '3px'
    },

    '&.tooltip-text': {
      fontSize: '12px',
      lineHeight: '16px',
      letterSpacing: '0.4px'
    }
  }
}))

export default BenchmarkRangeTooltip
