import { FC, useEffect, useRef } from 'react'
import * as d3 from 'd3'
import dayjs from 'dayjs'

/* Utils */
import useResizeObserver from '../../../../../../../hooks/useResizeObserver'
import { CPIFrequency, CPITimePeriod, HistoryBenchmarkDataValues } from '../../../../../../../models'
import {
  formatBenchmarkingXLabel,
  groupBenchmarkingDataByMonths,
  groupBenchmarkingDataByWeeks
} from '../performanceScoreUtils'
import { fillBlankDataBenchmarking, formatTooltipDate } from '../../../../utils'
import { HistoricalBenchmarkingChartWrapper } from '../../../categoryPage.styles'

const keys = ['less', 'equal', 'more']
const colors = {
  less: '#8E9AFF',
  equal: '#F09543',
  more: '#943798'
}
const margin = {
  left: 10,
  right: 10,
  top: 0,
  bottom: 0
}

interface Props {
  data: HistoryBenchmarkDataValues[]
  selectedTimePeriod: CPITimePeriod
}

const BenchmarkingScoreChart: FC<Props> = ({ data, selectedTimePeriod }) => {
  const svgRef = useRef<SVGSVGElement | null>(null)
  const yAxisRef = useRef<SVGSVGElement | null>(null)
  const wrapperRef = useRef<HTMLDivElement | null>(null)
  const tooltipRef = useRef<HTMLDivElement | null>(null)
  const dimensions = useResizeObserver(wrapperRef)

  useEffect(() => {
    if (!wrapperRef || !wrapperRef.current) return

    const { width: defaultWidth, height } = dimensions || wrapperRef.current.getBoundingClientRect()
    const width = defaultWidth - 30 - 32
    const normalizedData = data.map((item) => {
      const sum = item.less + item.more + item.equal

      if (sum === 0) return item

      if (sum !== 100) {
        return {
          ...item,
          more: 100 - (item.less + item.equal)
        }
      }
      return item
    })
    const dataStartsAt = dayjs(data[0].date || '').format('YYYY-MM-DD')

    let groupedData = groupBenchmarkingDataByWeeks(normalizedData)
    groupedData = fillBlankDataBenchmarking(groupedData as any, selectedTimePeriod) as any[]
    const groupedByMonth = groupBenchmarkingDataByMonths(groupedData)
    const svg = d3.select(svgRef.current)
    const everything = svg.selectAll('*')
    everything.remove()
    const yAxisSvg = d3.select(yAxisRef.current)
    const tooltip = d3.select('.benchmarking-tooltip')
    const stackGenerator = d3.stack().keys(keys)
    const layers = stackGenerator(groupedByMonth as any)

    let monthGroups: any[] = []

    groupedByMonth.forEach((n) => {
      const monthDates = monthGroups.map((n) => dayjs(n.date).format('MM/YY'))
      const currentMonth = dayjs(n.date).format('MM/YY')
      if (!monthDates.includes(currentMonth)) {
        monthGroups.push(n)
      }
    })
    monthGroups = monthGroups.sort((a, b) => (new Date(b.date) > new Date(a.date) ? -1 : 1))

    const x0 = d3
      .scaleBand()
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      .domain(groupedByMonth.map((d) => d.date))
      .range([margin.left, width - margin.right])
      .padding(0.1)

    const xAxisScale = d3
      .scaleBand()
      .domain(monthGroups.map((d) => d.date))
      .range([margin.left, width - margin.right])
      .padding(0.1)

    const yScale = d3
      .scaleLinear()
      .domain([0, 100])
      .range([height - 50, 0])

    /* Append Data */
    const appendBars = () => {
      const bars = svg
        .attr('width', width)
        .attr('height', height)
        .selectAll('.layer')
        .data(layers)
        .join('g')
        .attr('class', 'layer')
        .attr('fill', (layer) => (colors as any)[layer.key])
        .selectAll('rect')
        .data((layer) => layer)
        .join('rect')
        .attr('class', 'data-bar')
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        .attr('x', (sequence) => x0(sequence.data.date))
        .attr('width', x0.bandwidth())
        .attr('pointer-events', 'all')
        .attr('cursor', 'pointer')
        .on('mouseover', function () {
          tooltip.style('display', 'block')
        })
        .on('mouseout', function () {
          tooltip.style('display', 'none')
        })
        .on('mousemove', function (event, barItem) {
          const {
            data: { start, end, less, more, equal }
          } = barItem
          const startDate = dayjs(start).format('YYYY-MM-DD')
          const endDate = dayjs(end).format('YYYY-MM-DD')

          let foundLayer: any
          let foundBar: any

          layers.forEach((layer) => {
            layer.forEach((bar) => {
              const { data } = bar
              if (
                bar[0] === barItem[0] &&
                bar[1] === barItem[1] &&
                data.start === start &&
                data.end === end &&
                data.less === less &&
                data.more === more &&
                data.equal === equal
              ) {
                foundLayer = layer
                foundBar = bar
              }
            })
          })

          if (!foundLayer) return null

          tooltip.style('display', 'block')

          const key = foundLayer.key
          const value = foundBar.data[key]
          const xPosition = event.layerX - 190
          const yPosition = event.layerY + 25
          const formattedDate = formatTooltipDate(
            startDate,
            endDate,
            CPIFrequency.Weekly,
            selectedTimePeriod,
            dataStartsAt
          )
          const unitInfoText = `of companies had ${key} ${key === 'same' ? '' : 'strict'} SLA from ${formattedDate}`

          tooltip
            .html(
              `
              <p class='tooltip-performance'>${value}%</p>
              <p class='tooltip-units'>${unitInfoText}</p>
            `
            )
            .style('top', `${yPosition}px`)
            .style('left', `${xPosition}px`)
          tooltip.attr('transform', 'translate(' + xPosition + ',' + yPosition + ')')
        })

      bars
        .transition()
        .duration(500)
        .attr('y', (sequence) => yScale(sequence[1]))
        .attr('height', (sequence) => {
          if (!isNaN(sequence[0]) && !isNaN(sequence[1])) {
            return yScale(sequence[0]) - yScale(sequence[1])
          } else {
            return 0
          }
        })
    }
    appendBars()

    const appendXAxis = () => {
      const xAxis = d3
        .axisBottom(selectedTimePeriod === CPITimePeriod.Month ? x0 : xAxisScale)
        .tickFormat((date, i) =>
          formatBenchmarkingXLabel(
            date,
            selectedTimePeriod,
            selectedTimePeriod === CPITimePeriod.Month ? groupedByMonth : monthGroups,
            i
          )
        )
        .tickSizeInner(0)
        .tickSizeOuter(0)
        .tickPadding(8)
      const existingXAxis = svg.selectAll('.benchmarking-x-axis')
      if (!existingXAxis.empty()) existingXAxis.remove()

      const xAxisGroup = svg
        .append('g')
        .attr('class', 'benchmarking-x-axis')
        .attr('transform', `translate(0, ${height - 50})`)
        .call(xAxis)

      let fontSize = 12
      if (selectedTimePeriod === CPITimePeriod.Month && groupedByMonth.length >= 8) {
        fontSize = 11
      }

      if (selectedTimePeriod === 'Past Year') {
        xAxisGroup
          .selectAll('text')
          .data(groupedByMonth)
          .attr('text-anchor', 'middle')
          .attr('color', '#fff')
          .attr('font-size', `${fontSize}px`)
          .attr('font-weight', '400')
          .attr('letter-spacing', '0.1px')
          .attr('line-height', '16px')
          .attr('font-family', "'Quicksand', sans-serif")
          .style('transform', 'rotate(45deg)')
          .style('margin-top', '8px')
        xAxisGroup.style('transform', 'translate(0, 432px)')
      } else {
        xAxisGroup
          .selectAll('text')
          .data(groupedByMonth)
          .attr('text-anchor', 'middle')
          .attr('color', '#fff')
          .attr('font-size', `${fontSize}px`)
          .attr('font-weight', '400')
          .attr('letter-spacing', '0.1px')
          .attr('line-height', '16px')
          .attr('font-family', "'Quicksand', sans-serif")
      }
    }
    appendXAxis()

    const appendYAxis = () => {
      const yAxis = d3
        .axisLeft(yScale)
        .ticks(5)
        .tickFormat((d, i) => (i === 0 ? '0%' : i === 5 ? '100%' : ''))
        .tickSizeInner(0)
        .tickSizeOuter(0)
        .tickPadding(3)

      yAxisSvg
        .select('.benchmarking-y-axis-g')
        .attr('height', height)
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        .call(yAxis)
        .selectAll('text')
        .attr('dy', '18px')
        .attr('color', '#fff')
        .attr('font-size', '12px')
        .attr('font-weight', '400')
        .attr('letter-spacing', '0.4px')
        .attr('line-height', '16px')
        .attr('font-family', "'Quicksand', sans-serif")
    }
    appendYAxis()

    /* Remove X and Y-Axis lines and ticks */
    const axisLines = svg.selectAll('path,line')
    axisLines.remove()
    yAxisSvg.selectAll('path,line').remove()

    /* Border outline */
    svg
      .append('rect')
      .attr('x', 0)
      .attr('y', 0)
      .attr('height', height - 50)
      .attr('width', width)
      .attr('class', 'border-outline')
  }, [data, dimensions])

  return (
    <HistoricalBenchmarkingChartWrapper ref={wrapperRef}>
      <div ref={tooltipRef} className="benchmarking-tooltip" />
      <svg ref={yAxisRef} className="benchmarking-chart-y-axis" width="0.1">
        <g className="benchmarking-y-axis-g" />
      </svg>
      <svg className="benchmarking-chart-svg" ref={svgRef}>
        <g className="benchmarking-x-axis" />
      </svg>
    </HistoricalBenchmarkingChartWrapper>
  )
}

export default BenchmarkingScoreChart
