import styled from '@emotion/styled'
import { Box } from '@mui/material'

export const FilterWrapper = styled(Box)<{ showFilter: boolean }>`
  //border-left: ${({ showFilter }) => (showFilter ? '1px solid #4d444b' : 'none')};
  width: ${({ showFilter }) => (showFilter ? '396px' : '0px')};
  min-width: ${({ showFilter }) => (showFilter ? '396px' : '0px')};
  visibility: ${({ showFilter }) => (showFilter ? 'visible' : 'hidden')};
  opacity: ${({ showFilter }) => (showFilter ? '1' : '0')};
  padding-left: ${({ showFilter }) => (showFilter ? '24px' : '0')};
  margin-left: ${({ showFilter }) => (showFilter ? '24px' : '0')};
  transition: all 0.3s ease-in-out;
  height: 100%;

  .header-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 24px;

    .title {
      color: #fff;
      font-family: 'Quicksand', sans-serif;
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      white-space: nowrap;
    }

    .reset {
      color: #ffa9fc;
      font-size: 16px;
      font-style: normal;
      font-weight: 510;
      line-height: normal;
      cursor: pointer;
      white-space: nowrap;
    }

    .actions {
      display: flex;
      align-items: center;
      gap: 16px;
    }
  }
`

export const ContentWrapper = styled(Box)`
  overflow-y: auto;
  height: 100%;
  overflow-x: hidden;

  ::-webkit-scrollbar {
    width: 4px;
    background: transparent;
  }

  ::-webkit-scrollbar-track {
    box-shadow: none;
    background: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #ffa9fc;
    border-radius: 16px;
  }

  > div {
    width: 371px;
    min-width: 371px;
  }
`
