import { FC, MouseEvent } from 'react'
import { Grid, Box, Typography, InputAdornment, IconButton } from '@mui/material'
import { Visibility, VisibilityOff } from '@mui/icons-material'

/* Utils */
import { ModalVariant } from '../../../../../store/modals/modalSlice'

/* Components */
import {
  GridItem,
  Container,
  InputLabel,
  FormControl,
  GridItemWrapper,
  outlinedInputSx
} from './auth0Integration.styles'
import Toast from '../../../toast/Toast'
import ControlledOutlinedInput from '../../../form-components/ControlledOutlinedInput'

interface Props {
  success: boolean | null
  variant: ModalVariant
  activeStep: number
  showPassword: boolean
  errorMessage: string
  handleClickShowPassword: () => void
  handleMouseDownPassword: (event: MouseEvent<HTMLButtonElement>) => void
}

const Auth0IntegrationContent: FC<Props> = ({
  success,
  variant,
  activeStep,
  showPassword,
  errorMessage,
  handleClickShowPassword,
  handleMouseDownPassword
}) => (
  <Box sx={{ minHeight: '364px' }}>
    <Typography variant="h1" fontSize="36px" lineHeight="44px" fontWeight={800} mb="8px">
      {variant === 'add' ? 'Configure' : 'Modify'} Your Auth-0 Integration
    </Typography>
    <img src="/auth0-white.svg" alt="Auth0 logo" />
    {success === false && <Toast variant="error" message={errorMessage || undefined} sx={{ mt: '40px' }} />}
    {success && <Toast variant="success" sx={{ mt: '40px' }} />}
    {activeStep === 0 && (
      <>
        <Container container spacing={6} alignItems="center" justifyContent="center" mb="30px">
          <Grid item xs={12}>
            <Grid container spacing={3} mt="20px" alignItems="center" justifyContent="center">
              <GridItem item xs={12}>
                <GridItemWrapper>
                  <InputLabel>Domain *</InputLabel>
                  <FormControl>
                    <ControlledOutlinedInput
                      name="domain"
                      className="auth0"
                      placeholder="ex: https://example.us.auth0.com"
                      sx={outlinedInputSx}
                    />
                  </FormControl>
                </GridItemWrapper>
              </GridItem>
              <GridItem item xs={12}>
                <GridItemWrapper>
                  <InputLabel>Client ID *</InputLabel>
                  <FormControl>
                    <ControlledOutlinedInput
                      name="clientId"
                      className="auth0"
                      placeholder="ex: JSkuSKhjbSUkjbSIH98yS8yJSkuw"
                      sx={outlinedInputSx}
                    />
                  </FormControl>
                </GridItemWrapper>
              </GridItem>
              <GridItem item xs={12}>
                <GridItemWrapper>
                  <InputLabel>Client Secret *</InputLabel>
                  <FormControl>
                    <ControlledOutlinedInput
                      name="clientSecret"
                      type={showPassword ? 'text' : 'password'}
                      className="auth0"
                      placeholder="ex: Sfkdf2qwef2uwfe5asdfthSKw7s2"
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle Client Secret visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      }
                      sx={outlinedInputSx}
                    />
                  </FormControl>
                </GridItemWrapper>
              </GridItem>
              <GridItem item xs={12}>
                <GridItemWrapper>
                  <InputLabel>Audience *</InputLabel>
                  <FormControl>
                    <ControlledOutlinedInput
                      name="audience"
                      className="auth0"
                      placeholder="ex: Sfkdf2qwef2uwfe5asdfthSKw7s2"
                      sx={outlinedInputSx}
                    />
                  </FormControl>
                </GridItemWrapper>
              </GridItem>
            </Grid>
          </Grid>
        </Container>
      </>
    )}
  </Box>
)

export default Auth0IntegrationContent
