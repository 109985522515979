import * as d3 from 'd3'

interface Props {
  svg: d3.Selection<SVGSVGElement | null, unknown, null, undefined>
  height: number
  chartHeightStart: number
  healthBorder: number
  lineColorTop: string
  lineColorBottom: string
}

/*
 * Health Lines
 * */
export const appendHealthLines = ({
  svg,
  height,
  chartHeightStart,
  healthBorder,
  lineColorTop,
  lineColorBottom
}: Props) => {
  const existingSlaTopLine = svg.selectAll('.line-top')
  if (!existingSlaTopLine.empty()) existingSlaTopLine.remove()
  const existingSlaBottomLine = svg.selectAll('.line-bottom')
  if (!existingSlaBottomLine.empty()) existingSlaBottomLine.remove()

  /*
   *  Health - Top
   * */
  svg
    .append('line')
    .attr('class', 'line-top')
    .attr('x1', 0)
    .attr('y1', chartHeightStart)
    .attr('x2', 0)
    .attr('y2', healthBorder)
    .attr('stroke', lineColorTop)
    .attr('stroke-width', 6)

  /*
   *  Health - Bottom
   * */
  svg
    .append('line')
    .attr('class', 'line-bottom')
    .attr('x1', 0)
    .attr('y1', healthBorder)
    .attr('x2', 0)
    .attr('y2', height)
    .attr('stroke', lineColorBottom)
    .attr('stroke-width', 6)
}
