const PaginationEndIcon = () => (
  <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M5.79501 7.91L10.385 12.5L5.79501 17.09L7.20501 18.5L13.205 12.5L7.20501 6.5L5.79501 7.91ZM16.205 6.5H18.205V18.5H16.205V6.5Z"
      fill="#D0C3CC"
    />
  </svg>
)

export default PaginationEndIcon
