import { FC, ReactNode, MouseEvent, useState } from 'react'
import { IconButton } from '@mui/material'

/* Utils */
import { useAppDispatch } from '../../../../../store/hooks'
import { notification } from '../../../../../store/notifications/notificationSlice'
import { FILE_NAME } from '../crowdstrike-integration/crowdstrikeIntegration.constants'

/* Components */
import {
  SentinelInstructionsWrapper,
  SentinelInstructionsTitleWrapper,
  SentinelInstructionsTitle,
  SentinelInstructionsContent
} from './sentinelIntegration.styles'
import SentinelInstructionsTooltip from './SentinelInstructionsTooltip'
import { IntegrationInfoIcon } from '../../../svg'

interface Props {
  content: ReactNode
  variant: 'step1' | 'step2'
  hideTooltip?: boolean
}

const SentinelInstructions: FC<Props> = ({ content, variant, hideTooltip }) => {
  const dispatch = useAppDispatch()
  const [clientAnchor, setClientAnchor] = useState<null | HTMLElement>(null)
  const [clientOpen, setClientOpen] = useState(false)

  const clientCanBeOpen = clientOpen && Boolean(clientAnchor)
  const clientId = clientCanBeOpen ? 'transition-popper' : undefined

  const handleClick = (e: MouseEvent<HTMLButtonElement>) => {
    setClientAnchor(e.currentTarget)
    setClientOpen(!clientOpen)
  }

  const handleDownload = () => {
    fetch('/crowdstrikeIntegration.pdf').then((response) => {
      response
        .blob()
        .then((blob) => {
          const fileURL = window.URL.createObjectURL(blob)
          const alink = document.createElement('a')
          alink.href = fileURL
          alink.download = FILE_NAME
          alink.click()
        })
        .then(() => {
          dispatch(
            notification({
              open: true,
              variant: 'success',
              message: 'PDF saved to desktop'
            })
          )
        })
        .catch(() => {
          dispatch(
            notification({
              open: true,
              variant: 'error',
              message: 'Failed saving PDF to desktop'
            })
          )
        })
    })
  }

  return (
    <SentinelInstructionsWrapper>
      <SentinelInstructionsTitleWrapper>
        <SentinelInstructionsTitle>Instructions</SentinelInstructionsTitle>
        {!hideTooltip && (
          <IconButton onClick={handleClick}>
            <IntegrationInfoIcon />
          </IconButton>
        )}
        <SentinelInstructionsTooltip
          id={clientId}
          open={clientOpen}
          anchorEl={clientAnchor}
          handleDownload={handleDownload}
          variant={variant}
          handleClickAway={() => {
            if (clientOpen) {
              setClientOpen(false)
            }
          }}
        />
      </SentinelInstructionsTitleWrapper>
      <SentinelInstructionsContent>{content}</SentinelInstructionsContent>
    </SentinelInstructionsWrapper>
  )
}

export default SentinelInstructions
