import { FC } from 'react'
import { TableCell, TableRow, Skeleton } from '@mui/material'

interface Props {
  height?: string
}

const LoadingRows: FC<Props> = ({ height }) => {
  const rows = Array(height && !isNaN(parseInt(height)) ? Math.round(parseInt(height) / 47) : 25).fill(true)
  const cells = Array(7).fill(true)

  return (
    <>
      {rows.map((row, index) => (
        <TableRow key={index}>
          {cells.map((cell, idx) => (
            <TableCell key={idx} className="loading-cell-wrapper">
              <Skeleton animation="wave" variant="rectangular" width="100%" height="20px" />
            </TableCell>
          ))}
        </TableRow>
      ))}
    </>
  )
}

export default LoadingRows
