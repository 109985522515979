import { Dispatch, SetStateAction } from 'react'
import * as d3 from 'd3'
import { getCustomDatesForTimePeriod } from '../../../../utils'
import { ChartDataExtended, CPIFrequency, CPITimePeriod, CustomLabelDate } from '../../../../../../../models'
import { oddBarColor, evenBarColor } from '../chart-components-shared'

interface Props {
  svg: d3.Selection<SVGSVGElement | null, unknown, null, undefined>
  tooltip: d3.Selection<d3.BaseType, unknown, HTMLElement, any>
  xScaleGroup: d3.ScaleBand<string>
  uniqueMonths: string[]
  height: number
  marginTop: number
  marginBottom: number
  setDrawerOpen: Dispatch<SetStateAction<boolean>>
  setMode: Dispatch<SetStateAction<string>>
  offsetLeft: number
  stripeWidth: number
  selectedTimePeriod: CPITimePeriod
  selectedFrequency: CPIFrequency
  filteredData: any[]
  groupedByWeeks: ChartDataExtended | null
  setCustomDate?: ({ date, start, end }: CustomLabelDate) => void
  setRedirectToMode: Dispatch<SetStateAction<string>>
  dataStartsAt: string
}

/*
 * Stripes / Bars
 * */
export const appendStripes = ({
  svg,
  tooltip,
  xScaleGroup,
  uniqueMonths,
  height,
  marginTop,
  marginBottom,
  setDrawerOpen,
  setMode,
  offsetLeft,
  stripeWidth,
  selectedTimePeriod,
  selectedFrequency,
  filteredData,
  groupedByWeeks,
  setCustomDate,
  setRedirectToMode,
  dataStartsAt
}: Props) => {
  const existingBars = svg.selectAll('.bar')
  if (!existingBars.empty()) existingBars.remove()

  svg
    .selectAll('.bar')
    .data(uniqueMonths)
    .enter()
    .append('rect')
    .attr('fill', (d, i) => (i % 2 === 0 ? oddBarColor : evenBarColor))
    .attr('fill-opacity', '0.08')
    .attr('class', 'bar')
    .attr('x', (d) => xScaleGroup(d) || 0)
    .attr('y', 0)
    .attr('width', xScaleGroup.bandwidth())
    .attr('height', height + marginTop + marginBottom)
    .on('mouseout', () => {
      tooltip.style('display', 'none')
      const circleOuter = svg.select('.hover-circle-outer')
      const circleInner = svg.select('.hover-circle-inner')
      circleOuter.attr('display', 'none')
      circleInner.attr('display', 'none')
      const addCustomEventButton = svg.select('.add-event-button')
      addCustomEventButton.style('display', 'none')
    })
    .on('click', (e) => {
      setDrawerOpen(true)
      setMode('show-date')
      const data = groupedByWeeks ? groupedByWeeks.values : filteredData
      const { date, start, end } = getCustomDatesForTimePeriod(
        e.x,
        offsetLeft,
        stripeWidth,
        selectedTimePeriod,
        selectedFrequency,
        uniqueMonths,
        data,
        dataStartsAt
      )
      if (setCustomDate) {
        setCustomDate({ date, start, end })
      }
      setRedirectToMode('show-date')
    })
}
