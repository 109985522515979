import React from 'react'

const LockIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none">
    <path
      d="M20 8.5013H17V6.7113C17 4.1013 15.09 1.7713 12.49 1.5213C9.51 1.2413 7 3.5813 7 6.5013V8.5013H4V22.5013H20V8.5013ZM12 17.5013C10.9 17.5013 10 16.6013 10 15.5013C10 14.4013 10.9 13.5013 12 13.5013C13.1 13.5013 14 14.4013 14 15.5013C14 16.6013 13.1 17.5013 12 17.5013ZM9 8.5013V6.5013C9 4.8413 10.34 3.5013 12 3.5013C13.66 3.5013 15 4.8413 15 6.5013V8.5013H9Z"
      fill="#D0C3CC"
    />
  </svg>
)

export default LockIcon
