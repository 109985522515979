import { Box, Button } from '@mui/material'
import styled from '@emotion/styled/macro'

export const SourceCard = styled(Box)`
  padding: 8px;
  display: flex;
  background: linear-gradient(0deg, rgba(255, 169, 252, 0.11), rgba(255, 169, 252, 0.11)), #1e1a1d;
  box-shadow: 0 4px 8px 3px rgba(0, 0, 0, 0.15), 0 1px 3px rgba(0, 0, 0, 0.3);
  border-radius: 16px;
  width: 100%;

  @media screen and (min-width: 1600px) {
    flex-basis: 49%;
    width: 49%;
  }

  img {
    overflow: visible !important;
  }

  .source-card-additional {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    .source-card-button {
      min-width: 196px !important;
    }

    .additional-text-wrapper {
      display: flex;
      flex-flow: column wrap;
      justify-content: center;
      align-items: flex-start;
      flex-basis: 40%;

      .source-card-header {
        margin-left: 16px;
        color: #d0c3cc;
        font-size: 11px;
        font-family: Quicksand, sans-serif;
        font-weight: 500;
        line-height: 16px;
        letter-spacing: 0.5px;
      }
    }

    .MuiButtonBase-root {
      margin-top: 8px !important;
      margin-left: 8px;
      background: #131013;

      &:hover {
        background: rgba(255, 255, 255, 0.05);
      }
    }
  }

  .source-card-title {
    font-family: 'Quicksand', sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.5px;
    color: #ffffff;
    margin-left: 16px;
    text-transform: capitalize;
  }

  .source-card-text {
    font-family: 'Quicksand', sans-serif;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.4px;
    color: #ffffff;
    margin-top: 8px;
    margin-left: 16px;
  }

  .tooltip-text {
    color: #e9e0e4 !important;
    font-size: 14px !important;
    font-family: 'Quicksand', sans-serif;
    line-height: 20px !important;
    letter-spacing: 0.25px !important;
  }

  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`

export const FormulaWrapper = styled(Box)`
  display: flex;
  flex-flow: column wrap;
  box-shadow: 0 4px 8px 3px rgba(0, 0, 0, 0.15);
  filter: drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.3));
  margin-left: 16px;

  .image-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 16px;
    background: linear-gradient(0deg, rgba(255, 169, 252, 0.11), rgba(255, 169, 252, 0.11)), #1e1a1d;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 12px 12px 12px 12px;
    border: 1px solid rgba(147, 143, 153, 0.16);
    border-top: none;

    img {
      width: 100%;
      max-height: 100px;
      margin-top: 0 !important;
    }
  }
`

export const DataSourceButton = styled(Button)<{ isactive: string }>`
  font-family: 'Quicksand', sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.1px;
  color: #ffffff;
  border-radius: 8px;
  background: transparent;
  padding: 0;
  min-width: 174px;
  text-transform: none;
  height: 32px;
  margin-right: 16px;
  border: 1px solid #998d96;

  ${({ isactive }) =>
    isactive &&
    isactive === 'true' &&
    `
        background: #573F56;
        border: 0;
        color: #FBD8F5;
        `};
`

export const AboutDescriptionWrapper = styled(Box)<{ isopen: string }>`
  margin-left: 24px;
  max-width: 64%;

  ${({ isopen }) =>
    isopen === 'false' &&
    `
      display: flex;
      justify-content: center;
      align-items: flex-start;
      flex-flow: column wrap;
      margin: 24px auto;
      transition: all .5s ease;
  `};

  ${({ isopen }) =>
    isopen === 'true' &&
    `
      display: block;
      transition: all .5s ease;
  `};

  article {
    background: transparent;
    padding: 0 16px 16px 0;
    margin-bottom: 40px;
    border-bottom: 1px solid rgba(147, 143, 153, 0.12);
    width: 100%;

    .truncate-wrapper {
      padding-left: 16px;
    }

    .title {
      font-family: 'Quicksand', sans-serif;
      font-weight: 500;
      font-size: 22px;
      line-height: 28px;
      color: #fff;
      margin: 0 0 8px;
    }

    h2 {
      font-family: 'Quicksand', sans-serif;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.1px;
      margin-bottom: 0;
      margin-top: 0;
    }

    h3 {
      font-family: 'Quicksand', sans-serif;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.1px;
      color: #ffffff;
      padding-left: 16px;
      margin: 0 0 12px;

      &:not(:first-of-type) {
        margin-top: 24px;
      }
    }

    p {
      font-family: 'Quicksand', sans-serif;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px; /* 133.333% */
      letter-spacing: 0.4px;
      color: #fff;
      margin-top: 0;
      margin-bottom: 8px;
    }

    .button-wrapper {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      width: 100%;
      margin-top: 8px;

      .MuiButtonBase-root {
        padding: 6px 16px !important;
        min-width: 135px !important;

        &.expanded-icon {
          svg {
            transform: rotate(45deg);
            transition: transform 0.5s ease;
          }
        }

        &.collapsed-icon {
          svg {
            transform: rotate(0);
            transition: transform 0.5s ease;
          }
        }
      }
    }

    img {
      margin-top: 20px;
    }

    .display-linebreak {
      white-space: pre-line;
      padding-left: 16px;
      line-height: 4px;
    }
  }
`

export const AboutContentWrapper = styled(Box)<{ isopen: string }>`
  background: transparent;
  padding: 16px 16px 16px 0;
  margin-top: 24px;
  margin-bottom: 24px;
  border-bottom: 1px solid rgba(147, 143, 153, 0.12);
  margin-left: 24px;
  max-width: 64%;
  width: 100%;

  ${({ isopen }) =>
    isopen === 'false' &&
    `
      display: flex;
      justify-content: center;
      align-items: flex-start;
      flex-flow: column wrap;
      margin: 24px auto;
      transition: all .5s ease;
  `};

  ${({ isopen }) =>
    isopen === 'true' &&
    `
      display: block;
      transition: all .5s ease;
  `};

  .data-source-wrapper {
    display: flex;
    align-items: center;
    margin-top: 22px;
    margin-bottom: 12px;
    margin-left: 16px;
  }

  .card-description {
    font-family: 'Quicksand', sans-serif;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.25px;
    color: #ffffff;
    padding-left: 16px;
  }

  .source-card-wrapper {
    display: flex;
    align-items: center;
    flex-flow: column wrap;
    gap: 16px;
    width: 100%;
    margin-top: 12px;
    margin-left: 16px;

    @media screen and (min-width: 1600px) {
      flex-flow: row wrap;
    }
  }

  .title {
    font-family: 'Quicksand', sans-serif;
    font-weight: 400;
    font-size: 22px;
    line-height: 28px;
    color: #e9e0e4;
    margin-bottom: 4px;
  }

  .description {
    font-family: 'Quicksand', sans-serif;
    font-weight: 400;
    color: #e9e0e4;
    letter-spacing: 0.25px;
    font-size: 14px;
    line-height: 20px;
  }

  img:not(:last-of-type) {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    margin-top: 20px;
  }
`
