import { Box, Breadcrumbs } from '@mui/material'
import styled from '@emotion/styled/macro'
import { getCpiCategoryColors } from '../../../../../lib/utils'
import { CategoryFramework, CPIManifestCategory } from '../../../../../models'
import { CustomColors } from '../LibraryDetails'

export const HeaderBreadcrumbs = styled(Breadcrumbs)`
  .MuiLink-root,
  .current-breadcrumb {
    font-family: 'Quicksand', sans-serif;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.1px;
    text-decoration: none;

    &.category-breadcrumb {
      color: #fff;
      text-align: center;
      font-family: Quicksand, sans-serif;
      font-size: 11px;
      font-style: normal;
      font-weight: 500;
      line-height: 16px;
      letter-spacing: 0.5px;
      display: flex;
      padding: 2px 8px;
      align-items: center;
      gap: 8px;
      border-radius: 8px;
      background: #b15c0f;
      box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.15), 0 1px 2px 0 rgba(0, 0, 0, 0.3);
      text-transform: capitalize;
    }
  }

  .MuiLink-root {
    color: #fff;
  }

  .current-breadcrumb {
    color: #ffa9fc;
  }

  .MuiBreadcrumbs-separator {
    color: #debdd9;
  }
`

export const LibraryDetailsHeaderContainer = styled(Box)<{
  severity: '' | 'healthy' | 'problematic' | 'warning'
  category: CPIManifestCategory
  customColors?: CustomColors
}>`
  width: 100%;
  position: relative;
  z-index: 100;

  .breadcrumbs-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    max-width: 70%;
    padding: 18px 0 0 24px;

    .header-actions {
      display: flex;
      align-items: center;

      .favorite-icon {
        margin-right: 34px;

        .MuiButtonBase-root {
          &:hover,
          &:focus,
          &:active {
            background: rgba(202, 196, 208, 0.08);
          }
        }
      }
    }
  }

  .actions-wrapper {
    position: absolute;
    top: 8px;
    right: 32.5px;

    .edit-button {
      margin-left: 24px;

      svg {
        width: 24px !important;
        height: 24px !important;
      }

      &:disabled {
        border: 1px solid rgba(227, 227, 227, 0.12);
        background: transparent;
        color: #e9e0e4;
        opacity: 0.37;
      }
    }

    .export-report-btn {
      margin-right: 24px;
    }

    .MuiIconButton-root {
      border: 1px solid #998d96;

      svg {
        path {
          fill: #ffffff;
        }
      }
    }

    .export-report-btn {
      margin-right: 24px;

      svg {
        width: 24px !important;
        height: 24px !important;
      }
    }

    .settings-btn {
      background: #ffa9fc;
      padding: 10px 24px 10px 16px;
      gap: 8px;
      min-width: 121px;
      width: fit-content;
      height: 40px;
      border-radius: 100px;
      font-family: 'Quicksand', sans-serif;
      text-transform: none;
      font-weight: 550;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.1px;
      color: #590060;
      margin-left: 24.5px;

      svg {
        path {
          fill: #590060;
        }
      }
    }
  }

  .title-wrapper {
    padding-left: 24px;

    &.category-title-wrapper {
      padding: 24px 24px 16px 24px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .category-header-title {
        color: #fff;
        font-family: Quicksand, sans-serif;
        font-size: 28px;
        font-style: normal;
        font-weight: 400;
        line-height: 36px;
        text-transform: capitalize;
      }
    }
  }

  .header-title {
    font-family: 'Quicksand', sans-serif;
    font-weight: 400;
    font-size: 36px;
    line-height: 44px;
    color: #ffffff;
    margin: 26px 0 16px;
    max-width: 70%;

    .MuiButtonBase-root {
      margin-left: 24px;
    }
  }

  .chips-wrapper {
    display: flex;
    align-items: center;
    gap: 16px;
    padding-left: 24px;

    .cpi-chip {
      padding: 2px 12px;
      gap: 3px;
      background: #573f56;
      min-width: 81px;
      width: fit-content;
      min-height: 24px;
      border: 1px solid #debdd9;
      box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3), 0 1px 3px 1px rgba(0, 0, 0, 0.15);
      border-radius: 18px;
      font-family: 'Quicksand', sans-serif;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      display: flex;
      align-items: center;
      text-align: center;
      letter-spacing: 0.1px;
      color: #ffd6f9;
    }

    .cpi-category {
      padding: 6px 16px;
      gap: 8px;
      box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3), 0 1px 3px 1px rgba(0, 0, 0, 0.15);
      border-radius: 8px;
      width: fit-content;
      min-height: 32px;
      font-family: 'Quicksand', sans-serif;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      display: flex;
      align-items: center;
      text-align: center;
      letter-spacing: 0.1px;
      cursor: pointer;

      ${({ category, customColors }) => {
        if (customColors) {
          return `
          background: ${customColors.chip_bg_color};
          color: ${customColors.chip_color};
        `
        } else {
          const categoryId = category?.id
          const fw = category?.framework_id || ''
          const framework =
            fw === CategoryFramework.Onyxia
              ? CategoryFramework.Onyxia
              : fw === CategoryFramework.NIST
              ? CategoryFramework.NIST
              : CategoryFramework.Custom
          const colors = getCpiCategoryColors(categoryId, framework)

          return `
          background: ${colors.background};
          color: ${colors.color};
        `
        }
      }}
    }

    .cpi-framework-panel {
      .cpi-framework {
        display: flex;
        padding: 5px 15px 5px 8px;
        align-items: center;
        gap: 8px;
        border-radius: 8px;
        border: 1px solid #998d96;

        img {
          width: 18px;
          height: 18px;
        }

        .framework-title {
          color: #e9e0e4;
          font-family: 'Quicksand', sans-serif;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 20px; /* 142.857% */
          letter-spacing: 0.1px;
        }
      }
    }

    .cpi-active-button {
      width: fit-content;
      height: 32px;
      box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3), 0 1px 3px 1px rgba(0, 0, 0, 0.15);
      border-radius: 8px;
      padding: 6px 16px 6px 8px;
      gap: 8px;
      font-family: 'Quicksand', sans-serif;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      display: flex;
      align-items: center;
      text-align: center;
      letter-spacing: 0.1px;
      color: #ffffff;
    }

    .cpi-active {
      background: #3754fc;
    }

    .cpi-inactive {
      background: #4d444b;
    }
  }

  .header-info {
    display: flex;

    > * > * {
      font-family: 'Quicksand', sans-serif;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      letter-spacing: 0.4px;
      color: #d0c3cc;
    }

    .header-info__item {
      padding-right: 40px;
    }

    .header-info__title {
      margin-bottom: 4px;
    }

    .health-threshold {
      font-family: 'Quicksand', sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.1px;
      color: #e9e0e4;
      min-width: 102px;
      height: 32px;
      background: rgba(114, 226, 141, 0.3);
      box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.15), 0 1px 2px rgba(0, 0, 0, 0.3);
      border-radius: 8px;
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        margin-right: 8px;
      }

      ${({ severity }) =>
        severity &&
        severity === 'problematic' &&
        `
        background: rgba(246, 103, 123, 0.3);
        min-width: 125px;
      `};

      ${({ severity }) =>
        severity &&
        severity === 'warning' &&
        `
        background: rgba(244, 181, 127, 0.4);
      `};

      ${({ severity }) =>
        severity &&
        severity === 'healthy' &&
        `
        background: rgba(114, 226, 141, 0.3);
      `};
    }

    .header-info__category {
      background: #debdd9;
      box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.15), 0 1px 2px rgba(0, 0, 0, 0.3);
      border-radius: 8px;
      width: fit-content;
      padding: 6px 8px;
      font-size: 14px;
      line-height: 20px;
      font-weight: 500;
      text-transform: capitalize;
      color: #522217;
      letter-spacing: 0.1px;
    }
  }
`

export const LibraryHeaderBackground = styled(Box)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url('/details-bg-1.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top center;
  z-index: -1;
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.15);
  filter: drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.3));
  border-radius: 16px 0 0 0;
  mix-blend-mode: luminosity;
`
