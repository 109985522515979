import React, { FC, useEffect, useState } from 'react'

/* Utils */
import { AccountSetting } from '../../../../models'

/* Components */
import { CompanyInfo, HeaderWrapper, LogoNewWrapper } from '../dashboard.styles'
import HeaderComponent from '../../../components/header/HeaderComponent'
import { breadcrumbs } from '../../../../lib/utils'
import { Typography } from '@mui/material'

interface Props {
  accountSettings: AccountSetting | null
}

const DashboardHeader: FC<Props> = ({ accountSettings }) => {
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    if (accountSettings && Object.keys(accountSettings).length) {
      setLoading(false)
    }
  }, [accountSettings])

  return (
    <HeaderWrapper>
      <HeaderComponent breadcrumbs={breadcrumbs.dashboard} type={'dashboard'}>
        <CompanyInfo>
          <LogoNewWrapper>
            {loading ? (
              ''
            ) : (
              <img
                src={`${
                  accountSettings?.logo
                    ? `${process.env.REACT_APP_LOGO_HOST}${accountSettings.logo}`
                    : '/dashboard-logo.svg'
                }`}
                alt="Dashboard Logo"
              />
            )}
          </LogoNewWrapper>
          <Typography className="company-name">{accountSettings?.name}</Typography>
        </CompanyInfo>
      </HeaderComponent>
    </HeaderWrapper>
  )
}

export default DashboardHeader
