import { Box } from '@mui/material'
import styled from '@emotion/styled/macro'

export const EnvironmentStatsWrapper = styled(Box)`
  border-radius: 24px;
  background: linear-gradient(0deg, rgba(255, 169, 252, 0.05) 0%, rgba(255, 169, 252, 0.05) 100%), #1e1a1d;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.3), 0 4px 8px 3px rgba(0, 0, 0, 0.15);
  padding: 16px;
  gap: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 310px;

  @media (min-width: 1110px) {
    max-width: 235px;
  }

  @media (min-width: 1440px) {
    margin-left: 0;
    margin-bottom: 0;
    max-width: 295px;
  }

  @media (min-width: 1920px) {
    max-width: 414px;
    padding: 22px 32px;
    height: 310px;
  }

  .header {
    width: 100%;
    display: flex;
    align-items: center;

    .MuiTypography-root {
      color: #e9e0e4;
      font-family: Quicksand, sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: 0.1px;
      margin-left: 8px;
    }
  }
`

export const StatChip = styled(Box)<{ i: number; isstub?: string }>`
  width: 100%;
  border-radius: 24px;
  background: linear-gradient(0deg, rgba(255, 169, 252, 0.11) 0%, rgba(255, 169, 252, 0.11) 100%), #1e1a1d;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.3), 0 1px 3px 1px rgba(0, 0, 0, 0.15);
  padding: 9px 16px;

  ${({ isstub }) =>
    isstub &&
    isstub === 'true' &&
    `
      background: linear-gradient(4.59deg, rgba(87, 63, 86, 0.71) 37.5%, rgba(87, 63, 86, 0.9) 55.7292%, rgba(87, 63, 86, 0.71) 73.9583%, rgba(87, 63, 86, 0) 100%);
    `}
  .inner {
    display: flex;
    align-items: center;
    gap: 8px;

    .marker {
      width: 2px;
      height: 24px;
      background: #bca4b8;
      border-radius: 8px;

      ${({ i }) =>
        i === 1 &&
        `
        background: #A18A9D;
      `};

      ${({ i }) =>
        i === 2 &&
        `
        background: #867083;
      `};

      ${({ i }) =>
        i > 2 &&
        `
        background: #544152;
      `};
    }

    .status-value {
      color: #e9e0e4;
      text-shadow: 0 4px 4px rgba(0, 0, 0, 0.3), 0 8px 12px rgba(0, 0, 0, 0.15);
      font-family: Quicksand, sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: 0.1px;
    }

    .status-label {
      color: #fff;
      text-shadow: 0 4px 4px rgba(0, 0, 0, 0.3), 0 8px 12px rgba(0, 0, 0, 0.15);
      font-family: Quicksand, sans-serif;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 16px;
      letter-spacing: 0.5px;
    }
  }
`
