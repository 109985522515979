import { Box, Modal as MuiModal } from '@mui/material'
import styled from '@emotion/styled/macro'
import { DataStatus } from '../../../../../../../models'

export const Modal = styled(MuiModal)`
  .modal-wrapper-inner {
    position: absolute;
    transform: translate(-50%, -50%);
    border-radius: 16px;
    border: 2px solid #bb95ea;
    background: linear-gradient(
        180deg,
        rgba(187, 149, 234, 0.3) 0%,
        rgba(187, 149, 234, 0) 30.5%,
        rgba(187, 149, 234, 0) 86%
      ),
      linear-gradient(0deg, rgba(255, 169, 252, 0.05) 0%, rgba(255, 169, 252, 0.05) 100%), #000;
    width: calc(100vw - 115px);
    height: calc(100vh - 50px);
    top: 50%;
    left: 51%;
    padding: 5px 12px 12px 6px;
    max-width: 1724px;

    .close-modal-button {
      position: absolute;
      top: 8px;
      right: 8px;
      padding: 0;
    }
  }

  .header {
    display: flex;
    align-items: center;
    gap: 12px;
    margin-bottom: 6px;

    .header-title {
      color: #fff;
      font-family: Quicksand, sans-serif;
      font-size: 22px;
      font-style: normal;
      font-weight: 400;
      line-height: 28px;
    }
  }
`

export const ChartWrapperPredictions = styled(Box)<{
  status?: DataStatus
  severity: 'healthy' | 'problematic'
  zone: string
}>`
  display: flex;
  flex-direction: column;
  margin-top: 17px;
  padding: 24px;
  max-width: 1686px;
  margin-left: auto;
  margin-right: auto;

  .chart-container {
    width: 100%;
    height: 499px;
    margin-right: 41px;
    max-width: 1724px;

    .trends-d3-wrapper {
      position: relative;
      width: 100%;
      height: 100%;

      .svg-d3 {
        border-bottom-right-radius: 16px;
        border-bottom-left-radius: 16px;
        border: 1px solid rgba(147, 143, 153, 0.16);
        background: linear-gradient(0deg, rgba(255, 169, 252, 0.08) 0%, rgba(255, 169, 252, 0.08) 100%), #1e1a1d;
        box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.3), 0 4px 8px 3px rgba(0, 0, 0, 0.15);

        .event-indicator-count {
          cursor: pointer;
        }

        .add-event-button {
          cursor: pointer;
          display: none;

          &:hover {
            .add-button-circle {
              fill: #4e414d;
              stroke: #d0c3cc;
            }
          }
        }

        .add-button-tooltip {
          display: none;
        }

        .count-tooltip {
          display: none;
        }

        .sla {
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 16px;
          background: rgba(208, 188, 255, 0.16);
          box-shadow: 0 8px 12px 6px rgba(0, 0, 0, 0.15), 0 4px 4px rgba(0, 0, 0, 0.3);
          border: 0 solid #998d96;
          border-radius: 16px;
          backdrop-filter: blur(6px) brightness(0.8) contrast(0.9) hue-rotate(320deg);
          z-index: 1000000;

          .sla-tooltip-wrapper {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 16px;
            background: rgba(208, 188, 255, 0.16);
            box-shadow: 0 8px 12px 6px rgba(0, 0, 0, 0.15), 0 4px 4px rgba(0, 0, 0, 0.3);
            border: 0 solid #998d96;
            border-radius: 16px;
            backdrop-filter: blur(6px) brightness(0.8) contrast(0.9) hue-rotate(320deg);
            z-index: 1000000;
          }
        }
      }

      .data-point-tooltip {
        display: none;
        position: absolute;
        opacity: 1;
        padding: 16px;
        gap: 10px;
        border-radius: 8px;
        border: 1px solid #998d96;
        background: #4d444b;
        box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.3), 0 8px 12px 6px rgba(0, 0, 0, 0.15);
        color: #fff;
        font-family: Quicksand, sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: 0.1px;
        z-index: 10000;

        .prediction-text-wrapper {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          width: 232px;
          gap: 8px;
          margin-top: -4px;

          .tooltip-performance {
            color: #fff;
            font-family: Quicksand, sans-serif;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 24px;
            letter-spacing: 0.1px;
          }

          svg {
            margin-left: -6px;
          }
        }

        .tooltip-units-wrapper {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 232px;

          .tooltip-units-prediction {
            color: #fff;
            font-family: Quicksand, sans-serif;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
            letter-spacing: 0.25px;
            margin-top: 0;
            margin-bottom: 0;
          }
        }

        .tooltip-performance,
        .tooltip-units {
          color: #fff;
          font-family: Quicksand, sans-serif;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;
          letter-spacing: 0.25px;
        }

        .tooltip-performance {
          margin: 8px 0;

          &.prediction-date {
            color: #fff;
            font-family: Quicksand, sans-serif;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 24px;
            letter-spacing: 0.1px;
            margin-top: 2px;
          }
        }

        .tooltip-units {
          margin: 0;
        }

        .severity-text {
          text-transform: capitalize;
        }
      }
    }
  }

  .chart-container::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 0 rgba(0, 0, 0, 0);
    background: #2c242c;
  }

  .chart-container::-webkit-scrollbar-thumb {
    height: 5px;
    background-color: #524256;
  }

  .chart-container::-webkit-scrollbar-thumb:hover {
    background-color: #524256;
  }

  .chart-container::-webkit-scrollbar:vertical {
    display: none;
  }
`
