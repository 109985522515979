export enum SSMAssetType {
  Devices = 'Devices',
  Applications = 'Applications',
  Networks = 'Networks',
  Data = 'Data',
  Users = 'Users'
}

export enum SSMNistPillar {
  Identify = 'Identify',
  Protect = 'Protect',
  Detect = 'Detect',
  Respond = 'Respond',
  Recover = 'Recover',
  Govern = 'Govern'
}

export interface SSMResponseItem {
  created_at: string
  created_by: string | null
  description: string | null
  display_name: string
  is_product: boolean
  logo_path: string
  name: string
  tenant_id: string | null
  url: string | null
  uuid: string
  vendor_uuid: string | null
  vendor?: SSMResponseItem
  verified: boolean
  existsInCell?: boolean
}

export type Vendor = SSMResponseItem

export interface VendorExtended extends Vendor {
  nist: SSMNistPillar
  asset: SSMAssetType
}

export type SSMResponseAssets = Array<SSMResponseItem>

export type SSMResponseNists = Array<SSMResponseAssets>

export interface SSMResponse {
  columns: SSMAssetType[]
  rows: SSMNistPillar[]
  data: Array<SSMResponseNists>
}

export interface SSMResponseProduct {
  cost: number
  created_at: string
  created_by: string | null
  description: string | null
  display_name: string
  end_date: string
  is_product: boolean
  logo_path: string
  name: string
  product_uuid: string
  start_date: string
  tenant_id: string | null
  tilesTotal: number
  url: string | null
  uuid: string
  vendor?: SSMResponseItem
  vendor_uuid: string | null
  verified: boolean
  is_auto: boolean
}

export interface SSMResponseProductExtended extends SSMResponseProduct {
  nist: SSMNistPillar
  asset: SSMAssetType
}

export interface SSMResponseDataItem {
  cost: number
  itemId: string /* = uuid in Products */
}

export type SSMResponseAssetsV2 = Array<SSMResponseDataItem>

export type SSMResponseNistsV2 = Array<SSMResponseAssetsV2>

export interface SSMResponseV2 {
  columns: SSMAssetType[]
  rows: SSMNistPillar[]
  data: Array<SSMResponseNistsV2>
  products: SSMResponseProduct[]
}

export interface SSMGroupedDataItem {
  nist: SSMNistPillar
  asset: SSMAssetType
  items: SSMResponseDataItem[]
}

export type SSMGroupedData = Array<SSMGroupedDataItem>

export interface AddVendorPayload {
  itemId: string
  nist: string
  asset: string
  index?: number
}
