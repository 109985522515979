import React, { FC, ReactElement } from 'react'
import { Wrapper } from './ContentWrapper.styles'

interface Props {
  children?: ReactElement
  line: number
  paddingBottom?: number
  marginTop?: number
  scrollHeight?: number
  normalHeight?: string
}

const ContentWrapper: FC<Props> = ({ children, normalHeight, line, paddingBottom, scrollHeight, marginTop }) => {
  return (
    <Wrapper
      line={line}
      marginTop={marginTop}
      paddingBottom={paddingBottom}
      scrollHeight={scrollHeight}
      normalHeight={normalHeight}
    >
      {children}
    </Wrapper>
  )
}

export default ContentWrapper
