import { Box, Tabs } from '@mui/material'
import styled from '@emotion/styled/macro'
import theme from '../../../theme'

export const DashboardContainer = styled(Box)`
  width: 100%;
  background: ${theme.colorTokens.surface.pageBackground};
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  flex-direction: column;

  .dashboard-title {
    font-family: 'Quicksand', sans-serif;
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
  }

  .desktop-section {
    display: flex;
    flex-direction: column;
  }
`

export const HeaderWrapper = styled(Box)`
  //position: absolute;
  //top: 20px;
  //left: 88px;
  //display: flex;
  //align-items: center;
  //gap: 24px;
`

export const CompanyInfo = styled(Box)`
  padding: 8px 16px;
  border-radius: 8px;
  border: 1px solid #4d444b;
  background: #3f293e;
  z-index: 10;
  display: flex;
  align-items: center;
  gap: 8px;

  .company-name {
    font-family: 'Quicksand', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.1px;
  }
`

export const LogoWrapper = styled(Box)`
  z-index: 10000;
  display: flex;
  align-items: center;

  .MuiTypography-root {
    margin: 0 24px;
  }
`

export const LogoNewWrapper = styled(Box)`
  display: flex;
  align-items: center;

  img {
    max-width: 52px;
    height: 24px;
    object-fit: cover;
    object-position: center center;
  }

  .MuiTypography-root {
    margin: 0 24px;
  }
`

export const Main = styled(Box)`
  box-sizing: border-box;
  display: flex;
  align-items: flex-start;
  flex-grow: 1;
  background: #1e1a1d;
`

export const DashboardWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
  background: #1e1a1d;
  margin: 0 auto;
  padding-top: 40px;
  padding-bottom: 40px;

  @media (min-width: 1920px) {
    max-width: 1681px !important;
  }
  @media (min-width: 1440px) {
    max-width: 1364px;
  }
  @media (max-width: 1439px) {
    max-width: 1204px;
  }

  .main-content-container {
    display: flex;
    flex-grow: 1;
    width: 100%;
    gap: 40px;

    @media (max-width: 1440px) {
      max-width: 1368px;
      border-radius: 8px;
      background: #1e1a1d;
      justify-content: center;
      gap: 24px;
    }

    .second-breakpoint-feed-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
`

export const HeroSectionWrapper = styled(Box)`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 24px;
  margin-bottom: 40px;
  position: relative;

  @media (max-width: 1400px) {
    justify-content: center;
    margin-bottom: 24px;
  }
`

export const DashboardEmptyStateWrapper = styled(Box)`
  border-radius: 24px;
  background: #590060;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.3), 0 8px 12px 6px rgba(0, 0, 0, 0.15);
  width: 837px;
  height: 210px;
  padding: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 85px;
  left: 50%;
  transform: translate(-50%, 0);
  z-index: 100;
  opacity: 1;

  .text {
    color: #fff;
    font-family: Quicksand, sans-serif;
    font-size: 45px;
    font-style: normal;
    font-weight: 400;
    line-height: 52px;
    margin-right: 21px;
  }
`

export const IntelFeedWrapper = styled(Box)<{ width: number }>`
  background: transparent;
  border-radius: 16px;
  display: block;
  flex-flow: column wrap;
  gap: 8px;
  box-sizing: border-box;
  flex-basis: 40%;
  width: 100%;
  padding: 0 0 0 8px;

  @media (min-width: 1440px) {
    width: 100%;
  }

  .header {
    display: flex;
    align-items: center;

    svg {
      margin-right: 8px;
    }

    .MuiTypography-root {
      font-family: 'Quicksand', sans-serif;
      font-weight: 400;
      font-size: 22px;
      line-height: 28px;
      color: #e9e0e4;
    }
  }
`

export const IntelFeedCardWrapper = styled(Box)<{ read: boolean; expanded: string }>`
  margin-bottom: 16px;

  ${({ expanded }) =>
    expanded === 'true' &&
    `
    .MuiPaper-root {
      box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.3), 0 8px 12px 6px rgba(0, 0, 0, 0.15);
      border-radius: 16px;
      border: 1px solid #ffa9fc;
      background: #3f293e !important;
    }
  `};

  .MuiCard-root {
    background: rgb(55, 42, 54);
    box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.15), 0 1px 2px rgba(0, 0, 0, 0.3);
    border-radius: 16px;
    width: 100%;

    .MuiCardContent-root.main-content {
      display: flex;
      align-items: center;
      padding: 0;
      height: 70px;

      &.loading {
        height: 40px;
        justify-content: space-between;
      }

      .collapsed-icon,
      .expanded-icon {
        svg {
          width: 24px;
          height: 24px;
        }

        &:hover {
          background: rgba(202, 196, 208, 0.12);
        }
      }

      .collapsed-icon {
        transform: rotate(0);
        transition: transform 0.5s ease;
      }

      .expanded-icon {
        svg {
          path {
            fill: rgba(251, 216, 245, 1);
          }
        }

        transform: rotate(45deg);
        transition: transform 0.5s ease;
      }

      .title {
        height: 71px;
        padding: 4px 8px 8px 16px;
        flex-grow: 1;
        display: -webkit-box;
        max-width: 200px;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
        color: #fff;
        font-family: Quicksand, sans-serif;
        font-size: 14px;
        font-weight: 500;
        letter-spacing: 0.1px;
        text-transform: none;
      }

      .MuiIconButton-root {
        margin-right: 8px;
      }
    }

    .MuiCardContent-root.expanded-content {
      display: flex;
      flex-flow: column wrap;
      padding: 10px 8px;

      .expanded-text {
        > span > span {
          color: #fff;
          font-family: Quicksand, sans-serif;
          font-size: 14px;
          font-weight: 400;
          line-height: 20px;
          letter-spacing: 0.25px;

          > span {
            color: #fff;
            font-family: Quicksand, sans-serif;
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
            letter-spacing: 0.25px;
          }
        }
      }

      .expanded-subtext {
        color: #fff;
        font-family: Quicksand, sans-serif;
        font-size: 11px;
        font-weight: 500;
        line-height: 14px;
        letter-spacing: 0.5px;
        margin: 8px 0 4px;
      }

      .expanded-actions {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;

        .report-button {
          font-family: 'Quicksand', sans-serif;
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
          letter-spacing: 0.1px;
          color: #ffa9fc;
          text-transform: none;
          text-decoration: none;
          display: flex;
          align-items: center;

          svg {
            margin-right: 8px;

            path {
              fill: #ffa9fc;
            }
          }
        }

        .expanded-icons {
          display: flex;
          align-items: center;

          svg {
            path {
              fill: rgba(237, 237, 237, 1);
            }
          }
        }
      }
    }
  }
`

export const IntelFeedBackground = styled(Box)<{ url: string; fallBackUrl: string }>`
  width: 107px;
  min-width: 107px;
  height: 70px;
  border-radius: 16px;
  background: ${({ url, fallBackUrl }) =>
    `url("${url}") center/cover no-repeat, url("${fallBackUrl}") center/cover no-repeat`};
  box-shadow: 0 4px 8px 3px rgba(0, 0, 0, 0.15);
  filter: drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.3));
`

export const StubIntelFeedBackground = styled(Box)`
  width: 57px;
  height: 40px;
  border-radius: 16px;
  background: linear-gradient(0deg, rgba(255, 169, 252, 0.08) 0%, rgba(255, 169, 252, 0.08) 100%), #1e1a1d;
`

export const EmptyIntelFeedWrapper = styled(Box)`
  .MuiTypography-root {
    padding-right: 16px;
    font-family: 'Quicksand', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.1px;
    color: #fff;
  }

  .small-text {
    margin-top: 4px;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.25px;
  }
`

export const LoadingWrapper = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  width: 100%;
  flex-grow: 1;
`

export const IntelFeedTabs = styled(Tabs)`
  background: transparent;
  min-height: unset;
  height: 32px;
  margin-top: 24px;
  margin-bottom: 24px;

  .MuiButtonBase-root {
    width: 76px;
    height: 32px;
    min-height: unset;
    border: 1px solid #998d96;
    border-radius: 8px;
    padding: 6px 12px;
    gap: 10px;
    background: transparent;
    font-family: 'Quicksand', sans-serif;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.1px;
    color: #e9e0e4;
    text-transform: none;

    &:not(:last-of-type) {
      margin-right: 8px;
    }

    &.Mui-selected {
      background: #ffa9fc;
      color: #590060;
    }
  }
`

export const IntelFeedTabPanel = styled(Box)`
  height: 100%;
  overflow-y: scroll;
  margin-top: 8px;

  ::-webkit-scrollbar {
    display: none;
  }
`

export const FeedSectionWrapper = styled(Box)`
  margin-top: 20px;
  width: 100%;
  min-width: 529px;
  max-width: 529px;
  border-radius: 24px;
  background: linear-gradient(0deg, rgba(255, 169, 252, 0.05) 0%, rgba(255, 169, 252, 0.05) 100%), #1e1a1d;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.3), 0 4px 8px 3px rgba(0, 0, 0, 0.15);
  padding: 19px 33px 16px 9px;

  @media (min-width: 1110px) {
    min-width: 370px;
    max-width: 370px;
  }

  @media (min-width: 1440px) {
    width: 100%;
    min-width: 423px;
    max-width: 423px;
  }

  @media (min-width: 1920px) {
    width: 100%;
    min-width: 529px;
    max-width: 529px;
    padding: 22px 32px;
  }
`

export const MainScrollSection = styled(Box)`
  border-radius: 24px;
  background: linear-gradient(0deg, rgba(255, 169, 252, 0.05) 0%, rgba(255, 169, 252, 0.05) 100%), #1e1a1d;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.3), 0 1px 3px 1px rgba(0, 0, 0, 0.15);
  padding: 16px;
  width: 100%;
  display: flex;
  flex-flow: column wrap;
  justify-content: flex-start;
  height: 100%;
  flex-grow: 1;

  @media (max-width: 1440px) {
    width: 750px;
  }

  @media (min-width: 1440px) {
    width: 837px;
  }

  @media (min-width: 1920px) {
    width: 1112px;
    padding: 22px 32px;
  }

  @media (max-width: 1400px) {
    flex-grow: unset;
  }

  .empty-wrapper {
    margin-top: 0;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .title {
      width: 577px;
      margin: 24px auto;
      text-align: center;
      font-family: 'Quicksand', sans-serif;
      font-size: 28px;
      font-style: normal;
      font-weight: 400;
      line-height: 36px;

      @media (max-width: 600px) {
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        line-height: 32px;
      }
    }
  }

  .chart-title-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .title-wrapper {
      display: flex;
      align-items: center;
      gap: 8px;
      margin-left: 3px;
    }

    .chart-title {
      color: #fff;
      text-align: center;
      font-family: Quicksand, sans-serif;
      font-size: 24px;
      font-style: normal;
      font-weight: 400;
      line-height: 32px;
    }
  }

  .cpis-wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 16px;

    .MuiTypography-root {
      font-family: 'Quicksand', sans-serif;
      font-weight: 400;
      font-size: 24px;
      line-height: 32px;
      color: #e9e0e4;
    }

    svg {
      margin-right: 8px;

      path {
        fill: #e9e0e4;
      }
    }
  }

  .no-data-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .text {
      font-family: 'Quicksand', sans-serif;
      font-weight: 500;
      font-size: 22px;
      line-height: 28px;
      color: #ffffff;
    }
  }
`

export const CPIsSectionHeader = styled(Box)`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 24px;

  .title-block {
    display: flex;
    align-items: center;
  }

  .MuiTypography-root {
    font-family: 'Quicksand', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.1px;
    color: #e9e0e4;
  }

  .date {
    color: #998d96;
    font-family: Quicksand, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.1px;
  }

  svg {
    margin-right: 8px;

    path {
      fill: #e9e0e4;
    }
  }
`

export const InfoChipsWrapper = styled(Box)<{ forbenchmark?: string; isstub?: string }>`
  display: flex;
  align-items: center;

  ${({ forbenchmark }) =>
    forbenchmark &&
    forbenchmark === 'true' &&
    `
    margin-bottom: 26px;
    width: 100%;
  `};

  ${({ isstub }) =>
    isstub &&
    isstub === 'true' &&
    `
      margin-top: 12px;
  `};

  .chip {
    display: flex;
    align-items: center;

    .chip-circle {
      margin-right: 4px;
      border-radius: 12px;
      box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.3), 0 8px 12px 6px rgba(0, 0, 0, 0.15);
      width: 12px;
      height: 12px;

      &.pink {
        background: #ffa9fc;
      }

      &.blue {
        background: #3754fc;
      }

      &.less {
        background: #8e9aff;
      }

      &.same {
        background: #f09543;
      }

      &.more {
        background: #b051b3;
      }

      &.stub {
        background: #4d444b;
      }
    }

    .MuiTypography-root {
      color: #e9e0e4;
      font-family: Quicksand, sans-serif;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;
      letter-spacing: 0.4px;
      white-space: nowrap;

      ${({ forbenchmark }) =>
        forbenchmark &&
        forbenchmark === 'true' &&
        `
          color: #fff;
          font-family: Quicksand, sans-serif;
          font-size: 12px;
          font-style: normal;
          font-weight: 500;
          line-height: 16px;
          letter-spacing: 0.5px;
        `};
    }

    &:not(:first-of-type) {
      margin-left: 24px;
    }
  }
`

export const ExploreCategoriesWrapper = styled(Box)`
  display: flex;
  gap: 16px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
`
