import { Box } from '@mui/material'
import styled from '@emotion/styled/macro'

export const PerformanceScoreWrapper = styled(Box)<{ isempty: string }>`
  border-radius: 24px;
  background: linear-gradient(0deg, rgba(255, 169, 252, 0.05) 0%, rgba(255, 169, 252, 0.05) 100%), #1e1a1d;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.3), 0 4px 8px 3px rgba(0, 0, 0, 0.15);
  padding: 16px;
  width: 775px;
  min-width: 775px;
  height: 310px;

  @media (min-width: 1110px) {
    width: 571px;
    min-width: 571px;
  }

  @media (min-width: 1440px) {
    width: 633px;
    min-width: 633px;
  }
  @media (min-width: 1920px) {
    width: 775px;
    min-width: 775px;
    height: 310px;
    padding: 22px 32px;
  }

  ${({ isempty }) =>
    isempty === 'true' &&
    `
      opacity: 0.38;
      min-width: 497px;
      height: 367px;
  `};

  .title-wrapper {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    .title-block {
      display: flex;
      align-items: center;
    }

    .date {
      color: #998d96;
      font-family: Quicksand, sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: 0.1px;
    }

    img {
      width: 16px;
      height: 16px;
    }

    .chart-title {
      color: #e9e0e4;
      font-family: Quicksand, sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: 0.1px;
      margin-left: 8px;
    }
  }

  .pizza-chart-container {
    padding: 0px 20px 24px;
    display: flex;
    align-items: center;
    justify-content: space-around;
  }

  .pizza-chart-wrapper {
    margin-top: 20px;
  }
`

export const OverallScoreWrapper = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: flex-end;
  border-radius: 24px;
  background: #573f56;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.3), 0 1px 3px 1px rgba(0, 0, 0, 0.15);
  margin: 16px 0;

  .score-value {
    color: #fbd8f5;
    font-family: Quicksand, sans-serif;
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
  }

  .score-label {
    color: #fbd8f5;
    font-family: Quicksand, sans-serif;
    font-size: 11px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0.5px;
    margin-left: 8px;
    margin-bottom: 2px;
  }
`

export const EmptyPerformanceCategoriesWrapper = styled(Box)`
  width: 214px;
  height: 195px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.3), 0 1px 3px 1px rgba(0, 0, 0, 0.15);
  border-radius: 16px;
  margin-left: 20px;

  ::-webkit-scrollbar {
    display: none;
  }

  @media (min-width: 1280px) {
    margin-left: 45px;
  }

  .category-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px 12px;

    &:not(:last-of-type) {
      border-bottom: 1px solid #4d444b;
    }

    &:first-of-type {
      border-radius: 16px 16px 0 0;
    }

    &:last-of-type {
      border-radius: 0 0 16px 16px;
    }

    &:hover {
      background: #573f56;
      cursor: pointer;
      border-bottom: 1px solid #998d96;
    }

    .name {
      color: #d0c3cc;
      font-family: Quicksand, sans-serif;
      font-size: 11px;
      font-style: normal;
      font-weight: 500;
      line-height: 16px;
      letter-spacing: 0.5px;
    }

    .value {
      color: #d0c3cc;
      font-family: Quicksand, sans-serif;
      font-size: 11px;
      font-style: normal;
      font-weight: 500;
      line-height: 16px;
      letter-spacing: 0.5px;
      margin-left: 8px;
    }
  }
`

export const PerformanceCategoriesWrapper = styled(Box)`
  width: 100%;
  max-width: 280px;
  background: linear-gradient(0deg, rgba(255, 169, 252, 0.11) 0%, rgba(255, 169, 252, 0.11) 100%);
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.3), 0px 1px 3px 1px rgba(0, 0, 0, 0.15);
  border-radius: 16px;
  margin-left: 20px;
  height: 100%;
  max-height: 234px;
  overflow-y: scroll;

  ::-webkit-scrollbar {
    width: 4px;
    background: transparent;
  }

  ::-webkit-scrollbar-track {
    box-shadow: none;
    background: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #debdd9;
    border-radius: 8px;
  }
`

export const PerformanceCategoryItem = styled(Box)<{ isselected?: string }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 12px;

  &:not(:last-of-type) {
    border-bottom: 1px solid #4d444b;
  }

  &:first-of-type {
    border-radius: 16px 16px 0 0;
  }

  &:last-of-type {
    border-radius: 0 0 16px 16px;
  }

  &:hover {
    background: #573f56;
    cursor: pointer;
    border-bottom: 1px solid #998d96;
  }

  .name {
    color: #fff;
    font-family: Quicksand, sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0.5px;

    &:hover {
      color: #ffa9fc;
      text-decoration-line: underline;
    }
  }

  ${({ isselected }) =>
    isselected &&
    isselected === 'true' &&
    `
    background: #573f56;
    cursor: pointer;
    border-bottom: 1px solid #998d96;
    
    .name {
      color: #ffa9fc;
      text-decoration-line: underline;
    }
  `};

  .value {
    color: #fff;
    font-family: Quicksand, sans-serif;
    font-size: 11px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0.5px;
    margin-left: 8px;
  }

  .explore-category {
    color: #ffa9fc;
    text-align: center;
    font-family: Quicksand, sans-serif;
    font-size: 11px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0.5px;
    white-space: nowrap;

    &:hover {
      text-decoration: underline;
    }
  }
`
