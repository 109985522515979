import { FC } from 'react'
import { OutlinedInput, FormHelperText, InputProps } from '@mui/material'
import { useField } from 'formik'

interface Props extends InputProps {
  sx?: Record<string, unknown>
  name: string
  type?: string
  className?: string
  placeholder?: string
}

const ControlledOutlinedInput: FC<Props> = ({ name, sx, placeholder, className, type, ...rest }) => {
  const [field, meta] = useField(name)

  return (
    <>
      <OutlinedInput
        sx={sx}
        placeholder={placeholder}
        {...field}
        name={name}
        type={type}
        {...rest}
        className={className}
      />
      {meta.touched && meta.error && <FormHelperText sx={{ color: 'red', ml: 0 }}>{meta.error}</FormHelperText>}
    </>
  )
}

export default ControlledOutlinedInput
