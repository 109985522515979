import React, { FC, useState } from 'react'
import { Container } from './reports.styles'
import TabPanel from '../library/library-details/components/LibraryDetailsTabPanel'
import CreateReportTab from './sections/create-report-tab'
import HeaderComponent from '../../components/header/HeaderComponent'
import ContentWrapper from '../../components/header/ContentWrapper'

const Reports: FC = () => {
  const breadcrumbs = [{ name: 'Reports' }]
  const [tabValue] = useState(0)

  return (
    <Container>
      <HeaderComponent breadcrumbs={breadcrumbs} />
      <ContentWrapper line={1}>
        <TabPanel value={tabValue} index={0}>
          <CreateReportTab />
        </TabPanel>
      </ContentWrapper>

    </Container>
  )
}

export default Reports
