import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { UsCert } from '../../models/UsCert'
import { RootState } from '../store'

export interface UsCertState {
  usCerts: UsCert[]
  isLoading: boolean
  totalUsCerts: number
}

const initialState: UsCertState = {
  usCerts: [],
  isLoading: false,
  totalUsCerts: -1
}

export const usCertSlice = createSlice({
  name: 'US-Certs',
  initialState,
  reducers: {
    usCertsLoaded: (state: UsCertState, action: PayloadAction<{ usCerts: UsCert[] }>) => {
      state.usCerts = action.payload.usCerts
    },
    usCertsAreLoading: (state: UsCertState, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload
    },
    totalUsCertsChanged: (state: UsCertState, action: PayloadAction<number>) => {
      state.totalUsCerts = action.payload
    }
  }
})

export const usCertsSelector = (state: RootState): UsCert[] => state.usCerts.usCerts
export const usCertsLoadingSelector = (state: RootState) => state.usCerts.isLoading

export const { usCertsLoaded, usCertsAreLoading, totalUsCertsChanged } = usCertSlice.actions

export default usCertSlice.reducer
