import { FC, SVGProps } from 'react'

const IntegrationsAddIcon: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <rect width="40" height="40" rx="20" fill="#FFA9FC" />
    <path d="M27 21H21V27H19V21H13V19H19V13H21V19H27V21Z" fill="#590060" />
  </svg>
)

export default IntegrationsAddIcon
