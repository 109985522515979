import { FC } from 'react'
import { Box, Typography, CircularProgress } from '@mui/material'

/* Components */
import { Wrapper, Background, Content } from './reportCard.styles'
import { ThemeButton } from '../../../../../components/buttons'
import NewSvg from '../../../../../components/svg/NewSvg'
import { Chip } from '../../../../../components/nav/sidebar.styles'
import { DownloadIcon } from '../../../../../components/svg'

interface Props {
  title: string
  text: string
  backgroundUrl: string
  buttonText: string
  onClick: () => void
  disabled?: boolean
  comingSoon: boolean
  isNew?: boolean
  loading: boolean
}

const ReportCard: FC<Props> = ({
  title,
  text,
  backgroundUrl,
  isNew,
  buttonText,
  loading,
  onClick,
  disabled,
  comingSoon
}) => (
  <Wrapper is_coming_soon={comingSoon ? 'true' : 'false'}>
    {comingSoon && (
      <Box className="coming-soon-wrapper">
        <Chip isNew={false}>
          <NewSvg />
          <Typography className="chip-title">Coming Soon</Typography>
        </Chip>
      </Box>
    )}
    {isNew && (
      <Box className="new-wrapper">
        <Chip isNew={isNew}>
          <NewSvg />
          <Typography className="chip-title">New</Typography>
        </Chip>
      </Box>
    )}
    <Background backgroundUrl={backgroundUrl} />
    <Content>
      <Typography className="title">{title}</Typography>
      <Typography className="text">{text}</Typography>
      <ThemeButton
        themevariant="secondary"
        onClick={onClick}
        disabled={disabled}
        sx={{ textTransform: 'none !important' }}
      >
        <>
          {loading ? <CircularProgress size={18} color="secondary" /> : <DownloadIcon />}
          {buttonText}
        </>
      </ThemeButton>
    </Content>
  </Wrapper>
)

export default ReportCard
