const config = {
  auth: {
    baseUrl: process.env.REACT_APP_FRONTEGG_BASE_URL || '',
    clientId: process.env.REACT_APP_FRONTEGG_CLIENT_ID || ''
  },
  apiHost: process.env.REACT_APP_API_HOST || '',
  staticHost: process.env.REACT_APP_LOGO_HOST || ''
}

export default config
