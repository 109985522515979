import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Vulnerability } from '../../models/Vulnerability'
import { RootState } from '../store'

export interface VulnerabilityState {
  vulnerabilities: Vulnerability[]
  isLoading: boolean
  totalVulnerabilities: number
}

const initialState: VulnerabilityState = {
  vulnerabilities: [],
  isLoading: false,
  totalVulnerabilities: -1
}

export const vulnerabilitySlice = createSlice({
  name: 'Vulnerabilities',
  initialState,
  reducers: {
    vulnerabilitiesLoaded: (state: VulnerabilityState, action: PayloadAction<{ vulnerabilities: Vulnerability[] }>) => {
      state.vulnerabilities = action.payload.vulnerabilities
    },
    vulnerabilitiesAreLoading: (state: VulnerabilityState, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload
    },
    totalVulnerabilitiesChanged: (state: VulnerabilityState, action: PayloadAction<number>) => {
      state.totalVulnerabilities = action.payload
    }
  }
})

export const vulnerabilitiesSelector = (state: RootState): Vulnerability[] => state.vulnerabilities.vulnerabilities
export const vulnerabilitiesLoadingSelector = (state: RootState) => state.vulnerabilities.isLoading

export const { vulnerabilitiesLoaded, vulnerabilitiesAreLoading, totalVulnerabilitiesChanged } =
  vulnerabilitySlice.actions

export default vulnerabilitySlice.reducer
