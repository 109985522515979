import { FC } from 'react'
import { TableBody, TableCell, TableHead, TableRow, Skeleton } from '@mui/material'
import { TableContainerW, TableWrapper, Wrapper } from './table-components/DataTable.styles'

interface Props {
  height?: string
}

const LoadingSkeleton: FC<Props> = ({ height }) => {
  const rows = Array(height && !isNaN(parseInt(height)) ? Math.round(parseInt(height) / 47) : 25).fill(true)
  const cells = Array(7).fill(true)

  return (
    <Wrapper>
      <TableContainerW height={height || '100%'} hideoverflow="true">
        <TableWrapper
          sx={{ minWidth: 650 }}
          stickyHeader
          aria-label="sticky table loading"
          style={{ tableLayout: 'fixed' }}
        >
          <TableHead>
            <TableRow>
              <TableCell>Coverage</TableCell>
              <TableCell>Host Name</TableCell>
              <TableCell>Original Serial Number</TableCell>
              <TableCell>Mac Address</TableCell>
              <TableCell>Last Seen</TableCell>
              <TableCell>Operating System</TableCell>
              <TableCell>Matched By</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, index) => (
              <TableRow key={index}>
                {cells.map((cell, idx) => (
                  <TableCell key={idx} className="loading-cell-wrapper">
                    <Skeleton animation="wave" variant="rectangular" width="100%" height="20px" />
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </TableWrapper>
      </TableContainerW>
    </Wrapper>
  )
}

export default LoadingSkeleton
