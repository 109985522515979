import { FC, ReactNode, SyntheticEvent, Dispatch, SetStateAction, useState, useEffect } from 'react'
import { Box, CircularProgress, Tab, Typography } from '@mui/material'

/* Utils */
import { IntelInsight } from '../../../../../models'
import { getMissingSourcesMap, sidePanelWidth } from '../../utils'

/* Components */
import IntelFeedCard from '../../components/IntelFeedCard'
import { IntelFeedIcon } from '../../../../components/svg'
import {
  EmptyIntelFeedWrapper,
  FeedSectionWrapper,
  IntelFeedTabPanel,
  IntelFeedTabs,
  IntelFeedWrapper,
  LoadingWrapper
} from '../../dashboard.styles'

interface TabPanelProps {
  children?: ReactNode
  index: number
  value: number
  feedHeight: number
  onScroll: (e: any) => void
}

interface Props {
  id?: string
  feedHeight: number
  intelFeedTabValue: number
  handleTabChange: (event: SyntheticEvent, newValue: number) => void
  scrollHandler: (e: any) => void
  feedLoading: boolean
  internalFeed: IntelInsight[]
  fetchBookmarkFeed: () => void
  setDeleting: Dispatch<SetStateAction<boolean>>
  bookmarkFeed: IntelInsight[]
  deleting: boolean
}

const FeedSection: FC<Props> = ({
  id,
  intelFeedTabValue,
  handleTabChange,
  feedHeight,
  scrollHandler,
  feedLoading,
  internalFeed,
  fetchBookmarkFeed,
  setDeleting,
  bookmarkFeed,
  deleting
}) => {
  const [missingImageSourcesMap, setMissingImageSourcesMap] = useState<Map<string, string>>(new Map())

  useEffect(() => {
    setMissingImageSourcesMap(getMissingSourcesMap())
  }, [internalFeed])

  return (
    <FeedSectionWrapper id={id}>
      <IntelFeedWrapper width={sidePanelWidth}>
        <Box className="header">
          <IntelFeedIcon />
          <Typography>Intel Feed</Typography>
        </Box>
        <IntelFeedTabs
          value={intelFeedTabValue}
          onChange={handleTabChange}
          aria-label="intel feed tabs"
          TabIndicatorProps={{
            style: { display: 'none' }
          }}
        >
          <Tab label="Explore" {...a11yProps(0)} />
          <Tab label="Saved" {...a11yProps(1)} />
        </IntelFeedTabs>
        <TabPanel value={intelFeedTabValue} index={0} feedHeight={feedHeight} onScroll={scrollHandler}>
          {feedLoading && (
            <LoadingWrapper>
              <CircularProgress size="40px" color="primary" />
            </LoadingWrapper>
          )}
          {!feedLoading && !internalFeed.length && (
            <EmptyIntelFeedWrapper>
              <Typography>No data available</Typography>
            </EmptyIntelFeedWrapper>
          )}
          {!feedLoading &&
            internalFeed.length &&
            internalFeed.map((n, index) => (
              <IntelFeedCard
                key={index}
                intelInsight={n}
                callback={fetchBookmarkFeed}
                setDeleting={setDeleting}
                missingImageSourcesMap={missingImageSourcesMap}
              />
            ))}
        </TabPanel>
        <TabPanel value={intelFeedTabValue} index={1} feedHeight={feedHeight} onScroll={scrollHandler}>
          {feedLoading && (
            <LoadingWrapper>
              <CircularProgress size="40px" color="primary" />
            </LoadingWrapper>
          )}
          {!feedLoading && !bookmarkFeed.length ? (
            <EmptyIntelFeedWrapper>
              <Typography>There's nothing here.</Typography>
              <Typography className="small-text">
                Save an article from the Explore tab on your desktop or mobile device to get started.
              </Typography>
            </EmptyIntelFeedWrapper>
          ) : (
            !feedLoading &&
            bookmarkFeed.length &&
            bookmarkFeed.map((n, index) => (
              <IntelFeedCard
                key={index}
                intelInsight={n}
                callback={fetchBookmarkFeed}
                setDeleting={setDeleting}
                missingImageSourcesMap={missingImageSourcesMap}
              />
            ))
          )}

          {deleting && (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <CircularProgress size="24px" color="secondary" />
            </Box>
          )}
        </TabPanel>
      </IntelFeedWrapper>
    </FeedSectionWrapper>
  )
}

function a11yProps(index: number) {
  return {
    id: `intel-feed-tab-${index}`,
    'aria-controls': `intel-feed-tabpanel-${index}`
  }
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, feedHeight, onScroll, ...other } = props

  return (
    <IntelFeedTabPanel
      style={{ height: `${feedHeight}px` }}
      role="tabpanel"
      hidden={value !== index}
      id={`intel-feed-tabpanel-${index}`}
      aria-labelledby={`intel-feed-tab-${index}`}
      onScroll={onScroll}
      {...other}
    >
      {value === index && children}
    </IntelFeedTabPanel>
  )
}

export default FeedSection
