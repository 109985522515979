const OnyxAIIcon = () => (
  <svg width="62" height="62" viewBox="0 0 62 62" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#filter0_dd_72_2798)">
      <circle cx="31" cy="31" r="20" fill="url(#paint0_linear_72_2798)" />
      <circle cx="31" cy="31" r="19.6429" stroke="#BB95EA" strokeWidth="0.714286" />
    </g>
    <path d="M37 28L38.25 25.25L41 24L38.25 22.75L37 20L35.75 22.75L33 24L35.75 25.25L37 28Z" fill="white" />
    <path d="M37 34L35.75 36.75L33 38L35.75 39.25L37 42L38.25 39.25L41 38L38.25 36.75L37 34Z" fill="white" />
    <path
      d="M29.5 28.5L27 23L24.5 28.5L19 31L24.5 33.5L27 39L29.5 33.5L35 31L29.5 28.5ZM27.99 31.99L27 34.17L26.01 31.99L23.83 31L26.01 30.01L27 27.83L27.99 30.01L30.17 31L27.99 31.99Z"
      fill="white"
    />
    <defs>
      <filter
        id="filter0_dd_72_2798"
        x="0.285714"
        y="0.285714"
        width="61.4286"
        height="61.4286"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset />
        <feGaussianBlur stdDeviation="5.35714" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0.556863 0 0 0 0 0.305882 0 0 0 0 0.85098 0 0 0 1 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_72_2798" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset />
        <feGaussianBlur stdDeviation="1.78571" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0.556863 0 0 0 0 0.305882 0 0 0 0 0.85098 0 0 0 1 0" />
        <feBlend mode="normal" in2="effect1_dropShadow_72_2798" result="effect2_dropShadow_72_2798" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_72_2798" result="shape" />
      </filter>
      <linearGradient id="paint0_linear_72_2798" x1="31" y1="11" x2="31" y2="51" gradientUnits="userSpaceOnUse">
        <stop stopColor="#010002" />
        <stop offset="0.275" stopColor="#56406D" />
        <stop offset="0.53" />
      </linearGradient>
    </defs>
  </svg>
)

export default OnyxAIIcon
