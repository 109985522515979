import { Box } from '@mui/material'
import styled from '@emotion/styled/macro'

export const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  gap: 24px;
  box-sizing: border-box;
  height: 260px;
`

export const FrameworkSelectCardContainer = styled(Box)<{ isSelected: string }>`
  border-radius: 8px;
  background: #372a36;
  display: flex;
  padding: 24px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 24px;
  max-width: 400px;
  min-width: 354px;
  width: 100%;
  box-sizing: border-box;
  border: 2px solid transparent;
  height: 196px;

  ${({ isSelected }) =>
    isSelected === 'true' &&
    `
      border-color: #FFA9FC;
      background: #573F56;
    `};

  &:hover {
    cursor: pointer;
    background: #573f56;
  }
`

export const Header = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  .header-framework {
    display: flex;
    align-items: center;
    flex-grow: 1;
    gap: 8px;

    .header-title {
      color: #fff;
      text-align: center;
      font-family: Quicksand, sans-serif;
      font-size: 24px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
  }
`

export const Content = styled(Box)`
  .content-text {
    color: #fff;
    font-family: Quicksand, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
`
