import { Box, Typography } from '@mui/material'
import styled from '@emotion/styled/macro'

export const Wrapper = styled(Box)<{ isdisabled?: string }>`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  cursor: pointer;
  width: 100%;
  min-width: 881px;
  height: 80px;
  background: linear-gradient(0deg, rgba(255, 169, 252, 0.11), rgba(255, 169, 252, 0.11)), #1e1a1d;
  border-radius: 12px;

  ${({ isdisabled }) =>
    isdisabled &&
    isdisabled === 'true' &&
    `
    cursor: default;
  `};

  .title-wrapper {
    display: flex;
    flex-flow: column wrap;
  }
`

export const Title = styled(Typography)`
  font-family: 'Quicksand', sans-serif;
  color: #e9e0e4;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
`

export const Description = styled(Typography)`
  font-family: 'Quicksand', sans-serif;
  color: #e9e0e4;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.5px;
  margin-top: 4px;
`
