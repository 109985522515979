import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RssArticle } from '../../models/RssArticle'

interface RssState {
  articles?: RssArticle[]
}

const initialState: RssState = {
  articles: undefined
}

const rssSlice = createSlice({
  name: 'rss',
  initialState,
  reducers: {
    rssArticlesLoaded: (state: RssState, action: PayloadAction<any>) => {
      if (action.payload.errors) {
        state.articles = undefined
      } else {
        state.articles = action.payload
      }
    }
  }
})

export const { rssArticlesLoaded } = rssSlice.actions
export default rssSlice.reducer
