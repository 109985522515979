import React, { FC, useState } from 'react'
import { Box, ListItemText, MenuItem, OutlinedInput, SelectProps, Typography } from '@mui/material'

/* Components */
import { FormControl, MenuProps, Select, TextWrapper, Wrapper } from './miscControlSelectField.styles'
import { SelectDropdownIcon } from '../../../../../../components/svg'

interface Props extends SelectProps {
  options: string[]
  value: any
  handleChange: any
  minWidth?: string
  noborder?: string
  labeltext?: string
  noLabel?: boolean
  disabled?: boolean
  numberOfAssets?: number
}

const MiscControlSelectField: FC<Props> = ({
  options,
  labeltext,
  handleChange,
  placeholder,
  noborder,
  minWidth,
  value,
  noLabel,
  disabled,
  numberOfAssets,
  ...rest
}) => {
  const [anchorEl, setAnchorEl] = useState(null)

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget)
  }

  return (
    <Wrapper>
      {!noLabel ? (
        <TextWrapper>
          <Typography>Last Seen</Typography>
        </TextWrapper>
      ) : (
        ''
      )}

      <FormControl
        sx={{ minWidth }}
        isselected={
          placeholder !== 'Sort' && (value.length > 1 || (value.length === 1 && value[0] !== '')) ? 'true' : 'false'
        }
        is_opened={anchorEl ? 'true' : 'false'}
        is_disabled={disabled ? 'true' : 'false'}
        nolabel={noLabel ? 'true' : 'false'}
      >
        <Select
          noborder={noborder}
          isselected={
            placeholder !== 'Sort' && (value.length > 1 || (value.length === 1 && value[0] !== '')) ? 'true' : 'false'
          }
          labelId="library-select-label"
          id="library-select"
          displayEmpty
          MenuProps={{
            ...MenuProps,
            PaperProps: {
              ...MenuProps.PaperProps,
              style: { maxHeight: 'unset', display: anchorEl ? 'block' : 'none' }
            },
            anchorEl,
            open: Boolean(anchorEl),
            onClose: () => setAnchorEl(null)
          }}
          sx={{
            minWidth,
            '& .MuiSelect-icon': { transform: anchorEl ? 'rotate(180deg)' : 'rotate(0deg)' }
          }}
          IconComponent={(props) => <SelectDropdownIcon {...props} />}
          renderValue={(selected) => {
            if (Array.isArray(selected)) {
              const pruned = selected.filter((s: string) => s !== '')
              if (pruned.length === 0) {
                return placeholder
              }
              if (pruned.length === 1) {
                return pruned[0]
              }

              return `${pruned[0]} +${pruned.length - 1}`
            }

            return (
              <Box className="input-value-wrapper">
                <Typography className="input-value-integration">{value}</Typography>
                <Typography className="input-value-count">{numberOfAssets}</Typography>
              </Box>
            )
            if (labeltext) {
              return typeof noborder !== 'undefined' ? `${labeltext} ${selected}` : selected
            }
            return typeof noborder !== 'undefined' ? `Sort by: ${selected}` : selected
          }}
          inputProps={{ 'aria-label': 'Without label' }}
          {...rest}
          value={value}
          onChange={handleChange}
          input={<OutlinedInput onMouseDown={handleClick} />}
        >
          {options.map((option) => (
            <MenuItem
              key={option}
              value={option}
              onClick={() => {
                if (!rest.multiple) {
                  setAnchorEl(null)
                }
              }}
              disableRipple
            >
              <ListItemText primary={option} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Wrapper>
  )
}

export default MiscControlSelectField
