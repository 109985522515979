import React from 'react'
import { Actions, Header, Wrapper } from './SSoVerificationFailed.styles'
import { Box, Typography } from '@mui/material'
import { DeleteIcon } from '../../../svg'
import { closeModal, modalPropsSelector } from '../../../../../store/modals/modalSlice'
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks'
import { ThemeButton } from '../../../buttons'
import axios from '../../../../../lib/axios'
import { closeToast, openToast, ToastVariant } from '../../../../../store/toasts/toastSlice'

const SSoDeleteNotification = () => {
  const dispatch = useAppDispatch()
  const modalProps = useAppSelector(modalPropsSelector)

  const handleClose = () => {
    dispatch(closeModal())
  }

  const handleDelete = async () => {
    try {
      await axios.delete('/api/v3/sso', {
        data: {
          domain: modalProps.props?.connection?.domain
        }
      })

      setTimeout(() => {
        dispatch(
          openToast({
            variant: ToastVariant.success,
            props: {
              text: 'SSO Connection deleted',
              description: 'SSO Connection successfully deleted. '
            }
          })
        )
      }, 500)
      if (modalProps?.props?.callback) {
        await handleClose()
        await modalProps.props.callback()
      }
      setTimeout(() => {
        dispatch(closeToast())
      }, 5000)
    } catch (e) {
      console.error(e)
    }
  }

  return (
    <Wrapper>
      <Header>
        <Box className="header-block">
          <Typography className="title">Delete SSO Connection</Typography>
        </Box>
      </Header>

      <Typography className="description-line">Are you sure you want to delete this SSO connection?</Typography>

      <Actions>
        <ThemeButton themevariant="tertiary" onClick={handleClose}>
          Go Back
        </ThemeButton>
        <ThemeButton
          themevariant="secondary"
          type="button"
          onClick={handleDelete}
          sx={{
            background: '#FFB4AB !important',
            color: '#690005 !important',

            svg: {
              path: {
                fill: '#690005 !important'
              }
            }
          }}
        >
          <>
            <DeleteIcon />
            Delete
          </>
        </ThemeButton>
      </Actions>
    </Wrapper>
  )
}

export default SSoDeleteNotification
