/* eslint-disable no-unused-vars */
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../store'
import { TableData, UsCert, Vulnerability } from '../../models'

export enum DrawerType {
  jiraTicket = 'jiraTicket'
}

export enum DrawerVariant {
  add = 'add',
  edit = 'edit',
  view = 'view',
  delete = 'delete'
}

export interface DrawerProps {
  vulnerability?: Vulnerability
  usCert?: UsCert
  task?: TableData
}

export interface DrawerState {
  type?: DrawerType
  open: boolean
  variant: DrawerVariant
  props?: DrawerProps
}

const initialState: DrawerState = {
  type: undefined,
  open: false,
  variant: DrawerVariant.add,
  props: undefined
}

export const drawerSlice = createSlice({
  name: 'Drawers',
  initialState,
  reducers: {
    openDrawer: (
      state: DrawerState,
      action: PayloadAction<{
        type?: DrawerType
        variant?: DrawerVariant
        props?: DrawerProps
      }>
    ) => {
      const { type, variant, props } = action.payload

      state.type = type
      state.open = true
      state.variant = variant ?? DrawerVariant.add
      state.props = props
    },
    closeDrawer: (state: DrawerState) => {
      state.type = undefined
      state.open = false
    }
  }
})

export const drawerPropsSelector = (state: RootState): DrawerState => state.drawers

export const { openDrawer, closeDrawer } = drawerSlice.actions

export default drawerSlice.reducer
