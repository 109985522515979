import { Box, Tabs as MuiTabs } from '@mui/material'
import styled from '@emotion/styled/macro'

export const Tabs = styled(MuiTabs)<{ isdetails?: string }>`
  background: transparent;
  height: auto;

  .MuiTabs-scroller {
    height: 48px !important;
    padding-left: 24px;

    .MuiTabs-flexContainer {
      height: 48px !important;
    }
  }

  .MuiButtonBase-root {
    border-radius: 0;
    font-family: 'Quicksand', sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.5px;
    color: #debdd9;
    border: none;
    text-transform: none;
    padding: 0;
    //width: 94px;
    width: fit-content;
    min-width: unset;
    height: 48px;
    min-height: 48px;
    margin-right: 40px;
    //padding-bottom: 30px;

    &.Mui-selected {
      color: #ffa9fc;
      border-bottom: 2px solid #ffa9fc;
      //padding-bottom: 38px;
      //padding-top: 9px;
    }

    &.Mui-disabled {
      color: #debdd9;
    }
  }

  ${({ isdetails }) =>
    isdetails &&
    isdetails === 'true' &&
    `
    height: 48px;
    min-height: 48px;
    
    .MuiTabs-scroller {
      height: 48px;
      
      .MuiTabs-flexContainer {
        height: 48px;
      }
    }
    
    .MuiButtonBase-root {
      height: 48px;
      white-space: nowrap;
      width: fit-content;
    }
  `};
`

export const DrawerTabPanelWrapper = styled(Box)`
  height: calc(100vh - 324px);
`
