import { useMemo } from 'react'
import { Box, Typography } from '@mui/material'
import { circularProgressClasses, CircularProgressProps } from '@mui/material/CircularProgress'

/* Components */
import { CircleInner, CircleOuter, Container, popperProps, ValueWrapper } from './performanceWidget.styles'
import { BenchmarkCardTooltip } from '../../../../../dashboard/components/Benchmarking/components/BenchmarkCardTooltip'
import { OverPerformIcon } from '../../../../../../components/svg'

interface Props {
  value: number
  categoryName: string
}

const PerformanceWidget = ({ value, categoryName, ...props }: Props & CircularProgressProps) => {
  const overPerformValue = useMemo(() => {
    return +value - 100
  }, [value])

  return (
    <Container>
      <BenchmarkCardTooltip
        PopperProps={popperProps}
        title={
          <>
            <Box className="tooltip-wrapper">
              <Typography className="tooltip-title">{value}%</Typography>
              <Typography className="tooltip-text">
                Your current{' '}
                <Typography className="tooltip-category" component="span">
                  {categoryName}
                </Typography>{' '}
                Performance Score
              </Typography>
            </Box>
          </>
        }
      >
        <CircleInner
          variant="determinate"
          sx={{
            [`& .${circularProgressClasses.circle}`]: {
              strokeLinecap: 'round'
            }
          }}
          size={200}
          thickness={5}
          {...props}
          value={value - 100}
        />
      </BenchmarkCardTooltip>
      <BenchmarkCardTooltip
        PopperProps={popperProps}
        title={
          <>
            <Box className="tooltip-wrapper">
              <Typography className="tooltip-title">{value}%</Typography>
              <Typography className="tooltip-text">
                Your current{' '}
                <Typography className="tooltip-category" component="span">
                  {categoryName}
                </Typography>{' '}
                Performance Score
              </Typography>
            </Box>
          </>
        }
      >
        <CircleOuter
          variant="determinate"
          sx={{
            [`& .${circularProgressClasses.circle}`]: {
              strokeLinecap: 'round'
            }
          }}
          size={200}
          thickness={5}
          {...props}
          value={value > 100 ? 100 : value}
        />
      </BenchmarkCardTooltip>
      <BenchmarkCardTooltip
        PopperProps={popperProps}
        title={
          <>
            <Box className="tooltip-wrapper">
              <Typography className="tooltip-title">{value}%</Typography>
              <Typography className="tooltip-text">
                Your current{' '}
                <Typography className="tooltip-category" component="span">
                  {categoryName}
                </Typography>{' '}
                Performance Score
              </Typography>
            </Box>
          </>
        }
      >
        <ValueWrapper>
          <Typography variant="caption" component="div">
            {value}%
          </Typography>
        </ValueWrapper>
      </BenchmarkCardTooltip>

      {overPerformValue > 0 && (
        <Box className="over-perform">
          <Box className="info">
            <OverPerformIcon />
            <Typography className="over-perform-value">
              {overPerformValue}
              <Typography className="over-perform-value-unit">%</Typography>
            </Typography>
          </Box>
          <Typography className="over-perform-desc">Over Performing</Typography>
        </Box>
      )}
    </Container>
  )
}

export default PerformanceWidget
