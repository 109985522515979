import { Box, Typography } from '@mui/material'
import styled from '@emotion/styled/macro'

export const DeleteInstanceWrapper = styled(Box)`
  background: linear-gradient(0deg, rgba(255, 169, 252, 0.11), rgba(255, 169, 252, 0.11)), #1e1a1d;
  border-radius: 8px;
  padding: 24px 24px 18px;

  .input-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;

    .MuiFormControl-root {
      margin-bottom: 24px;
      width: 360px;

      label {
        color: #d0c3cc;

        .MuiInputLabel-asterisk {
          color: #fe455d;
        }
      }

      .MuiOutlinedInput-root {
        font-family: 'Quicksand', sans-serif;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.5px;
        color: #d0c3cc;
        border-radius: 4px;

        fieldset {
          border: 1px solid #998d96 !important;
        }
      }
    }
  }
`

export const DeleteInstanceTitle = styled(Typography)`
  font-family: 'Quicksand', sans-serif;
  font-weight: 400;
  font-size: 24px;
  line-height: 32px;
  color: #e9e0e4;
  mix-blend-mode: normal;
  margin-bottom: 16px;
`

export const DeleteInstanceSubtitle = styled(Typography)`
  font-family: 'Quicksand', sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.25px;
  color: #e9e0e4;
  mix-blend-mode: normal;
  margin-bottom: 16px;
`

export const DeleteInstanceActionsWrapper = styled(Box)`
  display: flex;
  justify-content: flex-end;
  margin-top: 6px;
  margin-bottom: 8px;

  .MuiCircularProgress-root {
    color: #690005;
  }

  .MuiButtonBase-root:first-of-type {
    margin-right: 8px;
  }

  .MuiButtonBase-root:last-of-type {
    background: #ffb4ab;
    color: #690005;

    svg {
      path {
        fill: #690005;
      }
    }

    &.Mui-disabled {
      background: rgba(227, 227, 227, 0.12);
      color: #E9E0E4;

      svg {
        path {
          fill: #E9E0E4;
        }
      }
    }
  }
`
