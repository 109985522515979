import styled from '@emotion/styled/macro'
import { Box } from '@mui/material'

export const Wrapper = styled(Box)`
  width: 100%;
  padding: 16px 26px 24px 26px;
  border-radius: 16px;
  border: 1px solid #998d96;
  background: linear-gradient(0deg, rgba(255, 169, 252, 0.05) 0%, rgba(255, 169, 252, 0.05) 100%), #1e1a1d;
  box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.15), 0px 1px 2px 0px rgba(0, 0, 0, 0.3);

  .title {
    color: #e9e0e4;
    font-family: Quicksand, sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 16px;
  }
`

export const Content = styled(Box)`
  .title {
    color: #fff;
    text-align: center;
    text-shadow: 0px 1px 3px rgba(0, 0, 0, 0.15), 0px 1px 2px rgba(0, 0, 0, 0.3);
    font-family: Quicksand, sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 16px;
  }

  .description {
    max-width: 532px;
    color: #fff;
    text-align: center;
    text-shadow: 0px 1px 3px rgba(0, 0, 0, 0.15), 0px 1px 2px rgba(0, 0, 0, 0.3);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 0 auto;
  }

  .action-wrapper {
    margin-top: 24px;
    display: flex;
    justify-content: center;
  }
`
