const AuditActivatedAccountIcon = () => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.895 4.125L9.195 5.625H13.125V10.125H10.605L10.305 8.625H4.875V4.125H8.895ZM10.125 2.625H3.375V15.375H4.875V10.125H9.075L9.375 11.625H14.625V4.125H10.425L10.125 2.625Z"
      fill="#FFA9FC"
    />
  </svg>
)

export default AuditActivatedAccountIcon
