import { FC, FormEvent, useState } from 'react'
import { useAppDispatch } from '../../../../../store/hooks'
import { Box, CircularProgress, Typography, DialogTitle, DialogContent, DialogActions } from '@mui/material'

/* Utils */
import { closeModal } from '../../../../../store/modals/modalSlice'
import { notification } from '../../../../../store/notifications/notificationSlice'
import { fetchConfiguredIntegrations } from '../../../../../store/integrations/actions'
import axios from '../../../../../lib/axios'

/* Components */
import { DeleteIntegrationContainer, CancelButton, ConfirmButton, TextField } from './deleteIntegration.styles'
import { DeleteIcon } from '../../../svg'

interface Props {
  type: string
  url: string
  description?: string
}

const DeleteIntegration: FC<Props> = ({ type, url, description }) => {
  const dispatch = useAppDispatch()
  const [value, setValue] = useState('')
  const [disabled, setDisabled] = useState(true)
  const [deleting, setDeleting] = useState(false)

  const handleClose = () => {
    dispatch(closeModal())
  }

  const handleDelete = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    try {
      setDeleting(true)
      const res = await axios.delete(url)

      if (res.status === 200 || res.status === 204) {
        dispatch(
          notification({
            open: true,
            variant: 'success',
            message: `Your ${type} integration was removed.`
          })
        )
        dispatch(fetchConfiguredIntegrations())

        handleClose()
      }
    } catch (err) {
      dispatch(
        notification({
          open: true,
          variant: 'error',
          message: `Unable to disable ${type} integration.`,
          title: 'Error'
        })
      )
    } finally {
      setDeleting(false)
    }
  }

  return (
    <DeleteIntegrationContainer>
      <form onSubmit={handleDelete}>
        <DialogTitle id="dialog-title">
          <Typography className="title">Are you sure?</Typography>
        </DialogTitle>
        <DialogContent>
          <Typography className="description">
            {description ||
              `You’re about to delete your ${type} integration, which can’t be undone. Related tickets will be stored for 30
            days.`}
          </Typography>
          <Box className="input-wrapper">
            <TextField
              variant="outlined"
              placeholder='Type "DELETE" to confirm'
              value={value}
              autoComplete="off"
              onChange={(e) => {
                if (e.target.value === 'DELETE') {
                  setDisabled(false)
                } else {
                  setDisabled(true)
                }
                setValue(e.target.value)
              }}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Box>
            <CancelButton type="button" onClick={handleClose}>
              Go Back
            </CancelButton>
            <ConfirmButton
              type="submit"
              disabled={disabled || deleting}
              endIcon={deleting && <CircularProgress size="20px" color="secondary" />}
            >
              <DeleteIcon />
              Confirm
            </ConfirmButton>
          </Box>
        </DialogActions>
      </form>
    </DeleteIntegrationContainer>
  )
}

export default DeleteIntegration
