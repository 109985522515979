import { Box, Button as MuiButton, TextField as MuiTextField } from '@mui/material'
import styled from '@emotion/styled/macro'

export const DeleteIntegrationContainer = styled(Box)`
  max-width: 600px;
  background: linear-gradient(0deg, rgba(255, 169, 252, 0.05), rgba(255, 169, 252, 0.05)), #1e1a1d;
  border-radius: 8px;

  .MuiDialogTitle-root {
    box-shadow: none;
  }

  .title {
    font-family: 'Quicksand', sans-serif;
    font-weight: 400;
    font-size: 24px;
    line-height: 32px;
    color: #e9e0e4;
    mix-blend-mode: normal;
  }

  .description {
    font-family: 'Quicksand', sans-serif;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.25px;
    color: #e9e0e4;
    mix-blend-mode: normal;
    margin-bottom: 16px;
  }

  .input-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;

    .MuiFormControl-root {
      height: 56px;

      .MuiOutlinedInput-root {
        height: 56px;
        border: 1px solid #998d96;
        border-radius: 4px;
        background: transparent;
        font-family: 'Quicksand', sans-serif;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.5px;
        color: #d0c3cc;
        min-width: 360px;

        input {
          color: #d0c3cc;
          font-family: 'Quicksand', sans-serif;
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          letter-spacing: 0.5px;

          &::placeholder {
            color: #d0c3cc;
          }
        }
      }
    }
  }
`

export const ConfirmButton = styled(MuiButton)`
  text-transform: capitalize;
  padding: 10px 24px 10px 16px;
  gap: 8px;
  background: #ffb4ab;
  border-radius: 100px;
  min-width: 120px;
  height: 40px;
  font-family: 'Quicksand', sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.1px;
  color: #690005;
  margin-right: 16px;
  margin-bottom: 10px;

  svg {
    path {
      fill: #690005;
    }
  }

  &:hover {
    background: #ffb4ab;
    opacity: 0.9;
    color: #690005;
  }

  &.Mui-disabled {
    background: rgba(227, 227, 227, 0.12);
    color: #e9e0e4;

    svg {
      path {
        fill: #e9e0e4;
      }
    }
  }
`

export const CancelButton = styled(MuiButton)`
  text-transform: capitalize;
  margin-right: 8px;
  padding: 10px 12px;
  gap: 8px;
  min-width: 80px;
  height: 40px;
  border-radius: 100px;
  font-family: 'Quicksand', sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.1px;
  color: #ffa9fc;
`

export const TextField = styled(MuiTextField)`
  .MuiInputBase-root {
    height: 32px;
  }

  .MuiOutlinedInput-input {
    color: #140c40;
    font-size: 14px;
    line-height: 32px;
    font-weight: 400;
  }

  .MuiOutlinedInput-root {
    background: #fff;
  }
`
