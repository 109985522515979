import styled from '@emotion/styled/macro'
import { Box } from '@mui/material'

export const Container = styled(Box)`
  padding: 24px;
  border-radius: 16px;
  width: 748px;
  margin: 32px auto 0 auto;
  border: 1px solid #4d444b;
  background: linear-gradient(0deg, rgba(255, 169, 252, 0.08) 0%, rgba(255, 169, 252, 0.08) 100%);

  .title {
    color: #e9e0e4;
    font-family: 'Quicksand', sans-serif;
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
    margin-bottom: 28px;
  }

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 16px;
    padding-left: 16px;
    margin-bottom: 8px;

    .name {
      color: #d0c3cc;
      font-family: 'Quicksand', sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0.25px;
    }

    .value {
      width: 175px;
      color: #d0c3cc;
      font-family: 'Quicksand', sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0.25px;
    }
  }

  .content {
    .content-item {
      border-radius: 16px;
      background: linear-gradient(0deg, rgba(255, 169, 252, 0.11) 0%, rgba(255, 169, 252, 0.11) 100%);
      padding: 8px 16px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 4px;

      .name {
        color: #e9e0e4;
        font-family: 'Quicksand', sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: 0.1px;
      }

      .value {
        width: 175px;
        color: #e9e0e4;
        font-family: 'Quicksand', sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0.5px;
      }

      .MuiOutlinedInput-root {
        border-radius: 4px;
        border: 1px solid #998d96;
        color: #d0c3cc;
        font-family: 'Quicksand', sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0.5px;
        height: 56px;
      }

      .MuiFormControl-root {
        width: 175px;
      }

      .length3 input {
        width: 28px;
      }

      .length2 input {
        width: 18px;
      }

      .length1 input {
        width: 9px;
      }

      .MuiTypography-root {
        color: #d0c3cc;
      }

      .Mui-disabled {
        color: #d0c3cc;
        -webkit-text-fill-color: #d0c3cc;
        cursor: not-allowed;
      }

      .Mui-focused {
        border: 2px solid #ffa9fc;

        input {
          width: 100%;
        }

        .percentage {
          display: none;
        }
      }
    }

    .line {
      margin-top: 16px;
      margin-bottom: 16px;
      width: 100%;
      height: 1px;
      background: #998d96;
    }
  }
`

export const FrameworkSection = styled(Box)`
  margin-bottom: 36px;

  .sub-title {
    color: #d0c3cc;
    font-family: 'Quicksand', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.25px;
    margin-bottom: 16px;
  }

  .framework {
    border-radius: 16px;
    background: linear-gradient(0deg, rgba(255, 169, 252, 0.11) 0%, rgba(255, 169, 252, 0.11) 100%);
    padding-left: 12px;
    padding-right: 16px;
    padding-top: 12px;
    padding-bottom: 12px;
    display: flex;
    align-items: center;
    gap: 8px;

    .text {
      color: #e9e0e4;
      font-family: 'Quicksand', sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0.5px;
    }
  }
`
