import { FC } from 'react'
import { Box, Button, Typography } from '@mui/material'

/* Components */
import { CategoryWrapper, ExploreCategoryWrapper } from './category.styles'
import { CategoryActivityItem } from '../../../../../../models'

interface Props {
  category: CategoryActivityItem
  handleCategoryClick: (category: string) => void
  handleCpiRedirect: (category: CategoryActivityItem) => void
}

const AvailableCategory: FC<Props> = ({ category, handleCategoryClick, handleCpiRedirect }) => (
  <CategoryWrapper titlelength={category.name.length}>
    <Box className="header" onClick={() => handleCategoryClick(category.name)}>
      <Typography className="category-title category-margin">{category.name}</Typography>
    </Box>

    <ExploreCategoryWrapper>
      <Box className="scale">
        <Typography className="scale-text">100%</Typography>
        <Typography className="scale-text">50%</Typography>
        <Typography className="scale-text">0%</Typography>
      </Box>
      <Box className="chart-button-wrapper">
        <Button onClick={() => handleCpiRedirect(category)}>
          <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
            <path d="M9 3L7.9425 4.0575L12.1275 8.25H3V9.75H12.1275L7.9425 13.9425L9 15L15 9L9 3Z" fill="#DEBDD9" />
          </svg>
          Explore CPIs
        </Button>
      </Box>
    </ExploreCategoryWrapper>
    <Typography className="available-status-sources-text">Data Sources: </Typography>
  </CategoryWrapper>
)

export default AvailableCategory
