import { Box } from '@mui/material'
import styled from '@emotion/styled/macro'

export const Wrapper = styled(Box)`
  display: flex;
  flex-flow: column wrap;
  width: 100%;
  position: relative;

  @media (max-width: 1440px) {
    margin-top: 16px;
  }

  .chips-title {
    font-family: 'Quicksand', sans-serif;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.1px;
    color: #ffffff;
    margin-bottom: 8px;
    white-space: nowrap;
  }

  .MuiChip-root {
    background: transparent;
    border-radius: 8px;
    border: 1px solid #998d96;
    font-family: 'Quicksand', sans-serif;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.5px;
    color: #ffffff;
    text-transform: capitalize;

    &.selected {
      color: #590060;
      border: none;
      background: #ffa9fc;
    }

    &:hover {
      background: #ffa9fc !important;
      color: #590060 !important;
      opacity: 0.9 !important;
    }
  }

  .month-picker-container {
    position: absolute;
    top: 225px;
    left: 457px;
    z-index: 10000;
    width: 428px;
    height: 209px;
    display: none;
    border-radius: 8px;
    background: #573f56;
    transition: opacity 313ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, transform 208ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

    @media (max-width: 1279px) {
      top: 267px;
      left: 37px;
    }

    &.isopen {
      padding: 0;

      > div:first-of-type {
        padding: 16px 12px 4px;
        justify-content: center;
        gap: 170px;

        & [class*='mx-4'] {
          &:before {
            width: 1px;
            height: 44px;
            background-color: #614964;
            position: absolute;
            left: 211.5px;
            top: 0;
            content: '';
            display: block;
          }
        }

        > div:first-of-type,
        > div:last-of-type {
          display: none;
        }
      }

      .months-wrapper {
        &:before {
          width: 1px;
          height: 44px;
          background-color: #614964;
          position: absolute;
          left: 211.5px;
          top: 165px;
          content: '';
          display: block;
        }

        > div:first-of-type {
          gap: 8px 0;
          padding: 4px 24px 0 0;

          > div {
            width: 40px;
            height: 40px;
          }
        }

        > div:nth-of-type(2) {
          gap: 8px 0;
          padding: 4px 8px 0 20px;
        }
      }
    }

    p {
      color: #d0c3cc;
      text-align: center;
      font-family: Quicksand, sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: 0.1px;
    }

    & [class*='gap-4'] {
      > div:first-of-type {
        &:before {
          width: 1px;
          height: 120px;
          background-color: #614964;
          position: absolute;
          left: 212px;
          top: 44px;
          content: '';
          display: block;
        }
      }
    }

    .month-picker-month {
      color: #fbd8f5;
      text-align: center;
      font-family: Quicksand, sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0.25px;

      &[class*='variant-start'],
      &[class*='variant-end'] {
        background: #ffa9fc;
        color: #590060;
      }

      &[class*='variant-selected'] {
        background: #614964;
        color: #fbd8f5;
      }

      &[class*='variant-selected'] {
        color: #fbd8f5;
      }

      &[class*='variant-default'] {
        &:hover {
          background: transparent;
        }
      }
    }

    &.isopen {
      display: flex;
    }
  }
`
