import { FC, Fragment, useLayoutEffect, MouseEvent, useCallback, useEffect, useRef, useState } from 'react'
import { Box, Typography, Divider, IconButton } from '@mui/material'
import FullscreenIcon from '@mui/icons-material/Fullscreen'

/* Utils */
import {
  nistPillars,
  groupSSMData,
  getNistPillarBackground,
  getHeaderItems,
  getBudgetBreakpoints
} from './securityStackMap.utils'
import { useAppDispatch } from '../../../store/hooks'
import { notification } from '../../../store/notifications/notificationSlice'
import {
  SSMAssetType,
  SSMGroupedData,
  SSMNistPillar,
  SSMResponseProduct,
  SSMResponseV2,
  SSMGroupedDataItem,
  SSMResponseProductExtended,
  VendorExtended
} from '../../../models'
import axios from '../../../lib/axios'
import useResizeObserver from '../../../hooks/useResizeObserver'

/* Components */
import {
  Container,
  Header,
  ViewToggle,
  ContentContainer,
  SSMGrid,
  GridHeader,
  BasicCell,
  EmptySSMGridContainer
} from './ssm.styles'
import SSMCard from './components/SSMCard'
import BudgetViewCard from './components/BudgetViewCard'
import { ToggleButton } from '../../components/buttons'
import { NistIcon } from '../../components/svg'
import NistPillar from './components/NistPillar'
import SSMSettingsDrawer from './components/SSMSettingsDrawer'
import ConfirmRemovalDialog from './components/ConfirmRemovalDialog'

export interface CurrentCard {
  open: boolean
  nist?: SSMNistPillar
  asset?: SSMAssetType
  target?: HTMLDivElement | null
  vendor?: VendorExtended
}

const placeholderAssets: SSMGroupedDataItem[] = [
  {
    nist: SSMNistPillar.Identify,
    asset: SSMAssetType.Devices,
    items: []
  },
  {
    nist: SSMNistPillar.Identify,
    asset: SSMAssetType.Applications,
    items: []
  },
  {
    nist: SSMNistPillar.Identify,
    asset: SSMAssetType.Networks,
    items: []
  },
  {
    nist: SSMNistPillar.Identify,
    asset: SSMAssetType.Data,
    items: []
  },
  {
    nist: SSMNistPillar.Identify,
    asset: SSMAssetType.Users,
    items: []
  },
  {
    nist: SSMNistPillar.Protect,
    asset: SSMAssetType.Devices,
    items: []
  },
  {
    nist: SSMNistPillar.Protect,
    asset: SSMAssetType.Applications,
    items: []
  },
  {
    nist: SSMNistPillar.Protect,
    asset: SSMAssetType.Networks,
    items: []
  },
  {
    nist: SSMNistPillar.Protect,
    asset: SSMAssetType.Data,
    items: []
  },
  {
    nist: SSMNistPillar.Protect,
    asset: SSMAssetType.Users,
    items: []
  },
  {
    nist: SSMNistPillar.Detect,
    asset: SSMAssetType.Devices,
    items: []
  },
  {
    nist: SSMNistPillar.Detect,
    asset: SSMAssetType.Applications,
    items: []
  },
  {
    nist: SSMNistPillar.Detect,
    asset: SSMAssetType.Networks,
    items: []
  },
  {
    nist: SSMNistPillar.Detect,
    asset: SSMAssetType.Data,
    items: []
  },
  {
    nist: SSMNistPillar.Detect,
    asset: SSMAssetType.Users,
    items: []
  },
  {
    nist: SSMNistPillar.Respond,
    asset: SSMAssetType.Devices,
    items: []
  },
  {
    nist: SSMNistPillar.Respond,
    asset: SSMAssetType.Applications,
    items: []
  },
  {
    nist: SSMNistPillar.Respond,
    asset: SSMAssetType.Networks,
    items: []
  },
  {
    nist: SSMNistPillar.Respond,
    asset: SSMAssetType.Data,
    items: []
  },
  {
    nist: SSMNistPillar.Respond,
    asset: SSMAssetType.Users,
    items: []
  },
  {
    nist: SSMNistPillar.Recover,
    asset: SSMAssetType.Devices,
    items: []
  },
  {
    nist: SSMNistPillar.Recover,
    asset: SSMAssetType.Applications,
    items: []
  },
  {
    nist: SSMNistPillar.Recover,
    asset: SSMAssetType.Networks,
    items: []
  },
  {
    nist: SSMNistPillar.Recover,
    asset: SSMAssetType.Data,
    items: []
  },
  {
    nist: SSMNistPillar.Recover,
    asset: SSMAssetType.Users,
    items: []
  },
  {
    nist: SSMNistPillar.Govern,
    asset: SSMAssetType.Devices,
    items: []
  },
  {
    nist: SSMNistPillar.Govern,
    asset: SSMAssetType.Applications,
    items: []
  },
  {
    nist: SSMNistPillar.Govern,
    asset: SSMAssetType.Networks,
    items: []
  },
  {
    nist: SSMNistPillar.Govern,
    asset: SSMAssetType.Data,
    items: []
  },
  {
    nist: SSMNistPillar.Govern,
    asset: SSMAssetType.Users,
    items: []
  }
]
const padding = 48
const header = 100
const gridHeader = 45
const headers = ['Devices', 'Applications', 'Networks', 'Data', 'Users']
export const SSM_EMPTY_BANNER_CLOSED = 'ssm_empty_banner_closed'

export interface HeaderItemProps {
  headerCost: number
  numOfItems: number
}

export interface DrawerProps {
  nist: SSMNistPillar | null
  asset: SSMAssetType | null
  assets: SSMResponseProduct[]
}

const SSM: FC = () => {
  const dispatch = useAppDispatch()
  const containerRef = useRef<HTMLDivElement>(null)
  const gridContainerRef = useRef<HTMLDivElement>(null)
  const [view, setView] = useState('coverage')
  const [loading, setLoading] = useState(true)
  const [groupedData, setGroupedData] = useState<SSMGroupedData>([])
  const [allProducts, setAllProducts] = useState<SSMResponseProduct[]>([])
  const [drawerOpen, setDrawerOpen] = useState(false)
  const [drawerProps, setDrawerProps] = useState<DrawerProps>({ nist: null, asset: null, assets: [] })
  const [budgetBreakpoints, setBudgetBreakpoints] = useState([0, 0, 0, 0, 0])
  const [confirmRemoveProps, setConfirmRemoveProps] = useState<any>({
    asset: null,
    thisNist: null,
    thisAsset: null,
    assets: [],
    open: false,
    displayName: '',
    numOfTiles: 0
  })
  const dimensions = useResizeObserver(containerRef)
  const [itemHeight, setItemHeight] = useState(
    ((containerRef?.current?.clientHeight || 1350) - gridHeader - padding - header) / 6
  )
  const [headerItems, setHeaderItems] = useState<Record<string, HeaderItemProps | number>>({})
  const [removing, setRemoving] = useState(false)
  const [expanded, setExpanded] = useState<string | false>(false)
  const [maxCellValue, setMaxCellValue] = useState(0)
  const [emptyStateBanner, setEmptyStateBanner] = useState(false)
  const ssmEmptyBannerClosed =
    window && typeof window !== 'undefined' ? localStorage.getItem(SSM_EMPTY_BANNER_CLOSED) : 'false'

  const getMaxCellValue = (data: any) => {
    if (data && data.length > 0) {
      let maxCellValue = 0

      const arr = [...nistPillars]
      arr.forEach((nist) => {
        const assets: SSMGroupedDataItem[] = data.filter((n: any) => n.nist === nist)

        assets.forEach(({ items }) => {
          const cellTotalCost = items.length > 0 ? items.reduce((acc, obj) => acc + obj.cost, 0) : 0

          if (cellTotalCost > maxCellValue) {
            maxCellValue = cellTotalCost
          }
        })
      })

      return maxCellValue
    }

    return 0
  }

  const handleRemoveCompletely = async (
    asset: SSMResponseProduct,
    thisNist: SSMNistPillar,
    thisAsset: SSMAssetType,
    assets: SSMResponseProduct[]
  ) => {
    setRemoving(true)

    const groupArr: any[] = []
    groupedData.forEach((group) => {
      const filteredItems = group.items.filter(
        (item) => (item as any).uuid !== asset.uuid && (item as any).uuid !== asset.uuid
      )

      groupArr.push({
        ...group,
        items: filteredItems
      })
    })
    setGroupedData(groupArr)
    dispatch(
      notification({
        open: true,
        variant: 'success',
        message: `${asset.display_name} was successfully removed from the Security Stack Map`
      })
    )
    const newAssets = assets.filter((n) => n.display_name !== asset.display_name)
    updateDrawerProps(thisNist as SSMNistPillar, thisAsset as SSMAssetType, newAssets)
    const oldRemoveProps = { ...confirmRemoveProps }
    setConfirmRemoveProps({ ...oldRemoveProps, open: false })
    setExpanded('')

    try {
      const res = await axios.delete('/api/v3/ssm', {
        data: {
          itemId: asset.uuid
        }
      })

      if (res.status.toString().includes('20')) {
        await fetchAndGroupData()
      }
    } catch (err) {
      console.error(err)
      await fetchAndGroupData()
      dispatch(
        notification({
          open: true,
          variant: 'error',
          message: `Failed removing product. Try again later`
        })
      )
    } finally {
      setRemoving(false)
    }
  }

  const handleCloseConfirmRemoveDialog = () => {
    setConfirmRemoveProps((pre: any) => ({ ...pre, open: false }))
  }

  const fetchAndGroupData = useCallback(async () => {
    const res = await axios.get<SSMResponseV2>('/api/v3/ssm')

    if (res.status === 200) {
      const { data, products } = res.data

      if (!data.length) {
        return
      }

      const groupedData = groupSSMData(data, products)
      const headerItems = getHeaderItems(groupedData)
      // const maxCellValue = Math.max(...products.map((n) => n.tilesTotal))
      const maxCellVal = getMaxCellValue(groupedData)
      const budgetBreakpoints = getBudgetBreakpoints(maxCellVal)
      // const thresholdGap = maxCellValue / 5
      // setBudgetBreakpoints([thresholdGap, thresholdGap * 2, thresholdGap * 3, thresholdGap * 4, thresholdGap * 5])
      setBudgetBreakpoints(budgetBreakpoints)
      setMaxCellValue(maxCellVal)
      setHeaderItems(headerItems)
      setGroupedData(groupedData)
      setAllProducts(products)

      return products
    }
  }, [])

  const fetchSSMData = useCallback(async () => {
    setLoading(true)

    try {
      await fetchAndGroupData()
    } catch (e) {
      // setNoData(true)
      console.error(e)
    } finally {
      setLoading(false)
    }
  }, [])

  useEffect(() => {
    fetchSSMData().catch((e) => e)
  }, [])

  useEffect(() => {
    if (allProducts.length > 0 && !loading) {
      setEmptyStateBanner(false)
    }
  }, [loading, allProducts, ssmEmptyBannerClosed])

  const getIntegrationLogo = (urlPath: string) => {
    const logoHost = process.env.REACT_APP_LOGO_HOST
    const url = `${logoHost}/${urlPath}`

    return urlPath ? url : 'default-vendor-logo.svg'
  }

  const handleChangeView = (event: MouseEvent<HTMLElement>, newView: string) => {
    if (loading) {
      event.preventDefault()
    } else {
      setView(newView ?? view)
    }
  }

  const handleOpenDrawer = (nist: SSMNistPillar, asset: SSMAssetType, assets: SSMResponseProduct[]) => {
    setDrawerOpen(true)
    setDrawerProps({ nist, asset, assets })
  }

  const handleCloseDrawer = () => {
    setDrawerOpen(false)
    setDrawerProps({ nist: null, asset: null, assets: [] })
  }

  const handleFullScreen = () => {
    if (document.fullscreenElement !== undefined && document.fullscreenElement === null) {
      document.body.requestFullscreen()
    } else {
      document.exitFullscreen()
    }
  }

  const updateDrawerProps = (nist: SSMNistPillar, asset: SSMAssetType, assets: SSMResponseProduct[]) => {
    setDrawerProps({ nist, asset, assets })
  }

  useLayoutEffect(() => {
    if (dimensions) {
      const itemHeight = (dimensions.height - gridHeader - padding - header) / 6
      const newItemHeight = emptyStateBanner ? itemHeight - 9 : itemHeight
      setItemHeight(newItemHeight)
    }
  }, [dimensions, emptyStateBanner, loading, allProducts])

  return (
    <>
      <Container ref={containerRef} className="ssm-container">
        <Header className="header">
          <Typography className="header-title">Security Stack Map</Typography>
          <Box>
            <ViewToggle
              color="primary"
              value={view}
              exclusive
              onChange={handleChangeView}
              aria-label="View"
              className="toggle-view-group"
            >
              <ToggleButton value="coverage">Coverage View</ToggleButton>
              <ToggleButton value="budget">Budget View</ToggleButton>
            </ViewToggle>
          </Box>
          <Box className="header-right-corner">
            <Typography className="total-products-text">
              Total:{' '}
              {view === 'coverage'
                ? `${allProducts.length} products`
                : `$${Intl.NumberFormat('en-US', {
                    notation: 'compact',
                    maximumFractionDigits: 2
                  }).format(allProducts.reduce((acc, obj) => acc + obj.cost, 0))}`}
            </Typography>
            <Divider orientation="vertical" variant="middle" flexItem />
            <IconButton onClick={handleFullScreen}>
              <FullscreenIcon />
            </IconButton>
          </Box>
        </Header>

        <ContentContainer ref={gridContainerRef} className="grid-container">
          <EmptySSMGridContainer opacity={emptyStateBanner ? 1 : 0}>
            <Box className="empty-message-wrapper">
              <Box className="empty-message-inner">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <path
                    d="M11 7H13V9H11V7ZM11 11H13V17H11V11ZM12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 20C7.59 20 4 16.41 4 12C4 7.59 7.59 4 12 4C16.41 4 20 7.59 20 12C20 16.41 16.41 20 12 20Z"
                    fill="white"
                  />
                </svg>
                <Typography className="empty-message-text">
                  Your Security Stack Map is empty. Hover over a cell to start adding products.
                </Typography>
              </Box>
              <IconButton
                onClick={() => {
                  setEmptyStateBanner(false)
                  localStorage.setItem(SSM_EMPTY_BANNER_CLOSED, 'true')
                }}
              >
                <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                  <g clipPath="url(#clip0_182_72997)">
                    <path
                      d="M14 -0.388572L12.3886 -2L6 4.38857L-0.388572 -2L-2 -0.388572L4.38857 6L-2 12.3886L-0.388572 14L6 7.61143L12.3886 14L14 12.3886L7.61143 6L14 -0.388572Z"
                      fill="white"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_182_72997">
                      <rect width="12" height="12" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </IconButton>
            </Box>
          </EmptySSMGridContainer>

          <SSMGrid className="grid">
            <BasicCell>
              <NistIcon />
            </BasicCell>
            {['header', ...nistPillars].map((nist, nistIndex: number) => {
              const assets: SSMGroupedDataItem[] =
                groupedData.length > 0
                  ? groupedData.filter((n) => n.nist === nist)
                  : placeholderAssets.filter((n) => n.nist === nist)

              let budgetRowCost = 0
              let rowsCounter = 0
              assets.forEach(({ items }: any) => {
                if (items.length > 0) {
                  rowsCounter += 1
                  const cellTotalCost = items.length > 0 ? items.reduce((acc: any, obj: any) => acc + obj.cost, 0) : 0
                  budgetRowCost += cellTotalCost
                }
              })
              const budgetRowCostFormatted = Intl.NumberFormat('en-US', {
                notation: 'compact',
                maximumFractionDigits: 2
              }).format(budgetRowCost)

              if (nist === 'header') {
                return headers.map((header, headerIdx) => {
                  const selector = header.toLowerCase()
                  const headerProps = headerItems[selector]
                  if (headerProps && typeof headerProps !== 'number') {
                    const { numOfItems, headerCost } = headerProps
                    const totalCost = Intl.NumberFormat('en-US', {
                      notation: 'compact',
                      maximumFractionDigits: 2
                    }).format(headerCost)

                    return (
                      <GridHeader key={headerIdx}>
                        <Typography className="name">{header}</Typography>
                        <Typography className="value">
                          {view === 'coverage' ? `${numOfItems}/6` : `$${totalCost}`}
                        </Typography>
                      </GridHeader>
                    )
                  }

                  return (
                    <GridHeader key={headerIdx}>
                      <Typography className="name">{header}</Typography>
                      <Typography className="value">0</Typography>
                    </GridHeader>
                  )
                })
              }
              return (
                <Fragment key={nistIndex}>
                  <NistPillar
                    nistPillarText={nist}
                    nistValueText={view === 'coverage' ? `${rowsCounter}/5` : `$${budgetRowCostFormatted}`}
                    background={getNistPillarBackground(nist as SSMNistPillar)}
                  />
                  <>
                    {view === 'coverage' ? (
                      <>
                        {assets.map(({ asset, items }: any, assetIndex) => {
                          return (
                            <SSMCard
                              key={`${asset}-${assetIndex}`}
                              asset={asset as SSMAssetType}
                              nist={nist as SSMNistPillar}
                              items={items as unknown as SSMResponseProduct[]}
                              getIntegrationLogo={getIntegrationLogo}
                              handleOpenDrawer={handleOpenDrawer}
                              handleCloseDrawer={handleCloseDrawer}
                              itemHeight={itemHeight}
                              loading={loading}
                            />
                          )
                        })}
                      </>
                    ) : (
                      <>
                        {assets.map(({ asset, items }, index) => {
                          if (groupedData.length > 0) {
                            const cellTotalCost = items.length > 0 ? items.reduce((acc, obj) => acc + obj.cost, 0) : 0

                            const itemIds = items.map((n: any) => n.uuid)
                            const productsInCell = allProducts.filter((n) => itemIds.includes(n.uuid))

                            const test = productsInCell.map((product) => {
                              const productId = product.uuid
                              const productCost = product.cost

                              const productInstances = groupedData.map((group) => {
                                return group.items.map((item) => {
                                  if ((item as any).uuid === productId) {
                                    return { ...item, nist: group.nist, asset: group.asset }
                                  }
                                  return null
                                })
                              })
                              const flattenedInstances = productInstances
                                .flat()
                                .filter((n) => !!n) as unknown as SSMResponseProductExtended[]

                              const flattenedInstancesSum = flattenedInstances.reduce((acc, obj) => acc + obj.cost, 0)
                              const sumMatching = flattenedInstancesSum === productCost

                              return flattenedInstances.map((n) => ({ ...n, sumMatching }))
                            })
                            const foundItem = test.flat().find((n) => n.asset === asset && n.nist === nist)

                            return (
                              <BudgetViewCard
                                nist={nist as SSMNistPillar}
                                asset={asset as SSMAssetType}
                                items={items as unknown as SSMResponseProduct[]}
                                handleOpenDrawer={handleOpenDrawer}
                                key={index}
                                budgetBreakpoints={budgetBreakpoints}
                                sumNotMatching={!foundItem ? false : !foundItem.sumMatching}
                                cellTotalCost={cellTotalCost}
                                itemHeight={itemHeight}
                              />
                            )
                          }

                          return null
                        })}
                      </>
                    )}
                  </>
                </Fragment>
              )
            })}
          </SSMGrid>
        </ContentContainer>
        <SSMSettingsDrawer
          drawerOpen={drawerOpen}
          setDrawerOpen={setDrawerOpen}
          drawerProps={drawerProps}
          groupedData={groupedData}
          fetchAndGroupData={fetchAndGroupData}
          updateDrawerProps={updateDrawerProps}
          setConfirmRemoveProps={setConfirmRemoveProps}
          setRemoving={setRemoving}
          setGroupedData={setGroupedData}
          expanded={expanded}
          setExpanded={setExpanded}
          allProducts={allProducts}
          removing={removing}
          maxCellValue={maxCellValue}
          setBudgetBreakpoints={setBudgetBreakpoints}
          setEmptyStateBanner={setEmptyStateBanner}
        />
      </Container>
      <ConfirmRemovalDialog
        onClose={handleCloseConfirmRemoveDialog}
        {...confirmRemoveProps}
        handleClick={handleRemoveCompletely}
        removing={removing}
      />
    </>
  )
}

export default SSM
