import humps from 'lodash-humps-ts'
import { Vendor } from './models/Vendor'
import { SecurityFinding } from './models/SecurityFinding'
import { UsCert, Vulnerability, TasksList, Integration, IntegrationInstance } from './models'
import axios from './lib/axios'

export const fetchVendors = async (
  limit?: number,
  offset?: number,
  query?: string,
  selected?: boolean
): Promise<{ vendors: Vendor[]; total: number }> => {
  const params: { limit?: number; offset?: number; name_contains?: string; selected?: boolean } = {
    limit,
    offset,
    name_contains: query
  }
  if (selected) {
    params.selected = true
  }
  const results = await axios.get<{ vendors: any[]; total: number; next_offset: number }>('/vendors', {
    params
  })
  const vendors = humps(results.data.vendors)

  return { vendors, total: results.data.total }
}

export const fetchSelectedProducts = async () => {
  const results = await axios.get('/products')

  return results.data.map((payload: any) => {
    return payload.product_uuid
  }) as string[]
}

export const setSelectedProducts = async (products: string[]) => {
  const results = await axios.post('/products', products)

  return results.data.map((payload: any) => {
    return payload.product_uuid
  }) as string[]
}

export const fetchSecurityFindings = async (
  limit: number,
  offset: number,
  filters: Partial<Pick<SecurityFinding, 'status' | 'securityControlSeverity' | 'resourceName'>>
): Promise<{ securityFindings: SecurityFinding[]; total: number }> => {
  const results = await axios.get<{ security_control_findings: any[]; total: number; next_offset: number }>(
    '/security_control_findings',
    {
      params: {
        limit: limit,
        offset: offset,
        name_contains: filters
      }
    }
  )

  return {
    securityFindings: humps(results.data.security_control_findings) as SecurityFinding[],
    total: results.data.total
  }
}

export const fetchSecurityFindingsSummary = async (): Promise<{
  crowdstrikeScore: any
  securityControlTotals: any
}> => {
  const results = await axios.get('/security_control_findings/summary')

  return {
    crowdstrikeScore: humps(results.data.crowdstrike_score),
    securityControlTotals: humps(results.data.security_control_totals)
  }
}

/* DEPRECATED */
export const fetchVulnerabilities = async (): Promise<{ vulnerabilities: Vulnerability[] }> => {
  try {
    const results = await axios.get('/widgets/known-vulnerabilities')

    return {
      vulnerabilities: humps(results.data)
    }
  } catch (err) {
    console.error(err)
  }

  return { vulnerabilities: [] }
}

/* DEPRECATED */
export const fetchUsCert = async (): Promise<{ usCerts: UsCert[] }> => {
  const results = await axios.get('/widgets/cve')

  return {
    usCerts: humps(results.data)
  }
}

/* DEPRECATED */
export const fetchRSSArticles = async () => {
  const results = await axios.get('/rss/search').then((response) => humps(response.data))

  return humps(results)
}

export const apiFetchIntegrations = async (): Promise<Integration[]> => {
  const results = await axios.get('/integrations')

  return humps(results.data.integrations) as Integration[]
}

export const apiFetchConfiguredIntegrations = async (): Promise<IntegrationInstance[]> => {
  const results = await axios.get('/api/v3/integrations/configured')

  return results.data.instances as IntegrationInstance[]
}

export const apiFetchTasks = async (limit = 10, page = 1): Promise<TasksList> => {
  return await axios.get(`/tasks?page=${page}&limit=${limit}`).then((response) => humps(response.data))
}

/* Fetch all Jira Projects */
export const fetchAllJiraProjects = async () => {
  const res = await axios.get('/api/v3/integrations/jira/projects')

  if (res.status === 200) {
    return res.data.projects
  }
}
