import { DialogContent, DialogActions, Box } from '@mui/material'
import styled from '@emotion/styled/macro'

export const Wrapper = styled(Box)``

export const Header = styled(Box)`
  padding: 8px 16px 16px;
  border-bottom: 1px solid #342f32;

  .title {
    font-family: 'Quicksand', sans-serif;
    font-weight: 400;
    font-size: 36px;
    line-height: 44px;
    color: #e9e0e4;
  }
`

export const Content = styled(DialogContent)`
  padding: 24px 58px 48px;

  .MuiFormControl-root {
    margin-bottom: 24px;

    label {
      color: #d0c3cc;

      .MuiInputLabel-asterisk {
        color: #fe455d;
      }
    }

    .MuiOutlinedInput-root {
      border-radius: 4px;
      color: #d0c3cc;
      font-family: 'Quicksand', sans-serif;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.5px;

      fieldset {
        border: 1px solid #998d96 !important;
      }
    }
    
    .MuiFormHelperText-root{
      margin-left: 0;
    }
  }

  .roles-wrapper {
    .roles-title {
      font-family: 'Quicksand', sans-serif;
      font-weight: 400;
      font-size: 22px;
      line-height: 28px;
      color: #e9e0e4;

      span {
        color: #fe455d;
      }
    }

    .MuiFormGroup-root {
      .MuiFormControlLabel-root {
        display: flex;
        align-items: flex-start;
        margin-left: 8px;

        &:not(:last-of-type) {
          margin-bottom: 16px;
        }

        &:first-of-type {
          margin-top: 8px;
        }

        .MuiRadio-root {
          padding: 2px 0 0;

          svg {
            width: 20px;
            height: 20px;

            path {
              fill: #d0c3cc;
            }
          }
        }

        .roles-name {
          font-family: 'Quicksand', sans-serif;
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          letter-spacing: 0.5px;
          color: #e9e0e4;
          margin-left: 18px;
        }

        .roles-description {
          font-family: 'Quicksand', sans-serif;
          font-weight: 400;
          font-size: 12px;
          line-height: 16px;
          letter-spacing: 0.4px;
          color: #d0c3cc;
          margin-left: 18px;
        }
      }
    }
  }
`

export const Actions = styled(DialogActions)`
  background: linear-gradient(0deg, rgba(255, 169, 252, 0.05), rgba(255, 169, 252, 0.05)), #1e1a1d;
  box-shadow: 0 2px 6px 2px rgba(0, 0, 0, 0.15), 0 1px 2px rgba(0, 0, 0, 0.3);
  border-radius: 0 0 16px 16px;
  padding: 16px 36px;

  .MuiButtonBase-root {
    &:last-of-type {
      margin-left: 24px;
    }
  }
`
