import React, { FC } from 'react'
import { Container, Notification } from './styles/TotalPercentageNotify.styles'
import { Box, Typography } from '@mui/material'
import { InfoIconDangerFilled, InfoIconFilled } from '../../../components/svg'

interface Props {
  isValid: boolean
  show: boolean
  total: number
  hidePanel?: boolean
  resetCategory?: () => void
}

const TotalPercentageNotify: FC<Props> = ({ isValid, show, total, resetCategory, hidePanel }) => {
  return (
    <Container>
      <Notification isValid={isValid} hidePanel={hidePanel}>
        {isValid ? <InfoIconFilled /> : <InfoIconDangerFilled />}
        <Typography className="info">Total must equal 100%.</Typography>
        {!isValid && show ? (
          <Typography className="info underline" onClick={() => (resetCategory ? resetCategory() : null)}>
            Reset to previous category values.
          </Typography>
        ) : (
          ''
        )}
      </Notification>

      <Box className="total-info">
        <Typography className="name">Total:</Typography>
        <Typography className={`${!isValid ? 'not-valid' : ''} value`}>{total}%</Typography>
      </Box>
    </Container>
  )
}

export default TotalPercentageNotify
