import { BenchmarkScoreCategory, CategoriesCpisWeightI } from '../../../../../models'

export const benchmarkingSelectOptions = ['All Industries', 'Financial Services', 'Information Security']

export const defaultBenchmarkCategorySortOnyxia = [
  'Detection & Response',
  'Vulnerability Management',
  'Training and Awareness',
  'Cloud Security',
  'Mail & Web Security',
  'Identity & Access Management',
  'Supply Chain & App Security',
  'Business Continuity',
  'Network Security',
  'Device Management',
  'Compliance'
]

export const defaultBenchmarkCategorySortNist = ['Identify', 'Protect', 'Detect', 'Respond', 'Recover', 'Govern']

export const categorySortFuncOnyxia = (a: BenchmarkScoreCategory, b: BenchmarkScoreCategory) => {
  return a.category_id
    ? defaultBenchmarkCategorySortOnyxia.indexOf(a.category_name) -
        defaultBenchmarkCategorySortOnyxia.indexOf(b.category_name)
    : 0
}

export const categorySortFuncOnyxiaWeights = (a: CategoriesCpisWeightI, b: CategoriesCpisWeightI) => {
  return a.id
    ? defaultBenchmarkCategorySortOnyxia.indexOf(a.name) - defaultBenchmarkCategorySortOnyxia.indexOf(b.name)
    : 0
}

export const categorySortFuncNistWeights = (a: CategoriesCpisWeightI, b: CategoriesCpisWeightI) => {
  return a.id ? defaultBenchmarkCategorySortNist.indexOf(a.name) - defaultBenchmarkCategorySortNist.indexOf(b.name) : 0
}

export const categorySortFuncNist = (a: BenchmarkScoreCategory, b: BenchmarkScoreCategory) => {
  return a.category_id
    ? defaultBenchmarkCategorySortNist.indexOf(a.category_name) -
        defaultBenchmarkCategorySortNist.indexOf(b.category_name)
    : 0
}
