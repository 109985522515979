import { Box, DialogActions, DialogContent } from '@mui/material'
import styled from '@emotion/styled/macro'

export const Container = styled(Box)``

export const Header = styled(Box)`
  padding: 8px 16px 16px;
  border-bottom: 1px solid #342f32;

  .title {
    font-family: 'Quicksand', sans-serif;
    font-weight: 400;
    font-size: 36px;
    line-height: 44px;
    color: #e9e0e4;
  }
`

export const Content = styled(DialogContent)`
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.3), 0 8px 12px 6px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  background: linear-gradient(0deg, rgba(255, 169, 252, 0.11) 0%, rgba(255, 169, 252, 0.11) 100%), #1e1a1d;
  padding: 24px 24px 16px 24px;
  display: flex;
  flex-direction: column;

  .title {
    font-family: 'Quicksand', sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px;
    color: #e9e0e4;
    margin-bottom: 12px;
  }

  .subtitle {
    color: #e9e0e4;
    font-family: 'Quicksand', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.5px;
    margin-bottom: 24px;
  }

  .framework {
    border-radius: 16px;
    background: linear-gradient(0deg, rgba(255, 169, 252, 0.11) 0%, rgba(255, 169, 252, 0.11) 100%);
    padding-left: 12px;
    padding-right: 16px;
    padding-top: 12px;
    padding-bottom: 12px;
    display: flex;
    align-items: center;
    gap: 8px;
    margin-bottom: 38px;

    .text {
      color: #e9e0e4;
      font-family: 'Quicksand', sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0.5px;
    }
  }

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 16px;
    padding-left: 16px;
    margin-bottom: 8px;

    .name {
      color: #d0c3cc;
      font-family: 'Quicksand', sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0.25px;
    }

    .value {
      width: 175px;
      color: #d0c3cc;
      font-family: 'Quicksand', sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0.25px;
    }
  }

  .content-category {
    margin-bottom: 38px;

    .content-item {
      border-radius: 16px;
      background: linear-gradient(0deg, rgba(255, 169, 252, 0.11) 0%, rgba(255, 169, 252, 0.11) 100%);
      padding: 8px 16px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 4px;

      .name {
        color: #e9e0e4;
        font-family: 'Quicksand', sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: 0.1px;
      }

      .value {
        width: 175px;
        color: #e9e0e4;
        font-family: 'Quicksand', sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0.5px;
      }
    }
  }

  .content-cpi {
    .content-item {
      border-radius: 16px;
      background: linear-gradient(0deg, rgba(255, 169, 252, 0.11) 0%, rgba(255, 169, 252, 0.11) 100%);
      padding: 8px 16px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 4px;

      .cpi-block {
        display: flex;
        align-items: baseline;
        gap: 16px;

        .cpi {
          border-radius: 18px;
          background: #debdd9;
          font-family: 'Quicksand', sans-serif;
          font-size: 12px;
          font-style: normal;
          font-weight: 500;
          line-height: 16px;
          letter-spacing: 0.5px;
          color: #3f293e;
          padding: 2px 8px;
        }

        .name {
          color: #e9e0e4;
          font-family: 'Quicksand', sans-serif;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 24px;
          letter-spacing: 0.1px;
          max-width: 310px;
        }
      }

      .value {
        width: 175px;
        color: #e9e0e4;
        font-family: 'Quicksand', sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0.5px;
      }
    }
  }
`

export const Actions = styled(DialogActions)`
  margin-top: 24px;
`
