import { Box, DialogActions, DialogContent } from '@mui/material'
import styled from '@emotion/styled/macro'

export const Wrapper = styled(Box)``

export const TransferOwnershipWrapper = styled(Box)`
  .content {
    padding: 8px 16px;
    margin-bottom: 40px;

    .title {
      font-size: 36px;
      font-family: Quicksand, sans-serif;
      line-height: 44px;
      color: #e9e0e4;
    }

    .text {
      color: #e9e0e4;
      font-size: 14px;
      font-family: Quicksand, sans-serif;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: 0.1px;
      margin-top: 8px;
    }
  }
`

export const Header = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 28px;

  .title {
    font-family: 'Quicksand', sans-serif;
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
    color: #e9e0e4;
  }
`

export const Content = styled(DialogContent)`
  padding: 0;

  .copy-btn {
    &:hover {
      background: rgba(208, 188, 255, 0.12) !important;
    }

    &:focus {
      background: rgba(208, 188, 255, 0.12) !important;
    }
  }

  .form-group {
    margin-bottom: 16px;
    position: relative;
    width: 92%;
    padding-top: 4px;

    .first svg {
      width: 24px;
      height: 24px;
    }

    .sso-url-info {
      position: absolute;
      right: -40px;
      top: 8px;
    }

    .MuiFormControl-root {
      width: 100%;
      border: 1px solid #998d96;
      border-radius: 4px;
    }

    .MuiOutlinedInput-root.Mui-focused {
      border: 2px solid #ffa9fc;
      outline: none;
    }

    .MuiOutlinedInput-notchedOutline {
      border: 0 !important;
    }

    .not-valid {
      .MuiOutlinedInput-root {
        border: 2px solid #ffb4ab;
      }

      .MuiInputLabel-root {
        color: #ffb4ab;
      }
    }

    label {
      background: #1e1a1d;
      padding-left: 6px;
      padding-right: 6px;
      color: #e9e0e4;
    }

    label.Mui-focused {
      color: #e9e0e4;
      background: #1e1a1d;
    }

    input,
    textarea {
      color: #e9e0e4 !important;
      font-family: 'Quicksand', sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0.5px;
    }

    .Mui-disabled {
      color: #e9e0e4 !important;
      -webkit-text-fill-color: #e9e0e4 !important;
    }
  }

  .user-roles {
    display: flex;
    align-items: center;

    .name {
      font-family: 'Quicksand', sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: 0.1px;
    }
  }

  .roles-dropdown {
    max-width: 150px;
  }

  .claim-panel {
    margin-top: 22px;

    .name {
      font-family: 'Quicksand', sans-serif;
      margin-bottom: 16px;
      color: white;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: 0.1px;
    }

    .claim-content {
      padding: 10px;
      border-radius: 4px;
      border: 1px solid #998d96;
      box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

      .form-group {
        width: 100%;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
`

export const Actions = styled(DialogActions)`
  margin-top: 36px;
`
