import { FC, MouseEvent, useState } from 'react'
import { Formik, Form } from 'formik'
import { DialogActions, DialogContent, DialogTitle } from '@mui/material'

/* Utils */
import axios from '../../../../../lib/axios'
import { notification } from '../../../../../store/notifications/notificationSlice'
import { useAppDispatch } from '../../../../../store/hooks'
import { fetchIntegrations } from '../../../../../store/integrations/actions'
import { closeModal, ModalVariant } from '../../../../../store/modals/modalSlice'
import { defaultValues, FormValues, schema } from './auth0Integration.constants'

/* Components */
import Auth0IntegrationContent from './Auth0IntegrationContent'
import Auth0CrowdstrikeActions from '../shared/Auth0CrowdstrikeActions'

interface Props {
  variant: ModalVariant
}

const Auth0Integration: FC<Props> = ({ variant }) => {
  const dispatch = useAppDispatch()
  const [activeStep, setActiveStep] = useState(0)
  const [showPassword, setShowPassword] = useState(false)
  const [success, setSuccess] = useState<boolean | null>(null)
  const [errorMessage, setErrorMessage] = useState('')

  const handleCancel = () => {
    dispatch(closeModal())
    dispatch(
      notification({
        open: true,
        variant: 'warning',
        message: 'Changes were not saved.'
      })
    )
  }

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1)
  }

  const handleBack = () => {
    setSuccess(null)
    setErrorMessage('')
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
  }

  const onSubmit = async (values: FormValues) => {
    if (activeStep === 0) {
      const payload = {
        credentials: {
          domain: values.domain,
          client_id: values.clientId,
          client_secret: values.clientSecret,
          audience: values.audience
        }
      }
      try {
        const res = await axios.post('/integrations/auth0', payload)

        if (res.status === 201) {
          setSuccess(true)
        } else {
          setSuccess(false)
        }
      } catch (err) {
        const message = (err as any).response.data.message
        if (message.includes('already enabled')) {
          setErrorMessage(message)
        }
        setSuccess(false)
      } finally {
        handleNext()
      }
    }
    if (activeStep === 1) {
      dispatch(closeModal())
      if (success) {
        dispatch(
          notification({
            open: true,
            variant: 'success',
            message: 'Your Auth-0 integration changes successfully updated.'
          })
        )
        dispatch(fetchIntegrations())
      }
    }
  }

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword as boolean)
  }

  const handleMouseDownPassword = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
  }

  return (
    <Formik initialValues={defaultValues} validationSchema={schema} onSubmit={onSubmit}>
      {({ isSubmitting, isValid }) => (
        <Form autoComplete="off">
          <DialogTitle id="dialog-title">Placeholder</DialogTitle>
          <DialogContent dividers>
            <Auth0IntegrationContent
              showPassword={showPassword}
              handleClickShowPassword={handleClickShowPassword}
              handleMouseDownPassword={handleMouseDownPassword}
              activeStep={activeStep}
              success={success}
              errorMessage={errorMessage}
              variant={variant}
            />
          </DialogContent>
          <DialogActions sx={{ padding: '16px 40px' }}>
            <Auth0CrowdstrikeActions
              handleCancel={handleCancel}
              isSubmitting={isSubmitting}
              isValid={isValid}
              handleBack={handleBack}
              activeStep={activeStep}
              success={success}
            />
          </DialogActions>
        </Form>
      )}
    </Formik>
  )
}

export default Auth0Integration
