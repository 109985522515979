import { FC, Fragment, useState, useEffect } from 'react'
import { Box, Link as MuiLink, Typography, ClickAwayListener } from '@mui/material'
import { Link } from 'react-router-dom'

/* Utils */
import {
  IntegrationConfigSyncStatus,
  IntegrationStatusMessage,
  PerformanceScoreCategoryDetailsDataSources
} from '../../../../../../models'

/* Components */
import { DataSourceTooltip } from './DataSourceTooltip'
import { DataSourceErrorTooltip, linkSx } from './DataSourceErrorTooltip'
import { ErrorIcon } from '../../../../../components/svg'
import { dataSourceErrorBackground, dataSourceErrorIcon } from './dataSourceErrorIcons'
import { fetchSyncStatus } from '../../../../../../lib/utils'

interface Props {
  dataSources: PerformanceScoreCategoryDetailsDataSources[]
}

export interface DataSourceError {
  id: string
  status: IntegrationStatusMessage
  errorMessage?: string
}

const DataSources: FC<Props> = ({ dataSources }) => {
  const sources = dataSources.length >= 3 ? dataSources.slice(0, 3) : dataSources
  const [open, setOpen] = useState(false)
  const [focused, setFocused] = useState(false)
  const [dataSourceErrors, setDataSourceErrors] = useState<DataSourceError[]>([])

  const getStatus = async (dataSource: PerformanceScoreCategoryDetailsDataSources) => {
    const integrationConfig = dataSource.integrationConfig
    const id = integrationConfig?.id || ''
    const res: IntegrationConfigSyncStatus | null = await fetchSyncStatus(id)
    const current: DataSourceError[] = [...dataSourceErrors]

    if (res && res.status && res.status === IntegrationStatusMessage.failed) {
      const payload: DataSourceError = {
        id,
        status: res.status,
        errorMessage: res.errorMessage
      }
      setDataSourceErrors([...current, payload])
    }
  }

  useEffect(() => {
    if (dataSources.length > 0) {
      dataSources.forEach((dataSource) => {
        getStatus(dataSource)
      })
    }
  }, [dataSources])

  const handleTooltipClose = () => {
    setOpen(false)
    setFocused(false)
  }

  const handleIconClick = () => {
    setOpen(true)
    setFocused(true)
  }

  return (
    <Box className="data-sources-wrapper">
      <Typography className="data-sources-text">Data Sources:</Typography>
      {sources.map(({ display_name: displayName, logo_path: logoPath, ...props }, index) => {
        const integrationConfig = props.integrationConfig
        const id = integrationConfig?.id || ''
        const error = dataSourceErrors.find((n) => n.id === id)
        let status = IntegrationStatusMessage.success
        if (error) {
          status = IntegrationStatusMessage.failed
        }

        return (
          <Fragment key={index}>
            {status === IntegrationStatusMessage.success ? (
              <DataSourceTooltip
                key={index}
                title={
                  <>
                    <Box className="tooltip-wrapper">
                      <img src={`/icons/circle/${logoPath}`} alt="" width={18} height={18} />
                      <Typography className="tooltip-title">{displayName}</Typography>
                    </Box>
                  </>
                }
              >
                <img src={`/icons/circle/${logoPath}`} alt="" width={18} height={18} />
              </DataSourceTooltip>
            ) : (
              <ClickAwayListener onClickAway={handleTooltipClose}>
                <div>
                  <DataSourceErrorTooltip
                    key={index}
                    placement="bottom-start"
                    componentsProps={{
                      popper: {
                        sx: {
                          borderRadius: '8px !important',
                          background: '#342F32 !important',
                          marginTop: '14px !important'
                        }
                      }
                    }}
                    PopperProps={{
                      disablePortal: true
                    }}
                    onClose={handleTooltipClose}
                    open={open || focused}
                    disableFocusListener
                    disableHoverListener
                    disableTouchListener
                    title={
                      <>
                        <Box className="tooltip-wrapper">
                          <Box className="instance-name-container">
                            <img src={`/icons/circle/${logoPath}`} alt="" width={24} height={24} />
                            <Typography className="instance-name">{displayName}</Typography>
                          </Box>
                          <Box className="error-description-container">
                            <Box className="error-icon">
                              <ErrorIcon />
                            </Box>
                            <Typography className="error-text">Data Synchronization Failure</Typography>
                          </Box>
                          <MuiLink
                            component={Link}
                            color="inherit"
                            to={`/settings/integrations`}
                            sx={linkSx}
                            state={{ instanceId: id }}
                          >
                            Try Instance Configuration
                          </MuiLink>
                        </Box>
                      </>
                    }
                  >
                    <Box
                      className="error-wrapper"
                      onClick={handleIconClick}
                      onMouseEnter={() => {
                        setOpen(true)
                      }}
                      onMouseLeave={() => {
                        setOpen(focused)
                      }}
                    >
                      <img src={`/icons/circle/${logoPath}`} alt="" width={18} height={18} />
                      {dataSourceErrorBackground}
                      {dataSourceErrorIcon}
                    </Box>
                  </DataSourceErrorTooltip>
                </div>
              </ClickAwayListener>
            )}
          </Fragment>
        )
      })}
      {dataSources.length > 3 && (
        <DataSourceTooltip
          title={
            <>
              <Box className="tooltip-list">
                {dataSources.map((n, index) => (
                  <Box className="tooltip-wrapper" key={index}>
                    <img src={`/icons/circle/${n.logo_path}`} alt="" width={18} height={18} />
                    <Typography className="tooltip-title">{n.display_name}</Typography>
                  </Box>
                ))}
              </Box>
            </>
          }
        >
          <Typography className="data-sources-count-text">+ {dataSources.length - 3}</Typography>
        </DataSourceTooltip>
      )}
    </Box>
  )
}

export default DataSources
