/* eslint camelcase: 0 */
import { FormControl as MuiFormControl, Select as MuiSelect } from '@mui/material'
import styled from '@emotion/styled/macro'

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 0

export const FormControl = styled(MuiFormControl)<{ isselected?: string; is_opened?: string; is_disabled: string }>`
  border: 1px solid #998d96;
  background: transparent;
  outline: none;
  width: 100%;
  height: 56px;
  border-radius: 4px;

  .MuiOutlinedInput-root {
    background: transparent;
    border: 0;
    color: #E9E0E4;
    text-align: center;
    font-family: Quicksand, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.1px;
    text-transform: capitalize;
    width: 100%;
  }

  &:hover {
    border-radius: 4px;
    background: rgba(234, 221, 255, 0.08);

    .MuiOutlinedInput-root {
      color: #ffa9fc;
    }
  }

  svg {
    top: calc(50% - 0.6em);
    right: 16px;
  }

  ${({ is_disabled }) =>
    is_disabled &&
    is_disabled === 'true' &&
    `
      opacity: 0.38;
      pointer-events: none;
      cursor: default;
      
      &:hover {
        border-radius: 4px;
        background: transparent;
    
        .MuiOutlinedInput-root {
          color: #ffa9fc;
        }
      }
      
       .MuiOutlinedInput-root {
        color: #ffa9fc;
      }
  `};

  ${({ is_opened }) =>
    is_opened &&
    is_opened === 'true' &&
    `
      border: 1px solid #FFA9FC;
  `};
`

export const Select = styled(MuiSelect)<{ isselected?: string }>`
  width: 100%;
  height: 56px;
  background: transparent;
  border-radius: 4px;
  border: 1px solid #998d96;

  color: #fff;
  text-align: center;
  font-family: Quicksand, sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.1px;
  text-transform: capitalize;

  ${({ isselected }) =>
    isselected === 'true' &&
    `
    
      background: linear-gradient(0deg, rgba(255, 169, 252, 0.08) 0%, rgba(255, 169, 252, 0.08) 100%), #1E1A1D;
  `};

  > * {
    border: none !important;
    outline: none !important;
  }

  .MuiOutlinedInput-input {
    text-align: left;
  }

  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border: none !important;
  }
`

export const CategoryMenuProps = {
  PaperProps: {
    sx: {
      background: '#573F56',
      boxShadow: '0 4px 4px 0 rgba(0, 0, 0, 0.30), 0 8px 12px 6px rgba(0, 0, 0, 0.15)',
      borderRadius: '8px',
      width: '456px',
      marginTop: '3px',

      '.MuiMenuItem-root': {
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        minWidth: '167px',
        height: '32px',
        paddingLeft: '16px',
        paddingRight: '16px',

        img: {
          marginRight: '8px'
        },

        '&:hover': {
          background: 'rgba(208, 188, 255, 0.08)'
        },

        '.MuiCheckbox-root': {
          marginRight: '8px',

          '&.Mui-checked': {
            svg: {
              path: {
                fill: '#FFA9FC'
              }
            }
          },

          '&:hover': {
            borderRadius: '100px',
            background: 'rgba(202, 196, 208, 0.12)'
          },

          svg: {
            width: '18px',
            height: '18px',

            path: {
              fill: '#D0C3CC'
            }
          }
        },

        '.MuiListItemText-root .MuiTypography-root': {
          fontFamily: '"Quicksand", sans-serif',
          fontWeight: 400,
          fontSize: '14px',
          lineHeight: '24px',
          letterSpacing: '0.5px',
          color: '#E9E0E4',
          fontStyle: 'normal',
          textTransform: 'capitalize'
        }
      },

      '&::-webkit-scrollbar': {
        width: '4px',
        background: 'transparent'
      },

      '&::-webkit-scrollbar-track': {
        boxShadow: 'none',
        background: 'transparent'
      },

      '&::-webkit-scrollbar-thumb': {
        backgroundColor: '#FBD8F5',
        borderRadius: '8px'
      }
    },
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP
    }
  },
  anchorOrigin: {
    vertical: 'bottom' as const,
    horizontal: 'left' as const
  },
  transformOrigin: {
    vertical: 'top' as const,
    horizontal: 'left' as const
  },
  variant: 'menu' as const
}
