import { FC, useEffect, useState } from 'react'
import { Box, SelectChangeEvent, Typography } from '@mui/material'

/* Utils */
import { Benchmark, BenchmarkScore, BenchmarkScoreCategory, CategoryFramework, IFramework } from '../../../../../models'
import { benchmarkingSelectOptions } from './benchmarking.constants'

/* Components */
import BenchmarkingSelectField from './components/BenchmarkingSelectField'
import BenchmarkCard from './components/BenchmarkCard'
import { BenchmarkingIcon } from '../../../../components/svg'
import { BenchmarkingContainer, EmptyBenchmarkingContainer } from './benchmarking.styles'
import { InfoChipsWrapper } from '../../dashboard.styles'

const emptyData: BenchmarkScoreCategory[] = [
  {
    category_id: '',
    category_name: 'Detection & Response',
    framework_id: CategoryFramework.Onyxia,
    framework_name: 'onyxia',
    function_id: '',
    function_name: '',
    summary: {
      less: null,
      equal: null,
      more: null
    },
    data: [
      {
        cpi_display_name: 'Placeholder',
        cpi_name: 'Placeholder',
        cpi_title: 'Placeholder'
      }
    ]
  },
  {
    category_id: '',
    category_name: 'Vulnerability Management',
    summary: {
      less: null,
      equal: null,
      more: null
    },
    framework_id: CategoryFramework.Onyxia,
    framework_name: 'onyxia',
    function_id: '',
    function_name: '',
    data: [
      {
        cpi_display_name: 'Placeholder',
        cpi_name: 'Placeholder',
        cpi_title: 'Placeholder'
      }
    ]
  },
  {
    category_id: '',
    category_name: 'Training & Awareness',
    summary: {
      less: null,
      equal: null,
      more: null
    },
    framework_id: CategoryFramework.Onyxia,
    framework_name: 'onyxia',
    function_id: '',
    function_name: '',
    data: [
      {
        cpi_display_name: 'Placeholder',
        cpi_name: 'Placeholder',
        cpi_title: 'Placeholder'
      }
    ]
  },
  {
    category_id: '',
    category_name: 'Cloud Security',
    summary: {
      less: null,
      equal: null,
      more: null
    },
    framework_id: CategoryFramework.Onyxia,
    framework_name: 'onyxia',
    function_id: '',
    function_name: '',
    data: [
      {
        cpi_display_name: 'Placeholder',
        cpi_name: 'Placeholder',
        cpi_title: 'Placeholder'
      }
    ]
  }
]

interface Props {
  width: number
  benchmark: Benchmark | null
  cpiTitles: {
    displayName: string
    name: string
    title: string
    category: string
  }[]
  isEmpty: boolean
  framework?: CategoryFramework
  customFramework: IFramework | null
}

const Benchmarking: FC<Props> = ({ width, benchmark, framework, customFramework, cpiTitles, isEmpty }) => {
  const [selectedBenchmark, setSelectedBenchmark] = useState<string>(benchmarkingSelectOptions[0])
  const [score, setScore] = useState<BenchmarkScore[]>([])
  const [loaded, setLoaded] = useState(false)
  const [currentBenchmark, setCurrentBenchmark] = useState<BenchmarkScore | null>(null)

  useEffect(() => {
    if (benchmark && benchmark.score.length > 0) {
      setScore(benchmark.score)
      const findItem = benchmark.score.find((item) => item.industry_name === 'All Industries')
      const item = findItem || benchmark.score[0]
      setCurrentBenchmark(item)
      setLoaded(true)
    }
  }, [benchmark])

  const handleCompareToChange = (e: SelectChangeEvent<typeof selectedBenchmark>) => {
    const { value } = e.target
    setSelectedBenchmark(value)
    const newBenchmark = score.find((n) => n.industry_name === value)
    if (newBenchmark) {
      setCurrentBenchmark(newBenchmark)
    }
  }

  const checkHasBenchmark = (category: BenchmarkScoreCategory) => {
    if (!category.summary) return false

    return !!category.summary
  }

  if (!loaded || isEmpty) {
    return (
      <EmptyBenchmarkingContainer width={width}>
        <Box className="wrapper">
          <Box className="header">
            <Box className="header-title">
              <BenchmarkingIcon />
              <Typography className="title">Benchmarking</Typography>
            </Box>
            <Box className="select-wrapper">
              <BenchmarkingSelectField
                options={['All Industries']}
                value="All Industries"
                handleChange={handleCompareToChange}
              />
            </Box>
          </Box>
          <Box className="card-container">
            {emptyData.map((n, i) => (
              <BenchmarkCard
                framework={framework}
                customFramework={customFramework}
                category={n}
                key={n.category_id || i}
                i={i}
                cpiTitles={emptyData.map((n) => ({
                  displayName: n.category_name,
                  name: 'Placeholder',
                  title: 'Placeholder',
                  category: 'Placeholder'
                }))}
                hasBenchmark={false}
              />
            ))}
          </Box>
        </Box>
      </EmptyBenchmarkingContainer>
    )
  }
  return (
    <BenchmarkingContainer width={width} isempty={isEmpty ? 'true' : 'false'}>
      <Box className="wrapper" id="benchmarking">
        <Box className="header">
          <Box className="header-title">
            <BenchmarkingIcon />
            <Typography className="title">Benchmarking</Typography>
          </Box>

          <Box className="select-wrapper">
            <BenchmarkingSelectField
              options={score.map((scoreItem) => scoreItem.industry_name).sort()}
              value={selectedBenchmark}
              handleChange={handleCompareToChange}
            />
          </Box>
        </Box>

        <InfoChipsWrapper forbenchmark="true">
          <Box className="chip">
            <Box className="chip-circle less" /> <Typography>Less Strict SLA</Typography>
          </Box>
          <Box className="chip">
            <Box className="chip-circle same" /> <Typography>Same SLA</Typography>
          </Box>
          <Box className="chip">
            <Box className="chip-circle more" /> <Typography>More Strict SLA</Typography>
          </Box>
        </InfoChipsWrapper>

        <Box className="card-container">
          {currentBenchmark &&
            currentBenchmark.categories.map((category, i) => (
              <BenchmarkCard
                framework={framework}
                customFramework={customFramework}
                category={category}
                key={category.category_id || i}
                i={i}
                cpiTitles={cpiTitles}
                hasBenchmark={checkHasBenchmark(category)}
              />
            ))}
        </Box>
      </Box>
    </BenchmarkingContainer>
  )
}

export default Benchmarking
