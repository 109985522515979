import { Box } from '@mui/material'
import styled from '@emotion/styled/macro'
import { getCpiCategoryColors } from '../../../../../lib/utils'
import { NistFunction } from '../../../../../models'

export const CategoryWeightItemContainer = styled(Box)<{ selected?: boolean; invalidcpisweight: string }>`
  width: 100%;
  height: 72px;
  min-height: 72px;
  padding: 12px 24px;
  border-radius: 16px;
  background: #372a36;
  gap: 16px;
  display: flex;
  align-items: center;
  justify-content: stretch;

  ${({ invalidcpisweight }) =>
    invalidcpisweight === 'true' &&
    `
      border: 1px solid #FF8A80;
  `}

  &:hover {
    cursor: pointer;
    background: #483646;
  }

  ${({ selected }) =>
    selected &&
    `
      background: #573F56 !important;
    `};

  .MuiOutlinedInput-root {
    border-radius: 4px;
    border: 1px solid #998d96;
    letter-spacing: 0.5px;
    height: 48px;
    color: #fff;
    text-align: center;
    font-family: Quicksand, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    width: 100%;
    outline: 0;

    fieldset {
      border: 0;
    }

    &:hover {
      border-color: #e9e0e4;
    }

    &:last-of-type {
      width: 81px;
      min-width: 81px;
    }

    &.Mui-focused {
      border-color: #ffa9fc;
    }

    input {
      padding-left: 22px;
    }
  }

  .length3 input {
    padding-left: 16px;
  }

  .length1 input {
    padding-left: 32px;
  }

  .percentage {
    .MuiTypography-root {
      color: #fff;
      text-align: center;
      font-family: Quicksand, sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }

  .MuiFormControl-root {
    width: 100%;

    &:last-of-type {
      width: 81px;
      min-width: 81px;
    }
  }

  .Mui-disabled {
    color: #d0c3cc;
    -webkit-text-fill-color: #d0c3cc;
    cursor: not-allowed;
  }

  .Mui-focused {
    border: 1px solid #ffa9fc;

    input {
      width: 100%;
    }
  }

  .close-button {
    color: #ffa9fc;

    &:hover {
      background: #362e35;
    }

    &.is-disabled {
      color: #7f747c;

      &:hover {
        cursor: default;
        background: transparent;
      }
    }
  }

  .category-text {
    color: #fff;
    font-family: Quicksand, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    flex-grow: 1;
  }

  .no-role-weights-wrapper {
    padding: 0 24px;
    width: 81px;
    min-width: 81px;

    .category-text {
      color: #fff;
      font-family: Quicksand, sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-align: center;
      flex-grow: 1;
    }
  }
`

export const CategoryCircle = styled(Box)<{ bgcolor: string }>`
  width: 24px !important;
  height: 24px !important;
  min-width: 24px !important;
  min-height: 24px !important;
  border-radius: 50%;
  background: ${({ bgcolor }) => `${bgcolor}`};
`

export const OnyxiaCategoryCircle = styled(Box)<{ categoryid: string }>`
  width: 24px !important;
  height: 24px !important;
  min-width: 24px !important;
  min-height: 24px !important;
  border-radius: 50%;

  ${({ categoryid }) => {
    const colors = getCpiCategoryColors(categoryid)

    return `
      background: ${colors.background};
      `
  }};
`

export const NistCategoryCircle = styled(Box)<{ fn: NistFunction | '' }>`
  width: 24px !important;
  height: 24px !important;
  min-width: 24px !important;
  min-height: 24px !important;
  border-radius: 50%;

  ${({ fn }) =>
    fn === NistFunction.Identify &&
    `
      background: #8E9AFF;
    `}

  ${({ fn }) =>
    fn === NistFunction.Protect &&
    `
      background: #C77EDA;
    `}
  
  ${({ fn }) =>
    fn === NistFunction.Detect &&
    `
      background: #FFDE6A;
    `}
  
  ${({ fn }) =>
    fn === NistFunction.Respond &&
    `
      background: #FF7F6B;
    `}
  
  ${({ fn }) =>
    fn === NistFunction.Recover &&
    `
      background: #99FDC0;
    `}
  
  ${({ fn }) =>
    fn === NistFunction.Govern &&
    `
      background: #FF9E47;
    `}
`
