import { FC, ReactNode, MouseEvent, SyntheticEvent, useState } from 'react'
import { Form, Formik, FormikValues } from 'formik'
import { DialogContent, DialogActions, Typography } from '@mui/material'

/* Utils */
import axios from '../../../../../lib/axios'
import { notification } from '../../../../../store/notifications/notificationSlice'
import { useAppDispatch } from '../../../../../store/hooks'
import { fetchConfiguredIntegrations } from '../../../../../store/integrations/actions'
import { closeModal } from '../../../../../store/modals/modalSlice'
import { defaultValues, FormValues, schema } from './crowdstrikeIntegration.constants'

/* Components */
import { Tabs, Tab } from './crowdstrikeIntegration.styles'
import { StepperActiveIcon, StepperInactiveIcon, StepperCompletedIcon } from '../../../svg'
import CrowdstrikeActions from './CrowdstrikeActions'
import CrowdstrikeIntegrationStep1 from './CrowdstrikeIntegrationStep1'
import CrowdstrikeIntegrationStep2 from './CrowdstrikeIntegrationStep2'

interface TabPanelProps {
  children?: ReactNode
  index: number
  value: number
}

const CrowdstrikeIntegration: FC = () => {
  const dispatch = useAppDispatch()
  const [activeStep, setActiveStep] = useState(0)
  const [showPassword, setShowPassword] = useState(false)
  const [toastMessage, setToastMessage] = useState('')
  const [tabValue, setTabValue] = useState(0)
  const [setupSuccessful, setSetupSuccessful] = useState(false)
  const [showToast, setShowToast] = useState(false)

  const handleChangeTabValue = (event: SyntheticEvent, newValue: number) => {
    setTabValue(newValue)
  }

  const handleCancel = () => {
    dispatch(closeModal())
    dispatch(
      notification({
        open: true,
        variant: 'warning',
        message: 'Changes were not saved.'
      })
    )
  }

  const handleSaveAndExit = (values: FormikValues) => {
    localStorage.setItem('CROWDSTRIKE_FORM_VALUES', JSON.stringify(values))
    dispatch(closeModal())
    dispatch(
      notification({
        open: true,
        variant: 'warning',
        message: 'Changes were saved.'
      })
    )
  }

  const handleNext = () => {
    setActiveStep(1)
    setTabValue(1)
  }

  const handleFinish = () => {
    localStorage.removeItem('CROWDSTRIKE_FORM_VALUES')
    dispatch(closeModal())
  }

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
    setTabValue((prevTabValue) => prevTabValue - 1)
  }

  const onSubmit = async (values: FormValues) => {
    const payload = {
      base_url: values.baseUrl,
      client_id: values.clientId,
      client_secret: values.secret
    }

    try {
      const res = await axios.post('/api/v3/integrations/crowdstrike/config', {
        config: payload
      })

      if (res.data.errors) {
        setToastMessage(typeof res.data.errors === 'string' ? res.data.errors : '')
        setSetupSuccessful(false)
        return
      }

      if (res.status === 201) {
        setSetupSuccessful(true)
        setToastMessage('You can click “Complete Setup” to exit this wizard.')
        localStorage.removeItem('CROWDSTRIKE_FORM_VALUES')
        dispatch(fetchConfiguredIntegrations())
      } else {
        setSetupSuccessful(false)
      }
    } catch (err) {
      const message = (err as any).response.data.message
      if (message.includes('already enabled')) {
        setToastMessage(message)
      }
      setSetupSuccessful(false)
    } finally {
      setShowToast(true)
    }
  }

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword as boolean)
  }

  const handleMouseDownPassword = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
  }

  return (
    <Formik
      initialValues={
        typeof window !== 'undefined' && !!localStorage.getItem('CROWDSTRIKE_FORM_VALUES')
          ? JSON.parse(localStorage.getItem('CROWDSTRIKE_FORM_VALUES') || '')
          : defaultValues
      }
      validationSchema={schema}
      onSubmit={onSubmit}
      validateOnMount
    >
      {({ isSubmitting, isValid, values }) => (
        <Form autoComplete="off">
          <Tabs value={tabValue} onChange={handleChangeTabValue} aria-label="crowdstrike tabs">
            <Tab {...a11yProps(0)} active={tabValue === 0}>
              {activeStep === 1 ? <StepperCompletedIcon /> : <StepperActiveIcon />}
              <Typography>Crowdstrike API</Typography>
              <Typography>Generate Client API & Keys</Typography>
            </Tab>
            <Tab {...a11yProps(1)} active={tabValue === 1}>
              {activeStep === 0 ? <StepperInactiveIcon /> : <StepperActiveIcon />}
              <Typography>Test</Typography>
              <Typography>Confirm Credentials</Typography>
            </Tab>
          </Tabs>
          <DialogContent sx={{ p: 0 }}>
            <TabPanel value={tabValue} index={0}>
              <CrowdstrikeIntegrationStep1
                showPassword={showPassword}
                handleClickShowPassword={handleClickShowPassword}
                handleMouseDownPassword={handleMouseDownPassword}
                activeStep={activeStep}
              />
            </TabPanel>
            <TabPanel value={tabValue} index={1}>
              <CrowdstrikeIntegrationStep2
                setupSuccessful={setupSuccessful}
                toastMessage={toastMessage}
                showToast={showToast}
              />
            </TabPanel>
          </DialogContent>
          <DialogActions sx={{ padding: '24px' }}>
            <CrowdstrikeActions
              setupSuccessful={setupSuccessful}
              isSubmitting={isSubmitting}
              isValid={isValid}
              handleCancel={handleCancel}
              handleBack={handleBack}
              handleNext={handleNext}
              handleSaveAndExit={() => handleSaveAndExit(values)}
              handleFinish={handleFinish}
              activeStep={activeStep}
            />
          </DialogActions>
        </Form>
      )}
    </Formik>
  )
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`crowdstrike-tabpanel-${index}`}
      aria-labelledby={`crowdstrike-tab-${index}`}
      {...other}
      style={{
        padding: '40px 72px 60px'
      }}
    >
      {value === index && <>{children}</>}
    </div>
  )
}

function a11yProps(index: number) {
  return {
    id: `crowdstrike-tab-${index}`,
    'aria-controls': `crowdstrike-tabpanel-${index}`
  }
}

export default CrowdstrikeIntegration
