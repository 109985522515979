const PaginationNextIcon = () => (
  <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6.11499 20.73L7.88499 22.5L17.885 12.5L7.88499 2.5L6.11499 4.27L14.345 12.5L6.11499 20.73Z"
      fill="#D0C3CC"
    />
  </svg>
)

export default PaginationNextIcon
