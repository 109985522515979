import React, { useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks'
import { closeModal, modalPropsSelector, ModalType, openModal } from '../../../../../store/modals/modalSlice'
import { Actions, Content, Header, Wrapper } from './UpdateSSoModal.styles'
import { Box, CircularProgress, IconButton, InputAdornment, TextField, Typography } from '@mui/material'
import { CloseChipIcon, CopyLineIcon, InfoTooltipIcon, PlusIcon } from '../../../svg'
import { SSOTooltip } from '../../../../pages/settings/account-management/components/SSOTooltip'
import TrendsChartSelectField from '../../../../pages/library/library-details/sections/trends-tab/components/TrendsChartSelectField'
import { ThemeButton } from '../../../buttons'
import { Form, Formik } from 'formik'
import axios from '../../../../../lib/axios'
import { closeToast, openToast, ToastVariant } from '../../../../../store/toasts/toastSlice'
import InfoFilledTooltipIcon from '../../../svg/InfoFilledTooltipIcon'

const defaultValue = {
  domain: '',
  sso_url: '',
  default_user_role_id: 'Admin',
  public_certificate: '',
  record_name: '',
  record_value: '',
  verified: false,
  enabled: false
}

const UpdateSSoModal = () => {
  const dispatch = useAppDispatch()
  const modalProps = useAppSelector(modalPropsSelector)
  const connection = modalProps?.props?.connection
  const [form, setForm] = useState(connection || defaultValue)
  const [show, setShow] = useState(connection?.verified || connection?.record_name)

  const samlSpIdentityId = process.env.REACT_APP_FRONTEGG_SAML_SP_ENTITY_ID || ''

  const handleClose = () => {
    dispatch(closeModal())
  }

  const getAcsUrl = () => {
    const ACS_URL_PATH = '/auth/saml/callback'
    const fronteggDomain = process.env.REACT_APP_FRONTEGG_BASE_URL
    return `${fronteggDomain}${ACS_URL_PATH}`
  }

  const handleChange = (event: any) => {
    const value = event.target.value
    const findItem = modalProps?.props?.roles?.find((item) => item.name === value)

    if (findItem && event.target.name === 'role') {
      setForm((prevState: any) => ({
        ...prevState,
        default_user_role_id: findItem.name
      }))
    } else
      setForm((prevState: any) => ({
        ...prevState,
        [event.target.name]: value
      }))
  }

  const handleUpdate = async () => {
    if (!modalProps?.props?.roles) return

    try {
      if (!form.record_name && modalProps?.props?.roles) {
        const data = {
          domain: form.domain,
          sso_url: form.sso_url,
          public_certificate: form.public_certificate,
          default_user_role_id: modalProps?.props?.roles.find((item) => item.name === form.default_user_role_id)?.id
        }

        const resp = await axios.post('/api/v3/sso', data)
        if (resp.status === 200) {
          dispatch(
            openModal({
              type: ModalType.ssoVerificationFailed,
              props: {
                isVerified: false,
                connection: form,
                roles: modalProps?.props?.roles,
                callback: modalProps?.props?.callback
              }
            })
          )
          return setShow(false)
        }
        const tempData = { ...resp.data.data }
        tempData.default_user_role_id = modalProps?.props?.roles.find(
          (item) => item.name === form.default_user_role_id
        )?.id
        setForm(tempData)
        return setShow(true)
      }

      const tempData = { ...form }
      tempData.default_user_role_id = modalProps?.props?.roles.find(
        (item) => item.name === form.default_user_role_id || item.id === form.default_user_role_id
      )?.id

      const data = {
        enabled: true,
        domain: tempData.domain,
        sso_url: tempData.sso_url,
        public_certificate: tempData.public_certificate,
        default_user_role_id: tempData.default_user_role_id
      }
      await axios.put('/api/v3/sso', data)
      setTimeout(() => {
        dispatch(
          openToast({
            variant: ToastVariant.success,
            props: {
              text: 'SSO Connection enabled',
              description: `SSO Connection (Domain Name : ${form.domain}) was successfully validated and applied.`
            }
          })
        )
      }, 500)

      if (modalProps?.props?.callback) {
        modalProps.props.callback()
        handleClose()
      }

      setTimeout(() => {
        dispatch(closeToast())
      }, 5000)
    } catch (e: any) {
      if ([400, 500].includes(e?.response.status)) {
        dispatch(
          openModal({
            type: ModalType.ssoVerificationFailed,
            props: {
              isVerified: false,
              connection: form,
              roles: modalProps?.props?.roles,
              callback: modalProps?.props?.callback
            }
          })
        )
      }
    }
  }

  const copy = (value: string) => {
    navigator.clipboard.writeText(value)
  }

  return (
    <Wrapper>
      <Header>
        <Typography className="title">
          {form.record_name ? 'Edit SAML SSO Configuration' : 'SAML SSO Configuration'}
        </Typography>
        <IconButton onClick={handleClose}>
          <CloseChipIcon />
        </IconButton>
      </Header>

      <Formik initialValues={connection || form} onSubmit={handleUpdate}>
        {({ isSubmitting }) => (
          <Form autoComplete="off">
            <Content>
              <Box className="form-group">
                <TextField
                  disabled={form.record_name}
                  value={form.domain}
                  onInput={handleChange}
                  label="Domain Name"
                  name="domain"
                  variant="outlined"
                  className={show && !form.record_name ? 'not-valid' : ''}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        {show && !form.record_name ? (
                          <IconButton edge="end" className="first">
                            <InfoFilledTooltipIcon />
                          </IconButton>
                        ) : (
                          ''
                        )}
                      </InputAdornment>
                    )
                  }}
                />
              </Box>

              <Box className="form-group">
                <TextField
                  value={form.sso_url}
                  onInput={handleChange}
                  label="SSO URL"
                  name="sso_url"
                  variant="outlined"
                />

                <SSOTooltip title="Identify Provider Single Sign-On URL." arrow placement="bottom-end">
                  <IconButton className="sso-url-info">
                    <InfoTooltipIcon />
                  </IconButton>
                </SSOTooltip>
              </Box>

              <Box className="form-group">
                <TextField
                  value={form.public_certificate}
                  onInput={handleChange}
                  label="Public Certificate"
                  variant="outlined"
                  name="public_certificate"
                  multiline
                  rows={2}
                  maxRows={4}
                />
              </Box>

              <Box className="form-group">
                <TextField
                  disabled
                  value={getAcsUrl()}
                  label="ACS URL"
                  name="ACSUrl"
                  variant="outlined"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          className="copy-btn"
                          aria-label="toggle Client Secret visibility"
                          onClick={() => copy(getAcsUrl())}
                          edge="end"
                        >
                          <CopyLineIcon />
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                />
              </Box>

              <Box className="form-group">
                <TextField
                  disabled
                  value={samlSpIdentityId}
                  label="SP Entity ID"
                  variant="outlined"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          className="copy-btn"
                          aria-label="toggle Client Secret visibility"
                          onClick={() => copy(samlSpIdentityId)}
                          edge="end"
                        >
                          <CopyLineIcon />
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                />
              </Box>

              <Box className="user-roles">
                <Typography className="name">User Roles</Typography>
                <SSOTooltip title="The default role will be assign to all SSO users." arrow placement="bottom-end">
                  <IconButton>
                    <InfoTooltipIcon />
                  </IconButton>
                </SSOTooltip>
              </Box>

              <Box className="roles-dropdown">
                <TrendsChartSelectField
                  name="role"
                  className="select-box"
                  options={modalProps?.props?.roles ? modalProps?.props?.roles.map((item) => item.name) : []}
                  placeholder="Filter by Time Period"
                  labeltext="Filter by: "
                  value={
                    modalProps?.props?.roles?.find(
                      (item) => item.id === form.default_user_role_id || item.name === form.default_user_role_id
                    )?.name
                  }
                  handleChange={handleChange}
                  disabled={false}
                  minWidth="75px !important"
                />
              </Box>

              {show && form.record_name ? (
                <Box className={'claim-panel'}>
                  <Typography className="name">Domain Claim</Typography>

                  <Box className="claim-content">
                    <Box className="form-group">
                      <TextField
                        disabled
                        value={form.record_name}
                        onInput={handleChange}
                        label="Record Name"
                        name="recordName"
                        variant="outlined"
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                className="copy-btn"
                                aria-label="toggle Client Secret visibility"
                                onClick={() => copy(form.record_name)}
                                edge="end"
                              >
                                <CopyLineIcon />
                              </IconButton>
                            </InputAdornment>
                          )
                        }}
                      />
                    </Box>
                    <Box className="form-group">
                      <TextField
                        disabled
                        value={form.record_value}
                        onInput={handleChange}
                        label="Record Value"
                        name="recordValue"
                        variant="outlined"
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                className="copy-btn"
                                aria-label="toggle Client Secret visibility"
                                onClick={() => copy(form.record_value)}
                                edge="end"
                              >
                                <CopyLineIcon />
                              </IconButton>
                            </InputAdornment>
                          )
                        }}
                      />
                    </Box>
                  </Box>
                </Box>
              ) : (
                ''
              )}

              <Actions>
                {!show ? (
                  <ThemeButton
                    themevariant="primary"
                    type="button"
                    disabled={!(form.domain && form.sso_url && form.public_certificate)}
                    endIcon={isSubmitting && <CircularProgress size="20px" />}
                    onClick={() => handleUpdate()}
                  >
                    <>
                      <PlusIcon />
                      Create
                    </>
                  </ThemeButton>
                ) : (
                  <ThemeButton
                    themevariant="primary"
                    type="submit"
                    disabled={!(form.domain && form.sso_url && form.public_certificate)}
                    endIcon={isSubmitting && <CircularProgress size="20px" />}
                  >
                    <>
                      <PlusIcon />
                      Validate & Apply
                    </>
                  </ThemeButton>
                )}
              </Actions>
            </Content>
          </Form>
        )}
      </Formik>
    </Wrapper>
  )
}

export default UpdateSSoModal
