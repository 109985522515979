import styled from '@emotion/styled/macro'
import { Box } from '@mui/material'

export const Wrapper = styled(Box)`
  border-top: 1px solid rgba(222, 189, 217, 0.25);
  padding-top: 16px;
  padding-right: 24px;
  padding-left: 24px;

  .line {
    background: rgba(222, 189, 217, 0.5);
    opacity: 0.5;
    margin-bottom: 24px;
    margin-top: 24px;
    width: 100%;
    display: block;
    height: 2px;
  }

  .line-actions {
    flex-direction: column !important;
    gap: 12px;

    > div {
      width: 100%;
    }
  }

  .sub-title {
    font-family: 'Quicksand', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.1px;
    margin-bottom: 16px;
  }

  .help {
    font-family: 'Quicksand', sans-serif;
    font-size: 11px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0.5px;
    color: #998d96;
    margin-bottom: 8px;
    display: flex;
    justify-content: flex-end;
    margin-right: 8px;
  }

  .save-button {
    width: 100%;
    color: #ffa9fc !important;
  }

  .disabled-btn {
    color: #e9e0e4 !important;
  }

  .edit-button {
    padding-left: 6px;
    padding-right: 6px;
    white-space: nowrap;
  }

  .text-danger {
    color: #ff8a80;
  }
`

export const StyledInputWrapper = styled(Box)`
  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-radius: 16px;
  }

  .MuiFormControl-root {
    width: 100%;

    fieldset {
      border: 0;
    }
  }

  .MuiOutlinedInput-input {
    //height: 29px;
    padding: 10px 16px !important;
    color: white;
    font-weight: 400;
    font-family: 'Quicksand', sans-serif;
    font-size: 14px;
    font-style: normal;
    line-height: 20px;
    letter-spacing: 0.25px;
    border-radius: 16px;
    border: 1px solid #998d96;
    background: linear-gradient(0deg, rgba(255, 169, 252, 0.08) 0%, rgba(255, 169, 252, 0.08) 100%);
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.3), 0px 1px 3px 1px rgba(0, 0, 0, 0.15);
    width: 100%;

    &::placeholder {
      opacity: 1;
      color: white;
      font-weight: 400;
      font-family: 'Quicksand', sans-serif;
      font-size: 14px;
      font-style: normal;
      line-height: 20px;
      letter-spacing: 0.25px;
    }

    .MuiAutocomplete-input {
      padding: 7.5px 0;
    }
  }

  .MuiTextField-root .MuiOutlinedInput-root {
    padding: 0 !important;
  }
`
