import { Typography } from '@mui/material'
import { circularProgressClasses, CircularProgressProps } from '@mui/material/CircularProgress'

/* Components */
import { CircleInner, CircleOuter, Container, ValueWrapper } from './OverPerformWidget.styles'

interface Props {
  value: number
}

const PerformanceWidget = ({ value, ...props }: Props & CircularProgressProps) => {
  return (
    <Container>
      <CircleInner
        variant="determinate"
        sx={{
          [`& .${circularProgressClasses.circle}`]: {
            strokeLinecap: 'round'
          }
        }}
        size={197}
        thickness={5}
        {...props}
        value={+value - 100}
      />

      <CircleOuter
        variant="determinate"
        sx={{
          [`& .${circularProgressClasses.circle}`]: {
            strokeLinecap: 'round'
          }
        }}
        size={197}
        thickness={5}
        {...props}
        value={value > 100 ? 100 : value}
      />

      <ValueWrapper>
        <Typography variant="caption" component="div" className="value">
          {value}%
        </Typography>
        <Typography variant="caption" component="div" className="caption">
          Overall Score
        </Typography>
      </ValueWrapper>
    </Container>
  )
}

export default PerformanceWidget
