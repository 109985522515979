import { Dispatch, FC, SetStateAction } from 'react'
import { Box, IconButton, Typography } from '@mui/material'
import dayjs from 'dayjs'

/* Utils */
import { AnalysisDataTable, IntegrationInstance } from '../../../../../models'

/* Components */
import { Drawer } from './settings.styles'
import RowDataItem from './RowDataItem'
import CloseChipIcon from '../../../../components/svg/CloseChipIcon'

interface Props {
  drawerOpen: boolean
  activeRowItem: AnalysisDataTable | null
  setDrawerOpen: Dispatch<SetStateAction<boolean>>
  firstInstance: IntegrationInstance | undefined
  secondInstance: IntegrationInstance | undefined
}

const RowDataDrawer: FC<Props> = ({ drawerOpen, activeRowItem, setDrawerOpen, firstInstance, secondInstance }) => {
  if (!activeRowItem) return <></>
  return (
    <Drawer anchor="right" open={drawerOpen} onClose={() => setDrawerOpen(false)}>
      <Box role="presentation">
        <Box className="wrapper-inner">
          <Box className="header">
            <Typography className="title">Asset Details</Typography>
            <IconButton className="close-icon" onClick={() => setDrawerOpen(false)}>
              <CloseChipIcon color="#D9BFD4" />
            </IconButton>
          </Box>

          <Box className="content">
            <Box className="row-item">
              <Typography className="row-name">Coverage</Typography>
              <Box className="row-data">
                <RowDataItem instance={firstInstance} text={firstInstance?.name || ''} />
                <RowDataItem instance={secondInstance} text={secondInstance?.name || ''} />
              </Box>
            </Box>

            <Box className="row-item">
              <Typography className="row-name">Host name</Typography>
              <Box className="row-data">
                <RowDataItem instance={firstInstance} text={activeRowItem.hostName} />
                <RowDataItem instance={secondInstance} text={activeRowItem.secondHostName || ''} />
              </Box>
            </Box>

            <Box className="row-item">
              <Typography className="row-name">Original serial number</Typography>
              <Box className="row-data">
                <RowDataItem instance={firstInstance} text={activeRowItem.originalSerialNumber} />
                <RowDataItem instance={secondInstance} text={activeRowItem.secondSerialNumber || ''} />
              </Box>
            </Box>

            <Box className="row-item">
              <Typography className="row-name">Mac address</Typography>
              <Box className="row-data">
                <RowDataItem instance={firstInstance} text={activeRowItem.macAddress} />
                <RowDataItem instance={secondInstance} text={activeRowItem.secondMacAddress || ''} />
              </Box>
            </Box>

            <Box className="row-item">
              <Typography className="row-name">Last seen</Typography>
              <Box className="row-data">
                <RowDataItem
                  instance={firstInstance}
                  text={dayjs(activeRowItem.lastSeen).format('YYYY-MM-DD HH:MM:ss')}
                />
                <RowDataItem
                  instance={secondInstance}
                  text={dayjs(activeRowItem.secondLastSeen).format('YYYY-MM-DD HH:MM:ss')}
                />
              </Box>
            </Box>

            <Box className="row-item">
              <Typography className="row-name">Operating system</Typography>
              <Box className="row-data">
                <RowDataItem instance={firstInstance} text={activeRowItem.operatingSystem} />
                <RowDataItem instance={secondInstance} text={activeRowItem.secondOperatingSystem || ''} />
              </Box>
            </Box>

            <Box className="row-item">
              <Typography className="row-name">Matched by</Typography>
              <Box className="row-data">
                <RowDataItem instance={firstInstance} text={activeRowItem.matchedBy} />
                <RowDataItem instance={secondInstance} text={activeRowItem.matchedBy} />
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Drawer>
  )
}

export default RowDataDrawer
