import { FC } from 'react'
import { Box, Fade, IconButton, Snackbar, Typography } from '@mui/material'
import { Warning } from '@mui/icons-material'
import InfoIcon from '@mui/icons-material/Info'
import styled from '@emotion/styled/macro'
import { useAppDispatch } from '../../../store/hooks'
import { closeNotification } from '../../../store/notifications/notificationSlice'
import { ToastVariant } from '../../../store/toasts/toastSlice'

const Wrapper = styled(Box)<{ variant: 'success' | 'error' | 'warning' | 'neutral' }>`
  width: 645px;
  //height: 72px;
  min-height: 72px;
  position: fixed;
  right: 40px;
  bottom: -30px;
  background: #6dd58c;
  box-shadow: 0 2px 6px 2px rgba(0, 0, 0, 0.15), 0 1px 2px rgba(0, 0, 0, 0.3);
  background: ${({ variant }) =>
    variant === ToastVariant.success
      ? '#AED581'
      : variant === ToastVariant.warning
      ? '#7FCFFF'
      : variant === ToastVariant.neutral
      ? '#4D444B'
      : '#FDAEA3'};
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  padding: 13px 16px;
  z-index: 10001;
  opacity: 1;
  transition: opacity 0.5s ease-in-out;
  animation: slidein 0.5s forwards;

  @keyframes slidein {
    from {
      bottom: -30px;
      opacity: 0;
    }
    to {
      bottom: 40px;
      opacity: 1;
    }
  }

  .message {
    color: #37003b;
    font-family: Quicksand, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.1px;
  }

  .left-side {
    display: flex;
    align-items: center;
    gap: 16px;

    .left-side-content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 4px;

      .title {
        color: #37003b;
        font-family: Quicksand, sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: 0.1px;
      }

      .message {
        color: #37003b;
        font-family: Quicksand, sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0.25px;
      }
    }
  }
`

const vertical = 'top'
const horizontal = 'center'

interface Props {
  variant: 'success' | 'error' | 'warning'
  message: string
  open: boolean
  autoHideDelay?: number
  title?: string
  sx?: Record<string, unknown>
}

const Notification: FC<Props> = ({ variant, message, title, sx, open, autoHideDelay }) => {
  const dispatch = useAppDispatch()
  const handleClose = () => dispatch(closeNotification())

  return (
    <Snackbar
      // anchorOrigin={{ vertical, horizontal }}
      open={open}
      onClose={handleClose}
      message={message}
      autoHideDuration={autoHideDelay}
      TransitionComponent={Fade}
      key={vertical + horizontal}
      sx={{
        bottom: '0 !important',
        width: '100%',
        zIndex: 100000
      }}
    >
      <Wrapper variant={variant} sx={sx}>
        <Box className="left-side">
          {variant === 'success' && (
            <>
              <Box sx={{ width: '24px !important', height: '24px !important' }}>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <path
                    d="M9.00016 16.1698L4.83016 11.9998L3.41016 13.4098L9.00016 18.9998L21.0002 6.99984L19.5902 5.58984L9.00016 16.1698Z"
                    fill="#37003B"
                  />
                </svg>
              </Box>

              <Box className="left-side-content">
                {title && (
                  <Typography variant="body1" className="title">
                    {title ?? 'Success: '}
                  </Typography>
                )}
                <Typography variant="body1" className="message">
                  {message ?? 'The test worked as expected.'}
                </Typography>
              </Box>
            </>
          )}

          {variant === 'error' && (
            <>
              <Box sx={{ width: '24px !important', height: '24px !important' }}>
                <Warning sx={{ color: '#E85541', fontSize: '16px', mx: '4px' }} />
              </Box>

              <Box className="left-side-content">
                {title && (
                  <Typography variant="body1" className="title">
                    {title ?? 'Test Failed: '}
                  </Typography>
                )}
                <Typography variant="body1" className="message">
                  {message ?? 'Something went wrong, please make sure inputs are accurate.'}
                </Typography>
              </Box>
            </>
          )}

          {variant === 'warning' && (
            <>
              <Box sx={{ width: '24px !important', height: '24px !important' }}>
                <InfoIcon
                  sx={{
                    color: '#23053A',
                    fontSize: '16px',
                    mx: '4px',
                    width: '24px !important',
                    height: '24px !important'
                  }}
                />
              </Box>

              <Box className="left-side-content">
                {title && (
                  <Typography variant="body1" className="title">
                    {title ?? 'Update Discarded:'}
                  </Typography>
                )}
                <Typography variant="body1" className="message">
                  {message ?? 'Changes were not saved.'}
                </Typography>
              </Box>
            </>
          )}
        </Box>

        <IconButton className="close-button" onClick={handleClose}>
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41Z"
              fill="#37003B"
            />
          </svg>
        </IconButton>
      </Wrapper>
    </Snackbar>
  )
}

export default Notification
