import { FC } from 'react'

interface Props {
  isPined?: boolean | null
}

const PinSvg: FC<Props> = ({ isPined }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="17" height="18" viewBox="0 0 17 18" fill="none">
    <path
      d="M9.8332 3.4V6.9C9.8332 7.684 10.0922 8.412 10.5332 9H6.3332C6.7882 8.398 7.0332 7.67 7.0332 6.9V3.4H9.8332ZM11.9332 2H4.9332C4.5482 2 4.2332 2.315 4.2332 2.7C4.2332 3.085 4.5482 3.4 4.9332 3.4H5.6332V6.9C5.6332 8.062 4.6952 9 3.5332 9V10.4H7.7122V15.3L8.4122 16L9.1122 15.3V10.4H13.3332V9C12.1712 9 11.2332 8.062 11.2332 6.9V3.4H11.9332C12.3182 3.4 12.6332 3.085 12.6332 2.7C12.6332 2.315 12.3182 2 11.9332 2Z"
      fill={isPined === true ? '#66006E' : isPined === false ? 'white' : '#E9E0E461'}
    />
  </svg>
)

export default PinSvg
