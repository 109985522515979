import { FC, SVGProps } from 'react'

const IntegrationsCheckIcon: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M6.5968 11.9067L3.4693 8.77918L2.4043 9.83668L6.5968 14.0292L15.5968 5.02918L14.5393 3.97168L6.5968 11.9067Z"
      fill="#7EBCA2"
    />
  </svg>
)

export default IntegrationsCheckIcon
