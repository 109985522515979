import React, { FC, useState } from 'react'
import { Box, ClickAwayListener, Link as MuiLink, Typography } from '@mui/material'
import { Link } from 'react-router-dom'

/* Utils */
import { linkSx } from '../../pages/dashboard/sections/category-activity/components/DataSourceErrorTooltip'

/* Components */
import { ErrorTooltip } from './ErrorTooltip'
import { ErrorIcon } from '../svg'
import {
  dataSourceErrorBackground,
  dataSourceErrorIcon
} from '../../pages/dashboard/sections/category-activity/components/dataSourceErrorIcons'
import { Container } from './dataSourceErrorTooltip.styles'

interface Props {
  displayName: string
  errorMessage: string
  logoPath: string
  instanceName: string
  id: string
  logoSize?: number
  chip?: boolean
  cpiName?: string
  useSquareLogo?: boolean
  hideBaseIcon?: boolean
  hideLink?: boolean
  integrationName?: string
  isTrendsError?: boolean
  shouldDisablePortal?: boolean
}

const DataSourceErrorTooltip: FC<Props> = ({
  // displayName,
  // errorMessage,
  logoPath,
  logoSize = 18,
  instanceName,
  id,
  chip,
  cpiName,
  useSquareLogo,
  hideBaseIcon,
  hideLink = false,
  integrationName,
  isTrendsError,
  shouldDisablePortal = true
}) => {
  const [open, setOpen] = useState(false)
  const [focused, setFocused] = useState(false)

  const handleTooltipClose = () => {
    setOpen(false)
    setFocused(false)
  }

  const handleIconClick = () => {
    setOpen(true)
    setFocused(true)
  }

  const getLogo = () => {
    if (useSquareLogo) {
      return `/icons/rounded/${logoPath}`
    }

    return `/icons/circle/${logoPath}`
  }

  const getIntegrationLogo = (name: string) => {
    if (name) return `/icons/circle/${name}_icon.svg`

    return '/crowdstrike_falcon_icon.svg'
  }

  return (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <Container logoSize={logoSize} isSquare={useSquareLogo} className="data-source-error-tooltip-container">
        <ErrorTooltip
          placement="bottom-start"
          componentsProps={{
            popper: {
              sx: {
                borderRadius: '8px !important',
                background: '#342F32 !important',
                marginTop: '14px !important',
                zIndex: 100000000
              }
            }
          }}
          PopperProps={{
            disablePortal: shouldDisablePortal
          }}
          onClose={handleTooltipClose}
          open={open || focused}
          isChip={!!chip}
          isSquare={useSquareLogo}
          disableFocusListener
          disableHoverListener
          disableTouchListener
          title={
            <>
              <Box className="tooltip-wrapper">
                <Box className="instance-name-container">
                  <img
                    src={integrationName ? getIntegrationLogo(integrationName) : logoPath}
                    alt=""
                    width="24px !important"
                    height="24px !important"
                    style={{ minWidth: 'unset !important' }}
                  />
                  <Typography className="instance-name">{instanceName}</Typography>
                </Box>
                <Box className="error-description-container">
                  <Box className="error-icon">
                    <ErrorIcon />
                  </Box>
                  <Typography className="error-text">Data Synchronization Failure</Typography>
                </Box>
                {!hideLink && (
                  <MuiLink
                    component={Link}
                    color="inherit"
                    to={'/settings/integrations'}
                    sx={linkSx}
                    state={{ instanceId: id }}
                  >
                    Adjust Configuration
                  </MuiLink>
                )}
              </Box>
            </>
          }
        >
          {chip ? (
            <Box
              className="cpi-chip"
              onClick={handleIconClick}
              onMouseEnter={() => {
                setOpen(true)
              }}
              onMouseLeave={() => {
                setOpen(focused)
              }}
            >
              {cpiName || ''}
              {dataSourceErrorBackground}
              {dataSourceErrorIcon}
            </Box>
          ) : (
            <Box
              className={`error-wrapper ${hideBaseIcon ? 'no-base-icon' : ''}${
                isTrendsError ? 'trends-chart-error' : ''
              }`}
              onClick={handleIconClick}
              onMouseEnter={() => {
                setOpen(true)
              }}
              onMouseLeave={() => {
                setOpen(focused)
              }}
            >
              {!hideBaseIcon && <img src={getLogo()} alt="" width={logoSize} height={logoSize} />}
              {dataSourceErrorBackground}
              {dataSourceErrorIcon}
            </Box>
          )}
        </ErrorTooltip>
      </Container>
    </ClickAwayListener>
  )
}

export default DataSourceErrorTooltip
