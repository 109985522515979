import styled from '@emotion/styled/macro'
import { Box } from '@mui/material'

export const Wrapper = styled(Box)`
  width: 539px;
  margin: 0 auto;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;

    .title {
      color: #e9e0e4;
      font-family: 'Quicksand', sans-serif;
      font-size: 22px;
      font-style: normal;
      font-weight: 400;
      line-height: 28px;
    }
  }
`

export const Content = styled(Box)`
  border-radius: 8px;
  border: 1px solid #998d96;
  background: linear-gradient(0deg, #292128 0%, #292128 100%);
  padding: 24px 24px 20px 24px;
  margin-bottom: 20px;

  &:last-child {
    margin-bottom: 0;
  }

  .MuiFormControl-root {
    width: 100%;
    border: 1px solid #998d96;
    border-radius: 4px;
    height: 52px;
  }

  label {
    background: linear-gradient(0deg, #292128 0%, #292128 100%);
    padding-left: 6px;
    padding-right: 6px;
  }

  label.Mui-focused {
    color: #e9e0e4;
    background: linear-gradient(0deg, #292128 0%, #292128 100%);
  }

  input {
    color: #e9e0e4;
    font-family: 'Quicksand', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.5px;
  }

  .MuiOutlinedInput-root.Mui-disabled {
    height: 52px;
  }

  .Mui-disabled {
    color: #e9e0e4 !important;
    -webkit-text-fill-color: #e9e0e4 !important;
  }

  .actions {
    margin-top: 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .status-block {
      display: flex;
      align-items: center;
      gap: 8px;

      .status {
        font-family: 'Quicksand', sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0.1px;
      }
    }

    button {
      height: 40px;
    }

    .buttons {
      display: flex;
      align-items: center;
      gap: 14px;

      .delete-btn {
        color: #ffb4ab !important;

        svg {
          path {
            fill: #ffb4ab !important;
          }
        }
      }
    }
  }
`

export const EmptyState = styled(Box)`
  .empty-title {
    color: white;
    font-family: 'Quicksand', sans-serif;
    font-size: 45px;
    font-style: normal;
    font-weight: 400;
    line-height: 52px;
    margin-bottom: 16px;
  }

  .empty-desc {
    color: #e9e0e4;
    font-family: 'Quicksand', sans-serif;
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
    margin-bottom: 34px;
  }

  .empty-img {
    position: absolute;
    right: 0;
    bottom: 0;
  }
`
