import { FC, FormEvent, useState } from 'react'
import { Box, CircularProgress, TextField, Typography } from '@mui/material'
import axios from '../../../../../lib/axios'

/* Utils */
import { useAppDispatch } from '../../../../../store/hooks'
import { closeModal } from '../../../../../store/modals/modalSlice'

/* Components */
import { Actions, Content, DeleteUsersContainer, Header } from '../delete-users/deleteUsers.styles'
import { ThemeButton } from '../../../buttons'
import { DeleteIcon } from '../../../svg'
import { openToast, ToastVariant } from '../../../../../store/toasts/toastSlice'
import { ContextHolder } from '@frontegg/rest-api'

const DeleteAccount: FC = () => {
  const dispatch = useAppDispatch()
  const [value, setValue] = useState('')
  const [disabled, setDisabled] = useState(true)
  const [loading, setLoading] = useState(false)

  const handleClose = () => {
    dispatch(closeModal())
  }

  const handleDelete = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    setLoading(true)

    try {
      await axios.delete('/api/v1/identity/users')
      const baseUrl = ContextHolder.getContext().baseUrl
      window.location.href = `${baseUrl}/oauth/logout?post_logout_redirect_uri=${window.location}`
    } catch (e) {
      dispatch(
        openToast({
          variant: ToastVariant.error,
          props: {
            text: 'Failed deleting user',
            description:
              (e as any).response?.data?.message && (e as any).response?.data?.message.includes('not authorized')
                ? 'Only Account Owners can delete users.'
                : (e as any).response?.data?.message || 'There was a problem while deleting user account.'
          }
        })
      )
    } finally {
      setLoading(false)
    }
  }

  return (
    <DeleteUsersContainer>
      <form onSubmit={handleDelete}>
        <Header>
          <Typography className="title">Are you sure you want to delete your account?</Typography>
        </Header>
        <Content>
          <Typography className="title">
            This action is irreversible. All your data will be deleted, you will be logged out of your account and you
            will have to register a new account if you want to continue using the application.
          </Typography>
          <Box className="input-wrapper">
            <TextField
              variant="outlined"
              label='Type "DELETE" to confirm'
              value={value}
              autoComplete="off"
              onChange={(e) => {
                if (e.target.value === 'DELETE') {
                  setDisabled(false)
                } else {
                  setDisabled(true)
                }
                setValue(e.target.value)
              }}
              fullWidth
            />
          </Box>
        </Content>
        <Actions>
          <ThemeButton themevariant="tertiary" onClick={handleClose}>
            Cancel
          </ThemeButton>
          <ThemeButton
            themevariant="primary"
            type="submit"
            disabled={disabled}
            endIcon={loading && <CircularProgress size="20px" />}
          >
            <>
              <DeleteIcon />
              Confirm
            </>
          </ThemeButton>
        </Actions>
      </form>
    </DeleteUsersContainer>
  )
}

export default DeleteAccount
