import * as d3 from 'd3'

/*
 * Area Gradients definitions
 * */
export const appendDefs = (svg: d3.Selection<SVGSVGElement | null, unknown, null, undefined>) => {
  const defs = svg.append('defs')
  const redGradient = defs
    .append('linearGradient')
    .attr('id', 'redGradient')
    .attr('x1', 0)
    .attr('x2', 0)
    .attr('y1', 0)
    .attr('y2', 1)
  redGradient.append('stop').attr('offset', '7%').attr('stop-color', 'hsla(5, 88%, 50%, 1)').attr('stop-opacity', '0.9')
  redGradient.append('stop').attr('offset', '93%').attr('stop-color', 'hsla(5, 100%, 50%, 0.2)')

  const greenGradient = defs
    .append('linearGradient')
    .attr('id', 'greenGradient')
    .attr('x1', 0)
    .attr('x2', 0)
    .attr('y1', 0)
    .attr('y2', 1)
  greenGradient
    .append('stop')
    .attr('offset', '7%')
    .attr('stop-color', 'hsla(88, 68%, 50%, 1)')
    .attr('stop-opacity', '1')
  greenGradient
    .append('stop')
    .attr('offset', '93%')
    .attr('stop-color', 'hsla(88, 68%, 50%, 0.2)')
    .attr('stop-opacity', '1')

  const yellowGradient = defs
    .append('linearGradient')
    .attr('id', 'yellowGradient')
    .attr('x1', 0)
    .attr('x2', 0)
    .attr('y1', 0)
    .attr('y2', 1)
  yellowGradient
    .append('stop')
    .attr('offset', '7%')
    .attr('stop-color', 'hsla(38, 94%, 69%, 1)')
    .attr('stop-opacity', '1')
  yellowGradient
    .append('stop')
    .attr('offset', '93%')
    .attr('stop-color', 'hsla(38, 94%, 69%, 0.2)')
    .attr('stop-opacity', '1')

  const blueGradient = defs
    .append('linearGradient')
    .attr('id', 'blueGradient')
    .attr('x1', 0)
    .attr('x2', 0)
    .attr('y1', 0)
    .attr('y2', 1)
  blueGradient.append('stop').attr('offset', '7%').attr('stop-color', 'hsla(38, 94%, 69%, 1)').attr('stop-opacity', '1')
  blueGradient
    .append('stop')
    .attr('offset', '93%')
    .attr('stop-color', 'hsla(38, 94%, 69%, 0.2)')
    .attr('stop-opacity', '1')
}
