import React from 'react'

const StubCategoriesSectionMobile = () => (
  <svg width="227" height="242" viewBox="0 0 227 242" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_27_18148)">
      <mask id="mask0_27_18148" maskUnits="userSpaceOnUse" x="0" y="0" width="227" height="242">
        <rect width="227" height="242" rx="24" fill="#1E1A1D" />
        <rect width="227" height="242" rx="24" fill="#FFA9FC" fillOpacity="0.11" />
      </mask>
      <g mask="url(#mask0_27_18148)">
        <path d="M-355.351 -221.158H1002.41V612.526H-355.351V-221.158Z" fill="#1E1A1D" />
        <path d="M-355.351 -221.158H1002.41V612.526H-355.351V-221.158Z" fill="url(#paint0_linear_27_18148)" />
      </g>
      <line x1="4.37114e-08" y1="37.5" x2="267" y2="37.5" stroke="#4D444B" />
      <line x1="4.37114e-08" y1="79.5" x2="267" y2="79.5" stroke="#4D444B" />
      <line x1="4.37114e-08" y1="121.5" x2="267" y2="121.5" stroke="#4D444B" />
      <line x1="4.37114e-08" y1="163.5" x2="267" y2="163.5" stroke="#4D444B" />
      <line x1="4.37114e-08" y1="221.5" x2="267" y2="221.5" stroke="#4D444B" />
      <line x1="4.37114e-08" y1="263.5" x2="267" y2="263.5" stroke="#4D444B" />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_27_18148"
        x1="-355.351"
        y1="-221.158"
        x2="978.764"
        y2="-123.006"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#573F56" stopOpacity="0.12" />
        <stop offset="0.375" stopColor="#573F56" stopOpacity="0.71" />
        <stop offset="0.557292" stopColor="#573F56" stopOpacity="0.9" />
        <stop offset="0.739583" stopColor="#573F56" stopOpacity="0.71" />
        <stop offset="1" stopColor="#573F56" stopOpacity="0" />
      </linearGradient>
      <clipPath id="clip0_27_18148">
        <rect width="292" height="274" rx="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
)

export default StubCategoriesSectionMobile
