import { forwardRef, ReactChild } from 'react'
import { ButtonProps } from '@mui/material'
import { StyledButton } from './themeButton.styles'

export type ThemeVariant = 'primary' | 'secondary' | 'tertiary' | 'quaternary' | 'ghost'

type Ref = HTMLButtonElement | null

interface Props extends ButtonProps {
  children: ReactChild
  themevariant?: ThemeVariant
  noActive?: boolean
  font?: string
}

const ThemeButton = forwardRef<Ref, Props>(
  ({ className, themevariant = 'primary', font = 'small', children, ...rest }, ref) => (
    <StyledButton className={className} themevariant={themevariant} font={font} {...rest} ref={ref}>
      {children}
    </StyledButton>
  )
)

export default ThemeButton
