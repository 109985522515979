import { Box } from '@mui/material'
import styled from '@emotion/styled/macro'

export const Wrapper = styled(Box)`
  padding: 24px 32px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 16px;

  @media (min-width: 1440px) {
    gap: 16px;
  }
`
