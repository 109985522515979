import styled from '@emotion/styled/macro'
import { Box } from '@mui/material'

export const ScoreWrapper = styled(Box)`
  width: 415px;
  height: 310px;
  border-radius: 24px;
  background: linear-gradient(0deg, rgba(255, 169, 252, 0.05) 0%, rgba(255, 169, 252, 0.05) 100%);
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.3), 0px 1px 3px 1px rgba(0, 0, 0, 0.15);
  padding: 16px;

  @media (min-width: 1110px) {
    width: 290px;
  }

  @media (min-width: 1440px) {
    width: 308px;
  }

  @media (min-width: 1920px) {
    height: 310px;
    width: 415px;
    padding: 22px 32px;
  }

  .title-wrapper {
    display: flex;
    align-items: center;

    img {
      width: 16px;
      height: 16px;
    }

    .chart-title {
      color: #e9e0e4;
      font-family: Quicksand, sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: 0.1px;
      margin-left: 8px;
    }
  }

  .over-perform {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4px;
    margin-top: 12px;

    b {
      color: white;
      font-family: 'Quicksand', sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: 0.1px;
    }

    .value {
      color: white;
      font-family: 'Quicksand', sans-serif;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 16px;
      letter-spacing: 0.5px;
    }
  }

  .content {
    width: 197px;
    margin: 18px auto 0 auto;
  }
`
