import * as d3 from 'd3'
import { CUSTOM_LABEL_ID, getUtcDateAndTime } from '../../../../utils'
import { CPITimePeriod, Label } from '../../../../../../../models'

const fill = '#1E1A1D'
const pinkStroke = '#FFA9FC'
const whiteStroke = '#D0C3CC'

interface Props {
  countIndicatorButton: d3.Selection<SVGGElement, unknown, null, undefined>
  pinnedLabels?: Label[]
  selectedTimePeriod: CPITimePeriod
  filledDates: string[]
  indicatorValue: number
  stripeWidth: number
  index: number
  height: number
  date: string
}

export const appendButtonCountIndicator = ({
  countIndicatorButton,
  pinnedLabels,
  selectedTimePeriod,
  filledDates,
  indicatorValue,
  stripeWidth,
  index,
  height,
  date
}: Props) => {
  const hasPinned = pinnedLabels?.find((n) => {
    const isCustomLabel = n.action === CUSTOM_LABEL_ID
    const convertedDate = getUtcDateAndTime(isCustomLabel && n.data.date ? n.data.date : n.created_at)
    let dateHasPinned = convertedDate.isoFormattedDate.slice(0, 10) === date
    if (selectedTimePeriod !== CPITimePeriod.Week) {
      dateHasPinned = filledDates.includes(convertedDate.isoFormattedDate.slice(0, 10))
    }

    return dateHasPinned
  })

  if (indicatorValue <= 0) return

  /* Count Indicator Button */
  countIndicatorButton
    .append('circle')
    .attr('cx', stripeWidth * index + stripeWidth / 2)
    .attr('cy', height)
    .attr('r', 12)
    .attr('fill', fill)
    .attr('stroke', hasPinned ? pinkStroke : whiteStroke)
    .attr('stroke-width', 2.33)
    .attr('filter', 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.30)) drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.15))')
    .attr('display', 'block')
  countIndicatorButton
    .append('text')
    .attr('x', stripeWidth * index + stripeWidth / 2)
    .attr('y', height + 5)
    .attr('text-anchor', 'middle')
    .attr('font-size', '14px')
    .attr('font-family', "'Quicksand', sans-serif")
    .attr('font-weight', '500')
    .attr('line-height', '20px')
    .attr('letter-spacing', '0.1px')
    .attr('fill', hasPinned ? pinkStroke : whiteStroke)
    .attr('display', 'block')
    .text(indicatorValue)

  /* Count Indicator Tooltip */
  countIndicatorButton
    .append('rect')
    .attr('class', 'count-rect-tooltip-wrapper')
    .attr('x', stripeWidth * index + stripeWidth / 2 - 65)
    .attr('y', height - 49)
    .attr('width', 78)
    .attr('height', 32)
    .attr('fill', '#FFA9FC')
    .attr('rx', 8)
    .style('display', 'none')
    .style('transform', 'translateX(25px)')
  countIndicatorButton
    .append('text')
    .attr('class', 'count-rect-tooltip-text')
    .attr('x', stripeWidth * index + stripeWidth / 2 - 55)
    .attr('y', height - 28.5)
    .style('fill', 'rgba(89, 0, 96, 1)')
    .style('font-size', '14px')
    .style('font-family', "'Quicksand', sans-serif")
    .style('font-weight', 600)
    .style('line-height', '20px')
    .style('letter-spacing', '0.1px')
    .style('display', 'none')
    .style('transform', 'translateX(25px)')
    .text(`${indicatorValue} System Event${indicatorValue === 1 ? '' : 's'}`)
}
