import React, { FC, SVGProps } from 'react'

type Props = SVGProps<SVGSVGElement>

const ArrowBackIcon: FC<Props> = ({ ...props }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none" {...props}>
    <path d="M15 8.25H5.8725L10.065 4.0575L9 3L3 9L9 15L10.0575 13.9425L5.8725 9.75H15V8.25Z" fill="#FFA9FC" />
  </svg>
)

export default ArrowBackIcon
