import { styled } from '@mui/material/styles'
import { tooltipClasses, TooltipProps } from '@mui/material/Tooltip'
import { Tooltip } from '@mui/material'

export const BenchmarkCardTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    display: 'flex',
    padding: '16px',
    flexDirection: 'column',
    alignItems: 'flex-start',
    borderRadius: '8px',
    border: '1px solid #998d96',
    background: '#4d444b',
    boxShadow: '0 4px 4px 0 rgba(0, 0, 0, 0.3), 0 8px 12px 6px rgba(0, 0, 0, 0.15)',
    width: '150px'
  },
  '.tooltip-title': {
    color: '#FFF',
    fontFamily: '"Quicksand", sans-serif',
    fontSize: '24px',
    fontWeight: 400,
    lineHeight: '32px',
    marginBottom: '3px'
  },
  '.tooltip-text': {
    color: '#FFF',
    fontSize: '12px',
    fontFamily: "'Quicksand', sans-serif",
    lineHeight: '16px',
    fontWeight: 400,
    letterSpacing: '0.4px'
  },
  '.tooltip-cpi-text': {
    color: '#FFF',
    fontFamily: '"Quicksand", sans-serif',
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '20px',
    letterSpacing: '0.1px'
  },
  '.tooltip-category': {
    textTransform: 'capitalize',
    color: '#FFF',
    fontFamily: '"Quicksand", sans-serif',
    fontSize: ' 12px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '16px',
    letterSpacing: '0.4px'
  }
})
