import axiosDefault from 'axios'
import config from '../config'
import { store } from '../store/store'

const axios = axiosDefault.create({
  baseURL: config.apiHost
})

axios.interceptors.request.use((config) => {
  if (!config.headers) config.headers = {}
  const accessToken = store.getState().user.token

  config.headers.Authorization = accessToken || ''
  return config
})

export const unauthenticatedAxios = axiosDefault.create({
  baseURL: config.apiHost
})

export { axios }
export default axios
