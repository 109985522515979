import React from 'react'

const NotOne = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="41" height="26" viewBox="0 0 41 26" fill="none">
    <path
      d="M27.7729 0.454529C26.1365 0.454529 24.591 0.799983 23.1729 1.36362C28.2638 5.61817 28.6819 11.4182 28.6819 13.1818C28.6819 14.9454 28.2638 20.7454 23.1729 25C24.591 25.5636 26.1365 25.9091 27.7729 25.9091C34.791 25.9091 40.5001 20.2 40.5001 13.1818C40.5001 6.16362 34.791 0.454529 27.7729 0.454529Z"
      fill="#FFA9FC" />
    <path
      d="M20.5 23.6002C23.5124 23.6002 25.9545 18.9277 25.9545 13.1638C25.9545 7.4 23.5124 2.72748 20.5 2.72748C17.4875 2.72748 15.0454 7.4 15.0454 13.1638C15.0454 18.9277 17.4875 23.6002 20.5 23.6002Z"
      fill="white" />
    <path
      d="M12.3182 13.1818C12.3182 11.4182 12.7364 5.61817 17.8273 1.36362C16.4091 0.799983 14.8636 0.454529 13.2273 0.454529C6.20909 0.454529 0.5 6.16362 0.5 13.1818C0.5 20.2 6.20909 25.9091 13.2273 25.9091C14.8636 25.9091 16.4091 25.5636 17.8273 25C12.7364 20.7454 12.3182 14.9454 12.3182 13.1818Z"
      fill="#FFA9FC" />
  </svg>
)

export default NotOne
