import { FC, useState } from 'react'
import { CircularProgress } from '@mui/material'
import axios from '../../../../../../lib/axios'

/* Utils */
import { closeModal } from '../../../../../../store/modals/modalSlice'
import { notification } from '../../../../../../store/notifications/notificationSlice'
import { useAppDispatch, useAppSelector } from '../../../../../../store/hooks'
import { drawerPropsSelector, closeDrawer } from '../../../../../../store/drawers/drawerSlice'

/* Components */
import { PrimaryButton, SecondaryButton } from '../../../../buttons'
import {
  DeleteTicketText,
  DeleteTicketTitle,
  DeleteTicketWrapper,
  DeleteTicketButtonWrapper
} from '../jiraTicket.styles'

const DeleteTicket: FC = () => {
  const { props: drawerProps } = useAppSelector(drawerPropsSelector)
  const dispatch = useAppDispatch()
  const [loading, setLoading] = useState(false)

  const handleCancel = () => {
    dispatch(closeModal())
  }

  const handleConfirm = async () => {
    if (!drawerProps) return
    const { task } = drawerProps
    setLoading(true)

    try {
      const res = await axios.delete(`/api/v3/integrations/jira/issues/${task?.externalKey}`)

      if (res.status === 204) {
        dispatch(
          notification({
            open: true,
            variant: 'success',
            message: 'Deleted successfully.'
          })
        )
        dispatch(closeDrawer())
        dispatch(closeModal())
      }
    } catch (err) {
      console.error(err)
      dispatch(
        notification({
          open: true,
          variant: 'error',
          message: 'Failed to delete.'
        })
      )
    } finally {
      setLoading(false)
    }
  }

  return (
    <DeleteTicketWrapper>
      <DeleteTicketTitle variant="body1" align="left">
        Are you sure?
      </DeleteTicketTitle>
      <DeleteTicketText variant="body2">
        You're about to permanently delete this issue, its comments and attachments, and all of its data.
      </DeleteTicketText>
      <DeleteTicketText variant="body2">
        If you're not sure, you can resolve or close this issue instead.
      </DeleteTicketText>

      <DeleteTicketButtonWrapper>
        <SecondaryButton
          type="button"
          onClick={handleCancel}
          sx={{ border: '1px solid #F4BA94', borderRadius: '4px', maxWidth: '87px', width: 'fit-content' }}
        >
          Cancel
        </SecondaryButton>
        <PrimaryButton
          type="button"
          buttontype="red-button"
          onClick={handleConfirm}
          disabled={loading}
          endIcon={loading && <CircularProgress size="24px" />}
          sx={{ maxWidth: '97px', width: 'fit-content' }}
        >
          Confirm
        </PrimaryButton>
      </DeleteTicketButtonWrapper>
    </DeleteTicketWrapper>
  )
}

export default DeleteTicket
