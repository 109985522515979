import { Box, IconButton } from '@mui/material'
import styled from '@emotion/styled/macro'

export const HistorySectionContent = styled(Box)``

export const HistorySectionButton = styled(IconButton)`
  position: absolute;
  right: 0;
  bottom: 4px;

  svg {
    width: 24px;
    height: 24px;

    path {
      fill: #ffa9fc;
    }
  }
`
