import { FC } from 'react'
import { Box, Typography } from '@mui/material'
import { colors } from '../../../../../theme/'

const SchemaMappingInstructions: FC = () => (
  <Box
    sx={{
      padding: '12px 16px',
      background: colors.primary,
      borderRadius: '4px',
      display: 'flex'
    }}
  >
    <Box>
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{ fontSize: '24px', width: '24px', height: '24px', marginRight: '12px' }}
      >
        <path
          d="M10.9844 7V4.98438H9.01562V7H10.9844ZM10.9844 15.0156V9.01562H9.01562V15.0156H10.9844ZM2.92188 2.96875C4.89062 1 7.25 0.015625 10 0.015625C12.75 0.015625 15.0938 1 17.0312 2.96875C19 4.90625 19.9844 7.25 19.9844 10C19.9844 12.75 19 15.1094 17.0312 17.0781C15.0938 19.0156 12.75 19.9844 10 19.9844C7.25 19.9844 4.89062 19.0156 2.92188 17.0781C0.984375 15.1094 0.015625 12.75 0.015625 10C0.015625 7.25 0.984375 4.90625 2.92188 2.96875Z"
          fill="#ACACAC"
        />
      </svg>
    </Box>
    <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
      <Typography
        variant="h5"
        sx={{
          fontSize: '16px',
          lineHeight: '24px',
          fontWeight: 700,
          color: '#EEEEEE'
        }}
      >
        Instructions
      </Typography>
      <Typography
        variant="body1"
        sx={{
          fontSize: '14px',
          lineHeight: '20px',
          fontWeight: 400,
          letterSpacing: '0.25px',
          margin: '8px 0',
          color: '#EEEEEE'
        }}
      >
        You can control how Onyxia maps customized fields within your Jira Projects. If you’d like to keep the our
        suggested schema mapping, you can click “Finish”.
      </Typography>
    </Box>
  </Box>
)

export default SchemaMappingInstructions
