import { Box, Table as MuiTable } from '@mui/material'
import styled from '@emotion/styled/macro'

export const Container = styled(Box)`
  width: 100%;
  display: flex;
  margin-left: auto;
  margin-right: auto;
  height: 100%;
`
export const ContentWrapper = styled(Box)<{ showFilter: boolean }>`
  width: ${({ showFilter }) => (showFilter ? 'calc(100% - 444px)' : '100%')};
  transition: all 0.3s ease-in-out;
  height: 100%;
`

export const Table = styled(MuiTable)`
  background: linear-gradient(0deg, rgba(255, 169, 252, 0.05) 0%, rgba(255, 169, 252, 0.05) 100%), #1e1a1d;
  border: 1px solid #342f32;
`

export const TableContainer = styled.div`
  display: block;
  overflow: auto;
  width: 100%;
  background: linear-gradient(0deg, rgba(255, 169, 252, 0.05) 0%, rgba(255, 169, 252, 0.05) 100%), #1e1a1d;
  border: 1px solid #342f32;
`
