import { Box } from '@mui/material'
import styled from '@emotion/styled/macro'

export const Container = styled(Box)`
  position: relative;
  background: #131013;
  height: 100vh;
  width: 100%;
  z-index: 1;

  .background-image {
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 0;
  }
`

export const Content = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  margin-top: -100px;
  gap: 45px;
  z-index: 1;

  .content-main {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-weight: 400;
    line-height: normal;
    max-width: 195px;
    gap: 14px;

    .content-main-image {
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 117.424px;
      width: 155px;
      height: 155px;
      background: #544152;
      box-shadow: 0 9.688px 14.531px 7.266px rgba(0, 0, 0, 0.15), 0 4.844px 4.844px 0 rgba(0, 0, 0, 0.3);
    }

    .content-main-text-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      gap: 9px;

      .content-main-title {
        color: #fff;
        font-family: Quicksand, sans-serif;
        text-align: center;
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        white-space: nowrap;
      }

      .content-main-text {
        color: #fff;
        font-family: Quicksand, sans-serif;
        text-align: center;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        white-space: nowrap;
      }
    }
  }

  .content-select-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 32px;

    .content-select-item {
      display: inline-flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 279px;
      height: 346px;
      gap: 27.5px;
      border-radius: 16px;
      background: #292128;

      .content-select-item__image-wrapper {
        width: 108px;
        height: 108px;
        border-radius: 81.581px;
        background: #544152;
        box-shadow: 0 6.73px 10.096px 5.048px rgba(0, 0, 0, 0.15), 0 3.365px 3.365px 0 rgba(0, 0, 0, 0.3);
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .content-select-item__text-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        gap: 12px;

        .content-select-item__title {
          color: #fff;
          font-family: Quicksand, sans-serif;
          font-size: 20px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }

        .content-select-item__text {
          color: #fff;
          font-family: Quicksand, sans-serif;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          max-width: 194px;
        }
      }
    }
  }
`
