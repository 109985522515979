import { useEffect, useState } from 'react'
import { LabelOption } from '../ui/pages/library/components/LibrarySelectLabelField'

const UseSearchField = (options: LabelOption[], handleReset: any, combine = false) => {
  const [search, setSearch] = useState('')
  const [labelOptions, setLabelOptions] = useState(options)

  useEffect(() => {
    if (options.length > 0) {
      setLabelOptions(options)
    }
  }, [options])

  useEffect(() => {
    setTimeout(() => {
      if (!search) setLabelOptions([...options])

      const filteredItems = options.filter((item) =>
        combine
          ? (item.label + ' ' + item.name).toLowerCase().includes(search.toLowerCase())
          : item.label.toLowerCase().includes(search.toLowerCase())
      )
      setLabelOptions([...filteredItems])
    }, 300)
  }, [search])

  const highlightMatch = (text: string) => {
    if (!search) return text

    const regex = new RegExp(`(${search})`, 'gi')
    return text.split(regex).map((part, index) =>
      regex.test(part) ? (
        <span key={index} style={{ color: '#FFA9FC' }}>
          {part}
        </span>
      ) : (
        part
      )
    )
  }

  const resetData = () => {
    setSearch('')
    handleReset()
  }

  return { search, labelOptions, setSearch, resetData, highlightMatch }
}

export default UseSearchField
