import { IChartData, IChartDataCategory, PerformanceScoreCategory, PerformanceStatus } from '../../../models'

export const sidePanelWidth = 529

export const formatPerformanceDataCategory = (apiData: PerformanceScoreCategory[]): IChartDataCategory[] => {
  if (apiData.every((n) => n.status === PerformanceStatus.unavailable)) {
    return apiData.map((n) => ({
      category: n.name || n.id,
      status: n.status,
      score: 100
    }))
  }
  return apiData.map((n) => {
    if (n.status === PerformanceStatus.active && typeof n.score === 'number') {
      return {
        category: n.name || n.id,
        status: n.status,
        score: n.score >= 100 ? 99.99 : n.score === 0 ? 0.01 : n.score
      }
    } else if (n.status === PerformanceStatus.available) {
      return {
        category: n.name || n.id,
        status: n.status,
        score: 0
      }
    } else {
      return {
        category: n.name || n.id,
        status: n.status,
        score: 100
      }
    }
  })
}

export const formatPerformanceData = (apiData: PerformanceScoreCategory[]): IChartData[] => {
  if (apiData.every((n) => n.status === PerformanceStatus.unavailable)) {
    return apiData.map((n) => ({
      category: n.name || n.id,
      status: n.status,
      score: 100
    }))
  }
  return apiData.map((n) => {
    if (n.status === PerformanceStatus.active && typeof n.score === 'number') {
      return {
        category: n.name || n.id,
        status: n.status,
        score: n.score >= 100 ? n.score : n.score === 0 ? 0.01 : n.score
      }
    } else if (n.status === PerformanceStatus.available) {
      return {
        category: n.name || n.id,
        status: n.status,
        score: 0
      }
    } else {
      return {
        category: n.name || n.id,
        status: n.status,
        score: 100
      }
    }
  })
}

export const getMissingSourcesMap = () => {
  const sourcesMap = new Map()
  sourcesMap.set('The Cyberwire', 'the_cyberwire')
  sourcesMap.set('Cybersafe', 'cybersafe')
  sourcesMap.set('eSecurity Planet', 'esecurity_planet')
  sourcesMap.set('Hackread', 'hackread')
  sourcesMap.set('Cybersecurity Insiders', 'cybersecurity_insiders')
  sourcesMap.set('MSSP Alert', 'mssp_alert')
  sourcesMap.set('Security Intelligence', 'security_intelligence')
  sourcesMap.set('itsecurityguru', 'itsecurityguru')
  sourcesMap.set('The Record', 'the_record')
  sourcesMap.set('NextGov: cybersecurity', 'nextgov_cybersecurity')
  sourcesMap.set('FCW: cybersecurity', 'fcw_cybersecurity')
  sourcesMap.set('CyberScoop', 'cyberscoop')
  sourcesMap.set('TechCrunch: Security', 'techcrunch_security')
  sourcesMap.set('Securelist', 'securelist')
  sourcesMap.set('Latest Hacking News', 'latest_hacking_news')
  sourcesMap.set('MIT News: Cybersecurity', 'mit_news_cybersecurity')
  sourcesMap.set('Cybersecurity Dive', 'cybersecurity_dive')
  sourcesMap.set('GBHackers on Security', 'gbhackers_on_security')
  sourcesMap.set('Data Breach Today', 'data_breach_today')
  sourcesMap.set('The Cyberwire', 'the_cyberwire')
  sourcesMap.set('Cybersafe', 'cybersafe')
  sourcesMap.set('The Cyber Post', 'the_cyber_post')
  sourcesMap.set('CISA Current Activity', 'cisa_current_activity')
  sourcesMap.set('CISA Alerts', 'cisa_alerts')
  sourcesMap.set('CISA ICS', 'cisa_ics')
  sourcesMap.set(
    'Carnegie Mellon University Recently Published Vulnerabilities',
    'carnegie_mellon_university_recently_published_vulnerabilities'
  )
  sourcesMap.set('Binary Defense', 'binary_defense')
  sourcesMap.set('Talos Intelligence', 'talos_intelligence')

  return sourcesMap
}
