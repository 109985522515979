import { Box, Chip as MuiChip } from '@mui/material'
import styled from '@emotion/styled/macro'

export const ChipWrapper = styled(Box)`
  background: transparent;
  border-radius: 8px;
  margin: 6px 0 4px;
`

export const Chip = styled(MuiChip)`
  background: #e0e0e0;
  margin-bottom: 4px;
  margin-right: 4px;
`

export const formControlStyles = {
  width: '25ch',
  formControlRoot: {
    width: '25ch',
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    flexWrap: 'wrap',
    flexDirection: 'row',
    border: '2px solid lightgray',
    padding: 4,
    borderRadius: '4px',

    '& > div.container': {
      flexDirection: 'row',
      flexWrap: 'wrap'
    },

    '& > div.container > span': {
      backgroundColor: 'gray',
      padding: '1px 3px',
      borderRadius: '4px'
    }
  }
}

export const textFieldStyles = {
  width: '441px',
  height: '32px',
  backgroundColor: '#fff'
}
