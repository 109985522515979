import { Box, Popper as MuiPopper } from '@mui/material'
import styled from '@emotion/styled/macro'

export const Popper = styled(MuiPopper)`
  //position: absolute !important;
  //top: 219px !important;
  //right: 24px !important;
  //left: unset !important;
  margin-top: 8px !important;
  z-index: 10000;
  //width: fit-content !important;
`

export const PopperInner = styled(Box)`
  border: 1px solid #4d444b;
  box-shadow: 0 8px 12px 6px rgba(0, 0, 0, 0.15), 0 4px 4px 0 rgba(0, 0, 0, 0.3);
  padding: 16px;
  gap: 24px;
  width: 460px;
  border-radius: 8px;
  background: #292128;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  .popper-search-container {
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 100%;

    .header-text {
      overflow: hidden;
      color: #fff;
      text-overflow: ellipsis;
      font-family: Quicksand, sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin-bottom: -4px;
    }
  }

  //.popper-inner-scrollable {
  //  height: 284px;
  //  width: 100%;
  //  overflow-y: auto;
  //  padding: 0 8px;
  //  gap: 16px;
  //  margin: 0;

  .checkbox-form-control {
    margin: 0;
    gap: 16px;

    .MuiFormLabel-root {
      color: #fff;
      font-family: Quicksand, sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }

    .MuiFormGroup-root {
      margin-top: 8px;

      .MuiFormControlLabel-root {
        .MuiTypography-root {
          color: #fff;
          font-family: Quicksand, sans-serif;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
      }
    }
  }
  //}

  .popper-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    border-top: 1px solid rgba(77, 68, 75, 1);
    padding-top: 16px;

    .footer-actions-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 16px;
    }
  }
`
