import * as d3 from 'd3'
import { getSlaGroupProps } from '../../../../utils'
import { VisibleLabel } from '../../../../../../../models'

const getTextWidth = (text: string, font: string): any => {
  // re-use canvas object for better performance
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const canvas = getTextWidth.canvas || (getTextWidth.canvas = document.createElement('canvas'))
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const context = canvas.getContext('2d')
  context.font = font
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const metrics = context.measureText(text)
  return metrics.width
}

interface Props {
  svg:
    | d3.Selection<SVGSVGElement | null, unknown, null, undefined>
    | d3.Selection<SVGGElement, unknown, null, undefined>
  slaGroup: d3.Selection<SVGGElement, unknown, null, undefined>
  text: string
  width: number
  height: number
  healthBorder: number
  visibleLabels: VisibleLabel[]
}

export const styledSlaGroup = ({ svg, slaGroup, text, width, height, healthBorder, visibleLabels }: Props) => {
  const circleMarginLeft = 16
  const circleWidth = 24
  const textWidth = getTextWidth(text, '500 14px Quicksand')
  const slaRectWidth = textWidth + 68
  const { slaYPosition, slaYPositionValue, overflowValuePadding, slaMarginLeft, slaRectHeight } = getSlaGroupProps(
    height,
    healthBorder,
    visibleLabels,
    slaRectWidth
  )

  slaGroup
    .append('rect')
    .attr('class', 'sla-tooltip-wrapper')
    .attr('x', slaMarginLeft)
    .attr('y', slaYPosition - slaRectHeight / 2)
    .attr('width', slaRectWidth)
    .attr('height', slaRectHeight)
    .attr('fill', 'rgba(208, 188, 255, 0.16)')
    .attr('opacity', 1)
    .attr('rx', 14)
    .attr('ry', 14)

  slaGroup
    .append('text')
    .attr('x', slaMarginLeft + 100 / 2)
    .attr('y', slaYPosition)
    .attr('dy', '0.36em')
    .style('fill', '#fff')
    .style('font-size', '14px')
    .style('font-family', "'Quicksand', sans-serif")
    .style('font-weight', '500')
    .style('line-height', '20px')
    .style('letter-spacing', '0.1px')
    .text(text)

  /* Circle line horizontal */
  slaGroup
    .append('line')
    .attr('x1', slaMarginLeft + circleMarginLeft)
    .attr('x2', slaMarginLeft + circleMarginLeft + circleWidth)
    .attr('y1', slaYPosition)
    .attr('y2', slaYPosition)
    .attr('stroke', 'white')
    .attr('stroke-width', 3)

  /* Circle line vertical */
  slaGroup
    .append('line')
    .attr('x1', slaMarginLeft + circleMarginLeft + circleWidth / 2)
    .attr('x2', slaMarginLeft + circleMarginLeft + circleWidth / 2)
    .attr('y1', slaYPosition - 12)
    .attr('y2', slaYPosition + 12)
    .attr('stroke', 'white')
    .attr('stroke-width', 3)

  /* Circle outer */
  slaGroup
    .append('circle')
    .attr('cx', slaMarginLeft + circleMarginLeft + circleWidth / 2)
    .attr('cy', slaYPosition)
    .attr('r', 10)
    .attr('fill', 'white')

  /* Circle inner */
  slaGroup
    .append('circle')
    .attr('cx', slaMarginLeft + circleMarginLeft + circleWidth / 2)
    .attr('cy', slaYPosition)
    .attr('r', 6)
    .attr('fill', 'white')
    .attr('stroke', 'black')
    .attr('stroke-width', '3')

  /*
   *  SLA Line
   * */
  const slaYPositionLow = slaYPosition - slaRectHeight / 2
  const slaYPositionHigh = slaYPosition + slaRectHeight / 2
  const fullWidth = slaMarginLeft + slaRectWidth
  const leftLineWidth =
    height - slaYPositionValue <= overflowValuePadding || slaYPositionValue < overflowValuePadding
      ? fullWidth
      : slaYPosition <= slaYPositionLow && slaYPosition >= slaYPositionHigh
      ? fullWidth
      : slaMarginLeft

  const existingSLALineLeft = svg.select('.sla-line-left')
  if (!existingSLALineLeft.empty()) existingSLALineLeft.remove()
  svg
    .append('line')
    .attr('class', 'sla-line-left')
    .attr('x1', 0)
    .attr('x2', leftLineWidth)
    .attr('y1', slaYPositionValue)
    .attr('y2', slaYPositionValue)
    .attr('stroke', '#fff')
    .attr('stroke-width', 1)

  const existingSLALineRight = svg.select('.sla-line-right')
  if (!existingSLALineRight.empty()) existingSLALineRight.remove()
  svg
    .append('line')
    .attr('class', 'sla-line-right')
    .attr('x1', slaRectWidth + slaMarginLeft)
    .attr('x2', width)
    .attr('y1', slaYPositionValue)
    .attr('y2', slaYPositionValue)
    .attr('stroke', '#fff')
    .attr('stroke-width', 1)
}
