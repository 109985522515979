const LabelPinIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
      <path
        d="M10.4001 3.40003V6.90003C10.4001 7.68403 10.6591 8.41203 11.1001 9.00003H6.9001C7.3551 8.39803 7.6001 7.67003 7.6001 6.90003V3.40003H10.4001ZM12.5001 2.00003H5.5001C5.1151 2.00003 4.8001 2.31503 4.8001 2.70003C4.8001 3.08503 5.1151 3.40003 5.5001 3.40003H6.2001V6.90003C6.2001 8.06203 5.2621 9.00003 4.1001 9.00003V10.4H8.2791V15.3L8.9791 16L9.6791 15.3V10.4H13.9001V9.00003C12.7381 9.00003 11.8001 8.06203 11.8001 6.90003V3.40003H12.5001C12.8851 3.40003 13.2001 3.08503 13.2001 2.70003C13.2001 2.31503 12.8851 2.00003 12.5001 2.00003Z"
        fill="#FFA9FC"
      />
    </svg>
  )
}

export default LabelPinIcon
