const LegendPredictedFindingIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="4" viewBox="0 0 16 4" fill="none">
    <mask id="path-1-inside-1_1_4270" fill="white">
      <path d="M0 0.5H16V3.5H0V0.5Z" />
    </mask>
    <path
      d="M16 -1H14V2H16V-1ZM10 -1H6V2H10V-1ZM2 -1H0V2H2V-1ZM16 -2.5H14V3.5H16V-2.5ZM10 -2.5H6V3.5H10V-2.5ZM2 -2.5H0V3.5H2V-2.5Z"
      fill="#BB95EA"
      mask="url(#path-1-inside-1_1_4270)"
    />
  </svg>
)

export default LegendPredictedFindingIcon
