import { Box, Breadcrumbs, Button, TableContainer as MuiTableContainer, TableRow } from '@mui/material'
import { styled as muiStyled } from '@mui/material/styles'
import styled from '@emotion/styled/macro'

export const Container = styled(Box)`
  width: 100%;
  margin-bottom: 146px;
`

export const BreadcrumbsWrapper = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  max-width: 70%;
  padding-left: 8px;

  .header-actions {
    display: flex;
    align-items: center;

    .favorite-icon {
      margin-right: 34px;

      .MuiButtonBase-root {
        &:hover,
        &:focus,
        &:active {
          background: rgba(202, 196, 208, 0.08);
        }
      }
    }
  }
`

export const HeaderBreadcrumbs = styled(Breadcrumbs)`
  .MuiLink-root,
  .current-breadcrumb {
    font-family: 'Quicksand', sans-serif;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.5px;
    text-decoration: none;

    &.category-breadcrumb {
      color: #fff;
      text-align: center;
      font-family: Quicksand, sans-serif;
      font-size: 11px;
      font-style: normal;
      font-weight: 500;
      line-height: 16px;
      letter-spacing: 0.5px;
      display: flex;
      padding: 2px 8px;
      align-items: center;
      gap: 8px;
      border-radius: 8px;
      background: #b15c0f;
      box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.15), 0 1px 2px 0 rgba(0, 0, 0, 0.3);
      text-transform: capitalize;
    }
  }

  .MuiLink-root {
    color: #debdd9;
  }

  .current-breadcrumb {
    color: #ffa9fc;
  }

  .MuiBreadcrumbs-separator {
    color: #debdd9;
  }
`

export const AuditLogContainer = styled(Box)`
  min-height: calc(100vh - 90px);
  width: 100%;
  padding: 0;
  display: flex;
  align-items: center;
  flex-direction: column;
`

export const TableContainer = styled(MuiTableContainer)<{ showborder: string }>`
  border-radius: 0;

  &::-webkit-scrollbar {
    position: sticky;
    top: 0;
  }

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 0 rgba(0, 0, 0, 0);
    background: #2c242c;
  }

  &::-webkit-scrollbar-thumb {
    height: 8px;
    border-radius: 8px;
    background-color: #fbd8f5;
  }

  ${({ showborder }) =>
    showborder === 'true' &&
    `
    border-radius: 0 0px 16px 16px;
  `};

  .MuiTable-root {
    .MuiTableBody-root {
      .MuiTableRow-root {
        &:hover {
          border-bottom: 1px solid #998d96;
        }
      }
    }
  }

  .head-title {
    color: #e9e0e4;
    font-family: Quicksand, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.1px;
  }

  .timestamp {
    color: #d0c3cc;
    font-family: Quicksand, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.1px;
    white-space: nowrap;
  }

  .name-wrapper {
    white-space: nowrap;
  }

  .name {
    color: #fff;
    font-family: Quicksand, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.1px;
    white-space: nowrap;
  }

  .email {
    color: #d0c3cc;
    font-family: Quicksand, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.5px;
    margin-left: 16px;
    white-space: nowrap;
  }

  .role {
    color: #d0c3cc;
    font-family: Quicksand, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.5px;
    text-transform: capitalize;
  }

  .description {
    color: #d0c3cc;
    font-family: Quicksand, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.5px;
  }
`

export const TableActionsWrapper = styled(Box)`
  display: flex;
  flex-flow: column nowrap;
  height: 100%;
  min-height: 100%;
  width: 100%;
`

export const TableHeader = styled(Box)<{ showborder?: string }>`
  display: flex;
  align-items: center;
  gap: 16px;
  width: 100%;

  ${({ showborder }) =>
    showborder &&
    showborder === 'false' &&
    `

    border-radius: 0 0 16px 16px;
  `};

  .header-actions {
    display: flex;
    align-items: center;
    gap: 24px;

    .password-reset {
      margin-left: 42px;
      padding: 10px 24px 10px 16px;
      gap: 8px;
      background: #573f56;
      border-radius: 100px;
      height: 40px;
      font-family: 'Quicksand', sans-serif;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.1px;
      color: #fbd8f5;
      text-transform: none;

      svg {
        width: 18px;
        height: 18px;

        path {
          fill: #f8d8f5;
        }
      }

      &:disabled {
        background: rgba(255, 255, 255, 0.1);
        color: #fff;

        svg {
          path {
            fill: #fff;
          }
        }
      }
    }

    .delete-user {
      margin-left: 16px;
      background: #93000a;
      border-radius: 100px;
      height: 40px;
      padding: 10px 24px 10px 16px;
      gap: 8px;
      font-family: 'Quicksand', sans-serif;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.1px;
      color: #ffb4ab;
      text-transform: none;

      svg {
        width: 18px;
        height: 18px;

        path {
          fill: #ffb4ab;
        }
      }

      &:disabled {
        background: rgba(255, 255, 255, 0.1);
        color: #fff;

        svg {
          path {
            fill: #fff;
          }
        }
      }
    }
  }

  .clear-filters-wrapper {
    display: flex;
    align-items: center;
    height: 56px;
  }

  .footer-actions {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    padding: 8px 0;
    gap: 38px;

    .per-page-wrapper {
      display: flex;
      align-items: center;
      gap: 16px;

      .per-page-text {
        color: #d0c3cc;
        font-family: Quicksand, sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0.5px;
      }
    }

    .page-counter {
      display: flex;
      align-items: center;

      .MuiTypography-root {
        color: #d0c3cc;
        text-align: right;
        font-family: Quicksand, sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0.5px;
      }
    }

    .actions-wrapper {
      display: flex;
      align-items: center;

      .icon-container {
        display: flex;
        align-items: center;
        padding: 8px;
        background: transparent;
        box-shadow: none;
        border: 0;

        &:hover {
          cursor: pointer;
        }

        &:disabled {
          cursor: default;

          svg {
            opacity: 0.38;
          }
        }

        &.left svg {
          transform: rotate(180deg);
        }

        svg {
          width: 24px;
          height: 24px;
        }
      }
    }
  }
`

export const EmptyRow = styled(Box)`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-flow: column wrap;
  background: linear-gradient(0deg, rgba(255, 169, 252, 0.11) 0%, rgba(255, 169, 252, 0.11) 100%), #1e1a1d;
  border-bottom: 1px solid #342f32;
  width: 100%;
  min-height: calc(100vh - 306px);
  position: relative;
  padding: 55px 52px;
  border-radius: 0;
  z-index: 1;
  height: 646px;

  .text {
    z-index: 1;
    color: #fff;
    font-family: Quicksand, sans-serif;
    font-size: 45px;
    font-style: normal;
    font-weight: 400;
    line-height: 52px;
    margin-bottom: 24px;
  }

  .icon {
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 0;
  }
`

export const ClearFilters = styled(Button)`
  background: transparent;
  border-radius: 8px;
  gap: 8px;
  min-width: 78px;
  height: 32px;
  font-family: 'Quicksand', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 510;
  line-height: normal;
  display: flex;
  align-items: center;
  letter-spacing: 0.5px;
  color: #ffa9fc;
  text-transform: none;

  &.Mui-disabled {
    color: #debdd9;
    opacity: 0.38;
  }
`

export const LogAction = styled(Box)`
  border-radius: 8px;
  border: 1px solid #998d96;
  display: flex;
  padding: 6px 16px 6px 8px;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  width: fit-content;

  .MuiTypography-root {
    color: #fbd8f5;
    text-align: center;
    font-family: Quicksand, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.1px;
    white-space: nowrap;
    margin-left: 8px;
  }
`

export const TableBodyRow = muiStyled(TableRow)(() => ({
  background: 'linear-gradient(0deg, rgba(255, 169, 252, 0.11) 0%, rgba(255, 169, 252, 0.11) 100%), #1E1A1D',
  padding: '8px 0 8px 16px',
  borderBottom: '1px solid #4D444B',
  '&:last-child td, &:last-child th': {
    border: 0
  },
  '.MuiTableCell-root': {
    border: 0,
    paddingTop: 0,
    paddingBottom: 0,

    '&.log-action': {
      paddingTop: '8px',
      paddingBottom: '8px'
    }
  },
  '&:hover': {
    background: '#573F56'
  }
}))

export const TableHeadRow = muiStyled(TableRow)({
  background: 'linear-gradient(0deg, rgba(255, 169, 252, 0.11) 0%, rgba(255, 169, 252, 0.11) 100%), #1E1A1D',
  borderBottom: '1px solid #4D444B',
  '.MuiTableCell-root': {
    border: 0,
    paddingTop: '8px',
    paddingBottom: '8px'
  },
  height: '55px'
})

export const DateRangePickerContainer = styled(Box)`
  position: relative;

  .MuiInputBase-input {
    color: #d0c3cc;
  }

  .MuiFormControl-root {
    margin-left: 8px !important;
    width: 152px;
  }

  .calendar-container {
    background: linear-gradient(0deg, rgba(255, 169, 252, 0.11), rgba(255, 169, 252, 0.11)), #1e1a1d;
    box-shadow: 0 4px 8px 3px rgba(0, 0, 0, 0.15), 0 1px 3px rgba(0, 0, 0, 0.3);
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    z-index: 1000000000;
    top: 80px;
    position: absolute;
  }

  .calendar-header {
    background: linear-gradient(0deg, rgba(255, 169, 252, 0.12), rgba(255, 169, 252, 0.12)), #1e1a1d;
    box-shadow: 0 6px 10px 4px rgba(0, 0, 0, 0.15), 0 2px 3px rgba(0, 0, 0, 0.3);
    border-radius: 4px 4px 0 0;

    .calendar-header__inner {
      display: flex;
      justify-content: space-around;
      align-items: center;
      max-width: 679px;
      padding: 16px 24px;

      fieldset {
        border: 1px solid #998d96;
        border-radius: 4px;
      }

      input {
        font-family: 'Quicksand', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.5px;
        color: #d0c3cc;
        -webkit-text-fill-color: unset;
      }

      label {
        font-family: 'Quicksand', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 23px;
        letter-spacing: 0.4px;
        color: #d0c3cc;
      }
    }
  }

  .calendar-content {
    display: flex;
    align-items: flex-start;

    .MuiPickerStaticWrapper-content {
      width: 660px;
      border-radius: unset;
      background: transparent;

      .MuiDateRangePickerViewDesktop-container {
        width: 330px;

        /* Month Selector */

        .MuiPickersArrowSwitcher-root {
          button svg {
            fill: #d0c3cc;
          }

          .MuiTypography-root {
            font-family: 'Quicksand', sans-serif;
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            letter-spacing: 0.1px;
            color: #d0c3cc;
          }
        }

        &.MuiDateRangePickerViewDesktop-container {
          border-right: 1px solid #444746;
        }

        .MuiDayPicker-weekContainer {
          .MuiDateRangePickerDay-root:first-of-type:not(.MuiDateRangePickerDay-rangeIntervalDayHighlightStart, .MuiDateRangePickerDay-rangeIntervalDayHighlightEnd),
          .MuiDateRangePickerDay-root:last-of-type {
            border-radius: 0 !important;
          }
        }
      }
    }

    .MuiDateRangePickerDay-root {
      .MuiButtonBase-root.MuiPickersDay-root {
        font-family: 'Quicksand', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.25px;
        color: #e9e0e4;

        &.MuiPickersDay-dayOutsideMonth {
          color: #4d444b;
        }
      }

      &.MuiDateRangePickerDay-rangeIntervalDayHighlight {
        background: #573f56;

        .MuiButtonBase-root.MuiPickersDay-root {
          color: #d0c3cc;
        }

        &.MuiDateRangePickerDay-rangeIntervalDayHighlightStart,
        &.MuiDateRangePickerDay-rangeIntervalDayHighlightEnd {
          .MuiDateRangePickerDay-rangeIntervalPreview {
            .MuiButtonBase-root.MuiPickersDay-root {
              background: #ffa9fc;
              color: #590060;

              &.MuiPickersDay-today {
                border: 1px solid #ffa9fc;
                color: #ffa9fc;
              }
            }
          }
        }
      }

      .MuiButtonBase-root.MuiPickersDay-root.MuiPickersDay-today {
        border: 1px solid #ffa9fc;
      }
    }

    .calendar-picker-wrapper {
      width: 140px;
    }

    .range-selector-wrapper {
      display: flex;
      flex-flow: column wrap;
      align-items: flex-start;
      margin-top: 5px;

      & > .MuiButtonBase-root {
        padding: 4px 24px 4px 16px;
        width: 100%;
        height: 48px;
        background: transparent;
        font-family: 'Quicksand', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.5px;
        text-transform: none;
        justify-content: flex-start;
        color: #e6e1e5;
      }
    }
  }

  .calendar-footer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 9px 12px 11px 0;
    border-top: 1px solid #444746;

    & > .MuiButtonBase-root {
      min-width: 69px;
      min-height: 40px;
      border-radius: 100px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: transparent;
      gap: 8px;
      font-family: 'Quicksand', sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.1px;
      color: #ffa9fc;
      text-transform: none;

      &:first-of-type {
        margin-right: 8px;
      }

      &:nth-of-type(2) {
        width: 45px;
        min-width: unset;
      }
    }
  }

  .MuiMultiInputDateRangeField-separator {
    display: none;
  }

  .MuiFormControl-root {
    height: 40px;

    .MuiInputLabel-root {
      color: #ffa9fc;
      text-align: center;
      font-family: Quicksand, sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: 0.1px;
      top: -6px;
    }

    .MuiOutlinedInput-root {
      height: 40px;
      border-radius: 100px;
      border: 1px solid #998d96;
      background: transparent;
      text-align: center;
      font-family: Quicksand, sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: 0.1px;
      color: #fff;

      .MuiInputBase-input {
        cursor: pointer;
      }

      &.Mui-focused {
        background: rgba(208, 188, 255, 0.16);
        color: #ffa9fc;
        border: 1px solid #ffa9fc;
      }

      input {
        height: 40px;
      }
    }
  }
`
