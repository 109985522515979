import { FC } from 'react'
import { DateRange, LocalizationProvider } from '@mui/x-date-pickers-pro'
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs'
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker'
import { TextField } from '@mui/material'
import dayjs, { Dayjs } from 'dayjs'
import { dateFormat } from '../../../../../lib/utils'
import { DateRangePickerContainer } from '../auditLog.styles'

interface Props {
  dateValues: DateRange<Dayjs>
  handleDateChange: (date: DateRange<Dayjs>) => Promise<void>
}

const AuditDatePicker: FC<Props> = ({ dateValues, handleDateChange }) => {
  return (
    <DateRangePickerContainer>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DateRangePicker
          calendars={1}
          value={dateValues}
          onAccept={(date) => {
            handleDateChange(date)
          }}
          slotProps={{
            popper: {
              sx: {
                '.MuiPaper-root.MuiPickersPopper-paper': {
                  borderRadius: '16px',
                  background: '#573F56',

                  '.MuiPickersArrowSwitcher-root': {
                    '.MuiButtonBase-root': {
                      color: '#D0C3CC'
                    }
                  },

                  '.MuiPickersCalendarHeader-label': {
                    color: '#D0C3CC',
                    fontFamily: '"Quicksand",sans-serif',
                    fontSize: '14px',
                    fontStyle: 'normal',
                    fontWeight: 500,
                    lineHeight: '20px',
                    letterSpacing: '0.1px'
                  },

                  '.MuiDayCalendar-header': {
                    '.MuiTypography-root.MuiTypography-caption.MuiDayCalendar-weekDayLabel': {
                      color: '#D0C3CC',
                      textAlign: 'center',
                      fontFamily: '"Quicksand",sans-serif',
                      fontSize: '16px',
                      fontStyle: 'normal',
                      fontWeight: 500,
                      lineHeight: '24px',
                      letterSpacing: '0.1px'
                    }
                  },

                  '.MuiButtonBase-root.MuiPickersDay-root': {
                    color: '#D0C3CC',
                    textAlign: 'center',
                    fontFamily: '"Quicksand",sans-serif',
                    fontSize: '16px',
                    fontStyle: 'normal',
                    fontWeight: 500,
                    lineHeight: '24px',
                    letterSpacing: '0.1px',

                    '&.Mui-selected': {
                      background: '#FFA9FC',
                      color: '#66006E'
                    },
                    '&:not(.Mui-selected)': {
                      '&:hover': {
                        background: '#867083',
                        borderColor: '#867083'
                      }
                    },
                    '&.MuiPickersDay-today': {
                      background: 'transparent',
                      border: '1px solid #FFA9FC',
                      color: '#FFA9FC'
                    },
                    '&.Mui-disabled': {
                      color: '#998D96'
                    }
                  },

                  '.MuiButton-root': {
                    color: '#ffa9fc',
                    fontFamily: 'Quicksand',
                    fontSize: '14px',
                    lineHeight: '20px',
                    letterSpacing: '0.1px',
                    margin: '0'
                  }
                }
              }
            },
            actionBar: {
              actions: ['cancel', 'accept']
            }
          }}
          format="MM/DD/YYYY"
          slots={{
            textField: (props) => {
              return (
                <>
                  <span>{props.label === 'Start' ? 'From' : 'To'}</span>
                  <TextField
                    className="text-field"
                    {...props}
                    label=""
                    value={`${dayjs((props as any).value).format(dateFormat.auditLogDefault)}`}
                  />
                </>
              )
            }
          }}
          showDaysOutsideCurrentMonth
          disableFuture
        />
      </LocalizationProvider>
    </DateRangePickerContainer>
  )
}

export default AuditDatePicker
