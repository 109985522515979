import React, { ChangeEvent, Dispatch, FC, SetStateAction } from 'react'
import { Box, IconButton, InputAdornment, TextField, Typography } from '@mui/material'

/* Utils */
import { FrameworkCategoryCpi, FrameworkType } from '../../../../../models'

/* Components */
import { Container } from './cpiItem.styles'
import CpiChip from '../../../../components/cpi-chip'
import CpiActiveButton from '../../../../components/cpi-active-button'
import CloseIcon from '@mui/icons-material/Close'

interface Props {
  cpi: FrameworkCategoryCpi
  handleDeleteCpi: (cpiName: string) => void
  categoriesForm: any
  setCategoriesForm: any
  selectedCategoryItem: any
  setSelectedCategoryItem: any
  hasEditRole: boolean
  framework: FrameworkType
  setEdited: Dispatch<SetStateAction<boolean>>
  setDiscardDisabled: Dispatch<SetStateAction<boolean>>
}

const CpiItem: FC<Props> = ({
  cpi,
  handleDeleteCpi,
  categoriesForm,
  setSelectedCategoryItem,
  setCategoriesForm,
  selectedCategoryItem,
  hasEditRole,
  framework,
  setDiscardDisabled,
  setEdited
}) => {
  const handleInput = (e: ChangeEvent<HTMLInputElement>) => {
    const updatedCategoryCpis = categoriesForm.categories.map((category: any) => {
      let isEdit = category.isEdit || false

      const tempCpis = category.cpis.map((cpi: any) => {
        if (cpi.name === e.target.name) {
          const payload = {
            ...cpi,
            weight: +e.target.value,
            isEdit: true
          }
          isEdit = true
          return payload
        }
        return cpi
      })
      if (category.id === selectedCategoryItem.id) {
        setSelectedCategoryItem({ ...selectedCategoryItem, cpis: tempCpis })
      }

      return {
        ...category,
        isEdit: isEdit,
        lastAction: 'edit',
        cpis: tempCpis
      }
    })

    const updatedForm = {
      ...categoriesForm,
      categories: updatedCategoryCpis
    }

    setCategoriesForm(updatedForm)
  }

  const preventMinus = (e: any) => {
    if (e.code === 'Minus') {
      e.preventDefault()
    }
  }

  return (
    <Container className="cpi-item-container" isactive={cpi.isActive ? 'true' : 'false'}>
      <Box className="left">
        <CpiChip text={cpi.displayName} />
        <Typography className="title">{cpi.title}</Typography>
        <CpiActiveButton active={cpi.isActive} />
      </Box>
      <Box className="right">
        {hasEditRole && cpi.isActive ? (
          <TextField
            variant="outlined"
            name={cpi.name}
            required
            className={
              String(cpi.weight).length === 3 ? 'length3' : String(cpi.weight).length === 2 ? 'length2' : 'length1'
            }
            autoComplete="off"
            inputProps={{
              min: 1,
              value: !isNaN(cpi.weight) ? cpi.weight : 0,
              max: 100
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="start" className="percentage">
                  %
                </InputAdornment>
              ),
              onKeyPress: preventMinus
            }}
            value={cpi.weight}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              const val = parseInt(e.target.value, 10)
              if (val < 0 || val > 100) {
                e.preventDefault()
              } else {
                handleInput(e)
                setDiscardDisabled(false)
                setEdited(true)
              }
            }}
          />
        ) : (
          cpi.isActive && (
            <Box className="no-role-weights-wrapper">
              <Typography className="category-text">{cpi.weight}%</Typography>
            </Box>
          )
        )}
        {hasEditRole && framework.includes('custom') && (
          <IconButton className="close-button" onClick={() => handleDeleteCpi(cpi.displayName)}>
            <CloseIcon />
          </IconButton>
        )}
      </Box>
    </Container>
  )
}

export default CpiItem
