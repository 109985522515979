import { FC } from 'react'
import { Box, Typography } from '@mui/material'
import styled from '@emotion/styled/macro'
import { useNavigate } from 'react-router-dom'

/* Components */
import ThemeButton from '../../../components/buttons/primary/ThemeButton'
import { RightIconButton } from '../../../components/svg'

const Wrapper = styled(Box)`
  display: flex;
  align-items: center;
  padding: 16px;
  height: 52px;
  border-radius: 24px;
  background: linear-gradient(0deg, rgba(255, 169, 252, 0.11) 0%, rgba(255, 169, 252, 0.11) 100%), #1e1a1d;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.3), 0 1px 3px 1px rgba(0, 0, 0, 0.15);
  width: 100%;

  .text {
    color: #d0c3cc;
    font-family: Quicksand, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.1px;
    margin-right: 12px;
  }
`

interface Props {
  category: string
}

const ExploreCategoryCard: FC<Props> = ({ category }) => {
  const navigate = useNavigate()

  const handleClick = () => {
    navigate('/library', { state: { navigatedCategory: category } })
  }

  return (
    <Wrapper>
      <Typography className="text">{category}</Typography>
      <ThemeButton themevariant="tertiary" onClick={handleClick}>
        <>
          View All
          <RightIconButton />
        </>
      </ThemeButton>
    </Wrapper>
  )
}

export default ExploreCategoryCard
