import { Box } from '@mui/material'
import styled from '@emotion/styled/macro'

export const ChartContainer = styled(Box)`
  border-radius: 4px;
  position: relative;

  .bordered-wrapper {
    position: absolute;
    height: 116px;
    width: 158px;
    top: 0;
    right: 0;
    z-index: 10;
    pointer-events: none;
    display: none;
    border-radius: 4px;
    border: 1px solid #4d444b;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.3), 0 6px 2px rgba(0, 0, 0, 0.15);

    @media (min-width: 900px) {
      display: block;
    }

    @media (min-width: 1440px) {
      right: 2px;
      width: 185px;
      height: 124px;
    }

    @media (min-width: 1920px) {
      width: 185px;
      height: 124px;
    }
  }

  #category-chart-svg,
  .category-chart-svg {
    border-radius: 4px;
    z-index: 10;
  }

  .category-chart-tooltip {
    display: none;
    position: absolute;
    opacity: 1;
    padding: 16px;
    gap: 10px;
    border-radius: 8px;
    border: 1px solid #998d96;
    background: #4d444b;
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.3), 0 8px 12px 6px rgba(0, 0, 0, 0.15);
    color: #fff;
    font-family: Quicksand, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.1px;
    width: 240px;
    z-index: 100000 !important;

    .tooltip-performance,
    .tooltip-units {
      color: #fff;
      font-family: Quicksand, sans-serif;
      font-style: normal;
      font-weight: 400;
    }

    .tooltip-performance {
      font-size: 24px;
      line-height: 32px;
      margin: 0 0 4px 0;
    }

    .tooltip-units {
      font-size: 12px;
      line-height: 16px;
      letter-spacing: 0.4px;
      margin: 0;
    }

    .severity-text {
      text-transform: capitalize;
    }
  }
`
