const ToastSuccessIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.00003 16.1698L4.83003 11.9998L3.41003 13.4098L9.00003 18.9998L21 6.99984L19.59 5.58984L9.00003 16.1698Z"
      fill="#37003B"
    />
  </svg>
)

export default ToastSuccessIcon
