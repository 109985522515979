import * as d3 from 'd3'

interface Props {
  addEventButton: d3.Selection<SVGGElement, unknown, null, undefined>
  addEventButtonTooltip: d3.Selection<SVGGElement, unknown, null, undefined>
  height: number
}

export const appendButtonAddEvent = ({ addEventButton, addEventButtonTooltip, height }: Props) => {
  /* Add Event Button */
  addEventButton
    .append('circle')
    .attr('class', 'add-button-circle')
    .attr('cy', height + 1)
    .attr('r', 12)
    .attr('fill', '#372A36')
    .attr('stroke', '#D0C3CC')
    .attr('stroke-width', 2.33)
    .attr('filter', 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.30)) drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.15))')
    .attr('display', 'block')
  addEventButton
    .append('rect')
    .attr('class', 'add-button-rect-hor')
    .attr('y', height)
    .attr('width', 11)
    .attr('height', 2.33)
    .attr('fill', '#D0C3CC')
  addEventButton
    .append('rect')
    .attr('class', 'add-button-rect-ver')
    .attr('y', height - 4.5)
    .attr('width', 2.33)
    .attr('height', 11)
    .attr('fill', '#D0C3CC')

  /* Button Hover Tooltip */
  addEventButtonTooltip
    .append('rect')
    .attr('class', 'add-button-rect-tooltip-wrapper')
    .attr('y', height + 22)
    .attr('width', 131)
    .attr('height', 32)
    .attr('fill', '#FFA9FC')
    .attr('rx', 8)
  addEventButtonTooltip
    .append('text')
    .attr('class', 'add-button-rect-tooltip-text')
    .attr('y', height + 42)
    .style('fill', 'rgba(89, 0, 96, 1)')
    .style('font-size', '14px')
    .style('font-family', "'Quicksand', sans-serif")
    .style('font-weight', 600)
    .style('line-height', '20px')
    .style('letter-spacing', '0.1px')
    .text('Create New Label')
}
