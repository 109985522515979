import React from 'react'

const OnlyA = () => (
  <svg xmlns='http://www.w3.org/2000/svg' width='41' height='26' viewBox='0 0 41 26' fill='none'>
    <path
      d='M27.7729 0C26.1365 0 24.591 0.345455 23.1729 0.909091C28.2638 5.16364 28.6819 10.9636 28.6819 12.7273C28.6819 14.4909 28.2638 20.2909 23.1729 24.5455C24.591 25.1091 26.1365 25.4545 27.7729 25.4545C34.791 25.4545 40.5001 19.7455 40.5001 12.7273C40.5001 5.70909 34.791 0 27.7729 0Z'
      fill='white' />
    <path
      d='M20.5 23.1457C23.5124 23.1457 25.9545 18.4732 25.9545 12.7093C25.9545 6.94547 23.5124 2.27295 20.5 2.27295C17.4875 2.27295 15.0454 6.94547 15.0454 12.7093C15.0454 18.4732 17.4875 23.1457 20.5 23.1457Z'
      fill='white' />
    <path
      d='M12.3182 12.7273C12.3182 10.9636 12.7364 5.16364 17.8273 0.909091C16.4091 0.345455 14.8636 0 13.2273 0C6.20909 0 0.5 5.70909 0.5 12.7273C0.5 19.7455 6.20909 25.4545 13.2273 25.4545C14.8636 25.4545 16.4091 25.1091 17.8273 24.5455C12.7364 20.2909 12.3182 14.4909 12.3182 12.7273Z'
      fill='#FFA9FC' />
  </svg>
)

export default OnlyA