import { FC, useState, useRef, ReactElement } from 'react'
import { Button, Paper, ClickAwayListener, Grow, MenuItem, MenuList, ButtonGroupProps } from '@mui/material'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'

import { StyledSplitButton, StyledPopper } from './splitButton.styles'

interface SplitOption {
  text: string
  action: () => void
  disabled?: boolean
}

interface Props extends ButtonGroupProps {
  options: SplitOption[]
  buttonText: string
  buttonIcon: ReactElement
  activeOption?: number
  onClick: () => void
}

const SplitButton: FC<Props> = ({ options, onClick, buttonText, activeOption, buttonIcon, ...rest }) => {
  const [open, setOpen] = useState(false)
  const anchorRef = useRef<HTMLDivElement>(null)
  const [selectedIndex, setSelectedIndex] = useState(activeOption || 10)

  const handleClick = () => {
    onClick()
  }

  const handleMenuItemClick = (event: React.MouseEvent<HTMLLIElement, MouseEvent>, index: number) => {
    setSelectedIndex(index)
    setOpen(false)
  }

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen)
  }

  const handleClose = (event: Event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return
    }

    setOpen(false)
  }

  return (
    <>
      <StyledSplitButton
        variant="contained"
        ref={anchorRef}
        aria-label="Button group with a nested menu"
        {...rest}
        disabled={rest.disabled || false}
      >
        <Button onClick={handleClick} className="main-button">
          <>
            {buttonIcon}
            {buttonText}
          </>
        </Button>
        <Button
          className="dropdown-button"
          size="small"
          aria-controls={open ? 'split-button-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-label="select remove option"
          aria-haspopup="menu"
          onClick={handleToggle}
        >
          <ArrowDropDownIcon />
        </Button>
      </StyledSplitButton>
      <StyledPopper
        sx={{ zIndex: 1 }}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        placement="bottom-end"
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom'
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu" autoFocusItem>
                  {options.map((option, index) => (
                    <MenuItem
                      key={option.text}
                      disabled={option.disabled}
                      selected={index === selectedIndex}
                      onClick={(event) => {
                        option.action()
                        handleMenuItemClick(event, index)
                      }}
                    >
                      {option.text}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </StyledPopper>
    </>
  )
}

export default SplitButton
