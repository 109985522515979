import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { SecurityFinding } from '../../models/SecurityFinding'
import { RootState } from '../store'

export interface SecurityFindingState {
  securityFindings: { [page: string]: SecurityFinding[] }
  isLoading: boolean
  pageSize: number
  currentPage: number
  totalSecurityFindings: number
  crowdScore: number
  failed: number
  passed: number
}

const initialState: SecurityFindingState = {
  securityFindings: {},
  isLoading: false,
  pageSize: 20,
  currentPage: 1,
  totalSecurityFindings: -1,
  crowdScore: -1,
  failed: -1,
  passed: -1
}

export const securityFindingSlice = createSlice({
  name: 'security findings',
  initialState,
  reducers: {
    securityFindingsLoaded: (
      state: SecurityFindingState,
      action: PayloadAction<{ securityFindings: SecurityFinding[]; page: number }>
    ) => {
      state.securityFindings[action.payload.page.toString()] = action.payload.securityFindings
      state.currentPage = action.payload.page
    },
    securityFindingsAreLoading: (state: SecurityFindingState, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload
    },
    totalSecurityFindingsChanged: (state: SecurityFindingState, action: PayloadAction<number>) => {
      state.totalSecurityFindings = action.payload
    },
    crowdScoreLoaded: (state: SecurityFindingState, action: PayloadAction<number>) => {
      state.crowdScore = action.payload
    },
    securityFindingsSummaryLoaded: (
      state: SecurityFindingState,
      action: PayloadAction<{ securityControlTotals: any }>
    ) => {
      const sumOfTotalFindings = action.payload.securityControlTotals.reduce((accumulator: any, object: any) => {
        return accumulator + object.totalFindings
      }, 0)
      const sumOfFailedFindings = action.payload.securityControlTotals.reduce((accumulator: any, object: any) => {
        return accumulator + object.failedFindings
      }, 0)
      const sumOfPassedFindings = sumOfTotalFindings - sumOfFailedFindings
      state.failed = sumOfFailedFindings
      state.passed = sumOfPassedFindings
    }
  }
})

export const securityFindingsSelector = (state: RootState): SecurityFinding[] =>
  Object.entries(state.securityFindings.securityFindings)
    .sort((a, b) => parseInt(a[0]) - parseInt(b[0]))
    .map((element) => element[1])
    .flat()
    .filter((value, index, self) => {
      return index === self.map((e) => e.uuid).indexOf(value.uuid)
    })
export const crowdScoreSelector = (state: RootState) => state.securityFindings.crowdScore
export const securityFindingsLoadingSelector = (state: RootState) => state.securityFindings.isLoading
export const securityFindingsSummarySelector = (state: RootState) => [
  state.securityFindings.failed,
  state.securityFindings.passed
]
export const securityFindingPaginationSelector = (state: RootState) => [
  state.securityFindings.currentPage,
  state.securityFindings.pageSize,
  state.securityFindings.totalSecurityFindings
]

export const {
  securityFindingsLoaded,
  securityFindingsAreLoading,
  totalSecurityFindingsChanged,
  crowdScoreLoaded,
  securityFindingsSummaryLoaded
} = securityFindingSlice.actions

export default securityFindingSlice.reducer
