import { FC, FormEvent, useState } from 'react'
import { Box, Typography, CircularProgress, TextField } from '@mui/material'
import axios from '../../../../../lib/axios'

/* Utils */
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks'
import { closeModal, modalPropsSelector } from '../../../../../store/modals/modalSlice'

/* Components */
import { DeleteUsersContainer, Header, Content, Actions } from './deleteUsers.styles'
import { ThemeButton } from '../../../buttons'
import { DeleteIcon } from '../../../svg'
import { closeToast, openToast, ToastVariant } from '../../../../../store/toasts/toastSlice'

const DeleteUsers: FC = () => {
  const dispatch = useAppDispatch()
  const modalProps = useAppSelector(modalPropsSelector)
  const [value, setValue] = useState('')
  const [disabled, setDisabled] = useState(true)
  const [loading, setLoading] = useState(false)
  const [multiple] = useState(!!modalProps?.props?.users && modalProps.props.users.length > 1)

  const handleClose = () => {
    dispatch(closeModal())
  }

  const handleDelete = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    const selected = modalProps?.props?.users

    try {
      setDisabled(true)
      setLoading(true)

      let id = ''
      if (selected && selected.length > 0) {
        id = selected[0].id
      }

      let res

      if (multiple) {
        const ids = modalProps.props?.users?.map((n) => n.id)
        res = await axios.post('/api/v1/identity/users/delete', { users: ids })
      } else {
        const url = `/api/v1/identity/users/${id}`
        res = await axios.delete(url)
      }

      if (res.status === 200 || res.status === 204) {
        let message = 'The user account has been deleted.'
        if (multiple) {
          message = 'The user accounts have been deleted.'
        }
        if (modalProps?.props?.users && !multiple) {
          message = `The user account connected to ${modalProps.props.users[0].email} has been deleted.`
        }
        dispatch(
          openToast({
            variant: ToastVariant.success,
            props: {
              text: 'User Deleted',
              description: message
            }
          })
        )

        handleClose()
      }
    } catch (err) {
      dispatch(
        openToast({
          variant: ToastVariant.error,
          props: {
            text: 'Failed deleting user',
            description:
              (err as any).response?.data?.message && (err as any).response?.data?.message.includes('not authorized')
                ? 'Only Account Owners can delete users.'
                : (err as any).response?.data?.message || 'There was a problem while deleting user account.'
          }
        })
      )
    } finally {
      setDisabled(false)
      setLoading(false)
      if (modalProps?.props?.callback) {
        modalProps.props.callback()
      }
      setTimeout(() => {
        dispatch(closeToast())
      }, 5000)
    }
  }

  return (
    <DeleteUsersContainer>
      <form onSubmit={handleDelete}>
        <Header>
          <Typography className="title">
            Are you sure you want to delete {multiple ? `${modalProps?.props?.users?.length} users` : 'this user'}?
          </Typography>
        </Header>
        <Content>
          <Box className="input-wrapper">
            <TextField
              variant="outlined"
              label='Type "DELETE" to confirm'
              value={value}
              autoComplete="off"
              onChange={(e) => {
                if (e.target.value === 'DELETE') {
                  setDisabled(false)
                } else {
                  setDisabled(true)
                }
                setValue(e.target.value)
              }}
              fullWidth
            />
          </Box>
        </Content>
        <Actions>
          <ThemeButton themevariant="tertiary" onClick={handleClose}>
            Cancel
          </ThemeButton>
          <ThemeButton
            themevariant="primary"
            type="submit"
            disabled={disabled}
            endIcon={loading && <CircularProgress size="20px" />}
          >
            <>
              <DeleteIcon />
              Delete {multiple ? 'Users' : 'User'}
            </>
          </ThemeButton>
        </Actions>
      </form>
    </DeleteUsersContainer>
  )
}

export default DeleteUsers
