import { FC } from 'react'

import { FrameworkType, NistFunction } from '../../../models'

/* Components */
import { OnyxiaContainer, NistContainer } from './categoryChip.styles'
import { Typography } from '@mui/material'

interface Props {
  framework: FrameworkType
  categoryName?: string
  categoryId?: string
  toLink?: () => void
  chipColors?: {
    chipBgColor: string
    chipColor: string
  }
}

const CategoryChip: FC<Props> = ({ framework, chipColors, categoryName, categoryId, toLink }) => {
  const category = categoryId ? categoryId.charAt(0).toUpperCase() + categoryId.slice(1) : ''
  const nistFn = category.includes('Identify')
    ? NistFunction.Identify
    : category.includes('Respond')
    ? NistFunction.Respond
    : category.includes('Detect')
    ? NistFunction.Detect
    : category.includes('Protect')
    ? NistFunction.Protect
    : category.includes('Recover')
    ? NistFunction.Recover
    : category.includes('Govern')
    ? NistFunction.Govern
    : ''

  if (!categoryId || !categoryName) return null
  if (framework === FrameworkType.Custom) {
    return (
      <OnyxiaContainer
        className="category-chip category-chip__onyxia"
        bgcolor={chipColors?.chipBgColor}
        textcolor={chipColors?.chipColor}
      >
        <Typography>{categoryName}</Typography>
      </OnyxiaContainer>
    )
  }
  if (framework === FrameworkType.Onyxia) {
    return (
      <OnyxiaContainer className="category-chip category-chip__onyxia" bgcolor={chipColors?.chipBgColor}>
        <Typography>{categoryName}</Typography>
      </OnyxiaContainer>
    )
  }
  return (
    <NistContainer className="category-chip category-chip__nist" fn={nistFn} onClick={() => (toLink ? toLink() : null)}>
      <img src="/nist-category.svg" alt="" width={24} height={24} />
      <Typography>{categoryName || 'N/A'}</Typography>
    </NistContainer>
  )
}

export default CategoryChip
