import { FC } from 'react'
import { Box, Button } from '@mui/material'
import { colors } from '../../../../../theme/'

interface Props {
  handleCancel: () => void
  handleBack: () => void
  activeStep: number
  isSubmitting: boolean
  isValid: boolean
  hasSelectedProjects: boolean
}

const JiraIntegrationActions: FC<Props> = ({
  handleCancel,
  handleBack,
  activeStep,
  isSubmitting,
  isValid,
  hasSelectedProjects
}) => (
  <Box
    sx={{
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center'
    }}
  >
    <Button
      onClick={handleCancel}
      sx={{
        color: colors.rawSienneCore,
        border: `1px solid ${colors.rawSienneCore}`,
        borderRadius: '4px',
        textTransform: 'capitalize',
        fontSize: '16px',
        lineHeight: '24px',
        fontWeight: 700,
        padding: '6px 16px'
      }}
    >
      Cancel
    </Button>
    <>
      <Button
        onClick={handleBack}
        disabled={activeStep === 0}
        sx={{
          color: colors.purpleLight,
          fontSize: '16px',
          lineHeight: '24px',
          fontWeight: 700,
          background: 'rgba(0, 0, 0, 0.06)',
          borderRadius: '4px',
          textTransform: 'capitalize',
          padding: '6px 16px',
          margin: '0 16px 0 33px'
        }}
      >
        Back
      </Button>
      <Button
        type="submit"
        sx={{
          color: colors.purpleLight,
          fontSize: '16px',
          lineHeight: '24px',
          fontWeight: 700,
          background: 'rgba(0, 0, 0, 0.06)',
          borderRadius: '4px',
          textTransform: 'capitalize',
          padding: '6px 16px'
        }}
        disabled={activeStep === 1 ? !hasSelectedProjects : !isValid || isSubmitting}
      >
        {activeStep < 2 ? 'Next' : 'Finish'}
      </Button>
    </>
  </Box>
)

export default JiraIntegrationActions
