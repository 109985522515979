import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Vendor } from '../../models/Vendor'
import { RootState } from '../store'

export interface VendorState {
  /**  Lists of vendors are stored indexed by page numbers.
   * {[page]: Vendors[]}
   * {1: [{vendor_id: afafa, name:"avava", } ...] }
   * Allows us to fetch pages out of order as long as we have meta data
   */
  vendors: { [page: string]: Vendor[] }
  isLoading: boolean
  query: string
  pageSize: number
  selectedProducts: string[]
  currentPage: number
  totalVendors: number
  selected?: Vendor
  filterSelectedVendorsOnly: boolean
}

const initialState: VendorState = {
  vendors: {},
  query: '',
  isLoading: false,
  selectedProducts: [],
  pageSize: 10,
  currentPage: 1,
  totalVendors: -1,
  filterSelectedVendorsOnly: false
}

export const vendorSlice = createSlice({
  name: 'vendors',
  initialState,
  reducers: {
    vendorsLoaded: (state: VendorState, action: PayloadAction<{ vendors: Vendor[]; page: number }>) => {
      // We store vendors segmented by pages
      state.vendors[action.payload.page.toString()] = action.payload.vendors
      state.currentPage = action.payload.page
    },
    vendorsAreLoading: (state: VendorState, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload
    },
    selectedProductsLoaded: (state: VendorState, action: PayloadAction<string[]>) => {
      state.selectedProducts = action.payload
    },
    totalVendorsChanged: (state: VendorState, action: PayloadAction<number>) => {
      state.totalVendors = action.payload
    },
    queryChanged: (state: VendorState, action: PayloadAction<string>) => {
      // Resets to base query.  Another way of doing this would be to scope vendors by query and page number.
      state.totalVendors = -1
      state.query = action.payload
      state.vendors = {}
      state.currentPage = 1
      state.selected = undefined
    },

    setSelectedVendor: (state: VendorState, action: PayloadAction<Vendor | undefined>) => {
      state.selected = action.payload
    },
    setFilterSelectedVendorsOnly: (state: VendorState, action: PayloadAction<boolean>) => {
      if (state.filterSelectedVendorsOnly !== action.payload) {
        state.filterSelectedVendorsOnly = action.payload
        state.totalVendors = -1
        state.vendors = {}
        state.currentPage = 1
        state.selected = undefined
      }
    }
  }
})

/**
 * Extract vendors from the store, sorts them by page number and flattens the array and makes everything unique
 * @param state RootState
 */
export const vendorsSelector = (state: RootState) =>
  Object.entries(state.vendors.vendors)
    .sort((a, b) => parseInt(a[0]) - parseInt(b[0]))
    .map((element) => element[1])
    .flat()
    .filter((value, index, self) => {
      return index === self.map((e) => e.uuid).indexOf(value.uuid)
    })

/**
 * Returns the page and page size
 */
export const vendorPaginationSelector = (state: RootState) => [
  state.vendors.currentPage,
  state.vendors.pageSize,
  state.vendors.totalVendors
]

export const vendorFiltersSelector = (state: RootState) => ({
  query: state.vendors.query,
  selectedVendorsOnly: state.vendors.filterSelectedVendorsOnly
})

export const selectedVendorSelector = (state: RootState) => state.vendors.selected

export const selectedProductsSelector = (state: RootState) => state.vendors.selectedProducts

export const {
  vendorsLoaded,
  vendorsAreLoading,
  selectedProductsLoaded,
  totalVendorsChanged,
  queryChanged,
  setSelectedVendor,
  setFilterSelectedVendorsOnly
} = vendorSlice.actions

export default vendorSlice.reducer
