import { FC } from 'react'
import { Box, Typography } from '@mui/material'
import { Warning, TaskAlt } from '@mui/icons-material'
import styled from '@emotion/styled/macro'

const Wrapper = styled(Box)<{ variant: 'success' | 'error' }>`
  width: 100%;
  height: 40px;
  border: ${({ variant }) => `1px solid ${variant === 'success' ? '#3E8B56' : '#E85541'}`};
  background: ${({ variant }) => (variant === 'success' ? '#C6FCCB' : '#FDAEA3')};
  display: flex;
  align-items: center;
`

interface Props {
  variant: 'success' | 'error'
  message?: string
  sx?: Record<string, unknown>
}

const Toast: FC<Props> = ({ variant, message, sx }) => (
  <Wrapper variant={variant} sx={sx}>
    {variant === 'error' ? (
      <Warning sx={{ color: '#E85541', fontSize: '16px', mx: '4px' }} />
    ) : (
      <TaskAlt sx={{ color: '#3E8B56', fontSize: '16px', mx: '4px' }} />
    )}
    {variant === 'error' && (
      <Typography variant="body1" sx={{ fontSize: '14px', lineHeight: '20px', fontWeight: 300, color: '#423D5C' }}>
        <strong>Test Failed</strong> {message ?? 'Something went wrong, please make sure inputs are accurate.'}
      </Typography>
    )}
    {variant === 'success' && (
      <Typography variant="body1" sx={{ fontSize: '14px', lineHeight: '20px', fontWeight: 300, color: '#423D5C' }}>
        <strong>Success:</strong> {message ?? 'The test worked as expected.'}
      </Typography>
    )}
  </Wrapper>
)

export default Toast
