import * as d3 from 'd3'
import {
  addStartEndWeekly,
  formatPerformanceUnit,
  formatXLabel,
  groupByWeeks
} from '../../../../utils'
import { ChartData, CPIFrequency, CPITimePeriod } from '../../../../../../../models'
import { styledXAxisGroup } from '../chart-components-shared'

interface Props {
  svg: d3.Selection<SVGSVGElement | null, unknown, null, undefined>
  data: ChartData
  xScaleGroup: d3.ScaleBand<string>
  yScale: d3.ScaleLinear<number, number, never>
  selectedTimePeriod: CPITimePeriod
  selectedFrequency: CPIFrequency
  filteredData: any[]
  dataStartsAt: string
  height: number
  healthUnit: string
  noData: boolean
  oc?: boolean
}

/*
 * XY-Axis
 * */
export const appendXYAxisOC = ({
  svg,
  data,
  xScaleGroup,
  yScale,
  selectedTimePeriod,
  selectedFrequency,
  filteredData,
  dataStartsAt,
  height,
  healthUnit,
  noData
}: Props) => {
  /*
   * X-Axis
   * */
  const xAxis = d3.axisBottom(xScaleGroup).tickFormat((date) => {
    if (selectedTimePeriod === CPITimePeriod.Week) {
      return formatXLabel({ date, selectedTimePeriod, selectedFrequency, filteredData, dataStartsAt })
    }
    if (selectedTimePeriod === CPITimePeriod.Month) {
      const groupedData = groupByWeeks(data)
      return formatXLabel({
        date,
        selectedTimePeriod,
        selectedFrequency,
        filteredData: groupedData.values,
        dataStartsAt
      })
    }

    const groupedByWeeks = addStartEndWeekly(data)
    return formatXLabel({
      date,
      selectedTimePeriod,
      selectedFrequency,
      filteredData: groupedByWeeks.values,
      dataStartsAt
    })
  })

  const existingXAxis = svg.selectAll('.x-axis')
  if (!existingXAxis.empty()) existingXAxis.remove()
  const xAxisGroup = svg
    .append('g')
    .attr('class', 'x-axis')
    .attr('color', '#fff')
    .attr('transform', `translate(0, ${height + 19})`)
    .call(xAxis)

  styledXAxisGroup(xAxisGroup)
  xAxisGroup.selectAll('path').attr('display', 'none')
  const xAxisTick = svg.selectAll('.tick')
  xAxisTick.selectAll('line').attr('display', 'none')

  /*
   * Y-Axis
   * */
  const yAxis = d3.axisRight(yScale).tickFormat((d, i) => {
    const unit = formatPerformanceUnit(healthUnit, true)

    return i === 0 ? '' : `${d}${unit}`
  })

  const existingYAxis = svg.selectAll('.y-axis')
  if (!existingYAxis.empty()) existingYAxis.remove()
  if (!noData) {
    const yAxisGroup = svg.append('g').attr('class', 'y-axis').attr('color', 'none').call(yAxis)
    yAxisGroup
      .selectAll('text')
      .attr('dx', '3px')
      .attr('dy', '18px')
      .attr('color', '#fff')
      .attr('font-size', '14px')
      .attr('font-weight', '500')
      .attr('letter-spacing', '0.1px')
      .attr('line-height', '20px')
      .attr('font-family', "'Quicksand', sans-serif")
    yAxisGroup.selectAll('path').attr('display', 'none')
    yAxisGroup.selectAll('line').attr('display', 'none')
  }
}
