import { Box } from '@mui/material'
import styled from '@emotion/styled/macro'

export const Wrapper = styled(Box)<{ is_coming_soon: string }>`
  width: 256px;
  min-width: 256px;
  height: 492px;
  position: relative;

  @media (min-width: 1440px) {
    width: 300px;
    min-width: 300px;
  }

  @media (min-width: 1920px) {
    width: 317px;
    min-width: 317px;
  }

  .coming-soon-wrapper {
    position: absolute;
    top: 7px;
    left: 123px;

    .chip-title {
      white-space: nowrap;
      text-transform: none;
    }
  }

  .new-wrapper {
    position: absolute;
    top: 0px;
    left: 76px;

    .chip-title {
      white-space: nowrap;
      text-transform: none;
    }
  }
`

export const Background = styled(Box)<{ backgroundUrl: string }>`
  width: 100%;
  height: 256px;
  background: ${({ backgroundUrl }) => `url("${backgroundUrl}") no-repeat`};
  background-size: cover;
  background-position: center center;
  border-radius: 16px 16px 0px 0px;
`

export const Content = styled(Box)`
  width: 100%;
  height: auto;
  min-height: 236px;
  padding: 24px 24px 32px;
  gap: 8px;
  border-radius: 0 0 16px 16px;
  background: linear-gradient(0deg, rgba(255, 169, 252, 0.05) 0%, rgba(255, 169, 252, 0.05) 100%), #1e1a1d;
  display: flex;
  flex-direction: column;

  .title {
    color: #e9e0e4;
    font-family: Quicksand, sans-serif;
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
  }

  .text {
    color: #d0c3cc;
    font-family: Quicksand, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.25px;
    flex-grow: 1;
    margin-bottom: 8px;
  }
`
