import { FC, PropsWithChildren } from 'react'
import { ButtonProps } from '@mui/material'
import { Button } from './primaryButton.styles'

interface Props extends ButtonProps {
  buttontype?: string
}

const PrimaryButton: FC<PropsWithChildren<Props>> = ({ buttontype, children, ...props }) => (
  <Button buttontype={buttontype} {...props}>
    {children}
  </Button>
)

export default PrimaryButton
