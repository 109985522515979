import { FC, Dispatch, SetStateAction } from 'react'
import { Box, ClickAwayListener, IconButton, Typography } from '@mui/material'

/* Components */
import { LibraryDetailsInfoTooltip } from '../../../components/LibraryDetailsTooltip'
import { ThemeButton } from '../../../../../../components/buttons'
import { LibraryTooltipActiveIcon, LibraryTooltipIcon } from '../../../../../../components/svg'

interface Props {
  setEmptyInfoTooltipOpen: Dispatch<SetStateAction<boolean>>
  emptyInfoTooltipOpen: boolean
  handleEmptyInfoClick: () => void
}

const NoPerformanceData: FC<Props> = ({ setEmptyInfoTooltipOpen, emptyInfoTooltipOpen, handleEmptyInfoClick }) => (
  <Box display="flex" alignItems="end">
    <Typography className="status empty">No Data</Typography>
    <ClickAwayListener onClickAway={() => setEmptyInfoTooltipOpen(false)}>
      <div>
        <LibraryDetailsInfoTooltip
          PopperProps={popperProps}
          open={emptyInfoTooltipOpen}
          disableFocusListener
          disableHoverListener
          disableTouchListener
          onClose={() => setEmptyInfoTooltipOpen(false)}
          title={
            <>
              <Box className="tooltip-wrapper">
                <Typography className="tooltip-text">
                  No data was detected within your selected criteria, try modifying or expanding your filters to broaden
                  the scope.
                  <br />
                  <br />
                  If the issue persists there might be an issue with the data source products you're using. Please
                  ensure that the data source you're relying on is functioning properly.
                  <br />
                  <br />
                  If you need further assistance, please don't hesitate to contact our support team.
                </Typography>

                <Box display="flex" alignItems="center" justifyContent="space-between">
                  <ThemeButton
                    themevariant="secondary"
                    sx={{ marginTop: '10px' }}
                    onClick={() => setEmptyInfoTooltipOpen(false)}
                  >
                    Close
                  </ThemeButton>
                  <ThemeButton themevariant="secondary" sx={{ marginTop: '10px' }} href={`mailto:support@onyxia.io`}>
                    Contact Support
                  </ThemeButton>
                </Box>
              </Box>
            </>
          }
        >
          <IconButton className="tooltip-icon" onClick={handleEmptyInfoClick}>
            {emptyInfoTooltipOpen ? <LibraryTooltipActiveIcon /> : <LibraryTooltipIcon />}
          </IconButton>
        </LibraryDetailsInfoTooltip>
      </div>
    </ClickAwayListener>
  </Box>
)

const popperProps = {
  disablePortal: true,
  sx: {
    marginTop: '-10px !important'
  }
}

export default NoPerformanceData
