import { Button } from '@mui/material'
import styled from '@emotion/styled/macro'
import type { ThemeVariant } from './ThemeButton'

export const StyledButton = styled(Button)<{
  themevariant?: ThemeVariant
  noActive?: boolean | undefined
  font?: string
}>`
  border: 1px solid #ffa9fc;
  border-radius: 100px;
  background: #ffa9fc;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 22px 10px 14px;
  gap: 8px;
  font-family: 'Quicksand', sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.1px;
  color: #590060;
  height: 40px;
  text-transform: none;

  &:hover {
    background: rgba(208, 188, 255, 0.12);
    color: #ffd6f9;
  }

  ${({ font }) =>
    font === 'big' &&
    `
     font-size: 16px;
     font-style: normal;
     font-weight: 510;
     line-height: normal;
  `};

  ${({ noActive }) =>
    !noActive &&
    `
      &:active,
      &:focus {
        background: rgba(208, 188, 255, 0.12);
        color: #ffd6f9;
      }
  `};

  ${({ themevariant }) =>
    themevariant &&
    themevariant === 'primary' &&
    `
      color: #590060;
      text-align: center;
      font-family: Quicksand, sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 510;
      line-height: normal;

      svg {
        path {
          fill: #590060;
        }
      }
      
      &:hover {
        background: #EC85EC;
        color: #590060;
        border-color: #781A7E;
        box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.15), 0px 1px 2px 0px rgba(0, 0, 0, 0.30);
        
        svg {
          path {
            fill: #590060;
          }
        }
      }
      
      &:active, &:focus {
        background: #FFA9FC;
        color: #590060;
        border-color: #FFF;
        
        svg {
          path {
            fill: #590060;
          }
        }
      }
      
      &.Mui-disabled {
        color: #7F747C !important;
        background: #483646;
        border: none;
        
        svg {
          path {
            fill: #7F747C;
          }
        }
      }
  `};

  ${({ themevariant }) =>
    themevariant &&
    themevariant === 'secondary' &&
    `
      font-size: 16px;
      font-weight: 510;
      padding: 10px 16px;
      height: 40px;
      color: #FFA9FC;
      background: transparent;
      border-color: #998d96;
      
      svg {
        path {
          fill: #FFA9FC;
        }
      }
      
      &:hover {
        border: 1px solid #998D96;
        background: #544152;
        color: #FFA9FC;
        
        svg {
          path {
            fill: #FFA9FC;
         }
        }
      }
      
      &:active, &:focus {
        background: transparent;
        color: #FFA9FC;
        border: 1px solid #fff;
        
        svg {
          path {
            fill: #FFA9FC;
         }
        }
      }
      
      &:active {
        background: #590060;
        color: #fff;
        
        svg {
          path {
            fill: #fff;
          }
        }
      }
      
      &:disabled {
        border: none;
        background: #483646;
        color: #7F747C;
        
        svg {
          path {
            fill: #7F747C;
          }
        }
      }
  `};

  ${({ themevariant }) =>
    themevariant &&
    themevariant === 'tertiary' &&
    `
      color: #FFA9FC;
      text-align: center;
      font-family: Quicksand, sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 510;
      line-height: normal;
      background: transparent;
      border: none;
      
      svg {
        path {
          fill: #FFA9FC;
        }
      }
      
      &:hover {
        background: #544152;
        color: #FFA9FC;
        
        svg {
          path {
            fill: #FFA9FC;
          }
        }
      }
      
      &:active {
        background: #590060;
        color: #fff;
      }
      
      &:focus {
        background: transparent;
        color: #FFA9FC;
        border: 1px solid #FFF;
      }
      
      &:disabled {
        background: #483646;
        color: #7F747C;
        
        svg {
          path {
            fill: #7F747C;
          }
        }
      }
  `};

  ${({ themevariant }) =>
    themevariant &&
    themevariant === 'quaternary' &&
    `
      color: #FFA9FC;
      background: linear-gradient(0deg, rgba(255, 169, 252, 0.05), rgba(255, 169, 252, 0.05)), #1E1A1D;
      border: none;
      
      svg {
        path {
          fill: #FFA9FC;
        }
      }
      
      &.Mui-disabled {
        background: rgba(227, 227, 227, 0.12);
        color: rgba(233, 224, 228, 0.38);

        svg {
          path {
            fill: #E9E0E4;
            opacity: 0.38;
          }
        }
      }
      
      &:hover {
        background: rgba(208, 188, 255, 0.08);
        color: #FFA9FC;
        
        svg {
          path {
            fill: #FFA9FC;
          }
        }
      }
      
      &:active, &:focus {
        background: rgba(208, 188, 255, 0.12);
        color: #FFA9FC;
      }
  `};

  ${({ themevariant }) =>
    themevariant &&
    themevariant === 'ghost' &&
    `
      color: #590060;
      background: #FFA9FC;
      font-family: Quicksand, sans-serif;
      font-size: 16px;
      font-weight: 510;
      border: none;
      
      svg {
        path {
          fill: #590060;
        }
      }
      
     &:hover {
      background: #EC85EC;
      color: #590060;
      border: none;
     }
     
     &:focus {
      border: 1px solid #fff;
      background: #FFA9FC;
     }
     
     &:active {
      background: #590060;
      color: #fff;
     }
     
     &:disabled {
      background: #483646;
      color: #7F747C;
      
      svg {
        path {
          fill: #7F747C;
        }
      }
     }
  `};
`
