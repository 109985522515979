import React from 'react'
import { Wrapper } from './StubOverPerformance.styles'
import { Box, Typography } from '@mui/material'

const StubOverPerformance = () => {
  return (
    <Wrapper>
      <Box className="title-wrapper">
        <img src="/title-vector.svg" alt="title-vector" />
        <Typography className="chart-title">Overall Performance Score</Typography>
      </Box>

      <Box className="circle">
        <svg xmlns="http://www.w3.org/2000/svg" width="218" height="218" viewBox="0 0 218 218" fill="none">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M108.755 217.468C168.819 217.468 217.51 168.786 217.51 108.734C217.51 48.682 168.819 0 108.755 0C48.6913 0 0 48.682 0 108.734C0 168.786 48.6913 217.468 108.755 217.468ZM109.395 191.884C155.679 191.884 193.2 154.37 193.2 108.094C193.2 61.8188 155.679 24.3051 109.395 24.3051C63.1101 24.3051 25.5892 61.8188 25.5892 108.094C25.5892 154.37 63.1101 191.884 109.395 191.884Z"
            fill="#573F56"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M108.755 217.511C168.818 217.511 217.51 168.829 217.51 108.777C217.51 48.7249 168.818 0.0429688 108.755 0.0429688C48.6912 0.0429688 0 48.7249 0 108.777C0 168.829 48.6912 217.511 108.755 217.511ZM109.397 191.926C155.681 191.926 193.202 154.413 193.202 108.137C193.202 61.8617 155.681 24.348 109.397 24.348C63.1122 24.348 25.5914 61.8617 25.5914 108.137C25.5914 154.413 63.1122 191.926 109.397 191.926Z"
            fill="url(#paint0_linear_27_18021)"
          />
          <defs>
            <linearGradient
              id="paint0_linear_27_18021"
              x1="197.736"
              y1="5.74347e-05"
              x2="29.1112"
              y2="5.83621e-05"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#352934" />
              <stop offset="0.81559" stopColor="#4B374A" stopOpacity="0" />
            </linearGradient>
          </defs>
        </svg>
      </Box>
    </Wrapper>
  )
}

export default StubOverPerformance
