import React, { FC, SVGProps } from 'react'

type Props = SVGProps<SVGSVGElement>

const ArrowUpTooltip: FC<Props> = ({ className, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="15"
    height="12"
    viewBox="0 0 15 12"
    fill="none"
    className={`${className} arrow-up`}
    {...props}
  >
    <path d="M7.49998 0L14.4282 12H0.571777L7.49998 0Z" fill="#998D96" />
  </svg>
)

export default ArrowUpTooltip
