import { FC, useCallback, useState } from 'react'
import { Controlled as ControlledZoom } from 'react-medium-image-zoom'
import './EnlargedImageStyles.css'
import { ManifestIntegration } from '../../../../../models';

interface Props {
  index: number
  integration: ManifestIntegration
}

const EnlargedImage: FC<Props> = ({
    index,
    integration
  }) => {
    const [isZoomed, setIsZoomed] = useState(false)

    const handleZoomChange = useCallback((shouldZoom: boolean) => {
        setIsZoomed(shouldZoom)
    }, [])

    return (
        <ControlledZoom isZoomed={isZoomed} onZoomChange={handleZoomChange}>
            <img
            alt={`step_${index}_image`}
            src={`/${integration.documentation.path}_step_${index}.png`}
            width="100px"
            />
        </ControlledZoom>
    )
}

export default EnlargedImage