import styled from '@emotion/styled/macro'
import { Box, Breadcrumbs } from '@mui/material'

export const Container = styled(Box)`
  width: 100%;
  padding-bottom: 60px;

  .header-actions {
    display: flex;
    align-items: center;

    .favorite-icon {
      margin-right: 34px;

      .MuiButtonBase-root {
        &:hover,
        &:focus,
        &:active {
          background: rgba(202, 196, 208, 0.08);
        }
      }
    }
  }

  .header-section {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding: 0px 24px 24px 24px;

    .title-wrapper {
      &.title-wrapper {
        .header-title {
          color: #fff;
          font-family: 'Quicksand', sans-serif;
          font-size: 32px;
          font-style: normal;
          font-weight: 400;
          line-height: 40px;
          text-transform: capitalize;
          margin-top: 24px;
          margin-bottom: 8px;
        }

        .header-subtitle {
          font-family: 'Quicksand', sans-serif;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px; /* 133.333% */
          letter-spacing: 0.25px;
          color: #e9e0e4;
        }
      }
    }
  }

  .header-actions {
    display: flex;
    align-items: center;
    gap: 32px;

    .revert-section {
      display: inline-flex;
      align-items: center;
      gap: 8px;
      cursor: pointer;

      .title {
        color: #ff8a80;
        font-family: 'Quicksand', sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0.1px;
      }
    }

    .panel-block {
      display: flex;
      align-items: center;
      gap: 8px;

      .text {
        color: #fff;
        font-family: 'Quicksand', sans-serif;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 16px;
        letter-spacing: 0.5px;
      }
    }

    .small-screen {
      .text {
        max-width: 102px;
        text-align: end;
      }

      .long-text {
        max-width: 150px;
      }
    }
  }
`

export const HeaderBreadcrumbs = styled(Breadcrumbs)`
  .MuiLink-root,
  .current-breadcrumb {
    font-family: 'Quicksand', sans-serif;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.1px;
    text-decoration: none;
  }

  .MuiLink-root {
    color: #fff;
  }

  .current-breadcrumb {
    color: #ffa9fc;
  }

  .MuiBreadcrumbs-separator {
    color: #debdd9;
  }
`

export const ContentWrapperMain = styled(Box)`
  margin-top: 40px;
`

export const FrameworkSection = styled(Box)`
  padding: 24px;
  border-radius: 16px;
  width: 748px;
  margin: 0 auto;
  border: 1px solid #4d444b;
  background: linear-gradient(0deg, rgba(255, 169, 252, 0.08) 0%, rgba(255, 169, 252, 0.08) 100%);

  .info-message {
    margin-bottom: 24px;
  }

  .title {
    color: #e9e0e4;
    font-family: 'Quicksand', sans-serif;
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
    margin-bottom: 18px;
  }

  .sub-title {
    color: #d0c3cc;
    font-family: 'Quicksand', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.25px;
    margin-bottom: 16px;
  }

  .framework {
    border-radius: 16px;
    background: linear-gradient(0deg, rgba(255, 169, 252, 0.11) 0%, rgba(255, 169, 252, 0.11) 100%);
    padding-left: 12px;
    padding-right: 16px;
    padding-top: 12px;
    padding-bottom: 12px;
    display: flex;
    align-items: center;
    gap: 8px;

    .text {
      color: #e9e0e4;
      font-family: 'Quicksand', sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0.5px;
    }
  }
`
