import { FC, ReactNode, MouseEvent, SyntheticEvent, useState, useEffect } from 'react'
import { Form, Formik, FormikValues } from 'formik'
import { DialogContent, DialogActions, Typography } from '@mui/material'

/* Utils */
import axios from '../../../../../lib/axios'
import { notification } from '../../../../../store/notifications/notificationSlice'
import { useAppDispatch } from '../../../../../store/hooks'
import { fetchConfiguredIntegrations } from '../../../../../store/integrations/actions'
import { closeModal } from '../../../../../store/modals/modalSlice'
import {
  FormValues,
  assistedSetupSchema,
  assistedStep1Schema,
  assistedStep2Schema,
  manualStep1Schema,
  manualStep2Schema,
  manualSetupSchema,
  formDefaultValues,
  SentinelIntegrationType
} from './sentinelIntegration.constants'

/* Components */
import { Tabs, Tab } from './sentinelIntegration.styles'
import { StepperActiveIcon, StepperInactiveIcon, StepperCompletedIcon } from '../../../svg'
import SentinelActions from './SentinelActions'
import SetupTypePicker from './SetupTypePicker'
import SentinelIntegrationStep1Assisted from './SentinelIntegrationStep1Assisted'
import SentinelIntegrationStep1Manual from './SentinelIntegrationStep1Manual'
import SentinelIntegrationStep2Assisted from './SentinelIntegrationStep2Assisted'
import SentinelIntegrationStep2Manual from './SentinelIntegrationStep2Manual'
import SentinelIntegrationStep3 from './SentinelIntegrationStep3'

interface TabPanelProps {
  children?: ReactNode
  index: number
  value: number
}

const SentinelIntegration: FC = () => {
  const dispatch = useAppDispatch()
  const [activeStep, setActiveStep] = useState(0)
  const [showPassword, setShowPassword] = useState(false)
  const [toastMessage, setToastMessage] = useState('')
  const [tabValue, setTabValue] = useState(0)
  const [setupSuccessful, setSetupSuccessful] = useState(false)
  const [showToast, setShowToast] = useState(false)
  const [setupType, setSetupType] = useState<number>(SentinelIntegrationType.assisted)
  const [schema, setSchema] = useState<any>(assistedSetupSchema)

  useEffect(() => {
    if (setupType === SentinelIntegrationType.assisted) {
      if (activeStep === 0) {
        setSchema(assistedStep1Schema)
      } else if (activeStep === 1) {
        setSchema(assistedStep2Schema)
      } else {
        setSchema(assistedSetupSchema)
      }
    } else if (setupType === SentinelIntegrationType.manual) {
      if (activeStep === 0) {
        setSchema(manualStep1Schema)
      } else if (activeStep === 1) {
        setSchema(manualStep2Schema)
      } else {
        setSchema(manualSetupSchema)
      }
    }
  }, [setupType, activeStep])

  const handleChangeTabValue = (event: SyntheticEvent, newValue: number) => {
    setTabValue(newValue)
  }

  const handleCancel = () => {
    dispatch(closeModal())
    dispatch(
      notification({
        open: true,
        variant: 'warning',
        message: 'Changes were not saved.'
      })
    )
  }

  const handleSaveAndExit = (values: FormikValues) => {
    localStorage.setItem('SENTINEL_FORM_VALUES', JSON.stringify(values))
    dispatch(closeModal())
    dispatch(
      notification({
        open: true,
        variant: 'warning',
        message: 'Changes were saved.'
      })
    )
  }

  const handleNext = () => {
    setActiveStep(activeStep + 1)
    setTabValue(tabValue + 1)
  }

  const handleFinish = () => {
    localStorage.removeItem('SENTINEL_FORM_VALUES')
    dispatch(closeModal())
  }

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
    setTabValue((prevTabValue) => prevTabValue - 1)
  }

  const onSubmit = async (values: FormValues) => {
    const payload = {
      config: {
        resource_group: values.resource_group,
        client_id: values.secret,
        tenant_id: values.tenant_id,
        secret: values.secret,
        subscription_id: 'sub_id',
        log_analytics_workspace: 'log'
      }
    }

    try {
      const res = await axios.post('/api/v3/integrations/mssentinel/config', payload)

      if (res.data.errors) {
        setToastMessage(typeof res.data.errors === 'string' ? res.data.errors : '')
        setSetupSuccessful(false)
        return
      }

      if (res.status === 201) {
        setSetupSuccessful(true)
        setToastMessage('You can click “Complete Setup” to exit this wizard.')
        localStorage.removeItem('SENTINEL_FORM_VALUES')
        dispatch(fetchConfiguredIntegrations())
      } else {
        setSetupSuccessful(false)
      }
    } catch (err) {
      const message = (err as any).response.data.message
      setToastMessage(message)
      setShowToast(true)
      setSetupSuccessful(false)
    } finally {
      setShowToast(true)
    }
  }

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword as boolean)
  }

  const handleMouseDownPassword = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
  }

  const handleChangeSetupType = (n: number) => {
    setSetupType(n)
  }

  return (
    <Formik
      initialValues={
        typeof window !== 'undefined' && !!localStorage.getItem('SENTINEL_FORM_VALUES')
          ? JSON.parse(localStorage.getItem('SENTINEL_FORM_VALUES') || '')
          : formDefaultValues
      }
      validationSchema={schema}
      onSubmit={onSubmit}
      validateOnMount
    >
      {({ isSubmitting, isValid, values }) => (
        <Form autoComplete="off">
          <Tabs value={tabValue} onChange={handleChangeTabValue} aria-label="sentinel tabs">
            <Tab {...a11yProps(0)} active={tabValue === 0}>
              {activeStep > 0 ? <StepperCompletedIcon /> : <StepperActiveIcon />}
              <Typography>Workspace</Typography>
              <Typography>Enter your Azure Workspace information.</Typography>
            </Tab>
            <Tab {...a11yProps(1)} active={tabValue === 1} completed={tabValue > 1}>
              {activeStep < 1 ? (
                <StepperInactiveIcon />
              ) : activeStep > 1 ? (
                <StepperCompletedIcon />
              ) : (
                <StepperActiveIcon />
              )}
              <Typography>Credentials</Typography>
              <Typography>Provide the credentials for your workspace.</Typography>
            </Tab>
            <Tab {...a11yProps(2)} active={tabValue === 2}>
              {activeStep === 0 ? <StepperInactiveIcon /> : <StepperActiveIcon />}
              <Typography>Test</Typography>
              <Typography>Confirm workspace credentials.</Typography>
            </Tab>
          </Tabs>
          <DialogContent sx={{ p: 0 }}>
            <TabPanel value={tabValue} index={0}>
              <SetupTypePicker value={setupType} handleChangeSetupType={handleChangeSetupType} />
              {setupType === 0 ? (
                <SentinelIntegrationStep1Assisted activeStep={activeStep} />
              ) : (
                <SentinelIntegrationStep1Manual activeStep={activeStep} />
              )}
            </TabPanel>
            <TabPanel value={tabValue} index={1}>
              <SetupTypePicker value={setupType} handleChangeSetupType={handleChangeSetupType} />
              {setupType === 0 ? (
                <SentinelIntegrationStep2Assisted
                  showPassword={showPassword}
                  handleClickShowPassword={handleClickShowPassword}
                  handleMouseDownPassword={handleMouseDownPassword}
                  activeStep={activeStep}
                />
              ) : (
                <SentinelIntegrationStep2Manual
                  showPassword={showPassword}
                  handleClickShowPassword={handleClickShowPassword}
                  handleMouseDownPassword={handleMouseDownPassword}
                  activeStep={activeStep}
                />
              )}
            </TabPanel>
            <TabPanel value={tabValue} index={2}>
              <SentinelIntegrationStep3
                setupSuccessful={setupSuccessful}
                toastMessage={toastMessage}
                showToast={showToast}
              />
            </TabPanel>
          </DialogContent>
          <DialogActions sx={{ padding: '24px' }}>
            <SentinelActions
              setupSuccessful={setupSuccessful}
              isSubmitting={isSubmitting}
              isValid={isValid}
              handleCancel={handleCancel}
              handleBack={handleBack}
              handleNext={handleNext}
              handleSaveAndExit={() => handleSaveAndExit(values)}
              handleFinish={handleFinish}
              activeStep={activeStep}
            />
          </DialogActions>
        </Form>
      )}
    </Formik>
  )
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`crowdstrike-tabpanel-${index}`}
      aria-labelledby={`crowdstrike-tab-${index}`}
      {...other}
      style={{
        padding: '40px 72px 60px',
        minHeight: '464px'
      }}
    >
      {value === index && <>{children}</>}
    </div>
  )
}

function a11yProps(index: number) {
  return {
    id: `crowdstrike-tab-${index}`,
    'aria-controls': `crowdstrike-tabpanel-${index}`
  }
}

export default SentinelIntegration
