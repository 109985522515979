import { Box } from '@mui/material'
import styled from '@emotion/styled/macro'

export const Wrapper = styled(Box)`
  padding: 16px 16px 34px 16px;

  img {
    width: 100%;
    max-width: 100%;
    padding-left: 12px;
    padding-right: 12px;
  }
`

export const Header = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 12px;

  .title {
    padding-left: 12px;
    font-family: 'Quicksand', sans-serif;
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
    color: #fff;
  }
`
