import { Dispatch, SetStateAction } from 'react'
import {
  AddVendorPayload,
  SSMAssetType,
  SSMGroupedData,
  SSMNistPillar,
  SSMResponseItem,
  SSMResponseNistsV2,
  SSMResponseProduct
} from '../../../models'
import { HeaderItemProps, CurrentCard } from './SSM'

export const nistPillars = [
  SSMNistPillar.Identify,
  SSMNistPillar.Protect,
  SSMNistPillar.Detect,
  SSMNistPillar.Respond,
  SSMNistPillar.Recover,
  SSMNistPillar.Govern
]

export const assetTypes = [
  SSMAssetType.Devices,
  SSMAssetType.Applications,
  SSMAssetType.Networks,
  SSMAssetType.Data,
  SSMAssetType.Users
]

export const groupSSMData = (data: SSMResponseNistsV2[], products: SSMResponseProduct[]): SSMGroupedData => {
  const groupedData: SSMGroupedData = []

  data.forEach((nist, index) => {
    const nistName = nistPillars[index]

    nist.forEach((asset, i) => {
      const assetName = assetTypes[i]

      const thisAsset = asset.map((currentAsset) => {
        const thisCurrentAsset = products.find((product) => product.uuid === currentAsset.itemId)

        if (thisCurrentAsset) {
          const currentAssetFromData = asset.find((n) => n.itemId === thisCurrentAsset.uuid)
          const cost =
            currentAssetFromData && Object.hasOwn(currentAssetFromData, 'cost')
              ? (currentAssetFromData as any).cost
              : thisCurrentAsset.cost || 0
          return { ...thisCurrentAsset, cost }
        }

        return null
      })

      groupedData.push({
        nist: nistName,
        asset: assetName,
        items: thisAsset as any
      })
    })
  })

  return groupedData
}

export const getHeaderItems = (groupedData: SSMGroupedData): Record<string, HeaderItemProps | number> => {
  const numOfDevices = []
  let devicesCost = 0
  const numOfApplications = []
  let applicationsCost = 0
  const numOfNetworks = []
  let networksCost = 0
  const numOfUsers = []
  let usersCost = 0
  const numOfData = []
  let dataCost = 0
  // const devicesProducts: SSMResponseProduct[] = []
  // const applicationsProducts: SSMResponseProduct[] = []
  // const networkProducts: SSMResponseProduct[] = []
  // const dataProducts: SSMResponseProduct[] = []
  // const usersProducts: SSMResponseProduct[] = []

  groupedData.forEach((item) => {
    if (item.asset === 'Devices' && item.items.length > 0) {
      numOfDevices.push(item)
      const cellTotalCost = item.items.length > 0 ? item.items.reduce((acc: any, obj: any) => acc + obj.cost, 0) : 0
      devicesCost += cellTotalCost
      // const devicesColumn = groupedData.filter((n) => n.asset === SSMAssetType.Devices)
      // devicesColumn.forEach((column) =>
      //   column.items.forEach((item: any) => {
      //     const includesProduct = devicesProducts.find((n) => n.product_uuid === item.product_uuid)
      //     if (!includesProduct) {
      //       devicesProducts.push(item)
      //     }
      //   })
      // )
    }
    if (item.asset === 'Applications' && item.items.length > 0) {
      numOfApplications.push(item)
      const cellTotalCost = item.items.length > 0 ? item.items.reduce((acc: any, obj: any) => acc + obj.cost, 0) : 0
      applicationsCost += cellTotalCost
      // const applicationsColumn = groupedData.filter((n) => n.asset === SSMAssetType.Applications)
      // applicationsColumn.forEach((column) =>
      //   column.items.forEach((item: any) => {
      //     const includesProduct = applicationsProducts.find((n) => n.product_uuid === item.product_uuid)
      //     if (!includesProduct) {
      //       applicationsProducts.push(item)
      //     }
      //   })
      // )
    }
    if (item.asset === 'Networks' && item.items.length > 0) {
      numOfNetworks.push(item)
      const cellTotalCost = item.items.length > 0 ? item.items.reduce((acc: any, obj: any) => acc + obj.cost, 0) : 0
      networksCost += cellTotalCost
      // const networkColumn = groupedData.filter((n) => n.asset === SSMAssetType.Networks)
      // networkColumn.forEach((column) =>
      //   column.items.forEach((item: any) => {
      //     const includesProduct = networkProducts.find((n) => n.product_uuid === item.product_uuid)
      //     if (!includesProduct) {
      //       networkProducts.push(item)
      //     }
      //   })
      // )
    }
    if (item.asset === 'Data' && item.items.length > 0) {
      numOfData.push(item)
      const cellTotalCost = item.items.length > 0 ? item.items.reduce((acc: any, obj: any) => acc + obj.cost, 0) : 0
      dataCost += cellTotalCost
      // const dataColumn = groupedData.filter((n) => n.asset === SSMAssetType.Data)
      // dataColumn.forEach((column) =>
      //   column.items.forEach((item: any) => {
      //     const includesProduct = dataProducts.find((n) => n.product_uuid === item.product_uuid)
      //     if (!includesProduct) {
      //       dataProducts.push(item)
      //     }
      //   })
      // )
    }
    if (item.asset === 'Users' && item.items.length > 0) {
      numOfUsers.push(item)
      const cellTotalCost = item.items.length > 0 ? item.items.reduce((acc: any, obj: any) => acc + obj.cost, 0) : 0
      usersCost += cellTotalCost
      // const usersColumn = groupedData.filter((n) => n.asset === SSMAssetType.Users)
      // usersColumn.forEach((column) =>
      //   column.items.forEach((item: any) => {
      //     const includesProduct = usersProducts.find((n) => n.product_uuid === item.product_uuid)
      //     if (!includesProduct) {
      //       usersProducts.push(item)
      //     }
      //   })
      // )
    }
  })

  // const devicesTotal = devicesProducts.reduce((a, b) => a + b.tilesTotal, 0)
  // const applicationsTotal = applicationsProducts.reduce((a, b) => a + b.tilesTotal, 0)
  // const networksTotal = networkProducts.reduce((a, b) => a + b.tilesTotal, 0)
  // const dataTotal = dataProducts.reduce((a, b) => a + b.tilesTotal, 0)
  // const usersTotal = usersProducts.reduce((a, b) => a + b.tilesTotal, 0)

  return {
    devices: {
      headerCost: devicesCost,
      numOfItems: numOfDevices.length
    },
    applications: {
      headerCost: applicationsCost,
      numOfItems: numOfApplications.length
    },
    networks: {
      headerCost: networksCost,
      numOfItems: numOfNetworks.length
    },
    users: {
      headerCost: usersCost,
      numOfItems: numOfUsers.length
    },
    data: {
      headerCost: dataCost,
      numOfItems: numOfData.length
    },
    // budgetTotalCost: devicesTotal + applicationsTotal + networksTotal + usersTotal + dataTotal
    budgetTotalCost: devicesCost + applicationsCost + networksCost + usersCost + dataCost
  }
}

const RECENTLY_ADDED_VENDORS = 'recently_added_vendors'
export const VENDOR_ADDED_AT = 'vendor_added_at'

export const recentSearchesSortFn = (a: any, b: any) => {
  if (a.index && b.index) {
    return a.index > b.index ? 1 : -1
  }
  return 1
}

export const addVendorToRecentSearches = (payload: any) => {
  if (!window || !window.localStorage) return

  const existingStoredVendors = localStorage.getItem(RECENTLY_ADDED_VENDORS)
  let recentlyAddedVendors: AddVendorPayload[] = []

  if (existingStoredVendors) {
    const existingParsed: AddVendorPayload[] = JSON.parse(existingStoredVendors)
    const vendorExists = existingParsed.find((n: any) => n.uuid === payload.uuid)

    const sortedArray = vendorExists
      ? existingParsed
          .map((n) =>
            (n as any).uuid === (vendorExists as any).uuid
              ? {
                  ...n,
                  index: 1
                }
              : {
                  ...n,
                  index: (n as any).index + 5
                }
          )
          .sort(recentSearchesSortFn)
          .map((n) => (n.index === 1 ? n : { ...n, index: n.index - 5 }))
      : [payload, ...existingParsed].sort(recentSearchesSortFn)

    if (existingParsed.length >= 5) {
      recentlyAddedVendors = sortedArray.slice(0, 5).map((n, i) => ({ ...n, index: i + 1 }))
    } else {
      recentlyAddedVendors = sortedArray.map((n, i) => ({ ...n, index: i + 1 }))
    }
  } else {
    recentlyAddedVendors = [{ ...payload, index: 1 }]
  }

  localStorage.setItem(RECENTLY_ADDED_VENDORS, JSON.stringify(recentlyAddedVendors))
  const date = new Date().getTime()
  localStorage.setItem(VENDOR_ADDED_AT, String(date))
}

export const updateVendorRecentSearches = (vendorId: string, values: any) => {
  if (!window || !window.localStorage) return

  const existingStoredVendors = localStorage.getItem(RECENTLY_ADDED_VENDORS)
  let recentlyAddedVendors: AddVendorPayload[] = []

  if (existingStoredVendors) {
    const existingParsed: AddVendorPayload[] = JSON.parse(existingStoredVendors)
    const vendorExists = existingParsed.find((n: any) => n.uuid === vendorId)

    let sortedArray = []

    if (vendorExists) {
      sortedArray = existingParsed
        .map((n) =>
          (n as any).uuid === (vendorExists as any).uuid
            ? {
                ...n,
                index: 1,
                ...values
              }
            : {
                ...n,
                index: (n as any).index + 5
              }
        )
        .sort(recentSearchesSortFn)
        .map((n) => (n.index === 1 ? n : { ...n, index: n.index - 5 }))
    }

    if (existingParsed.length >= 5) {
      recentlyAddedVendors = sortedArray.slice(0, 5).map((n, i) => ({ ...n, index: i + 1 }))
    } else {
      recentlyAddedVendors = sortedArray.map((n, i) => ({ ...n, index: i + 1 }))
    }
  }

  localStorage.setItem(RECENTLY_ADDED_VENDORS, JSON.stringify(recentlyAddedVendors))
  const date = new Date().getTime()
  localStorage.setItem(VENDOR_ADDED_AT, String(date))
}

export const getRecentSearches = () => {
  if (!window || !window.localStorage) return null

  const existingStoredVendors = localStorage.getItem(RECENTLY_ADDED_VENDORS)

  if (existingStoredVendors) {
    const result: AddVendorPayload = JSON.parse(existingStoredVendors)
    if (Array.isArray(result) && result.length > 0) return result
  }

  return null
}

export const findAllAddedVendors = (groupedData: SSMGroupedData) => {
  const allAddedVendors = nistPillars.map((nist) => {
    const assets = groupedData.filter((n) => n.nist === nist)
    const allAddedVendors: SSMResponseItem[] = []

    assets.forEach((asset) => {
      asset.items.forEach((assetItem) => {
        allAddedVendors.push(assetItem as any)
      })
    })

    return allAddedVendors
  })
  const allAddedVendorsFlat = allAddedVendors.flat()
  const allUniqueVendors: SSMResponseItem[] = []

  allAddedVendorsFlat.forEach((item) => {
    const hasItem = allUniqueVendors.find((n) => n.uuid === item.uuid)
    if (!hasItem) {
      allUniqueVendors.push(item)
    }
  })

  return allUniqueVendors
}

export const handleOpenCloseConditions = ({
  e,
  editMode,
  itemsLength,
  currentCard,
  setCurrentCard,
  customCard,
  setCustomCard,
  setDetailsCard,
  thisNist,
  thisAsset,
  currentTarget
}: {
  e: any
  editMode: boolean
  itemsLength: number
  currentCard: CurrentCard
  setCurrentCard: Dispatch<SetStateAction<CurrentCard>>
  customCard: CurrentCard
  setCustomCard: Dispatch<SetStateAction<CurrentCard>>
  setDetailsCard: Dispatch<SetStateAction<CurrentCard>>
  thisNist: SSMNistPillar
  thisAsset: SSMAssetType
  currentTarget: EventTarget & HTMLDivElement
}) => {
  if (!editMode) {
    setDetailsCard({
      open: true,
      target: currentTarget,
      nist: thisNist,
      asset: thisAsset
    })
    e.preventDefault()

    return
  }

  if (editMode && itemsLength > 5) {
    if (currentCard.open) {
      setCurrentCard({ open: false })
    }

    return
  }

  if (!currentCard.target) {
    if (!currentCard.open) {
      setCurrentCard({
        open: true,
        nist: thisNist,
        asset: thisAsset,
        target: currentTarget
      })
    } else {
      setCurrentCard({
        open: false,
        nist: thisNist,
        asset: thisAsset,
        target: null
      })
    }

    return
  }

  if (customCard.open) {
    if (customCard.nist && customCard.nist === thisNist && customCard.asset && customCard.asset === thisAsset) {
      setCustomCard({
        open: false,
        nist: thisNist,
        asset: thisAsset
      })
    }
  }

  if (currentCard.open) {
    if (currentCard.nist && currentCard.nist === thisNist && currentCard.asset && currentCard.asset === thisAsset) {
      setCurrentCard({
        open: false,
        nist: thisNist,
        asset: thisAsset,
        target: currentTarget
      })

      return
    }

    setCurrentCard({
      open: true,
      nist: thisNist,
      asset: thisAsset,
      target: currentTarget
    })

    return
  }

  if (customCard.open) {
    setCustomCard({
      open: false,
      nist: thisNist,
      asset: thisAsset
    })

    if (currentCard.nist && currentCard.nist === thisNist && currentCard.asset && currentCard.asset === thisAsset) {
      setCurrentCard({
        open: false,
        nist: thisNist,
        asset: thisAsset,
        target: currentTarget
      })

      return
    } else {
      setCurrentCard({
        open: true,
        nist: thisNist,
        asset: thisAsset,
        target: currentTarget
      })
    }

    return
  }

  setCurrentCard({
    open: true,
    nist: thisNist,
    asset: thisAsset,
    target: currentTarget
  })
}

export const getNistPillarBackground = (nist: SSMNistPillar) => {
  switch (nist) {
    case SSMNistPillar.Identify:
      return '#8E9AFF'
    case SSMNistPillar.Protect:
      return '#C77EDA'
    case SSMNistPillar.Detect:
      return '#FFDE6A'
    case SSMNistPillar.Respond:
      return '#FF7F6B'
    case SSMNistPillar.Recover:
      return '#99FDC0'
    case SSMNistPillar.Govern:
      return '#FF9E47'
    default:
      return 'e0e0e0'
  }
}

export const getBudgetBreakpoints = (maxCellValue: number): number[] => {
  const thresholdGap = maxCellValue / 5

  return [thresholdGap, thresholdGap * 2, thresholdGap * 3, thresholdGap * 4, thresholdGap * 5]
}

export enum CostDistribution {
  Even = 'even',
  Manual = 'manual'
}
