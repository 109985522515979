import { FC, MouseEvent, useState, useEffect, useRef, useMemo, useCallback } from 'react'
import { useLocation, useNavigate, Link } from 'react-router-dom'
import { Box, IconButton, Link as MuiLink, Menu, MenuItem } from '@mui/material'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import SaveIcon from '@mui/icons-material/Save'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import { useAuth } from '@frontegg/react'
import useDebounce from '../../../hooks/useDebounce'

/* Utils */
import axios from '../../../lib/axios'
import {
  ConfiguredDataSource,
  FrameworkCategoryWeightItem,
  FrameworkType,
  IFramework,
  FrameworkCategoryResponse,
  CategoriesWeightsResponse,
  CPIWeightsByCategoryResponse,
  CPIWeightsByCategoryCpi,
  FrameworkCategoryCpi,
  CategoriesWeightsCategory,
  FrameworkCategoryResponseItem,
  LibraryFilters
} from '../../../models'
import { equalWeightsGen, Role } from '../../../lib/utils'
import { closeToast, openToast, ToastVariant } from '../../../store/toasts/toastSlice'
import { useAppDispatch } from '../../../store/hooks'

/* Components */
import { Container, Breadcrumbs, Content, SectionDivider, paperPropsSx } from './frameworkConfigurationV2.styles'
import { Header } from './framework.styles'
import { ThemeButton } from '../../components/buttons'
import Categories from './sections/Categories'
import CPIs from './sections/CPIs'
import AddCpiDrawer, { CpiListItem } from './sections/AddCpiDrawer'
import { useSearchCpis } from '../library/utils'
import { useDeleteFramework } from '../../../hooks/useDeleteFramework'

const defaultFilters = {
  category: [],
  source: [],
  status: [],
  sortBy: 'Newest to Oldest',
  frameworkCategory: ''
} as LibraryFilters

const customFrameworkColors = [
  { chipBgColor: 'rgba(255,235,249,1)', chipColor: 'rgba(0,0,0,1)' },
  { chipBgColor: 'rgba(255,224,250,1)', chipColor: 'rgba(0,0,0,1)' },
  { chipBgColor: 'rgba(255,203,253,1)', chipColor: 'rgba(0,0,0,1)' },
  { chipBgColor: 'rgba(255,169,252,1)', chipColor: 'rgba(0,0,0,1)' },
  { chipBgColor: 'rgba(236,133,236,1)', chipColor: 'rgba(0,0,0,1)' },
  { chipBgColor: 'rgba(206,107,207,1)', chipColor: 'rgba(0,0,0,1)' },
  { chipBgColor: 'rgba(176,81,179,1)', chipColor: 'rgba(255,255,255,1)' },
  { chipBgColor: 'rgba(148,55,152,1)', chipColor: 'rgba(255,255,255,1)' },
  { chipBgColor: 'rgba(127,40,131,1)', chipColor: 'rgba(255,255,255,1)' },
  { chipBgColor: 'rgba(112,21,119,1)', chipColor: 'rgba(255,255,255,1)' },
  { chipBgColor: 'rgba(92,7,99,1)', chipColor: 'rgba(255,255,255,1)' },
  { chipBgColor: 'rgba(77,0,83,1)', chipColor: 'rgba(255,255,255,1)' }
]

const FrameworkConfiguration: FC = () => {
  const dispatch = useAppDispatch()
  const location = useLocation()
  const navigate = useNavigate()
  const thisPath = location.pathname.replace('/framework-configuration/', '')
  const framework = thisPath === 'onyxia' ? 'onyxia' : thisPath === 'nist' ? 'nist' : 'custom'
  const categoriesContainerRef = useRef<HTMLDivElement>(null)
  const { user: authUser } = useAuth()
  const [drawerOpen, setDrawerOpen] = useState(false)
  const [categories, setCategories] = useState<FrameworkCategoryResponseItem[]>([])
  const [categoryItemsPristine, setCategoryItemsPristine] = useState<FrameworkCategoryWeightItem[]>([])
  const [selectedCategoryItem, setSelectedCategoryItem] = useState<FrameworkCategoryWeightItem | undefined>(undefined)
  const [headerDropdownAnchor, setHeaderDropdownAnchor] = useState<null | HTMLElement>(null)
  const headerDropdownOpen = Boolean(headerDropdownAnchor)
  const [categoriesForm, setCategoriesForm] = useState<any>({
    framework: {},
    categoryFramework: {},
    categories: categories,
    categoryCpis: categoryItemsPristine.map((n) => n.cpis).flat()
  })
  const [categoriesTotal, setCategoriesTotal] = useState(100)
  const [hasEditRole, setHasEditRole] = useState(false)
  const [initialLoad, setInitialLoad] = useState(false)
  const [customFramework, setCustomFramework] = useState<IFramework | null>(null)
  const [discardDisabled, setDiscardDisabled] = useState(true)
  const [configuredIntegrations, setConfiguredIntegrations] = useState<ConfiguredDataSource[]>([])
  const [createBlank, setCreateBlank] = useState((location as any).state?.blank || false)
  const [template, setTemplate] = useState((location as any).state?.template || '')
  const [saving, setSaving] = useState(false)
  const [edited, setEdited] = useState(false)
  const [createBlankEdited, setCreateBlankEdited] = useState(false)
  const [selectedCpis, setSelectedCpis] = useState<CpiListItem[]>([])

  const checkCategoryItemIsInvalid = useCallback(
    (categoryItem: FrameworkCategoryWeightItem) => {
      if (categoryItem.cpis.every((n) => !n.isActive) && (categoryItem.weight === 0 || !categoryItem.weight))
        return false

      const thisPristineCategory = categoryItemsPristine.find((n) => n.name === categoryItem.name)
      if (thisPristineCategory) {
        const pristineActiveCpis = thisPristineCategory.cpis.filter((n) => n.isActive).map((c) => c.displayName)
        const categoryItemCpis = categoryItem.cpis.filter((n) => n.isActive)
        const cpiSum = categoryItemCpis.reduce((acc, obj) => acc + obj.weight, 0)

        if (pristineActiveCpis.length !== categoryItemCpis.length && cpiSum !== 100) {
          return true
        }
      }

      return !categoryItem.cpis.length
    },
    [categoryItemsPristine]
  )

  const saveDisabled = useMemo(() => {
    if (!initialLoad) return true
    if (saving) return true
    let erroredName = false

    const cpisWeightSum: number[] = []
    let categoriesWeightSum = 0

    categoriesForm.categories.forEach((category: any) => {
      if (category.name === '') {
        erroredName = true
      }
      const categoryItemIsInvalid = checkCategoryItemIsInvalid(category)
      if (categoryItemIsInvalid) {
        erroredName = categoryItemIsInvalid
      }

      let cpiSum = 0
      if (category.cpis.length > 0) {
        if (category.weight) {
          categoriesWeightSum += +category.weight
        }

        category.cpis.forEach((cpi: any) => {
          if (cpi.isActive) {
            cpiSum += +cpi.weight
          }
        })
        cpisWeightSum.push(cpiSum)
      }
    })

    /* Check duplicate category name */
    const categoriesNames: string[] = categoriesForm.categories.map((n: any) => n.name)
    const nameExists = categoriesNames.some((item, i) => categoriesNames.indexOf(item) !== i)
    if (nameExists) {
      erroredName = true
    }

    if (erroredName) return true
    if (categoriesForm.categories.every((category: any) => !category.cpis.length)) {
      categoriesWeightSum = 100
    }

    const everyCpiHasCorrectWeight = cpisWeightSum.every((n) => n === 100 || n === 0)
    if (!everyCpiHasCorrectWeight) return true
    const everyCategoryHasCorrectWeight = Math.round(categoriesWeightSum) === 100
    if (!everyCategoryHasCorrectWeight) return true

    if (createBlank) {
      if (!createBlankEdited) {
        return true
      }
    }
    if (template) return false

    return !edited
  }, [initialLoad, createBlank, edited, categoriesForm, saving])

  useEffect(() => {
    if (location.state) {
      const blank = (location as any).state?.blank
      setCreateBlank(Boolean(blank))

      const template = (location as any).state?.template
      setTemplate(template)

      if (blank || template) {
        setDiscardDisabled(false)
      }
    }
  }, [location])

  const fetchConfiguredIntegrations = useCallback(async () => {
    const res = await axios.get<{ instances: ConfiguredDataSource[] }>('/api/v3/integrations/configured')

    if (res.status.toString().includes('20')) {
      const { instances } = res.data
      setConfiguredIntegrations(instances)
    }
  }, [])

  const createCustomFramework = async (template: string) => {
    try {
      const payload = template === 'custom' ? undefined : { template }
      await axios.post('/api/v3/frameworks/custom', payload)
    } catch (err) {
      console.error(err)
    }
  }

  const fetchCustomFramework = async (): Promise<IFramework | null> => {
    try {
      const res = await axios.get<{ framework: IFramework }>('/api/v3/frameworks/custom')

      if (res.status.toString().includes('20')) {
        const customFramework = res.data.framework

        if (customFramework) {
          setCustomFramework(customFramework)

          return customFramework
        }
      }

      return null
    } catch (err) {
      console.error(err)
    }

    return null
  }

  const fetchCpiWeightsForCategory = async (
    categoryId: string,
    frameworkName?: string
  ): Promise<CPIWeightsByCategoryCpi[]> => {
    let url = `/api/v3/frameworks/cpis/${categoryId}/weights?fetch-cpi-weights`
    if (frameworkName) {
      url += `&framework=${frameworkName}`
    }

    try {
      const resp = await axios.get<{ data: CPIWeightsByCategoryResponse }>(url)

      return resp.data.data.cpis
    } catch (err) {
      console.error(err)
    }

    return []
  }

  const fetchCategoriesWeights = async (fwName?: string) => {
    let url = '/api/v3/frameworks/categories/weights?fetch-category-weights'
    if (fwName) {
      url += `&framework=${fwName}`
    }

    const categoriesWeightsResponse = await axios.get<{ data: CategoriesWeightsResponse }>(url)

    const {
      data: {
        data: { categories }
      }
    } = categoriesWeightsResponse

    return categories
  }

  const fetchCategories = async (fwName?: string) => {
    let url = '/api/v3/frameworks/categories?fetch-categories'
    if (fwName) {
      url += `&framework=${fwName}`
    }

    const categoriesRes = await axios.get<{ data: FrameworkCategoryResponse }>(url)

    const {
      data: {
        data: { categories }
      }
    } = categoriesRes

    return categories
  }

  const fetchAndGroupCpiCategoryWeights = async (
    allCategories: FrameworkCategoryResponseItem[],
    categoriesWeights: CategoriesWeightsCategory[],
    fwName: string
  ): Promise<FrameworkCategoryWeightItem[]> => {
    const promises: Promise<FrameworkCategoryWeightItem>[] = []

    allCategories.forEach((category) => {
      const promise = async () => {
        const categoryWithWeight = {
          id: category.id,
          name: category.title,
          chip_bg_color: category.chip_bg_color,
          chip_color: category.chip_color
        } as FrameworkCategoryWeightItem
        const cpisWeights = await fetchCpiWeightsForCategory(category.id, fwName)
        const categoryWeight = categoriesWeights.find((n: any) => n.id === category.id)
        if (categoryWeight) {
          categoryWithWeight.weight = categoryWeight.weight
        }
        categoryWithWeight.cpis = [...cpisWeights.map((n) => ({ ...n, id: '' }))] as FrameworkCategoryCpi[]

        return categoryWithWeight
      }

      promises.push(promise())
    })

    /* Categories with weights and CPI weights */
    return await Promise.all(promises)
  }

  const fetchInitialData = async (frameworkName: string, template?: string) => {
    try {
      const customFramework = await fetchCustomFramework()
      let fwName = frameworkName === 'custom' && customFramework ? customFramework.id : frameworkName

      if (createBlank) {
        const starterCategories: any = Array.from(Array(3).keys())
          .map((key, index: number) => ({
            name: `Category ${key + 1}`,
            id: `placeholder-id-${index}`,
            weight: 0,
            chip_color: customFrameworkColors[index].chipColor,
            chip_bg_color: customFrameworkColors[index].chipBgColor,
            cpis: []
          }))
          .reverse()

        setCategoryItemsPristine(starterCategories)
        setCategoriesForm((pre: any) => ({
          ...pre,
          categories: starterCategories
        }))
        setSelectedCategoryItem(starterCategories[0])
      } else {
        if (!customFramework && template) {
          await createCustomFramework(template && template.length > 0 ? template : 'custom')
          const newCustomFramework = await fetchCustomFramework()
          if (newCustomFramework) {
            fwName = newCustomFramework.id
          }
        }

        const categories = await fetchCategories(fwName)
        const categoriesWeights = await fetchCategoriesWeights(fwName)
        setCategories(categories)
        const completeCategoriesWithWeights = await fetchAndGroupCpiCategoryWeights(
          categories,
          categoriesWeights,
          fwName
        )

        setCategoryItemsPristine(completeCategoriesWithWeights)
        setSelectedCategoryItem(completeCategoriesWithWeights[0])
        setCategoriesForm((pre: any) => ({
          ...pre,
          categories: completeCategoriesWithWeights,
          categoryFramework: {
            value: fwName
          }
        }))
      }
    } catch (err) {
      console.error(err)
    } finally {
      setInitialLoad(true)
    }
  }

  useEffect(() => {
    fetchConfiguredIntegrations().catch((e) => e)
  }, [])

  useEffect(() => {
    fetchInitialData(framework, template).catch((e) => e)
  }, [framework, createBlank, template])

  useDeleteFramework(createBlank || template.length > 0)

  useEffect(() => {
    if (authUser) {
      const roles = authUser ? authUser.roles : []

      if (roles.length > 0 && roles[0].name) {
        const role = roles[0].name.toLowerCase()
        setHasEditRole(role === Role.Owner || role === Role.Admin)
      }
    }
  }, [authUser])

  const breadcrumbs = [
    <MuiLink
      component={Link}
      underline="none"
      key="1"
      color="inherit"
      to="/framework-configuration"
      sx={{ color: '#D9BFD4' }}
    >
      Frameworks
    </MuiLink>,
    <MuiLink
      component={Link}
      underline="none"
      key="2"
      color="inherit"
      to={createBlank ? '/framework-configuration/custom' : `/framework-configuration/${framework}`}
    >
      {framework}
    </MuiLink>
  ]

  const handleAddCpis = () => {
    // updateCpiCategories(categoriesForm.categories)
    setDrawerOpen(true)
  }

  const handleDeleteCategory = (categoryId: string) => {
    const categories = [...categoriesForm.categories]
    const categoryIndex = categories.findIndex((n: any) => n.id === categoryId)
    const newSelection2 = categories.find((n: any, i: number) => i === categoryIndex + 1)
    if (newSelection2) {
      setSelectedCategoryItem(newSelection2)
    }

    const newCategories = [...categories].filter((n: any) => n.id !== categoryId)
    setEdited(true)
    setCategoriesForm((pre: any) => ({
      ...pre,
      categories: newCategories
    }))
    setDiscardDisabled(false)
  }

  const handleDeleteCpi = async (cpiName: string) => {
    if (!selectedCategoryItem) return

    const newCpis = [...selectedCategoryItem.cpis].filter((n) => n.displayName !== cpiName)
    const allCpisAreWeightless = newCpis.every((n) => !n.isActive)

    const updatedCategories = categoriesForm.categories.map((cat: any) =>
      cat.id === selectedCategoryItem.id
        ? {
            ...cat,
            isEdit: true,
            lastAction: 'delete',
            cpis: newCpis,
            weight: !newCpis.length || (newCpis.length > 0 && allCpisAreWeightless) ? 0 : cat.weight
          }
        : cat
    )
    setEdited(true)
    setCategoriesForm((pre: any) => ({
      ...pre,
      categories: updatedCategories
    }))
    setSelectedCategoryItem((pre: any) => ({
      ...pre,
      cpis: newCpis
    }))
    setDiscardDisabled(false)
  }

  const handleHeaderDropdownClick = (e: MouseEvent<HTMLElement>) => {
    setHeaderDropdownAnchor(e.currentTarget)
  }

  const updateCpiWeights = async (category: any, frameworkName: string) => {
    try {
      const cpis =
        category.cpis.length > 0
          ? category.cpis.map((n: any) => ({
              name: n.name,
              weight: !isNaN(n.weight) ? n.weight : undefined
            }))
          : []
      const payload = {
        cpis
      }

      if (frameworkName.includes('custom')) {
        await axios.post(`/api/v3/frameworks/custom/${category.id}/cpis`, payload)
      } else {
        const nonCustomCpis = cpis.filter((n: any) => !!n.weight)
        await axios.post(`/api/v3/frameworks/cpis/${category.id}/weights?framework=${frameworkName}`, {
          cpis: nonCustomCpis
        })
      }
    } catch (err) {
      console.error(err)
    }
  }

  const updateCpis = async (thisCategoriesForm: any) => {
    const tempForm: any = {
      framework: {},
      categoryFramework: {},
      categories: [],
      categoryCpis: []
    }

    const data = thisCategoriesForm && typeof thisCategoriesForm !== 'string' ? thisCategoriesForm : categoriesForm

    if (data.framework.isEdit) {
      tempForm.framework = data.framework
    }
    data.categories.forEach((category: any) => {
      if (category.isEdit)
        tempForm.categories.push({
          ...category,
          cpis: category.cpis.map((cpi: any) => ({
            name: cpi.name,
            weight: cpi.isActive ? cpi.weight || 0 : undefined
          }))
        })
    })
    data.categoryCpis.forEach((item: any) => {
      item.cpis.forEach((i: any) => {
        if (i.isEdit) {
          tempForm.categoryCpis.push(i)
        }
      })
    })

    for (const category of tempForm.categories) {
      // if (category.cpis.length > 0) {
      await updateCpiWeights(category, framework)
      // }
    }
  }

  /* First update custom categories if user adds/delete a category, then update weights for categories
   * The idea with API is that 1 operation is updating categories, another operation is updating weights
   * */
  const updateCategories = async () => {
    const prevTemplate = template
    const prevCreateBlank = createBlank
    let customFw = customFramework

    try {
      dispatch(
        openToast({
          variant: ToastVariant.success,
          props: {
            text: 'Changes Saved.',
            description: ' Your changes have been successfully saved and applied.'
          }
        })
      )
      setDiscardDisabled(true)
      setEdited(false)
      setCreateBlank(false)
      setTemplate('')
      navigate(`/framework-configuration/custom`, {})

      let thisCategoriesForm = categoriesForm

      if (framework === FrameworkType.Custom) {
        // const colors = customFrameworkColors
        const payload = {
          categories: categoriesForm.categories.map((n: any, i: number) => {
            const result: any = {
              title: n.name,
              chip_color: n.chip_color || customFrameworkColors[i].chipColor,
              chip_bg_color: n.chip_bg_color || customFrameworkColors[i].chipBgColor
              // chip_color: colors[i].chipColor,
              // chip_bg_color: colors[i].chipBgColor
            }
            if (typeof n.id === 'string' && n.id.length > 0 && !n.id.includes('placeholder')) {
              result.id = n.id
            }

            return result
          })
        }

        if (!customFw) {
          try {
            await createCustomFramework(template && template.length > 0 ? template : 'custom')

            // setTimeout(() => {
            // }, 0)
            const newCustomFramework = await fetchCustomFramework()
            if (newCustomFramework) {
              setCustomFramework(newCustomFramework)
              customFw = newCustomFramework
            }
          } catch (err) {
            console.error(err)
          }
        }

        /* Update Categories Custom Category */
        const createdCategories: any = await axios.post('/api/v3/frameworks/custom/categories', payload)
        const newCategoriesForm = thisCategoriesForm.categories.map((n: any) => {
          if (n.id.includes('placeholder-id-')) {
            const functions = createdCategories?.data?.framework?.data?.functions
            const found = functions.find((c: any) => c.title === n.name)
            return {
              ...n,
              id: found.id
            }
          } else return n
        })
        thisCategoriesForm = {
          ...thisCategoriesForm,
          categories: newCategoriesForm
        }
        setCategoriesForm(thisCategoriesForm)
        setCategoryItemsPristine(thisCategoriesForm.categories)
        const foundSelectedItem = thisCategoriesForm.categories.find((n: any) => n.name === selectedCategoryItem?.name)
        setSelectedCategoryItem(foundSelectedItem || thisCategoriesForm.categories[0])

        setTimeout(() => {
          navigate(location.pathname, {})
        }, 0)
      }

      const activeCategories = thisCategoriesForm.categories.filter(
        (n: any) => n.cpis.length > 0 && n.cpis.some((c: any) => c.isActive)
      )
      const payload = {
        categories: activeCategories.map((cat: any) => {
          const result: any = {
            name: cat.name,
            weight: cat.cpis.length > 0 && cat.cpis.some((x: any) => x.isActive) ? cat.weight || 0 : undefined
          }
          if (cat.id && !cat.id.includes('placeholder')) {
            result.id = cat.id
          }

          return result
        })
      }

      if (payload.categories.length > 0) {
        /* Update Category Weights for Named Framework */
        await axios.post(
          `/api/v3/frameworks/categories/weights?framework=${
            framework === 'custom' && customFw ? customFw.id : framework
          }`,
          payload
        )
      }

      return thisCategoriesForm
    } catch (err) {
      console.error(err)
      setDiscardDisabled(false)
      setCreateBlank(prevCreateBlank)
      setTemplate(prevTemplate)
      navigate(`/framework-configuration/custom?template=${prevTemplate}`, {})
      return (err as any).response?.data?.message
    }
  }

  useEffect(() => {
    let sum = 0
    categoriesForm.categories.forEach((category: any) => {
      if (category.weight) {
        sum += +category.weight
      }
    })

    setCategoriesTotal(Math.round(sum))
  }, [initialLoad, categoriesForm])

  const submitForm = async () => {
    try {
      setSaving(true)
      const prevTemplate = template
      setTemplate('')

      try {
        const categoriesUpdated = await updateCategories()

        if (categoriesUpdated && typeof categoriesUpdated === 'string' && categoriesUpdated.includes('must be 100')) {
          dispatch(
            openToast({
              variant: ToastVariant.error,
              props: {
                text: 'Failed updating Categories Configuration.',
                description: categoriesUpdated
              }
            })
          )
        } else if (
          typeof categoriesUpdated === 'string' &&
          categoriesUpdated.includes('industry') &&
          categoriesUpdated.toLowerCase().includes('required')
        ) {
          dispatch(
            openToast({
              variant: ToastVariant.error,
              props: {
                text: 'Failed updating Framework Configuration.',
                description:
                  'You need to have industry selected in order to update framework. Please go to Settings / Account Management to update your industry.'
              }
            })
          )
        } else {
          setEdited(false)
          setCreateBlankEdited(false)
          setCreateBlank(false)
        }

        await updateCpis(categoriesUpdated)
      } catch (err) {
        console.error(err)
        setTemplate(prevTemplate)
        dispatch(
          openToast({
            variant: ToastVariant.error,
            props: {
              text: 'Failed to update Framework Configuration.',
              description: (err as any).response?.data?.message || ''
            }
          })
        )
      } finally {
        setTimeout(() => {
          dispatch(closeToast())
        }, 6000)
      }

      // await Promise.all([updateCategories(), updateCpis()])
      //   .then((res: any) => {
      //     if (!res[0]) {
      //       setEdited(false)
      //       setCreateBlankEdited(false)
      //       setCreateBlank(false)
      //     } else if (
      //       typeof res[0] === 'string' &&
      //       res[0].includes('industry') &&
      //       res[0].toLowerCase().includes('required')
      //     ) {
      //       dispatch(
      //         openToast({
      //           variant: ToastVariant.error,
      //           props: {
      //             text: 'Failed updating Framework Configuration.',
      //             description:
      //               'You need to have industry selected in order to update framework. Please go to Settings / Account Management to update your industry.'
      //           }
      //         })
      //       )
      //     } else {
      //       if (res[0] && res[0].includes('must be 100')) {
      //         dispatch(
      //           openToast({
      //             variant: ToastVariant.error,
      //             props: {
      //               text: 'Failed updating Categories Configuration.',
      //               description: res[0]
      //             }
      //           })
      //         )
      //       }
      //     }
      //   })
      //   .catch((e) => {
      //     console.error(e)
      //     setTemplate(prevTemplate)
      //     dispatch(
      //       openToast({
      //         variant: ToastVariant.error,
      //         props: {
      //           text: 'Failed to update Framework Configuration.',
      //           description: (e as any).response?.data?.message || ''
      //         }
      //       })
      //     )
      //   })
      //   .finally(() => {
      //     setTimeout(() => {
      //       dispatch(closeToast())
      //     }, 6000)
      //   })
    } finally {
      setSaving(false)
    }
  }

  const evenWeights = (categoryId: string, type: 'categories' | 'cpis') => {
    setEdited(true)
    const foundCategory = categoriesForm.categories.find((n: any) => n.id === categoryId)

    if (type === 'categories') {
      let tempWeightsCategories: any[] = []
      const categoriesWithCpis = categoriesForm.categories.filter(
        (n: any) => n.cpis.length > 0 && n.cpis.some((n: any) => n.isActive)
      )
      const weightsGen = equalWeightsGen(categoriesWithCpis.length)
      const weights = categoriesWithCpis.map((c: any) => {
        return {
          ...c,
          weight: weightsGen.next().value as number
        }
      })
      tempWeightsCategories = weights.reverse()
      const updatedCategories = categoriesForm.categories.map((cat: any) => {
        const foundInTemp = tempWeightsCategories.find((n) => n.id === cat.id)

        return (
          foundInTemp || {
            ...cat,
            weight: 0
          }
        )
      })
      setCategoriesForm((pre: any) => ({
        ...pre,
        categories: updatedCategories
      }))
    } else {
      if (foundCategory) {
        const tempCpis = [...foundCategory.cpis]
        const tempActiveCpis = [...foundCategory.cpis].filter((n) => n.isActive)
        const weightsGen = equalWeightsGen(tempActiveCpis.length)

        const weights = tempCpis.map((c: any) => {
          if (Object.hasOwn(c, 'weight')) {
            return {
              ...c,
              weight: weightsGen.next().value as number
            }
          }
          return c
        })

        setCategoriesForm((pre: any) => ({
          ...pre,
          categories: categoriesForm.categories.map((n: any) =>
            n.id === categoryId
              ? {
                  ...n,
                  isEdit: true,
                  cpis: weights
                }
              : n
          )
        }))
      }
    }
  }

  const handleCloseDropdown = () => {
    setHeaderDropdownAnchor(null)
  }

  const handleRevertValues = () => {
    handleDiscard()
    handleCloseDropdown()
    setEdited(false)
  }

  const [searchData, setSearchData] = useState<CpiListItem[]>([])
  const [searchDataPristine, setSearchDataPristine] = useState<CpiListItem[]>([])
  const [filters, setFilters] = useState<LibraryFilters>(defaultFilters)
  const [initialLoadSearch, setInitialLoadSearch] = useState(false)
  const [searchValue, setSearchValue] = useState('')
  const debouncedSearchValue = useDebounce(searchValue, 250)

  const updateCpiCategories = (categories: any) => {
    const adjustedSearchData: any = []

    categories.forEach(({ id, name, cpis }: any) => {
      const searchDataCategoryCpis = searchData.filter((n) => n.category && n.category.title === name)
      cpis.forEach((cpi: any) => {
        const thisCpi = searchDataCategoryCpis.find((n) => n.name === cpi.name)

        if (thisCpi) {
          adjustedSearchData.push({
            ...thisCpi,
            category: thisCpi.selected
              ? {
                  ...thisCpi.category,
                  id,
                  title: name,
                  shadowed: false
                }
              : undefined
          })
        } else {
          adjustedSearchData.push(cpi)
        }
      })
    })

    setSearchData(adjustedSearchData)
  }

  const updateCategoryCpisDrawer = (payload: any) => {
    if (!selectedCategoryItem) return
    setEdited(true)

    const updatedFormCategories = categoriesForm.categories.map((category: any) => {
      if (category.id === selectedCategoryItem.id) {
        const cpis = payload.cpis.map((n: any) => ({
          displayName: n.display_name,
          id: '',
          isActive: n.isActive,
          name: n.name,
          title: n.title,
          weight: n.weight
        }))
        return {
          ...category,
          isEdit: true,
          weight: cpis.every((cpi: any) => !cpi.isActive) ? 0 : category.weight || 0,
          cpis
        }
      } else {
        const payloadCpiNames = payload.cpis.map((n: any) => n.name)
        const cpis = category.cpis.filter((n: any) => !payloadCpiNames.includes(n.name))
        return {
          ...category,
          weight: cpis.every((cpi: any) => !cpi.isActive) ? 0 : category.weight || 0,
          cpis
        }
      }
    })
    const updatedSelectedCategoryItem = updatedFormCategories.find((n: any) => n.id === selectedCategoryItem.id)
    setSelectedCategoryItem({ ...updatedSelectedCategoryItem, weight: updatedSelectedCategoryItem.weight || 0 })

    updateCpiCategories(categoriesForm.categories)

    setCreateBlankEdited(true)
    setCategoriesForm((pre: any) => ({
      ...pre,
      categories: updatedFormCategories
    }))
  }

  const { isFetching, data: searchedData } = useSearchCpis({
    value: debouncedSearchValue,
    framework: thisPath === 'custom' && customFramework ? customFramework.id : thisPath,
    filters,
    categoryOptions: [],
    reset: false,
    keepPreviousData: false,
    enabled: true,
    staleTime: Infinity
  })

  const handleDiscard = () => {
    if (createBlank || template.length > 0) {
      axios.delete('/api/v3/frameworks/custom').catch((e) => e)
      navigate('/framework-configuration/custom/create')
      setCreateBlank(false)
      setTemplate('')
    } else {
      setEdited(false)
    }

    setCategoriesForm((pre: any) => ({
      ...pre,
      categories: categoryItemsPristine
    }))
    setDiscardDisabled(true)

    const thisPristineCategory = categoryItemsPristine.find((n) => n.name === selectedCategoryItem?.name)

    if (thisPristineCategory) {
      const cpisNames = thisPristineCategory.cpis.map((c) => c.name) || []
      const selected = searchDataPristine
        .filter((n) => cpisNames.includes(n.name))
        .map((c) => ({ ...c, selected: true }))
      setSelectedCpis(selected || [])
      setSearchData(searchDataPristine.map((n) => ({ ...n, selected: cpisNames.includes(n.name) })))
      setSelectedCategoryItem((pre: any) => ({
        ...pre,
        cpis: thisPristineCategory.cpis
      }))
    }
  }

  return (
    <Container className="framework-configuration-container">
      <Header>
        <Breadcrumbs separator={<NavigateNextIcon fontSize="medium" />} aria-label="breadcrumb">
          {breadcrumbs}
        </Breadcrumbs>

        {hasEditRole && (
          <Box className="header-actions">
            <ThemeButton themevariant="secondary" onClick={handleDiscard} disabled={!initialLoad || discardDisabled}>
              <>
                <DeleteOutlineIcon />
                Discard
              </>
            </ThemeButton>
            <ThemeButton themevariant="primary" onClick={submitForm} disabled={saveDisabled}>
              <>
                <SaveIcon />
                Save
              </>
            </ThemeButton>

            {(framework === FrameworkType.Onyxia || framework === FrameworkType.Nist) && (
              <>
                <Box className="divider" />

                <IconButton
                  className="edit-icon"
                  aria-label="more"
                  id="long-button"
                  aria-controls={headerDropdownOpen ? 'long-menu' : undefined}
                  aria-expanded={headerDropdownOpen ? 'true' : undefined}
                  aria-haspopup="true"
                  onClick={handleHeaderDropdownClick}
                >
                  <MoreVertIcon />
                </IconButton>

                <Menu
                  id="long-menu"
                  MenuListProps={{ 'aria-labelledby': 'long-button' }}
                  anchorEl={headerDropdownAnchor}
                  open={headerDropdownOpen}
                  onClose={handleCloseDropdown}
                  PaperProps={paperPropsSx}
                >
                  <MenuItem onClick={handleRevertValues}>Revert to default values</MenuItem>
                </Menu>
              </>
            )}
          </Box>
        )}
      </Header>

      <Content className="content-wrapper">
        <Categories
          categoriesContainerRef={categoriesContainerRef}
          selectedCategoryItem={selectedCategoryItem}
          setSelectedCategoryItem={setSelectedCategoryItem}
          handleDeleteCategory={handleDeleteCategory}
          framework={framework as FrameworkType}
          thisPath={thisPath}
          categoriesForm={categoriesForm}
          setCategoriesForm={setCategoriesForm}
          evenWeights={evenWeights}
          hasEditRole={hasEditRole}
          initialLoad={initialLoad}
          categoriesTotal={categoriesTotal}
          setDiscardDisabled={setDiscardDisabled}
          setEdited={setEdited}
          categoryItemsPristine={categoryItemsPristine}
        />
        <SectionDivider
          height={
            !initialLoad
              ? 'calc(100vh - 125px)'
              : categoriesContainerRef && categoriesContainerRef.current
              ? `${categoriesContainerRef.current.clientHeight - 56}px`
              : `500px`
          }
        />
        <CPIs
          handleAddCpis={handleAddCpis}
          cpis={selectedCategoryItem ? selectedCategoryItem.cpis : []}
          handleDeleteCpi={handleDeleteCpi}
          selectedCategoryItem={selectedCategoryItem}
          categoriesForm={categoriesForm}
          setCategoriesForm={setCategoriesForm}
          setSelectedCategoryItem={setSelectedCategoryItem}
          evenWeights={evenWeights}
          hasEditRole={hasEditRole}
          initialLoad={initialLoad}
          setEdited={setEdited}
          framework={framework as FrameworkType}
          setDiscardDisabled={setDiscardDisabled}
        />
      </Content>

      <AddCpiDrawer
        drawerOpen={drawerOpen}
        setDrawerOpen={setDrawerOpen}
        selectedCategoryItem={selectedCategoryItem}
        framework={thisPath as FrameworkType}
        customCategories={categories}
        customFramework={customFramework}
        updateCategoryCpis={updateCategoryCpisDrawer}
        configuredIntegrations={configuredIntegrations}
        initialLoad={initialLoadSearch}
        searchValue={searchValue}
        setSearchValue={setSearchValue}
        data={searchData}
        selectedCpis={selectedCpis}
        setSelectedCpis={setSelectedCpis}
        dataPristine={searchDataPristine}
        setDiscardDisabled={setDiscardDisabled}
        searchData={searchedData}
        setCategoriesForm={setCategoriesForm}
        setData={setSearchData}
        setDataPristine={setSearchDataPristine}
        setInitialLoad={setInitialLoadSearch}
        setFilters={setFilters}
        isFetching={isFetching}
        setSelectedCategoryItem={setSelectedCategoryItem}
        categoriesFormCpis={categoriesForm.categories
          .map((n: any) =>
            n.cpis.map((c: any) => ({
              ...c,
              category: {
                id: n.id,
                name: n.name,
                shadowed: c.shadowed
              }
            }))
          )
          .flat()}
      />
    </Container>
  )
}

export default FrameworkConfiguration
