import { Box } from '@mui/material'
import styled from '@emotion/styled/macro'

export const Container = styled(Box)`
  display: flex;
  flex-direction: row;
  min-height: 100vh;
`

export const Wrapper = styled(Box)`
  display: flex;
  padding-left: 100px;
  max-width: 861px;
  height: fit-content;
  margin-top: 67px;
`
