import styled from '@emotion/styled/macro'
import { Box, Breadcrumbs, Button, Switch, Tabs as MuiTabs } from '@mui/material'

export const Container = styled(Box)`
  width: 100%;
`

export const AccountManagementHeaderContainer = styled(Box)`
  width: 100%;
  position: relative;
  z-index: 100;

  .MuiTab-root {
    text-transform: capitalize;
    padding-bottom: 8px;
  }

  .breadcrumbs-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    max-width: 100%;
    padding: 16px 0 0 24px;

    .header-actions {
      display: flex;
      align-items: center;

      .favorite-icon {
        margin-right: 34px;

        .MuiButtonBase-root {
          &:hover,
          &:focus,
          &:active {
            background: rgba(202, 196, 208, 0.08);
          }
        }
      }
    }
  }

  .title-wrapper {
    padding-left: 24px;

    &.title-wrapper {
      padding: 0 0 0 24px;

      .header-title {
        color: #fff;
        font-family: 'Quicksand', sans-serif;
        font-size: 32px;
        font-style: normal;
        font-weight: 400;
        line-height: 40px;
        text-transform: capitalize;
        margin-top: 21px;
        margin-bottom: 0;
      }

      .header-subtitle {
        font-family: 'Quicksand', sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 133.333% */
        letter-spacing: 0.25px;
        color: #e9e0e4;
      }
    }
  }
`

export const HeaderBackground = styled(Box)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url('/category-page-bg.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top center;
  z-index: -1;
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.15);
  filter: drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.3));
  mix-blend-mode: luminosity;
`

export const HeaderBreadcrumbs = styled(Breadcrumbs)`
  .MuiLink-root,
  .current-breadcrumb {
    font-family: 'Quicksand', sans-serif;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.5px;
    text-decoration: none;
  }

  .MuiLink-root {
    color: #debdd9;
  }

  .category-breadcrumb {
    color: #debdd9;
    font-family: 'Quicksand', sans-serif;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.5px;
    text-decoration: none;
  }

  .current-breadcrumb {
    color: #ffa9fc;
  }

  .MuiBreadcrumbs-separator {
    color: #debdd9;
  }
`

export const Tabs = styled(MuiTabs)`
  .MuiTabs-indicator {
    background-color: #ffa9fc;
  }

  .MuiTab-root {
    font-family: 'Quicksand', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.5px;
    color: #a18a9d;
    text-transform: capitalize;
  }

  .MuiTab-root.Mui-selected {
    color: #ffa9fc;
    border-bottom: 2px solid #ffa9fc;
  }

  .MuiButtonBase-root {
    padding-bottom: 30px;
    padding-left: 0;
    padding-right: 0;
    margin-right: 40px;
  }
`

export const Tab = styled(Button)<{ active: boolean }>`
  width: 50%;
  max-width: unset;
  min-width: unset;
  height: 64px;
  box-sizing: border-box;
  text-align: left;
  display: inline-flex;
  flex-flow: column wrap;
  justify-content: center;
  align-items: flex-start;
  border-radius: 0;

  .MuiTypography-root {
    padding-left: 40px;
    text-transform: none;
    font-family: 'Quicksand', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    line-height: 28px;

    /* Second row of title */

    &:nth-of-type(2) {
      color: ${({ active }) => (active ? '#D0C3CC' : '&C7579')};
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
      letter-spacing: 0.5px;
    }
  }

  svg {
    position: absolute;
    top: 10px;
    left: 10px;
  }

  &:first-of-type {
    border-bottom: 2px solid #ffa9fc;

    .MuiTypography-root {
      color: #e9e0e4;
    }

    svg {
      path {
        fill: #ffa9fc;
      }
    }
  }

  &:last-of-type {
    border-bottom: ${({ active }) => (active ? '2px solid #ffa9fc' : '2px solid #4d444b')};

    .MuiTypography-root {
      color: ${({ active }) => (active ? '#E9E0E4' : '#7C7579')};
    }

    svg {
      path {
        fill: ${({ active }) => (active ? '#FFA9FC' : '#7C7579')};
      }
    }
  }
`

export const EditFixedPanel = styled(Box)`
  margin: 0 auto 32px 0;
  display: flex;
  justify-content: flex-end;
  width: 555px;

  .panel-block {
    display: flex;
    align-items: center;
    gap: 8px;

    .text {
      color: #fff;
      font-family: 'Quicksand', sans-serif;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 16px;
      letter-spacing: 0.5px;
    }
  }
`

export const LockIconWrapper = styled(Box)`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
  border-radius: 100px;
  border: 1px solid #998d96;
  width: 40px;
  height: 40px;
`

export const UnlockIconWrapper = styled(Box)`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
  border-radius: 100px;
  background: #e9e0e4;
  width: 40px;
  height: 40px;
`

export const ContentWrapperMain = styled(Box)`
  margin: 32px auto;
  //overflow-y: scroll;
  //height: calc(100vh - 200px);
  //margin-top: 0;
  //padding-top: 40px;

  .loading-wrapper {
    display: flex;
    margin-top: 40px;
    justify-content: center;
  }
`

export const MaterialUISwitch = styled(Switch)(() => ({
  width: 79,
  height: 40,
  margin: 0,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    margin: 1,
    padding: 0,
    transform: 'translateX(3px)',
    '&.Mui-checked': {
      color: '#fff',
      transform: 'translateX(41px)',
      '& .MuiSwitch-thumb:before': {
        marginLeft: 1,
        backgroundImage: `url('/unlock.svg')`
      },
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: '#FFA9FC'
      }
    }
  },
  '& .MuiSwitch-thumb': {
    backgroundColor: '#FFF',
    width: 33,
    height: 33,
    marginTop: 2,
    '&:before': {
      content: "''",
      position: 'absolute',
      width: '100%',
      height: '100%',
      left: 0,
      top: 0,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      backgroundImage: `url('/lock.svg')`
    }
  },
  '& .MuiSwitch-track': {
    opacity: 1,
    backgroundColor: '#998D96',
    borderRadius: 36,
    height: '40px',
    minWidth: '79px'
  }
}))
