import { useEffect, FC, ChangeEvent, Dispatch, SetStateAction, useState } from 'react'
import { Box, Checkbox, Typography } from '@mui/material'

/* Utils */
import { CpiListItem } from '../AddCpiDrawer'
import {
  CPILibraryItemCategory,
  FrameworkCategoryResponseItem,
  FrameworkCategoryWeightItem,
  FrameworkType
} from '../../../../../models'
import { getCpiCategoryColors } from '../../../../../lib/utils'

/* Components */
import { Container } from './drawerCpiItem.styles'
import CpiChip from '../../../../components/cpi-chip'
import CpiActiveButton from '../../../../components/cpi-active-button'
import DataSources from '../../../../components/data-sources'
import CategoryChip from '../../../../components/category-chip'
import { CheckboxCheckedIcon, CheckboxIcon, InfoIconFilled } from 'ui/components/svg'
import { CategoryInfoTooltip } from '../../components/CategoryInfoTooltip'

interface Props {
  framework: FrameworkType
  cpi: CpiListItem
  cpiCategory?: CPILibraryItemCategory | null
  data: CpiListItem[]
  setData: Dispatch<SetStateAction<CpiListItem[]>>
  dataPristine: CpiListItem[]
  setDataPristine: Dispatch<SetStateAction<CpiListItem[]>>
  customCategories: FrameworkCategoryResponseItem[]
  setSelectedCpis: Dispatch<SetStateAction<CpiListItem[]>>
  selectedCpis: CpiListItem[]
  tabValue?: number
  setActionsDisabled: Dispatch<SetStateAction<boolean>>
  cpiSelected: boolean
  setCategoriesForm: any
  selectedCategoryItem: FrameworkCategoryWeightItem | undefined
  setSelectedCategoryItem: Dispatch<SetStateAction<FrameworkCategoryWeightItem | undefined>>
}

const DrawerCpiItem: FC<Props> = ({
  data,
  setData,
  framework,
  cpi,
  cpiCategory,
  customCategories,
  setSelectedCpis,
  selectedCpis,
  tabValue,
  setDataPristine,
  dataPristine,
  setActionsDisabled,
  cpiSelected
  // setCategoriesForm,
  // selectedCategoryItem,
  // setSelectedCategoryItem
}) => {
  const [checked, setChecked] = useState(cpiSelected)

  useEffect(() => {
    setChecked(cpiSelected)
  }, [cpiSelected])

  const handleChange = (e: ChangeEvent<HTMLInputElement>, checked: boolean) => {
    setChecked(checked)

    const list = [...data]
    const newList = list.map((n) =>
      n.display_name === cpi.display_name
        ? { ...n, selected: checked, category: { ...n.category, shadowed: checked } }
        : n
    )
    const allCpiList = [...dataPristine]
    setData(newList)
    setDataPristine(allCpiList.map((n) => (n.display_name === cpi.display_name ? { ...n, selected: checked } : n)))
    setActionsDisabled(false)

    if (tabValue && tabValue === 1) {
      const selectedList = [...selectedCpis].map((n) =>
        n.display_name === cpi.display_name ? { ...n, selected: checked } : n
      )
      setSelectedCpis(selectedList)
    } else {
      setSelectedCpis(newList.filter((n) => n.selected))
    }
  }

  const getChipBgColors = () => {
    const res = cpiCategory
      ? customCategories.find((n) =>
          n.title === 'Vulnerability Management'
            ? cpiCategory?.title === 'Vulnerabilty Management'
            : n.title === cpiCategory?.title
        )
      : undefined

    if (res) {
      return {
        chipBgColor: res.chip_bg_color,
        chipColor: res.chip_color
      }
    } else {
      const colors = getCpiCategoryColors(cpiCategory?.id || '')

      return {
        chipBgColor: colors.background,
        chipColor: colors.color
      }
    }
  }

  return (
    <Container className="drawer-cpi-item-container" isactive={cpi.isActive ? 'true' : 'false'}>
      <Checkbox
        checked={checked}
        onChange={handleChange}
        icon={<CheckboxIcon />}
        checkedIcon={<CheckboxCheckedIcon />}
      />
      <Box className="inner">
        <Box className="inner-header">
          <CpiChip text={cpi.display_name} />
          <Typography className="title">{cpi.title}</Typography>
        </Box>
        <Box className="inner-footer">
          {cpiCategory && cpiCategory.title && (
            <Box className="category-chip-group">
              <CategoryChip
                framework={framework}
                categoryId={cpiCategory.id}
                categoryName={
                  cpiCategory.title.toLowerCase() === 'iam' ? 'Identity & Access Management' : cpiCategory.title
                }
                chipColors={getChipBgColors()}
              />

              <CategoryInfoTooltip
                placement="bottom-start"
                title={
                  <>
                    <Box className="tooltip-wrapper">
                      <InfoIconFilled className="info-icon" />
                      <Typography className="tooltip-text">
                        {cpi.display_name} has already been assigned to "{cpiCategory.title}". Selecting this CPI will
                        remove it from that Category. Please make sure to adjust the CPI weights for "
                        {cpiCategory.title}" after you removed the CPI from it.
                      </Typography>
                    </Box>
                  </>
                }
              >
                <svg
                  width="16"
                  height="17"
                  viewBox="0 0 16 17"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="info-icon"
                >
                  <path
                    d="M7.33325 5.16683H8.66659V6.50016H7.33325V5.16683ZM7.33325 7.8335H8.66659V11.8335H7.33325V7.8335ZM7.99992 1.8335C4.31992 1.8335 1.33325 4.82016 1.33325 8.50016C1.33325 12.1802 4.31992 15.1668 7.99992 15.1668C11.6799 15.1668 14.6666 12.1802 14.6666 8.50016C14.6666 4.82016 11.6799 1.8335 7.99992 1.8335ZM7.99992 13.8335C5.05992 13.8335 2.66659 11.4402 2.66659 8.50016C2.66659 5.56016 5.05992 3.16683 7.99992 3.16683C10.9399 3.16683 13.3333 5.56016 13.3333 8.50016C13.3333 11.4402 10.9399 13.8335 7.99992 13.8335Z"
                    fill="white"
                  />
                </svg>
              </CategoryInfoTooltip>
            </Box>
          )}
          <DataSources
            dataSources={cpi.configuration ? cpi.configuration.dataSources || [] : cpi.supported_integrations || []}
          />
          <CpiActiveButton active={cpi.isActive} short />
        </Box>
      </Box>
    </Container>
  )
}

export default DrawerCpiItem
