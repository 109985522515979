import { Box } from '@mui/material'
import styled from '@emotion/styled/macro'

export const IntegrationCardContainer = styled(Box)<{ expanded: string; hasFailed: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px;
  width: 100%;
  position: relative;

  ${({ expanded }) =>
    expanded === 'true' &&
    `
      border: 2px solid rgba(255, 169, 252, 0.89);
      border-radius: 22px;
      margin-top: 16px;
    `};

  .MuiAccordion-root {
    background: #131013;
    border-radius: 12px;
    width: 100%;

    .MuiAccordionSummary-root {
      background: linear-gradient(0deg, rgba(255, 169, 252, 0.11), rgba(255, 169, 252, 0.11)), #1e1a1d;
      border-radius: 12px;
      height: 80px;

      ${({ hasFailed }) =>
        hasFailed &&
        `
          height: 116px;
      `};

      .MuiAccordionSummary-content {
        margin: 12px 0;
      }
    }

    .MuiCollapse-root {
      padding-top: 16px;
      background: #131013;

      .MuiCollapse-wrapper {
        .MuiAccordionDetails-root {
          background: linear-gradient(0deg, rgba(255, 169, 252, 0.11), rgba(255, 169, 252, 0.11)), #1e1a1d;
          border-radius: 12px;

          &:not(:last-of-type) {
            margin-bottom: 16px;
          }
        }
      }

      ${({ expanded }) =>
        expanded === 'false' &&
        `
          visibility: hidden;
          padding: 0;
      `};
    }
  }
`

export const IntegrationCardWrapper = styled(Box)<{ expanded: string }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  min-height: 80px;

  .description {
    .header {
      font-family: 'Quicksand', sans-serif;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.1px;
      color: #e9e0e4;
    }

    .subheader {
      font-family: 'Quicksand', sans-serif;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.25px;
      color: #d0c3cc;
    }

    .subheader-empty {
      font-family: 'Quicksand', sans-serif;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.25px;
      color: #d0c3cc;
      opacity: 0 !important;
      visibility: hidden !important;
    }
  }

  .actions {
    display: flex;
    align-items: center;

    .instance-button {
      height: 40px;
      border: 1px solid #998d96;
      border-radius: 100px;
      padding: 0 14px 0 24px;
      gap: 8px;
      font-family: 'Quicksand', sans-serif;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      display: flex;
      align-items: center;
      letter-spacing: 0.1px;
      color: #ffa9fc;
      text-transform: none;

      &.Mui-disabled {
        color: #e9e0e4;
        opacity: 0.38;
      }

      svg {
        transition: transform 0.3s;

        ${({ expanded }) =>
          expanded === 'true' &&
          `
          transform: rotate(180deg);
      `};
      }
    }

    .icon-button {
      padding: 0;
      margin-left: 20px;
    }
  }
`

export const WarningMessageContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding: 4px 8px;
  width: fit-content;
  margin-top: 8px;

  border-radius: 8px;
  border: 1px solid #ff8a80;
  background: rgba(255, 138, 128, 0.2);

  .warning-text {
    color: #fff;
    font-family: Quicksand, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.1px;
  }
`

export const InstanceCardContainer = styled(Box)<{ isSelected: string; hasFailed: boolean }>`
  background: linear-gradient(0deg, rgba(255, 169, 252, 0.14), rgba(255, 169, 252, 0.14)), #1e1a1d;
  border-radius: 12px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px;
  width: 100%;
  margin-top: 32px;
  max-height: 80px;
  height: 80px;

  ${({ hasFailed }) =>
    hasFailed &&
    `
      height: 116px;
      max-height: unset;
  `};

  &:first-of-type {
    margin-top: 0;
  }

  ${({ isSelected }) =>
    isSelected === 'true' &&
    `
    border: 1px solid #FFA9FC;
  `};

  .instance-card-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    min-height: 80px;

    .description {
      .header {
        font-family: 'Quicksand', sans-serif;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.1px;
        color: #e9e0e4;
        text-transform: capitalize;
      }

      .subheader {
        font-family: 'Quicksand', sans-serif;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.25px;
        color: #d0c3cc;
      }

      .subheader-empty {
        font-family: 'Quicksand', sans-serif;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.25px;
        color: #d0c3cc;
        opacity: 0 !important;
        visibility: hidden !important;
      }
    }

    .actions {
      display: flex;
      align-items: center;

      .instance-button {
        height: 40px;
        border: 1px solid #998d96;
        border-radius: 100px;
        padding: 0 14px 0 24px;
        gap: 8px;
        font-family: 'Quicksand', sans-serif;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        display: flex;
        align-items: center;
        letter-spacing: 0.1px;
        color: #ffa9fc;
        text-transform: none;

        svg {
          transition: transform 0.3s;
        }
      }

      .icon-button {
        padding: 0;
        margin-left: 20px;
      }
    }
  }
`

export const NewIntegrationMarkerContainer = styled(Box)<{ expanded: string }>`
  position: absolute;
  top: ${({ expanded }) => (expanded === 'true' ? '24px' : '8px')};
  left: 4px;
  z-index: 100;
  font-family: 'Quicksand', sans-serif;
  font-weight: 500;
  font-size: 11px;
  line-height: 16px;
  letter-spacing: 0.5px;
  color: #ffffff;
  display: flex;
  align-items: center;
  padding: 6px 16px 6px 8px;
  gap: 8px;
  width: 73px;
  height: 18px;
  background: #3754fc;
  border-radius: 24px;
`
