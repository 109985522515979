import { FC, useEffect, useState } from 'react'
import { Typography } from '@mui/material'

/* Utils */
import { CompetitorAnalysisPerformanceMetric } from '../../../../../../../../models'

/* Components */
import { getSlaUnit } from '../../../../../utils'
import { AnalysisItem, AnalysisItemDataContainer } from '../competitorAnalysis.styles'
import CompetitorAnalysisSlider from './CompetitorAnalysisSlider'

export interface Mark {
  label: string
  value: number
}

interface Props {
  item: CompetitorAnalysisPerformanceMetric
  sla?: number
  unit: string
  active: boolean
  name: string
  healthDirection: string
}

const CompetitorAnalysisItem: FC<Props> = ({ item, active, sla, unit, healthDirection }) => {
  const [slaPosition, setSlaPosition] = useState(0)
  const [avgIndustryPosition, setAvgIndustryPosition] = useState(0)

  const measurementUnit = getSlaUnit(unit)

  const getMarks = (): Mark[] => {
    const low = item.summary.performance_range.low
    const high = item.summary.performance_range.high
    const diff = high - low
    const step = Math.round(diff / 5)
    const numOfSteps = [1, 2, 3, 4, 5, 6]

    return numOfSteps.map((n, i) => ({
      value: i * step,
      label: `${i * step}${measurementUnit}`
    }))
  }

  const marks = getMarks()
  const max = marks[marks.length - 1].value

  useEffect(() => {
    if (sla) {
      setSlaPosition((sla * 100) / max)
    }
    setAvgIndustryPosition((item.summary.industry_performance_avg * 100) / max)
  }, [item, sla])

  const highRangeLeft = (item.summary.top_performance_range.low * 100) / max
  let highRangeWidth = ((item.summary.top_performance_range.high - item.summary.top_performance_range.low) * 100) / max

  const lowRangeLeft = (item.summary.bottom_performance_range.low * 100) / max
  let lowRangeWidth = (item.summary.bottom_performance_range.high * 100) / max - lowRangeLeft

  const lowRangeRight = max - (item.summary.bottom_performance_range.high * 100) / max
  const highRangeRight = max - (item.summary.top_performance_range.high * 100) / max

  let barWidth = ((item.summary.bottom_performance_range.high - item.summary.top_performance_range.low) * 100) / max
  let barLeft = highRangeLeft

  const isReverse = item.summary.bottom_performance_range.high < item.summary.top_performance_range.high
  if (isReverse) {
    barWidth = ((item.summary.top_performance_range.high - item.summary.bottom_performance_range.low) * 100) / max
    barLeft = lowRangeLeft
  }
  if (healthDirection === 'asc') {
    highRangeWidth = ((item.summary.top_performance_range.high - item.summary.top_performance_range.low) * 100) / max
    lowRangeWidth =
      ((item.summary.bottom_performance_range.high - item.summary.bottom_performance_range.low) * 100) / max
  }

  return (
    <AnalysisItem key={item.industry_id}>
      <Typography className="analysis-category-text">{item.industry_name}</Typography>
      <AnalysisItemDataContainer>
        <CompetitorAnalysisSlider
          sla={sla}
          active={active}
          item={item}
          measurementUnit={measurementUnit}
          unit={unit}
          slaPosition={slaPosition}
          avgIndustryPosition={avgIndustryPosition}
          marks={marks}
          lowRangeLeft={lowRangeLeft}
          highRangeLeft={highRangeLeft}
          lowRangeWidth={lowRangeWidth}
          highRangeWidth={highRangeWidth}
          healthDirection={healthDirection}
          lowRangeRight={lowRangeRight}
          highRangeRight={highRangeRight}
          barWidth={barWidth}
          barLeft={barLeft}
          isReverse={isReverse}
          max={max}
        />
      </AnalysisItemDataContainer>
    </AnalysisItem>
  )
}

export default CompetitorAnalysisItem
