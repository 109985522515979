import { FC, ReactNode, useState } from 'react'
import { FeedSectionWrapper, IntelFeedTabPanel, IntelFeedTabs, IntelFeedWrapper } from '../../../dashboard.styles'
import { sidePanelWidth } from '../../../utils'
import { Box, CircularProgress, Tab, Typography } from '@mui/material'
import { IntelFeedIcon } from '../../../../../components/svg'
import StubIntelFeedCard from './Stubs/StubIntelFeedCard'

interface TabPanelProps {
  children?: ReactNode
  index: number
  value: number
  feedHeight: number
  loading: boolean
}

interface Props {
  feedHeight: number
}

const StubFeedSection: FC<Props> = ({ feedHeight }) => {
  const [selectedTab, setSelectedTab] = useState(0)

  return (
    <FeedSectionWrapper>
      <IntelFeedWrapper width={sidePanelWidth}>
        <Box className="header">
          <IntelFeedIcon />
          <Typography>Intel Feed</Typography>
        </Box>
        <IntelFeedTabs
          value={false}
          onChange={(e, newValue) => setSelectedTab(newValue)}
          aria-label="intel feed tabpanel loading"
          TabIndicatorProps={{ style: { display: 'none' } }}
        >
          <Tab label="Explore" {...a11yProps(0)} />
          <Tab label="Saved" {...a11yProps(1)} />
        </IntelFeedTabs>
        <TabPanel value={selectedTab} index={0} feedHeight={feedHeight - 35} loading={false}>
          <StubIntelFeedCard />
          <StubIntelFeedCard />
          <StubIntelFeedCard />
          <StubIntelFeedCard />
          <StubIntelFeedCard />
          <StubIntelFeedCard />
          <StubIntelFeedCard />
          <StubIntelFeedCard />
          <StubIntelFeedCard />
          <StubIntelFeedCard />
          <StubIntelFeedCard />
          <StubIntelFeedCard />
          <StubIntelFeedCard />
          <StubIntelFeedCard />
          <StubIntelFeedCard />
        </TabPanel>
        <TabPanel value={selectedTab} index={1} feedHeight={feedHeight - 35} loading={false}>
          <StubIntelFeedCard />
          <StubIntelFeedCard />
          <StubIntelFeedCard />
          <StubIntelFeedCard />
          <StubIntelFeedCard />
          <StubIntelFeedCard />
          <StubIntelFeedCard />
          <StubIntelFeedCard />
          <StubIntelFeedCard />
          <StubIntelFeedCard />
          <StubIntelFeedCard />
          <StubIntelFeedCard />
          <StubIntelFeedCard />
          <StubIntelFeedCard />
          <StubIntelFeedCard />
          <StubIntelFeedCard />
          <StubIntelFeedCard />
          <StubIntelFeedCard />
          <StubIntelFeedCard />
          <StubIntelFeedCard />
          <StubIntelFeedCard />
        </TabPanel>
      </IntelFeedWrapper>
    </FeedSectionWrapper>
  )
}

function a11yProps(index: number) {
  return {
    id: `intel-feed-tab-loading-${index}`,
    'aria-controls': `intel-feed-tabpanel-loading-${index}`
  }
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, feedHeight, loading, ...other } = props

  return (
    <IntelFeedTabPanel
      style={{ height: `${feedHeight}px` }}
      role="tabpanel"
      hidden={value !== index}
      id={`intel-feed-tabpanel-loading-${index}`}
      aria-labelledby={`intel-feed-tab-loading-${index}`}
      {...other}
    >
      {value === index && children}

      {loading && (
        <Box display="flex" justifyContent="center" style={{ marginTop: '15px' }}>
          <CircularProgress size="36px" color="primary" />
        </Box>
      )}
    </IntelFeedTabPanel>
  )
}

export default StubFeedSection
