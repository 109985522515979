import React, { ChangeEvent, Dispatch, FC, SetStateAction, useEffect, useState } from 'react'

/* Utils */
import { CategoriesCpisWeightI, FormWeightI } from '../../../../models'

/* Components */
import { Container } from './styles/CategorySection.styles'
import { Box, InputAdornment, TextField, Typography } from '@mui/material'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import { ExpandMoreSvg } from '../../../components/svg'
import TotalPercentageNotify from './TotalPercentageNotify'

interface Props {
  isEdit: boolean
  category: CategoriesCpisWeightI
  form: FormWeightI
  categoryCpis: any
  setForm: Dispatch<SetStateAction<any>>
  setIsChanged: Dispatch<SetStateAction<boolean>>
  setIsFormValid: Dispatch<SetStateAction<boolean>>
}

const CategorySection: FC<Props> = ({
  isEdit,
  category,
  form,
  categoryCpis,
  setForm,
  setIsChanged,
  setIsFormValid
}) => {
  const [isExpanded, setIsExpanded] = useState(false)
  const [isValid, setIsValid] = useState(false)
  const [total, setTotal] = useState(100)

  useEffect(() => {
    setIsFormValid(isValid)
  }, [isValid])

  useEffect(() => {
    let sum = 0
    const findItem = form.categoryCpis.find((item) => item.id === category.id)?.cpis

    if (!findItem) return
    findItem.forEach((item: any) => {
      sum += +item.weight
    })

    setTotal(Math.round(sum))
    setIsValid(Math.round(sum) === 100)
  }, [form])

  const expandHandler = (event: React.SyntheticEvent, expanded: boolean) => {
    setIsExpanded(expanded)
  }

  const handleInput = (e: ChangeEvent<HTMLInputElement>) => {
    const updatedCategoryCpis = form.categoryCpis.map((category) => {
      let isEdit = category.isEdit || false
      const tempCpis = category.cpis.map((cpi) => {
        if (cpi.name === e.target.name) {
          isEdit = true
          return {
            ...cpi,
            weight: +e.target.value,
            isEdit: true
          }
        }
        return cpi
      })

      return {
        ...category,
        isEdit: isEdit,
        cpis: tempCpis
      }
    })

    const updatedForm = {
      ...form,
      categoryCpis: updatedCategoryCpis
    }

    setIsChanged(true)
    setForm(updatedForm)
  }

  const resetCategory = () => {
    const findItem = categoryCpis.find((item: { id: string }) => item.id === category.id)

    const updatedCategoryCpis = form.categoryCpis.map((categoryItem) => {
      if (categoryItem.id === category.id) {
        categoryItem = findItem
      }
      return categoryItem
    })

    setForm({
      ...form,
      categoryCpis: updatedCategoryCpis
    })
  }

  const sortCpis = (a: any, b: any) => {
    if (a.displayName < b.displayName) return -1
    if (a.displayName > b.displayName) return 1
    return 0
  }

  return (
    <Container>
      <Accordion onChange={expandHandler}>
        <AccordionSummary expandIcon={<ExpandMoreSvg />} aria-controls="panel1-content" id="panel1-header">
          <Typography className="title">{category.name} CPI Weights</Typography>
          <Typography className="desc">{category.cpis.length} active</Typography>
          {!isValid && !isExpanded ? (
            <TotalPercentageNotify isValid={isValid} show={true} total={total} resetCategory={resetCategory} />
          ) : (
            ''
          )}
        </AccordionSummary>
        <AccordionDetails>
          <Box className="content-block">
            <Box className="header">
              <Typography className="name">Cyber Performance Indicator</Typography>
              <Typography className="value">Weights</Typography>
            </Box>
            <Box className="content">
              {category.cpis.sort(sortCpis).map((cpi) => {
                return (
                  <Box className="content-item">
                    <Box className="cpi-block">
                      <Typography className="cpi">{cpi.displayName}</Typography>
                      <Typography className="name">{cpi.title}</Typography>
                    </Box>
                    {isEdit ? (
                      <>
                        <TextField
                          className={
                            String(cpi.weight).length === 3
                              ? 'length3'
                              : String(cpi.weight).length === 2
                              ? 'length2'
                              : 'length1'
                          }
                          variant="outlined"
                          name={cpi.name}
                          required
                          inputProps={{ min: 1, value: cpi.weight ? cpi.weight : '', max: 100 }}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="start" className="percentage">
                                %
                              </InputAdornment>
                            )
                          }}
                          onChange={handleInput}
                        />
                      </>
                    ) : (
                      <Typography className="value">{cpi.weight}%</Typography>
                    )}
                  </Box>
                )
              })}
            </Box>

            <Box className="line" />

            <TotalPercentageNotify isValid={isValid} show={true} total={total} resetCategory={resetCategory} />
          </Box>
        </AccordionDetails>
      </Accordion>
    </Container>
  )
}

export default CategorySection
