import { ChangeEvent, FC, Dispatch, SetStateAction } from 'react'
import { OutlinedInput, Typography } from '@mui/material'
import { FrameworkCategoryWeightItem, FrameworkType } from '../../../../../../../models'

interface Props {
  framework: FrameworkType
  hasEditRole: boolean
  categoryItem: FrameworkCategoryWeightItem
  handleChange: (e: ChangeEvent<HTMLInputElement>, field: 'weight' | 'category') => void
  setDiscardDisabled: Dispatch<SetStateAction<boolean>>
  setEdited: Dispatch<SetStateAction<boolean>>
  handleBlur: (e: any) => void
}

const CategoryName: FC<Props> = ({
  framework,
  hasEditRole,
  categoryItem,
  handleChange,
  setDiscardDisabled,
  setEdited,
  handleBlur
}) => {
  if (framework === FrameworkType.Custom && hasEditRole) {
    return (
      <OutlinedInput
        inputProps={{ 'aria-label': 'category', maxLength: 30 }}
        name={categoryItem.id}
        value={categoryItem.name}
        onChange={(e: ChangeEvent<HTMLInputElement>) => {
          handleChange(e, 'category')
          setDiscardDisabled(false)
          setEdited(true)
        }}
        onBlur={handleBlur}
      />
    )
  }
  return <Typography className="category-text">{categoryItem.name}</Typography>
}

export default CategoryName
