import { tr } from 'date-fns/locale'

export enum OperatingSystem {
  'All' = 'All',
  'Linux' = 'Linux',
  'Mac' = 'MacOS',
  'Mobile' = 'Mobile',
  'Windows' = 'Windows'
}

export enum LastSeenWithin {
  'All' = 'All',
  '7 days' = '7 days',
  '14 days' = '14 days',
  '30 days' = '30 days',
  '60 days' = '60 days',
  '90 days' = '90 days'
}

export const defaultTableHeaders = [
  { name: 'Coverage', dataKey: 'coverage', sort: false, sortType: 'asc', show: true },
  { name: 'Host Name', dataKey: 'hostName', sort: false, sortType: 'asc', show: true },
  { name: 'Original Serial Number', dataKey: 'originalSerialNumber', sort: false, sortType: 'asc', show: true },
  { name: 'Mac Address', dataKey: 'macAddress', sort: false, sortType: 'asc', show: true },
  { name: 'Last Seen', dataKey: 'lastSeen', sort: true, sortType: 'asc', show: true },
  { name: 'Operating System', dataKey: 'operatingSystem', sort: false, sortType: 'asc', show: true },
  { name: 'Matched By', dataKey: 'matchedBy', sort: false, sortType: 'asc', show: true }
]

export interface TableHeaders {
  name: string
  dataKey: string
  show: boolean
  sort: boolean
  sortType: string
}

export interface DefaultTableHeader {
  name: string
  show: boolean
}

export enum AcaOperatingSystem {
  All = 'all',
  Linux = 'linux',
  MacOS = 'macos',
  Mobile = 'mobile',
  Windows = 'windows'
}

export enum AcaLastSeen {
  'All' = 'all',
  '7 days' = '7 days',
  '14 days' = '14 days',
  '30 days' = '30 days',
  '60 days' = '60 days',
  '90 days' = '90 days'
}

export enum AcaCoverageFilter {
  Intersection = 'intersection',
  SymmetricDifference = 'symmetric_difference',
  FirstNotSecond = 'first_not_second',
  SecondNotFirst = 'second_not_first'
}

export interface AcaComparisonPayload {
  operating_system: AcaOperatingSystem
  integration_ids: string[]
  last_seen: AcaLastSeen
  coverage?: AcaCoverageFilter
  search_term?: string
  sort_by?: string
  sort_direction?: string
  page_number?: number
}

export interface AnalyzerDataValue {
  xValue: string
  yValue: number
}

export interface AnalyzerDataValueFilled {
  xValue: string
  yValue: number
  dummy?: boolean
}

export interface AnalyzerChartData {
  id: string
  integrationName: string
  instanceName: string
  status: string
  values: AnalyzerDataValue[]
}

export interface AnalyzerData {
  [key: string]: AnalyzerDataValue[]
}

export interface AcaConfigurationIntegration {
  id: string
  name: string
  deleted: boolean
  config_name: string
  data_status: string
}

export interface AcaConfiguration {
  tenant_id: string
  tenant_slug: string
  data: {
    integrations: AcaConfigurationIntegration[]
  }
  created_at: string
  created_by: string
  updated_at: string
  updated_by: string
}

export interface AnalyzerDataValueExtended {
  xValue: string
  y1Value: number
  y2Value: number
  start?: string
  end?: string
  dummy?: boolean
}

export interface AnalyzerDataExtended {
  values: AnalyzerDataValueExtended[]
}

export interface DummyObjectAca {
  xValue: string
  yValue: number
  dummy: boolean
}

export interface AnalyzerAnalysisItem {
  instanceName: string
  id: string
  hostNameFirst: string
  hostNameSecond: string
  url: string
}

export interface AnalysisData {
  covered_by: string
  matched_by: string
  host_name: string
  last_seen: string
  mac_address: string
  operating_system: string
  serial_number: string
  [key: string]: any
}

export interface AnalysisDataTable {
  coverage: string
  matchedBy: string
  hostName: string
  originalSerialNumber: string
  macAddress: string
  lastSeen: string
  operatingSystem: string
  sameHostName?: boolean
  sameMacAddress?: boolean
  sameOperatingSystem?: boolean
  sameSerialNumber?: boolean
  secondHostName?: string
  secondMacAddress?: string
  secondOperatingSystem?: string
  secondSerialNumber?: string
  secondLastSeen?: string
}

export interface AnalysisDataCoverage {
  integrationName: string
  instanceName: string
  src: string[]
}

export interface AnalysisDataItem {
  coverage: AnalysisDataCoverage
  serial_number: string
  original_serial_number1: string
  original_serial_number2: string
  host_name1: string
  host_name2: string
  last_seen1: string
  last_seen2: string
  operating_system1: string
  operating_system2: string
  mac_address1: string
  mac_address2: string
  key: string
}

export interface AnalysisDataResponseBoth {
  [key: string]: AnalysisData
}

export interface AnalysisDataResponse {
  // @ts-ignore
  both?: AnalysisDataResponseBoth[]

  [key: string]: AnalysisData[] | undefined
}
