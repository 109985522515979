import { FC, SVGProps } from 'react'

const VisibilityOffIcon: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M12 6.4748C15.79 6.4748 19.17 8.6048 20.82 11.9748C20.23 13.1948 19.4 14.2448 18.41 15.0948L19.82 16.5048C21.21 15.2748 22.31 13.7348 23 11.9748C21.27 7.58481 17 4.4748 12 4.4748C10.73 4.4748 9.51 4.6748 8.36 5.0448L10.01 6.6948C10.66 6.5648 11.32 6.4748 12 6.4748ZM10.93 7.6148L13 9.6848C13.57 9.9348 14.03 10.3948 14.28 10.9648L16.35 13.0348C16.43 12.6948 16.49 12.3348 16.49 11.9648C16.5 9.4848 14.48 7.4748 12 7.4748C11.63 7.4748 11.28 7.5248 10.93 7.6148ZM2.01 4.3448L4.69 7.02481C3.06 8.3048 1.77 10.0048 1 11.9748C2.73 16.3648 7 19.4748 12 19.4748C13.52 19.4748 14.98 19.1848 16.32 18.6548L19.74 22.0748L21.15 20.6648L3.42 2.9248L2.01 4.3448ZM9.51 11.8448L12.12 14.4548C12.08 14.4648 12.04 14.4748 12 14.4748C10.62 14.4748 9.5 13.3548 9.5 11.9748C9.5 11.9248 9.51 11.8948 9.51 11.8448ZM6.11 8.4448L7.86 10.1948C7.63 10.7448 7.5 11.3448 7.5 11.9748C7.5 14.4548 9.52 16.4748 12 16.4748C12.63 16.4748 13.23 16.3448 13.77 16.1148L14.75 17.0948C13.87 17.3348 12.95 17.4748 12 17.4748C8.21 17.4748 4.83 15.3448 3.18 11.9748C3.88 10.5448 4.9 9.3648 6.11 8.4448Z"
      fill="#DEBDD9"
    />
  </svg>
)

export default VisibilityOffIcon
