import React, { ChangeEvent, Dispatch, SetStateAction, forwardRef } from 'react'
import { Box, InputAdornment, TextField, Typography } from '@mui/material'
import { FrameworkCategoryWeightItem } from '../../../../../../../models'

type Ref = HTMLButtonElement | null

interface Props {
  hasEditRole: boolean
  categoryItem: FrameworkCategoryWeightItem
  handleChange: (e: ChangeEvent<HTMLInputElement>, field: 'weight' | 'category') => void
  setDiscardDisabled: Dispatch<SetStateAction<boolean>>
  textFieldClassName: string
  setEdited: Dispatch<SetStateAction<boolean>>
}

const CategoryWeight = forwardRef<Ref, Props>(
  ({ textFieldClassName, hasEditRole, categoryItem, handleChange, setDiscardDisabled, setEdited }, ref) => {
    if (hasEditRole && categoryItem.cpis.length > 0 && categoryItem.cpis.some((n) => n.isActive)) {
      const preventMinus = (e: any) => {
        if (e.code === 'Minus') {
          e.preventDefault()
        }
      }

      return (
        <TextField
          variant="outlined"
          name={categoryItem.name}
          required
          className={textFieldClassName}
          autoComplete="off"
          inputRef={ref}
          inputProps={{
            min: 1,
            value: !isNaN(categoryItem.weight) ? categoryItem.weight : 0,
            max: 100,
            'aria-label': 'weight'
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="start" className="percentage">
                %
              </InputAdornment>
            ),
            onKeyPress: preventMinus
          }}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            const val = parseInt(e.target.value, 10)
            if (val < 0 || val > 100) {
              e.preventDefault()
            } else {
              handleChange(e, 'weight')
              setDiscardDisabled(false)
              setEdited(true)
            }
          }}
        />
      )
    }
    return (
      <Box className="no-role-weights-wrapper">
        <Typography className="category-text">{categoryItem.weight || 0}%</Typography>
      </Box>
    )
  }
)

export default CategoryWeight
