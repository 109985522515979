import {
  Box,
  Paper,
  Button,
  Select,
  TextField,
  Typography,
  IconButton,
  Chip as MuiChip,
  Checkbox as MuiCheckbox,
  TableBody as MuiTableBody,
  TableCell as MuiTableCell,
  InputAdornment as MuiInputAdornment,
  FormControlLabel as MuiFormControlLabel
} from '@mui/material'
import styled from '@emotion/styled/macro'
import { colors } from '../../../../theme'

export const TeamManagementContainer = styled(Box)`
  padding-bottom: 0;
  margin-top: 40px;
  height: auto;
`

export const Container = styled(Box)`
  height: 100%;
  border-radius: 8px;
  margin-left: 36px;
  margin-right: 44px;
  padding-bottom: 35px;
  position: relative;
`

export const Wrapper = styled(Box)`
  padding: 0 34px 26px;
  min-width: 800px;
  background-color: ${colors.primary};
  border-radius: 20px 20px 0 0;
  height: 100%;
`

export const TableContainer = styled.div`
  height: auto;
`

export const Table = styled.table`
  border-collapse: separate;
  border-spacing: 0 20px;
  width: 100%;

  .MuiTableHead-root .MuiTableRow-root th {
    border-bottom: 1px solid #6e6699;
    padding-bottom: 8px;
    text-align: left;
    font-weight: 600;
    font-size: 18px;
    line-height: 21px;
    color: #f5f5f5;
    padding-top: 12px;

    &:not(:first-of-type) {
      padding-left: 16px;
    }
  }
`

export const TableBody = styled(MuiTableBody)`
  .MuiTableRow-root:not(:first-of-type) {
    margin: 16px 0;
  }

  .MuiTableRow-root {
    cursor: pointer;

    .MuiTableCell-root {
      .MuiTypography-root {
        font-weight: 400;
        color: #f5f5f5;
      }

      :nth-of-type(2) {
        .MuiTypography-root {
          text-transform: capitalize;
          font-size: 14px;
          line-height: normal;
        }
      }

      :nth-of-type(3) {
        .MuiTypography-root {
          font-size: 14px;
          line-height: normal;
          font-weight: 700;
        }
      }

      :nth-of-type(6) {
        .MuiTypography-root {
          font-size: 14px;
          line-height: normal;
        }
      }

      @media (min-width: 900px) {
        :nth-of-type(1) {
          .MuiTypography-root {
            width: 200px !important;
          }
        }
      }

      @media (min-width: 1140px) {
        :nth-of-type(1) {
          .MuiTypography-root {
            width: 320px !important;
          }
        }
      }

      @media (min-width: 1240px) {
        :nth-of-type(1) {
          .MuiTypography-root {
            width: 420px !important;
          }
        }
      }

      @media (min-width: 1440px) {
        :nth-of-type(1) {
          .MuiTypography-root {
            width: 520px !important;
          }
        }

        :nth-of-type(2) {
          .MuiTypography-root {
            width: 120px !important;
          }
        }

        :nth-of-type(3) {
          .MuiTypography-root {
            width: 100px !important;
          }
        }
      }

      .MuiTypography-root {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #f5f5f5;

        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    :first-of-type {
      padding-left: 38px;
    }
  }
`

export const TableCell = styled(MuiTableCell)`
  border: none;
  color: ${colors.white};
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
`

const rowHeight = 60

export const rowStyle = {
  backgroundColor: 'rgba(44, 41, 61, 0.25)',
  boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
  borderRadius: '8px',
  height: rowHeight,
  width: '100%'
}

export const Chip = styled(MuiChip)<{ status: string }>`
  border-radius: 12px;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  background-color: #cfd8dc;
  border-color: #cfd8dc;
  height: auto;
  cursor: pointer;

  .MuiChip-label {
    padding: 2px 12px;
  }

  ${({ status }) =>
    status === 'Deleted' &&
    `
    background-color: #EF9A9A;
    border-color: #EF9A9A;
  `};
`

export const FormControlLabel = styled(MuiFormControlLabel)`
  .MuiTypography-root {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #dbdbdb;
  }

  svg {
    width: 16px;
    height: 16px;
  }
`

export const Checkbox = styled(MuiCheckbox)`
  color: #fff;

  &.Mui-checked {
    color: #fff;
  }
`

export const ControlsWrapper = styled(Box)`
  display: flex;
  justify-content: space-between;
  flex-flow: column wrap;

  @media (min-width: 1150px) {
    flex-flow: row nowrap;
  }
`

export const SearchWrapper = styled(Box)`
  display: flex;
  align-items: center;
  margin-bottom: 24px;
`

export const SearchPaper = styled(Paper)`
  padding: 2px 4px;
  display: flex;
  align-items: center;
  width: 600px;
  background: transparent;
  border: 1px solid #acacac;
  border-radius: 0;

  @media (max-width: 780px) {
    width: 100%;
    max-width: 600px;
  }
`

export const AutocompleteInput = styled(TextField)`
  .MuiOutlinedInput-root {
    padding: 0;
  }

  .MuiChip-root {
    border: 1px solid #b4acfb;
    border-radius: 8px;
    background: #7464cb;
    color: #f5f5f5;

    svg {
      fill: #ededed;
    }
  }
`

export const SearchResultButton = styled(Button)`
  max-height: 24px;
  background: #7464cb;
  border: 1px solid ${colors.infoDark};
  border-radius: 8px;
  padding: 4px 6px;
  display: flex;
  justify-content: space-between;

  .MuiTypography-root {
    text-transform: none;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #f5f5f5;
  }

  svg {
    margin-left: 8px;
  }
`

export const SearchIconButton = styled(IconButton)`
  padding: 10px;
  color: ${colors.white};
`

export const SearchButton = styled(Button)`
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  margin-left: 10px;
  min-width: 129px;
  height: 40px;
  color: ${colors.backgroundLight};
  background: ${colors.rawSienneCore};
  text-transform: capitalize;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;

  &:hover {
    color: ${colors.white};
  }
`

export const PaginationContainer = styled(Box)`
  height: 36px;
  background-color: #d3ceef;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;

  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
`

export const PaginationWrapper = styled(Box)`
  height: 36px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const Controls = styled(Box)`
  display: flex;
  align-items: center;
  margin-left: 8px;
`

export const Info = styled(Box)`
  display: flex;
  align-items: center;
  margin-right: 70px;
`

export const PaginationText = styled(Typography)`
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  color: #140c40;
`

export const PaginationSelect = styled(Select)`
  :before,
  :after {
    border: none !important;
  }
`

export const paginationMenuStyles = {
  '.MuiPaper-root': {
    background: 'white !important'
  }
}

export const jiraTaskTicketSx = {
  color: '#429DDF !important',
  fontWeight: '700 !important',
  fontSize: '16px',
  lineHeight: '24px',
  cursor: 'pointer'
}

export const autocompleteSx = {
  width: 500,
  height: 'auto',

  '.MuiFormControl-root': {
    height: 'auto',

    '.MuiOutlinedInput-root': {
      height: 'auto',
      color: '#D3CEEF',
      fontWeight: 500,
      fontSize: '18px',
      lineHeight: '32px',

      input: {
        padding: 0
      },

      fieldset: {
        border: 'none !important',
        outline: 'none !important'
      },

      '.MuiAutocomplete-endAdornment': {
        display: 'none'
      }
    }
  }
}

export const checkboxSx = { marginRight: '8px', color: '#5E4FB0', svg: { fill: '#5E4FB0' } }

export const AutocompletePaper = styled(Paper)`
  width: 600px;
  background: #f5f5f5;
  transform: translateX(1px);
  border-radius: 0;

  ul {
    padding-top: 0;
  }

  li {
    color: #5e4fb0;
    text-transform: capitalize;
  }
`

export const PopperWrapper = styled(Box)`
  margin-left: 1px;
  margin-right: -1px;
  margin-top: 9px;
  background: #f5f5f5;
  padding: 16px 16px 0;
`

export const PopperText = styled(Typography)`
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  display: flex;
  align-items: center;
  color: #5e4fb0;
`

export const InputAdornment = styled(MuiInputAdornment)`
  height: auto;
`
