import React from 'react'

const ZeroState = () => (
  <svg width="128" height="129" viewBox="0 0 128 129" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="2" y="2.5" width="124" height="124" rx="62" stroke="#4D444B" strokeWidth="4" strokeDasharray="8 8" />
    <path
      d="M68 44.5H52C49.8 44.5 48 46.3 48 48.5V80.5C48 82.7 49.78 84.5 51.98 84.5H76C78.2 84.5 80 82.7 80 80.5V56.5L68 44.5ZM76 80.5H52V48.5H66V58.5H76V80.5Z"
      fill="#D9BFD4"
    />
    <path
      d="M58.06 62.56C55.32 65.3 55.32 69.72 58.06 72.46C60.3 74.7 63.66 75.08 66.32 73.64L70.08 77.4L72.9 74.58L69.14 70.82C70.56 68.16 70.2 64.8 67.96 62.56C65.22 59.82 60.78 59.82 58.06 62.56ZM65.12 69.62C63.94 70.8 62.04 70.8 60.88 69.62C59.7 68.44 59.7 66.54 60.88 65.38C62.06 64.2 63.96 64.2 65.12 65.38C66.3 66.56 66.3 68.44 65.12 69.62Z"
      fill="#D9BFD4"
    />
  </svg>
)

export default ZeroState
