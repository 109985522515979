import React, { FC, SVGProps } from 'react'

type Props = SVGProps<SVGSVGElement>

const CloseModalIcon: FC<Props> = ({ ...props }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36" fill="none" {...props}>
    <path
      d="M28.5 9.615L26.385 7.5L18 15.885L9.615 7.5L7.5 9.615L15.885 18L7.5 26.385L9.615 28.5L18 20.115L26.385 28.5L28.5 26.385L20.115 18L28.5 9.615Z"
      fill="white"
    />
  </svg>
)

export default CloseModalIcon
