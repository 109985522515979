import { Box, Accordion as MuiAccordion } from '@mui/material'
import styled from '@emotion/styled/macro'

export const Accordion = styled(MuiAccordion)`
  border: 1px solid #4d444b;
  border-radius: 8px;
  background: #372a36;
  width: 100%;

  .MuiAccordionSummary-root {
    border-radius: 8px;
    background: #372a36;

    .summary-logo-image {
      min-width: 40px !important;
      min-height: 40px !important;
      height: 40px !important;
      width: 40px !important;
    }

    &.Mui-expanded {
      border-radius: 8px 8px 0 0;
    }

    .MuiAccordionSummary-content {
      display: flex;
      align-items: center;
      gap: 16px;
      margin-top: 16px;
      margin-bottom: 16px;

      .asset-name {
        color: #fff;
        font-family: Quicksand, sans-serif;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }

      .asset-summary-indicator {
        width: 8px !important;
        height: 8px !important;
        min-width: 8px !important;
        min-height: 8px !important;
        border-radius: 50%;
        background: #ffe082;
      }

      .missing-vendor-logo {
        display: flex;
        align-items: center;
        justify-content: center;
        background: #c3c3c3;
        border-radius: 50%;
        color: #000;
        font-family: Quicksand, sans-serif;
        font-size: 20px;
        font-weight: 500;
        min-width: 40px !important;
        min-height: 40px !important;
        height: 40px !important;
        width: 40px !important;
      }

      .asset-summary-space {
        flex-grow: 1;
      }

      .asset-value {
        color: #867083;
        font-family: Quicksand, sans-serif;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-right: 16px;
      }
    }
  }

  .MuiCollapse-wrapper {
    background: #372a36;
    border-radius: 8px;

    .MuiAccordionDetails-root {
      padding-top: 24px;

      .content-vendor-buttons {
        display: flex;
        align-items: center;
        flex-flow: row wrap;
        gap: 16px;

        .MuiButtonBase-root {
          height: 40px;
          color: #ffa9fc;
          text-align: center;
          font-family: Quicksand, sans-serif;
          font-size: 16px;
          font-style: normal;
          font-weight: 510;
          line-height: normal;
          padding: 8px 24px 8px 16px;
        }
      }

      .contract-details {
        margin: 24px 0 16px 0;
        gap: 8px;

        .contract-details-title {
          color: #fff;
          font-family: Quicksand, sans-serif;
          font-size: 20px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
        }

        .contract-details-text {
          color: #d9bfd4;
          font-family: Quicksand, sans-serif;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
      }

      .inputs-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-bottom: 24px;

        .dates-wrapper {
          display: flex;
          align-items: center;
          gap: 16px;
          margin-bottom: 16px;
          flex-basis: 100%;
          width: 100%;
        }

        .form-control {
          gap: 4px;
          width: 100%;

          .MuiOutlinedInput-root {
            height: 40px;
            overflow: hidden;
            color: #fff;
            text-overflow: ellipsis;
            font-family: Quicksand, sans-serif;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            border-radius: 4px;
            border: 1px solid #998d96;
            padding-left: 8px;

            &.Mui-focused > fieldset {
              border-color: #998d96;
              border-width: 1px;
            }

            &:hover {
              border-color: #998d96;
            }

            input {
              margin-left: -7px;
            }

            &.Mui-disabled {
              input {
                -webkit-text-fill-color: #fff;
              }
            }
          }

          .MuiTypography-root {
            overflow: hidden;
            color: #fff;
            text-overflow: ellipsis;
            font-family: Quicksand, sans-serif;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;

            &.Mui-focused {
              color: #fff !important;
            }
          }
        }
      }

      .expense-calculation {
        display: flex;
        flex-direction: column;
        gap: 8px;
        margin-bottom: 16px;

        .expense-calculation-title {
          color: #fff;
          font-family: Quicksand, sans-serif;
          font-size: 20px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
        }

        .expense-calculation-text {
          color: #fff;
          font-family: Quicksand, sans-serif;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }

        .expense-radio-form-control {
          .MuiRadio-root {
            color: #ffa9fc;
          }

          .MuiFormControlLabel-label {
            color: #fff;
            font-family: Quicksand, sans-serif;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          }
        }

        .edit-calculation-wrapper {
          display: flex;
          flex-direction: column;
          gap: 16px;
          margin-top: 8px;

          .edit-calculation-row {
            display: flex;
            gap: 16px;
            align-items: center;
            flex-basis: 100%;
            width: 100%;

            .edit-calculation-row-cell {
              flex-basis: 50%;
              display: flex;
              align-items: center;
              height: 40px;
              border-radius: 4px;
              background: #483646;
              padding: 8px;
              min-width: 278px;

              .MuiTypography-root {
                white-space: nowrap;
                overflow: hidden;
                color: #fff;
                text-overflow: ellipsis;
                font-family: Quicksand, sans-serif;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
              }

              .MuiTypography-root.this-cell {
                white-space: nowrap;
                overflow: hidden;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 1;
                color: #ffa9fc;
                text-overflow: ellipsis;
                font-family: Quicksand, sans-serif;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
              }
            }

            .edit-calculation-row-value {
              height: 40px;
              flex-basis: 50%;
              width: 100%;

              .MuiFormControl-root {
                width: 100%;
              }

              .MuiInputAdornment-root .MuiTypography-root {
                overflow: hidden;
                color: #fff;
                text-overflow: ellipsis;
                font-family: Quicksand, sans-serif;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
              }

              .MuiOutlinedInput-root {
                //width: 341px;
                width: 100%;
                height: 40px;
                overflow: hidden;
                color: #fff;
                text-overflow: ellipsis;
                font-family: Quicksand, sans-serif;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                border-radius: 4px;
                border: 1px solid #998d96;
                padding-left: 8px;

                &.Mui-focused > fieldset {
                  border-color: #998d96;
                  border-width: 1px;
                }

                &.Mui-disabled {
                  input {
                    -webkit-text-fill-color: #fff;
                  }
                }

                &:hover {
                  border-color: #998d96;
                }

                input {
                  margin-left: -7px;
                }
              }
            }
          }
        }
      }

      .sum-not-matched-wrapper {
        border-radius: 4px;
        background: #ffe082;
        width: 100%;
        display: flex;
        align-items: center;
        gap: 10px;
        padding: 8px 16px;
        min-height: 40px;
        margin-top: 24px;
        margin-bottom: 24px;

        .MuiTypography-root {
          color: #211920;
          font-family: Quicksand, sans-serif;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
      }

      .actions-wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-flow: row wrap;
        gap: 16px;
        padding-top: 16px;

        .main-action-bar {
          gap: 8px;
          display: flex;
          align-items: center;
        }
      }
    }
  }
`

export const Divider = styled(Box)`
  width: calc(100% - 8px);
  height: 1px;
  background: #4d444b;
  display: flex;
  justify-self: center;
  margin: 0 auto;
`
