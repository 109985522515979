import React from 'react'
import { Actions, Header, Wrapper } from './SSoVerificationFailed.styles'
import { Box, IconButton, Typography } from '@mui/material'
import { CloseChipIcon } from '../../../svg'
import { closeModal, modalPropsSelector, ModalType, openModal } from '../../../../../store/modals/modalSlice'
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks'
import VerificationFailedIcon from '../../../svg/VerificationFailedIcon'
import SuccessIcon from '../../../svg/SuccessIcon'
import { ThemeButton } from '../../../buttons'

const SSoVerificationFailed = () => {
  const dispatch = useAppDispatch()
  const modalProps = useAppSelector(modalPropsSelector)

  const handleClose = () => {
    dispatch(closeModal())

    const connection = modalProps?.props?.connection
    dispatch(
      openModal({
        type: ModalType.ssoSupport,
        props: { connection, roles: modalProps?.props?.roles, callback: modalProps?.props?.callback }
      })
    )
  }

  return (
    <Wrapper>
      <Header>
        <Box className="header-block">
          {modalProps?.props?.isVerified ? <SuccessIcon /> : <VerificationFailedIcon />}
          <Typography className="title">Verification {modalProps?.props?.isVerified ? 'Success' : 'Failed'}</Typography>
        </Box>

        <IconButton onClick={handleClose}>
          <CloseChipIcon />
        </IconButton>
      </Header>

      {!modalProps?.props?.isVerified ? (
        <Typography className="description">The information you provided was incorrect. Please try again.</Typography>
      ) : (
        ''
      )}

      <Actions>
        <ThemeButton themevariant="primary" type="button" onClick={handleClose}>
          {modalProps?.props?.isVerified ? 'Close' : 'Try Again'}
        </ThemeButton>
      </Actions>
    </Wrapper>
  )
}

export default SSoVerificationFailed
