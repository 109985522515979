import { Dispatch, FC, SetStateAction } from 'react'

/* Utils */
import { CPILibraryItem, IntegrationInstance, IntegrationStatusMessage } from '../../../../../models'

/* Components */
import { InstanceCardContainer } from './integrationCard.styles'
import InstanceCardContent from './InstanceCardContent'

interface Props {
  instance: IntegrationInstance
  handleOpenDetails: () => void
  detailsOpen: boolean
  selectedInstance: IntegrationInstance | null
  setSelectedInstance: Dispatch<SetStateAction<IntegrationInstance | null>>
  affectedCpis: CPILibraryItem[][]
  index: number
  loading: boolean
}

const InstanceCard: FC<Props> = ({
  instance,
  handleOpenDetails,
  detailsOpen,
  selectedInstance,
  setSelectedInstance,
  affectedCpis,
  index,
  loading
}) => {
  const handleClick = () => {
    if (selectedInstance && selectedInstance.id === instance.id) {
      if (detailsOpen) {
        setSelectedInstance(null)
      }
    } else {
      setSelectedInstance(instance)
      if (detailsOpen) return
    }
    handleOpenDetails()
  }

  const hasFailed = instance.syncStatus && instance.syncStatus.status === IntegrationStatusMessage.failed

  return (
    <InstanceCardContainer
      isSelected={selectedInstance && selectedInstance.id === instance.id ? 'true' : 'false'}
      onClick={handleClick}
      hasFailed={hasFailed}
    >
      <InstanceCardContent
        instance={instance}
        handleOpenDetails={handleOpenDetails}
        detailsOpen={detailsOpen}
        index={index}
        affectedCpis={affectedCpis}
        loading={loading}
        hasFailed={hasFailed}
      />
    </InstanceCardContainer>
  )
}

export default InstanceCard
