import { baseColors } from './colors'

export const colorTokens = {
  surface: {
    pageBackground: baseColors.surface[10],
    containerBackground: baseColors.surface[20],
    itemBackgroundLow: baseColors.surface[30],
    itemBackground: baseColors.surface[40],
    itemBackgroundHigh: baseColors.surface[50],
    itemBackgroundHighest: baseColors.surface[60]
  },
  controls: {
    controlPrimary: baseColors.primary[80],
    controlTextPrimary: baseColors.primary[20],
    controlPrimaryHover: baseColors.primary[70],
    controlPrimaryDown: baseColors.primary[80],
    controlDown: baseColors.primary[20],
    controlHover: baseColors.secondary[30],
    controlBorder: baseColors.greyscale[60],
    controlBorderFocus: baseColors.greyscale[100],
    controlDisabled: baseColors.surface[50],
    controlTextDisabled: baseColors.greyscale[50]
  },
  text: {
    textDefault: baseColors.greyscale[100],
    textInvert: baseColors.greyscale[10],
    textPrimary: baseColors.primary[80],
    textPrimaryLow: baseColors.primary[90],
    textSecondary: baseColors.secondary[80],
    textSecondaryLow: baseColors.secondary[50]
  },
  border: {
    borderLow: baseColors.greyscale[30],
    BorderHigh: baseColors.greyscale[60],
    borderPrimary: baseColors.primary[80]
  },
  info: {
    info: baseColors.info[10],
    success: baseColors.info[20],
    warningLow: baseColors.info[30],
    warningHigh: baseColors.info[40],
    error: baseColors.info[50]
  }
}
