import { FC } from 'react'
import { Box, Typography } from '@mui/material'
import styled from '@emotion/styled/macro'

const Container = styled(Box)<{ containerType: 'info' | 'error' }>`
  border-radius: 8px;
  border: 1px solid #573f56;
  background: rgba(87, 63, 86, 0.7);
  box-shadow: 0 2px 6px 2px rgba(0, 0, 0, 0.15), 0 1px 2px 0 rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
  padding: 16px;
  height: 56px;
  width: 255px;
  min-width: 255px;

  img {
    min-width: 24px;
    min-height: 24px;
  }

  ${({ containerType }) =>
    containerType === 'error' &&
    `
      border: 1px solid #573F56;
      background: rgba(255, 138, 128, 0.20);
    `};

  .total-info-text {
    color: #e9e0e4;
    font-family: Quicksand, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    white-space: nowrap;
  }
`

interface Props {
  type: 'info' | 'error'
}

const TotalMustEqual: FC<Props> = ({ type = 'info' }) => (
  <Container className="total-info-wrapper" containerType={type}>
    <img
      src={type === 'info' ? '/info-icon.svg' : '/error-icon.svg'}
      alt={type === 'info' ? 'info' : 'error'}
      width="24px"
      height="24px"
    />
    <Typography className="total-info-text">Total must equal 100%.</Typography>
  </Container>
)

export default TotalMustEqual
