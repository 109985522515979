import { FC, SetStateAction, Dispatch } from 'react'
import { MenuItem, FormControl, ListItemText, Select, SelectChangeEvent, Checkbox, OutlinedInput } from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import {
  selectSx,
  MenuProps,
  menuItemSx,
  formControlSx,
  selectInputSx,
  selectMenuItemSx,
  selectCheckboxSx,
  selectListItemTextSx
} from './selectProject.styles'
import './select.module.css'

/* Utils */
import { SelectOption } from './JiraIntegration'

/* Components */
import { CheckedIcon, UncheckedIcon } from '../../../svg'

interface Props {
  options: SelectOption[]
  selected: string[]
  setSelected: Dispatch<SetStateAction<string[]>>
  allProjectsSelected: boolean
}

const SelectProject: FC<Props> = ({ options, selected, setSelected, allProjectsSelected }) => {
  const handleChange = (event: SelectChangeEvent<typeof selected>) => {
    const {
      target: { value }
    } = event
    if (value[value.length - 1] === 'Select all projects') {
      setSelected(selected.length === options.length ? [] : options.map((option) => option.label))

      return
    }

    setSelected(typeof value === 'string' ? value.split(',') : value)
  }

  return (
    <div>
      <FormControl sx={formControlSx}>
        <Select
          labelId="project-multiple-checkbox-label"
          id="project-multiple-checkbox"
          multiple
          displayEmpty
          value={selected}
          onChange={handleChange}
          input={<OutlinedInput sx={selectInputSx} />}
          MenuProps={MenuProps}
          IconComponent={(props) => <ExpandMoreIcon {...props} />}
          renderValue={(selected) => {
            if (selected.length === 0) {
              return 'Select'
            }

            return `${selected.length > 1 ? selected.length + ' Projects' : selected.length + ' Project'}`
          }}
          sx={selectSx}
        >
          <MenuItem sx={menuItemSx} value="Select all projects">
            <Checkbox
              checked={allProjectsSelected}
              icon={<UncheckedIcon />}
              checkedIcon={<CheckedIcon />}
              indeterminate={selected.length > 0 && selected.length < options.length}
              sx={selectCheckboxSx}
            />
            <ListItemText primary="Select all projects" sx={selectListItemTextSx} />
          </MenuItem>
          {options.map(({ label, value }) => (
            <MenuItem key={value} value={label} sx={selectMenuItemSx}>
              <Checkbox checked={selected.indexOf(label) > -1} icon={<UncheckedIcon />} checkedIcon={<CheckedIcon />} />
              <ListItemText primary={label} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  )
}

export default SelectProject
