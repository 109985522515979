import { Dispatch, FC, MouseEvent, SetStateAction, ChangeEvent } from 'react'
import { FormikErrors } from 'formik'
import { Grid, Box, Typography, InputLabel, FormControl, InputAdornment, IconButton } from '@mui/material'
import { Visibility, VisibilityOff } from '@mui/icons-material'

/* Components */
import UncontrolledOutlinedInput from '../../../form-components/UncontrolledOutlinedInput'
import Instructions from './Instructions'
import Notice from './Notice'
import SchemaMappingInstructions from './SchemaMappingInstructions'
import SchemaMapping from './SchemaMapping'
import SelectProject from './SelectProject'
import Toast from '../../../toast/Toast'

/* Utils */
import { ModalVariant } from '../../../../../store/modals/modalSlice'
import { SelectOption } from './JiraIntegration'
import { ProjectMeta } from '../../../../../models'
import { FormValues as IntegrationFormValues } from './jiraIntegration.constants'
import { FormValues as MappingFormValues } from './schemaMapping.constants'

interface Props {
  handleClickShowPassword: () => void
  handleMouseDownPassword: (event: MouseEvent<HTMLButtonElement>) => void
  showPassword: boolean
  activeStep: number
  success: boolean | null
  errorMessage: string
  variant: ModalVariant
  options: SelectOption[]
  selected: string[]
  setSelected: Dispatch<SetStateAction<string[]>>
  allProjectsSelected: boolean
  projectsMetadata: ProjectMeta[]
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => Promise<FormikErrors<any>> | Promise<void>
  values: IntegrationFormValues | MappingFormValues
  onChange: {
    (e: ChangeEvent<any>): void
    <T_1 = string | ChangeEvent<any>>(field: T_1): T_1 extends ChangeEvent<any>
      ? void
      : (e: string | ChangeEvent<any>) => void
  }
}

const JiraIntegrationContent: FC<Props> = ({
  handleClickShowPassword,
  handleMouseDownPassword,
  showPassword,
  activeStep,
  success,
  errorMessage,
  variant,
  options,
  selected,
  setSelected,
  allProjectsSelected,
  projectsMetadata,
  setFieldValue,
  values,
  onChange
}) => (
  <Box sx={{ minHeight: activeStep === 0 ? '706px' : '364px' }}>
    <Typography variant="h1" fontSize="36px" lineHeight="44px" fontWeight={800} mb="8px">
      {variant === 'add' ? 'Configure' : 'Modify'} Your Jira Integration
    </Typography>
    <img src="/jira-white.svg" alt="Jira logo" />
    {success === false && activeStep === 0 && (
      <Toast
        variant="error"
        message={
          errorMessage ||
          "These credentials weren't recognized, or you don’t have permission to configure this integration. Please contact your System administrator."
        }
        sx={{ mt: '40px' }}
      />
    )}
    {success && activeStep === 0 && <Toast variant="success" sx={{ mt: '40px' }} />}
    {activeStep === 0 && (
      <>
        <Grid
          container
          spacing={3}
          alignItems="center"
          justifyContent="center"
          mb="30px"
          sx={{ minHeight: '364px', height: '100%' }}
        >
          <Grid item xs={7} sx={{ marginTop: '40px !important' }}>
            <Instructions />
          </Grid>
          <Grid item xs={5}>
            <Grid container spacing={3} mt="20px" alignItems="center" justifyContent="center">
              <Grid item xs={12} sx={{ margin: '0 auto', display: 'flex', justifyContent: 'center' }}>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    height: '73px'
                  }}
                >
                  <InputLabel sx={{ color: '#DBDBDB', fontSize: '12px', lineHeight: '16px', mb: '2px' }}>
                    Jira URL *
                  </InputLabel>
                  <FormControl sx={{ width: '25ch' }}>
                    <UncontrolledOutlinedInput
                      name="jiraUrl"
                      sx={{
                        width: '280px',
                        height: '32px',
                        backgroundColor: '#fff'
                      }}
                      value={(values as IntegrationFormValues).jiraUrl}
                      onChange={onChange}
                      autoComplete="off"
                      placeholder="jira.atlassian.com/example"
                    />
                  </FormControl>
                </Box>
              </Grid>
              <Grid item xs={12} sx={{ margin: '0 auto', display: 'flex', justifyContent: 'center' }}>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    height: '73px'
                  }}
                >
                  <InputLabel sx={{ color: '#DBDBDB', fontSize: '12px', lineHeight: '16px', mb: '2px' }}>
                    Jira Email *
                  </InputLabel>
                  <FormControl sx={{ width: '25ch' }}>
                    <UncontrolledOutlinedInput
                      name="jiraEmail"
                      sx={{
                        width: '280px',
                        height: '32px',
                        backgroundColor: '#fff'
                      }}
                      autoComplete="off"
                      value={(values as IntegrationFormValues).jiraEmail}
                      onChange={onChange}
                      placeholder="example@onyxia.io"
                    />
                  </FormControl>
                </Box>
              </Grid>
              <Grid item xs={12} sx={{ margin: '0 auto', display: 'flex', justifyContent: 'center' }}>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    height: '73px'
                  }}
                >
                  <InputLabel sx={{ color: '#DBDBDB', fontSize: '12px', lineHeight: '16px', mb: '2px' }}>
                    API Key *
                  </InputLabel>
                  <FormControl sx={{ width: '25ch' }}>
                    <UncontrolledOutlinedInput
                      name="apiKey"
                      type={showPassword ? 'text' : 'password'}
                      sx={{
                        width: '280px',
                        height: '32px',
                        backgroundColor: '#fff'
                      }}
                      value={(values as IntegrationFormValues).apiKey}
                      onChange={onChange}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle API Key visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      }
                      autoComplete="off"
                      placeholder="API Key"
                    />
                  </FormControl>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </>
    )}
    {activeStep === 1 && (
      <Grid container spacing={3} alignItems="flex-start" justifyContent="center" mb="30px" sx={{ height: '100%' }}>
        <Grid item xs={7} sx={{ marginTop: '40px !important' }}>
          <Notice />
        </Grid>
        <Grid item xs={5} sx={{ marginTop: '40px !important' }}>
          <Grid container spacing={0} alignItems="center" justifyContent="center">
            <Grid item xs={12} sx={{ margin: '0 auto', display: 'flex', justifyContent: 'center' }}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column'
                }}
              >
                <InputLabel sx={{ fontSize: '12px', lineHeight: '16px', fontWeight: 400, color: '#DBDBDB' }}>
                  Select the project(s) you'd like to integrate
                </InputLabel>
                <SelectProject
                  options={options}
                  selected={selected}
                  setSelected={setSelected}
                  allProjectsSelected={allProjectsSelected}
                />
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    )}
    {activeStep === 2 && projectsMetadata && values && (
      <Grid container spacing={3} alignItems="flex-start" justifyContent="center" mb="30px" sx={{ height: '100%' }}>
        <Grid item xs={12} sx={{ marginTop: '40px !important' }}>
          <SchemaMappingInstructions />
        </Grid>
        <Grid item xs={12}>
          <SchemaMapping
            metadata={projectsMetadata}
            setFieldValue={setFieldValue}
            values={values as MappingFormValues}
          />
        </Grid>
      </Grid>
    )}
  </Box>
)

export default JiraIntegrationContent
