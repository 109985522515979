import { Dispatch, FC, SetStateAction } from 'react'
import { Box, SelectChangeEvent, Typography } from '@mui/material'

/* Utils */
import { CPITimePeriod, LastSeenWithin, OperatingSystem } from '../../../../../../models'

/* Components */
import { MiscControlWrapper } from './analysisChartDescription.styles'
import MiscControlSelectField from './controls-components/MiscControlSelectField'

interface Props {
  firstSource: string
  secondSource: string
  selectedOperatingSystem: OperatingSystem
  setSelectedOperatingSystem: Dispatch<SetStateAction<OperatingSystem>>
  setIsFilterActive: Dispatch<SetStateAction<boolean>>
  selectedTimePeriod: CPITimePeriod
  setSelectedTimePeriod: Dispatch<SetStateAction<CPITimePeriod>>
  fetchData: ({
    timePeriod,
    seenWithin,
    operatingSystem
  }: {
    timePeriod?: CPITimePeriod
    seenWithin?: LastSeenWithin
    operatingSystem?: OperatingSystem
  }) => void
}

const AnalysisChartDescription: FC<Props> = ({
  firstSource,
  secondSource,
  selectedOperatingSystem,
  setSelectedOperatingSystem,
  selectedTimePeriod,
  setSelectedTimePeriod,
  fetchData,
  setIsFilterActive
}) => {
  const handleChangeTimePeriod = async (event: SelectChangeEvent) => {
    const period = event.target.value as CPITimePeriod
    setSelectedTimePeriod(period)
    setIsFilterActive(true)
    fetchData({
      timePeriod: period
    })
  }

  const handleChangeOperatingSystem = async (event: SelectChangeEvent) => {
    const operatingSystem = event.target.value as OperatingSystem
    setSelectedOperatingSystem(operatingSystem)
    setIsFilterActive(true)
    fetchData({ operatingSystem })
  }

  return (
    <Box className="controls-section misc-controls">
      <MiscControlWrapper>
        <Typography className="misc-control-title">Time Period</Typography>
        <MiscControlSelectField
          options={[
            CPITimePeriod.Week,
            CPITimePeriod.Month,
            CPITimePeriod.ThreeMonths,
            CPITimePeriod.SixMonths,
            CPITimePeriod.PastYear
          ]}
          label=""
          placeholder=""
          noLabel={true}
          value={selectedTimePeriod}
          handleChange={handleChangeTimePeriod}
          disabled={!firstSource && !secondSource}
        />
      </MiscControlWrapper>

      <MiscControlWrapper>
        <Typography className="misc-control-title">Operating System</Typography>
        <MiscControlSelectField
          options={Object.values(OperatingSystem)}
          label=""
          placeholder=""
          noLabel={true}
          value={selectedOperatingSystem}
          handleChange={handleChangeOperatingSystem}
          disabled={!firstSource && !secondSource}
        />
      </MiscControlWrapper>
    </Box>
  )
}

export default AnalysisChartDescription
