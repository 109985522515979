import { FC, Dispatch, SetStateAction } from 'react'
import { Box, CircularProgress } from '@mui/material'
import { IntegrationsListWrapper } from '../integrations.styles'
import InstanceCard from './InstanceCard'
import { CPILibraryItem, IntegrationInstance } from '../../../../../models'

interface Props {
  instances: IntegrationInstance[]
  handleOpenDetails: () => void
  detailsOpen: boolean
  selectedInstance: IntegrationInstance | null
  setSelectedInstance: Dispatch<SetStateAction<IntegrationInstance | null>>
  affectedCpis: CPILibraryItem[][]
  loading: boolean
  resultsLoading: boolean
}

const InstancesList: FC<Props> = ({
  instances,
  handleOpenDetails,
  detailsOpen,
  selectedInstance,
  setSelectedInstance,
  affectedCpis,
  loading,
  resultsLoading
}) => (
  <IntegrationsListWrapper>
    {resultsLoading ? (
      <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <CircularProgress size="40px" color="primary" />
      </Box>
    ) : (
      !!instances &&
      instances.map((n, index) => (
        <InstanceCard
          key={index}
          index={index}
          instance={n}
          affectedCpis={affectedCpis}
          detailsOpen={detailsOpen}
          handleOpenDetails={handleOpenDetails}
          selectedInstance={selectedInstance}
          loading={loading}
          setSelectedInstance={setSelectedInstance}
        />
      ))
    )}
  </IntegrationsListWrapper>
)

export default InstancesList
