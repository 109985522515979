import { FC } from 'react'
import { Typography } from '@mui/material'

/* Utils */
import { CPISeverity, InsightDataInstance } from '../../../../../../models'

/* Components */
import { CpiDetailsWrapper, InstanceLogo, CpiChip, SeverityChip } from './cpiDetails.styles'

const getIntegrationLogo = (name: string) => {
  if (name) {
    return `/icons/circle/${name}`
  }

  return '/icons/circle/crowdstrike_falcon_icon.svg'
}

interface Props {
  displayName: string
  severity: CPISeverity
  instances: InsightDataInstance[]
  title?: string
  isMainSection?: boolean
}

const CpiDetails: FC<Props> = ({ displayName, isMainSection, title, severity, instances }) => {
  return (
    <CpiDetailsWrapper>
      <CpiChip>{displayName}</CpiChip>
      {isMainSection && title && (
        <Typography className="performance-text" sx={isMainSection ? { marginBottom: '0 !important' } : null}>
          {title}
        </Typography>
      )}
      <SeverityChip>{severity} Severity</SeverityChip>
      {instances.map((instance) => (
        <InstanceLogo key={instance.id} bg={getIntegrationLogo(instance.logo)} />
      ))}
    </CpiDetailsWrapper>
  )
}

export default CpiDetails
