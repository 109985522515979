import { Box } from '@mui/material'
import { styled as muiStyled } from '@mui/system'
import styled from '@emotion/styled/macro'

export const SearchVendorsContainer = muiStyled('div')`
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-end;
  gap: 16px;
  width: 100%;
  margin-top: 18px;
`

export const AutocompleteContainer = muiStyled('div')`
  position: relative;
  width: 100%;
`

export const SearchVendorsInputWrapper = styled(Box)`
  width: 100%;
  gap: 2px;
  display: flex;
  flex-flow: column wrap;

  .form-label-wrapper {
    .form-label-text {
      overflow: hidden;
      color: #fff;
      text-overflow: ellipsis;
      font-family: Quicksand, sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }

  & .MuiFormControl-root {
    width: 100%;
    border-radius: 4px;
    border: none;
    outline: 0;
    height: 40px;

    & .MuiOutlinedInput-root {
      color: #fff;
      font-family: Quicksand, sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0.5px;
      height: 40px;
      padding-right: 0;

      & .MuiOutlinedInput-input {
        padding: 0 8px;
        overflow: hidden;
        color: #fff;
        text-overflow: ellipsis;
        font-family: Quicksand, sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;

        &::placeholder {
          overflow: hidden;
          color: #d9bfd4;
          opacity: 1;
          text-overflow: ellipsis;
          font-family: Quicksand, sans-serif;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
      }

      & .end-adornment-close-icon {
        color: #fff;
        padding: 4px;
        margin-right: 4px;

        &:hover {
          background: rgba(255, 255, 255, 0.05);
        }
      }

      fieldset {
        border: 1px solid #998d96;

        span {
          font-size: 8.5px;
        }
      }

      &:hover fieldset {
        border-color: #998d96;
      }

      &.Mui-focused {
        border: none;

        & fieldset {
          border: 2px solid #ffa9fc;
        }
      }
    }

    & .search-vendors-loading-indicator {
      color: #ffa9fc;
    }
  }
`

export const ResultListItem = styled('li')<{ iscustomvendor?: string }>`
  display: flex;
  align-items: center;
  padding: 12px;
  cursor: default;
  list-style: none;

  .option-text {
    color: #fff;
    font-family: Quicksand, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.5px;
    flex-grow: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 300px;
  }

  &:hover {
    cursor: pointer;
    background: rgba(208, 188, 255, 0.08);

    //&:last-of-type {
    //  box-shadow: none;
    //  background: #4d444b;
    //}
  }

  &[aria-selected='true'] {
    background: rgba(208, 188, 255, 0.08);
  }

  &.Mui-focused,
  &.Mui-focusVisible {
    background: rgba(208, 188, 255, 0.08);
  }

  &[aria-selected='true'].Mui-focused,
  &[aria-selected='true'].Mui-focusVisible {
    background: rgba(208, 188, 255, 0.08);
  }

  ${({ iscustomvendor }) =>
    iscustomvendor &&
    iscustomvendor === 'true' &&
    `    
      &:hover {
        cursor: pointer;
        background: rgba(208, 188, 255, 0.08) !important;  
  
        &:last-of-type {
          box-shadow: none;
          background: transparent;
        }
      }
    
      &:last-of-type {
        background: transparent;
        
        &:hover {
          background: rgba(208, 188, 255, 0.08) !important;
        }
      }
    `}
  .vendor-logo-icon {
    margin-right: 16px;
    width: 24px;
    height: 24px;
    object-fit: cover;
    object-position: center;
  }

  .add-custom-loading-wrapper {
    color: #ffa9fc;
  }
`

export const ResultsList = styled('ul')<{ hideborder?: string; length: number }>`
  font-family: Quicksand, sans-serif;
  font-size: 0.875rem;
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  outline: 0;
  background: #4d444b;
  border-radius: 8px;
  max-height: 250px;

  ${({ hideborder }) =>
    hideborder &&
    hideborder === 'true' &&
    `
      border-radius: 0 0 8px 8px;
  `};

  ${({ length }) =>
    length > 5 &&
    `
    overflow-y: scroll;
  `};

  ::-webkit-scrollbar {
    width: 4px;
    background: #998d96;
    border-radius: 8px;
  }

  ::-webkit-scrollbar-track {
    box-shadow: none;
    background: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #ffa9fc;
    border-radius: 8px;
  }
`

export const SearchVendorsResultsContainer = styled('div')`
  border-radius: 8px;
  background: #4d444b;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.3), 0 8px 12px 6px rgba(0, 0, 0, 0.15);
  z-index: 1;
  position: absolute;
  left: 0;
  right: 0;
  margin-top: 8px;

  .search-vendors-results-text {
    color: #d0c3cc;
    font-family: Quicksand, sans-serif;
    font-size: 11px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0.5px;
    margin: 8px 0 0 12px;
  }

  .search-vendors-loading-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    min-height: 260px;

    .search-vendors-loading-progress {
      color: #ffa9fc;
    }
  }

  .search-vendors-empty-wrapper {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    gap: 4px;
    padding: 39px 16px 16px 16px;

    .search-vendors-empty-title {
      color: #fff;
      font-family: Quicksand, sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: 0.1px;
    }

    .search-vendors-empty-text {
      color: #fff;
      font-family: Quicksand, sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0.25px;
    }
  }
`
