import React, { FC } from 'react'
import { Box, IconButton, Typography } from '@mui/material'
import { Header, Wrapper } from './zoomIn.styles'

/* Utils */
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks'
import { closeModal, modalPropsSelector } from '../../../../../store/modals/modalSlice'
import { CloseChipIcon } from '../../../svg'

const ZoomIn: FC = () => {
  const dispatch = useAppDispatch()
  const modalProps = useAppSelector(modalPropsSelector)
  const zoomIn = modalProps?.props?.zoomIn

  const handleClose = () => {
    dispatch(closeModal())
  }

  return (
    <Wrapper>
      <Header>
        <Typography className="title">Formula</Typography>
        <IconButton onClick={handleClose}>
          <CloseChipIcon />
        </IconButton>
      </Header>

      <Box className="image-container">
        <img src={`/${zoomIn?.url}`} alt={`${zoomIn?.name} formula`} />
      </Box>
    </Wrapper>
  )
}

export default ZoomIn
