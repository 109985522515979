import { FC } from 'react'
import { Box, Typography } from '@mui/material'

/* Utils */
import { CompetitorAnalysisPerformanceMetric } from '../../../../../../../../models'

/* Components */
import { Mark as IMark } from './CompetitorAnalysisItem'
import {
  HighRange,
  IndustryAverageMarker,
  LowRange,
  Mark,
  MarkContainer,
  SLAMarker,
  Wrapper
} from './competitorAnalysisSlider.styles'
import BenchmarkRangeTooltip from './BenchmarkRangeTooltip'

interface Props {
  item: CompetitorAnalysisPerformanceMetric
  measurementUnit: string
  unit: string
  marks: IMark[]
  slaPosition: number
  avgIndustryPosition: number
  lowRangeLeft: number
  lowRangeWidth: number
  highRangeLeft: number
  highRangeWidth: number
  healthDirection: string
  lowRangeRight: number
  highRangeRight: number
  sla?: number
  max: number
  barWidth: number
  barLeft: number
  isReverse: boolean
  active: boolean
}

const CompetitorAnalysisSlider: FC<Props> = ({
  slaPosition,
  avgIndustryPosition,
  item,
  measurementUnit,
  unit,
  marks,
  lowRangeLeft,
  lowRangeWidth,
  highRangeLeft,
  highRangeWidth,
  healthDirection,
  lowRangeRight,
  highRangeRight,
  sla,
  max,
  barLeft,
  isReverse,
  barWidth,
  active
}) => {
  const getTooltipValue = (value: number | string): string => {
    if (unit === 'hours' || unit === 'days') {
      return `${value} ${unit}`
    }
    return `${value}${measurementUnit}`
  }

  return (
    <Wrapper isReverse={isReverse}>
      {/* Industry Average */}
      <BenchmarkRangeTooltip
        title={
          <Box className="tooltip-value">
            <Typography className="tooltip-title">{getTooltipValue(item.summary.industry_performance_avg)}</Typography>
            <Typography className="tooltip-text">
              This is the average SLA used{' '}
              {item.industry_name === 'All Industries'
                ? 'across all industries.'
                : `in the ${item.industry_name} industry`}
            </Typography>
          </Box>
        }
        placement="bottom"
      >
        <IndustryAverageMarker left={avgIndustryPosition} />
      </BenchmarkRangeTooltip>
      {/* SLA */}
      {typeof sla !== 'undefined' && sla <= max && active && (
        <BenchmarkRangeTooltip
          title={
            <Box className="tooltip-value">
              <Typography className="tooltip-title">{getTooltipValue(sla)}</Typography>
              <Typography className="tooltip-text">This is what your SLA is currently set to</Typography>
            </Box>
          }
          placement="bottom"
        >
          <SLAMarker left={slaPosition} />
        </BenchmarkRangeTooltip>
      )}
      <Box className="bar-block" sx={{ width: `${barWidth}%`, marginLeft: `${barLeft}%` }}>
        {healthDirection === 'desc' || !healthDirection ? (
          <>
            {/* High Range */}
            <BenchmarkRangeTooltip
              title={
                <Box className="tooltip-value">
                  <Typography className="tooltip-title">
                    {getTooltipValue(
                      `${item.summary.bottom_performance_range.low} - ${item.summary.bottom_performance_range.high}`
                    )}
                  </Typography>
                  <Typography className="tooltip-text">Low performers set their SLA within this range.</Typography>
                </Box>
              }
              placement="bottom"
            >
              <LowRange left={lowRangeLeft} sx={{ width: `${lowRangeWidth}%` }} />
            </BenchmarkRangeTooltip>
            {/* Low Range */}
            <BenchmarkRangeTooltip
              title={
                <Box className="tooltip-value">
                  <Typography className="tooltip-title">
                    {getTooltipValue(
                      `${item.summary.top_performance_range.low} - ${item.summary.top_performance_range.high}`
                    )}
                  </Typography>
                  <Typography className="tooltip-text">Top performers set their SLA within this range.</Typography>
                </Box>
              }
              placement="bottom"
            >
              <HighRange left={highRangeLeft} sx={{ width: `${highRangeWidth}%` }} />
            </BenchmarkRangeTooltip>
          </>
        ) : (
          <>
            {/* Low Range */}
            <BenchmarkRangeTooltip
              title={
                <Box className="tooltip-value">
                  <Typography className="tooltip-title">
                    {getTooltipValue(
                      `${item.summary.bottom_performance_range.low} - ${item.summary.bottom_performance_range.high}`
                    )}
                  </Typography>
                  <Typography className="tooltip-text">Low performers set their SLA within this range.</Typography>
                </Box>
              }
              placement="bottom"
            >
              <HighRange right={lowRangeRight} sx={{ width: `${lowRangeWidth}%` }} />
            </BenchmarkRangeTooltip>
            {/* High Range */}
            <BenchmarkRangeTooltip
              title={
                <Box className="tooltip-value">
                  <Typography className="tooltip-title">
                    {getTooltipValue(
                      `${item.summary.top_performance_range.low} - ${item.summary.top_performance_range.high}`
                    )}
                  </Typography>
                  <Typography className="tooltip-text">Top performers set their SLA within this range.</Typography>
                </Box>
              }
              placement="bottom"
            >
              <LowRange right={highRangeRight} sx={{ width: `${highRangeWidth}%` }} />
            </BenchmarkRangeTooltip>
          </>
        )}
      </Box>

      <MarkContainer>
        {marks.map((mark) => (
          <Mark key={mark.value} left={(mark.value * 100) / max}>
            {mark.label}
          </Mark>
        ))}
      </MarkContainer>
    </Wrapper>
  )
}

export default CompetitorAnalysisSlider
