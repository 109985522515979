import styled from '@emotion/styled/macro'
import { Box } from '@mui/material'

export const Wrapper = styled(Box)`
  border-top: 1px solid rgba(222, 189, 217, 0.5);

  .MuiMultiInputDateRangeField-separator {
    display: none;
  }

  .MuiMultiInputDateRangeField-root {
    flex-direction: column;
    align-items: self-end;
  }

  .MuiFormControl-root {
    height: 56px;
    width: 100%;
    margin-bottom: 16px;

    .MuiInputLabel-root {
      text-align: center;
      font-family: Quicksand, sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: 0.1px;
      top: 2px;
      color: white;
      background: #141218;
    }

    .MuiOutlinedInput-root {
      height: 56px;
      border: 1px solid #998d96;
      border-radius: 12px;
      background: transparent;
      text-align: center;
      font-family: Quicksand, sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: 0.1px;
      color: #fff;

      &.Mui-focused {
        color: #998d96;
        border: 1px solid #998d96;
      }

      input {
        height: 56px;
      }

      svg {
        path {
          fill: #998d96;
        }
      }
    }
  }

  .content {
    padding-top: 16px;
    padding-left: 24px;
    padding-right: 24px;

    .reset-block {
      display: flex;
      justify-content: flex-end;
      margin-top: 10px;
    }

    .reset {
      color: #998d96;
      text-align: center;
      font-family: 'Quicksand', sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: 0.1px;
      cursor: pointer;
    }

    .label {
      font-family: 'Quicksand', sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0.5px;
      margin-bottom: 16px;
    }

    .dates {
      border-bottom: 1px solid rgba(222, 189, 217, 0.5);
      margin-bottom: 16px;
    }

    .checkbox-wrapper {
      display: flex;
      align-items: center;
      gap: 16px;

      label {
        font-family: 'Quicksand', sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: 0.1px;
        color: white;
        cursor: pointer;
      }
    }

    .filters-actions {
      justify-content: center;

      button {
        width: 131px !important;
        justify-content: center;
        padding-right: 19px !important;
      }

      .disabled-btn {
        background: rgba(227, 227, 227, 0.12) !important;
        color: #e9e0e4 !important;
      }

      span {
        color: #ffa9fc;
        font-family: 'Quicksand', sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0.1px;
        cursor: pointer;
      }
    }
  }
`
