/* eslint camelcase: 0 */
import { Box, FormControl as MuiFormControl, Select as MuiSelect } from '@mui/material'
import styled from '@emotion/styled/macro'

const ITEM_HEIGHT = 40
const ITEM_PADDING_TOP = 8

export const FormControl = styled(MuiFormControl)<{
  isselected?: string
  isopen: string
  is_disabled: string
  field_width?: string
}>`
  border: 1px solid #998d96;
  background: transparent;
  outline: none;
  width: ${({ field_width }) => (field_width && field_width.length > 0 ? `${field_width}px` : '300px')};
  height: 40px;
  border-radius: 4px;
  box-sizing: border-box;

  .MuiOutlinedInput-root {
    background: transparent;
    border: 0;
    color: #d9bfd4;
    text-align: left;
    font-family: Quicksand, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: capitalize;
    flex-direction: row;
    justify-content: center;
  }

  &:hover {
    background: rgba(234, 221, 255, 0.08);

    .MuiOutlinedInput-root {
      color: #ffa9fc;
    }
  }

  svg:first-of-type {
    top: calc(50% - 0.6em);
    right: 16px;

    path {
      fill: #fff;
    }
  }

  ${({ is_disabled }) =>
    is_disabled &&
    is_disabled === 'true' &&
    `
      opacity: 0.38;
      pointer-events: none;
      cursor: default;
      
      &:hover {
        background: transparent;
    
        .MuiOutlinedInput-root {
          color: #ffa9fc;
        }
      }
      
       .MuiOutlinedInput-root {
        color: #ffa9fc;
      }
  `};

  ${({ isopen }) =>
    isopen === 'true' &&
    `
      border: 1px solid #FFA9FC;
      background: rgba(208, 188, 255, 0.16);
      
      .MuiOutlinedInput-root {
        color: #ffa9fc;
      }
  `};
`

export const Result = styled(Box)`
  color: #867083;
  font-size: 10px;
  font-style: normal;
  font-weight: 590;
  line-height: normal;
  letter-spacing: 1px;
  text-transform: uppercase;
`

export const Select = styled(MuiSelect)<{ isselected?: string; noborder?: string }>`
  min-width: 200px;
  height: 40px;
  background: transparent;
  border: 1px solid #998d96;
  color: #fff;
  font-family: Quicksand, sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.1px;
  text-transform: capitalize;

  .input-value-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 8px;

    .input-value-integration {
      color: #e9e0e4;
      font-family: Quicksand, sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0.25px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      max-width: 250px;
    }

    .input-value-count {
      color: #867083;
      text-align: right;
      text-overflow: ellipsis;
      font-size: 16px;
      font-weight: 400;
      font-style: normal;
      line-height: normal;
      margin-right: 16px;
    }
  }

  ${({ isselected }) =>
    isselected === 'true' &&
    `
    background: #6C586A;
  `};

  > * {
    border: none !important;
    outline: none !important;
  }

  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border: none !important;
  }

  svg {
    color: #d0c3cc;
  }
`

export const OptionsWrapper = styled(Box)<{ length: number }>`
  height: ${({ length }) => (length > 5 ? '200px' : 'fit-content')};
  overflow-y: scroll;

  ::-webkit-scrollbar {
    width: 4px;
    background: transparent;
  }

  ::-webkit-scrollbar-track {
    box-shadow: none;
    background: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #ffa9fc;
    border-radius: 16px;
  }
`

export const MenuProps = {
  PaperProps: {
    sx: {
      border: '1px solid #4D444B',
      background: '#372A36',
      boxShadow: '0px 8px 12px 6px rgba(0, 0, 0, 0.15), 0px 4px 4px 0px rgba(0, 0, 0, 0.30)',
      borderRadius: '8px',
      minWidth: '350px',
      padding: '8px 16px',
      marginTop: '4px',

      '.MuiList-root': {
        minWidth: '316px'
      },

      '.MuiMenuItem-root': {
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        height: '40px',
        padding: '8px',
        background: 'transparent !important',
        borderRadius: '4px',

        img: {
          marginRight: '8px'
        },

        '&:hover': {
          background: '#573F56 !important'
        },

        '.MuiListItemText-root .MuiTypography-root': {
          fontFamily: '"Quicksand", sans-serif',
          fontWeight: 400,
          fontSize: '16px',
          lineHeight: '24px',
          letterSpacing: '0.5px',
          color: '#FFF',
          fontStyle: 'normal',
          textTransform: 'capitalize'
        }
      },

      '.disabled-item': {
        cursor: 'default',

        '&:hover': {
          background: 'transparent !important'
        }
      },

      '.disabled-item .MuiTypography-root': {
        color: '#7F747C !important'
      },

      '.button-wrapper': {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        paddingTop: '4px'
      },

      '.Mui-selected': {
        backgroundColor: 'rgba(208, 188, 255, 0.16) !important'
      },

      '&::-webkit-scrollbar': {
        width: '4px',
        background: 'transparent'
      },

      '&::-webkit-scrollbar-track': {
        boxShadow: 'none',
        background: 'transparent'
      },

      '&::-webkit-scrollbar-thumb': {
        backgroundColor: '#FBD8F5',
        borderRadius: '8px'
      }
    },
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP
    }
  },
  anchorOrigin: {
    vertical: 'bottom' as const,
    horizontal: 'left' as const
  },
  transformOrigin: {
    vertical: 'top' as const,
    horizontal: 'left' as const
  },
  variant: 'menu' as const
}

export const getMenuProps = ({ width }: { width?: string }) => ({
  PaperProps: {
    sx: {
      border: '1px solid #4D444B',
      background: '#372A36',
      boxShadow: '0px 8px 12px 6px rgba(0, 0, 0, 0.15), 0px 4px 4px 0px rgba(0, 0, 0, 0.30)',
      borderRadius: '8px',
      minWidth: width || '350px',
      width: width || 'unset',
      padding: '8px',
      marginTop: '4px',

      '.MuiList-root': {
        minWidth: width ? 'unset' : '316px',
        padding: 0,
        display: 'flex',
        gap: '9px',
        flexDirection: 'column',
        justifyContent: 'center'
      },

      '.MuiMenuItem-root': {
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        height: '40px',
        padding: '8px',
        background: 'transparent !important',
        borderRadius: '4px',

        img: {
          marginRight: '16px'
        },

        '&:hover': {
          background: '#573F56 !important'
        },

        '.MuiListItemText-root .MuiTypography-root': {
          fontFamily: '"Quicksand", sans-serif',
          fontWeight: 400,
          fontSize: '16px',
          color: '#FFF',
          lineHeight: 'normal',
          fontStyle: 'normal',
          textTransform: 'capitalize',
          textOverflow: 'ellipsis',
          overflow: 'hidden'
        }
      },

      '.disabled-item': {
        cursor: 'default',

        '&:hover': {
          background: 'transparent !important'
        }
      },

      '.disabled-item .MuiTypography-root': {
        color: '#7F747C !important'
      },

      '.button-wrapper': {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        paddingTop: '4px'
      },

      '.Mui-selected': {
        backgroundColor: 'rgba(208, 188, 255, 0.16) !important'
      },

      '&::-webkit-scrollbar': {
        width: '4px',
        background: 'transparent'
      },

      '&::-webkit-scrollbar-track': {
        boxShadow: 'none',
        background: 'transparent'
      },

      '&::-webkit-scrollbar-thumb': {
        backgroundColor: '#FBD8F5',
        borderRadius: '8px'
      }
    },
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP
    }
  },
  anchorOrigin: {
    vertical: 'bottom' as const,
    horizontal: 'left' as const
  },
  transformOrigin: {
    vertical: 'top' as const,
    horizontal: 'left' as const
  },
  variant: 'menu' as const
})
