import { Button as MuiButton } from '@mui/material'
import styled from '@emotion/styled/macro'
import { colors } from '../../../../theme/'

export const Button = styled(MuiButton)<{ buttontype?: string }>`
  background: #f4ba94;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: ${colors.purpleDark};
  text-transform: capitalize;
  padding: 6px 36px;
  margin: 0 auto;

  :hover {
    color: ${colors.white};

    svg {
      path {
        fill: ${colors.white};
      }
    }
  }

  ${({ buttontype }) =>
    buttontype &&
    buttontype === 'delete' &&
    `
     background: #E85541;
     color: #F5F5F5;
  `};

  ${({ buttontype }) =>
    buttontype &&
    buttontype === 'red-button' &&
    `
     border-radius: 4px;
     background: #FD7B69;
  `};
`
