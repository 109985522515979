import { useState, useEffect } from 'react'

export const useResize = (ref: any): number => {
  const [width, setWidth] = useState<number>(0)

  useEffect(() => {
    const handleResize = () => {
      setWidth(ref.current.offsetWidth - 36)
    }

    if (ref.current) {
      setWidth(ref.current.offsetWidth - 36)
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [ref])

  return width
}
