import styled from '@emotion/styled/macro'
import { Drawer as MuiDrawer } from '@mui/material'

export const Drawer = styled(MuiDrawer)`
  .loading-wrapper {
    position: absolute;
    height: 100vh;
    width: 100%;
    background: rgba(20, 18, 24, 0.65);
    z-index: 10;

    > div {
      margin-top: 15px;
      position: absolute;
      left: 50%;
      top: 50%;
    }
  }

  .MuiPaper-root {
    background: #141218;
    box-shadow: 0 8px 12px rgba(0, 0, 0, 0.15), 0 4px 4px rgba(0, 0, 0, 0.3);
    min-width: 320px;
    padding-top: 12px;
    padding-bottom: 12px;
    border-left: 1px solid #998d96;

    ::-webkit-scrollbar {
      display: none;
    }

    .wrapper-inner {
      width: 100%;
      //margin-bottom: 100px;

      .header-block {
        padding-left: 24px;
        padding-right: 24px;

        .header {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 100%;
          margin-bottom: 12px;

          .close-icon {
            width: 40px;
            height: 40px;
            margin-right: 8px;

            &:hover {
              background: rgba(202, 196, 208, 0.12);
            }

            svg {
              path {
                fill: #ffffff;
              }
            }
          }

          .title {
            font-family: 'Quicksand', sans-serif;
            font-weight: 400;
            font-size: 22px;
            line-height: 28px;
            color: #ffffff;
          }
        }

        .custom-header {
          justify-content: flex-start;
        }

        .dates {
          font-family: 'Quicksand', sans-serif;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 20px;
          letter-spacing: 0.1px;
          padding-bottom: 20px;
        }
      }

      .drawer-actions {
        background: #131013;
        border-top: 1px solid rgba(222, 189, 217, 0.5);
        padding: 16px 24px;
        display: flex;
        align-items: center;
        gap: 16px;
        position: fixed;
        bottom: 0;
        width: 320px;
        right: 0;

        .icon-block {
          border: 1px solid #998d96;
          border-radius: 100%;
          padding: 8px;
          width: 40px;
          height: 40px;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .MuiButton-root {
          border-radius: 44px;
          border: 1px solid #998d96;
          background: linear-gradient(0deg, rgba(255, 169, 252, 0.08) 0%, rgba(255, 169, 252, 0.08) 100%);
          box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.3), 0px 1px 3px 1px rgba(0, 0, 0, 0.15);
          color: white;
          width: 100%;
        }
      }

      .edit-actions {
        button {
          padding-right: 8px;
        }

        .save-btn {
          border: 1px solid rgba(227, 227, 227, 0.12) !important;
          background: #ffa9fc;
          color: #590060;

          &:hover {
            svg {
              path {
                fill: #590060;
              }
            }
          }
        }
      }

      .unpin-btn {
        border: 1px solid rgba(227, 227, 227, 0.12) !important;
        background: #ffa9fc !important;
        color: #590060 !important;
        font-weight: 500 !important;

        &:hover {
          svg {
            path {
              fill: #590060;
            }
          }
        }
      }

      .disabled-button {
        background: rgba(227, 227, 227, 0.12) !important;
        color: #e9e0e4 !important;

        svg {
          path {
            fill: #e9e0e4 !important;

            &:last-child {
              stroke: #e9e0e4 !important;
            }
          }
        }
      }
    }
  }
`
