import { LabelOption } from './components/AuditLogSelectLabelField'
import { AuditLogRecord } from '../../../../models'
import { dateFormat, removeDuplicates } from '../../../../lib/utils'
import { ActionOption } from './AuditLog'
import { DateRange } from '@mui/x-date-pickers-pro'
import dayjs, { Dayjs } from 'dayjs'

export const defaultLimitOptions: LabelOption[] = [
  {
    name: '20',
    label: '20'
  },
  {
    name: '50',
    label: '50'
  },
  {
    name: '100',
    label: '100'
  }
]

export const getUserOptions = (data: AuditLogRecord[]) => {
  const usernames = data.map((n: AuditLogRecord) => n.username).filter((n: string) => n !== '')
  const prunedUsernames = removeDuplicates(usernames)

  return prunedUsernames.map((n: string) => ({ id: n, name: n }))
}

export const getPayloadEmails = (data: AuditLogRecord[], selectedUsers: string[]): string[] | undefined => {
  const users = selectedUsers.filter((n) => n !== '')
  const emailRecords = data.filter((n) => users.includes(n.username))
  const emails = emailRecords.map((n) => n.email)
  const prunedEmails = removeDuplicates(emails)

  return prunedEmails.length > 0 ? prunedEmails : undefined
}

export const getPayloadActions = (actionOptions: ActionOption[], selectedActions: string[]): string[] | undefined => {
  const prunedSelectedActions = selectedActions.filter((action) => action !== '')
  const newActions = actionOptions.filter((action) => prunedSelectedActions.includes(action.name))
  const result = newActions.map((action) => action.id)

  return result.length > 0 ? result : undefined
}

export const getPayloadDateRange = (date: DateRange<Dayjs>) => {
  const from = dayjs(date[0]).format(dateFormat.short)
  const to = dayjs(date[1]).add(1, 'day').format(dateFormat.short)
  const daterange = {
    from: new Date(from).toISOString(),
    to: new Date(to).toISOString()
  }

  return date[1] !== null ? daterange : undefined
}
