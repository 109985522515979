import { FC } from 'react'

const CopyIcon: FC = () => (
  <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask
      id="mask0_3111_71111"
      style={{ maskType: 'alpha' }}
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="17"
      height="17"
    >
      <rect x="0.198242" y="0.762695" width="16" height="16" fill="#D9D9D9" />
    </mask>
    <g mask="url(#mask0_3111_71111)">
      <path
        d="M3.53158 15.429C3.16491 15.429 2.85091 15.2986 2.58958 15.0377C2.32869 14.7764 2.19824 14.4624 2.19824 14.0957V4.76237H3.53158V14.0957H10.8649V15.429H3.53158ZM6.19824 12.7624C5.83158 12.7624 5.5178 12.6319 5.25691 12.371C4.99558 12.1097 4.86491 11.7957 4.86491 11.429V3.42904C4.86491 3.06237 4.99558 2.74837 5.25691 2.48704C5.5178 2.22615 5.83158 2.0957 6.19824 2.0957H12.1982C12.5649 2.0957 12.8789 2.22615 13.1402 2.48704C13.4011 2.74837 13.5316 3.06237 13.5316 3.42904V11.429C13.5316 11.7957 13.4011 12.1097 13.1402 12.371C12.8789 12.6319 12.5649 12.7624 12.1982 12.7624H6.19824ZM6.19824 11.429H12.1982V3.42904H6.19824V11.429Z"
        fill="white"
      />
    </g>
  </svg>
)

export default CopyIcon
