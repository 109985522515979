import { FC, SyntheticEvent } from 'react'
import { LibraryDetailsHoverTooltip } from '../../../library/library-details/components/LibraryDetailsTooltip'
import { Tab } from '@mui/material'
import { a11yProps } from '../../../library/library-details/components/LibraryDetailsTabPanel'
import { Tabs } from './drawerTabs.styles'

interface Props {
  tabValue: number
  handleTabChange: (event: SyntheticEvent, newValue: number) => void
  title: string
  tabLabels: string[]
  parents?: string[]
  loading?: boolean
}

const DrawerTabs: FC<Props> = ({ tabValue, handleTabChange, title, loading, tabLabels }) => (
  <Tabs
    value={tabValue}
    onChange={handleTabChange}
    aria-label={`${title} tabs`}
    TabIndicatorProps={{
      style: { display: 'none' }
    }}
    isdetails="true"
  >
    {tabLabels.map((label, index) => {
      if (label === 'Data Sources' && location.pathname === '/asset-coverage-analyzer') {
        return (
          <LibraryDetailsHoverTooltip key={index} title="Enabled data sources to analyze" arrow>
            <Tab label={label} {...a11yProps(index)} />
          </LibraryDetailsHoverTooltip>
        )
      }

      return <Tab label={label} {...a11yProps(index)} key={index} disabled={loading} />
    })}
  </Tabs>
)

export default DrawerTabs
