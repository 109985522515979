import { FC } from 'react'
import { Box, Typography } from '@mui/material'
import styled from '@emotion/styled/macro'
import { ThemeButton } from '../../../components/buttons'
import { RightIconButton } from '../../../components/svg'
import { useNavigate } from 'react-router-dom'

const Container = styled(Box)`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 13px 16px;
  top: 0;
  left: 72px;
  width: calc(100% - 72px);
  height: 100px;
  z-index: 2000;
  background: #e9e0e4;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.3), 0 2px 6px 2px rgba(0, 0, 0, 0.15);

  .wrapper {
    display: flex;
    justify-content: center;
    align-items: center;

    .header {
      gap: 4px;
      margin-left: 16px;

      .title {
        color: #342f32;
        font-family: Quicksand, sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: 0.1px;
      }

      .subtitle {
        color: #342f32;
        font-family: Quicksand, sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0.25px;
      }
    }
  }
`

const NoDataBanner: FC = () => {
  const navigate = useNavigate()

  const handleExploreCpis = () => {
    navigate('/library')
  }

  return (
    <Container>
      <Box className="wrapper">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M19 9L20.25 6.25L23 5L20.25 3.75L19 1L17.75 3.75L15 5L17.75 6.25L19 9Z" fill="#342F32" />
          <path d="M19 15L17.75 17.75L15 19L17.75 20.25L19 23L20.25 20.25L23 19L20.25 17.75L19 15Z" fill="#342F32" />
          <path
            d="M11.5 9.5L9 4L6.5 9.5L1 12L6.5 14.5L9 20L11.5 14.5L17 12L11.5 9.5ZM9.99 12.99L9 15.17L8.01 12.99L5.83 12L8.01 11.01L9 8.83L9.99 11.01L12.17 12L9.99 12.99Z"
            fill="#342F32"
          />
        </svg>
        <Box className="header">
          <Typography className="title">Set Up a Data Source to Begin</Typography>
          <Typography className="subtitle">Integrate a data source to activate a CPI</Typography>
        </Box>
      </Box>
      <Box className="action-wrapper">
        <ThemeButton
          themevariant="quaternary"
          sx={{
            color: '#FBD8F5 !important',
            background: '#573F56 !important',
            svg: {
              path: {
                fill: '#DEBDD9 !important'
              }
            }
          }}
          onClick={handleExploreCpis}
        >
          <>
            <RightIconButton />
            Explore CPIs
          </>
        </ThemeButton>
      </Box>
    </Container>
  )
}

export default NoDataBanner
