const LegendFindingIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="15" height="16" viewBox="0 0 15 16" fill="none">
    <rect x="1.16667" y="1.89506" width="12.6667" height="12.6667" rx="6.33333" fill="#1E1A1D" />
    <rect x="1.16667" y="1.89506" width="12.6667" height="12.6667" rx="6.33333" fill="#FFA9FC" fillOpacity="0.11" />
    <rect
      x="1.16667"
      y="1.89506"
      width="12.6667"
      height="12.6667"
      rx="6.33333"
      stroke="#D0C3CC"
      strokeWidth="2.33333"
    />
  </svg>
)

export default LegendFindingIcon
