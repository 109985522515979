import { baseColors, baseUnit, iconSize, spacing, colorTokens } from './config'

export const colors = {
  primary: '#3C3854',
  primaryDark: '#1E1A1D',
  primaryLight: '#573F56',
  backgroundLight: 'linear-gradient(0deg, rgba(255, 169, 252, 0.08), rgba(255, 169, 252, 0.08)), #1E1A1D',
  backgroundMedium: 'linear-gradient(0deg, rgba(255, 169, 252, 0.12), rgba(255, 169, 252, 0.12)), #1E1A1D',
  backgroundDark: 'linear-gradient(0deg, rgba(255, 169, 252, 0.14), rgba(255, 169, 252, 0.14)), #1E1A1D',
  white: '#FFFFFF',
  info: '#6ABEED',
  warning: '#FFBC6C',
  infoLight: '#D3CEEF',
  infoDark: '#B4ACFB',
  errorLight: '#F89B8E',
  warningLight: '#FFCC8E',
  primaryBorder: '#423D5C',
  secondaryDark: '#3F3B4D',
  rawSienneCore: '#F4BA94',
  purpleLight: '#E1BEE7',
  purpleDark: '#23053A',
  neutral: '#E9E0E4',
  neutral100: '#CDC4C8'
}

export const attributes = {
  boxShadow: {
    primary: '0 4px 8px 3px rgba(0, 0, 0, 0.15), 0 1px 3px rgba(0, 0, 0, 0.3)',
    secondary: '0 6px 10px 4px rgba(0, 0, 0, 0.15), 0 2px 3px rgba(0, 0, 0, 0.3)',
    tertiary: '0 4px 4px rgba(0, 0, 0, 0.25)',
    quaternary: '0 1px 3px 1px rgba(0, 0, 0, 0.15), 0 1px 2px rgba(0, 0, 0, 0.3)',
    toolbar: '0 2px 6px 2px rgba(0, 0, 0, 0.15), 0 1px 2px rgba(0, 0, 0, 0.3)'
  },
  borderRadius: {
    primary: '20px',
    secondary: '8px'
  }
}

export const theme = {
  colors,
  attributes,
  baseColors,
  baseUnit,
  iconSize,
  spacing,
  colorTokens
}
