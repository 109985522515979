import { Dispatch, FC, SetStateAction, useMemo } from 'react'
import { Box, IconButton, Typography } from '@mui/material'
import dayjs from 'dayjs'

/* Utils */
import axios from '../../../../../../../../lib/axios'
import { CustomLabelCountI, CustomLabelI, SystemEventName } from '../../../../../../../../models'
import { dateFormat } from '../../../../../../../../lib/utils'
import { CUSTOM_LABEL_ID, getSystemEventValue } from '../../../../../utils'

/* Components */
import { Wrapper } from './CustomLabel.style'
import YellowLabelSvg from '../../../../../../../components/svg/labels/YellowLabelSvg'
import { LibraryDetailsHoverTooltip } from '../../../../components/LibraryDetailsTooltip'
import { EditIcon } from '../../../../../../../components/svg'
import PinSvg from '../../../../../../../components/svg/labels/PinSvg'

interface Props {
  setLoading: Dispatch<SetStateAction<boolean>>
  customLabel: CustomLabelI
  customLabelsCount: CustomLabelCountI
  updateEditLabel: (label: any) => void
  updateMode: (newMode: string) => void
  updateLabels: () => void
}

const CustomLabel: FC<Props> = ({
  customLabel,
  customLabelsCount,
  updateMode,
  updateEditLabel,
  updateLabels,
  setLoading
}) => {
  const isPined = useMemo(() => {
    return !!customLabelsCount?.pinned_labels?.find((item) => item.id === customLabel.id)
  }, [customLabelsCount])

  const openEdit = () => {
    updateMode('edit')
    updateEditLabel(customLabel)
  }

  const togglePin = async () => {
    try {
      setLoading(true)

      if (isPined) {
        const body = {
          ids: [customLabel.id]
        }
        await axios.delete(`/api/v1/labels/cpis/pin`, { data: body } as any)
      } else await axios.post(`/api/v1/labels/cpis/pin/${customLabel.id}`)

      await updateLabels()
    } catch (e) {
      console.error(e)
    } finally {
      setLoading(false)
    }
  }

  if (!customLabel.data) return <Box></Box>
  return (
    <Wrapper>
      <Box>
        <Box className="header">
          <YellowLabelSvg />
          <Typography className="title">
            {customLabel.action === CUSTOM_LABEL_ID ? customLabel.data.title : getSystemEventValue(customLabel.action)}
          </Typography>
        </Box>

        <Typography className="description">{customLabel.data.description}</Typography>
        <Typography className="date">
          {dayjs(customLabel.data.date || customLabel.created_at).format(dateFormat.short)}
        </Typography>
      </Box>
      <Box className="actions-wrapper">
        {isPined ? (
          <LibraryDetailsHoverTooltip title={'Unpin Label from Chart'} arrow placement="bottom-end">
            <IconButton className={`is-pined pined-button`} onClick={() => togglePin()}>
              <PinSvg isPined={true} />
            </IconButton>
          </LibraryDetailsHoverTooltip>
        ) : customLabelsCount?.pinned_labels.length >= 4 ? (
          <LibraryDetailsHoverTooltip title={'4 Labels max'} arrow placement="bottom-end">
            <IconButton className={`is-disabled pined-button`}>
              <PinSvg isPined={null} />
            </IconButton>
          </LibraryDetailsHoverTooltip>
        ) : (
          <LibraryDetailsHoverTooltip title={'Pin Label to Chart'} arrow placement="bottom-end">
            <IconButton className={`pined-button`} onClick={() => togglePin()}>
              <PinSvg isPined={false} />
            </IconButton>
          </LibraryDetailsHoverTooltip>
        )}

        {customLabel.action === SystemEventName.CpiCustom ? (
          <LibraryDetailsHoverTooltip title="Edit Label" arrow placement="bottom-end">
            <IconButton className="edit-button" onClick={() => openEdit()}>
              <EditIcon />
            </IconButton>
          </LibraryDetailsHoverTooltip>
        ) : (
          ''
        )}
      </Box>
    </Wrapper>
  )
}

export default CustomLabel
