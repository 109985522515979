import { Avatar as MuiAvatar, IconButton as MuiIconButton } from '@mui/material'
import styled from '@emotion/styled/macro'

export const IconButton = styled(MuiIconButton)`
  border-radius: 50%;
  padding: 0;
  background: #debdd9;
`

export const Avatar = styled(MuiAvatar)`
  width: 40px;
  height: 40px;
  background: #debdd9;
  color: #3f293e;
  text-transform: uppercase;
  font-family: Quicksand, sans-serif;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.1px;
`
