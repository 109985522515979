import { FC, useState } from 'react'
import { Actions, Content, Header, Wrapper } from '../userProfile.styles'
import { Box, CircularProgress, IconButton, Typography } from '@mui/material'
import { CloseChipIcon } from '../../../../svg'
import { ThemeButton } from '../../../../buttons'
import { closeModal, modalPropsSelector, setModalProps } from '../../../../../../store/modals/modalSlice'
import { useAppDispatch, useAppSelector } from '../../../../../../store/hooks'
import axios from '../../../../../../lib/axios'
import { useAuth } from '@frontegg/react'
import { Form, Formik } from 'formik'
import { emailSchema } from './user-modal.constants'
import ControlledTextField from '../../../../form-components/ControlledTextField'

interface FormValues {
  email: string
}

const ChangeEmail: FC = () => {
  const dispatch = useAppDispatch()
  const modalProps = useAppSelector(modalPropsSelector)
  const [loading, setLoading] = useState(false)
  const { user } = useAuth()

  const initialValues = { email: modalProps.props?.user?.email || '' }

  const handleClose = () => {
    dispatch(closeModal())
  }

  const handleSave = async (values: FormValues) => {
    setLoading(true)
    const value = values.email
    const userId = modalProps?.props?.user?.id
    const updateSelfUrl = '/api/v1/identity/users'
    const updateUserUrl = `/api/v1/identity/users/${userId}`
    const url = user?.id === modalProps?.props?.user?.id ? updateSelfUrl : updateUserUrl

    try {
      const res = await axios.put(url, {
        email: value
      })

      if (res.status === 200) {
        if (!modalProps.props) return
        if (!modalProps.props.user) return

        dispatch(
          setModalProps({
            open: false,
            props: {
              ...modalProps.props,
              user: {
                ...modalProps.props.user,
                email: value
              }
            }
          })
        )
      }
    } catch (e) {
      console.error(e)
    } finally {
      setLoading(false)
    }
  }

  return (
    <Wrapper>
      <Header>
        <Typography className="title">Change Email</Typography>
        <IconButton onClick={handleClose}>
          <CloseChipIcon />
        </IconButton>
      </Header>

      <Formik initialValues={initialValues} validationSchema={emailSchema} onSubmit={handleSave}>
        {({ isValid, errors }) => (
          <Form>
            <Content userrole="">
              <Box className="input-wrapper">
                <ControlledTextField
                  data-private
                  helperText={errors.email}
                  variant="outlined"
                  label="Email"
                  name="email"
                  autoComplete="off"
                  fullWidth
                  required
                />
              </Box>
            </Content>

            <Actions>
              <ThemeButton
                type="submit"
                themevariant="primary"
                disabled={!isValid || loading}
                endIcon={loading && <CircularProgress size="20px" />}
              >
                Save
              </ThemeButton>
            </Actions>
          </Form>
        )}
      </Formik>
    </Wrapper>
  )
}

export default ChangeEmail
