import { FC, PropsWithChildren } from 'react'
import { Button as MuiButton, ButtonProps } from '@mui/material'
import { colors } from '../../../../theme/'
import styled from '@emotion/styled/macro'

const Button = styled(MuiButton)`
  background: transparent;
  margin: 0 auto;
  box-sizing: border-box;
  color: ${colors.rawSienneCore};
  border-radius: 4px;
  text-transform: capitalize;
  font-size: 16px;
  line-height: 24px;
  font-weight: 700;
  padding: 6px 16px;

  :hover {
    color: #dbdbdb;
  }
`

type Props = ButtonProps

const SecondaryButton: FC<PropsWithChildren<Props>> = ({ children, ...props }) => {
  return <Button {...props}>{children}</Button>
}

export default SecondaryButton
