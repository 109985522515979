import { AnyAction, configureStore, ThunkAction } from '@reduxjs/toolkit'
import vendorReducer from './vendors/vendorSlice'
import userReducer from './user/userSlice'
import rssReducer from './rss/rssSlice'
import securityFindingReducer from './security-findings/securityFindingSlice'
import vulnerabilityReducer from './vulnerabilities/vulnerabilitySlice'
import usCertReducer from './us-certs/usCertSlice'
import modalReducer from './modals/modalSlice'
import notificationReducer from './notifications/notificationSlice'
import integrationsReducer from './integrations/integrationsSlice'
import jiraReducer from './jira/jiraSlice'
import drawerReducer from './drawers/drawerSlice'
import toastReducer from './toasts/toastSlice'

export const store = configureStore({
  reducer: {
    vendors: vendorReducer,
    user: userReducer,
    securityFindings: securityFindingReducer,
    vulnerabilities: vulnerabilityReducer,
    usCerts: usCertReducer,
    rss: rssReducer,
    modals: modalReducer,
    drawers: drawerReducer,
    notifications: notificationReducer,
    integrations: integrationsReducer,
    jira: jiraReducer,
    toasts: toastReducer
  }
})

export type RootState = ReturnType<typeof store.getState>

export type AppDispatch = typeof store.dispatch

export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, AnyAction>
