import { FC } from 'react'
import { Typography } from '@mui/material'

/* Utils */
/* Components */
import { Wrapper } from './nistFrameworkCategory.styles'
import { NistFunction } from '../../../models'

interface Props {
  categoryName: string
  categoryId: string | undefined
  cpiName: string
  onlyFunction?: boolean
  toLink?: () => void
}

const NistFrameworkCategory: FC<Props> = ({ categoryName, categoryId, toLink }) => {
  const category = categoryId ? categoryId.charAt(0).toUpperCase() + categoryId.slice(1) : ''
  const nistFn = category.includes('Identify')
    ? NistFunction.Identify
    : category.includes('Respond')
    ? NistFunction.Respond
    : category.includes('Detect')
    ? NistFunction.Detect
    : category.includes('Protect')
    ? NistFunction.Protect
    : category.includes('Recover')
    ? NistFunction.Recover
    : category.includes('Govern')
    ? NistFunction.Govern
    : ''

  return (
    <Wrapper fn={nistFn} onClick={() => (toLink ? toLink() : null)}>
      <img src="/nist-category.svg" alt="" width={24} height={24} />
      <Typography>{categoryName || 'N/A'}</Typography>
    </Wrapper>
  )
}

export default NistFrameworkCategory
