import React, { FC } from 'react'
import { Box, Typography } from '@mui/material'

/* Components */
import { CategoryInfoTooltip } from '../../../../components/CategoryInfoTooltip'
import { CPIWeightsIcon, InfoIconDangerFilled } from '../../../../../../components/svg'

interface Props {
  itemDeleted: boolean
  badName?: boolean
  duplicateName?: boolean
}

const ErrorIndicator: FC<Props> = ({ itemDeleted, badName, duplicateName }) => (
  <CategoryInfoTooltip
    placement="bottom-start"
    title={
      <>
        <Box className="tooltip-wrapper weights-wrapper">
          {!badName && !duplicateName && (
            <Box className="weights-wrapper-header">
              <CPIWeightsIcon />
              <Typography className="tooltip-text weights-title">CPI Weights</Typography>
            </Box>
          )}
          <Box className="weights-wrapper-header header-text-wrapper">
            <InfoIconDangerFilled className="weights-warning-icon" fill="#FF8A80" />
            <Typography className="tooltip-text weights">
              {badName
                ? "Category name can't be empty"
                : duplicateName
                ? 'Please choose a different category name'
                : !itemDeleted
                ? 'After changing the weight of a CPI in this category the total of the CPI weights doesn’t equal 100%. Please make adjustments.'
                : 'After removing a CPI from this category the total of the CPI weights doesn’t equal 100%. Please make adjustments.'}
            </Typography>
          </Box>
        </Box>
      </>
    }
  >
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="info-icon"
    >
      <path
        d="M12 21C13.1046 21 14 20.1046 14 19C14 17.8954 13.1046 17 12 17C10.8954 17 10 17.8954 10 19C10 20.1046 10.8954 21 12 21Z"
        fill="#FF8A80"
      />
      <path d="M10 3H14V15H10V3Z" fill="#FF8A80" />
    </svg>
  </CategoryInfoTooltip>
)

export default ErrorIndicator
