import { useCallback, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router'
import { useBlocker } from './useBlocker'
import axios from '../lib/axios'

export function useDeleteFramework(when: boolean): (boolean | (() => void))[] {
  const navigate = useNavigate()
  const location = useLocation()
  const [shouldDeleteFramework, setShouldDeleteFramework] = useState(when)
  const [lastLocation, setLastLocation] = useState<any>(null)
  const [confirmedNavigation, setConfirmedNavigation] = useState(false)

  useEffect(() => {
    setShouldDeleteFramework(when)
  }, [when])

  const handleBlockedNavigation = useCallback(
    (nextLocation: any) => {
      if (shouldDeleteFramework && nextLocation.location.pathname !== location.pathname) {
        setLastLocation(nextLocation)

        axios.delete('/api/v3/frameworks/custom').catch((e) => e)
        confirmNavigation()

        return false
      }
      return false
    },
    [shouldDeleteFramework, location]
  )

  const confirmNavigation = useCallback(() => {
    setShouldDeleteFramework(false)
    setConfirmedNavigation(true)
  }, [])

  useEffect(() => {
    if (confirmedNavigation && lastLocation) {
      navigate(lastLocation.location?.pathname)

      setConfirmedNavigation(false)
    }
  }, [confirmedNavigation, lastLocation])

  useBlocker(handleBlockedNavigation, when)

  return [shouldDeleteFramework, confirmNavigation]
}
