/* eslint-disable no-unused-vars */
export interface QuestionnaireItemChoices {
  id: string
  label: string
}

export enum QuestionnaireItemValueType {
  optionArray = 'OptionArray',
  optionValue = 'OptionValue',
  integerScale = 'IntegerScale',
  integerValue = 'IntegerValue',
  vendorArray = 'VendorArray',
  productArray = 'ProductArray'
}

export interface QuestionnaireItem {
  id: string
  label: string
  value_type: QuestionnaireItemValueType
  multi: boolean
  min_value: number
  max_value: number
  required: boolean
  allowed_choices: QuestionnaireItemChoices[]
}

export type Questionnaire = QuestionnaireItem[]

export enum OnboardingCategory {
  companyInfo = 'Company Info',
  frameworkCompliance = 'Framework Compliance',
  securityPractices = 'Security Practices',
  riskAssesment = 'Risk Assesment',
  vendors = 'Vendors'
}

export interface QuestionSet {
  [OnboardingCategory.companyInfo]: Array<QuestionnaireItem>
  [OnboardingCategory.frameworkCompliance]: Array<QuestionnaireItem>
  [OnboardingCategory.securityPractices]: Array<QuestionnaireItem>
  [OnboardingCategory.riskAssesment]: Array<QuestionnaireItem>
  [OnboardingCategory.vendors]: Array<QuestionnaireItem>
}
