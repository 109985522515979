export enum FrameworkType {
  Onyxia = 'onyxia',
  Nist = 'nist',
  Hipaa = 'hipaa',
  Pci = 'pci',
  Soc2 = 'soc2',
  Custom = 'custom'
}

export interface IFrameworkData {
  id: string
  title: string
  template: FrameworkType
  functions: FrameworkCategory[]
  description: string
}

export interface IFramework {
  id: string
  variant: FrameworkType
  title: string
  data: IFrameworkData
  created_at: string
  created_by: string
  updated_at: string
}

export interface FrameworkCategoryOption {
  id: string
  title: string
}

export interface FrameworkCategory {
  id: string
  title: string
  weight?: number
  chip_bg_color: string
  chip_color: string
}

export interface FrameworkCategoryCpi {
  displayName: string
  name: string
  title: string
  weight: number
  isActive: boolean
  id: string
}

export interface FrameworkCategoryWeightItem {
  id: string
  name: string
  weight: number
  chip_bg_color: string
  chip_color: string
  cpis: FrameworkCategoryCpi[]
}

export interface FrameworkCategoriesForm {
  categories: FrameworkCategoryWeightItem[]
  categoryCpis: FrameworkCategoryCpi[]
  categoryFramework: {
    value: FrameworkType
  }
  framework: FrameworkType | null
}

/* API Response Interfaces */
export interface CategoriesWeightsCategory {
  id: string
  name: string
  weight: number
}

export interface CategoriesWeightsResponse {
  categories: CategoriesWeightsCategory[]
}

export interface FrameworkCategoryResponseItem {
  id: string
  title: string
  chip_color: string
  chip_bg_color: string
}

export interface FrameworkCategoryResponse {
  categories: FrameworkCategoryResponseItem[]
}

export interface CPIWeightsByCategoryCpi {
  name: string
  displayName: string
  title: string
  isActive: boolean
  weight?: number
}

export interface CPIWeightsByCategoryResponse {
  id: string
  name: string
  cpis: CPIWeightsByCategoryCpi[]
}

export interface FrameworkTemplate {
  id: FrameworkType
  title: string
}
