import { Box, FormControl, Select } from '@mui/material'
import styled from '@emotion/styled/macro'

const ITEM_HEIGHT = 32
const ITEM_PADDING_TOP = 8

export const Wrapper = styled(Box)`
  display: flex;
  align-items: center;
  gap: 8px;
`

export const CategoryMenuProps = {
  PaperProps: {
    sx: {
      background: 'linear-gradient(0deg, rgba(255, 169, 252, 0.14), rgba(255, 169, 252, 0.14)), #1E1A1D',
      boxShadow: '0 8px 12px 6px rgba(0, 0, 0, 0.15), 0 4px 4px rgba(0, 0, 0, 0.3)',
      borderRadius: '8px',
      minWidth: '345px !important',
      maxHeight: '486px !important',

      '.MuiList-root': {
        paddingBottom: '8px',
        background: '#573F56'
      },

      '.MuiMenuItem-root': {
        height: '48px',
        paddingLeft: '4px',
        paddingRight: '16px',
        gap: '4px',

        img: {
          marginRight: '8px'
        },

        '&:hover': {
          background: 'rgba(208, 188, 255, 0.16) !important'
        },

        '.MuiCheckbox-root': {
          padding: '8px',

          '&:hover': {
            background: 'rgba(202, 196, 208, 0.12)'
          }
        },

        '.MuiListItemText-root .MuiTypography-root': {
          fontFamily: '"Quicksand", sans-serif',
          fontWeight: 500,
          fontSize: '16px',
          lineHeight: '24px',
          letterSpacing: '0.1px',
          color: '#fff'
        },

        display: 'flex',
        alignItems: 'center',
        width: '100%',

        '.image-wrapper': {
          display: 'flex',

          '> img': {
            width: '18px',
            height: '18px',
            boxShadow: '0 4px 4px 0 rgba(0, 0, 0, 0.3), 0 8px 12px 6px rgba(0, 0, 0, 0.15)',
            margin: '-4.5px',
            borderRadius: '50%',

            '&:nth-of-type(1)': {
              zIndex: 10
            },
            '&:nth-of-type(2)': {
              zIndex: 9
            },
            '&:nth-of-type(3)': {
              zIndex: 8
            },
            '&:nth-of-type(4)': {
              zIndex: 7
            },
            '&:nth-of-type(5)': {
              zIndex: 6
            },
            '&:nth-of-type(6)': {
              zIndex: 5
            },
            '&:nth-of-type(7)': {
              zIndex: 4
            }
          }
        },

        '.textWrapper': {
          marginLeft: '13px'
        },

        '&.Mui-disabled': {
          opacity: 1,

          '.MuiCheckbox-root': {
            opacity: 0.38
          },

          svg: {}
        }
      },

      '&::-webkit-scrollbar-track': {
        background: '#3e2e3d'
      },

      '&::-webkit-scrollbar-thumb': {
        background: '#FBD8F5',
        height: '4px',
        width: '4px',
        border: '3px solid transparent',
        backgroundClip: 'content-box'
      }
    },
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP
    }
  },
  anchorOrigin: {
    vertical: 'bottom' as const,
    horizontal: 'left' as const
  },
  transformOrigin: {
    vertical: 'top' as const,
    horizontal: 'left' as const
  },
  variant: 'menu' as const
  // getContentAnchorEl: null
}

export const LibraryFormControl = styled(FormControl)<{ noborder?: string; isselected?: string; iscustom?: string }>`
  border-radius: ${({ iscustom }) => (iscustom && iscustom === 'true' ? '100px' : '8px')};
  min-width: 113px;
  background: transparent;
  border: 1px solid #998d96;
  height: ${({ iscustom }) => (iscustom && iscustom === 'true' ? '40px' : '32px')};
  z-index: 10;

  ${({ isselected }) =>
    isselected &&
    isselected === 'true' &&
    `
    background: rgba(234, 221, 255, 0.08);
    color: #FFA9FC !important;
    
    .MuiOutlinedInput-root {
      .MuiSelect-select {
        color: #FFA9FC !important;
      }
    }    
  `};

  &:hover {
    border: 1px solid #ffa9fc;
    background: rgba(208, 188, 255, 0.16);
  }

  ${({ iscustom }) =>
    iscustom &&
    iscustom === 'true' &&
    `
    justify-content: center;
  `};

  .MuiOutlinedInput-root {
    background: transparent;
    border-color: transparent;
  }

  ${({ isselected }) =>
    isselected &&
    isselected === 'true' &&
    `
    border: 1px solid #FFA9FC;
    background: rgba(208, 188, 255, 0.16);
  `};

  ${({ noborder, isselected }) =>
    noborder &&
    noborder === 'true' &&
    `
    border-radius: ${isselected && isselected === 'true' ? '100px' : 0};
    border-color: transparent;
    border: ${isselected && isselected === 'true' ? '1px solid #ffa9fc' : 0};
    background: ${isselected && isselected === 'true' ? 'rgba(208, 188, 255, 0.16)' : 'transparent'};
    
    &:hover {
      border-radius: 100px;
    }
  `};
`

export const ComingSoonChip = styled(Box)`
  display: inline-flex;
  padding: 0 4px;
  align-items: center;
  gap: 8px;
  border-radius: 18px;
  background: #3754fc;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.3), 0 1px 3px 1px rgba(0, 0, 0, 0.15);
  position: absolute;
  top: 0;
  right: 10px;

  .chip-text {
    color: #fff;
    text-align: center;
    font-family: Quicksand, sans-serif;
    font-size: 11px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0.5px;
  }
`

export const LibrarySelect = styled(Select)<{ isselected?: string; noborder?: string; iscustom?: string }>`
  min-width: 113px;
  height: 32px;
  background: transparent;
  gap: 8px;
  border-radius: 100px;
  border: 1px solid #998d96;

  ${({ noborder }) =>
    noborder &&
    noborder === 'true' &&
    `
    border-radius: 0;
    border: 0;
    border-color: transparent;
    background: transparent;
  `};

  color: #d0c3cc;
  font-family: Quicksand, sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.1px;
  text-align: left;

  ${({ isselected }) =>
    isselected &&
    isselected === 'true' &&
    `
    background: rgba(234, 221, 255, 0.08);
    color: #FFA9FC !important;
    
    .MuiOutlinedInput-root {
      .MuiSelect-select {
        color: #FFA9FC !important;
      }
    }    
  `};

  > * {
    border: none !important;
    outline: none !important;
  }

  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border: none !important;
  }

  ${({ iscustom }) =>
    iscustom &&
    iscustom === 'true' &&
    `
    min-width: 122px;
    height: 40px;
  `};

  .MuiOutlinedInput-root {
    ${({ iscustom }) =>
      iscustom &&
      iscustom === 'true' &&
      `
      height: 40px;
  `};
  }

  svg {
    top: calc(50% - 0.6em);
    right: 13px;

    &.MuiSelect-icon {
      pointer-events: none;
      color: rgba(0, 0, 0, 0.54);
      position: absolute;
    }

    &.MuiSelect-iconClosed {
      transform: rotate(0deg) !important;
    }

    &.MuiSelect-iconOpen {
      transform: rotate(180deg) !important;
    }

    path {
      fill: #ffa9fc;
    }
  }
`
