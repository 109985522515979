import { styled } from '@mui/material/styles'
import { tooltipClasses, TooltipProps } from '@mui/material/Tooltip'
import { Tooltip } from '@mui/material'

export const ErrorTooltip = styled(
  ({ className, ...props }: TooltipProps & { isChip?: boolean; isSquare?: boolean }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  )
)({
  [`& .${tooltipClasses.tooltip}`]: {
    display: 'flex',
    marginTop: '0 !important',
    padding: '16px',
    flexDirection: 'column',
    alignItems: 'flex-start',
    borderRadius: '16px',
    border: '1px solid #998D96',
    background: '#4E4E4E',
    minWidth: '253px'
  },
  '.text-container': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    whiteSpace: 'pre-wrap',
    wordBreak: 'break-word',
    textAlign: 'start',

    '.tooltip-title': {
      color: '#FFF',
      fontFamily: "'Quicksand',sans-serif",
      fontSize: '16px',
      fontStyle: 'normal',
      fontWeight: 500,
      lineHeight: '24px',
      letterSpacing: '0.1px'
    },

    '.description-container': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      gap: '16px',

      '.tooltip-text': {
        color: '#FFF',
        fontFamily: "'Quicksand',sans-serif",
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: '20px',
        letterSpacing: '0.25px',

        'ul li': {
          color: '#FFF',
          fontFamily: "'Quicksand',sans-serif",
          fontSize: '14px',
          fontStyle: 'normal',
          fontWeight: 400,
          lineHeight: '20px',
          letterSpacing: '0.25px'
        }
      }
    }
  },
  '.tooltip-wrapper': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
    gap: '8px',
    width: '100%',

    '.instance-name-container': {
      display: 'flex',
      alignItems: 'center',
      gap: '8px',

      img: {
        width: '24px',
        height: '24px',
        borderRadius: '50%',
        minWidth: 'unset !important'
      },

      '.instance-name': {
        color: '#FFF',
        fontFamily: '"Quicksand", sans-serif',
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: 590,
        lineHeight: 'normal'
      }
    },

    '.error-description-container': {
      display: 'flex',
      alignItems: 'center',
      gap: '8px',

      '.error-icon': {
        width: '24px',
        height: '24px',
        borderRadius: '50%'
      },

      '.error-text': {
        color: '#FF8A80',
        fontFamily: '"Quicksand", sans-serif',
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: 'normal'
      }
    }
  }
})

export const linkSx = {
  whiteSpace: 'nowrap',
  fontFamily: '"Quicksand", sans-serif',
  fontSize: '12px',
  fontWeight: 500,
  lineHeight: '16px',
  letterSpacing: '0.5px',
  textDecoration: 'underline',
  cursor: 'pointer'
}
