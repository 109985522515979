import React, { useEffect } from 'react'

/* Utils */
import { useAppDispatch, useAppSelector } from '../../../../store/hooks'

/* Components */
import { Container } from './account-management.styles'
import { modalPropsSelector, setModalProps } from '../../../../store/modals/modalSlice'
import { useAuth } from '@frontegg/react'
import UserProfile from '../../../components/modal/variants/user-profile/UserProfile'
import HeaderComponent from '../../../components/header/HeaderComponent'
import { breadcrumbs } from '../../../../lib/utils'
import ContentWrapper from '../../../components/header/ContentWrapper'

const Profile = () => {
  const dispatch = useAppDispatch()
  const modalProps = useAppSelector(modalPropsSelector)
  const { user } = useAuth()

  useEffect(() => {
    const currentUser = {
      id: user?.id || '',
      name: modalProps.props?.user?.name || user?.name || '',
      email: modalProps.props?.user?.email || user?.email || '',
      phone_number: Number(user?.phoneNumber) ?? null,
      profile_picture_url: user?.profilePictureUrl || '',
      tenant_id: user?.tenantId || '',
      verified: Boolean(user?.verified),
      created_at: (user as any)?.createdAt ?? '',
      role: user?.roles[0].name ?? ''
    }

    dispatch(
      setModalProps({
        open: false,
        props: {
          ...modalProps.props,
          user: currentUser
        }
      })
    )
  }, [])

  return (
    <Container>
      <HeaderComponent breadcrumbs={breadcrumbs.profile} />

      <ContentWrapper line={1}>
        <UserProfile />
      </ContentWrapper>
    </Container>
  )
}

export default Profile
