const LegendSlaIcon = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8 5.09091C9.60727 5.09091 10.9091 6.39273 10.9091 8C10.9091 9.60727 9.60727 10.9091 8 10.9091C6.39273 10.9091 5.09091 9.60727 5.09091 8C5.09091 6.39273 6.39273 5.09091 8 5.09091ZM1.49818 7.27273C1.83273 4.24 4.24 1.83273 7.27273 1.49818V0H8.72727V1.49818C11.76 1.83273 14.1673 4.24 14.5018 7.27273H16V8.72727H14.5018C14.1673 11.76 11.76 14.1673 8.72727 14.5018V16H7.27273V14.5018C4.24 14.1673 1.83273 11.76 1.49818 8.72727H0V7.27273H1.49818ZM8 13.0909C10.8145 13.0909 13.0909 10.8145 13.0909 8C13.0909 5.18545 10.8145 2.90909 8 2.90909C5.18545 2.90909 2.90909 5.18545 2.90909 8C2.90909 10.8145 5.18545 13.0909 8 13.0909Z"
      fill="#FFF"
    />
  </svg>
)

export default LegendSlaIcon
