const UnPinAll = () => (
  <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11 3V6.75C11 7.59 11.2775 8.37 11.75 9H7.25C7.7375 8.355 8 7.575 8 6.75V3H11ZM13.25 1.5H5.75C5.3375 1.5 5 1.8375 5 2.25C5 2.6625 5.3375 3 5.75 3H6.5V6.75C6.5 7.995 5.495 9 4.25 9V10.5H8.7275V15.75L9.4775 16.5L10.2275 15.75V10.5H14.75V9C13.505 9 12.5 7.995 12.5 6.75V3H13.25C13.6625 3 14 2.6625 14 2.25C14 1.8375 13.6625 1.5 13.25 1.5Z"
      fill="#66006E"
    />
    <path d="M15.8535 2.35352L4.85352 13.3535" stroke="#66006E" stroke-width="1.5" stroke-linecap="round" />
  </svg>
)

export default UnPinAll
