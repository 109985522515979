import { FC } from 'react'

/* Utils */
import { IChartData, IntegrationInstance } from '../../../../models'

/* Components */
import PerformanceScore from './PerformanceScore'
import EnvironmentStats from './EnvironmentStats'
import OverallPerformanceScore from './OverallPerformanceScore/OverallPerformanceScore'

interface Props {
  performanceScore: number
  cpisActivated: number
  chartLoading: boolean
  initialLoad: boolean
  chartData: IChartData[]
  noCategories: boolean
  configuredIntegrations: IntegrationInstance[]
  activeUsers: number
  availableCpis: string[]
  handleCategoryClick: (category: string) => void
  lastUpdatePerformance: string
}

const DashboardHero: FC<Props> = ({
  performanceScore,
  cpisActivated,
  chartLoading,
  initialLoad,
  chartData,
  noCategories,
  configuredIntegrations,
  activeUsers,
  availableCpis,
  handleCategoryClick,
  lastUpdatePerformance
}) => {
  return (
    <>
      <OverallPerformanceScore performanceScore={performanceScore} />
      <PerformanceScore
        chartLoading={chartLoading}
        initialLoad={initialLoad}
        chartData={chartData}
        isEmpty={false}
        handleCategoryClick={handleCategoryClick}
        noCategories={noCategories}
        lastUpdatePerformance={lastUpdatePerformance}
      />
      <EnvironmentStats
        cpisActivated={cpisActivated}
        dataSources={configuredIntegrations.length}
        activeUsers={activeUsers}
        availableCPIs={availableCpis.length}
      />
    </>
  )
}

export default DashboardHero
