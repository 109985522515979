import styled from '@emotion/styled/macro'
import { Box } from '@mui/material'

export const FormWrapper = styled(Box)`
  .Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: #ffa9fc !important;
  }

  .MuiInputLabel-root.Mui-focused {
    color: #ffa9fc !important;
  }

  .texts {
    font-family: 'Quicksand', sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.5px;
    color: #e9e0e4;
    text-transform: capitalize;
  }

  svg {
    color: white;
  }

  .MuiList-root {
    background: red;
  }
`
