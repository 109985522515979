import { FC } from 'react'
import { Box } from '@mui/material'
import styled from '@emotion/styled/macro'

const Wrapper = styled(Box)`
  border-radius: 18px;
  background: #debdd9;
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.15), 0 1px 2px 0 rgba(0, 0, 0, 0.3);
  display: flex;
  padding: 2px 8px;
  align-items: center;
  gap: 3px;
  min-width: 61px;
  height: 20px;
  text-transform: uppercase;
  color: #3f293e;
  text-align: center;
  font-family: Quicksand, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.5px;
  white-space: nowrap;
`

interface Props {
  text: string
  sx?: Record<string, unknown>
}

const CpiChip: FC<Props> = ({ text, sx }) => <Wrapper sx={sx}>{text}</Wrapper>

export default CpiChip
