import {
  CPIManifest,
  CPIManifestUIUserInput,
  CybersecurityFramework,
  PerformanceScoreCategoryDetailsDataSources
} from '.'

export interface CPILibraryIntegration {
  display_name: string
  name: string
  vendor: string
  product: string
  logo_url: string
}

export interface CPIIntegrationItem {
  id: string
  name: string
  deleted?: boolean
  configName?: string
}

export interface CPIDataSources {
  display_name: string
  logo_path: string
  name: boolean
}

export interface CPILibraryConfigurationIntegration {
  id: string
  name: string
  configName?: string
  deleted?: boolean
}

export interface CPILibraryConfiguration {
  integrations: CPILibraryConfigurationIntegration[]
  created_at: string
  created_by: string
  id: string
  name: string
  user_input: CPIManifestUIUserInput
  tenant_id: string
  updated_at: string
  updated_by: string
  dataSources: PerformanceScoreCategoryDetailsDataSources[]
}

export interface CPILibraryItemCategory {
  id: string
  framework_id: string
  title: string
  shadowed?: boolean
}

export interface CPILibraryItem {
  name: string
  title: string
  long_description: string
  short_description: string
  published_at: string
  identifier: string
  categories: string[]
  category: CPILibraryItemCategory
  cybersecurity_frameworks: CybersecurityFramework[]
  business_value: string
  supported_integrations: CPILibraryIntegration[]
  favorite: boolean
  configuration?: CPILibraryConfiguration
  display_name: string
}

export interface CPIConfigUserInputVisualizationParamSection {
  name: string
  value: number
}

export interface CPIConfigUserInputVisualizationParam {
  identical_section_values: boolean
  identical_value?: number
  sections?: CPIConfigUserInputVisualizationParamSection[]
}

export interface CPIConfigUserInput {
  formula_params: CPIManifest['ui']['trend_chart']['user_input']['formula_params']
  visualization_param: CPIConfigUserInputVisualizationParam
}

export interface ConfiguredCPI {
  id: string
  name: string
  tenant_id: string
  created_at: string
  updated_at: string
  created_by: string
  updated_by: string
  integrations: Array<CPIIntegrationItem>
  user_input: CPIConfigUserInput
}

export enum CPISeverity {
  'All' = 'all',
  'Critical' = 'critical',
  'High' = 'high',
  'Medium' = 'medium',
  'Low' = 'low'
}

export enum CPITimePeriod {
  'Week' = '1W',
  'Month' = '1M',
  'ThreeMonths' = '3M',
  'SixMonths' = '6M',
  'PastYear' = 'Past Year',
  'Custom' = 'Custom'
}

export enum CPIFrequency {
  'Daily' = 'daily',
  'Weekly' = 'weekly',
  'Monthly' = 'monthly'
}

export enum DataStatus {
  'Initializing' = 'INITIALIZING',
  'Ready' = 'READY',
  'Recalculating' = 'RECALCULATING'
}

export interface ChartDataValue {
  xValue: string
  yValue: number
  numerator: number
  denominator: number
  dummy?: boolean
}

export interface ChartDataValueExtended extends ChartDataValue {
  start: string
  end: string
}

export interface ChartDataExtended {
  recentDate: string
  recentValue: number
  status: DataStatus
  zoneColor: string
  sla: number
  updatedAt: string // date ISO format
  values: ChartDataValueExtended[]
}

export interface TrendsDataPayload {
  section: CPISeverity
  integration_ids: string[]
  timePeriod?: '1w' | '1m' | '3m' | '6m' | '1y'
  dateFrom?: string
  dateTo?: string
  stripes?: number
  frequency: string
  type?: string
}

export interface CustomTrendsDataPayload extends TrendsDataPayload {
  frequency: string
  stripes?: number
}

export interface CustomLabelsDataPayload {
  timePeriod?: '1w' | '1m' | '3m' | '6m' | '1y'
  time_period?: '1w' | '1m' | '3m' | '6m' | '1y'
  frequency: string
}

export interface ChartData {
  recentDate: string
  recentValue: number
  status: DataStatus
  zoneColor: string
  sla: number
  updatedAt: string // date ISO format
  values: ChartDataValue[]
}

export interface LibraryFilters {
  category: string[]
  source: string[]
  status: string[]
  sortBy: string
  frameworkCategory: 'assigned' | 'unassigned' | ''
}

export interface PredictionChartData extends Omit<ChartData, 'values'> {
  values: PredictionDataExtended[]
}

export interface PredictionData {
  xValue: string
  yValue: number
}

export interface PredictionDataExtended extends PredictionData {
  prediction: boolean
}
