const ITEM_HEIGHT = 32
const ITEM_PADDING_TOP = 8

export const formControlSx = {
  my: 1,
  width: 300,

  '.MuiOutlinedInput-notchedOutline': {
    border: '1px solid #F4BA94'
  },

  '.MuiInputLabel-root': {
    color: '#fff'
  }
}

export const menuItemSx = {
  '.MuiList-root .MuiMenu-list': {
    paddingTop: '0 !important'
  },

  '.MuiTypography-root': {
    fontSize: '14px',
    lineHeight: '18px',
    fontWeight: 400,
    color: 'rgba(20, 12, 64, 0.9)'
  },

  '&.Mui-selected': {
    background: 'transparent'
  }
}

export const MenuProps = {
  PaperProps: {
    sx: {
      transform: 'translateY(-4px) !important'
    },
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 280,
      left: '200px !important'
    }
  }
}

export const selectInputSx = { color: '#rgba(20, 12, 64, 0.5)', fontSize: '16px', lineHeight: '24px' }

export const selectSx = {
  color: '#000',
  fontSize: '16px',
  lineHeight: '24px',
  maxWidth: '280px',
  maxHeight: ITEM_HEIGHT,
  background: '#fff'
}

export const selectMenuItemSx = {
  padding: '6px 16px',

  '.MuiTypography-root': {
    fontSize: '14px',
    lineHeight: '18px',
    fontWeight: 400,
    color: 'rgba(20, 12, 64, 0.9)'
  }
}

export const selectCheckboxSx = {
  '&.MuiCheckbox-root.MuiCheckbox-indeterminate': {
    padding: '9px',

    input: {
      width: '34px',
      height: '34px'
    },

    svg: {
      fontSize: '1.125rem',
      marginRight: '-2.1px'
    }
  }
}

export const selectListItemTextSx = { padding: '9px 9px 9px 0' }
