import { Box } from '@mui/material'
import styled from '@emotion/styled/macro'

export const Container = styled(Box)`
  width: 100%;
  height: 100%;
  background: #131013;

  .sub-header-text {
    margin-top: 16px;
    margin-left: 24px;
    color: #fff;
    font-family: Quicksand, sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
`

export const Header = styled(Box)`
  height: 100px;
  padding: 24px;
  background: transparent;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 1;

  .header-title {
    color: #fff;
    font-family: Quicksand, sans-serif;
    font-size: 28px;
    font-style: normal;
    font-weight: 400;
    line-height: 36px;
    width: fit-content;
  }

  .header-actions {
    display: flex;
    align-items: center;
    gap: 16px;

    .divider {
      width: 1px;
      height: 56px;
      background: #4d444b;
    }

    .edit-icon {
      margin-left: -8px;
      color: #ffa9fc;

      &:hover {
        background: #362e35;
        cursor: pointer;
      }

      &[aria-expanded='true'] {
        background: #362e35;
        cursor: pointer;
      }
    }
  }
`

export const ContentWrapper = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 0 24px;
  margin-top: -100px;
`

export const Content = styled(Box)`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  gap: 24px;

  @media (max-width: 1280px) {
    flex-direction: column;
  }
`

export const Divider = styled(Box)`
  width: 1px;
  height: 272px;
  background: #4d444b;

  @media (max-width: 1280px) {
    height: 1px;
    width: 400px;
  }
`
