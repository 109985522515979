import { Box, FormControl, Select } from '@mui/material'
import styled from '@emotion/styled/macro'

const ITEM_HEIGHT = 32
const ITEM_PADDING_TOP = 8

export const CategoryMenuProps = {
  PaperProps: {
    sx: {
      background: 'linear-gradient(0deg, rgba(255, 169, 252, 0.14), rgba(255, 169, 252, 0.14)), #1E1A1D',
      boxShadow: '0 8px 12px 6px rgba(0, 0, 0, 0.15), 0 4px 4px rgba(0, 0, 0, 0.3)',
      borderRadius: '4px',
      minWidth: '130px',

      '.MuiMenuItem-root': {
        height: '32px',
        paddingLeft: '16px',
        paddingRight: '16px',

        img: {
          marginRight: '8px'
        },

        '&:hover': {
          background: 'rgba(208, 188, 255, 0.16) !important'
        },

        '.MuiCheckbox-root': {
          paddingRight: '19px',
          paddingLeft: 0
        },

        '.MuiListItemText-root .MuiTypography-root': {
          fontFamily: '"Quicksand", sans-serif',
          fontWeight: 400,
          fontSize: '14px',
          lineHeight: '20px',
          letterSpacing: '0.25px',
          color: '#d0c3cc'
        },
        display: 'flex',
        alignItems: 'center',
        width: '100%',

        '.image-wrapper': {
          display: 'flex',

          '> img': {
            width: '18px',
            height: '18px',
            boxShadow: '0 4px 4px 0 rgba(0, 0, 0, 0.3), 0 8px 12px 6px rgba(0, 0, 0, 0.15)',
            margin: '-4.5px',
            borderRadius: '50%',

            '&:nth-of-type(1)': {
              zIndex: 10
            },
            '&:nth-of-type(2)': {
              zIndex: 9
            },
            '&:nth-of-type(3)': {
              zIndex: 8
            },
            '&:nth-of-type(4)': {
              zIndex: 7
            },
            '&:nth-of-type(5)': {
              zIndex: 6
            },
            '&:nth-of-type(6)': {
              zIndex: 5
            },
            '&:nth-of-type(7)': {
              zIndex: 4
            }
          }
        },

        '.textWrapper': {
          marginLeft: '13px'
        }
      },

      '&::-webkit-scrollbar': {
        display: 'none'
      }
    },
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP
    }
  },
  anchorOrigin: {
    vertical: 'bottom' as const,
    horizontal: 'left' as const
  },
  transformOrigin: {
    vertical: 'top' as const,
    horizontal: 'left' as const
  },
  variant: 'menu' as const,
  getContentAnchorEl: null
}

export const IntegrationsSelect = styled(Select)<{ isselected?: string; noborder?: string }>`
  min-width: 113px;
  height: 40px;
  background: transparent;
  border-radius: 100px;
  border: 1px solid #998d96;
  gap: 8px;

  ${({ noborder }) =>
    noborder === 'true' &&
    `
    border-radius: 0;
    border: 0;
    border-color: transparent;
    background: transparent;
  `};

  color: #fff;
  text-align: center;
  font-family: Quicksand, sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.1px;

  ${({ isselected }) =>
    isselected === 'true' &&
    `
    background: #6C586A;
  `};

  > * {
    border: none !important;
    outline: none !important;
  }

  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border: none !important;
  }

  svg {
    color: #d0c3cc;
  }
`

export const IntegrationsFormControl = styled(FormControl)<{ noBorder?: boolean; isSort?: boolean }>`
  background: transparent;
  outline: none;
  min-width: 120px;
  height: 40px;
  border-radius: 100px;
  display: flex;

  ${({ noBorder }) =>
    !noBorder &&
    `
    border: 1px solid #998d96;
  `};

  .label-text {
    font-family: Quicksand, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 142.857% */
    letter-spacing: 0.1px;
    color: white;
  }

  .MuiOutlinedInput-root {
    background: transparent;
    border: 0;
    color: ${({ isSort }) => (isSort ? '#fff' : '#d0c3cc')};
    text-align: center;
    font-family: Quicksand, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.1px;
  }

  &:hover {
    border-radius: 100px;
    background: rgba(234, 221, 255, 0.08);

    .MuiOutlinedInput-root {
      color: #ffa9fc;
    }
  }

  svg {
    top: calc(50% - 0.6em);
    right: 16px;

    path {
      fill: #ffa9fc;
    }
  }

  .MuiSelect-outlined {
    width: auto;
  }

  fieldset {
    padding: 0;
  }
`

export const Wrapper = styled(Box)<{ isSort?: boolean }>`
  display: flex;
  align-items: center;

  ${({ isSort }) =>
          !isSort &&
          `
   gap: 8px;
  `};
`
