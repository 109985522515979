import React, { Dispatch, FC, SetStateAction } from 'react'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers-pro'
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs'
import dayjs from 'dayjs'
import { Wrapper } from './CalendarDatePicker.styles'
import { DateRangeI } from '../../../../../../../../models'

interface Props {
  editDate: string | null
  dateRange: DateRangeI
  setForm: Dispatch<SetStateAction<any>>
  format?: string
}

const CalendarDatePicker: FC<Props> = ({ dateRange, editDate, setForm, format }) => {
  const today = new Date().getTime()
  const todayFormatted = editDate || today

  const handleDateChange = (date: any) => {
    setForm((prevState: any) => ({
      ...prevState,
      date: date
    }))
  }

  return (
    <Wrapper>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          label="Date"
          maxDate={dayjs(dateRange.maxDate)}
          minDate={dayjs(dateRange.minDate)}
          defaultValue={dayjs(todayFormatted)}
          format={format}
          onAccept={(date) => {
            handleDateChange(date)
          }}
          slotProps={{
            popper: {
              sx: {
                '.MuiPaper-root.MuiPickersPopper-paper': {
                  borderRadius: '16px',
                  background: '#573F56',

                  '.MuiPickersArrowSwitcher-root': {
                    '.MuiButtonBase-root': {
                      color: '#CAC4D0'
                    }
                  },

                  '.MuiPickersCalendarHeader-label': {
                    color: '#CAC4D0',
                    fontFamily: '"Quicksand",sans-serif',
                    fontSize: '14px',
                    fontStyle: 'normal',
                    fontWeight: 500,
                    lineHeight: '20px',
                    letterSpacing: '0.1px'
                  },

                  '.MuiDayCalendar-header': {
                    '.MuiTypography-root.MuiTypography-caption.MuiDayCalendar-weekDayLabel': {
                      color: '#E6E0E9',
                      textAlign: 'center',
                      fontFamily: '"Quicksand",sans-serif',
                      fontSize: '16px',
                      fontStyle: 'normal',
                      fontWeight: 500,
                      lineHeight: '24px',
                      letterSpacing: '0.1px'
                    }
                  },

                  '.MuiButtonBase-root.MuiPickersDay-root': {
                    color: '#E6E0E9',
                    textAlign: 'center',
                    fontFamily: '"Quicksand",sans-serif',
                    fontSize: '16px',
                    fontStyle: 'normal',
                    fontWeight: 500,
                    lineHeight: '24px',
                    letterSpacing: '0.1px',

                    '&.Mui-selected': {
                      background: '#FFA9FC',
                      color: '#66006E'
                    },
                    '&:not(.Mui-selected)': {
                      '&:hover': {
                        background: '#867083',
                        borderColor: '#867083'
                      }
                    },
                    '&.MuiPickersDay-today': {
                      background: 'transparent',
                      border: '1px solid #FFA9FC',
                      color: '#FFA9FC'
                    },
                    '&.Mui-disabled': {
                      color: '#998D96'
                    }
                  },

                  '.MuiButton-root': {
                    color: '#ffa9fc',
                    fontFamily: 'Quicksand',
                    fontSize: '14px',
                    lineHeight: '20px',
                    letterSpacing: '0.1px',
                    margin: '0'
                  }
                }
              }
            },
            actionBar: {
              actions: ['cancel', 'accept']
            }
          }}
        />
      </LocalizationProvider>
    </Wrapper>
  )
}

export default CalendarDatePicker
