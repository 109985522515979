import { FC, SVGProps } from 'react'

const IntegrationsEditIcon: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <rect width="40" height="40" rx="20" fill="#FFA9FC" />
    <path
      d="M22.8155 17.02L23.8505 17.94L13.658 27H12.623V26.08L22.8155 17.02ZM26.8655 11C26.5843 11 26.2918 11.1 26.078 11.29L24.0193 13.12L28.238 16.87L30.2968 15.04C30.7355 14.65 30.7355 14.02 30.2968 13.63L27.6643 11.29C27.4393 11.09 27.158 11 26.8655 11ZM22.8155 14.19L10.373 25.25V29H14.5918L27.0343 17.94L22.8155 14.19Z"
      fill="#590060"
    />
  </svg>
)

export default IntegrationsEditIcon
