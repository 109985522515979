import { styled } from '@mui/material/styles'
import { tooltipClasses, TooltipProps } from '@mui/material/Tooltip'
import { Tooltip } from '@mui/material'

export const CategoryInfoTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    padding: '16px',
    width: '316px',
    borderRadius: '8px',
    border: '1px solid #998D96',
    background: '#362E35',
    margin: 0,
    marginLeft: '12px',
    height: '100%'
  },
  '.tooltip-wrapper': {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    gap: '8px',

    '&.weights-wrapper': {
      gap: '12px',

      '.weights-wrapper-header': {
        display: 'flex',
        alignCenter: 'center',
        gap: '8px',

        '&.header-text-wrapper': {
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',

          '.tooltip-text.weights': {
            marginRight: '12px'
          }
        },

        '.weights-warning-icon': {
          width: '24px',
          height: '24px',
          minWidth: '24px',
          minHeight: '24px',

          path: {
            fill: '#FF8A80'
          }
        }
      }
    },

    '.info-icon': {
      width: '24px',
      height: '24px',
      minWidth: '24px',
      minHeight: '24px',

      path: {
        fill: '#D9BFD4'
      }
    }
  },
  '.tooltip-text': {
    color: '#FFF',
    fontFamily: "'Quicksand', sans-serif",
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: 'normal',

    '&.weights': {
      color: '#FF8A80',
      lineHeight: 'normal'
    },

    '&.weights-title': {
      marginTop: '4px'
    }
  }
})
