const DisabledCheckboxSvg = () => (
  <svg
    width="18"
    height="19"
    viewBox="0 0 18 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ opacity: '0.2' }}
  >
    <rect x="1" y="1.5" width="16" height="16" rx="1" stroke="#D0C3CC" stroke-width="2" />
  </svg>
)
export default DisabledCheckboxSvg
