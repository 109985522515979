import { FC } from 'react'
import { OutlinedInput, InputProps } from '@mui/material'

interface Props extends InputProps {
  name: string
  sx?: Record<string, unknown>
  placeholder?: string
  type?: string
}

const UncontrolledOutlinedInput: FC<Props> = ({ name, sx, placeholder, type, ...rest }) => (
  <OutlinedInput sx={sx} placeholder={placeholder} name={name} type={type} {...rest} />
)

export default UncontrolledOutlinedInput
