import * as Yup from 'yup'

export interface FormValues {
  name: string
  description: string
}

export const defaultValues = {
  name: '',
  description: '',
}

export const schema = Yup.object({
  name: Yup.string().required('Required'),
  description: Yup.string(),
  api_id: Yup.string().required('Required'),
  client_id: Yup.string().required('Required'),
  secret: Yup.string().required('Required')
})
