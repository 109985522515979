/* eslint-disable no-unused-vars */
export interface AllowedValues {
  id: string
  name: string
}

export interface CustomField {
  key: string
  name: string
}

export interface IssueTypeField {
  id: string
  name: string
  schemaType: string
  sourceField: string
  required: boolean
  allowedValues?: AllowedValues
  customFields?: CustomField[]
}

export interface IssueType {
  id: string
  name: string
  fields: IssueTypeField[]
}

export interface ProjectMeta {
  id: string
  name: string
  issueTypes: IssueType[]
}

export interface Project {
  id: string
  key: string
  name: string
  avatarUrl: string
}

export interface ProjectSchema {
  projects: ProjectMeta[]
  priorities: string[]
}

export interface Assignee {
  avatarUrl: string
  displayName: string
  emailAddress: string
  id: string
  type: string
}

export interface Credentials {
  auth_type: string
  base_url: string
  username: string
  password: string
}

export enum TicketRelationType {
  securityFinding = 'security_finding',
  insight = 'insight'
}

export interface Task {
  uuid: string
  organization_uuid: string
  integration_uuid: string
  logo: string
  related_to: string
  relation_type: string
  summary: string
  issue_type: string
  priority: string
  status: string
  external_key: string
  external_link: string
  created_by: string
  created_at: string
  updated_at: string | null
  assignee: string
  assignee_name: string | null
  due_date: string
  deleted: boolean
}

export interface TasksList {
  tasks: Task[]
  limit: number
  total: number
}

export interface CreateTaskPayload {
  issue: {
    project: string
    project_id: string
    status: string
    [key: string]: unknown
  }
  related_to: string
  relation_type: TicketRelationType
}
