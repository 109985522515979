import React, { FC, useEffect, useState } from 'react'
import { useAuth } from '@frontegg/react'
import { useNavigate } from 'react-router-dom'
import { Box, CircularProgress, Grid } from '@mui/material'

/* Utils */
import { hasPermission, Permission } from '../../../lib/utils'

/* Components */
import { Container, Wrapper } from './settings.styles'
import SettingsListItem from './SettingsListItem'

const defaultSettings = [
  {
    title: 'Account Management',
    description: 'Control account-wide configurations for everyone in your company.',
    link: '/settings/account-management',
    buttonText: 'Manage Account',
    permission: Permission.AccountManagementView
  },
  {
    title: 'User Management',
    description: 'Manage users, privileges, and roles.',
    link: '/settings/user-management',
    buttonText: 'Manage Users',
    permission: Permission.UsersProfileView
  },
  {
    title: 'Integrations',
    description: 'Configure & Edit your plugins for ticketing, endpoint detection and more.',
    link: '/settings/integrations',
    buttonText: 'Manage Preferences',
    permission: Permission.ConfigIntegrationsConfigView
  },
  {
    title: 'Audit Log',
    description: 'View system performance insights and usage patterns.',
    link: '/settings/audit-log',
    buttonText: 'View Audit Log',
    permission: Permission.AuditView
  }
]

const Settings: FC = () => {
  const navigate = useNavigate()
  const { user, isLoading } = useAuth()
  const [settings, setSettings] = useState(defaultSettings)

  useEffect(() => {
    if (user) {
      if (user.roles.length > 0) {
        const role = user.roles[0].name

        const yourProfilePermissions = ['viewer', 'manager', 'cpi_manager']
        if (yourProfilePermissions.includes(role.toLowerCase())) {
          const settings = defaultSettings.filter((n) => n.title !== 'User Management')
          setSettings(settings)
        }
        const userManagementPermissions = ['admin', 'owner', 'demo']
        if (userManagementPermissions.includes(role.toLowerCase())) {
          if (role === 'Owner' || role === 'Admin') {
            return setSettings(defaultSettings)
          }
          const settings = defaultSettings.filter((n) => n.title !== 'Account Management')
          return setSettings(settings)
        }
      }
    }
  }, [user])

  const handleClick = (permission: Permission, link: string) => {
    if (!hasPermission(permission, user)) {
      return
    }

    navigate(link)
  }

  return (
    <Container>
      <Wrapper>
        <Grid container spacing={2}>
          {isLoading ? (
            <Box display="flex" justifyContent="center">
              <CircularProgress />
            </Box>
          ) : (
            settings.map(({ title, description, link, buttonText, permission }) => (
              <Grid item xs={12} key={title}>
                <SettingsListItem
                  title={title}
                  description={description}
                  handleClick={() => handleClick(permission, link)}
                  buttonText={buttonText}
                  hasPermission={hasPermission(permission, user)}
                />
              </Grid>
            ))
          )}
        </Grid>
      </Wrapper>
    </Container>
  )
}

export default Settings
