import { FC } from 'react'
import { FormControl, MenuItem, Select, SelectProps, Typography } from '@mui/material'
import '../modal/variants/jira-integration/select.module.css'
import { FormWrapper } from './styles/controlledSelectBox.styles'
import { SelectFieldOption } from '../../../models'
import InputLabel from '@mui/material/InputLabel'

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
interface Props extends SelectProps {
  label?: string
  options: SelectFieldOption[]
  placeholder: string
  value: string
  name: string
  isframework?: boolean
  onChange: (value: string, name: string) => void
}

const ControlledSelectBox: FC<Props> = ({ label, isframework, options, placeholder, name, value, onChange }) => {
  const handleChange = (e: { target: { value: string; name: string } }) => {
    onChange(e.target.value, e.target.name)
  }

  return (
    <FormWrapper>
      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">{label}</InputLabel>
        <Select
          variant="outlined"
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          name={name}
          value={value}
          label={label}
          onChange={handleChange}
          placeholder={placeholder}
          MenuProps={{
            PaperProps: {
              sx: {
                borderRadius: '8px',
                bgcolor: '#4D444B',
                boxShadow: '0 4px 4px 0 rgba(0, 0, 0, 0.30), 0 8px 12px 6px rgba(0, 0, 0, 0.15)',

                '& .MuiMenuItem-root': {
                  padding: 2
                }
              }
            }
          }}
          renderValue={(value) => {
            let val = value
            if (isframework) {
              val = value === 'onyxia' ? 'Onyxia' : 'NIST'
            }

            return val
          }}
        >
          {options.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              <>
                {option.icon && <img src={option.icon} alt={option.label} width={24} height={24} />}
                <Typography className="texts" sx={option.icon ? { marginLeft: '17px' } : null}></Typography>
                {option.label}
              </>
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </FormWrapper>
  )
}

export default ControlledSelectBox
