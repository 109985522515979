import React from 'react'
import { Wrapper } from './EmptyState.styles'
import { Box, Typography } from '@mui/material'
import ZeroState from '../../../components/svg/ZeroState'

const EmptyState = () => {
  return (
    <Wrapper>
      <Box className="content">
        <ZeroState />
        <Typography className="title">No Results Found</Typography>
        <Typography className="description">Try changing the filter values, or select different sources</Typography>
      </Box>
    </Wrapper>
  )
}

export default EmptyState
