import { FC } from 'react'
import { Typography } from '@mui/material'

/* Utils */
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks'
import { closeModal, modalPropsSelector } from '../../../../../store/modals/modalSlice'

/* Components */
import { Actions, Container, Content } from './CancelChanges.styles'
import { ThemeButton } from '../../../buttons'
import { RightIconButton } from '../../../svg'

const CancelChanges: FC = () => {
  const dispatch = useAppDispatch()
  const modalProps = useAppSelector(modalPropsSelector)

  const handleClose = () => {
    if (modalProps?.props?.cancelNavigation) {
      modalProps?.props?.cancelNavigation()
    }
    dispatch(closeModal())
  }

  const cancelChanges = () => {
    if (modalProps?.props?.confirmNavigation) {
      modalProps?.props?.confirmNavigation()
    }

    setTimeout(() => {
      handleClose()
    }, 100)

    if (modalProps?.props?.callback) {
      modalProps.props.callback()
    }
  }

  return (
    <Container>
      <Content>
        <Typography className="title">Are You Sure?</Typography>
        <Typography className="text">Please confirm that you want to abandon your changes</Typography>

        <Actions>
          <ThemeButton themevariant="tertiary" onClick={handleClose}>
            Cancel
          </ThemeButton>
          <ThemeButton themevariant="primary" type="button" onClick={() => cancelChanges()}>
            <>
              <RightIconButton />
              Continue
            </>
          </ThemeButton>
        </Actions>
      </Content>
    </Container>
  )
}

export default CancelChanges
