import * as d3 from 'd3'
import { formatPerformanceUnit } from '../../../../utils'
import { CPISeverity, VisibleLabel } from '../../../../../../../models'
import { styledSlaGroup } from '../chart-components-shared'

interface Props {
  svg: d3.Selection<SVGSVGElement | null, unknown, null, undefined>
  selectedSeverity: CPISeverity
  width: number
  height: number
  healthBorder: number
  sla: number
  healthUnit: string
  visibleLabels: VisibleLabel[]
  sectionsDisplayName: string
  sectionsDisplayNameSingular: string
  isNoSeveritiesCpi: boolean
}

/*
 * SLA Tooltip Elements
 * */
export const appendSLAElements = ({
  svg,
  sectionsDisplayName,
  sectionsDisplayNameSingular,
  isNoSeveritiesCpi,
  selectedSeverity,
  width,
  height,
  healthBorder,
  sla,
  healthUnit,
  visibleLabels
}: Props) => {
  const existingSlaGroup = svg.select('g.sla')
  if (!existingSlaGroup.empty()) existingSlaGroup.remove()

  const slaGroup = svg.append('g').attr('class', 'sla')
  const text = isNoSeveritiesCpi
    ? `SLA: ${sla}${formatPerformanceUnit(healthUnit, true)}`
    : `${selectedSeverity.charAt(0).toUpperCase()}${selectedSeverity.slice(1)} ${
        selectedSeverity === 'all' ? sectionsDisplayName : sectionsDisplayNameSingular
      } SLA: ${sla}${formatPerformanceUnit(healthUnit, true)}`

  styledSlaGroup({
    svg,
    slaGroup,
    text,
    width,
    height,
    healthBorder,
    visibleLabels
  })
}
