import { FC, useState, useEffect } from 'react'
import { SelectChangeEvent, Typography } from '@mui/material'
import { MonthRangePicker } from '@khan_skadi/month-range-picker'
import dayjs from 'dayjs'

/* Utils */
import { CPITimePeriod } from '../../../models'
import { monthDiff } from '../../pages/library/utils'

/* Components */
import { ChipsContainer, ViewData, viewDataStyles } from '../../pages/library/components/library-components.styles'
import TrendsChartCustomSelectField from '../../pages/library/library-details/sections/trends-tab/components/TrendsChartCustomSelectField'
import ThemeButton from '../buttons/primary/ThemeButton'

const getDaysIntMonth = (y: number, m: number) => new Date(y, m, 0).getDate()

type RangeParams = {
  startMonth: number
  startYear: number
  endMonth: number
  endYear: number
}

interface Props {
  timePeriodValues: Array<CPITimePeriod | string>
  selectedTimePeriod: CPITimePeriod | string
  handleChangeTimePeriod: (event: SelectChangeEvent | null, customPayload?: any) => void
  checkTimePeriodDisabled: () => boolean
  customTimePeriodValue: string
  hideSelected?: boolean
  disabled?: boolean
}

const CustomTimePicker: FC<Props> = ({
  timePeriodValues,
  selectedTimePeriod,
  handleChangeTimePeriod,
  checkTimePeriodDisabled,
  customTimePeriodValue,
  hideSelected,
  disabled
}) => {
  const [start, setStart] = useState('')
  const [end, setEnd] = useState('')
  const [pickerOpen, setPickerOpen] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null)
  const [viewDataDisabled, setViewDataDisabled] = useState(true)

  useEffect(() => {
    if (!anchorEl) {
      closeCustomPicker()
    }
  }, [anchorEl])

  const onRangeSelect = (params: RangeParams) => {
    const { startMonth, endMonth, startYear, endYear } = params
    const stringMonthStart = startMonth.toString().padStart(2, '0')
    const stringMonthEnd = endMonth.toString().padStart(2, '0')
    if (startMonth && startYear) {
      setStart(`${startYear}-${stringMonthStart}-01`)
    }
    if (endMonth && endYear) {
      const days = getDaysIntMonth(endYear, endMonth)
      setEnd(`${endYear}-${stringMonthEnd}-${days}`)
    }
  }

  const handleCustomClick = () => {
    setPickerOpen(!pickerOpen)
  }

  const closeCustomPicker = () => {
    setPickerOpen(false)
  }

  const handleViewData = () => {
    const diff = monthDiff(new Date(start), new Date(end))
    const today = new Date()
    const endDate = new Date(end)
    const res = endDate.getTime() > today.getTime() ? today : endDate
    const resFormatted = dayjs(res).format('YYYY-MM-DD')
    handleChangeTimePeriod(null, {
      dateFrom: start,
      dateTo: resFormatted,
      stripes: diff
    })
    setAnchorEl(null)
    closeCustomPicker()
  }

  const onChangeCallback = (month: any, startMonth: any, endMonth: any) => {
    if (!startMonth && !endMonth && month) {
      setViewDataDisabled(true)
    } else if (!endMonth) {
      setViewDataDisabled(false)
    } else if (startMonth && endMonth && month) {
      setViewDataDisabled(true)
    }
  }

  return (
    <ChipsContainer className="chips-container time-period">
      <Typography className="chips-title">Time Period:</Typography>
      <TrendsChartCustomSelectField
        options={timePeriodValues}
        label=""
        placeholder="Filter by Time Period"
        labeltext="Filter by: "
        value={selectedTimePeriod}
        handleChange={handleChangeTimePeriod}
        disabled={checkTimePeriodDisabled() || Boolean(disabled)}
        minWidth="75px !important"
        handleCustomClick={handleCustomClick}
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
        closeCustomPicker={closeCustomPicker}
        customTimePeriodValue={customTimePeriodValue}
        hideSelected={hideSelected}
      />
      <MonthRangePicker
        onRangeSelect={onRangeSelect}
        calendarClassName={`month-picker-container ${anchorEl && pickerOpen && 'isopen'}`}
        headerClassName="month-picker-header"
        monthClassName="month-picker-month"
        columns={2}
        initialYear={2023}
        onChangeCallback={onChangeCallback}
      />
      <ViewData className={anchorEl && pickerOpen ? 'isopen' : ''}>
        <ThemeButton themevariant="secondary" sx={viewDataStyles} onClick={handleViewData} disabled={viewDataDisabled}>
          View Data
        </ThemeButton>
      </ViewData>
    </ChipsContainer>
  )
}

export default CustomTimePicker
