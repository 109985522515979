import { FC, useState } from 'react'
import { Checkbox, ListItemText, MenuItem, OutlinedInput, SelectProps } from '@mui/material'
import { SelectDropdownIcon } from '../../../../../components/svg'
import { BenchmarkingFormControl, BenchmarkingSelect, CategoryMenuProps } from './benchmarkingSelectField.styles'

interface Props extends SelectProps {
  options: string[]
  value: any
  handleChange: any
  minWidth?: string
  compareText?: string
}

const BenchmarkingSelectField: FC<Props> = ({
  options,
  compareText,
  handleChange,
  placeholder,
  minWidth,
  value,
  ...rest
}) => {
  const [anchorEl, setAnchorEl] = useState(null)

  const handleClick = (event: any) => {
    if (rest.disabled) return
    setAnchorEl(event.currentTarget)
  }

  return (
    <BenchmarkingFormControl
      sx={{
        minWidth
      }}
    >
      <BenchmarkingSelect
        isselected={
          placeholder !== 'Sort' && (value.length > 1 || (value.length === 1 && value[0] !== '')) ? 'true' : 'false'
        }
        labelId="library-select-label"
        id="library-select"
        displayEmpty
        MenuProps={{
          ...CategoryMenuProps,
          anchorEl,
          open: Boolean(anchorEl),
          onClose: () => setAnchorEl(null)
        }}
        IconComponent={(props) => <SelectDropdownIcon {...props} />}
        renderValue={(selected) => {
          if (Array.isArray(selected)) {
            const pruned = selected.filter((s: string) => s !== '')
            if (pruned.length === 0) {
              return placeholder
            }
            if (pruned.length === 1) {
              return pruned[0]
            }

            return `${pruned[0]} +${pruned.length - 1}`
          }

          return `${compareText ?? 'Compared to'}: ${selected}`
        }}
        inputProps={{ 'aria-label': 'Without label' }}
        {...rest}
        value={value}
        onChange={handleChange}
        input={<OutlinedInput onMouseDown={handleClick} />}
        sx={{
          minWidth
        }}
      >
        {options.sort().map((option) => (
          <MenuItem
            key={option}
            value={option}
            onClick={() => {
              if (!rest.multiple) {
                setAnchorEl(null)
              }
            }}
          >
            {rest.multiple && <Checkbox checked={value.indexOf(option) > -1} />}
            <ListItemText primary={option} />
          </MenuItem>
        ))}
      </BenchmarkingSelect>
    </BenchmarkingFormControl>
  )
}

export default BenchmarkingSelectField
