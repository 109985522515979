import { FC, useEffect, useMemo, useRef } from 'react'
import { Box } from '@mui/material'
import * as d3 from 'd3'
import dayjs from 'dayjs'

/* Utils */
import {
  CPIFrequency,
  CPITimePeriod,
  HistoryPerformanceData,
  HistoryPerformanceDataExtended
} from '../../../../../../../models'
import {
  evenBarColor,
  filterHistoricalData,
  findHistoricalUniqueMonths,
  formatHistoricalXLabel,
  getHistoricalPathAndPositions,
  groupHistoricalDataByWeeks,
  oddBarColor,
  pinkStroke
} from '../performanceScoreUtils'
import { fillBlankData, findNearestXIndex, formatTooltipDate } from '../../../../utils'

/* Components */
import { HistoricalPerformanceChartContainer } from './historyPerformanceChart.styles'

interface Props {
  data: HistoryPerformanceData[]
  selectedTimePeriod: CPITimePeriod
  numberOfStripes: 7 | 4 | 3 | 6 | 12
}

const HistoryPerformanceChart: FC<Props> = ({ data, selectedTimePeriod, numberOfStripes }) => {
  const containerRef = useRef<HTMLDivElement | null>(null)
  const svgRef = useRef<SVGSVGElement | null>(null)
  const tooltipRef = useRef<HTMLDivElement | null>(null)
  const margin = { top: 20, right: 0, bottom: 40, left: 0 }
  const width = 765
  const height = 508 - margin.top - margin.bottom
  const chartWidthStart = 35
  const chartHeightStart = 32

  const isOverPerform = useMemo(() => {
    const findItem = data.find((item) => item.yValue > 100)
    return !!findItem
  }, [data])

  const buildChart = (data: HistoryPerformanceDataExtended[], timeRange: number) => {
    const tooltip = d3.select('.historical-performance-tooltip')
    const dataStartsAt = dayjs(data[0].xValue || '').format('YYYY-MM-DD')
    const filteredData = filterHistoricalData(timeRange, data)
    const svg = d3.select(svgRef.current)

    /*
     * Area Gradients definitions
     * */
    const appendDefs = () => {
      const defs = svg.append('defs')
      const pinkGradient = defs
        .append('linearGradient')
        .attr('id', 'pinkGradient')
        .attr('x1', 0)
        .attr('x2', 0)
        .attr('y1', 0)
        .attr('y2', 1)
      pinkGradient.append('stop').attr('offset', '7%').attr('stop-color', '#FFA9FC').attr('stop-opacity', 1)
      pinkGradient
        .append('stop')
        .attr('offset', '93%')
        .attr('stop-color', 'rgba(255, 169, 252, 1)')
        .attr('stop-opacity', 0.4)
      const blueGradient = defs
        .append('linearGradient')
        .attr('id', 'blueGradient')
        .attr('x1', 0)
        .attr('x2', 0)
        .attr('y1', 0)
        .attr('y2', 1)
      blueGradient
        .append('stop')
        .attr('offset', '7%')
        .attr('stop-color', 'hsla(38, 94%, 69%, 1)')
        .attr('stop-opacity', '1')
      blueGradient
        .append('stop')
        .attr('offset', '93%')
        .attr('stop-color', 'hsla(38, 94%, 69%, 0.2)')
        .attr('stop-opacity', '1')
    }
    appendDefs()

    /*
     *  Populate each stripe with data points for that month
     * */
    const yAxisDomain = isOverPerform ? [0, 150] : [0, 100]
    const uniqueMonths = findHistoricalUniqueMonths(filteredData, selectedTimePeriod, data)
    const stripeWidth = width / uniqueMonths.length

    const [pathStr, positions, pathStartPoint] = getHistoricalPathAndPositions({
      uniqueMonths,
      stripeWidth,
      height,
      filteredData,
      selectedTimePeriod,
      yAxisDomain,
      chartHeightStart,
      chartWidthStart
    })

    const xScaleGroup = d3.scaleBand().domain(uniqueMonths).range([chartWidthStart, width]).padding(0)
    const yScale = d3
      .scaleLinear()
      .domain(yAxisDomain)
      .range([height + chartHeightStart, 0])
      .nice()

    /*
     * Cartesian Grid Bars
     * */
    const existingBars = svg.selectAll('.bar')
    if (!existingBars.empty()) existingBars.remove()
    svg
      .selectAll('.bar')
      .data(uniqueMonths)
      .enter()
      .append('rect')
      .attr('fill', (d, i) => (i % 2 === 0 ? oddBarColor : evenBarColor))
      .attr('class', 'bar')
      .attr('x', (d) => xScaleGroup(d) || 0)
      .attr('y', 0)
      .attr('width', xScaleGroup.bandwidth())
      .attr('height', height + margin.top + margin.bottom - chartHeightStart + 4)
      .on('mouseout', () => {
        tooltip.style('display', 'none')
        const circleInner = svg.select('.hover-circle-inner')
        circleInner.attr('display', 'none')
        const circleOuter = svg.select('.hover-circle-outer')
        circleOuter.attr('display', 'none')
      })

    /*
     * Mouse Move for data point tooltip
     * */
    svg.on('mousemove', (event: any) => {
      if (!data.length) return
      const [mouseX, mouseY] = d3.pointer(event)
      const dataPoint = findNearestXIndex(positions, mouseX)
      if (mouseX > width + chartWidthStart || mouseY > height + chartHeightStart) return null
      const matchingDataPoint = filteredData[dataPoint?.index || 0]
      if (dataPoint) mouseMove(event, dataPoint, matchingDataPoint)
    })

    /*
     * Hover Data Point Circle
     * */
    const appendHoverCircles = () => {
      const hoverCircleInner = svg.selectAll('.hover-circle-inner')
      if (!hoverCircleInner.empty()) hoverCircleInner.remove()
      svg
        .append('circle')
        .attr('class', 'hover-circle-inner')
        .attr('cx', 100)
        .attr('cy', 200)
        .attr('r', 7)
        .attr('fill', '#fff')
        .attr('stroke', '#000')
        .attr('stroke-width', 2)
        .attr('stroke-opacity', 1)
        .attr('display', 'none')
      const hoverCircleOuter = svg.selectAll('.hover-circle-outer')
      if (!hoverCircleOuter.empty()) hoverCircleOuter.remove()
      svg
        .append('circle')
        .attr('class', 'hover-circle-outer')
        .attr('cx', 100)
        .attr('cy', 200)
        .attr('r', 7)
        .attr('fill', 'none')
        .attr('stroke', '#fff')
        .attr('stroke-width', 15)
        .attr('stroke-opacity', 0.5)
        .attr('display', 'none')
    }
    appendHoverCircles()

    /*
     * Hover Tooltip
     * */
    const circle = d3.select('.hover-circle-inner')
    const circle1 = d3.select('.hover-circle-outer')
    const mouseMove = (event: any, dataPoint: any, d: any) => {
      tooltip.style('display', 'block')
      circle.attr('cx', dataPoint.x)
      circle.attr('cy', dataPoint.y)
      circle.attr('display', 'block')
      circle1.attr('cx', dataPoint.x)
      circle1.attr('cy', dataPoint.y)
      circle1.attr('display', 'block')
      cursorLine.attr('x1', dataPoint.x).attr('x2', dataPoint.x).style('display', 'block')

      const formattedDate = formatTooltipDate(d.start, d.end, CPIFrequency.Weekly, selectedTimePeriod, dataStartsAt)
      const unitInfoText = d.dummy ? `There is no data for these dates` : `Performance score from ${formattedDate}`
      const value = d.dummy ? '' : `${d.yValue}%`

      tooltip
        .html(
          `
          <p class='tooltip-performance'>${value}</p>
          <p class='tooltip-units'>${unitInfoText}</p>
          `
        )
        .style('top', dataPoint.y - 25 + 'px')
        .style('left', dataPoint.x + 50 + 'px')
    }

    /*
     * Horizontal line middle
     * */
    if (isOverPerform) {
      const existingHorizontalLine = svg.selectAll('.horizontal-line-middle')
      if (!existingHorizontalLine.empty()) existingHorizontalLine.remove()
      svg
        .append('line')
        .attr('class', 'horizontal-line-middle')
        .attr('x1', chartWidthStart)
        .attr('x2', width - margin.left - margin.right)
        .attr('y1', (height + margin.top - chartHeightStart * 3.5) / 2)
        .attr('y2', (height + margin.top - chartHeightStart * 3.5) / 2)
        .attr('stroke', '#F09543')
        .attr('stroke-width', 2)
    }

    /*
     * Cursor Line
     * */
    const existingline = svg.selectAll('.cursor-line')
    if (!existingline.empty()) existingline.remove()
    const cursorLine = svg
      .append('line')
      .attr('class', 'cursor-line')
      .attr('x1', 0)
      .attr('x2', 0)
      .attr('y1', 0)
      .attr('y2', height + chartHeightStart)
      .attr('stroke', '#fff')
      .attr('stroke-width', 1)
      .style('display', 'none')
    svg.on('mouseout', () => {
      cursorLine.style('display', 'none')
      tooltip.style('display', 'none')
      const circle1 = svg.select('.hover-circle-outer')
      const circle2 = svg.select('.hover-circle-inner')
      circle1.attr('display', 'none')
      circle2.attr('display', 'none')
    })

    const filledArea = svg.select('.filled-area')
    const filledAreaStroke = svg.select('.filled-area-stroke')

    /*
     *  Filled Area
     * */
    const appendFilledArea = () => {
      const existingFilledArea = svg.selectAll('.filled-area')
      if (!existingFilledArea.empty()) existingFilledArea.remove()
      svg
        .append('path')
        .datum(filteredData)
        .attr(
          'd',
          `${pathStr}` +
            `L${positions[positions.length - 1].x} ${height + chartHeightStart} L ${pathStartPoint.x} ${
              height + chartHeightStart
            } Z`
        )
        .attr('class', 'filled-area')
        .attr('fill', `url(#pinkGradient)`)
        .attr('stroke', 'none')
        .on('mouseout', () => {
          tooltip.style('display', 'none')
          const circleInner = svg.select('.hover-circle-inner')
          circleInner.attr('display', 'none')
          const circleOuter = svg.select('.hover-circle-outer')
          circleOuter.attr('display', 'none')
        })
    }
    appendFilledArea()

    /*
     * Filled Area Stroke
     * */
    const appendFilledAreaStroke = () => {
      const existingFilledAreaStroke = svg.selectAll('.filled-area-stroke')
      if (!existingFilledAreaStroke.empty()) existingFilledAreaStroke.remove()
      svg
        .append('path')
        .attr('fill', 'none')
        .attr('stroke', `${pinkStroke}`)
        .attr('d', `${pathStr}`)
        .attr('class', 'filled-area-stroke')
        .attr('stroke-width', 3)
        .attr('stroke-linejoin', 'bevel')
    }
    appendFilledAreaStroke()

    if (!filledArea.empty()) filledArea.remove()
    if (!filledAreaStroke.empty()) filledAreaStroke.remove()

    /*
     * X-Axis
     * */
    const xAxis = d3
      .axisBottom(xScaleGroup)
      .tickFormat((date) => formatHistoricalXLabel(date, selectedTimePeriod, filteredData))
    const existingXAxis = svg.selectAll('.x-axis')
    if (!existingXAxis.empty()) existingXAxis.remove()
    const xAxisGroup = svg
      .append('g')
      .attr('class', 'x-axis')
      .attr('color', '#fff')
      .attr('transform', `translate(0, ${height + chartHeightStart})`)
      .call(xAxis)

    let fontSize = 12
    if (selectedTimePeriod === CPITimePeriod.Month && uniqueMonths.length >= 8) {
      fontSize = 11
    }

    xAxisGroup
      .selectAll('text')
      .attr('color', '#fff')
      .attr('font-size', `${fontSize}px`)
      .attr('font-weight', '400')
      .attr('letter-spacing', '0.4px')
      .attr('line-height', '16px')
      .attr('font-family', "'Quicksand', sans-serif")
    xAxisGroup.selectAll('path').attr('display', 'none')
    const xAxisTick = svg.selectAll('.tick')
    xAxisTick.selectAll('line').attr('display', 'none')

    /*
     *  Y-Axis
     * */
    const yAxis = isOverPerform
      ? d3
          .axisRight(yScale)
          .ticks(8)
          .tickFormat((d, i) => {
            return i === 0 ? '0%' : i === 2 ? '50%' : i === 5 ? '100%' : i === 8 ? '150%' : ''
          })
      : d3
          .axisRight(yScale)
          .ticks(5)
          .tickFormat((d, i) => {
            return i === 0 ? '0%' : i === 2 ? '50%' : i === 5 ? '100%' : ''
          })
    const existingYAxis = svg.selectAll('.y-axis')
    if (!existingYAxis.empty()) existingYAxis.remove()
    const yAxisGroup = svg
      .append('g')
      .attr('class', `${isOverPerform ? 'y-axis-perform' : 'y-axis'}`)
      .attr('color', 'none')
      .attr('height', '480px')
      .attr('transform', 'translate(-10, 0)')
      .call(yAxis)
    yAxisGroup
      .selectAll('text')
      .attr('dy', '30px')
      .attr('color', '#fff')
      .attr('font-size', '12px')
      .attr('font-weight', '400')
      .attr('letter-spacing', '0.4px')
      .attr('line-height', '16px')
      .attr('font-family', "'Quicksand', sans-serif")
    yAxisGroup.selectAll('path').attr('display', 'none')
    yAxisGroup.selectAll('line').attr('display', 'none')

    svg.selectAll('.filled-area').raise()
    svg.selectAll('.filled-area-stroke').raise()
    svg.selectAll('.hover-circle-outer').raise()
    svg.selectAll('.hover-circle-inner').raise()
    svg.selectAll('.cursor-line').raise()
  }

  useEffect(() => {
    if (!containerRef.current) return

    let groupedData: HistoryPerformanceDataExtended[] = groupHistoricalDataByWeeks(data)
    const dataMaxValue = Math.max(...data.map((e: any) => (isNaN(e.yValue) ? 0 : e.yValue)))
    groupedData = groupedData.map((e) => {
      const num = isNaN(e.yValue) ? 0 : e.yValue

      return { ...e, yValue: dataMaxValue === 0 ? 0 : num }
    })
    groupedData = fillBlankData(groupedData, selectedTimePeriod) as HistoryPerformanceDataExtended[]

    buildChart(groupedData, numberOfStripes)
  }, [data])

  return (
    <HistoricalPerformanceChartContainer
      ref={containerRef}
      width={width + margin.left + margin.right + 2}
      height={height + margin.top + margin.bottom + 2}
    >
      <Box className="bordered-wrapper" />
      <div ref={tooltipRef} className="historical-performance-tooltip" />
      <svg
        ref={svgRef}
        id="historical-performance-svg"
        className="historical-performance-svg"
        width={width + margin.left + margin.right}
        height={height + margin.top + margin.bottom}
      />
    </HistoricalPerformanceChartContainer>
  )
}

export default HistoryPerformanceChart
