import { FC } from 'react'
import { Typography } from '@mui/material'

/* Utils */
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks'
import { closeModal, modalPropsSelector } from '../../../../../store/modals/modalSlice'

/* Components */
import { Actions, Container, Content } from './CancelChanges.styles'
import { ThemeButton } from '../../../buttons'
import { RevertBackIcon } from '../../../svg'

const RevertWeights: FC = () => {
  const dispatch = useAppDispatch()
  const modalProps = useAppSelector(modalPropsSelector)

  const handleClose = () => {
    dispatch(closeModal())
  }

  const revertChanges = () => {
    if (modalProps?.props?.callback) {
      handleClose()
      modalProps.props.callback()
    }
  }

  return (
    <Container>
      <Content>
        <Typography className="title">Are You Sure?</Typography>
        <Typography className="text">
          Please confirm that you are reverting all Category and Cyber Performance Indicator weights back to the default
          values.
        </Typography>

        <Actions>
          <ThemeButton themevariant="tertiary" onClick={handleClose}>
            Cancel
          </ThemeButton>
          <ThemeButton themevariant="primary" type="button" onClick={() => revertChanges()}>
            <>
              <RevertBackIcon />
              Revert to Default Values
            </>
          </ThemeButton>
        </Actions>
      </Content>
    </Container>
  )
}

export default RevertWeights
