import { useMemo, Dispatch, SetStateAction, FC, SyntheticEvent, useState, useEffect, useRef } from 'react'
import { Box, IconButton, Skeleton, Typography } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
// import { useLocation } from 'react-router-dom'

/* Utils */
import {
  ConfiguredDataSource,
  CPILibraryItem,
  FrameworkCategoryResponseItem,
  FrameworkCategoryWeightItem,
  FrameworkType,
  IFramework,
  LibraryFilters
} from '../../../../models'
import { equalWeightsGen } from '../../../../lib/utils'

/* Components */
import { Drawer, Wrapper, Content } from './styles/addCpiDrawer.styles'
import DrawerTabs from './components/DrawerTabs'
import DrawerTabPanel from './components/DrawerTabPanel'
import DrawerCpiItem from './components/DrawerCpiItem'
import SearchCategoryCpis from './components/SearchCategoryCpis'
import FilterCategoryCpis from './components/FilterCategoryCpis'
import EmptyList from './drawer-components/EmptyList'
import Footer from './drawer-components/Footer'
import { CategorySkeleton, CategorySkeletonWrapper } from './styles/categories.styles'
// import FilterSelectedCpis from './components/FilterSelectedCpis'

const defaultFilters = {
  category: [],
  source: [],
  status: [],
  sortBy: 'Newest to Oldest',
  frameworkCategory: ''
} as LibraryFilters

export interface CpiListItem extends CPILibraryItem {
  selected: boolean
  visible: boolean
  isActive: boolean
}

interface Props {
  drawerOpen: boolean
  setDrawerOpen: Dispatch<SetStateAction<boolean>>
  selectedCategoryItem: FrameworkCategoryWeightItem | undefined
  framework: FrameworkType
  customCategories: FrameworkCategoryResponseItem[]
  customFramework: IFramework | null
  updateCategoryCpis: (payload: any) => void
  configuredIntegrations: ConfiguredDataSource[]
  initialLoad: boolean
  searchValue: string
  data: CpiListItem[]
  dataPristine: CpiListItem[]
  setData: Dispatch<SetStateAction<CpiListItem[]>>
  setDataPristine: Dispatch<SetStateAction<CpiListItem[]>>
  searchData: any
  setInitialLoad: Dispatch<SetStateAction<boolean>>
  setSearchValue: Dispatch<SetStateAction<string>>
  setFilters: Dispatch<SetStateAction<LibraryFilters>>
  isFetching: boolean
  categoriesFormCpis: any[]
  setCategoriesForm: any
  setSelectedCategoryItem: Dispatch<SetStateAction<FrameworkCategoryWeightItem | undefined>>
  setDiscardDisabled: Dispatch<SetStateAction<boolean>>
  setSelectedCpis: Dispatch<SetStateAction<CpiListItem[]>>
  selectedCpis: CpiListItem[]
}

const AddCpiDrawer: FC<Props> = ({
  selectedCategoryItem,
  drawerOpen,
  setDrawerOpen,
  customCategories,
  framework,
  customFramework,
  updateCategoryCpis,
  configuredIntegrations,
  initialLoad,
  searchValue,
  data,
  dataPristine,
  setData,
  setDataPristine,
  searchData,
  setInitialLoad,
  setSearchValue,
  setFilters,
  isFetching,
  categoriesFormCpis,
  setCategoriesForm,
  setSelectedCategoryItem,
  setDiscardDisabled,
  selectedCpis,
  setSelectedCpis
}) => {
  // const location = useLocation()
  // const thisPath = location.pathname.replace('/framework-configuration/', '')
  const [tabValue, setTabValue] = useState(0)
  const [selectedSearchValue, setSelectedSearchValue] = useState('')
  const [filtersOpen, setFiltersOpen] = useState(false)
  const filterButtonRef = useRef<HTMLButtonElement>(null)
  const [cpiActivationStatus, setCpiActivationStatus] = useState<'all' | 'active' | 'inactive'>('all')
  const [cpiAssignment, setCpiAssignment] = useState<'all' | 'assigned' | 'unassigned'>('all')
  const [actionsDisabled, setActionsDisabled] = useState(true)

  useEffect(() => {
    if (searchData) {
      if (!initialLoad) {
        const searchedCpis = searchData.data.data.cpis || []
        const result = searchedCpis.map((cpi: any) => {
          const payload = {
            ...cpi,
            visible: true,
            selected: false,
            isActive: !!cpi.configuration
          }

          if (!selectedCategoryItem) return payload

          const foundInSelectedItem = selectedCategoryItem.cpis.find(
            (selectedCategoryCpi) => selectedCategoryCpi.name === cpi.name
          )

          if (foundInSelectedItem) {
            payload.selected = true
          }

          return payload
        })

        setSelectedCpis(result.filter((n: any) => n.selected))
        setData(result)
        setDataPristine(result)
        setInitialLoad(true)
      } else {
        const searchedCpis = searchData.data.data.cpis || []

        const result = searchedCpis.map((cpi: any) => {
          const foundInCategoriesForm = categoriesFormCpis.find((n) => n.name === cpi.name)
          const payload = {
            ...cpi,
            category:
              foundInCategoriesForm && foundInCategoriesForm.category
                ? {
                    ...foundInCategoriesForm.category,
                    title: foundInCategoriesForm.category.name,
                    shadowed: foundInCategoriesForm.category.shadowed,
                    framework_id: cpi.category?.framework_id || customFramework?.id || ''
                  }
                : undefined,
            visible: true,
            selected: false,
            isActive: !!cpi.configuration
          }

          if (!selectedCategoryItem) return payload

          const foundInSelectedItem = selectedCategoryItem.cpis.find(
            (selectedCategoryCpi) => selectedCategoryCpi.name === cpi.name
          )

          if (foundInSelectedItem) {
            payload.selected = true
          }

          return payload
        })

        setSelectedCpis(result.filter((n: any) => n.selected))
        setData(result)
        const oldPristine = [...dataPristine]
        const newPristine = oldPristine.map((n: any) => {
          const found = result.find((c: any) => c.name === n.name)
          if (found) {
            return found
          } else return n
        })
        setDataPristine(newPristine)
      }
    }
  }, [searchData, selectedCategoryItem, initialLoad])

  const handleTabChange = (event: SyntheticEvent, newValue: number) => {
    if (newValue === 1) {
      setSelectedSearchValue(searchValue)
    } else {
      setSearchValue(selectedSearchValue)
    }
    setTimeout(() => {
      setTabValue(newValue)
    }, 0)
  }

  const handleSelectedInputChange = (e: any) => {
    const res = selectedCpis.map((cpi: any) => {
      const foundCpi = selectedCpis.find((n) => n.display_name === cpi.display_name)

      if (foundCpi) {
        if (
          foundCpi.name.includes(e.target.value) ||
          foundCpi.display_name.includes(e.target.value) ||
          foundCpi.title.includes(e.target.value)
        ) {
          return {
            ...foundCpi,
            visible: true
          }
        }
      }

      return {
        ...cpi,
        visible: false
      }
    })

    setSelectedCpis(res)
  }

  const handleCloseFilters = () => {
    setFiltersOpen(false)
  }

  const handleClickFilter = () => {
    setFiltersOpen((pre) => !pre)
  }

  const handleOk = () => {
    if (!selectedCategoryItem) return

    const selected = dataPristine.filter((n) => n.selected)
    const active = selected.filter((n) => n.isActive).reverse()

    let tempWeights: any[] = []
    const weightsGen = equalWeightsGen(active.length)
    const weights = active.map((c: any) => {
      return {
        ...c,
        weight: weightsGen.next().value as number
      }
    })
    tempWeights = weights.reverse()

    const payload = {
      cpis: selected.map((n) => {
        const temp = tempWeights.find((c) => c.display_name === n.display_name)

        return {
          ...n,
          name: n.name,
          weight: temp?.weight || 0
        }
      })
    }

    setSearchValue('')
    setData(dataPristine)

    setFilters(defaultFilters)
    setFiltersOpen(false)
    setCpiActivationStatus('all')
    setCpiAssignment('all')

    setTabValue(0)
    updateCategoryCpis(payload)
    setDiscardDisabled(false)
    setDrawerOpen(false)
    setActionsDisabled(true)
  }

  const handleCloseDrawer = () => {
    const unselected = dataPristine.map((n) => ({ ...n, selected: false, visible: true }))
    // const selectedCpis: any = selectedCategoryItem?.cpis.map((n) => ({ ...n, selected: true, visible: true }))
    const selectedCpiNames = selectedCategoryItem?.cpis.map((c) => c.name) || []
    const selected = dataPristine
      .filter((n) => selectedCpiNames.includes(n.name))
      .map((c) => ({ ...c, selected: true }))

    setDrawerOpen(false)

    setTimeout(() => {
      /* Reset search results */
      setSearchValue('')
      setData(dataPristine.map((n) => ({ ...n, selected: selectedCpiNames.includes(n.name) })))

      /* Reset filters */
      setFilters(defaultFilters)
      setFiltersOpen(false)
      setCpiActivationStatus('all')
      setCpiAssignment('all')

      setTabValue(0)
      setSelectedCpis(selected || [])
      setDataPristine(unselected)
      setActionsDisabled(true)
    }, 200)
  }

  const renderItem = useMemo(() => {
    return data.map((cpi: CpiListItem, index: number) => {
      if (!cpi.visible) return null

      return (
        <DrawerCpiItem
          key={index}
          framework={framework}
          cpi={cpi}
          cpiSelected={cpi.selected}
          cpiCategory={cpi.category}
          data={data}
          setData={setData}
          dataPristine={dataPristine}
          setDataPristine={setDataPristine}
          customCategories={customCategories}
          selectedCpis={selectedCpis}
          setSelectedCpis={setSelectedCpis}
          setActionsDisabled={setActionsDisabled}
          setCategoriesForm={setCategoriesForm}
          selectedCategoryItem={selectedCategoryItem}
          setSelectedCategoryItem={setSelectedCategoryItem}
        />
      )
    })
  }, [data])

  if (!selectedCategoryItem) return null
  return (
    <Drawer anchor="right" open={drawerOpen} onClose={handleCloseDrawer} className="add-cpi-drawer">
      <Wrapper className="wrapper">
        <Box className="header">
          <Typography>{selectedCategoryItem.name} CPIs</Typography>
          <IconButton className="close-icon" onClick={handleCloseDrawer}>
            <CloseIcon />
          </IconButton>
        </Box>
        <DrawerTabs
          tabValue={tabValue}
          handleTabChange={handleTabChange}
          title=""
          tabLabels={[`All (${data.length})`, `Selected (${data.filter((n) => n.selected).length})`]}
        />
        <Box className="search-container">
          <SearchCategoryCpis
            placeholder="Search..."
            options={[]}
            onInputChange={tabValue === 0 ? null : handleSelectedInputChange}
            searchValue={tabValue === 0 ? searchValue : selectedSearchValue}
            setSearchValue={tabValue === 0 ? setSearchValue : setSelectedSearchValue}
          />

          <FilterCategoryCpis
            ref={filterButtonRef}
            filtersOpen={filtersOpen}
            handleCloseFilters={handleCloseFilters}
            handleClickFilter={handleClickFilter}
            cpiActivationStatus={cpiActivationStatus}
            setCpiActivationStatus={setCpiActivationStatus}
            cpiAssignment={cpiAssignment}
            setCpiAssignment={setCpiAssignment}
            configuredIntegrations={configuredIntegrations}
            setFilters={setFilters}
            defaultFilters={defaultFilters}
          />
        </Box>

        <DrawerTabPanel value={tabValue} index={0}>
          <Content
            className="content"
            emptylist={!isFetching && initialLoad && !(searchData as any).data.data.cpis.length ? 'true' : 'false'}
          >
            {!isFetching && initialLoad && (searchData as any).data.data.cpis.length > 0 && data.length > 0 && (
              <Box className="drawer-cpi-item-wrapper">
                {renderItem}
                {/*                {data.map((cpi: CpiListItem, index: number) => {
                  if (cpi.visible) {
                    return (
                      <DrawerCpiItem
                        key={index}
                        framework={framework}
                        cpi={cpi}
                        cpiSelected={cpi.selected}
                        cpiCategory={cpi.category}
                        data={data}
                        setData={setData}
                        dataPristine={dataPristine}
                        setDataPristine={setDataPristine}
                        customCategories={customCategories}
                        selectedCpis={selectedCpis}
                        setSelectedCpis={setSelectedCpis}
                        setActionsDisabled={setActionsDisabled}
                      />
                    )
                  }
                  return null
                })} */}
              </Box>
            )}
            {(isFetching || !initialLoad) && (
              <CategorySkeletonWrapper className="category-skeleton-wrapper">
                {Array.from(Array(4).keys()).map((key) => (
                  <CategorySkeleton key={key} className="category-skeleton-cpi-drawer">
                    <Skeleton
                      variant="rectangular"
                      component="div"
                      animation="wave"
                      width="80%"
                      height="100%"
                      className="card-skeleton-inner"
                    />
                    <Skeleton
                      variant="rectangular"
                      component="div"
                      animation="wave"
                      width="20%"
                      height="100%"
                      className="card-skeleton-inner"
                    />
                  </CategorySkeleton>
                ))}
              </CategorySkeletonWrapper>
            )}
            {!isFetching && initialLoad && !(searchData as any).data.data.cpis.length && (
              <EmptyList selectedCategoryItem={selectedCategoryItem} />
            )}
          </Content>

          <Footer handleCancel={handleCloseDrawer} handleOk={handleOk} actionsDisabled={actionsDisabled} />
        </DrawerTabPanel>
        <DrawerTabPanel value={tabValue} index={1}>
          <Content className="content" emptylist={selectedCpis.every((n) => !n.visible) ? 'true' : 'false'}>
            {selectedCpis.length > 0 && (
              <Box className="drawer-cpi-item-wrapper">
                {selectedCpis.map((cpi, index) => {
                  if (cpi.visible) {
                    return (
                      <DrawerCpiItem
                        key={index}
                        framework={framework}
                        cpiSelected={cpi.selected}
                        cpi={cpi}
                        cpiCategory={cpi.category}
                        data={data}
                        setData={setData}
                        dataPristine={dataPristine}
                        setDataPristine={setDataPristine}
                        customCategories={customCategories}
                        selectedCpis={selectedCpis}
                        setSelectedCpis={setSelectedCpis}
                        tabValue={1}
                        setActionsDisabled={setActionsDisabled}
                        setCategoriesForm={setCategoriesForm}
                        selectedCategoryItem={selectedCategoryItem}
                        setSelectedCategoryItem={setSelectedCategoryItem}
                      />
                    )
                  }
                  return null
                })}
              </Box>
            )}
            {!isFetching && initialLoad && selectedCpis.every((n) => !n.visible) && (
              <EmptyList selectedCategoryItem={selectedCategoryItem} />
            )}
          </Content>

          <Footer handleCancel={handleCloseDrawer} handleOk={handleOk} actionsDisabled={actionsDisabled} />
        </DrawerTabPanel>
      </Wrapper>
    </Drawer>
  )
}

export default AddCpiDrawer
