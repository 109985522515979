import { FC } from 'react'
import { FormikValues } from 'formik'

/* Components */
import { CancelIconButton, RightIconButton, SaveIconButton } from '../../../svg'
import {
  CrowdstrikeActionContainer,
  CrowdstrikeActionButton,
  BackToStep1Button
} from '../crowdstrike-integration/crowdstrikeIntegration.styles'

interface Props {
  handleCancel: () => void
  handleBack: () => void
  handleNext: () => void
  handleSaveAndExit: (values: FormikValues) => void
  handleFinish: () => void
  activeStep: number
  isSubmitting: boolean
  isValid: boolean
  setupSuccessful: boolean
}

const SentinelActions: FC<Props> = ({
  handleCancel,
  handleBack,
  handleNext,
  handleSaveAndExit,
  handleFinish,
  activeStep,
  isSubmitting,
  isValid,
  setupSuccessful
}) => (
  <CrowdstrikeActionContainer>
    {activeStep === 0 && (
      <CrowdstrikeActionButton onClick={handleCancel} secondary="true">
        <CancelIconButton />
        Cancel
      </CrowdstrikeActionButton>
    )}
    {activeStep > 0 && (
      <BackToStep1Button onClick={handleBack}>
        <RightIconButton />
        Go back to Workspace
      </BackToStep1Button>
    )}
    {activeStep < 2 && (
      <>
        <CrowdstrikeActionButton onClick={handleSaveAndExit} tertiary="true" disabled={setupSuccessful}>
          <SaveIconButton />
          Save & Exit
        </CrowdstrikeActionButton>
        {activeStep === 0 && (
          <CrowdstrikeActionButton onClick={handleNext} disabled={!isValid || isSubmitting}>
            <RightIconButton />
            Go to Credentials
          </CrowdstrikeActionButton>
        )}
        {activeStep === 1 && (
          <CrowdstrikeActionButton onClick={handleNext} disabled={!isValid || isSubmitting}>
            <RightIconButton />
            Go to Test
          </CrowdstrikeActionButton>
        )}
      </>
    )}
    {activeStep === 2 && (
      <>
        <CrowdstrikeActionButton onClick={handleSaveAndExit} tertiary="true" disabled={setupSuccessful}>
          <SaveIconButton />
          Save & Exit
        </CrowdstrikeActionButton>
        <CrowdstrikeActionButton onClick={handleFinish} disabled={!setupSuccessful}>
          <RightIconButton />
          Complete Setup
        </CrowdstrikeActionButton>
      </>
    )}
  </CrowdstrikeActionContainer>
)

export default SentinelActions
