import React from 'react'

const DisabledSso = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      d="M3.11495 2.50507L1.69495 3.91507L3.96495 6.18507C2.91495 7.76507 2.30495 9.65507 2.30495 11.6951C2.30495 17.2151 6.78495 21.6951 12.3049 21.6951C14.3449 21.6951 16.2349 21.0851 17.8149 20.0351L20.0849 22.3051L21.4949 20.8851L3.11495 2.50507ZM12.3049 19.6951C7.89495 19.6951 4.30495 16.1051 4.30495 11.6951C4.30495 10.2151 4.71495 8.83507 5.42495 7.63507L16.3549 18.5751C15.1649 19.2851 13.7849 19.6951 12.3049 19.6951Z"
      fill="#998D96"
    />
    <path
      d="M12.3049 3.69507C16.7149 3.69507 20.3049 7.28507 20.3049 11.6951C20.3049 13.1751 19.8949 14.5551 19.1849 15.7451L20.6349 17.1951C21.6949 15.6251 22.3049 13.7351 22.3049 11.6951C22.3049 6.17507 17.8249 1.69507 12.3049 1.69507C10.2649 1.69507 8.37495 2.30507 6.79495 3.35507L8.24495 4.80507C9.44495 4.10507 10.8249 3.69507 12.3049 3.69507Z"
      fill="#998D96"
    />
  </svg>
)

export default DisabledSso
