import { CPIDataSources, DataStatus } from './'

export enum PerformanceStatus {
  'active' = 'ACTIVE',
  'available' = 'AVAILABLE',
  'unavailable' = 'UNAVAILABLE'
}

export interface PerformanceItemDetails {
  cpiDisplayName: string
  cpiTitle: string
  cpiName: string
  section: string
  dataSources: CPIDataSources[]
  status: string
  sla: number
  value: number
  unit: string
  score: number
}

export interface PerformanceItem {
  name: string
  category: string
  status: PerformanceStatus
  details: PerformanceItemDetails[]
  value?: number
  id: string
}

export type IChartData = {
  category: string
  status: PerformanceStatus
  score: number
}

export enum IntegrationStatusMessage {
  'success' = 'success',
  'failed' = 'failed'
}

export type IntegrationConfigSyncStatus =
  | {
      status: IntegrationStatusMessage.success
      errorMessage: undefined
    }
  | {
      status: IntegrationStatusMessage.failed
      errorMessage: string
    }

export interface PerformanceScoreIntegrationConfig {
  connection_id: string
  created_at: string
  created_by: string
  description: string
  id: string
  integration_name: string
  name: string
  prefect_deployment_id: string
  source_id: string
  syncStatus: IntegrationConfigSyncStatus
  tenant_id: string
  updated_at: string
  updated_by: string
}

export interface PerformanceScoreDataSource {
  display_name: string
  integrationConfig: PerformanceScoreIntegrationConfig
  logo_path: string
  name: string
}

export interface PerformanceScoreCpi {
  cpiDisplayName: string
  cpiName: string
  cpiTitle: string
  dataSources: PerformanceScoreDataSource[]
  date: string
  score: number
  section: string
  sla: number
  status: DataStatus
  unit: string
  value: number
  weight: number
  recentFinding?: string
}

export interface PerformanceScoreCategory {
  date: string
  details: PerformanceScoreCpi[]
  score: number
  id: string
  name: string
  status: PerformanceStatus
}

export interface PerformanceScoreData {
  categories: PerformanceScoreCategory[]
  date: string
  score: number
}
