import * as d3 from 'd3'

export const styledXAxisGroup = (xAxisGroup: d3.Selection<SVGGElement, unknown, null, undefined>) => {
  xAxisGroup
    .selectAll('text')
    .attr('color', '#fff')
    .attr('font-size', '14px')
    .attr('font-weight', '400')
    .attr('letter-spacing', '0.25px')
    .attr('line-height', '20px')
    .attr('font-family', "'Quicksand', sans-serif")
}
