import { FC, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Box, Typography } from '@mui/material'
import ReactMarkdown from 'react-markdown'
import rehypeRaw from 'rehype-raw'

/* Utils */
import { CPILibraryIntegration, CPILibraryItem, CPIManifest, IntegrationInstance } from '../../../../../../models'

/* Components */
import {
  AboutContentWrapper,
  AboutDescriptionWrapper,
  DataSourceButton,
  FormulaWrapper,
  SourceCard
} from './aboutTab.styles'
import { ThemeButton } from '../../../../../components/buttons'
import { PlusIcon, RightIconButton } from '../../../../../components/svg'
import ZoomInSvg from '../../../../../components/svg/ZoomInSvg'
import { useAppDispatch } from '../../../../../../store/hooks'
import { ModalType, openModal } from '../../../../../../store/modals/modalSlice'

interface Props {
  manifest: CPIManifest | null
  cpi: CPILibraryItem | null
  activeSources: IntegrationInstance[]
  notConfiguredInstancesApplicableToCpi: IntegrationInstance[]
  additionalOptions: CPILibraryIntegration[]
  getIntegrationLogo: (integrationName: string) => string
  active: boolean
  drawerOpen: boolean
}

const AboutTab: FC<Props> = ({
  manifest,
  cpi,
  activeSources,
  notConfiguredInstancesApplicableToCpi,
  additionalOptions,
  getIntegrationLogo,
  active,
  drawerOpen
}) => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const [selectedDataSourceOption, setSelectedDataSourceOption] = useState(0)
  const [whyItsImportantOpen, setWhyItsImportantOpen] = useState(false)
  const [businessValueOpen, setBusinessValueOpen] = useState(false)

  const handleClickWhyItsImportant = () => {
    setWhyItsImportantOpen(!whyItsImportantOpen)
  }

  const handleClickBusinessValue = () => {
    setBusinessValueOpen(!businessValueOpen)
  }

  const handleOpenIntegration = (n: any) => {
    navigate(`/settings/integrations/${n.name}/add`)
  }

  const TruncatedMarkdown = ({ source, line }: any) => {
    if (!source) return <ReactMarkdown>{''}</ReactMarkdown>

    const lines = source.split('\n')

    if (lines.length > line) {
      const truncatedLines = lines.slice(0, line)

      if (truncatedLines[1].length > 100) truncatedLines[1] = truncatedLines[1].substr(0, 100)

      const truncatedMarkdown = truncatedLines.join('\n') + '\n... '

      return <ReactMarkdown>{truncatedMarkdown}</ReactMarkdown>
    }

    return <ReactMarkdown>{source}</ReactMarkdown>
  }

  const isAdditionalOptionsActive = () => {
    if (!active) {
      return selectedDataSourceOption === 1 ? 'true' : 'false'
    }
    return selectedDataSourceOption === 2 ? 'true' : 'false'
  }

  const zoomInModal = () => {
    const zoomIn = {
      url: manifest?.formula.url,
      name: manifest?.display_name
    }
    dispatch(openModal({ type: ModalType.zoomIn, props: { zoomIn } }))
  }

  if (!manifest || !cpi) return null
  return (
    <>
      <AboutContentWrapper isopen={drawerOpen ? 'true' : 'false'}>
        <Typography className="title">Data Sources</Typography>
        <Box className="data-source-wrapper">
          <DataSourceButton
            className="data-source-button"
            isactive={selectedDataSourceOption === 0 ? 'true' : 'false'}
            onClick={() => setSelectedDataSourceOption(0)}
          >
            Your {active ? 'Active' : 'Potential'} Sources
          </DataSourceButton>
          {active && (
            <DataSourceButton
              className="data-source-button"
              isactive={selectedDataSourceOption === 1 ? 'true' : 'false'}
              onClick={() => setSelectedDataSourceOption(1)}
            >
              Your Potential Sources
            </DataSourceButton>
          )}
          <DataSourceButton
            className="data-source-button"
            isactive={isAdditionalOptionsActive()}
            onClick={() => setSelectedDataSourceOption(active ? 2 : 1)}
          >
            Additional Options
          </DataSourceButton>
        </Box>
        {selectedDataSourceOption === 0 && (
          <>
            <Typography className="card-description">
              {active
                ? 'Your integration instances that are actively configured to this CPI:'
                : activeSources.length > 0
                ? 'Your integration instances that are available but not yet configured to this CPI:'
                : "Your don't have any integration instances that are available but not yet configured to this CPI. Please see Additional Options to setup an instance."}
            </Typography>
            <Box className="source-card-wrapper">
              {!!activeSources.length &&
                activeSources.map((n) => (
                  <SourceCard key={n.name}>
                    <img
                      src={getIntegrationLogo(n.integration_name)}
                      alt={`${n.integration_name} logo`}
                      width="80px"
                      height="80px"
                    />
                    <Box className="source-card-additional">
                      <Box className="additional-text-wrapper">
                        <Typography className="source-card-header">Product Tenant:</Typography>
                        <Typography className="source-card-title">{n.name}</Typography>
                      </Box>
                    </Box>
                  </SourceCard>
                ))}
            </Box>
          </>
        )}
        {active && selectedDataSourceOption === 1 && (
          <>
            <Typography className="card-description">
              {notConfiguredInstancesApplicableToCpi.length > 0
                ? 'Potential instances that can be configured to this CPI:'
                : "You don't have any integration instances that are available but not yet configured to this CPI. Please see Additional Options to setup an instance."}
            </Typography>
            <Box className="source-card-wrapper">
              {!!notConfiguredInstancesApplicableToCpi.length &&
                notConfiguredInstancesApplicableToCpi.map((n) => (
                  <SourceCard key={n.name}>
                    <img
                      src={getIntegrationLogo(n.integration_name)}
                      alt={`${n.integration_name} logo`}
                      width="80px"
                      height="80px"
                    />
                    <Box className="source-card-additional">
                      <Box className="additional-text-wrapper">
                        <Typography className="source-card-header">Product Tenant:</Typography>
                        <Typography className="source-card-title">{n.name}</Typography>
                      </Box>
                    </Box>
                  </SourceCard>
                ))}
            </Box>
          </>
        )}
        {((!active && selectedDataSourceOption === 1) || (active && selectedDataSourceOption === 2)) && (
          <>
            <Typography className="card-description">
              The full suite of integration types that can be used for this CPI:
            </Typography>
            <Box className="source-card-wrapper">
              {!!additionalOptions.length &&
                additionalOptions.map((n) => (
                  <SourceCard key={n.name}>
                    <img src={`/icons/rounded/${n.logo_url}`} alt={`${n.name} logo`} width="80px" height="80px" />
                    <Box className="source-card-additional">
                      <Box className="additional-text-wrapper">
                        <Typography className="source-card-header">Product:</Typography>
                        <Typography className="source-card-title">{n.display_name}</Typography>
                      </Box>
                      <ThemeButton
                        themevariant="quaternary"
                        className="source-card-button"
                        onClick={() => handleOpenIntegration(n)}
                      >
                        <>
                          <RightIconButton />
                          Create Integration
                        </>
                      </ThemeButton>
                    </Box>
                  </SourceCard>
                ))}
            </Box>
          </>
        )}
      </AboutContentWrapper>
      <AboutDescriptionWrapper isopen={drawerOpen ? 'true' : 'false'}>
        <article>
          <h2 className="title">Description</h2>
          {!whyItsImportantOpen && (
            <div className="truncate-wrapper">
              <TruncatedMarkdown source={manifest.long_description} line={2} />
            </div>
          )}
          {whyItsImportantOpen && (
            <ReactMarkdown rehypePlugins={[rehypeRaw]} className="display-linebreak">
              {manifest.long_description}
            </ReactMarkdown>
          )}
          <Box className="button-wrapper">
            <ThemeButton
              themevariant="tertiary"
              onClick={handleClickWhyItsImportant}
              className={whyItsImportantOpen ? 'expanded-icon' : 'collapsed-icon'}
            >
              <>
                <PlusIcon />
                {whyItsImportantOpen ? 'Show Less' : 'Show More'}
              </>
            </ThemeButton>
          </Box>
        </article>
        <article>
          <h2>Measurement Formula</h2>
          <FormulaWrapper>
            <Box className="image-container">
              <img src={`/${manifest.formula.url}`} alt={`${manifest.display_name} formula`} />
            </Box>
          </FormulaWrapper>
          <Box className="button-wrapper">
            <ThemeButton themevariant="tertiary" onClick={zoomInModal}>
              <>
                <ZoomInSvg />
                Zoom in
              </>
            </ThemeButton>
          </Box>
        </article>
        {manifest.business_value && (
          <article>
            <h2 className="title">Business Value</h2>
            {!businessValueOpen && (
              <div className="truncate-wrapper">
                <TruncatedMarkdown source={manifest.business_value} line={2} />
              </div>
            )}
            {businessValueOpen && (
              <ReactMarkdown rehypePlugins={[rehypeRaw]} className="display-linebreak">
                {manifest.business_value}
              </ReactMarkdown>
            )}
            <Box className="button-wrapper">
              <ThemeButton
                themevariant="tertiary"
                onClick={handleClickBusinessValue}
                className={businessValueOpen ? 'expanded-icon' : 'collapsed-icon'}
              >
                <>
                  <PlusIcon />
                  {businessValueOpen ? 'Show Less' : 'Show More'}
                </>
              </ThemeButton>
            </Box>
          </article>
        )}
      </AboutDescriptionWrapper>
    </>
  )
}

export default AboutTab
