export const frameworkDefaultOptions = [
  {
    label: 'Onyxia',
    value: 'onyxia',
    icon: '/onyxia-settings-icon.svg'
  },
  {
    label: 'NIST CSF',
    value: 'nist',
    icon: '/nist-settings-icon.svg'
  },
  {
    label: 'Custom',
    value: 'custom',
    icon: '/custom-icon.svg'
  }
]
