import { Box } from '@mui/material'
import styled from '@emotion/styled/macro'

export const DetailedComparisonWrapper = styled(Box)`
  padding: 16px 24px;
  border-radius: 16px;
  background: linear-gradient(0deg, rgba(255, 169, 252, 0.08) 0%, rgba(255, 169, 252, 0.08) 100%), #1e1a1d;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.3), 0 2px 6px 2px rgba(0, 0, 0, 0.15);
  width: 100%;
  max-width: 756px;

  .detailed-comparison-title {
    color: #fff;
    font-family: Quicksand, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.1px;
  }

  .detailed-comparison-description {
    color: #d0c3cc;
    font-family: Quicksand, sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.4px;
  }

  .no-category {
    border-radius: 2px;
    background: #4d444b;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.3), 0 2px 6px 2px rgba(0, 0, 0, 0.15);
    font-family: 'Quicksand', sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.4px;
    color: white;
    padding: 6px 7px;
    display: flex;
    align-items: center;
    gap: 8px;
    margin-top: 16px;
    margin-bottom: 24px;
  }
`

export const DetailedComparisonLegend = styled(Box)`
  margin: 24px 0 52px;
  gap: 24px;
  display: flex;
  align-items: center;

  .legend-item {
    display: flex;
    align-items: center;

    .legend-color {
      width: 16px;
      height: 16px;

      &.blue {
        border-radius: 4px 0 0 4px;
        border: 1px solid #8e9aff;
        background: linear-gradient(180deg, #8e9aff 0%, rgba(142, 154, 255, 0.4) 100%);
      }

      &.orange {
        border: 1px solid #f09543;
        background: linear-gradient(180deg, rgba(240, 149, 67, 0.94) 0%, rgba(240, 149, 67, 0.4) 100%);
      }

      &.purple {
        border-radius: 0 4px 4px 0;
        border: 1px solid #b051b3;
        background: linear-gradient(180deg, #781a7e 0%, rgba(120, 26, 126, 0.4) 100%);
      }
    }

    .legend-text {
      color: #fff;
      font-family: Quicksand, sans-serif;
      font-size: 11px;
      font-style: normal;
      font-weight: 500;
      line-height: 16px;
      letter-spacing: 0.5px;
      margin-left: 8px;
    }
  }
`

export const DetailedComparisonGroupsWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 32px;
`

export const DetailedComparisonItemWrapper = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 32px;

  .title-wrapper {
    flex-basis: 25%;
    margin-right: 24px;

    .industry-title {
      text-align: right;
      white-space: nowrap;
      color: #fff;
      font-family: Quicksand, sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: 0.1px;
    }
  }
`

export const DetailedComparisonItemValues = styled(Box)<{
  one: string | number
  two: string | number
  three: string | number
}>`
  width: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;
  box-sizing: content-box;
  flex-basis: 75%;

  color: #fff;
  text-align: center;
  font-family: Quicksand, sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.1px;

  &:hover {
    .one {
      opacity: 0.38;
    }

    .two {
      opacity: 0.38;
    }

    .three {
      opacity: 0.38;
    }
  }

  .one {
    width: ${({ one }) => `${one < 1 ? 0 : one}%`};
    border-radius: 16px 0 0 16px;
    border-top: 1px solid #8e9aff;
    border-bottom: 1px solid #8e9aff;
    border-left: 1px solid #8e9aff;
    background: linear-gradient(180deg, #8e9aff 0%, rgba(142, 154, 255, 0.4) 100%);
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;

    ${({ one }) =>
      one < 1 &&
      `
      border: none;
    `};

    ${({ two, three }) =>
      two < 1 &&
      three < 1 &&
      `
      border: 16px;
    `};

    &:hover {
      border: 1px solid #fff;
      box-shadow: 0 0 16px 0 rgba(255, 255, 255, 0.25);
      opacity: 1;
    }
  }

  .two {
    width: ${({ two }) => `${two < 1 ? 0 : two}%`};
    border-top: 1px solid #f09543;
    border-bottom: 1px solid #f09543;
    background: linear-gradient(180deg, rgba(240, 149, 67, 0.94) 0%, rgba(240, 149, 67, 0.4) 100%);
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;

    ${({ one, two }) =>
      one < 1 &&
      two >= 1 &&
      `
      border-radius: 16px 0 0 16px;
    `};

    ${({ one, two }) =>
      one < 1 &&
      two < 1 &&
      `
      border: none;
    `};

    ${({ one, three }) =>
      one < 1 &&
      three < 1 &&
      `
      border-radius: 16px;
    `};

    &:hover {
      border: 1px solid #fff;
      box-shadow: 0 0 16px 0 rgba(255, 255, 255, 0.25);
      opacity: 1;
    }
  }

  .three {
    width: ${({ three }) => `${three < 1 ? 0 : three}%`};
    border-radius: 0 16px 16px 0;
    border-top: 1px solid #b051b3;
    border-right: 1px solid #b051b3;
    border-bottom: 1px solid #b051b3;
    background: linear-gradient(180deg, #781a7e 0%, rgba(120, 26, 126, 0.4) 100%);
    color: #fff;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;

    ${({ one, two }) =>
      one < 1 &&
      two < 1 &&
      `
      border-radius: 16px;
    `};

    ${({ three }) =>
      three < 1 &&
      `
      border: none;
    `};

    &:hover {
      border: 1px solid #fff;
      box-shadow: 0 0 16px 0 rgba(255, 255, 255, 0.25);
      opacity: 1;
    }
  }
`
