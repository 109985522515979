import styled from '@emotion/styled'
import { Box } from '@mui/material'

export const SearchBarWrapper = styled(Box)`
  display: flex;
  justify-content: center;
  position: relative;
  width: 200px;

  .search-icon {
    position: absolute;
    left: 10px;
    top: 9px;
    z-index: 1;
  }

  .clear-btn {
    position: absolute;
    right: 0;
    top: 0px;
  }

  .search-bar {
    width: 100%;
    border-radius: 28px;
    height: 40px;
  }

  .MuiOutlinedInput-root {
    height: 40px;
  }

  .MuiOutlinedInput-input {
    border-radius: 4px;
    border: 1px solid #998d96;
    padding: 8px 40px 8px 40px;
    color: #fff;
    font-family: 'Quicksand', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    box-sizing: border-box;
    height: 40px;

    &::placeholder {
      color: #e9e0e4;
      opacity: 1;
    }

    &:focus {
      border-color: #ffa9fc;
    }
  }

  fieldset {
    border: none;
  }
`
