import { styled } from '@mui/material/styles'
import { tooltipClasses, TooltipProps } from '@mui/material/Tooltip'
import { Tooltip } from '@mui/material'

export const CategoryHoverTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    display: 'flex',
    padding: '16px',
    flexDirection: 'column',
    alignItems: 'flex-start',
    borderRadius: '8px',
    border: '1px solid #998D96',
    background: '#4D444B',
    boxShadow: '0px 1px 2px 0px rgba(0, 0, 0, 0.30), 0px 1px 3px 1px rgba(0, 0, 0, 0.15);',
    color: '#E9E0E4',
    fontFamily: '"Quicksand", sans-serif',
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '16px'
  },
  '.tooltip-list': {
    display: 'flex',
    alignItems: 'center',
    gap: '2px',
    marginBottom: '2px',

    '.tooltip-header': {
      fontFamily: '"Quicksand", sans-serif',
      fontSize: '14px !important',
      fontWeight: 500,
      lineHeight: '20px',
      letterSpacing: '0.1px',
      color: '#FFF'
    }
  },
  '.tooltip-desc': {
    fontFamily: '"Quicksand", sans-serif',
    fontSize: '12px !important',
    fontWeight: 400,
    lineHeight: '16px',
    letterSpacing: '0.4px',
    color: '#FFF',
    maxWidth: '235px'
  },
  '.tooltip-wrapper': {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    marginBottom: '8px',
    marginTop: '8px',

    '.tooltip-text': {
      fontFamily: '"Quicksand", sans-serif',
      fontSize: '11px !important',
      fontWeight: 500,
      lineHeight: '16px',
      color: '#E9E0E4'
    }
  }
})
