import { FC } from 'react'
import { Box, IconButton, Typography } from '@mui/material'
import { Error } from '@mui/icons-material'
import { ToastVariant } from '../../../store/toasts/toastSlice'
import styled from '@emotion/styled/macro'

const Wrapper = styled(Box)<{ variant: ToastVariant }>`
  width: 645px;
  height: 72px;
  position: fixed;
  right: 40px;
  background: #6dd58c;
  box-shadow: 0 2px 6px 2px rgba(0, 0, 0, 0.15), 0 1px 2px rgba(0, 0, 0, 0.3);
  border-radius: 0;
  background: ${({ variant }) =>
    variant === ToastVariant.success
      ? '#AED581'
      : variant === ToastVariant.warning
      ? '#7FCFFF'
      : variant === ToastVariant.neutral
      ? '#4D444B'
      : '#FDAEA3'};
  display: flex;
  align-items: center;
  z-index: 10001;
  opacity: 1;
  transition: opacity 0.5s ease-in-out;
  bottom: -30px;
  animation: slidein 0.5s forwards;

  @keyframes slidein {
    from {
      bottom: -30px;
      opacity: 0;
    }
    to {
      bottom: 40px;
      opacity: 1;
    }
  }

  .close-button {
    margin-right: 16px;
  }

  .svg-wrapper {
    padding: 24px 16px;
  }

  .wrapper {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-flow: column wrap;
    text-align: left;
    flex-grow: 1;

    .text {
      text-align: left;
      font-family: 'Quicksand', sans-serif;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.1px;
      color: #37003b;
      margin-bottom: 4px;
    }

    .neutral-text {
      color: #e9e0e4;
    }

    .description {
      text-align: left;
      font-family: 'Quicksand', sans-serif;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.25px;
      color: #37003b;
    }

    .neutral-description {
      color: #e9e0e4;
    }
  }
`

interface Props {
  variant: ToastVariant
  text: string
  description: string
  handleClose: () => void
  sx?: Record<string, unknown>
}

const MainLayoutToast: FC<Props> = ({ variant, text, description, handleClose, sx }) => (
  <Wrapper variant={variant} sx={sx}>
    {variant === ToastVariant.error ? (
      <Box className="svg-wrapper">
        <Error
          sx={{
            fill: '#690005'
          }}
        />
      </Box>
    ) : variant === ToastVariant.warning ? (
      <Box className="svg-wrapper">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
          <rect x="6" y="6" width="12" height="12" fill="#37003B" />
        </svg>
      </Box>
    ) : variant === ToastVariant.neutral ? (
      <Box className="svg-wrapper">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
          <path
            d="M9.37 5.51C9.19 6.15 9.1 6.82 9.1 7.5C9.1 11.58 12.42 14.9 16.5 14.9C17.18 14.9 17.85 14.81 18.49 14.63C17.45 17.19 14.93 19 12 19C8.14 19 5 15.86 5 12C5 9.07 6.81 6.55 9.37 5.51ZM12 3C7.03 3 3 7.03 3 12C3 16.97 7.03 21 12 21C16.97 21 21 16.97 21 12C21 11.54 20.96 11.08 20.9 10.64C19.92 12.01 18.32 12.9 16.5 12.9C13.52 12.9 11.1 10.48 11.1 7.5C11.1 5.69 11.99 4.08 13.36 3.1C12.92 3.04 12.46 3 12 3Z"
            fill="#E9E0E4"
          />
        </svg>
      </Box>
    ) : (
      <Box className="svg-wrapper">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M9.00003 16.1698L4.83003 11.9998L3.41003 13.4098L9.00003 18.9998L21 6.99984L19.59 5.58984L9.00003 16.1698Z"
            fill="#37003B"
          />
        </svg>
      </Box>
    )}

    <Box className="wrapper">
      <Typography className={`${variant === ToastVariant.neutral ? 'neutral-text' : ''} text`}>{text}</Typography>
      <Typography className={`${variant === ToastVariant.neutral ? 'neutral-description' : ''} description`}>
        {description}
      </Typography>
    </Box>
    <IconButton className="close-button" onClick={handleClose}>
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41Z"
          fill={variant === ToastVariant.neutral ? '#D0C3CC' : '#37003B'}
        />
      </svg>
    </IconButton>
  </Wrapper>
)

export default MainLayoutToast
