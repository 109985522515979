import { Box } from '@mui/material'
import styled from '@emotion/styled/macro'

export const HistoricalPerformanceChartContainer = styled(Box)`
  border-radius: 4px;
  z-index: 10;
  position: relative;

  .bordered-wrapper {
    position: absolute;
    height: 481px;
    width: 732px;
    top: -1px;
    right: 1px;
    z-index: 10;
    pointer-events: none;
    display: none;
    border-radius: 4px;
    border: 1px solid #998d96;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.3), 0 6px 2px rgba(0, 0, 0, 0.15);

    @media (min-width: 900px) {
      display: block;
    }

    @media (min-width: 1441px) {
      left: auto;
      //right: -1px;
    }

    @media (min-width: 1510px) {
      left: auto;
      right: 0;
    }
  }

  #historical-performance-svg {
    border-radius: 4px;
    z-index: 10;
    //width: 582px;

    g.y-axis {
      .tick:first-of-type {
        transform: translate(13px, 448px) !important;
      }

      .tick:nth-child(4) {
        transform: translate(4px, 210px) !important;
      }

      .tick:last-of-type {
        transform: translate(0, -9px) !important;
      }
    }

    g.y-axis-perform {
      .tick:first-of-type {
        transform: translate(13px, 448px) !important;
      }

      .tick:nth-child(7) {
        transform: translate(0px, 150px) !important;
      }

      .tick:nth-child(4) {
        transform: translate(4px, 300px) !important;
      }

      .tick:last-of-type {
        transform: translate(0, -9px) !important;
      }
    }
  }

  .historical-performance-tooltip {
    display: none;
    position: absolute;
    opacity: 1;
    padding: 16px;
    gap: 10px;
    border-radius: 8px;
    border: 1px solid #998d96;
    background: #4d444b;
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.3), 0 8px 12px 6px rgba(0, 0, 0, 0.15);
    color: #fff;
    font-family: Quicksand, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.1px;
    width: 200px;
    z-index: 9999;

    .tooltip-performance,
    .tooltip-units {
      color: #fff;
      font-family: Quicksand, sans-serif;
      font-style: normal;
      font-weight: 400;
    }

    .tooltip-performance {
      font-size: 24px;
      line-height: 32px;
      margin: 0 0 4px 0;
    }

    .tooltip-units {
      font-size: 12px;
      line-height: 16px;
      letter-spacing: 0.4px;
      margin: 0;
    }

    .severity-text {
      text-transform: capitalize;
    }
  }
`
