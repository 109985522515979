import { FC } from 'react'
import { Grid, Box, Typography, Link } from '@mui/material'

/* Components */
import ControlledTextField from '../../../form-components/ControlledTextField'
import SentinelInstructions from './SentinelInstructions'
import { SentinelInputWrapper } from './sentinelIntegration.styles'

const instructionsContent = (
  <ol>
    <li>
      <Typography>
        Go to your{' '}
        <Link
          href="https://login.microsoftonline.com/organizations/oauth2/v2.0/authorize?redirect_uri=https%3A%2F%2Fportal.azure.com%2Fsignin%2Findex%2F&response_type=code%20id_token&scope=https%3A%2F%2Fmanagement.core.windows.net%2F%2Fuser_impersonation%20openid%20email%20profile&state=OpenIdConnect.AuthenticationProperties%3DMoAJb0sCpuCQLYykmtyBG75U-ccWXir4VrTsWygANEOACn2FRvkTTWMmbptATjtEt0RxD3rwBuQ6eTny0FNK9ZhEWbw8rRGz872w5wwhUumosGhjnSI200NGniKFssnUxBFXOAMcG3qKxr8bJXxmOiBTuu_B5njhyEysbiyl8daQXWNw77ajgysSGvYcbGWvZ0jYgUz2b_7EVwxVlUUgkkSdL7Jy8gRSc9XHbwjNNDtL7c4cqD9XdNtAveXJqr0neztywCo70s38l15wsKZG9kJzsyvZmhEQxK88AcLuJwkyj165pfs_vNFgzTSscL2BKS6wj4_5o3819UDq_Bo3XG-vpo-p3r0kxVf7hhgSwB-pWK2y8UcOII8huSu-mPmz-IRGaVATXYj80vDl1lkoBHeV4eMydzBfPdH0r-m6VcZEw8fvY_ZIFA6B35CHoPUcSBmfFOA9RpqwOj9gIAL1cpMmibe0NmE12073xARFc18&response_mode=form_post&nonce=638113460936755158.NDhkZjM4ZDItMDIzYi00ZGRmLWJlMDYtNDMyNjUyZjkzNGM2OWIyMGM4ODktZTUzNy00MDc4LWI4ZmItMjExNzVmOTJhNWQ5&client_id=c44b4083-3bb0-49c1-b47d-974e53cbdf3c&site_id=501430&client-request-id=e27fcfb8-5dd7-46f6-999e-f5dbcdb0f9e9&x-client-SKU=ID_NET472&x-client-ver=6.22.1.0"
          target="_blank"
          rel="noopener noreferrer"
        >
          Microsoft Azure Portal.
        </Link>
      </Typography>
    </li>
    <li>
      <Typography>
        In your portal, open the menu (top left corner), select “Support and Resources”, then click “API Clients and
        Keys”.
      </Typography>
    </li>
  </ol>
)

interface Props {
  activeStep: number
}

const SentinelIntegrationStep1Assisted: FC<Props> = ({ activeStep }) => (
  <Box sx={{ display: 'flex' }}>
    <SentinelInstructions content={instructionsContent} variant="step1" />
    {activeStep === 0 && (
      <>
        <Grid container spacing={0} sx={{ pl: '72px' }}>
          <Grid item xs={12}>
            <Grid container spacing={0}>
              <Grid item xs={12}>
                <SentinelInputWrapper maxWidth="408px">
                  <ControlledTextField
                    id="workspace-url"
                    name="resource_group"
                    label="Workspace URL *"
                    type="text"
                    variant="filled"
                  />
                </SentinelInputWrapper>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </>
    )}
  </Box>
)

export default SentinelIntegrationStep1Assisted
