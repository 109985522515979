import { Autocomplete } from '@mui/material'
import styled from '@emotion/styled/macro'

export const StyledAutocomplete = styled(Autocomplete)`
  min-width: 300px;
  height: 40px;
  flex-grow: 1;
  background: transparent;
  border: 1px solid #998D96;
  border-radius: 100px;
  font-family: 'Quicksand', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.1px;
  color: #D0C3CC;

  @media (max-width: 1330px) {
    min-width: 270px;
  }

  &.Mui-focused {
    border: 1px solid #FFA9FC;
  }

  .MuiOutlinedInput-root {
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 0;
    padding-bottom: 0;
    height: 40px;
    color: #e3e3e3;

    .MuiAutocomplete-endAdornment {
      .MuiButtonBase-root {
        padding: 6px;

        &:hover {
          background: rgba(202, 196, 208, 0.08);
        }

        svg {
          path {
            fill: #d0c3cc;
          }
        }
      }
    }

    .MuiOutlinedInput-input {
      padding-left: 10px;
      padding-top: 0;
      padding-bottom: 0;
      height: 40px;
      font-family: 'Quicksand', sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px; /* 142.857% */
      letter-spacing: 0.1px;

      &::placeholder {
        opacity: 1;
        color: #D0C3CC;
        font-family: 'Quicksand', sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px; /* 142.857% */
        letter-spacing: 0.1px;
      }
    }

    &::placeholder {
      opacity: 1;
    }

    fieldset {
      border: none;
    }
`
