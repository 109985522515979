import * as Yup from 'yup'

export enum SentinelIntegrationType {
  assisted,
  manual
}

export interface FormValues {
  resource_group: string
  client_id: string
  tenant_id: string
  secret: string
  log_analytics_workspace: string
}

export const formDefaultValues = {
  resource_group: '',
  client_id: '',
  tenant_id: '',
  secret: '',
  log_analytics_workspace: 'log',
  subscription_id: '',
  workspace_name: ''
}

export const assistedStep1Schema = Yup.object({
  resource_group: Yup.string().required('Required').label('Resource Group'),
  log_analytics_workspace: Yup.string().required()
})

export const assistedStep2Schema = Yup.object({
  client_id: Yup.string().required().label('Client ID'),
  tenant_id: Yup.string().required().label('Tenant ID'),
  secret: Yup.string().required().label('Secret')
})

export const assistedSetupSchema = Yup.object().shape(assistedStep1Schema.fields).shape(assistedStep2Schema.fields)

export const manualStep1Schema = Yup.object({
  subscription_id: Yup.string().required('Required').label('Subscription ID'),
  resource_group_name: Yup.string().required('Required').label('Resource Group Name'),
  workspace_name: Yup.string().required('Required').label('Workspace Name')
})

export const manualStep2Schema = Yup.object({
  client_id: Yup.string().required().label('Client ID'),
  tenant_id: Yup.string().required().label('Tenant ID'),
  secret: Yup.string().required().label('Secret')
})

export const manualSetupSchema = Yup.object().shape(manualStep1Schema.fields).shape(manualStep2Schema.fields)
