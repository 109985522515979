import * as Yup from 'yup'

export interface FormValues {
  name: string
  email: string
  role: string
}

export const defaultValues = {
  name: '',
  email: '',
  role: ''
}

export const schema = Yup.object({
  name: Yup.string().min(3).max(200).required('Required'),
  email: Yup.string().email('Please enter a valid email').max(77).required('Required'),
  role: Yup.string().required('Required')
})
