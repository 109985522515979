import { FC, MouseEvent, useState } from 'react'
import { Box, Grid, IconButton, InputAdornment, Link, Typography } from '@mui/material'
import { Visibility, VisibilityOff } from '@mui/icons-material'

/* Utils */
import { FILE_NAME } from '../crowdstrike-integration/crowdstrikeIntegration.constants'
import { notification } from '../../../../../store/notifications/notificationSlice'
import { useAppDispatch } from '../../../../../store/hooks'

/* Components */
import ControlledTextField from '../../../form-components/ControlledTextField'
import {
  ScriptButton,
  SentinelInputWrapper,
  SentinelInstructionsContent,
  SentinelInstructionsTitle,
  SentinelInstructionsTitleWrapper,
  SentinelInstructionsWrapper
} from './sentinelIntegration.styles'
import { CopyLineIcon, DownloadIcon, IntegrationInfoIcon } from '../../../svg'
import SentinelInstructionsTooltip from './SentinelInstructionsTooltip'

interface Props {
  activeStep: number
  showPassword: boolean
  handleClickShowPassword: () => void
  handleMouseDownPassword: (event: MouseEvent<HTMLButtonElement>) => void
}

const SentinelIntegrationStep2Assisted: FC<Props> = ({
  activeStep,
  showPassword,
  handleClickShowPassword,
  handleMouseDownPassword
}) => {
  const dispatch = useAppDispatch()
  const [clientAnchor, setClientAnchor] = useState<null | HTMLElement>(null)
  const [clientOpen, setClientOpen] = useState(false)

  const clientCanBeOpen = clientOpen && Boolean(clientAnchor)
  const clientId = clientCanBeOpen ? 'transition-popper' : undefined

  const handleClickTooltipIcon = (e: MouseEvent<HTMLButtonElement>) => {
    setClientAnchor(e.currentTarget)
    setClientOpen(!clientOpen)
  }

  const handleLearnMore = () => {
    fetch('/crowdstrikeIntegration.pdf').then((response) => {
      response
        .blob()
        .then((blob) => {
          const fileURL = window.URL.createObjectURL(blob)
          const alink = document.createElement('a')
          alink.href = fileURL
          alink.download = FILE_NAME
          alink.click()
        })
        .then(() => {
          dispatch(
            notification({
              open: true,
              variant: 'success',
              message: 'PDF saved to desktop'
            })
          )
        })
        .catch(() => {
          dispatch(
            notification({
              open: true,
              variant: 'error',
              message: 'Failed saving PDF to desktop'
            })
          )
        })
    })
  }

  const handleDownloadScript = () => {
    //
  }

  return (
    <Box sx={{ display: 'flex' }}>
      {/* Instructions */}
      <SentinelInstructionsWrapper>
        <SentinelInstructionsTitleWrapper>
          <SentinelInstructionsTitle>Instructions</SentinelInstructionsTitle>
          <SentinelInstructionsTooltip
            id={clientId}
            open={clientOpen}
            anchorEl={clientAnchor}
            handleDownload={handleLearnMore}
            variant="step2"
            handleClickAway={() => {
              if (clientOpen) {
                setClientOpen(false)
              }
            }}
          />
        </SentinelInstructionsTitleWrapper>
        <SentinelInstructionsContent>
          <ol>
            <li>
              <Typography>Download the Script:</Typography>
              <ScriptButton onClick={handleDownloadScript}>
                <DownloadIcon /> Script
              </ScriptButton>
              <IconButton onClick={handleClickTooltipIcon} className="tooltip-icon-padded">
                <IntegrationInfoIcon />
              </IconButton>
            </li>
            <li>
              <Typography>
                Go to your{' '}
                <Link
                  href="https://login.microsoftonline.com/organizations/oauth2/v2.0/authorize?redirect_uri=https%3A%2F%2Fportal.azure.com%2Fsignin%2Findex%2F&response_type=code%20id_token&scope=https%3A%2F%2Fmanagement.core.windows.net%2F%2Fuser_impersonation%20openid%20email%20profile&state=OpenIdConnect.AuthenticationProperties%3DMoAJb0sCpuCQLYykmtyBG75U-ccWXir4VrTsWygANEOACn2FRvkTTWMmbptATjtEt0RxD3rwBuQ6eTny0FNK9ZhEWbw8rRGz872w5wwhUumosGhjnSI200NGniKFssnUxBFXOAMcG3qKxr8bJXxmOiBTuu_B5njhyEysbiyl8daQXWNw77ajgysSGvYcbGWvZ0jYgUz2b_7EVwxVlUUgkkSdL7Jy8gRSc9XHbwjNNDtL7c4cqD9XdNtAveXJqr0neztywCo70s38l15wsKZG9kJzsyvZmhEQxK88AcLuJwkyj165pfs_vNFgzTSscL2BKS6wj4_5o3819UDq_Bo3XG-vpo-p3r0kxVf7hhgSwB-pWK2y8UcOII8huSu-mPmz-IRGaVATXYj80vDl1lkoBHeV4eMydzBfPdH0r-m6VcZEw8fvY_ZIFA6B35CHoPUcSBmfFOA9RpqwOj9gIAL1cpMmibe0NmE12073xARFc18&response_mode=form_post&nonce=638113460936755158.NDhkZjM4ZDItMDIzYi00ZGRmLWJlMDYtNDMyNjUyZjkzNGM2OWIyMGM4ODktZTUzNy00MDc4LWI4ZmItMjExNzVmOTJhNWQ5&client_id=c44b4083-3bb0-49c1-b47d-974e53cbdf3c&site_id=501430&client-request-id=e27fcfb8-5dd7-46f6-999e-f5dbcdb0f9e9&x-client-SKU=ID_NET472&x-client-ver=6.22.1.0"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Azure Portal Cloudshell
                </Link>{' '}
                to upload the Script from your device.
              </Typography>
            </li>
            <li>
              <Typography>Paste the following into the command line:</Typography>
              <Typography className="copy-text">
                terraform init <CopyLineIcon />
              </Typography>
            </li>
            <li>
              <Typography>After the initialization is complete, paste the following into the command line:</Typography>
              <Typography className="copy-text">
                terraform apply <CopyLineIcon />
              </Typography>
            </li>
            <li>
              <Typography>
                Copy the output fields and paste them into the corresponding fields in this wizard.
              </Typography>
            </li>
          </ol>
        </SentinelInstructionsContent>
      </SentinelInstructionsWrapper>
      {activeStep === 1 && (
        <>
          <Grid container spacing={0} sx={{ pl: '72px' }}>
            <Grid item xs={12}>
              <Grid container spacing={0}>
                <Grid item xs={12}>
                  <SentinelInputWrapper>
                    <ControlledTextField
                      data-private
                      id="client-id"
                      name="client_id"
                      label="Client ID *"
                      type="text"
                      variant="filled"
                    />
                  </SentinelInputWrapper>
                </Grid>
                <Grid item xs={12}>
                  <SentinelInputWrapper>
                    <ControlledTextField
                      data-private
                      id="tenant-id"
                      name="tenant_id"
                      label="Tenant ID *"
                      type="text"
                      variant="filled"
                    />
                  </SentinelInputWrapper>
                </Grid>
                <Grid item xs={12}>
                  <SentinelInputWrapper>
                    <ControlledTextField
                      data-private
                      id="secret"
                      name="secret"
                      label="Secret *"
                      variant="filled"
                      type={showPassword ? 'text' : 'password'}
                      autoComplete="current-password"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle Client Secret visibility"
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                            >
                              {showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                          </InputAdornment>
                        )
                      }}
                    />
                  </SentinelInputWrapper>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </>
      )}
    </Box>
  )
}

export default SentinelIntegrationStep2Assisted
