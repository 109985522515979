const LegendPredictedPerformanceIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="4" viewBox="0 0 16 4" fill="none">
    <mask id="path-1-inside-1_1_4265" fill="white">
      <path d="M0 0.5H16V3.5H0V0.5Z" />
    </mask>
    <path d="M0 3.5H16V-2.5H0V3.5Z" fill="#BB95EA" mask="url(#path-1-inside-1_1_4265)" />
  </svg>
)

export default LegendPredictedPerformanceIcon
