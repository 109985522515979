import { FC } from 'react'
import { Box, Typography } from '@mui/material'
import { colors } from '../../../../../theme/'
import styled from '@emotion/styled/macro'

const List = styled.ul`
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
  color: #eeeeee;
  padding-inline-start: 23px;
`

const Notice: FC = () => (
  <Box
    sx={{
      padding: '12px 16px',
      background: colors.primary,
      borderRadius: '4px',
      display: 'flex'
    }}
  >
    <Box>
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{ fontSize: '24px', width: '24px', height: '24px', marginRight: '12px' }}
      >
        <path
          d="M10.9844 7V4.98438H9.01562V7H10.9844ZM10.9844 15.0156V9.01562H9.01562V15.0156H10.9844ZM2.92188 2.96875C4.89062 1 7.25 0.015625 10 0.015625C12.75 0.015625 15.0938 1 17.0312 2.96875C19 4.90625 19.9844 7.25 19.9844 10C19.9844 12.75 19 15.1094 17.0312 17.0781C15.0938 19.0156 12.75 19.9844 10 19.9844C7.25 19.9844 4.89062 19.0156 2.92188 17.0781C0.984375 15.1094 0.015625 12.75 0.015625 10C0.015625 7.25 0.984375 4.90625 2.92188 2.96875Z"
          fill="#ACACAC"
        />
      </svg>
    </Box>
    <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
      <Typography
        variant="h5"
        sx={{
          fontSize: '16px',
          lineHeight: '24px',
          fontWeight: 700,
          color: '#EEEEEE'
        }}
      >
        Notice
      </Typography>
      <Typography
        variant="body1"
        sx={{
          fontSize: '12px',
          lineHeight: '16px',
          fontWeight: 400,
          margin: '8px 0',
          color: '#EEEEEE'
        }}
      >
        You can always come back and edit your desired projects later.
      </Typography>
      <Typography
        variant="body1"
        sx={{
          fontSize: '12px',
          lineHeight: '16px',
          fontWeight: 400,
          marginTop: '16px',
          color: '#EEEEEE'
        }}
      >
        Onyxia normalizes Jira's prioritization categories into 3 categories:
      </Typography>
      <List>
        <li>
          <strong>High</strong> = Blocker & Critical
        </li>
        <li>
          <strong>Medium</strong> = Major
        </li>
        <li>
          <strong>Low</strong> = Minor & Trivial
        </li>
      </List>
    </Box>
  </Box>
)

export default Notice
