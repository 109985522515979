import { Box, Slider as MuiSlider } from '@mui/material'
import styled from '@emotion/styled/macro'

export const CompetitorAnalysisLegend = styled(Box)`
  margin: 24px 0 38px;
  gap: 24px;
  display: flex;
  align-items: center;

  .legend-item {
    display: flex;
    align-items: center;

    .legend-color {
      width: 16px;
      height: 16px;

      &.blue {
        border-radius: 2px;
        border: 1px solid #8e9aff;
        background: #8e9aff;
      }

      &.purple {
        border-radius: 2px;
        border: 1px solid #781a7e;
        background: #781a7e;
      }

      &.white {
        border: 1px solid #fff;
        background: linear-gradient(180deg, #fff 0%, rgba(255, 255, 255, 0.4) 100%);
        box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.3), 0 2px 6px 2px rgba(0, 0, 0, 0.15);
      }

      &.orange {
        background: linear-gradient(180deg, #f09543 0%, rgba(240, 149, 67, 0.4) 100%);
        stroke-width: 2px;
        border: 2px solid #f09543;
        stroke: #f09543;
        filter: drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.3)) drop-shadow(0px 2px 6px rgba(0, 0, 0, 0.15));
        border-radius: 50%;
      }
    }

    .legend-text {
      color: #fff;
      font-family: Quicksand, sans-serif;
      font-size: 11px;
      font-style: normal;
      font-weight: 500;
      line-height: 16px;
      letter-spacing: 0.5px;
      margin-left: 8px;
    }
  }
`

export const AnalysisGroupsWrapper = styled(Box)``

export const AnalysisItem = styled(Box)`
  margin-bottom: 35px;

  .analysis-category-text {
    color: #fff;
    font-family: Quicksand, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.1px;
  }
`

export const AnalysisItemDataContainer = styled(Box)`
  position: relative;
`

export const Slider = styled(MuiSlider)(() => ({
  color: '#3a8589',
  height: 32,
  padding: '9px 0',

  '& .MuiSlider-thumb': {
    height: 27,
    width: 27,
    borderRadius: '0',

    '.MuiSlider-valueLabel': {
      width: '200px',
      borderRadius: '8px',
      border: '1px solid #998D96',
      background: '#4D444B',
      boxShadow: '0 1px 2px 0 rgba(0, 0, 0, 0.30), 0 1px 3px 1px rgba(0, 0, 0, 0.15)',
      padding: '16px',

      '.tooltip-value': {
        '.MuiTypography-root': {
          color: '#FFF',
          fontFamily: '"Quicksand", sans-serif',
          fontWeight: 400,
          fontStyle: 'normal',
          textAlign: 'start',
          wordBreak: 'break-word',
          whiteSpace: 'pre-wrap'
        },

        '.tooltip-title': {
          fontSize: '24px',
          lineHeight: '32px',
          marginBottom: '3px'
        },

        '&.tooltip-text': {
          fontSize: '12px',
          lineHeight: '16px',
          letterSpacing: '0.4px'
        }
      }
    },

    '&[data-index="0"]': {
      display: 'none'
    },

    '&[data-index="1"]': {
      width: '32px',
      height: '32px',
      background: 'linear-gradient(180deg, #F09543 0%, rgba(240, 149, 67, 0.40) 100%)',
      strokeWidth: '2px',
      stroke: '#F09543',
      border: '2px solid #F09543',
      outline: 'none',
      filter: 'drop-shadow(0px 2px 3px rgba(0, 0, 0, 0.30)) drop-shadow(0px 6px 10px rgba(0, 0, 0, 0.15))',
      borderRadius: '50%',

      '&:hover': {
        stroke: '#FFD2AA',
        border: '2px solid #FFD2AA'
      }
    },

    '&[data-index="2"]': {
      width: '8px',
      height: '30px',
      border: '1px solid #FFF',
      background: 'linear-gradient(180deg, #FFF 0%, rgba(255, 255, 255, 0.40) 100%)',
      boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.30), 0 4px 8px 3px rgba(0, 0, 0, 0.15)',
      borderRadius: '0 !important',

      '&:hover': {
        border: '1px solid rbga(255, 255, 255, 0.8)',
        boxShadow: 'none'
      }
    },

    '&[data-index="3"]': {
      display: 'none'
    },

    '&:hover': {
      boxShadow: '0 0 0 8px rgba(58, 133, 137, 0.16)'
    }
  },

  '& .MuiSlider-track': {
    height: 32,
    background: 'linear-gradient(90deg, #781A7E 0%, #8255B9 47.92%, #8E9AFF 100%)',
    borderRadius: 0,
    border: 'none'
  },

  '& .MuiSlider-rail': {
    color: '#1E1A1D',
    borderRadius: '2px',
    border: '1px solid #7C7579',
    opacity: 1,
    height: 32
  },

  '& .MuiSlider-mark': {
    display: 'none'
  },

  '& .MuiSlider-markLabel': {
    marginTop: '15px',
    color: '#D0C3CC',
    fontFamily: '"Quicksand", sans-serif',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '16px',
    letterSpacing: '0.5px'
  }
}))

export const SliderInactive = styled(MuiSlider)(() => ({
  color: '#3a8589',
  height: 32,
  padding: '9px 0',

  '& .MuiSlider-thumb': {
    height: 27,
    width: 27,
    borderRadius: '0',

    '.MuiSlider-valueLabel': {
      width: '200px',
      borderRadius: '8px',
      border: '1px solid #998D96',
      background: '#4D444B',
      boxShadow: '0 1px 2px 0 rgba(0, 0, 0, 0.30), 0 1px 3px 1px rgba(0, 0, 0, 0.15)',
      padding: '16px',

      '.tooltip-value': {
        '.MuiTypography-root': {
          color: '#FFF',
          fontFamily: '"Quicksand", sans-serif',
          fontWeight: 400,
          fontStyle: 'normal',
          textAlign: 'start',
          wordBreak: 'break-word',
          whiteSpace: 'pre-wrap'
        },

        '.tooltip-title': {
          fontSize: '24px',
          lineHeight: '32px',
          marginBottom: '3px'
        },

        '&.tooltip-text': {
          fontSize: '12px',
          lineHeight: '16px',
          letterSpacing: '0.4px'
        }
      }
    },

    '&[data-index="0"]': {
      display: 'none'
    },

    '&[data-index="1"]': {
      width: '8px',
      height: '30px',
      border: '1px solid #FFF',
      background: 'linear-gradient(180deg, #FFF 0%, rgba(255, 255, 255, 0.40) 100%)',
      boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.30), 0 4px 8px 3px rgba(0, 0, 0, 0.15)',
      borderRadius: '0 !important',

      '&:hover': {
        border: '1px solid rbga(255, 255, 255, 0.8)',
        boxShadow: 'none'
      }
    },

    '&[data-index="2"]': {
      display: 'none'
    },

    '&[data-index="3"]': {
      display: 'none'
    },

    '&:hover': {
      boxShadow: '0 0 0 8px rgba(58, 133, 137, 0.16)'
    }
  },

  '& .MuiSlider-track': {
    height: 32,
    background: 'linear-gradient(90deg, #781A7E 0%, #8255B9 47.92%, #8E9AFF 100%)',
    borderRadius: 0,
    border: 'none'
  },

  '& .MuiSlider-rail': {
    color: '#1E1A1D',
    borderRadius: '2px',
    border: '1px solid #7C7579',
    opacity: 1,
    height: 32
  },

  '& .MuiSlider-mark': {
    display: 'none'
  },

  '& .MuiSlider-markLabel': {
    marginTop: '15px',
    color: '#D0C3CC',
    fontFamily: '"Quicksand", sans-serif',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '16px',
    letterSpacing: '0.5px'
  }
}))
