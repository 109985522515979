import * as d3 from 'd3'

interface Props {
  svg:
    | d3.Selection<SVGSVGElement | null, unknown, null, undefined>
    | d3.Selection<SVGGElement, unknown, null, undefined>
  areaStroke: string
  pathStr: string
}

/*
 * Filled Area Stroke
 * */
export const appendFilledAreaStroke = ({ svg, areaStroke, pathStr }: Props) => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const existingFilledAreaStroke = svg.selectAll('.filled-area-stroke')
  if (!existingFilledAreaStroke.empty()) existingFilledAreaStroke.remove()

  svg
    .append('path')
    .attr('fill', 'none')
    .attr('stroke', `${areaStroke}`)
    .attr('d', `${pathStr}`)
    .attr('class', 'filled-area-stroke')
    .attr('stroke-width', 3)
    .attr('stroke-linejoin', 'bevel')
}
