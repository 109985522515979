import { FC } from 'react'
import { Box, Typography } from '@mui/material'
import styled from '@emotion/styled/macro'
import { ActiveCPIIcon, InactiveCpiLibraryIcon } from '../svg'
import ArrowUpTooltip from '../svg/ArrowUpTooltip'
import { FrameworksTooltip } from '../../pages/framework-configuration/components/FrameworksTooltip'

const Wrapper = styled(Box)<{ isactive: string; short?: string }>`
  width: fit-content;
  height: 32px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3), 0 1px 3px 1px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  padding: 6px 16px 6px 8px;
  gap: 8px;
  font-family: 'Quicksand', sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.1px;
  color: #ffffff;

  &:hover {
    cursor: pointer;
  }

  ${({ isactive }) =>
    isactive === 'true' &&
    `
      background: #3754fc;
  `};

  ${({ isactive }) =>
    isactive === 'false' &&
    `
      background: #4d444b;
  `};

  ${({ short }) =>
    short === 'true' &&
    `
      border-radius: 50%;
      width: 30px;
      height: 30px;
      padding: 6px;
  `};
`

interface Props {
  active: boolean
  short?: boolean
  sx?: Record<string, unknown>
}

const CpiActiveButton: FC<Props> = ({ active, short, sx }) => (
  <FrameworksTooltip
    title={
      <>
        <Box className="tooltip-wrapper">
          <ArrowUpTooltip />
          <Typography className="tooltip-text">CPI {active ? 'active' : 'inactive'}</Typography>
        </Box>
      </>
    }
  >
    <Wrapper sx={sx} isactive={active ? 'true' : 'false'} short={short ? 'true' : 'false'}>
      {active ? <ActiveCPIIcon /> : <InactiveCpiLibraryIcon />}
      {!short && <>{active ? 'Active' : 'Inactive'}</>}
    </Wrapper>
  </FrameworksTooltip>
)

export default CpiActiveButton
