import { Box, Tabs, Typography } from '@mui/material'
import styled from '@emotion/styled/macro'
import { TestStatus } from './SetupIntegrationInstance'

export const IntegrationsContainer = styled(Box)<{ hasPadding?: boolean }>`
  width: 100%;
  padding: ${({ hasPadding }) => (hasPadding ? '24px 16px' : '')};
  height: 100%;

  form {
    height: 100%;
    overflow-y: hidden;
  }
`

export const IntegrationsHeaderWrapper = styled(Box)`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-end;
  padding-left: 0;
  transition: width 0.5s;

  .header-inner {
    display: flex;
    flex-flow: column wrap;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    height: 100%;
    padding-top: 20px;

    > div {
      width: 100%;
    }
  }
`

export const DetailsOpenContainer = styled(Box)`
  display: flex;
  justify-content: center;
  padding-right: 16px;
  padding-left: 8px;
  width: 100%;
  margin-top: 24px;

  .left-side {
    padding: 0 4px 0 8px;
    max-width: 920px;
    width: 100%;
    overflow-y: auto;
    margin-right: 24px;

    ::-webkit-scrollbar {
      width: 4px;
      background: transparent;
    }

    ::-webkit-scrollbar-track {
      box-shadow: none;
      background: #4d444b;
    }

    ::-webkit-scrollbar-thumb {
      background-color: #ffa9fc;
      border-radius: 16px;
    }

    .MuiBox-root:first-of-type {
      height: auto;

      .header-inner {
        justify-content: flex-start;
      }
    }
  }
`

export const InstanceDetailsContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 558px;
  min-width: 558px;
  background: linear-gradient(0deg, rgba(255, 169, 252, 0.05), rgba(255, 169, 252, 0.05)), #1e1a1d;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.15), 0 1px 2px rgba(0, 0, 0, 0.3);
  padding: 24px;
  height: calc(100vh - 274px);
  overflow-y: auto;
  overflow-x: hidden;

  .analyzer-block {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-bottom: 10px;

    .title {
      font-family: 'Quicksand', sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0.5px;
      color: #fff;
    }
  }

  .right-side__title {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .right-side__title-wrapper {
      display: flex;
      flex-direction: column;
      width: 100%;

      .header-container {
        display: flex;
        align-items: center;
        width: 100%;

        .header-main {
          display: flex;
          align-items: center;
          flex-grow: 1;
          width: 100%;
        }
      }

      .title {
        font-family: 'Quicksand', sans-serif;
        font-weight: 400;
        font-size: 28px;
        line-height: 36px;
        color: #e9e0e4;
        margin-right: 21px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }

      .tooltip-wrapper {
        display: flex;
        align-items: center;
        gap: 16px;
        width: 100%;
        padding: 16px;
        background: rgba(255, 138, 128, 0.2);
        border: 1px solid #ff8a80;
        border-radius: 8px;
        margin-top: 16px;
        margin-bottom: 4px;

        .tooltip-icon {
          width: 24px;
          height: 24px;
          border-radius: 50%;
          align-self: flex-start;
        }

        img {
          margin-right: 8px;
          border-radius: 50%;
        }

        .text-container {
          display: flex;
          flex-direction: column;
          align-items: flex-start;

          .tooltip-title {
            color: #fff;
            font-family: 'Quicksand', sans-serif;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 24px;
            letter-spacing: 0.1px;
          }

          .description-container {
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 16px;

            .tooltip-text {
              color: #fff;
              font-family: 'Quicksand', sans-serif;
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 20px;
              letter-spacing: 0.25px;
            }
          }
        }
      }
    }
  }

  .subtitle,
  .body {
    font-family: 'Quicksand', sans-serif;
    font-weight: 500;
    font-size: 11px;
    line-height: 16px;
    letter-spacing: 0.5px;
    color: #d0c3cc;
    white-space: pre-line;
  }

  .subtitle {
    font-weight: bold;
    margin-top: 12px;
  }

  .right-side-box {
    background: linear-gradient(0deg, rgba(255, 169, 252, 0.11), rgba(255, 169, 252, 0.11)), #1e1a1d;
    box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.15), 0 1px 2px rgba(0, 0, 0, 0.3);
    border-radius: 16px;
    padding: 16px;
    margin-top: 12px;

    .box-title {
      font-family: 'Quicksand', sans-serif;
      font-weight: 500;
      font-size: 16px;
      font-style: normal;
      line-height: 24px;
      letter-spacing: 0.1px;
      margin-bottom: 4px;
      color: white;
    }

    .box-sub-title {
      font-family: 'Quicksand', sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0.25px;
      color: white;
      margin-bottom: 10px;
    }

    .box-description {
      font-family: 'Quicksand', sans-serif;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.25px;
      color: #e9e0e4;
      margin-top: 4px;
      white-space: pre-line;

      &.spacing-top {
        margin-top: 20px;
      }
    }
  }
`

export const IntegrationsTabsWrapper = styled(Box)<{ custom?: boolean }>`
  display: flex;
  justify-content: flex-start;
  margin: ${({ custom }) => (custom ? '16px 24px 0' : '16px 0 0')};
`

export const TabPanelWrapper = styled(Box)`
  .card {
    background: transparent;
    padding: 16px 16px 16px 0;
    margin-top: 24px;
    margin-bottom: 24px;
    border-bottom: 1px solid rgba(147, 143, 153, 0.12);
    margin-left: 24px;
    max-width: calc(100% - 465px);

    .data-source-wrapper {
      display: flex;
      align-items: center;
      margin-top: 22px;
      margin-bottom: 12px;
      margin-left: 16px;
    }

    .card-description {
      font-family: 'Quicksand', sans-serif;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.25px;
      color: #ffffff;
      padding-left: 16px;
    }

    .source-card-wrapper {
      display: flex;
      align-items: center;
      margin-top: 12px;
      margin-left: 16px;
    }

    .title {
      font-family: 'Quicksand', sans-serif;
      font-weight: 400;
      font-size: 22px;
      line-height: 28px;
      color: #e9e0e4;
      margin-bottom: 4px;
    }

    .description {
      font-family: 'Quicksand', sans-serif;
      font-weight: 400;
      color: #e9e0e4;
      letter-spacing: 0.25px;
      font-size: 14px;
      line-height: 20px;
    }

    img:not(:last-of-type) {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
      margin-top: 20px;
    }
  }

  .cpi-short-description {
    font-family: 'Quicksand', sans-serif;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.1px;
    color: #e9e0e4;
    margin: 8px auto 0;
    max-width: 1686px;
    padding-left: 24px;
  }
`

export const IntegrationsTabs = styled(Tabs)<{ isdetails?: string }>`
  background: transparent;
  height: auto;

  .MuiTabs-scroller {
    height: 50px !important;

    .MuiTabs-flexContainer {
      height: 50px !important;
    }
  }

  .MuiButtonBase-root {
    border-radius: 0;
    font-family: 'Quicksand', sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.5px;
    color: #debdd9;
    border: none;
    text-transform: none;
    padding: 0;
    width: 94px;
    height: 50px;
    min-height: 50px;
    margin-right: 40px;
    padding-bottom: 30px;

    &.Mui-selected {
      color: #fff;
      border-bottom: 4px solid #ffa9fc;
      padding-bottom: 38px;
      padding-top: 9px;
    }

    &.Mui-disabled {
      color: #debdd9;
    }
  }

  ${({ isdetails }) =>
    isdetails &&
    isdetails === 'true' &&
    `
    height: 50px;
    min-height: 50px;
    
    .MuiTabs-scroller {
      height: 50px;
      
      .MuiTabs-flexContainer {
        height: 50px;
      }
    }
    
    .MuiButtonBase-root {
      height: 50px;
      white-space: nowrap;
      width: fit-content;
    }
  `};
`

export const SelectorsWrapper = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 24px;
  width: 100%;

  .filters-wrapper {
    display: flex;
    align-items: center;
    gap: 64px;

    .filters-block {
      display: flex;
      align-items: center;
      gap: 24px;
    }
  }

  .sort-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 24px;

    @media (min-width: 1280px) {
      padding: 0;
    }

    .sort-description {
      font-family: 'Quicksand', sans-serif;
      font-weight: 500;
      font-size: 11px;
      line-height: 16px;
      letter-spacing: 0.5px;
      color: #d0c3cc;
      margin-right: 8px;
    }

    .results-text {
      color: #d0c3cc;
      font-family: Quicksand, sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px; /* 142.857% */
      letter-spacing: 0.1px;
      z-index: 10;
      white-space: nowrap;
    }
  }
`

export const IntegrationsListWrapper = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column wrap;
  max-width: 920px;
  width: 100%;
`

export const ErrorSpan = styled.span`
  font-size: 12px;
  line-height: 16px;
  font-weight: 700;
  color: #23053a;
`

export const ActionSpan = styled.span`
  display: inline;
  font-size: 12px;
  line-height: 18px;
  font-weight: 300;
  color: #23053a;
  text-decoration: underline;
  cursor: pointer;
`

export const EditIntegrationsTitle = styled(Typography)`
  font-family: 'Quicksand', sans-serif;
  font-weight: 400;
  font-size: 28px;
  line-height: 36px;
  color: #e9e0e4;
`

export const EditIntegrationContainer = styled(Box)<{ testStatus: TestStatus }>`
  display: flex;
  gap: 24px;
  margin-top: 14px;
  height: 100%;

  .left {
    width: 50%;
    flex-basis: 50%;
    overflow-y: auto;
    height: auto;
    max-height: calc(100vh - 84px);

    .info {
      display: flex;
      flex-flow: column wrap;
      padding: 24px 14px;
      background: linear-gradient(0deg, rgba(255, 169, 252, 0.11), rgba(255, 169, 252, 0.11)), #1e1a1d;
      border-radius: 12px;

      .MuiInputLabel-root {
        font-family: 'Quicksand', sans-serif;
        font-weight: 400;
        letter-spacing: 0.4px;
        color: #d0c3cc;
      }

      .MuiOutlinedInput-root {
        font-family: 'Quicksand', sans-serif;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.5px;
        color: #d0c3cc;
        margin-bottom: 24px;

        fieldset {
          //border: 1px solid #998d96;
          border-radius: 4px;
        }
      }

      .MuiInputAdornment-root {
        svg {
          path {
            fill: #debdd9;
          }
        }
      }

      .text-area {
        margin-bottom: 51px;
      }
    }

    .test {
      background: linear-gradient(0deg, rgba(255, 169, 252, 0.11), rgba(255, 169, 252, 0.11)), #1e1a1d;
      border-radius: 12px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 16px 0;
      padding: 16px;

      ${({ testStatus }) =>
        testStatus === TestStatus.successful &&
        `
            .MuiButtonBase-root {
              color: #7EBCA2 !important;    
            }
            
            svg {
              path {
                fill: #7EBCA2 !important;
              }
            }
        `};

      ${({ testStatus }) =>
        testStatus === TestStatus.failed &&
        `
            .MuiButtonBase-root {
              color: #FE455D !important;    
            }
            
            svg {
              path {
                fill: #FE455D !important;
              }
            }
        `};
    }

    .actions {
      background: linear-gradient(0deg, rgba(255, 169, 252, 0.11), rgba(255, 169, 252, 0.11)), #1e1a1d;
      border-radius: 12px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 16px 0 0 0;
      padding: 16px 16px 16px 8px;
    }
  }

  .right {
    flex-basis: 50%;
    background: linear-gradient(0deg, rgba(255, 169, 252, 0.05), rgba(255, 169, 252, 0.05)), #1e1a1d;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.15), 0 1px 2px rgba(0, 0, 0, 0.3);
    border-radius: 16px;
    padding: 18px 30px;
    display: flex;
    flex-flow: column;
    overflow-y: auto;
    height: auto;
    max-height: calc(100vh - 84px);

    ::-webkit-scrollbar {
      width: 4px;
      background: transparent;
    }

    ::-webkit-scrollbar-track {
      box-shadow: none;
      background: #4d444b;
    }

    ::-webkit-scrollbar-thumb {
      background-color: #ffa9fc;
      border-radius: 16px;
    }

    .instructions-text {
      font-family: 'Quicksand', sans-serif;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.1px;
      color: #e9e0e4;
    }

    .instructions-inner {
      background: linear-gradient(0deg, rgba(255, 169, 252, 0.11), rgba(255, 169, 252, 0.11)), #1e1a1d;
      box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.15), 0 1px 2px rgba(0, 0, 0, 0.3);
      border-radius: 16px;
      padding: 16px 32px;
      margin-top: 15px;
      height: 100%;
      flex: 1 1 auto; // fill up remaining space
      width: 100%;

      ol {
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0.25px;
        color: #ffffff;
        margin: 0;
        padding: 0 0 0 20px;
        line-break: auto;

        a {
          color: rgb(255, 169, 252);
        }

        li:not(:last-of-type) {
          margin-bottom: 16px;
        }

        li .MuiLink-root {
          color: #ffa9fc;
          text-decoration: underline;
          margin: 0;
          padding: 0;
          font-weight: 600;
        }

        li {
          ul {
            li {
              margin-bottom: 0 !important;
            }
          }
        }

        .tooltip-icon-padded {
          margin-left: 8px;
        }

        p {
          margin-top: 0 !important;
          margin-bottom: 0 !important;
        }
      }

      .MuiTypography-root {
        font-family: 'Quicksand', sans-serif;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.25px;
        color: #fbd8f5;
        margin-left: 4px;
      }

      .copy-text {
        font-family: 'IBM Plex Mono', monospace;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.25px;
        color: #ffa9fc;
        display: flex;
        align-items: center;
        margin-top: 8px;

        svg {
          margin-left: 4px;
        }
      }
    }
  }
`
