import { FC, SyntheticEvent, useState } from 'react'
import { AccordionSummary } from '@mui/material'
import { ExpandMore } from '@mui/icons-material'
import { ToastSuccessIcon, ToastErrorIcon } from '../../../svg'
import {
  SentinelAccordion,
  SentinelToastTitle,
  SentinelToastWrapper,
  SentinelToastMessage,
  SentinelAccordionDetails
} from './sentinelIntegration.styles'

interface Props {
  variant: 'success' | 'error'
  title: string
  message: string
  sx?: Record<string, unknown>
}

const SentinelIntegrationToast: FC<Props> = ({ variant, message, title, sx }) => {
  const [expanded, setExpanded] = useState<string | false>(false)

  const handleChange = (panel: string) => (event: SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false)
  }

  return (
    <SentinelToastWrapper success={variant === 'success'} sx={sx}>
      {variant === 'success' ? <ToastSuccessIcon /> : <ToastErrorIcon />}
      <SentinelAccordion
        success={variant === 'success'}
        expanded={expanded === 'panel1'}
        onChange={handleChange('panel1')}
        isopen={expanded}
      >
        <AccordionSummary expandIcon={<ExpandMore />} aria-controls="panel1-content" id="panel1-header">
          <SentinelToastTitle success={variant === 'success'}>{title}</SentinelToastTitle>
          {!expanded && <SentinelToastMessage success={variant === 'success'}>Click to expand</SentinelToastMessage>}
        </AccordionSummary>
        <SentinelAccordionDetails>
          <SentinelToastMessage success={variant === 'success'}>{message}</SentinelToastMessage>
        </SentinelAccordionDetails>
      </SentinelAccordion>
    </SentinelToastWrapper>
  )
}

export default SentinelIntegrationToast
