import { FC } from 'react'
import { useField } from 'formik'
import { TextFieldProps } from '@mui/material'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import { TextField } from './styles/controlledTextField.styles'

const theme = createTheme({
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        input: {
          '&:-webkit-autofill': {
            '-webkit-background-clip': 'text !important',
            '-webkit-text-fill-color': '#d0c3cc'
          }
        }
      }
    }
  }
})

type Props = TextFieldProps & {
  name: string
}

const ControlledTextField: FC<Props> = ({ name, ...rest }) => {
  const [field] = useField(name)

  return (
    <ThemeProvider theme={theme}>
      <TextField {...field} {...rest} />
    </ThemeProvider>
  )
}

export default ControlledTextField
