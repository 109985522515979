import { IntelFeedCardWrapper, StubIntelFeedBackground } from '../../../../dashboard.styles'
import { Card, CardContent, IconButton } from '@mui/material'
import { PlusIcon } from '../../../../../../components/svg'

const StubIntelFeedCard = () => (
  <IntelFeedCardWrapper read={false} expanded="false">
    <Card>
      <CardContent className="main-content loading">
        <StubIntelFeedBackground />
        <IconButton size="large" className="collapsed-icon">
          <PlusIcon />
        </IconButton>
      </CardContent>
    </Card>
  </IntelFeedCardWrapper>
)

export default StubIntelFeedCard
