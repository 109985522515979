import React from 'react'

const TableColumnArrow = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
    <path
      d="M13.4761 7.99996L12.5361 7.05996L8.80945 10.78V2.66663H7.47611V10.78L3.75611 7.05329L2.80945 7.99996L8.14278 13.3333L13.4761 7.99996Z"
      fill="#D9BFD4" />
  </svg>
)

export default TableColumnArrow
