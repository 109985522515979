import { Box } from '@mui/material'
import styled from '@emotion/styled/macro'

export const CategoryWrapper = styled(Box)<{ titlelength?: number }>`
  padding: 16px 12px 12px 16px;
  border-radius: 24px;
  background: linear-gradient(0deg, rgba(255, 169, 252, 0.08) 0%, rgba(255, 169, 252, 0.08) 100%), #1e1a1d;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.3), 0 1px 3px 1px rgba(0, 0, 0, 0.15);
  height: 234px;

  .card-wrapper-loading {
    display: flex;
    width: 100%;
    gap: 7px;

    .card-loading {
      border-radius: 24px;
      background: linear-gradient(0deg, rgba(255, 169, 252, 0.08) 0%, rgba(255, 169, 252, 0.08) 100%), #1e1a1d;
      flex-basis: 33.33%;
      width: 100%;
      height: 84px;
    }
  }

  .header-loading {
    display: flex;
    align-items: center;
    margin-bottom: 16px;

    .category-title-loading {
      font-family: 'Quicksand', sans-serif;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.1px;
      color: #fff;
      margin-left: 16px;
    }
  }

  .header {
    display: flex;
    align-items: flex-start;
    margin-bottom: 8px;
    flex-direction: column;

    ${({ titlelength }) =>
      titlelength &&
      titlelength > 24 &&
      `
        margin-bottom: 8px;
      `};

    .category-title {
      color: #fff;
      font-family: Quicksand, sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0.25px;

      &:hover {
        color: #fbd8f5;
        text-decoration-line: underline;
        cursor: pointer;
      }
    }

    .category-margin {
      margin-bottom: 26px;
    }

    .over-perform {
      display: flex;
      align-items: center;
      gap: 4px;
      margin-top: 6px;

      b {
        color: white;
        font-family: 'Quicksand', sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0.1px;
      }

      .value {
        color: white;
        font-family: 'Quicksand', sans-serif;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 16px;
        letter-spacing: 0.5px;
      }
    }

    .action {
      display: inline-flex;
      align-items: center;
      gap: 8px;
      cursor: pointer;

      .category-all {
        font-family: 'Quicksand', sans-serif;
        font-size: 11px;
        font-style: normal;
        font-weight: 500;
        line-height: 16px;
        letter-spacing: 0.5px;
        color: #ffa9fc;
      }
    }
  }

  .data-sources-wrapper {
    display: flex;
    align-items: center;
    margin-top: 12px;
    gap: 5px;

    img {
      border-radius: 50%;
    }

    ${({ titlelength }) =>
      titlelength &&
      titlelength > 24 &&
      `
          margin-top: 6px;
      `};

    .data-sources-text {
      color: #fff;
      font-family: Quicksand, sans-serif;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;
      letter-spacing: 0.4px;
    }

    .error-wrapper {
      border-radius: 50%;
      position: relative;
      cursor: pointer;
      border: 1px solid #ff8a80;
      box-sizing: content-box;

      svg {
        position: absolute;
        bottom: -2px;
        left: 5px;

        &.error-content {
          left: 7px;
          bottom: 0;
        }
      }
    }

    .data-sources-count-text {
      color: #d0c3cc;
      font-family: Quicksand, sans-serif;
      font-size: 11px;
      font-style: normal;
      font-weight: 500;
      line-height: 16px;
      letter-spacing: 0.5px;
    }
  }

  .available-status-sources-text {
    color: #d0c3cc;
    font-family: Quicksand, sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.4px;
    margin-top: 16px;
    opacity: 0.38;

    ${({ titlelength }) =>
      titlelength &&
      titlelength > 24 &&
      `
          margin-top: 6px;
      `};
  }
`

export const ExploreCategoryWrapper = styled(Box)`
  display: flex;

  .scale {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .scale-text {
      color: #fff;
      font-family: Quicksand, sans-serif;
      font-size: 11px;
      font-style: normal;
      font-weight: 500;
      line-height: 16px;
      letter-spacing: 0.5px;
      opacity: 0.38;
    }
  }

  .chart-button-wrapper {
    background-color: rgba(30, 26, 29, 0.38);
    height: 116px;
    width: 156px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #4d444b;
    border-radius: 4px;

    .MuiButtonBase-root {
      color: #debdd9;
      text-align: center;
      font-family: Quicksand, sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: 0.1px;
      text-transform: none;
      opacity: 1 !important;

      svg {
        margin-right: 8px;
      }
    }

    @media (min-width: 1920px) {
      width: 240px;
      height: 124px;
    }
  }
`

export const EmptyDataCategoryWrapper = styled(Box)`
  display: flex;

  .scale {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .scale-text {
      color: #fff;
      font-family: Quicksand, sans-serif;
      font-size: 11px;
      font-style: normal;
      font-weight: 500;
      line-height: 16px;
      letter-spacing: 0.5px;
    }
  }

  .chart-button-wrapper {
    background-color: #1e1a1d;
    height: 116px;
    width: 156px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #4d444b;
    border-radius: 4px;

    .no-data-text {
      color: #998d96;
      font-family: Quicksand, sans-serif;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 16px;
      letter-spacing: 0.5px;
    }

    .MuiButtonBase-root {
      color: #debdd9;
      text-align: center;
      font-family: Quicksand, sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: 0.1px;
      text-transform: none;

      svg {
        margin-right: 8px;
      }
    }

    @media (min-width: 1920px) {
      width: 240px;
      height: 124px;
    }
  }
`
