import { FC, Dispatch, SetStateAction } from 'react'
import { Box, IconButton, Typography } from '@mui/material'
import { tooltipClasses } from '@mui/material/Tooltip'
import CloseIcon from '@mui/icons-material/Close'

/* Utils */
import { FrameworkCategoryWeightItem, FrameworkType } from '../../../../../../../models'

/* Components */
import { FrameworksTooltip } from '../../../../components/FrameworksTooltip'
import ArrowUpTooltip from '../../../../../../components/svg/ArrowUpTooltip'

interface Props {
  framework: FrameworkType
  hasEditRole: boolean
  categories: FrameworkCategoryWeightItem[]
  categoryItem: FrameworkCategoryWeightItem
  setDiscardDisabled: Dispatch<SetStateAction<boolean>>
  handleDeleteCategory: (categoryId: string) => void
}

const RemoveCategory: FC<Props> = ({
  framework,
  categories,
  hasEditRole,
  categoryItem,
  setDiscardDisabled,
  handleDeleteCategory
}) => {
  if (hasEditRole && framework === FrameworkType.Custom) {
    if (categories.length <= 3) {
      return (
        <IconButton className="close-button is-disabled" onClick={(e) => e.preventDefault()}>
          <FrameworksTooltip
            title={
              <>
                <Box className="tooltip-wrapper">
                  <ArrowUpTooltip />
                  <Typography className="tooltip-text">Minimum 3 categories required</Typography>
                </Box>
              </>
            }
            sx={{
              [`& .${tooltipClasses.tooltip}`]: {
                width: 'fit-content !important'
              }
            }}
          >
            <CloseIcon />
          </FrameworksTooltip>
        </IconButton>
      )
    }
    return (
      <IconButton
        className="close-button"
        onClick={(e) => {
          e.preventDefault()
          e.stopPropagation()
          setDiscardDisabled(false)
          handleDeleteCategory(categoryItem.id)
        }}
      >
        <CloseIcon />
      </IconButton>
    )
  }

  return <></>
}

export default RemoveCategory
