import React, { FC, useEffect, useState } from 'react'
import { Route, Routes, useLocation } from 'react-router-dom'
import { AppBar, Box, Toolbar } from '@mui/material'
import { useAuth } from '@frontegg/react'

/* Utils */
import theme, { attributes } from '../theme'
import { UserInfo } from '../models'
import { useAppDispatch, useAppSelector } from '../store/hooks'
import { closeToast, toastPropsSelector } from '../store/toasts/toastSlice'
import { Permission } from '../lib/utils'

/* Components */
import Sidebar from './components/nav/Sidebar'
import Titlebar from './components/nav/Titlebar'
import Settings from './pages/settings'
import Integrations from './pages/settings/integrations/Integrations'
import SetupIntegrationInstance from './pages/settings/integrations/SetupIntegrationInstance'
import UserManagement from './pages/settings/user-management/UserManagement'
import AuditLog from './pages/settings/audit-log/AuditLog'
import Dashboard from './pages/dashboard'
import Library from './pages/library'
import SSM from './pages/security-stack-map/SSM'
import MainLayoutToast from './components/toast/MainLayoutToast'
import ProtectedRoute from './ProtectedRoute'
import AccountManagement from './pages/settings/account-management/AccountManagement'
import Profile from './pages/settings/profile/Profile'
import CoverageAnalyzer from './pages/coverage-analyzer'
import ZendeskSso from './pages/zendesk/ZendeskSSO'
import FrameworkConfigurationDeprecated from './pages/framework-configuration/FrameworkConfigurationDeprecated'
import FrameworkConfiguration from './pages/framework-configuration/FrameworkConfiguration'
import Framework from './pages/framework-configuration/Framework'
import CategoryPage from './pages/library/library-category'
import LibraryDetails from './pages/library/library-details'
import Reports from './pages/reports'
import CustomizeReport from './pages/reports/customize-report'
import MonthlyReportBuilder from './pages/reports/monthly-report-builder'
import QuarterlyReportBuilder from './pages/reports/quarterly-report-builder'
import OnyxAIInsights from './pages/onyxai-insights'
import ChooseCustomFramework from './pages/framework-configuration/sections/ChooseCustomFramework'

interface Props {
  user: UserInfo
  accessToken: string | null
}

export const MAIN_MENU_WIDTH = 72

const MainLayout: FC<Props> = ({ user, accessToken }) => {
  const location = useLocation()
  const { user: authUser } = useAuth()
  const dispatch = useAppDispatch()
  const toast = useAppSelector(toastPropsSelector)
  const [showLayout, setShowLayout] = useState(false)

  useEffect(() => {
    setShowLayout(!location.pathname.includes('/create-password'))

    if (!location.pathname.includes('/library/cpis') && localStorage.getItem('tempFilters')) {
      localStorage.removeItem('tempFilters')
    }
  }, [location.pathname])

  const showAppBar =
    location.pathname !== '/' &&
    !location.pathname.includes('/framework-configuration') &&
    !location.pathname.includes('/library') &&
    location.pathname !== '/settings/user-management' &&
    location.pathname !== '/settings/profile' &&
    location.pathname !== '/settings/audit-log' &&
    location.pathname !== '/settings/account-management' &&
    location.pathname !== '/framework-configuration' &&
    !location.pathname.includes('/settings/integrations') &&
    !location.pathname.includes('/security-stack-map') &&
    location.pathname !== '/asset-coverage-analyzer' &&
    location.pathname !== '/onyxai-insights' &&
    !location.pathname.includes('/reports')

  const getPadding = () => {
    return '0px 0 8px 0'
  }

  const getMaxWidth = () => {
    return `calc(100% - ${MAIN_MENU_WIDTH}px)`
  }

  if (!accessToken || !user) return null
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        background: '#1e1a1d',
        padding: 0
      }}
    >
      {toast.props && toast.open && (
        <MainLayoutToast
          variant={toast.variant}
          text={toast.props.text}
          description={toast.props.description}
          handleClose={() => {
            dispatch(closeToast())
          }}
        />
      )}
      {showLayout && (
        <>
          {showAppBar && (
            <AppBar
              position="fixed"
              sx={{
                width: `calc(100% - ${MAIN_MENU_WIDTH}px)`,
                ml: `${MAIN_MENU_WIDTH}px`,
                boxShadow: attributes.boxShadow.toolbar,
                backgroundColor: `${theme.colorTokens.surface.pageBackground}`
              }}
            >
              <Titlebar />
            </AppBar>
          )}
          <Sidebar />
          <Box
            component="main"
            sx={{
              flexGrow: 1,
              marginLeft: `${MAIN_MENU_WIDTH}px`,
              background:
                location.pathname === '/'
                  ? theme.colorTokens.surface.pageBackground
                  : location.pathname.includes('/security-stack-map')
                  ? theme.colorTokens.text.textInvert
                  : location.pathname === '/asset-coverage-analyzer'
                  ? theme.colorTokens.surface.pageBackground
                  : 'linear-gradient(0deg, rgba(255, 169, 252, 0.05) 0%, rgba(255, 169, 252, 0.05) 100%), #1E1A1D',
              bgcolor:
                location.pathname === '/framework-configuration'
                  ? '#131013'
                  : location.pathname === '/'
                  ? theme.colorTokens.surface.pageBackground
                  : location.pathname.includes('user-management')
                  ? 'rgba(30, 26, 29, 1)'
                  : location.pathname.includes('/library') || location.pathname.includes('/security-stack-map')
                  ? 'unset'
                  : location.pathname === '/asset-coverage-analyzer'
                  ? theme.colorTokens.surface.pageBackground
                  : '#131013',
              maxWidth: getMaxWidth(),
              height:
                location.pathname === '/'
                  ? 'auto'
                  : location.pathname.includes('/settings/integrations/') &&
                    (location.pathname.includes('/add') || location.pathname.includes('/edit'))
                  ? '100vh'
                  : 'auto',
              minHeight:
                location.pathname.includes('/library') ||
                location.pathname.includes('/settings/integrations') ||
                location.pathname.includes('user-management') ||
                location.pathname.includes('/settings/audit-log') ||
                location.pathname.includes('/settings/account-management') ||
                location.pathname.includes('/framework-configuration') ||
                location.pathname === '/asset-coverage-analyzer' ||
                location.pathname === '/onyxai-insights' ||
                location.pathname.includes('/reports')
                  ? '100vh'
                  : location.pathname === '/'
                  ? 'calc(100vh - 8px)'
                  : undefined,
              borderRadius:
                location.pathname === '/'
                  ? '8px'
                  : location.pathname.includes('/library')
                  ? '16px 0px 0px 16px'
                  : 'unset',
              padding:
                location.pathname === '/'
                  ? getPadding()
                  : location.pathname.includes('/library') ||
                    location.pathname === '/settings/account-management' ||
                    location.pathname.includes('/security-stack-map')
                  ? '0'
                  : location.pathname === '/asset-coverage-analyzer' ||
                    location.pathname === '/onyxai-insights' ||
                    location.pathname.includes('/reports') ||
                    location.pathname === '/framework-configuration'
                  ? '0'
                  : '0px 0px 24px 0px',
              justifyContent: location.pathname === '/' ? 'center' : 'flex-start',
              display: location.pathname === '/' ? 'flex' : 'block'
            }}
          >
            {showAppBar && <Toolbar />}
            <Routes>
              <Route
                path="/library"
                element={
                  <ProtectedRoute user={authUser} permission={Permission.CPILibraryView}>
                    <Library />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/asset-coverage-analyzer"
                element={
                  <ProtectedRoute user={authUser} permission={'all'}>
                    <CoverageAnalyzer />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/onyxai-insights"
                element={
                  <ProtectedRoute user={authUser} permission={'all'}>
                    <OnyxAIInsights />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/reports"
                element={
                  <ProtectedRoute user={authUser} permission={'all'}>
                    <Reports />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/reports/customize-cpi-report"
                element={
                  <ProtectedRoute user={authUser} permission={'all'}>
                    <CustomizeReport />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/reports/customize-quarterly-report"
                element={
                  <ProtectedRoute user={authUser} permission={'all'}>
                    <QuarterlyReportBuilder />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/reports/customize-monthly-report"
                element={
                  <ProtectedRoute user={authUser} permission={'all'}>
                    <MonthlyReportBuilder />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/library/cpis/:cpiName"
                element={
                  <ProtectedRoute user={authUser} permission={Permission.CPIConfigView}>
                    <LibraryDetails />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/library/categories/:categoryName"
                element={
                  <ProtectedRoute user={authUser} permission={Permission.CPIConfigView}>
                    <CategoryPage />
                  </ProtectedRoute>
                }
              />
              <Route path="/settings" element={<Settings />} />
              <Route
                path="/settings/integrations"
                element={
                  <ProtectedRoute user={authUser} permission={Permission.ConfigIntegrationsConfigView}>
                    <Integrations />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/settings/integrations/:integrationName/:instanceId/edit"
                element={
                  <ProtectedRoute user={authUser} permission={Permission.ConfigIntegrationsConfigView}>
                    <SetupIntegrationInstance setupType="edit" />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/settings/integrations/:integrationName/add"
                element={
                  <ProtectedRoute user={authUser} permission={Permission.ConfigIntegrationsConfigView}>
                    <SetupIntegrationInstance setupType="add" />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/settings/user-management"
                element={
                  <ProtectedRoute user={authUser} permission={Permission.UsersProfileView}>
                    <UserManagement />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/settings/profile"
                element={
                  <ProtectedRoute user={authUser} permission={'all'}>
                    <Profile />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/settings/account-management"
                element={
                  <ProtectedRoute user={authUser} permission={Permission.AccountManagementView}>
                    <AccountManagement />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/security-stack-map"
                element={
                  <ProtectedRoute user={authUser} permission="all">
                    <SSM />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/settings/audit-log"
                element={
                  <ProtectedRoute user={authUser} permission={Permission.AuditView}>
                    <AuditLog />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/framework-configurationv2"
                element={
                  <ProtectedRoute user={authUser} permission="all">
                    <FrameworkConfigurationDeprecated />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/framework-configuration"
                element={
                  <ProtectedRoute user={authUser} permission="all">
                    <Framework />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/framework-configuration/:frameworkName"
                element={
                  <ProtectedRoute user={authUser} permission="all">
                    <FrameworkConfiguration />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/framework-configuration/custom/create"
                element={
                  <ProtectedRoute user={authUser} permission="all">
                    <ChooseCustomFramework />
                  </ProtectedRoute>
                }
              />
              <Route path="/zendesk/sso" element={<ZendeskSso />} />
              <Route path="/" element={<Dashboard />} />
            </Routes>
          </Box>
        </>
      )}
    </Box>
  )
}

export default MainLayout
