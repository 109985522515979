import * as Yup from 'yup'

export interface FormValues {
  baseUrl: string
  secret: string
  clientId: string
}

export const defaultValues = {
  baseUrl: '',
  secret: '',
  clientId: ''
}

export const schema = Yup.object({
  baseUrl: Yup.string().required('Required'),
  secret: Yup.string().required('Required'),
  clientId: Yup.string().required('Required')
})

export const FILE_NAME = 'Crowdstrike Integration.pdf'
