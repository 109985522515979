import { FC } from 'react'
import { Box, Typography } from '@mui/material'

import SentinelInstructions from './SentinelInstructions'
import SentinelIntegrationToast from './SentinelIntegrationToast'
import { TestConfigurationIconButton } from '../../../svg'
import { SentinelIntegrationStep2Wrapper, SentinelIntegrationStep2Button } from './sentinelIntegration.styles'

interface Props {
  setupSuccessful: boolean
  toastMessage: string
  showToast: boolean
}

const SentinelIntegrationStep3: FC<Props> = ({ setupSuccessful, toastMessage, showToast }) => (
  <SentinelIntegrationStep2Wrapper>
    <SentinelInstructions
      content={<Typography>Click Test Configuration button to the right to confirm access.</Typography>}
      variant="step1"
      hideTooltip
    />
    <Box sx={{ marginLeft: '72px' }}>
      <SentinelIntegrationStep2Button type="submit">
        <TestConfigurationIconButton />
        Test Configuration
      </SentinelIntegrationStep2Button>
      {showToast && (
        <SentinelIntegrationToast
          variant={setupSuccessful ? 'success' : 'error'}
          title={setupSuccessful ? 'The test was successful' : 'Failed to Authenticate'}
          message={toastMessage}
        />
      )}
    </Box>
  </SentinelIntegrationStep2Wrapper>
)

export default SentinelIntegrationStep3
