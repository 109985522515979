import { styled } from '@mui/material/styles'
import { tooltipClasses, TooltipProps } from '@mui/material/Tooltip'
import { Tooltip } from '@mui/material'

export const SSOTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.arrow}`]: {
    color: '#FFA9FC'
  },
  [`& .${tooltipClasses.tooltip}`]: {
    fontFamily: '"Quicksand", sans-serif',
    fontSize: '14px',
    lineHeight: '20px',
    fontWeight: 500,
    color: '#590060',
    letterSpacing: '0.1px',
    borderRadius: '8px',
    boxShadow: '0 8px 12px 6px rgba(0, 0, 0, 0.15)',
    filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.3))',
    background: '#FFA9FC',
    width: '176px'
  }
})
