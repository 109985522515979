import * as d3 from 'd3'

interface Props {
  svg: d3.Selection<SVGSVGElement | null, unknown, null, undefined>
  width: number
  height: number
  chartHeightStart: number
}

/*
 * Cartesian Grid Horizontal Line
 * */
export const appendCartesianGridHorizontalOC = ({ svg, width, height, chartHeightStart }: Props) => {
  svg
    .selectAll('.cartesian-grid-horizontal')
    .data([0, 1, 2, 3, 4, 5])
    .enter()
    .append('line')
    .attr('class', 'cartesian-grid-horizontal')
    .attr('x1', 0)
    .attr('x2', width)
    .attr('y1', (d) => (d === 0 ? chartHeightStart : d * (height / 5)))
    .attr('y2', (d) => (d === 0 ? chartHeightStart : d * (height / 5)))
    .attr('stroke', 'rgba(147, 143, 153, 0.16)')
    .attr('stroke-width', '1px')
}
