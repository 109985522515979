import { Box } from '@mui/material'
import styled from '@emotion/styled/macro'

export const CategoriesWrapper = styled(Box)`
  width: 100%;
  flex-basis: 50%;
  margin-right: 13.05px;
`

export const CategoriesContainer = styled(Box)`
  width: 100%;
  flex-basis: 50%;
  gap: 12px;
  display: flex;
  flex-direction: column;
`

export const CategoriesSummary = styled(Box)`
  gap: 16px;
  display: flex;
  flex-direction: column;

  .categories-summary-header {
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .header-text {
      color: #fff;
      font-family: Quicksand, sans-serif;
      font-size: 24px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
`

export const SummaryInner = styled(Box)`
  padding: 24px;
  border-radius: 16px;
  background: #372a36;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 241px;
  gap: 12px;

  .inner-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    height: 100%;

    .inner-text {
      color: #fff;
      font-family: Quicksand, sans-serif;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }

    .inner-content-actions {
      height: 100%;
      display: flex;
      align-items: flex-end;
      gap: 24px;
      flex-flow: row wrap;
      row-gap: 0;
    }
  }
`

export const CategoriesWeight = styled(Box)<{ haseditrole?: string }>`
  border-radius: 16px;
  background: transparent;
  margin: 16px 0 4px;
  gap: 16px;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;

  .categories-weight-header {
    background: #292128;
    border-radius: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 43px;
    padding: 12px 24px;

    > .MuiTypography-root {
      color: #fff;
      font-family: Quicksand, sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      width: fit-content;

      &:last-of-type {
        margin-right: ${({ haseditrole }) => (haseditrole && haseditrole === 'true' ? '80px' : '24px')};
      }
    }
  }
`

export const CategoryWeightList = styled(Box)`
  height: calc(100vh - 496px);
  overflow-y: auto;
  gap: 16px;
  display: flex;
  flex-direction: column;
  padding-right: 36px;
  padding-bottom: 40px;
  width: calc(100% + 36px);

  &.has-scrollbar {
    width: calc(100% + 48px);
  }
`

export const CategorySkeletonWrapper = styled(Box)`
  gap: 12px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: stretch;
  flex-direction: column;
`

export const CategorySkeleton = styled(Box)`
  width: 100%;
  flex-grow: 1;
  height: 72px !important;
  min-height: 72px !important;
  padding: 12px 24px;
  border-radius: 16px;
  background: #292128;
  flex-basis: 100%;
  display: flex;
  align-items: center;
  gap: 16px;

  .card-skeleton-inner {
    width: 100%;
    height: 100%;
    min-height: 48px;
    background-color: #372a36;
    border-radius: 16px;

    &:after {
      background: linear-gradient(
        90deg,
        rgba(255, 255, 255, 0) 15.32%,
        rgba(255, 255, 255, 0.35) 49.89%,
        rgba(255, 255, 255, 0) 83.78%
      );
    }
  }

  &.category-skeleton-cpi-drawer {
    height: 105px !important;
    min-height: 105px !important;

    .card-skeleton-inner {
      min-height: 68px;
    }
  }
`
