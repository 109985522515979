import { styled } from '@mui/material/styles'
import { tooltipClasses, TooltipProps } from '@mui/material/Tooltip'
import { Tooltip } from '@mui/material'

export const DataSourceErrorTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    display: 'flex',
    marginTop: '0 !important',
    padding: '16px',
    flexDirection: 'column',
    alignItems: 'flex-start',
    borderRadius: '16px',
    border: '1px solid #998D96',
    background: '#4E4E4E',
    minWidth: '253px'
  },
  '.tooltip-wrapper': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
    gap: '8px',
    width: '100%',

    '.instance-name-container': {
      display: 'flex',
      alignItems: 'center',
      gap: '8px',

      img: {
        width: '24px',
        height: '24px',
        borderRadius: '50%'
      },

      '.instance-name': {
        color: '#FFF',
        fontFamily: '"Quicksand", sans-serif',
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: 590,
        lineHeight: 'normal'
      }
    },

    '.error-description-container': {
      display: 'flex',
      alignItems: 'center',
      gap: '8px',

      '.error-icon': {
        width: '24px',
        height: '24px',
        borderRadius: '50%'
      },

      '.error-text': {
        color: '#FF8A80',
        fontFamily: '"Quicksand", sans-serif',
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: 'normal'
      }
    }
  }
})

export const linkSx = {
  whiteSpace: 'nowrap',
  fontFamily: '"Quicksand", sans-serif',
  fontSize: '14px',
  fontWeight: 400,
  lineHeight: 'normal',
  textDecoration: 'underline',
  cursor: 'pointer',
  color: '#FFF',
  alignSelf: 'flex-start',
  marginTop: '8px'
}
