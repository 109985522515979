import { Box, FormGroup } from '@mui/material'
import styled from '@emotion/styled/macro'

export const Container = styled(Box)`
  width: 100%;
`

export const CustomizerContainer = styled(Box)`
  width: 100%;
`

export const Wrapper = styled(Box)`
  width: 100%;
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: -64px; /* Offset the sidebar */
`

export const FormWrapper = styled(Box)`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 24px 0;
  gap: 24px;
`

export const FormItemWrapper = styled(Box)`
  border-radius: 16px;
  background: linear-gradient(0deg, rgba(255, 169, 252, 0.08) 0%, rgba(255, 169, 252, 0.08) 100%), #1e1a1d;
  padding: 16px 24px 24px 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  flex-shrink: 0;
  gap: 8px;
  width: 504px;
  min-height: 128px;

  .title {
    color: #e9e0e4;
    font-family: Quicksand, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.5px;
  }
`

export const MonthPickerWrapper = styled(Box)<{ isopen: string }>`
  display: none;
  align-items: center;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.3), 0 8px 12px 6px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  background: #573f56;
  width: 456px;
  margin-top: -8px;

  ${({ isopen }) =>
    isopen === 'true' &&
    `
      display: flex;
    `};

  .month-calendar-container {
    padding-top: 16px;

    &:first-of-type {
      border-right: 1px solid #6c586a;
    }

    .month-picker-monthly-title {
      color: #d0c3cc;
      text-align: center;
      font-family: Quicksand, sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: 0.1px;
    }

    .MuiMonthCalendar-root {
      width: 228px;

      .MuiPickersMonth-root {
        .MuiPickersMonth-monthButton {
          width: 40px;
          height: 40px;
          color: #fbd8f5;
          text-align: center;
          font-family: Quicksand, sans-serif;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;
          letter-spacing: 0.25px;

          &.Mui-selected {
            border-radius: 50%;
            color: #590060;
            background: #ffa9fc;
          }

          &.Mui-disabled {
            color: #d0c3cc;
          }
        }
      }

      .MuiPickersCalendarHeader-root {
        div {
          display: flex;
          justify-content: center;
          width: 100%;
        }

        .MuiPickersArrowSwitcher-root {
          display: none;
        }
      }
    }
  }
`

export const ActionsWrapper = styled(Box)`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  align-items: center;
  max-width: 504px;

  .buttons-container {
    display: flex;
    flex-direction: row;
  }
`

export const FormItemInfoWrapper = styled(Box)`
  display: flex;
  padding: 13px 16px;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 8px;
  border-radius: 8px;
  border: 1px solid #8e9aff;
  background: rgba(142, 154, 255, 0.4);
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.3), 0 2px 6px 2px rgba(0, 0, 0, 0.15);

  .icon-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
  }

  .info-text {
    margin-left: 16px;
    color: #e9e0e4;
    font-family: Quicksand, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.25px;
  }
`

export const SelectCpisWrapper = styled(Box)`
  display: flex;
  justify-content: center;
  flex-direction: column;

  .controls {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-top: 2px;
    margin-bottom: 8px;

    .MuiButtonBase-root {
      padding: 10px 12px !important;
      border-radius: 0 !important;

      &:first-of-type {
        margin-left: -10px !important;
      }
    }
  }
`

export const CpisList = styled(FormGroup)`
  .MuiFormControlLabel-root {
    .MuiCheckbox-root {
      svg {
        path {
          fill: #d0c3cc;
        }
      }
    }
  }

  .label-wrapper {
    display: flex;
    align-items: center;

    .label-title {
      color: #e9e0e4;
      font-family: Quicksand, sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0.5px;
      margin-left: 16px;

      &.no-margin {
        margin-left: 0;
      }
    }
  }
`
