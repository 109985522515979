import { FC, useEffect, ChangeEvent, Dispatch, SetStateAction, useMemo, useState, useRef } from 'react'

/* Utils */
import { FrameworkCategoryWeightItem, FrameworkType, NistFunction } from '../../../../../../models'

/* Components */
import { CategoryWeightItemContainer } from '../categoryWeightItem.styles'
import { ErrorIndicator, CategoryName, CategoryWeight, CategoryColor, RemoveCategory } from './components'

interface Props {
  categoryItem: FrameworkCategoryWeightItem
  selected: boolean
  handleDeleteCategory: (categoryId: string) => void
  framework: FrameworkType
  thisPath: string
  hasEditRole: boolean
  categories: FrameworkCategoryWeightItem[]
  setDiscardDisabled: Dispatch<SetStateAction<boolean>>
  cpisInvalidWeight: boolean
  categoryItemsPristine: FrameworkCategoryWeightItem[]
  setEdited: Dispatch<SetStateAction<boolean>>
  setSelectedCategoryItem: Dispatch<SetStateAction<FrameworkCategoryWeightItem | undefined>>
  categoriesForm: any
  setCategoriesForm: any
}

const CategoryWeightItem: FC<Props> = ({
  categoryItem,
  framework,
  selected,
  setSelectedCategoryItem,
  handleDeleteCategory,
  thisPath,
  hasEditRole,
  categories,
  setDiscardDisabled,
  cpisInvalidWeight,
  categoryItemsPristine,
  setEdited,
  categoriesForm,
  setCategoriesForm
}) => {
  const weightInputRef = useRef(null)
  const [itemDeleted, setItemDeleted] = useState(false)
  const [duplicateName, setDuplicateName] = useState(false)

  useEffect(() => {
    if (categoryItem) {
      const cpis = categoryItem.cpis

      if (cpis.length > 0) {
        setItemDeleted((categoryItem as any).lastAction === 'delete')
      }
    }
  }, [categoryItem])

  const nistFn = useMemo(
    () =>
      !categoryItem.name
        ? ''
        : categoryItem.name.includes('Identify')
        ? NistFunction.Identify
        : categoryItem.name.includes('Respond')
        ? NistFunction.Respond
        : categoryItem.name.includes('Detect')
        ? NistFunction.Detect
        : categoryItem.name.includes('Protect')
        ? NistFunction.Protect
        : categoryItem.name.includes('Recover')
        ? NistFunction.Recover
        : categoryItem.name.includes('Govern')
        ? NistFunction.Govern
        : '',
    [categoryItem]
  )

  const handleChange = (e: ChangeEvent<HTMLInputElement>, field: 'weight' | 'category') => {
    const updatedCategories = categories.map((categoryItem: any) => {
      if (field === 'weight') {
        if (categoryItem.name === e.target.name) {
          return {
            ...categoryItem,
            weight: +e.target.value,
            isEdit: true
          }
        }
      } else {
        if (categoryItem.id === e.target.name) {
          return {
            ...categoryItem,
            name: e.target.value,
            isEdit: true
          }
        }
      }

      return categoryItem
    })

    const categoriesWithoutThis = categories.filter((cat) => cat.id !== categoryItem.id)
    const existingCategoriesNames = categoriesWithoutThis.map((c) => c.name)

    if (!existingCategoriesNames.includes(e.target.value)) {
      setDuplicateName(false)
    }

    const updatedForm = {
      ...categoriesForm,
      categories: updatedCategories
    }

    setCategoriesForm(updatedForm)
  }

  const handleBlur = (e: any) => {
    const updatedCategories = categories.map((categoryItem: any) => {
      if (categoryItem.id === e.target.name) {
        return {
          ...categoryItem,
          name: e.target.value.trim(),
          isEdit: true
        }
      }

      return categoryItem
    })

    const updatedForm = {
      ...categoriesForm,
      categories: updatedCategories
    }

    setCategoriesForm(updatedForm)
  }

  const categoryItemIsInvalid = useMemo(() => {
    const categoriesWithoutThis = categories.filter((cat) => cat.id !== categoryItem.id)
    const existingCategoriesNames = categoriesWithoutThis.map((c) => c.name)
    if (!existingCategoriesNames.includes(categoryItem.name)) {
      setDuplicateName(false)
    }
    if (existingCategoriesNames.includes(categoryItem.name)
      // && categoryItem.id.includes('placeholder-id-')
    ) {
      setDuplicateName(true)
      return true
    }

    if (categoryItem.cpis.every((n) => !n.isActive) && (categoryItem.weight === 0 || !categoryItem.weight)) return false

    const thisPristineCategory = categoryItemsPristine.find((n) => n.name === categoryItem.name)
    if (thisPristineCategory) {
      const pristineActiveCpis = thisPristineCategory.cpis.filter((n) => n.isActive).map((c) => c.displayName)
      const categoryItemCpis = categoryItem.cpis.filter((n) => n.isActive)
      const cpiSum = categoryItemCpis.reduce((acc, obj) => acc + obj.weight, 0)

      if (pristineActiveCpis.length !== categoryItemCpis.length && cpiSum !== 100) {
        setItemDeleted(true)
        return true
      }
    }

    return cpisInvalidWeight
  }, [cpisInvalidWeight, duplicateName, categoryItem])

  const textFieldClassName = useMemo(
    () =>
      String(categoryItem.weight).length === 3
        ? 'length3'
        : String(categoryItem.weight).length === 2
        ? 'length2'
        : 'length1',
    [categoryItem]
  )

  return (
    <CategoryWeightItemContainer
      className="category-weight-item-container"
      selected={selected}
      onClick={() => {
        setSelectedCategoryItem(categoryItem)
      }}
      invalidcpisweight={categoryItemIsInvalid ? 'true' : 'false'}
    >
      {(categoryItemIsInvalid || !categoryItem.name || duplicateName) && (
        <ErrorIndicator itemDeleted={itemDeleted} badName={!categoryItem.name} duplicateName={duplicateName} />
      )}
      <CategoryColor framework={framework} nistFn={nistFn} categoryItem={categoryItem} thisPath={thisPath} />
      <CategoryName
        framework={framework}
        hasEditRole={hasEditRole}
        categoryItem={categoryItem}
        handleChange={handleChange}
        setDiscardDisabled={setDiscardDisabled}
        setEdited={setEdited}
        handleBlur={handleBlur}
      />
      <CategoryWeight
        ref={weightInputRef}
        textFieldClassName={textFieldClassName}
        hasEditRole={hasEditRole}
        categoryItem={categoryItem}
        handleChange={handleChange}
        setDiscardDisabled={setDiscardDisabled}
        setEdited={setEdited}
      />
      <RemoveCategory
        framework={framework}
        hasEditRole={hasEditRole}
        categoryItem={categoryItem}
        categories={categories}
        setDiscardDisabled={setDiscardDisabled}
        handleDeleteCategory={handleDeleteCategory}
      />
    </CategoryWeightItemContainer>
  )
}

export default CategoryWeightItem
