import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Task } from '../../models'
import { RootState } from '../store'

export interface JiraState {
  tasks: Task[]
  loading: boolean
  pageSize: number
  currentPage: number
  totalTasks: number
  showDeleted: boolean
  previousSearches: string[]
}

const prevSearches = window.localStorage.getItem('previousSearches')

const initialState: JiraState = {
  tasks: [],
  loading: false,
  pageSize: 10,
  currentPage: 1,
  totalTasks: -1,
  showDeleted: false,
  previousSearches: typeof window !== 'undefined' && prevSearches ? JSON.parse(prevSearches) : []
}

export const jiraSlice = createSlice({
  name: 'jira',
  initialState,
  reducers: {
    tasksLoaded: (state: JiraState, action: PayloadAction<{ tasks: Task[]; page: number }>) => {
      state.tasks = action.payload.tasks
      state.currentPage = action.payload.page
    },
    tasksAreLoading: (state: JiraState, action: PayloadAction<boolean>) => {
      state.loading = action.payload
    },
    totalTasksChanged: (state: JiraState, action: PayloadAction<number>) => {
      state.totalTasks = action.payload
    },
    toggleShowDeleted: (state: JiraState, action: PayloadAction<boolean>) => {
      state.showDeleted = action.payload
    },
    setPreviousSearches: (state: JiraState, action: PayloadAction<string[]>) => {
      state.previousSearches = action.payload
    }
  }
})

export const jiraShowDeleted = (state: RootState): boolean => state.jira.showDeleted
export const jiraTasksSelector = (state: RootState): Task[] => state.jira.tasks
export const jiraLoadingSelector = (state: RootState): boolean => state.jira.loading
export const jiraPaginationSelector = (state: RootState) => [
  state.jira.currentPage,
  state.jira.pageSize,
  state.jira.totalTasks
]
export const jiraPreviousSearchesSelector = (state: RootState): string[] => state.jira.previousSearches

export const { tasksLoaded, tasksAreLoading, totalTasksChanged, toggleShowDeleted, setPreviousSearches } =
  jiraSlice.actions

export default jiraSlice.reducer
