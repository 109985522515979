import React from 'react'
import { Box, Typography } from '@mui/material'
import { Content, Wrapper } from './emptyData.styles'
import { ThemeButton } from '../../../../components/buttons'
import { PlusIcon } from '../../../../components/svg'
import { useLocation, useNavigate } from 'react-router-dom'

const EmptyData = () => {
  const navigate = useNavigate()
  const location = useLocation()

  const activateCpi = () => {
    navigate(`/library`,{
      state: {
        navigatedCategory: location.pathname.split('/')[3],
      }
    })
  }

  return (
    <Wrapper>
      <Typography className="title">Benchmarking Score</Typography>

      <Content>
        <Typography className="title">No Data Available</Typography>
        <Typography className="description">
          You currently don’t have any CPIs with benchmark data activated. Please browse the CPI Library and activate
          CPIs with benchmark data for this category.{' '}
        </Typography>
        <Box className="action-wrapper">
          <ThemeButton type="button" onClick={() => activateCpi()} sx={{ minWidth: '80px' }}>
            <>
              <PlusIcon />
              Activate CPIs
            </>
          </ThemeButton>
        </Box>
      </Content>
    </Wrapper>
  )
}

export default EmptyData
