import { FC } from 'react'
import { Box, Typography } from '@mui/material'
import { IntegrationInstance } from '../../../../../models'

const getIntegrationLogo = (name: string) => {
  if (name) {
    return `/icons/circle/${name}_icon.svg`
  }

  return '/icons/circle/crowdstrike_falcon_icon.svg'
}

interface Props {
  instance: IntegrationInstance | undefined
  text: string
}

const RowDataItem: FC<Props> = ({ instance, text }) => (
  <Box className="data-item">
    <img
      src={getIntegrationLogo(instance?.integration_name || '')}
      alt=""
      width="24px !important"
      height="24px !important"
      style={{ minWidth: 'unset !important' }}
    />
    <Typography className="name">{text}</Typography>
  </Box>
)

export default RowDataItem
