export const baseColors = {
  primary: {
    10: '#37003b',
    20: '#590060',
    30: '#781A7E',
    40: '#943798',
    50: '#B051B3',
    60: '#CE6BCF',
    70: '#EC85EC',
    80: '#FFA9FC',
    90: '#FFD6F9',
    95: '#FFEBF9',
    100: '#FFFBFF'
  },
  secondary: {
    10: '#261625',
    20: '#3C2B3B',
    30: '#544152',
    40: '#6C586A',
    50: '#867083',
    60: '#A18A9D',
    70: '#BCA4B8',
    80: '#D9BFD4',
    90: '#F6DBF0',
    95: '#FFEBF9',
    100: '#FFFBFF'
  },
  greyscale: {
    0: '#000000',
    10: '#211920',
    20: '#362E35',
    30: '#4D444D',
    40: '#665B63',
    50: '#7F747C',
    60: '#998D96',
    70: '#B5A8B0',
    80: '#D0C3CC',
    90: '#EDDEE8',
    95: '#FCEDF6',
    99: '#FFFBFF',
    100: '#FFFFFF'
  },
  info: {
    10: '#3754FC',
    20: '#AED581',
    30: '#FFE082',
    40: '#F09543',
    50: '#FF8A80'
  },
  surface: {
    10: '#1E1A1D',
    20: '#292128',
    30: '#362E35',
    40: '#372A36',
    50: '#483646',
    60: '#573F56'
  },
  brand: {
    10: '#2B0645',
    20: '#EA8E3B',
    30: '#421682',
    40: '#8F9CF8',
    50: '#FFFFFF'
  }
}
