import styled from '@emotion/styled/macro'
import { Box } from '@mui/material'

export const AccountWrapper = styled(Box)`
  width: 555px;
  margin: 0 auto 32px;
  border-radius: 8px;
  border: 1px solid #998d96;
  background: linear-gradient(0deg, rgba(255, 169, 252, 0.05) 0%, rgba(255, 169, 252, 0.05) 100%);
  padding: 24px 32px;
`

export const TitleWrapper = styled(Box)`
  margin-bottom: 24px;

  .title {
    color: #e9e0e4;
    font-family: 'Quicksand', sans-serif;
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
  }
`

export const Content = styled(Box)`
  .info-block {
    display: flex;
    align-items: center;
    gap: 20px;
    padding-bottom: 15px;
    padding-top: 15px;
    border-bottom: 1px solid #4d444b;

    &:first-of-type {
      padding-top: 0;
    }

    &:last-of-type {
      border-bottom: none;
      padding-bottom: 9px;
    }
  }

  .left-side {
    width: 40%;
    color: #d0c3cc;
    font-family: 'Quicksand', sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.4px;
  }

  .right-side {
    width: 60%;
    color: #e9e0e4;
    font-family: 'Quicksand', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.5px;

    img {
      width: 40px;
      min-width: 40px;
      height: 40px;
      object-fit: cover;
      border-radius: 100%;
    }

    &.capitalize-first {
      text-transform: capitalize;
    }
  }
`

export const FormContent = styled(Box)`
  .MuiTextField-root {
    width: 100%;
  }

  .MuiInputLabel-root {
    font-family: 'Quicksand', sans-serif;
    font-weight: 400;
    letter-spacing: 0.4px;
    color: #d0c3cc;
    width: 100%;
  }

  .MuiOutlinedInput-root {
    font-family: 'Quicksand', sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.5px;
    color: #d0c3cc;
    margin-bottom: 24px;

    &:hover {
      border: transparent;
    }

    fieldset {
      border: 1px solid #998d96;
      border-radius: 4px;
    }
  }

  .MuiInputAdornment-root {
    svg {
      path {
        fill: #debdd9;
      }
    }
  }

  .text-area {
    margin-bottom: 51px;
  }

  .image-upload-wrapper {
    display: flex;
    align-items: center;
    gap: 20px;
    margin-bottom: 27px;

    img {
      min-width: 40px;
      width: 40px;
      height: 40px;
      object-fit: cover;
      border-radius: 100%;
    }

    .img-upload {
      display: flex;

      .inputfile {
        /* visibility: hidden etc. wont work */
        width: 0.1px;
        height: 0.1px;
        opacity: 0;
        overflow: hidden;
        position: absolute;
        z-index: -1;
      }

      .inputfile:focus + label {
        /* keyboard navigation */
        outline: 1px dotted #000;
        outline: -webkit-focus-ring-color auto 5px;
      }

      .inputfile + label * {
        pointer-events: none;
      }

      label {
        display: flex;
        align-items: center;
        gap: 8px;
        cursor: pointer;
        padding: 10px 16px 10px 12px;

        .label-text {
          color: #ffa9fc;
          text-align: center;
          font-family: 'Quicksand', sans-serif;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 20px;
          letter-spacing: 0.1px;
        }
      }
    }
  }
`
