/* eslint-disable no-unused-vars */
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../store'

export enum ToastVariant {
  success = 'success',
  error = 'error',
  warning = 'warning',
  neutral = 'neutral'
}

export interface ToastProps {
  text: string
  description: string
}

export interface ToastState {
  open: boolean
  variant: ToastVariant
  props: ToastProps | null
}

const initialState: ToastState = {
  open: false,
  variant: ToastVariant.success,
  props: null
}

export const toastSlice = createSlice({
  name: 'Toasts',
  initialState,
  reducers: {
    openToast: (
      state: ToastState,
      action: PayloadAction<{
        variant?: ToastVariant
        props?: ToastProps
      }>
    ) => {
      const { variant, props } = action.payload

      state.props = props ?? null
      state.open = true
      state.variant = variant ?? ToastVariant.success
    },
    closeToast: (state: ToastState) => {
      state.open = false
      state.props = null
    }
  }
})

export const toastPropsSelector = (state: RootState): ToastState => state.toasts

export const { openToast, closeToast } = toastSlice.actions

export default toastSlice.reducer
