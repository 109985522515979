const SaveSvg = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="19" height="18" viewBox="0 0 19 18" fill="none">
      <path
        d="M13.6841 2.25H4.68408C3.85158 2.25 3.18408 2.925 3.18408 3.75V14.25C3.18408 15.075 3.85158 15.75 4.68408 15.75H15.1841C16.0091 15.75 16.6841 15.075 16.6841 14.25V5.25L13.6841 2.25ZM15.1841 14.25H4.68408V3.75H13.0616L15.1841 5.8725V14.25ZM9.93408 9C8.68908 9 7.68408 10.005 7.68408 11.25C7.68408 12.495 8.68908 13.5 9.93408 13.5C11.1791 13.5 12.1841 12.495 12.1841 11.25C12.1841 10.005 11.1791 9 9.93408 9ZM5.43408 4.5H12.1841V7.5H5.43408V4.5Z"
        fill="#590060"
      />
    </svg>
  )
}

export default SaveSvg
