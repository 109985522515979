import { FC } from 'react'
import { ToggleButtonProps } from '@mui/material'

import { StyledToggleButton } from './toggleButton.styles'

const ToggleButton: FC<ToggleButtonProps> = ({ value, ...rest }) => (
  <StyledToggleButton value={value} {...rest}>
    {rest.children}
  </StyledToggleButton>
)

export default ToggleButton
