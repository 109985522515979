import {
  Box,
  Accordion,
  AccordionDetails,
  Button,
  Tabs as MuiTabs,
  Tab as MuiTab,
  Link,
  Popper,
  Typography,
  InputLabel as MuiInputLabel,
  FormControl as MuiFormControl
} from '@mui/material'
import styled from '@emotion/styled/macro'

export const SentinelIntegrationStep2Wrapper = styled(Box)`
  display: flex;

  .MuiTypography-root:first-child {
    font-family: 'Quicksand', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.25px;
    color: #fbd8f5;
    text-align: left;
  }
`

export const SentinelIntegrationStep2Button = styled(Button)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 24px 10px 16px;
  gap: 8px;
  width: 408px;
  height: 40px;
  background: #ffa9fc;
  border-radius: 100px;
  font-family: 'Quicksand', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.1px;
  color: #590060;
  text-transform: none;
  margin-bottom: 24px;

  &:hover {
    background: rgba(208, 188, 255, 0.08);
  }

  &:focus {
    background: rgba(208, 188, 255, 0.12);
  }

  &:active {
    background: rgba(208, 188, 255, 0.12);
  }

  ${({ disabled }) =>
    disabled &&
    `
      background: rgba(227, 227, 227, 0.12);
  `};
`

export const BackToStep1Button = styled(Button)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 16px 10px 12px;
  gap: 8px;
  width: 236px;
  height: 40px;
  border-radius: 100px;
  text-transform: none;
  font-family: 'Quicksand', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.1px;
  color: #ffa9fc;

  svg {
    transform: rotate(180deg);

    path {
      fill: #ffa9fc;
    }
  }
`

export const ScriptButton = styled(Button)`
  gap: 8px;
  width: 105px;
  height: 40px;
  border: 1px solid #998d96;
  border-radius: 100px;
  background: transparent;
  color: #ffa9fc;
  letter-spacing: 0.1px;
  font-family: 'Quicksand', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  text-transform: capitalize;
  margin-top: 20px;
  margin-bottom: 19px;

  svg {
    path {
      fill: #ffa9fc;
    }
  }
`

export const SentinelInputWrapper = styled(Box)`
  margin-bottom: 24px;

  .MuiFormControl-root {
    width: 100%;
    background: #1e1a1d;

    label {
      font-family: 'Quicksand', sans-serif;
      font-style: normal;
      color: #d0c3cc;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.4px;
    }

    .MuiInputLabel-root.MuiInputLabel-shrink {
      font-size: 12px;
      line-height: 16px;
      color: #ffa9fc;
    }
  }

  .MuiFormHelperText-root {
    margin-top: 4px;
    font-family: 'Quicksand', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.4px;
    color: #d0c3cc;
  }

  .MuiInputBase-root-MuiFilledInput-root:hover:not(.Mui-disabled):before {
    border-bottom: 1px solid #ffa9fc;
  }

  .MuiFilledInput-root {
    &.Mui-focused {
      &:after {
        border-color: #ffa9fc;
      }
    }

    &:hover:before {
      border-color: #ffa9fc;
    }

    &::before {
      border-color: #e9e0e4;
    }
  }

  input {
    letter-spacing: 0.5px;
    font-family: 'Quicksand', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #d0c3cc;
  }

  svg {
    color: #fbd8f5;
  }

  .MuiInputAdornment-root button {
    padding-right: 16px;
  }
`

export const InputWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  height: 56px;
`

export const LabelWrapper = styled(Box)`
  display: flex;
  align-items: center;
`

export const InputLabel = styled(MuiInputLabel)`
  color: #dbdbdb;
  font-size: 12px;
  line-height: 16px;
  margin-bottom: 2px;
`

export const FormControl = styled(MuiFormControl)`
  width: 25ch;
`

export const outlinedInputSx = {
  width: '280px',
  height: '32px',
  backgroundColor: '#fff'
}

/* Tooltip */
export const TooltipContainer = styled(Box)`
  padding: 24px;
  max-width: 340px;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  background: #573f56;
  border-radius: 8px;
`

export const TooltipWrapper = styled(Box)`
  display: flex;
  flex-flow: column wrap;

  .tooltip-action-wrapper {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .tooltip-action-button {
      gap: 8px;
      width: 131px;
      height: 40px;
      border-radius: 100px;
      font-family: 'Quicksand', sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      display: flex;
      align-items: center;
      text-align: center;
      letter-spacing: 0.1px;
      color: #ffa9fc;
      margin-top: 34px;
      text-transform: capitalize;
    }
  }
`

export const TooltipText = styled(Typography)`
  font-family: 'Quicksand', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.25px;
  color: #fbd8f5;
  mix-blend-mode: normal;
  text-align: left;

  &.tooltip-title {
    font-size: 24px;
    line-height: 32px;
    letter-spacing: normal;
    margin-bottom: 16px;
  }
`

export const TooltipButton = styled(Button)`
  padding: 10px 16px 10px 12px;
  gap: 8px;
  width: 100%;
  min-width: 257px;
  height: 40px;
  border-radius: 100px;
  background: transparent;
  font-family: 'Quicksand', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.1px;
  color: #ffa9fc;
  text-transform: none;
  margin-top: 24px;

  svg {
    path {
      fill: #ffa9fc;
    }
  }
`

export const TooltipLink = styled(Link)`
  color: #f5f5f5;
  text-decoration: underline;
  font-weight: bold;
`

export const TooltipButtonWrapper = styled(Box)`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 18px !important;
  width: 100%;
`

export const primaryButtonSx = {
  width: 'fit-content',
  margin: '0 !important',

  svg: {
    marginRight: '8px'
  }
}

export const TooltipPopper = styled(Popper)`
  z-index: 10000;
`

export const Tabs = styled(MuiTabs)`
  .MuiTabs-indicator {
    background-color: #ffa9fc;
  }
`

export const Tab = styled(Button)<{ active: boolean; completed?: boolean }>`
  width: 50%;
  max-width: unset;
  min-width: unset;
  height: 64px;
  box-sizing: border-box;
  text-align: left;
  display: inline-flex;
  flex-flow: column wrap;
  justify-content: center;
  align-items: flex-start;
  border-radius: 0;

  .MuiTypography-root {
    padding-left: 40px;
    text-transform: none;
    font-family: 'Quicksand', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    line-height: 28px;
    color: #e9e0e4;

    /* Second row of title */
    &:nth-of-type(2) {
      color: ${({ active }) => (active ? '#D0C3CC' : '#7C7579')};
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
      letter-spacing: 0.5px;
    }

    ${({ active }) =>
      !active &&
      `
      color: #7C7579;
    `}
  }

  svg {
    position: absolute;
    top: 10px;
    left: 10px;
  }

  &:first-of-type {
    border-bottom: 2px solid #ffa9fc;

    .MuiTypography-root {
      color: #e9e0e4;
    }

    svg {
      path {
        fill: #ffa9fc;
      }
    }
  }

  ${({ active }) =>
    !active &&
    `
    border-bottom: 2px solid #4D444B;
    `}

  &:last-of-type {
    border-bottom: ${({ active }) => (active ? '2px solid #ffa9fc' : '2px solid #4d444b')};

    .MuiTypography-root {
      color: ${({ active }) => (active ? '#E9E0E4' : '#7C7579')};
    }

    svg {
      path {
        fill: ${({ active }) => (active ? '#FFA9FC' : '#7C7579')};
      }
    }
  }

  ${({ completed }) =>
    completed &&
    `
    border-bottom: 2px solid #ffa9fc;
    
    .MuiTypography-root {
      color: #D0C3CC;
      
       &:nth-of-type(2) {
        color: #D0C3CC;
       }
    }
  `};
`

export const SentinelActionContainer = styled(Box)`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 40px;
`

export const SentinelActionButton = styled(Button)<{ secondary?: string; tertiary?: string }>`
  color: ${({ secondary }) => (secondary && secondary === 'true' ? '#FFB4AB' : '#590060')};
  font-family: 'Quicksand', sans-serif;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.1px;
  font-weight: 500;
  background: ${({ secondary }) => (secondary && secondary === 'true' ? 'transparent' : '#ffa9fc')};
  border-radius: 100px;
  text-transform: none;
  padding: 10px 24px 10px 16px;

  svg {
    margin-right: 8px;
  }

  &:hover {
    background: rgba(208, 188, 255, 0.08);
  }

  &:focus {
    background: rgba(208, 188, 255, 0.12);
  }

  &:active {
    background: rgba(208, 188, 255, 0.12);
  }

  &.Mui-disabled {
    background: linear-gradient(0deg, rgba(255, 169, 252, 0.05), rgba(255, 169, 252, 0.05)), #1e1a1d;
    color: #ffa9fc;
    gap: 0;
  }

  ${({ tertiary }) =>
    tertiary &&
    tertiary === 'true' &&
    `
    color: #FFA9FC;
    background: linear-gradient(0deg, rgba(255, 169, 252, 0.05), rgba(255, 169, 252, 0.05)), #1E1A1D;
    
    svg {
      path {
        fill: #FFA9FC;
      }
    }
  `};

  ${({ disabled }) =>
    disabled &&
    `
    background: rgba(227, 227, 227, 0.12);
    color: #E9E0E4;
    opacity: 0.38;
  `};
`

export const SentinelInstructionsWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px 16px 24px;
  width: 100%;
  max-width: 504px;
  height: 100%;
  background: linear-gradient(0deg, rgba(255, 169, 252, 0.14), rgba(255, 169, 252, 0.14)), #1e1a1d;
  box-shadow: 0 8px 12px 6px rgba(0, 0, 0, 0.15), 0 4px 4px rgba(0, 0, 0, 0.3);
  border-radius: 8px;
`

export const SentinelInstructionsTitleWrapper = styled(Box)`
  display: flex;
  align-items: center;
  margin-top: 8px;

  &.wrapper-manual {
    flex-flow: column wrap;
    align-items: flex-start;
    justify-content: center;
  }

  .MuiIconButton-root:first-of-type {
    &:hover {
      background: rgba(202, 196, 208, 0.12);
    }
  }
`
export const SentinelInstructionsTitle = styled(Typography)`
  font-family: 'Quicksand', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: left;
  letter-spacing: 0.1px;
  color: #fbd8f5;
  margin-right: 8px;
`

export const SentinelInstructionsContent = styled(Box)`
  display: flex;
  flex-flow: column wrap;
  margin-top: 16px;

  .external-link {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 24px 10px 16px;
    gap: 8px;
    min-width: 210px;
    width: 100%;
    height: 40px;
    font-family: 'Quicksand', sans-serif;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.1px;
    color: #ffa9fc;
    border: 1px solid #998d96;
    border-radius: 100px;
    text-decoration: none;

    :hover {
      background: rgba(25, 118, 210, 0.04);
    }

    svg {
      margin-right: 8px;
    }
  }

  ol {
    font-family: 'Quicksand', sans-serif;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.25px;
    color: #fbd8f5;
    margin: 0;
    padding: 0 0 0 16px;

    li:not(:last-of-type) {
      margin-bottom: 16px;
    }

    li .MuiLink-root {
      color: #ffa9fc;
      text-decoration: underline;
      margin: 0;
      padding: 0;
      font-weight: 600;
    }
  }

  .MuiTypography-root {
    font-family: 'Quicksand', sans-serif;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.25px;
    color: #fbd8f5;
    margin-left: 4px;
  }

  .tooltip-icon-padded {
    margin-left: 8px;
  }

  .copy-text {
    font-family: 'IBM Plex Mono', monospace;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.25px;
    color: #ffa9fc;
    display: flex;
    align-items: center;
    margin-top: 8px;

    svg {
      margin-left: 4px;
    }
  }
`

export const SentinelToastWrapper = styled(Box)<{ success: boolean }>`
  width: 100%;
  height: auto;
  background: #6dd58c;
  box-shadow: 0 2px 6px 2px rgba(0, 0, 0, 0.15), 0 1px 2px rgba(0, 0, 0, 0.3);
  border-radius: 12px;
  background: ${({ success }) => (success ? '#6DD58C' : '#F2B8B5')};
  display: inline-flex;
  box-sizing: border-box;

  svg:first-child {
    margin: 24px 16px;
    width: 24px;
    height: 24px;
    min-width: 24px;
    min-height: 24px;
    box-sizing: border-box;
  }

  svg {
    path {
      fill: ${({ success }) => (success ? '#37003b' : '#690005')};
    }
  }
`

export const SentinelToastTitle = styled(Typography)<{ success: boolean }>`
  font-family: 'Quicksand', sans-serif !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 16px !important;
  line-height: 24px !important;
  letter-spacing: 0.1px !important;
  color: ${({ success }) => (success ? '#37003b' : '#690005')} !important;
  margin-bottom: 4px; !important
`

export const SentinelToastMessage = styled(Typography)<{ success: boolean }>`
  font-family: 'Quicksand', sans-serif !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 20px !important;
  letter-spacing: 0.25px !important;
  color: ${({ success }) => (success ? '#37003b' : '#690005')} !important;
  word-break: break-word !important;
  white-space: break-spaces !important;
`

export const SentinelAccordion = styled(Accordion)<{ success: boolean; isopen?: string | false }>`
  width: 100%;
  margin: 0;
  padding: 0;
  text-align: left;
  background: ${({ success }) => (success ? '#6DD58C' : '#F2B8B5')};
  box-shadow: none;
  border: 0;
  outline: 0;

  .MuiAccordionSummary-root {
    padding: 0;
    margin: 0;
    min-height: unset;
    height: auto;

    .MuiAccordionSummary-content {
      padding: 0;
      margin: ${({ isopen }) => (isopen ? '-22px 0 0' : 0)};
      flex-flow: column wrap;
    }
  }
`

export const SentinelAccordionDetails = styled(AccordionDetails)`
  padding: 0 16px 16px 0;
  margin: -35px 0 0;
`

export const SetupTypePickerTabs = styled(MuiTabs)`
  box-shadow: 0 8px 12px 6px rgba(0, 0, 0, 0.15);
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.3));
  border-radius: 100px;

  .MuiTabs-indicator {
    background-color: transparent;
  }
`

export const SetupTypePickerTab = styled(MuiTab)<{ active: boolean }>`
  max-width: unset;
  min-width: unset;
  box-sizing: border-box;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0 !important;
  border-radius: 0;

  background: #573f56;
  width: 492px;
  height: 40px;

  &:first-of-type {
    border-radius: 100px 0 0 100px;
  }

  &:last-of-type {
    border-radius: 0 100px 100px 0;
  }

  ${({ active }) =>
    active &&
    `
     background: #FFA9FC;
    `}

  .text-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 100%;
    height: 100%;

    .MuiTypography-root {
      font-family: 'Quicksand', sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.1px;
      color: #fbd8f5;
      text-transform: none;

      ${({ active }) =>
        active &&
        `
          color: #590060;
      `}
    }

    svg {
      margin-right: 8px;

      path {
        fill: #fbd8f5;
      }

      ${({ active }) =>
        active &&
        `
          path {
            fill: #590060;
          }
      `}
    }
  }
`
