import * as Yup from 'yup'
import { ProjectMeta } from '../../../../../models'

export interface FormValues {
  projects: ProjectMeta[]
}

export const defaultValues = {
  projects: [
    {
      id: '',
      name: '',
      issueTypes: [
        {
          id: '',
          name: '',
          fields: [
            {
              id: '',
              name: '',
              schemaType: '',
              sourceField: '',
              required: false
            }
          ]
        }
      ]
    }
  ]
}

export const schema = Yup.object({
  projects: Yup.array().of(
    Yup.object().shape({
      id: Yup.string().required(),
      name: Yup.string().required(),
      issueTypes: Yup.array().of(
        Yup.object().shape({
          id: Yup.string().required(),
          name: Yup.string().required(),
          fields: Yup.array().of(
            Yup.object().shape({
              id: Yup.string().required(),
              name: Yup.string().required(),
              schemaType: Yup.string().required(),
              sourceField: Yup.string().required(),
              required: Yup.boolean().required()
            })
          )
        })
      )
    })
  )
})
