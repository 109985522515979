import { Box, FormControl, Select } from '@mui/material'
import styled from '@emotion/styled/macro'

const ITEM_HEIGHT = 32
const ITEM_PADDING_TOP = 8

export const Wrapper = styled(Box)<{ isSort?: boolean }>`
  display: flex;
  align-items: center;

  ${({ isSort }) =>
    !isSort &&
    `
   gap: 8px;
  `};

  .label-text {
    font-family: Quicksand, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    color: white;
    white-space: nowrap;
  }
`

export const LibraryFormControl = styled(FormControl)<{
  isselected?: string
  iscustom?: string
}>`
  outline: none;
  height: 40px;
  border-radius: 100px;
  display: flex;
  position: relative;

  .label-text {
    font-family: Quicksand, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.1px;
    color: white;
  }
`

export const LibrarySelect = styled(Select)<{ menuopen?: string; isselected?: string }>`
  height: 40px;
  background: transparent;
  gap: 8px;
  position: relative;
  border-radius: 100px;
  border: 1px solid #998d96 !important;
  color: #ffa9fc;
  text-align: center;
  font-family: Quicksand, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 510;
  line-height: normal;

  > * {
    border: none !important;
    outline: none !important;
  }

  .MuiSelect-select.MuiSelect-outlined.MuiOutlinedInput-input {
    padding-right: 48px;
    padding-left: 24px;
  }

  svg.svg-dropdown {
    right: 16px;
    width: 24px;
    height: 24px;

    &.MuiSelect-icon {
      pointer-events: none;
      color: rgba(0, 0, 0, 0.54);
      position: absolute;
    }

    &.MuiSelect-iconClosed {
      transform: rotate(0deg);
    }

    &.MuiSelect-iconOpen {
      transform: rotate(180deg);
    }

    path {
      fill: #ffa9fc;
    }
  }

  ${({ menuopen }) =>
    menuopen &&
    menuopen === 'true' &&
    `
    background: #590060;
    color: #fff;
    
    svg.svg-dropdown {
      path {
        fill: #fff;
      }
    }
  `};

  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border: none !important;
  }

  &:hover {
    border-radius: 100px;
    border: 1px solid #998d96 !important;
    background: #544152;
    color: #ec85ec;

    svg.svg-dropdown {
      path {
        fill: #ec85ec;
      }
    }
  }

  &:focus {
    border-color: #fff;
    color: #ffa9fc;
  }

  &.Mui-disabled {
    background: #483646;
    color: #7f747c;
    pointer-events: none;

    .MuiSelect-select.MuiSelect-outlined {
      -webkit-text-fill-color: unset;
    }

    svg.svg-dropdown {
      path {
        fill: #7f747c;
      }
    }
  }
`

export const SelectDropdownMenuProps = {
  PaperProps: {
    sx: {
      background: '#573F56',
      borderRadius: '8px',
      minWidth: '250px !important',
      maxHeight: '600px !important',
      marginTop: '4px',

      '.MuiList-root': {
        background: '#573F56',
        paddingTop: '8px',
        paddingBottom: '8px'
      },

      '.MuiMenuItem-root': {
        width: '100%',
        height: '48px',
        display: 'flex',
        alignItems: 'center',
        padding: '0 16px',

        '&:hover': {
          background: 'rgba(208, 188, 255, 0.16) !important'
        },

        '.MuiCheckbox-root': {
          padding: '8px',

          '&:hover': {
            background: 'rgba(202, 196, 208, 0.12)'
          },

          svg: {
            path: {
              fill: '#d0c3cc'
            }
          }
        },

        '.MuiListItemText-root .MuiTypography-root': {
          fontFamily: '"Quicksand", sans-serif',
          color: '#FFF',
          fontSize: '16px',
          fontStyle: 'normal',
          fontWeight: 400,
          lineHeight: 'normal'
        }
      },

      '&::-webkit-scrollbar-track': {
        background: '#483646'
      },

      '&::-webkit-scrollbar-thumb': {
        background: '#ffa9fc',
        height: '4px',
        width: '4px',
        border: '4px solid transparent',
        backgroundClip: 'content-box'
      }
    },
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP
    }
  },
  anchorOrigin: {
    vertical: 'bottom' as const,
    horizontal: 'left' as const
  },
  transformOrigin: {
    vertical: 'top' as const,
    horizontal: 'left' as const
  },
  variant: 'menu' as const
}
