import * as Yup from 'yup'

export interface FormValues {
  domain: string
  clientId: string
  clientSecret: string
  audience: string
}

export const defaultValues = {
  domain: '',
  clientId: '',
  clientSecret: '',
  audience: ''
}

export const schema = Yup.object({
  domain: Yup.string().required('Required'),
  clientId: Yup.string().required('Required'),
  clientSecret: Yup.string().required('Required'),
  audience: Yup.string().required('Required')
})
