import { Box, Table as MuiTable } from '@mui/material'
import styled from '@emotion/styled/macro'

export const BenchmarkCardContainer = styled(Box)<{ isexpanded?: string }>`
  border-bottom: 1px solid rgba(147, 143, 153, 0.16);
  cursor: pointer;
  border-radius: 24px;
  background: linear-gradient(0deg, rgba(255, 169, 252, 0.11) 0%, rgba(255, 169, 252, 0.11) 100%), #1e1a1d;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.3), 0 1px 3px 1px rgba(0, 0, 0, 0.15);
  padding: 6px 16px;

  &:hover {
    background: #573f56;
  }

  .category-name {
    color: #fff;
    font-family: Quicksand, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.25px;
    text-decoration: none;

    &:hover {
      color: #ffa9fc;
      text-decoration-line: underline;
    }
  }

  .card-button {
    padding-left: 0;
    font-size: 14px;
    line-height: 16px;
    font-weight: 500;
    letter-spacing: 0.5px;
    margin-top: 8px;

    svg {
      transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
      transform: ${({ isexpanded }) => (isexpanded === 'true' ? 'rotate(45deg)' : 'rotate(0deg)')};
    }

    &:hover,
    &:focus-within {
      background: transparent;
      color: #fff;

      svg {
        path {
          fill: #fff;
        }
      }
    }
  }

  .no-category {
    border-radius: 2px;
    background: #4d444b;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.3), 0 2px 6px 2px rgba(0, 0, 0, 0.15);
    font-family: 'Quicksand', sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.4px;
    color: white;
    padding: 6px 7px;
    display: flex;
    align-items: center;
    gap: 8px;
    margin-top: 16px;
  }
`

export const BenchmarkTable = styled(MuiTable)`
  margin-top: 8px;

  .MuiTableHead-root .MuiTableRow-root .MuiTableCell-root {
    color: #fff !important;
    font-family: Quicksand, sans-serif;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0.5px;
    white-space: nowrap;
    border-bottom: none;
    padding-bottom: 8px;
    text-align: center;

    &:first-of-type {
      text-align: left;
      padding-left: 0;
    }

    &:nth-of-type(2) {
      text-align: left;
    }

    &:last-of-type {
      text-align: right;
      padding-right: 0;
    }
  }

  .MuiTableBody-root .MuiTableRow-root .MuiTableCell-root {
    color: #fff;
    font-family: Quicksand, sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.5px;
    white-space: nowrap;
    border-bottom: none;
    padding-top: 8px;
    padding-bottom: 8px;

    &:first-of-type {
      color: #ffa9fc;
      text-align: left;
      padding-left: 0;
      text-transform: uppercase;
      text-decoration-line: underline;
    }

    &:nth-of-type(2) {
      text-align: left;
    }

    &:last-of-type {
      text-align: right;
      padding-right: 0;
    }

    &.cpi-cell {
      cursor: pointer;
    }
  }

  .MuiTableHead-root .MuiTableRow-root {
    .category-title {
      width: 95px !important;
      white-space: pre-wrap !important;
      color: #fff;
      font-family: Quicksand, sans-serif;
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
      letter-spacing: 0.4px;
    }

    .category-cell {
      color: #fff;
      font-family: Quicksand, sans-serif;
      font-size: 24px;
      font-weight: 400;
      line-height: 32px;

      @media (max-width: 1279px) {
        padding: 8px 16px;

        &:nth-of-type(2) {
          text-align: center;
        }

        &:nth-of-type(3) {
          text-align: center;
        }
      }
    }
  }
`

export const CategoryBenchmarkWrapper = styled(Box)`
  border-top: 1px solid #998d96;
  margin-top: 8px;
`

export const BenchmarkValueWrapper = styled(Box)<{
  one: string | number
  two: string | number
  three: string | number
}>`
  width: 100%;
  height: 20px;
  display: flex;
  align-items: center;
  cursor: pointer;
  box-sizing: content-box;

  color: #fff;
  text-align: center;
  font-family: Quicksand, sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.1px;

  &:hover {
    .one {
      opacity: 0.38;
    }

    .two {
      opacity: 0.38;
    }

    .three {
      opacity: 0.38;
    }
  }

  .one {
    width: ${({ one }) => `${one}%`};
    border-radius: 16px 0 0 16px;
    border-top: 1px solid #8e9aff;
    border-bottom: 1px solid #8e9aff;
    border-left: 1px solid #8e9aff;
    background: linear-gradient(180deg, #8e9aff 0%, rgba(142, 154, 255, 0.4) 100%);
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      border: 1px solid #fff;
      box-shadow: 0 0 16px 0 rgba(255, 255, 255, 0.25);
      opacity: 1;
    }
  }

  .two {
    width: ${({ two }) => `${two}%`};
    border-top: 1px solid #f09543;
    border-bottom: 1px solid #f09543;
    background: linear-gradient(180deg, rgba(240, 149, 67, 0.94) 0%, rgba(240, 149, 67, 0.4) 100%);
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      border: 1px solid #fff;
      box-shadow: 0 0 16px 0 rgba(255, 255, 255, 0.25);
      opacity: 1;
    }
  }

  .three {
    width: ${({ three }) => `${three}%`};
    border-radius: 0 16px 16px 0;
    border-top: 1px solid #b051b3;
    border-right: 1px solid #b051b3;
    border-bottom: 1px solid #b051b3;
    background: linear-gradient(180deg, #781a7e 0%, rgba(120, 26, 126, 0.4) 100%);
    color: #fff;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      border: 1px solid #fff;
      box-shadow: 0 0 16px 0 rgba(255, 255, 255, 0.25);
      opacity: 1;
    }
  }
`

export const NoBenchmarkWrapper = styled(Box)`
  border-radius: 2px;
  background: #4d444b;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.3), 0 2px 6px 2px rgba(0, 0, 0, 0.15);
  display: flex;
  align-items: center;
  padding: 8px;

  .no-benchmark-text {
    margin-left: 8px;
    color: #fff;
    font-family: Quicksand, sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.4px;
  }
`
