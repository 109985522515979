import { FC } from 'react'
import { Box, Typography } from '@mui/material'

/* Components */
import { ThemeButton } from '../../../components/buttons'
import RequestQuoteIcon from '../../../components/svg/RequestQuoteIcon'

interface Props {
  requestQuote: () => void
}

const DemoAccountPrompt: FC<Props> = ({ requestQuote }) => (
  <Box className="empty-wrapper">
    <Box>
      <Box display="flex" justifyContent="center">
        <img src="/empty-dashboard.svg" alt="empty-dashboard" />
      </Box>
      <Typography display="flex" justifyContent="center" className="title">
        Unlock the full potential of Onyxia with a premium account
      </Typography>

      <Box display="flex" justifyContent="center">
        <ThemeButton themevariant="primary" className="source-card-button" onClick={requestQuote}>
          <>
            <RequestQuoteIcon />
            Request a Quote
          </>
        </ThemeButton>
      </Box>
    </Box>
  </Box>
)

export default DemoAccountPrompt
