const TestConfigurationIconButton = () => (
  <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.5 1.5C5.36 1.5 2 4.86 2 9C2 13.14 5.36 16.5 9.5 16.5C13.64 16.5 17 13.14 17 9C17 4.86 13.64 1.5 9.5 1.5ZM9.5 15C6.1925 15 3.5 12.3075 3.5 9C3.5 5.6925 6.1925 3 9.5 3C12.8075 3 15.5 5.6925 15.5 9C15.5 12.3075 12.8075 15 9.5 15ZM7.625 12.375L12.875 9L7.625 5.625V12.375Z"
      fill="#590060"
    />
  </svg>
)

export default TestConfigurationIconButton
