import { Box, Divider } from '@mui/material'
import styled from '@emotion/styled/macro'

export const Container = styled(Box)`
  width: 100%;
  display: flex;
  flex-basis: 100%;
  height: 100%;
  min-height: calc(100vh - 100px);
`

export const Section = styled(Box)`
  flex-basis: 50%;
  display: flex;
  align-items: center;
  justify-content: center;

  .analysis-description-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 24px;

    .content-container {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      gap: 16px;
      max-width: 420px;

      .pink-header-text {
        color: #ffa9fc;
        text-align: center;
        font-size: 10px;
        font-style: normal;
        font-weight: 590;
        line-height: normal;
        letter-spacing: 1px;
        text-transform: uppercase;
      }

      .header-text {
        color: #fff;
        text-align: center;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }

      .description-text {
        color: #fff;
        text-align: center;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }
  }
`

export const Separator = styled(Divider)`
  background: #4d444b;
  margin: auto;
`
