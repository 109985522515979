import { Box } from '@mui/material'
import styled from '@emotion/styled/macro'

export const EmptyDataContainer = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 80px;

  .MuiTypography-root {
    font-size: 36px;
  }
`
