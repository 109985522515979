import styled from '@emotion/styled/macro'
import { Box, Breadcrumbs, Switch } from '@mui/material'

export const Container = styled(Box)`
  width: 100%;
`

export const AccountManagementHeaderContainer = styled(Box)`
  width: 100%;
  position: relative;
  z-index: 100;

  .breadcrumbs-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    max-width: 100%;
    padding: 16px 0 0 24px;

    .header-actions {
      display: flex;
      align-items: center;

      .favorite-icon {
        margin-right: 34px;

        .MuiButtonBase-root {
          &:hover,
          &:focus,
          &:active {
            background: rgba(202, 196, 208, 0.08);
          }
        }
      }
    }
  }

  .title-wrapper {
    padding-left: 24px;

    &.title-wrapper {
      padding: 0 0 24px 24px;

      .header-title {
        color: #fff;
        font-family: 'Quicksand', sans-serif;
        font-size: 32px;
        font-style: normal;
        font-weight: 400;
        line-height: 40px;
        text-transform: capitalize;
        margin-top: 24px;
        margin-bottom: 8px;
      }

      .header-subtitle {
        font-family: 'Quicksand', sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 133.333% */
        letter-spacing: 0.25px;
        color: #e9e0e4;
      }
    }
  }
`

export const HeaderBackground = styled(Box)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url('/category-page-bg.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top center;
  z-index: -1;
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.15);
  filter: drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.3));
  mix-blend-mode: luminosity;
`

export const HeaderBreadcrumbs = styled(Breadcrumbs)`
  .MuiLink-root,
  .current-breadcrumb {
    font-family: 'Quicksand', sans-serif;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.5px;
    text-decoration: none;
  }

  .MuiLink-root {
    color: #debdd9;
  }

  .current-breadcrumb {
    color: #ffa9fc;
  }

  .MuiBreadcrumbs-separator {
    color: #debdd9;
  }
`

export const EditFixedPanel = styled(Box)`
  position: fixed;
  right: 24px;
  top: 96px;

  .panel-block {
    display: flex;
    align-items: center;
    gap: 8px;

    .text {
      color: #fff;
      font-family: 'Quicksand', sans-serif;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 16px;
      letter-spacing: 0.5px;
    }
  }
`

export const LockIconWrapper = styled(Box)`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
  border-radius: 100px;
  border: 1px solid #998d96;
  width: 40px;
  height: 40px;
`

export const UnlockIconWrapper = styled(Box)`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
  border-radius: 100px;
  background: #e9e0e4;
  width: 40px;
  height: 40px;
`

export const ContentWrapper = styled(Box)`
  margin: 32px auto;
`

export const MaterialUISwitch = styled(Switch)(() => ({
  width: 79,
  height: 40,
  margin: 0,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    margin: 1,
    padding: 0,
    transform: 'translateX(3px)',
    '&.Mui-checked': {
      color: '#fff',
      transform: 'translateX(41px)',
      '& .MuiSwitch-thumb:before': {
        marginLeft: 1,
        backgroundImage: `url('/unlock.svg')`
      },
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: '#FFA9FC'
      }
    }
  },
  '& .MuiSwitch-thumb': {
    backgroundColor: '#FFF',
    width: 33,
    height: 33,
    marginTop: 2,
    '&:before': {
      content: "''",
      position: 'absolute',
      width: '100%',
      height: '100%',
      left: 0,
      top: 0,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      backgroundImage: `url('/lock.svg')`
    }
  },
  '& .MuiSwitch-track': {
    opacity: 1,
    backgroundColor: '#998D96',
    borderRadius: 36,
    height: '40px',
    minWidth: '79px'
  }
}))
