import { FC } from 'react'
import { Actions, TransferOwnershipWrapper } from '../userProfile.styles'
import { Box, CircularProgress, Typography } from '@mui/material'
import { ThemeButton } from '../../../../buttons'
import {
  closeModal,
  modalPropsSelector,
  ModalType,
  openModal,
  setModalProps
} from '../../../../../../store/modals/modalSlice'
import { useAppDispatch, useAppSelector } from '../../../../../../store/hooks'
import axios from '../../../../../../lib/axios'
import { Form, Formik } from 'formik'
import { closeToast, openToast, ToastVariant } from '../../../../../../store/toasts/toastSlice'

const TransferOwnership: FC = () => {
  const dispatch = useAppDispatch()
  const modalProps = useAppSelector(modalPropsSelector)

  const handleClose = () => {
    dispatch(openModal({ type: ModalType.userProfile, props: modalProps.props }))
  }

  const handleConfirm = async () => {
    if (!modalProps.props) return
    if (!modalProps.props.user) return

    try {
      const res = await axios.post(`/api/v1/identity/users/${modalProps.props.user.id}/change-owner`, {
        role: 'admin'
      })

      if (res.status === 200) {
        dispatch(
          openToast({
            variant: ToastVariant.success,
            props: {
              text: 'Role Added',
              description: `Ownership has been revoked from your account and granted to ${modalProps.props.user.email}, you are now an administrator.`
            }
          })
        )
        dispatch(
          setModalProps({
            open: false,
            props: {
              ...modalProps.props,
              user: {
                ...modalProps.props.user,
                role: 'admin'
              }
            }
          })
        )
        dispatch(closeModal())
      }
    } catch (e) {
      console.error(e)
      dispatch(
        openToast({
          variant: ToastVariant.error,
          props: {
            text: 'Failed changing role',
            description: (e as any).response?.data?.message ?? 'There was a problem while transferring ownership.'
          }
        })
      )
    } finally {
      setTimeout(() => {
        dispatch(closeToast())
      }, 5000)
    }
  }

  if (!modalProps.props || !modalProps.props.user) {
    return <div>No user found.</div>
  }
  return (
    <Formik initialValues={{}} onSubmit={handleConfirm}>
      {({ isSubmitting, isValid }) => (
        <Form autoComplete="off">
          <TransferOwnershipWrapper>
            <Box className="content">
              <Typography className="title">Are you sure you want to reassign ownership?</Typography>
              <Typography className="text">
                Only one user can be the workspace owner at a time. You will be demoted to an Administrator.
              </Typography>
            </Box>
            <Actions>
              <ThemeButton themevariant="tertiary" type="button" onClick={handleClose}>
                Cancel
              </ThemeButton>
              <ThemeButton
                themevariant="primary"
                type="submit"
                disabled={isSubmitting || !isValid}
                endIcon={isSubmitting && <CircularProgress size="20px" />}
              >
                Confirm
              </ThemeButton>
            </Actions>
          </TransferOwnershipWrapper>
        </Form>
      )}
    </Formik>
  )
}

export default TransferOwnership
