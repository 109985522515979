import { FC, SyntheticEvent } from 'react'
import { CircularProgress, TextField } from '@mui/material'
import { DebouncedFunc } from 'lodash'

/* Components */
import { HistoryIcon, SearchIcon } from '../../../../components/svg'
import { PaperComponent, StyledAutocomplete } from './searchUsers.styles'

interface Props {
  options: string[]
  onInputChange: DebouncedFunc<(event: SyntheticEvent, value: string) => Promise<void>>
  loading: boolean
}

const SearchUsers: FC<Props> = ({ options, loading, onInputChange, ...rest }) => (
  <StyledAutocomplete
    freeSolo
    id="search-users-box"
    options={options}
    {...rest}
    loading={loading}
    onInputChange={onInputChange}
    PaperComponent={PaperComponent}
    renderOption={(props, option, state) => (
      <li {...props} {...state}>
        <>
          <HistoryIcon />
          {option}
        </>
      </li>
    )}
    renderInput={(params) => (
      <TextField
        {...params}
        placeholder="Search..."
        InputProps={{
          ...params.InputProps,
          startAdornment: <SearchIcon />,
          endAdornment: (
            <>
              {loading ? <CircularProgress color="inherit" size={20} /> : null}
              {params.InputProps.endAdornment}
            </>
          )
        }}
      />
    )}
  />
)

export default SearchUsers
