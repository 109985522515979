import { FC } from 'react'
import { PopperProps, Fade, ClickAwayListener, Button, Box } from '@mui/material'

/* Components */
import { TooltipText, TooltipPopper, TooltipWrapper, TooltipContainer } from './sentinelIntegration.styles'
import { PlusIcon } from '../../../svg'

interface Props extends PopperProps {
  handleClickAway: () => void
  handleDownload: () => void
  variant: 'step1' | 'step2'
}

const SentinelInstructionsTooltip: FC<Props> = ({
  id,
  open,
  handleDownload,
  anchorEl,
  handleClickAway,
  variant,
  ...rest
}) => (
  <TooltipPopper id={id} open={open} anchorEl={anchorEl} transition {...rest} placement="bottom-start">
    {({ TransitionProps }) => (
      <Fade {...TransitionProps} timeout={350}>
        <TooltipContainer>
          <ClickAwayListener onClickAway={handleClickAway}>
            <TooltipWrapper>
              {variant === 'step1' && (
                <TooltipText variant="body1">
                  Entering your Workspace URL provides Onyxia with your Subscription ID, Resource Group Name, and
                  Workspace Name.
                </TooltipText>
              )}
              {variant === 'step2' && (
                <>
                  <TooltipText variant="body1" className="tooltip-title">
                    About the Script
                  </TooltipText>
                  <TooltipText variant="body1">
                    This is a terraform script that will automate the configuration process. It will set up an
                    application to enable api calls.
                  </TooltipText>
                  <Box className="tooltip-action-wrapper">
                    <Button onClick={handleDownload} className="tooltip-action-button">
                      <PlusIcon />
                      Learn More
                    </Button>
                  </Box>
                </>
              )}
            </TooltipWrapper>
          </ClickAwayListener>
        </TooltipContainer>
      </Fade>
    )}
  </TooltipPopper>
)

export default SentinelInstructionsTooltip
