/* eslint camelcase: 0 */
import { Box, FormControl as MuiFormControl, Select as MuiSelect } from '@mui/material'
import styled from '@emotion/styled/macro'

const ITEM_HEIGHT = 40
const ITEM_PADDING_TOP = 0

export const Wrapper = styled(Box)`
  display: flex;
  align-items: center;
`

export const TextWrapper = styled(Box)`
  border-radius: 4px 0px 0px 4px;
  border-top: 1px solid #998d96;
  border-bottom: 1px solid #998d96;
  border-left: 1px solid #998d96;
  background: #362e35;
  padding: 9.5px 16px;
  height: 40px;

  .MuiTypography-root {
    color: #fff;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    white-space: nowrap;
  }
`

export const FormControl = styled(MuiFormControl)<{
  isselected?: string
  is_opened: string
  is_disabled: string
  nolabel?: string
}>`
  width: 100%;
  background: transparent;
  outline: none;
  min-width: 200px;
  height: 40px;
  border-radius: ${({ nolabel }) => (nolabel && nolabel === 'true' ? '4px' : '0px 4px 4px 0px')};
  border: 1px solid #998d96;

  .MuiOutlinedInput-root {
    background: transparent;
    border: 0;
    color: #fff;
    text-align: left;
    font-family: Quicksand, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.1px;
    height: 40px;
  }

  &:hover {
    background: rgba(234, 221, 255, 0.08);

    .MuiOutlinedInput-root {
      color: #ffa9fc;
    }
  }

  svg {
    top: calc(50% - 0.6em);
    right: 16px;

    path {
      fill: #fff;
    }
  }

  ${({ is_disabled }) =>
    is_disabled &&
    is_disabled === 'true' &&
    `
      opacity: 0.38;
      pointer-events: none;
      cursor: default;
      
      &:hover {
        border-radius: 100px;
        background: transparent;
    
        .MuiOutlinedInput-root {
          color: #ffa9fc;
        }
      }
      
       .MuiOutlinedInput-root {
        color: #ffa9fc;
      }
  `};

  ${({ is_opened }) =>
    is_opened &&
    is_opened === 'true' &&
    `
      border: 1px solid #FFA9FC;
      background: rgba(208, 188, 255, 0.16);
      
      .MuiOutlinedInput-root {
        color: #ffa9fc;
      }
  `};
`

export const Select = styled(MuiSelect)<{ isselected?: string; noborder?: string }>`
  min-width: 200px;
  height: 40px;
  background: transparent;
  border-radius: 0px 4px 4px 0px;
  border: 1px solid #998d96;
  gap: 8px;
  ${({ noborder }) =>
    noborder === 'true' &&
    `
    border-radius: 0;
    border: 0;
    border-color: transparent;
    background: transparent;
  `};
  color: #fff;
  text-align: center;
  font-family: Quicksand, sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.1px;
  ${({ isselected }) =>
    isselected === 'true' &&
    `
    background: #6C586A;
  `};

  > * {
    border: none !important;
    outline: none !important;
  }

  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border: none !important;
  }

  svg {
    color: #d0c3cc;
  }
  
  .input-value-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 8px;

    .input-value-count {
      color: #867083;
      text-align: right;
      text-overflow: ellipsis;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin-right: 8px;
    }
`

export const MenuProps = {
  PaperProps: {
    sx: {
      background: '#372A36',
      border: '1px solid #4D444B',
      borderRadius: '4px',
      padding: '8px',
      minWidth: '167px',
      width: '167px',

      '.MuiList-root': {
        paddingTop: '0',
        paddingBottom: '0'
      },

      '.MuiMenuItem-root': {
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        minWidth: '167px',
        height: '40px',
        padding: '8px',
        borderRadius: '4px',

        img: {
          marginRight: '8px'
        },

        '&:hover': {
          background: 'rgba(208, 188, 255, 0.08)'
        },

        '.MuiListItemText-root .MuiTypography-root': {
          color: '#E9E0E4',
          fontFamily: "'Quicksand', sans-serif",
          fontSize: '16px',
          fontStyle: 'normal',
          fontWeight: 400,
          lineHeight: '24px',
          letterSpacing: '0.5px'
        }
      },

      '.Mui-selected': {
        'background-color': 'rgba(208, 188, 255, 0.16) !important'
      },

      '&::-webkit-scrollbar': {
        width: '4px',
        background: 'transparent'
      },

      '&::-webkit-scrollbar-track': {
        boxShadow: 'none',
        background: 'transparent'
      },

      '&::-webkit-scrollbar-thumb': {
        backgroundColor: '#FBD8F5',
        borderRadius: '8px'
      }
    },
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP
    }
  },
  anchorOrigin: {
    vertical: 'bottom' as const,
    horizontal: 'left' as const
  },
  transformOrigin: {
    vertical: 'top' as const,
    horizontal: 'left' as const
  },
  variant: 'menu' as const
}
