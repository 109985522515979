import { FC } from 'react'
import { Box, Typography } from '@mui/material'
import dayjs from 'dayjs'

/* Utils */
import { CPISeverity, Insight } from '../../../../../models'

/* Components */
import { ContentInner, HeaderBackground, InsightsImageWrapper, MainSectionContent } from './mainSection.styles'
import { RightIconButton } from '../../../../components/svg'
import { ThemeButton } from '../../../../components/buttons'
import CpiDetails from './components/CpiDetails'

interface Props {
  insight: Insight
  getHeaderBackground: (text: string) => string
  handleExploreData: (res: Insight) => void
}

const MainSection: FC<Props> = ({ insight, getHeaderBackground, handleExploreData }) => {
  return (
    <MainSectionContent sx={{ height: '100%' }} bg={getHeaderBackground(insight.sub_type)} id="mainSection">
      <Box className="main-section-header">
        <HeaderBackground
          bg={getHeaderBackground(insight.sub_type)}
          sx={{ width: '100% !important', height: '53px !important' }}
        />
        <img src={insight.data?.card_icon || ''} alt="" />
        <Typography className="header-text">{insight.data?.header || ''}</Typography>
        <Typography className="header-date">
          {dayjs(insight.data?.insight_date || undefined).format('MMM D, YYYY hh:mm A')}
        </Typography>
      </Box>
      <ContentInner>
        <Box sx={{ paddingTop: '18px', paddingBottom: '15px' }}>
          <CpiDetails
            displayName={insight.data?.cpi_display_name || ''}
            severity={insight.data?.section || CPISeverity.All}
            title={insight.data?.title || ''}
            instances={insight.data?.instances || []}
            isMainSection
          />
        </Box>
        <Typography className="main-performance-text">{insight.data?.description || ''}</Typography>
        <InsightsImageWrapper>
          <img src={insight.data?.card_image || ''} alt="" />
        </InsightsImageWrapper>
        <ThemeButton onClick={() => handleExploreData(insight)} themevariant="tertiary" sx={{ marginTop: '8px' }}>
          <>
            <RightIconButton />
            Explore Data
          </>
        </ThemeButton>
      </ContentInner>
    </MainSectionContent>
  )
}

export default MainSection
