import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../store'

const AUTO_HIDE_DELAY = 5000

export interface NotificationState {
  variant: 'success' | 'error' | 'warning'
  message: string
  open: boolean
  autoHideDelay?: number
  title?: string
}

const initialState: NotificationState = {
  variant: 'success',
  message: '',
  title: undefined,
  open: false,
  autoHideDelay: 5000
}

export const notificationSlice = createSlice({
  name: 'Notifications',
  initialState,
  reducers: {
    notification: (state: NotificationState, action: PayloadAction<NotificationState>) => {
      const { variant, message, open, autoHideDelay, title } = action.payload

      state.variant = variant
      state.message = message
      state.open = open
      state.autoHideDelay = autoHideDelay ?? AUTO_HIDE_DELAY
      state.title = title
    },
    closeNotification: (state: NotificationState) => {
      state.open = false
      state.autoHideDelay = 5000
    }
  }
})

export const notificationPropsSelector = (state: RootState): NotificationState => state.notifications

export const { notification, closeNotification } = notificationSlice.actions

export default notificationSlice.reducer
