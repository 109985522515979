import { Box } from '@mui/material'
import styled from '@emotion/styled/macro'

export const ChartWrapper = styled(Box)`
  display: flex;
  margin-left: auto;
  margin-right: auto;
  height: 100%;

  .description-wrapper {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    border-radius: 16px 16px 0 0;
    background: #131013;
    border: 1px solid rgba(147, 143, 153, 0.16);
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.3), 0 4px 8px 3px rgba(0, 0, 0, 0.15);
    height: 150px;

    @media (min-height: 801px) {
      gap: 10px;
    }

    @media (max-width: 1440px) and (max-height: 900px) {
      gap: 10px;

      .chips-container {
        margin-top: 0;
      }
    }

    @media (max-width: 1440px) and (min-height: 901px) {
      gap: 10px;

      .chips-container {
        margin-top: 0;
      }
    }

    @media (max-width: 1366px) and (max-height: 768px) {
      gap: 10px;

      .chips-container {
        margin-top: 0;
      }
    }

    @media (max-width: 1280px) {
      gap: 10px;

      .chips-container {
        margin-top: 0;
      }
    }

    @media (max-height: 800px) {
      gap: 10px;
      height: 119px;
    }

    .box-wrapper {
      border-radius: 12px;
      background: #281e28;
      padding: 16px;

      @media (max-height: 800px) {
        padding: 10px 0 0 0;
      }

      .measurement-wrapper {
        width: 100%;
        display: flex;
        align-items: center;
        gap: 16px;
        padding: 0 16px;

        .status {
          display: flex;
          align-items: flex-end;

          span {
            font-family: 'Quicksand', sans-serif;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 16px;
            letter-spacing: 0.4px;
          }
        }

        .column {
          flex-direction: column;
        }
      }

      .box-wrapper-options {
        display: flex;
        gap: 12px;

        @media (max-width: 1400px) {
          flex-wrap: wrap;
        }
      }

      .label-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;
        text-transform: none;

        > div {
          cursor: pointer;
        }

        .title {
          margin-bottom: 8px;
        }

        .count {
          border-radius: 100px;
          border: 1px solid #998d96;
          background: #ffe082;
          position: absolute;
          top: 30px;
          right: 5px;
          min-width: 14px;
          height: 14px;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #66006e;
          text-align: center;
          font-family: 'Quicksand', sans-serif;
          font-size: 11px;
          font-style: normal;
          font-weight: 700;
          line-height: 16px; /* 145.455% */
          letter-spacing: 0.5px;
        }
      }
    }

    .wrapper-options {
      flex-direction: column;
      display: flex;
      align-items: flex-start;
      justify-content: center;
      max-width: 679px;
      padding-left: 16px;
      padding-right: 16px;
      padding-bottom: 16px;
    }

    .wrapper-options,
    .legend-wrapper,
    .wrapper-measurement {
      width: 100%;
    }

    .wrapper-measurement {
      @media (max-width: 1280px) {
        max-width: 266px;
      }

      @media (max-width: 1440px) {
        max-width: 312px;
      }

      @media (min-width: 1441px) {
        max-width: 422px;
      }
    }

    .px-7 {
      padding-left: 7px;
      padding-right: 7px;
    }

    .legend-wrapper {
      padding: 16px;
      max-width: 363px;

      @media (max-width: 1400px) {
        max-width: 187px;
        width: auto;
      }

      @media (max-width: 1280px) {
        max-width: 187px;
      }

      @media (max-height: 800px) {
        padding-top: 10px;
        padding-bottom: unset;

        .legend-key {
          margin-right: 13px;
        }

        .legend-container {
          gap: 3px !important;
        }
      }

      .legend {
        @media (max-width: 1400px) {
          display: flex;
          flex-direction: row;
          flex-flow: nowrap;
          gap: 12px;
          align-items: start;
          justify-content: center;
        }
      }

      @media screen and (max-width: 1440px) and (max-height: 800px) {
        max-width: 279px;

        .legend {
          flex-flow: column wrap;

          .legend-container {
            flex-flow: row wrap;
            gap: 8px 16px !important;
          }
        }
      }
    }

    .text {
      font-family: 'Quicksand', sans-serif;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.1px;
      color: #ffffff;

      .severity {
        text-transform: capitalize;
      }
    }

    .status {
      font-family: 'Quicksand', sans-serif;
      font-size: 76.533px;
      font-style: normal;
      font-weight: 400;
      line-height: 57.439px;
      color: #aed581;
      margin-top: 0;
      text-transform: capitalize;
    }

    .updated-at {
      font-family: 'Quicksand', sans-serif;
      font-weight: 500;
      font-size: 11px;
      line-height: 16px;
      letter-spacing: 0.5px;
      color: #d0c3cc;
    }

    .legend {
      width: auto;
      display: flex;
      flex-flow: column wrap;

      .legend-container {
        display: flex;
        flex-wrap: wrap;
        gap: 14px;

        @media (max-width: 1500px) {
          gap: 8px;
        }
      }

      .legend-key {
        font-family: 'Quicksand', sans-serif;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.1px;
        color: #ffffff;
        margin-bottom: 8px;
      }

      .legend-item {
        display: flex;
        align-items: center;

        .MuiTypography-root {
          margin-left: 8px;
          font-family: 'Quicksand', sans-serif;
          font-weight: 500;
          font-size: 12px;
          line-height: 16px;
          letter-spacing: 0.5px;
          color: #ffffff;
          white-space: nowrap;
        }
      }
    }

    .data-source-wrapper {
      display: flex;
      flex-flow: column wrap;

      .data-source-select-wrapper {
        max-width: 120px;
        margin-top: 4px;
      }

      .title {
        font-family: 'Quicksand', sans-serif;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.1px;
        color: #ffffff;
        margin-bottom: 4px;
      }
    }
  }

  .chart-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-flow: column wrap;

    .chart-wrapper {
      flex: 1 1 auto;
      width: 100%;
      height: calc(100vh - 56px - 24px - 74px - 116px - 100px + 24px);
    }

    .trends-d3-wrapper {
      position: relative;
      width: 100%;
      height: 100%;

      .svg-d3 {
        border-bottom-right-radius: 16px;
        border-bottom-left-radius: 16px;
        border: 1px solid rgba(147, 143, 153, 0.16);
        background: linear-gradient(0deg, rgba(255, 169, 252, 0.08) 0%, rgba(255, 169, 252, 0.08) 100%), #1e1a1d;

        .event-indicator-count {
          cursor: pointer;
        }

        .add-event-button {
          cursor: pointer;
          display: none;

          &:hover {
            .add-button-circle {
              fill: rgba(208, 195, 204, 0.15);
              stroke: #d0c3cc;
            }
          }
        }

        .add-button-tooltip {
          display: none;
        }

        .count-tooltip {
          display: none;
        }

        .sla {
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 16px;
          background: rgba(208, 188, 255, 0.16);
          box-shadow: 0 8px 12px 6px rgba(0, 0, 0, 0.15), 0 4px 4px rgba(0, 0, 0, 0.3);
          border: 0 solid #998d96;
          border-radius: 16px;
          backdrop-filter: blur(6px) brightness(0.8) contrast(0.9) hue-rotate(320deg);
          z-index: 1000000;

          .sla-tooltip-wrapper {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 16px;
            background: rgba(208, 188, 255, 0.16);
            box-shadow: 0 8px 12px 6px rgba(0, 0, 0, 0.15), 0 4px 4px rgba(0, 0, 0, 0.3);
            border: 0 solid #998d96;
            border-radius: 16px;
            backdrop-filter: blur(6px) brightness(0.8) contrast(0.9) hue-rotate(320deg);
            z-index: 1000000;
          }
        }
      }

      .data-point-tooltip {
        display: none;
        position: absolute;
        opacity: 1;
        padding: 16px;
        gap: 10px;
        border-radius: 8px;
        border: 1px solid #998d96;
        background: #4d444b;
        box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.3), 0 8px 12px 6px rgba(0, 0, 0, 0.15);
        color: #fff;
        font-family: Quicksand, sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: 0.1px;

        .asset-wrapper-coverage {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 300px;
          flex-flow: row wrap;

          .asset-wrapper-inner {
            display: flex;
            align-items: center;
            gap: 4px;
            margin-right: 12px;
            width: fit-content;
            max-width: 300px;

            > svg:first-child {
              margin-right: 4px;
              min-width: 12px;
            }
          }

          .tooltip-performance {
            width: fit-content;
            display: flex;
            margin: 4px 0;

            &.subtext {
              &.long {
                width: inherit;
                justify-content: flex-end;
              }
            }
          }

          .long {
            width: 100%;
            display: flex;
            justify-content: flex-end;
            align-items: center;
          }
        }

        .tooltip-performance,
        .tooltip-units {
          color: #fff;
          font-family: Quicksand, sans-serif;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;
          letter-spacing: 0.25px;
        }

        .tooltip-units {
          margin: 0;
        }

        .severity-text {
          text-transform: capitalize;
        }
      }
    }
  }

  .chart-container::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 0 rgba(0, 0, 0, 0);
    background: #2c242c;
  }

  .chart-container::-webkit-scrollbar-thumb {
    height: 5px;
    background-color: #524256;
  }

  .chart-container::-webkit-scrollbar-thumb:hover {
    background-color: #524256;
  }

  .chart-container::-webkit-scrollbar:vertical {
    display: none;
  }
`

export const ChartHeader = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
  flex: 0 1 auto;

  .info-block {
    display: flex;
    align-items: center;
    gap: 24px;

    > div {
      display: flex;
      align-items: center;
      gap: 8px;
    }

    .square {
      width: 16px;
      height: 16px;
      border-radius: 4px;
    }

    .blue {
      background: #3754fc;
    }

    .orange {
      background: #f09543;
    }

    .name {
      color: #fff;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }

  .filter-icon-block {
    position: relative;
    display: flex;
    border-radius: 50%;
    background: transparent;
    padding: 8px;

    .active-circle {
      position: absolute;
      top: 3px;
      right: 3px;
      background: #1e1a1d;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 14px;
      height: 14px;

      .circle {
        width: 8px;
        height: 8px;
        background: #ffe082;
        border-radius: 50%;
      }
    }
  }
`
