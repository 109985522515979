import { FC, SVGProps } from 'react'

type Props = SVGProps<SVGSVGElement>

const IntelFeedIcon: FC<Props> = ({ ...props }) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M12 7.99951C9.79 7.99951 8 9.78951 8 11.9995C8 14.2095 9.79 15.9995 12 15.9995C14.21 15.9995 16 14.2095 16 11.9995C16 9.78951 14.21 7.99951 12 7.99951ZM20.94 10.9995C20.48 6.82951 17.17 3.51951 13 3.05951V1.99951C13 1.44951 12.55 0.999512 12 0.999512C11.45 0.999512 11 1.44951 11 1.99951V3.05951C6.83 3.51951 3.52 6.82951 3.06 10.9995H2C1.45 10.9995 1 11.4495 1 11.9995C1 12.5495 1.45 12.9995 2 12.9995H3.06C3.52 17.1695 6.83 20.4795 11 20.9395V21.9995C11 22.5495 11.45 22.9995 12 22.9995C12.55 22.9995 13 22.5495 13 21.9995V20.9395C17.17 20.4795 20.48 17.1695 20.94 12.9995H22C22.55 12.9995 23 12.5495 23 11.9995C23 11.4495 22.55 10.9995 22 10.9995H20.94ZM12 18.9995C8.13 18.9995 5 15.8695 5 11.9995C5 8.12951 8.13 4.99951 12 4.99951C15.87 4.99951 19 8.12951 19 11.9995C19 15.8695 15.87 18.9995 12 18.9995Z"
      fill="#E9E0E4"
    />
  </svg>
)

export default IntelFeedIcon
