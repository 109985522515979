import styled from '@emotion/styled/macro'
import { Box } from '@mui/material'

export const Wrapper = styled(Box)`
  padding: 9px 16px 8px 16px;
  display: flex;
  justify-content: space-between;
  gap: 10px;
  border-radius: 16px;
  border: 1px solid #998d96;
  background: linear-gradient(0deg, rgba(255, 169, 252, 0.08) 0%, rgba(255, 169, 252, 0.08) 100%);
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.3), 0 1px 3px 1px rgba(0, 0, 0, 0.15);
  min-width: 258px;
  margin-bottom: 16px;

  .header {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-bottom: 3px;

    .title {
      font-family: 'Quicksand', sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: 0.1px;
    }
  }

  .description {
    font-family: 'Quicksand', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.25px;
    margin-bottom: 8px;
    word-break: break-all;
  }

  .date {
    font-family: 'Quicksand', sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0.5px;
    color: #998d96;
    margin-bottom: 0;
  }

  .actions-wrapper {
    display: flex;
    flex-direction: column;
    gap: 8px;
    justify-content: center;

    .edit-button,
    .pined-button {
      svg {
        width: 20px !important;
        height: 20px !important;
      }

      :hover {
        background: rgba(208, 195, 204, 0.15);
      }
    }

    .is-pined {
      background: #ffa9fc;
      border-color: #998d96;
    }

    .is-disabled {
      cursor: inherit;
      border-color: #998d96;
      background: rgba(227, 227, 227, 0.13);
    }

    .edit-button {
      svg {
        path {
          fill: #ffffff;
        }
      }
    }

    .MuiIconButton-root {
      border: 1px solid #998d96;
      padding: 5px;
    }
  }
`
