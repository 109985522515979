import { FC, SVGProps } from 'react'

type Props = SVGProps<SVGSVGElement>

const InfoIconFilled: FC<Props> = ({ ...props }) => (
  <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M7.33325 5.16683H8.66659V6.50016H7.33325V5.16683ZM7.33325 7.8335H8.66659V11.8335H7.33325V7.8335ZM7.99992 1.8335C4.31992 1.8335 1.33325 4.82016 1.33325 8.50016C1.33325 12.1802 4.31992 15.1668 7.99992 15.1668C11.6799 15.1668 14.6666 12.1802 14.6666 8.50016C14.6666 4.82016 11.6799 1.8335 7.99992 1.8335ZM7.99992 13.8335C5.05992 13.8335 2.66659 11.4402 2.66659 8.50016C2.66659 5.56016 5.05992 3.16683 7.99992 3.16683C10.9399 3.16683 13.3333 5.56016 13.3333 8.50016C13.3333 11.4402 10.9399 13.8335 7.99992 13.8335Z"
      fill="white"
    />
  </svg>
)

export default InfoIconFilled
