import { Dispatch, FC, SetStateAction, useMemo, useState } from 'react'
import { Box, IconButton, Typography } from '@mui/material'
import TrendsFilterIconDefault from '../../../../components/svg/coverage-analyzer/TrendsFilterIconDefault'

/* Utils */
import {
  AnalyzerData,
  CPITimePeriod,
  IntegrationInstance,
  LastSeenWithin,
  OperatingSystem
} from '../../../../../models'

/* Components */
import { ChartHeader, ChartWrapper } from './trendsTab.styles'
import AnalysisChart from './components/AnalysisChart'
import TrendsFilterIcon from '../../../../components/svg/coverage-analyzer/TrendsFilterIcon'
import FilterComponent from '../../components/FilterComponent'
import AnalysisChartDescription from './components/AnalysisChartDescription'

interface Props {
  firstSource: string
  secondSource: string
  selectedOperatingSystem: OperatingSystem
  setSelectedOperatingSystem: Dispatch<SetStateAction<OperatingSystem>>
  chartData: AnalyzerData | null
  selectedTimePeriod: CPITimePeriod
  setSelectedTimePeriod: Dispatch<SetStateAction<CPITimePeriod>>
  fetchData: ({
    timePeriod,
    seenWithin,
    operatingSystem
  }: {
    timePeriod?: CPITimePeriod
    seenWithin?: LastSeenWithin
    operatingSystem?: OperatingSystem
  }) => void
  instances: IntegrationInstance[]
  selectedInstances: IntegrationInstance[]
  isTrendsFilterActive: boolean
  setIsTrendsFilterActive: Dispatch<SetStateAction<boolean>>
  chartLoading: boolean
}

const TrendsTab: FC<Props> = ({
  firstSource,
  secondSource,
  selectedOperatingSystem,
  setSelectedOperatingSystem,
  chartData,
  selectedTimePeriod,
  setSelectedTimePeriod,
  fetchData,
  instances,
  selectedInstances,
  isTrendsFilterActive,
  setIsTrendsFilterActive,
  chartLoading
}) => {
  const [showFilter, setShowFilter] = useState(false)

  const firstSourceId = useMemo(() => {
    return selectedInstances[0].id
  }, [selectedInstances])

  const secondSourceId = useMemo(() => {
    return selectedInstances[1].id
  }, [selectedInstances])

  const resetFilter = () => {
    setSelectedTimePeriod(CPITimePeriod.Week)
    setSelectedOperatingSystem(OperatingSystem.All)
    setIsTrendsFilterActive(false)
    fetchData({ timePeriod: CPITimePeriod.Week, operatingSystem: OperatingSystem.All })
  }

  return (
    <>
      <ChartWrapper>
        <Box className="chart-container">
          <ChartHeader>
            <Box className="info-block">
              <Box>
                <Typography className="square blue" />
                <Typography className="name">{selectedInstances[0].name}</Typography>
              </Box>

              <Box>
                <Typography className="square orange" />
                <Typography className="name">{selectedInstances[1].name}</Typography>
              </Box>
            </Box>

            <IconButton sx={{ padding: 0 }} onClick={() => setShowFilter(!showFilter)}>
              {showFilter ? (
                <TrendsFilterIcon />
              ) : (
                <Box className="filter-icon-block">
                  <TrendsFilterIconDefault />

                  {isTrendsFilterActive && (
                    <Box className="active-circle">
                      <Box className="circle" />
                    </Box>
                  )}
                </Box>
              )}
            </IconButton>
          </ChartHeader>

          <Box className="chart-wrapper">
            <AnalysisChart
              selectedTimePeriod={selectedTimePeriod}
              chartData={chartData}
              firstSourceId={firstSourceId}
              secondSourceId={secondSourceId}
              instances={instances}
              chartLoading={chartLoading}
            />
          </Box>
        </Box>

        <FilterComponent showFilter={showFilter} setShowFilter={setShowFilter} resetFilter={resetFilter}>
          <AnalysisChartDescription
            setIsFilterActive={setIsTrendsFilterActive}
            firstSource={firstSource}
            secondSource={secondSource}
            selectedOperatingSystem={selectedOperatingSystem}
            setSelectedOperatingSystem={setSelectedOperatingSystem}
            selectedTimePeriod={selectedTimePeriod}
            setSelectedTimePeriod={setSelectedTimePeriod}
            fetchData={fetchData}
          />
        </FilterComponent>
      </ChartWrapper>
    </>
  )
}

export default TrendsTab
