import styled from '@emotion/styled/macro'
import { Box } from '@mui/material'

export const Container = styled(Box)`
  border-radius: 16px;
  width: 748px;
  margin: 32px auto 0 auto;
  border: 1px solid #4d444b;

  .MuiAccordion-root {
    padding-right: 0;
    padding-left: 0;
    border-radius: 16px !important;
    background: linear-gradient(0deg, rgba(255, 169, 252, 0.08) 0%, rgba(255, 169, 252, 0.08) 100%);

    .MuiAccordionSummary-content {
      flex-direction: column;
      margin-top: 24px;
      margin-bottom: 24px;
    }

    .MuiAccordionDetails-root {
      padding-left: 0;
      padding-right: 0;
      border-top: 1px solid #4d444b;
    }

    .content-block {
      padding-right: 24px;
      padding-left: 24px;
      margin-top: 18px;

      .header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-right: 16px;
        padding-left: 16px;
        margin-bottom: 8px;

        .name {
          color: #d0c3cc;
          font-family: 'Quicksand', sans-serif;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;
          letter-spacing: 0.25px;
        }

        .value {
          width: 175px;
          color: #d0c3cc;
          font-family: 'Quicksand', sans-serif;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;
          letter-spacing: 0.25px;
        }
      }

      .content {
        .content-item {
          border-radius: 16px;
          background: linear-gradient(0deg, rgba(255, 169, 252, 0.11) 0%, rgba(255, 169, 252, 0.11) 100%);
          padding: 8px 16px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 4px;

          .cpi-block {
            display: flex;
            align-items: baseline;
            gap: 16px;

            .cpi {
              border-radius: 18px;
              background: #debdd9;
              font-family: 'Quicksand', sans-serif;
              font-size: 12px;
              font-style: normal;
              font-weight: 500;
              line-height: 16px;
              letter-spacing: 0.5px;
              color: #3f293e;
              padding: 2px 8px;
            }

            .name {
              color: #e9e0e4;
              font-family: 'Quicksand', sans-serif;
              font-size: 16px;
              font-style: normal;
              font-weight: 500;
              line-height: 24px;
              letter-spacing: 0.1px;
              max-width: 310px;
            }
          }

          .value {
            width: 175px;
            color: #e9e0e4;
            font-family: 'Quicksand', sans-serif;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
            letter-spacing: 0.5px;
          }

          .MuiOutlinedInput-root {
            border-radius: 4px;
            border: 1px solid #998d96;
            color: #d0c3cc;
            font-family: 'Quicksand', sans-serif;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
            letter-spacing: 0.5px;
            height: 56px;
          }

          .Mui-focused {
            border: 2px solid #ffa9fc;

            input {
              width: 100%;
            }

            .percentage {
              display: none;
            }
          }
        }
      }

      .line {
        margin-top: 16px;
        margin-bottom: 16px;
        width: 100%;
        height: 1px;
        background: #998d96;
      }
    }
  }

  .MuiFormControl-root {
    width: 175px;
  }

  .length3 input {
    width: 28px;
  }

  .length2 input {
    width: 18px;
  }

  .length1 input {
    width: 9px;
  }

  .MuiTypography-root {
    color: #d0c3cc;
  }

  .title {
    color: #e9e0e4;
    font-family: 'Quicksand', sans-serif;
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
    margin-bottom: 8px;
  }

  .desc {
    color: #e9e0e4;
    font-family: 'Quicksand', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.5px;
  }
`
