import React from 'react'

const NewSvg = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="13" height="14" viewBox="0 0 13 14" fill="none">
    <path
      d="M10.5 5.24967L11.2292 3.64551L12.8333 2.91634L11.2292 2.18717L10.5 0.583008L9.77083 2.18717L8.16667 2.91634L9.77083 3.64551L10.5 5.24967ZM6.125 5.54134L4.66667 2.33301L3.20833 5.54134L0 6.99967L3.20833 8.45801L4.66667 11.6663L6.125 8.45801L9.33333 6.99967L6.125 5.54134ZM10.5 8.74967L9.77083 10.3538L8.16667 11.083L9.77083 11.8122L10.5 13.4163L11.2292 11.8122L12.8333 11.083L11.2292 10.3538L10.5 8.74967Z"
      fill="white"
    />
  </svg>
)

export default NewSvg