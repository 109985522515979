import React from 'react'

const ResetIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      d="M19.05 4.9375C17.2375 3.125 14.75 2 11.9875 2C6.4625 2 2 6.475 2 12C2 17.525 6.4625 22 11.9875 22C16.65 22 20.5375 18.8125 21.65 14.5H19.05C18.025 17.4125 15.25 19.5 11.9875 19.5C7.85 19.5 4.4875 16.1375 4.4875 12C4.4875 7.8625 7.85 4.5 11.9875 4.5C14.0625 4.5 15.9125 5.3625 17.2625 6.725L13.2375 10.75H21.9875V2L19.05 4.9375Z"
      fill="#7F747C"
    />
  </svg>
)

export default ResetIcon
