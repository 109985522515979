import { Box } from '@mui/material'
import styled from '@emotion/styled/macro'

export const DataSourceWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  .header-wrapper {
    display: flex;
    align-items: center;

    sup {
      vertical-align: super;
      font-size: 10px;
    }

    .source-selector-title {
      color: #fff;
      font-family: Quicksand, sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: 0.1px;
      margin-bottom: 4px;
    }
  }
`
