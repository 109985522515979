const LabelEditIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
      <path
        d="M10.4412 6.91403L11.0852 7.55803L4.74322 13.9H4.09922V13.256L10.4412 6.91403ZM12.9612 2.70003C12.7862 2.70003 12.6042 2.77003 12.4712 2.90303L11.1902 4.18403L13.8152 6.80903L15.0962 5.52803C15.3692 5.25503 15.3692 4.81403 15.0962 4.54103L13.4582 2.90303C13.3182 2.76303 13.1432 2.70003 12.9612 2.70003ZM10.4412 4.93303L2.69922 12.675V15.3H5.32422L13.0662 7.55803L10.4412 4.93303Z"
        fill="white"
      />
    </svg>
  )
}

export default LabelEditIcon
