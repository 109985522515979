import { Box, Breadcrumbs } from '@mui/material'
import styled from '@emotion/styled/macro'

export const HeaderContainer = styled(Box)`
  position: relative;
  z-index: 100;
  border-bottom: 1px solid #4d444b;
  margin-left: 24px;
  margin-right: 24px;
  margin-top: 50px;

  .breadcrumbs-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    max-width: 100%;
    padding: 16px 0 0 24px;

    .header-actions {
      display: flex;
      align-items: center;

      .favorite-icon {
        margin-right: 34px;

        .MuiButtonBase-root {
          &:hover,
          &:focus,
          &:active {
            background: rgba(202, 196, 208, 0.08);
          }
        }
      }
    }
  }

  .title-wrapper {
    padding-left: 24px;

    &.title-wrapper {
      padding: 0 0 24px 24px;

      .header-title {
        color: #fff;
        font-family: 'Quicksand', sans-serif;
        font-size: 28px;
        font-style: normal;
        font-weight: 400;
        line-height: 36px;
        text-transform: capitalize;
        margin-top: 18px;
        margin-bottom: 8px;
      }
    }
  }
`

export const HeaderBackground = styled(Box)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url('/category-page-bg.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top center;
  z-index: -1;
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.15);
  filter: drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.3));
  mix-blend-mode: luminosity;
  border-radius: 8px 8px 0 0;
`

export const HeaderBreadcrumbs = styled(Breadcrumbs)`
  .MuiLink-root,
  .current-breadcrumb {
    font-family: 'Quicksand', sans-serif;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.5px;
    text-decoration: none;
  }

  .MuiLink-root {
    color: #debdd9;
  }

  .current-breadcrumb {
    color: #ffa9fc;
  }

  .MuiBreadcrumbs-separator {
    color: #debdd9;
  }
`
