import { Box } from '@mui/material'
import styled from '@emotion/styled/macro'

export const MiscControlWrapper = styled(Box)`
  display: flex;
  flex-flow: column wrap;
  margin-bottom: 24px;

  .misc-control-title {
    font-family: Quicksand, sans-serif;
    letter-spacing: 0.1px;
    white-space: nowrap;
    margin-bottom: 4px;
    display: inline-block;
    color: #fff;
    font-size: 16px;
    font-style: normal;
    font-weight: 510;
    line-height: normal;
  }

  .pointer {
    cursor: pointer;
  }

  .system-wrapper {
    display: flex;
    align-items: center;
  }
`

export const CoverageWrapper = styled(Box)`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  margin-top: 16px;
  gap: 16px;
`

export const CoverageItem = styled(Box)<{ isActive?: boolean; disabled?: string }>`
  border-radius: 8px;
  border: ${({ isActive }) => (isActive ? '1px solid #590060' : '1px solid #998D96')};
  background: ${({ isActive }) => (isActive ? '#590060' : 'transparent')};
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 16px;
  width: 47%;
  cursor: pointer;

  ${({ disabled }) =>
    disabled &&
    disabled === 'true' &&
    `
      opacity: 0.38;
      pointer-events: none;
      cursor: default;
      color: #ffa9fc;
    `};

  .name {
    color: #fff;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
`
