import {
  CPITimePeriod,
  HistoryBenchmarkDataValues,
  HistoryBenchmarkDataValuesExtended,
  HistoryPerformanceData,
  HistoryPerformanceDataExtended
} from '../../../../../../models'
import { endOfWeek, startOfWeek } from 'date-fns'
import dayjs from 'dayjs'
import * as d3 from 'd3'
import { getUtcDateAndTime } from '../../../utils'

export const oddBarColor = '#3754FC'
export const evenBarColor = '#5468F3'
export const pinkStroke = '#FFA9FC'

export const filterHistoricalData = (
  months: number,
  data: HistoryPerformanceData[]
): HistoryPerformanceData[] | HistoryPerformanceDataExtended[] => {
  const currentDate = new Date(data[data.length - 1].xValue)

  return data
    .filter((d: any) => {
      if (!d.xValue) return false
      const dataDate = new Date(d.xValue)
      const diff =
        currentDate.getMonth() - dataDate.getMonth() + 12 * (currentDate.getFullYear() - dataDate.getFullYear())

      return diff <= months
    })
    .map((n) => ({ ...n, xValue: getUtcDateAndTime(n.xValue).labelFormatDate }))
}

export const filterHistoricalDateByDay = (data: HistoryPerformanceData[], day: string): HistoryPerformanceData[] => {
  let d = day
  const curDat = data.filter((n) => n.xValue.includes(day))
  const currentDate = curDat[0].xValue
  if (currentDate) d = currentDate
  const currentMonth = new Date(d).getMonth()
  const currentYear = new Date(d).getFullYear()

  return data.filter((e: any) => {
    const thisMonth = new Date(e.xValue).getMonth()
    const thisYear = new Date(e.xValue).getFullYear()

    return thisMonth === currentMonth && thisYear === currentYear
  })
}

export const filterHistoricalDateByMonth = (
  data: HistoryPerformanceData[],
  month: string
): HistoryPerformanceData[] | HistoryPerformanceDataExtended[] => {
  let d = month
  const curDat = data.filter((n) => n.xValue.includes(month))
  if (!curDat.length) return []
  const currentDate = curDat[0].xValue
  if (currentDate) d = currentDate
  const currentMonth = d.slice(5, 7)
  const currentYear = d.slice(0, 4)

  return data.filter((e: any) => {
    const thisMonth = e.xValue.slice(5, 7)
    const thisYear = e.xValue.slice(0, 4)

    return thisMonth === currentMonth && thisYear === currentYear
  })
}

export const filterBenchmarkingDateByMonth = (
  data: HistoryBenchmarkDataValues[],
  month: string
): HistoryBenchmarkDataValues[] | HistoryBenchmarkDataValuesExtended[] => {
  const utcMonth = getUtcDateAndTime(month).month
  const utcYear = getUtcDateAndTime(month).year

  return data.filter((e) => {
    const thisUtcMonth = getUtcDateAndTime(e.date).month
    const thisUtcYear = getUtcDateAndTime(e.date).year

    return thisUtcMonth === utcMonth && utcYear === thisUtcYear
  })
}

export const filterHistoricalDateByWeek = (data: HistoryPerformanceData[], day: string): HistoryPerformanceData[] => {
  const date = new Date(day)
  const startOfWeekDate = startOfWeek(date, { weekStartsOn: 1 })
  const endOfWeekDate = endOfWeek(date, { weekStartsOn: 1 })
  const startTime = new Date(startOfWeekDate).getTime()
  const endTime = new Date(endOfWeekDate).getTime()

  return data.filter((e) => {
    const currentDayTime = new Date(e.xValue).getTime()

    return currentDayTime >= startTime && currentDayTime < endTime
  })
}

export const formatHistoricalXLabel = (
  date: string,
  timePeriod: CPITimePeriod,
  filteredData: HistoryPerformanceData[]
) => {
  if (timePeriod === CPITimePeriod.Month) {
    const groupedDays = filterHistoricalDateByMonth(filteredData, date) as HistoryPerformanceDataExtended[]

    if (groupedDays.length < 1) return dayjs(date).format('MMM DD')
    const current = groupedDays.find((n) => n.xValue === date)

    if (current) {
      const rangeFrom = current.start
      let rangeTo = current.end
      const rangeFromMonth = new Date(current.start).getMonth() + 1
      const rangeToMonth = new Date(current.end).getMonth() + 1
      const rangeToFormat = rangeFromMonth === rangeToMonth ? 'D' : 'MMM D'

      const today = new Date().getTime()
      const end = new Date(rangeTo).getTime()
      const todayFormatted = dayjs(today).format('YYYY-MM-DD')
      if (current.start === todayFormatted) {
        return dayjs(current.start).format('MMM D')
      }
      if (today < end) {
        rangeTo = new Date(today).toISOString()
      }

      return `${dayjs(rangeFrom).format('MMM D')}-${dayjs(rangeTo).format(rangeToFormat)}`
    }
    const rangeFrom = groupedDays[0].start
    let rangeTo = groupedDays[0].end
    const rangeFromMonth = new Date(groupedDays[0].start).getMonth() + 1
    const rangeToMonth = new Date(groupedDays[0].end).getMonth() + 1
    const rangeToFormat = rangeFromMonth === rangeToMonth ? 'D' : 'MMM D'

    const today = new Date().getTime()
    const end = new Date(rangeTo).getTime()
    if (today < end) {
      rangeTo = new Date(today).toISOString()
    }

    return `${dayjs(rangeFrom).format('MMM D')}-${dayjs(rangeTo).format(rangeToFormat)}`
  }
  if (timePeriod === CPITimePeriod.ThreeMonths || timePeriod === CPITimePeriod.SixMonths) {
    const groupedDays = filterHistoricalDateByMonth(filteredData, date)

    if (groupedDays.length < 1) return dayjs(date).format('MMM DD')

    return dayjs(date).format("MMM 'YY")
  }
  if (timePeriod === CPITimePeriod.PastYear) {
    return dayjs(date).format('MM/YY')
  }

  return ''
}

export const formatBenchmarkingXLabel = (
  date: string,
  timePeriod: CPITimePeriod,
  filteredData: HistoryBenchmarkDataValues[] | HistoryBenchmarkDataValuesExtended[],
  index: number
) => {
  if (!date) return ''
  if (timePeriod === CPITimePeriod.Month) {
    const groupedDays = filterBenchmarkingDateByMonth(filteredData, date) as HistoryBenchmarkDataValuesExtended[]
    const current = groupedDays.find((n) => n.date === date)

    if (current) {
      const rangeFrom = current.start
      let rangeTo = current.end
      const rangeFromMonth = new Date(current.start).getMonth() + 1
      const rangeToMonth = new Date(current.end).getMonth() + 1
      const rangeToFormat = rangeFromMonth === rangeToMonth ? 'D' : 'MMM D'

      const today = new Date().getTime()
      const end = new Date(rangeTo).getTime()
      const todayFormatted = dayjs(today).format('YYYY-MM-DD')
      if (current.date === todayFormatted) {
        return dayjs(current.date).format('MMM D')
      }
      if (today < end) {
        rangeTo = new Date(today).toISOString()
      }

      return `${dayjs(rangeFrom).format('MMM D')}-${dayjs(rangeTo).format(rangeToFormat)}`
    }
    const rangeFrom = groupedDays[0].start
    let rangeTo = groupedDays[0].end
    const rangeFromMonth = new Date(groupedDays[0].start).getMonth() + 1
    const rangeToMonth = new Date(groupedDays[0].end).getMonth() + 1
    const rangeToFormat = rangeFromMonth === rangeToMonth ? 'D' : 'MMM D'

    const today = new Date().getTime()
    const end = new Date(rangeTo).getTime()
    const todayFormatted = dayjs(today).format('YYYY-MM-DD')
    if (groupedDays[0].date === todayFormatted) {
      return dayjs(groupedDays[0].date).format('MMM D')
    }
    if (today < end) {
      rangeTo = new Date(today).toISOString()
    }

    return `${dayjs(rangeFrom).format('MMM D')}-${dayjs(rangeTo).format(rangeToFormat)}`
  }
  const groupedDays = filterBenchmarkingDateByMonth(filteredData, date)
  if (!groupedDays.length) return ''

  const midPoint = groupedDays.length > 1 ? Math.floor(groupedDays.length / 2) : 0
  const label = groupedDays[midPoint]
  const foundDataIndex = filteredData.findIndex((item) => item.date === label.date)

  let format = "MMM 'YY"
  if (timePeriod === CPITimePeriod.PastYear) {
    format = 'MM/YY'
  }
  if (index === foundDataIndex) {
    return dayjs(label.date).format(format)
  }

  return ''
}

export const groupHistoricalDataByWeeks = (data: HistoryPerformanceData[]): HistoryPerformanceDataExtended[] => {
  const groupedData: {
    [key: string]: {
      xValue: string
      yValue: number
      start: string
      end: string
      dummy?: boolean
    }
  } = {}

  data.forEach((entry) => {
    const date = new Date(entry.xValue)
    const year = date.getFullYear()

    const startOfWeekDate = startOfWeek(date, { weekStartsOn: 1 })
    const startDay = startOfWeekDate.getDate()
    const startMonth = startOfWeekDate.getMonth() + 1

    const endOfWeekDate = endOfWeek(date, { weekStartsOn: 1 })
    const endYear = endOfWeekDate.getFullYear()
    const endDay = endOfWeekDate.getDate()
    const endMonth = endOfWeekDate.getMonth() + 1

    /* Note: If it's stupid, but it works, it aint stupid.
     * Temporary quick solution to handle UTC dates in different timezones.
     * Needs to be refactored to handle all dates to support timezones
     * instead of user local time */
    const y = entry.xValue.slice(0, 4)
    const m = entry.xValue.slice(5, 7)
    const day = entry.xValue.slice(8, 10)
    const groupKey = `${y}-W${m}-${day}`

    if (!groupedData[groupKey]) {
      groupedData[groupKey] = {
        xValue: entry.xValue,
        yValue: entry.yValue,
        start: `${year}-${startMonth < 10 ? '0' : ''}${startMonth}-${startDay < 10 ? '0' : ''}${startDay}`,
        end: `${endYear}-${endMonth < 10 ? '0' : ''}${endMonth}-${endDay < 10 ? '0' : ''}${endDay}`
      }
    }
  })

  const result: HistoryPerformanceDataExtended[] = []
  for (const key in groupedData) {
    const prunedXValue = key.replace('W', '')
    const xValue = dayjs(prunedXValue).format('YYYY-MM-DD')

    result.push({
      xValue: xValue || '',
      yValue: groupedData[key].yValue,
      start: groupedData[key].start || '',
      end: groupedData[key].end || '',
      dummy: groupedData[key].dummy
    })
  }

  return result
}

export const groupBenchmarkingDataByMonths = (
  data: HistoryBenchmarkDataValues[]
): HistoryBenchmarkDataValuesExtended[] => {
  const groupedData: {
    [key: string]: {
      less: number
      equal: number
      more: number
      date: string
      start: string
      end: string
      month: string
      week?: number
      dummy?: boolean
    }
  } = {}

  const d = data.reverse()
  // Parse date strings into Date objects and group by months
  d.forEach((entry) => {
    const date = new Date(entry.date)
    const year = date.getFullYear()

    const startOfWeekDate = startOfWeek(date, { weekStartsOn: 1 })
    const startDay = startOfWeekDate.getDate()
    const startMonth = startOfWeekDate.getMonth() + 1
    // const dateMonth = new Date(date).getMonth() + 1

    /* TODO: Check regressions */
    // if (startMonth < dateMonth) return

    const endOfWeekDate = endOfWeek(date, { weekStartsOn: 1 })
    const endYear = endOfWeekDate.getFullYear()
    const endDay = endOfWeekDate.getDate()
    const endMonth = endOfWeekDate.getMonth() + 1
    const groupKey = `${year}-W${startMonth < 10 ? '0' : ''}${startMonth}-${startDay < 10 ? '0' : ''}${startDay}`
    const month = date.getMonth() + 1

    if (!groupedData[groupKey]) {
      groupedData[groupKey] = {
        date: entry.date,
        less: entry.less,
        more: entry.more,
        equal: entry.equal,
        month: `${year}-${month}`,
        start: `${year}-${startMonth < 10 ? '0' : ''}${startMonth}-${startDay < 10 ? '0' : ''}${startDay}`,
        end: `${endYear}-${endMonth < 10 ? '0' : ''}${endMonth}-${endDay < 10 ? '0' : ''}${endDay}`,
        dummy: entry.dummy
      }
    }
  })

  const result: any[] = []
  for (const key in groupedData) {
    const prunedDate = key.replace('W', '')
    const formattedDate = dayjs(prunedDate).format('YYYY-MM-DD')

    result.push({
      date: formattedDate || '',
      less: groupedData[key].less || 0,
      equal: groupedData[key].equal || 0,
      more: groupedData[key].more || 0,
      start: groupedData[key].start || '',
      end: groupedData[key].end || '',
      month: groupedData[key].month || '',
      dummy: groupedData[key].dummy || undefined
    })
  }

  return result.reverse()
}

export const groupBenchmarkingDataByWeeks = (
  data: HistoryBenchmarkDataValues[]
): HistoryBenchmarkDataValuesExtended[] => {
  const groupedData: {
    [key: string]: {
      date: string
      less: number
      equal: number
      more: number
      start: string
      end: string
    }
  } = {}

  data.forEach((entry) => {
    const date = new Date(entry.date)
    const year = date.getFullYear()

    const startOfWeekDate = startOfWeek(date, { weekStartsOn: 1 })
    const startDay = startOfWeekDate.getDate()
    const startMonth = startOfWeekDate.getMonth() + 1

    const endOfWeekDate = endOfWeek(date, { weekStartsOn: 1 })
    const endYear = endOfWeekDate.getFullYear()
    const endDay = endOfWeekDate.getDate()
    const endMonth = endOfWeekDate.getMonth() + 1
    // const groupKey = `${year}-W${startMonth < 10 ? '0' : ''}${startMonth}-${startDay < 10 ? '0' : ''}${startDay}`
    const y = entry.date.slice(0, 4)
    const m = entry.date.slice(5, 7)
    const day = entry.date.slice(8, 10)
    const groupKey = `${y}-W${m}-${day}`

    if (!groupedData[groupKey]) {
      groupedData[groupKey] = {
        date: entry.date,
        less: entry.less,
        more: entry.more,
        equal: entry.equal,
        start: `${year}-${startMonth < 10 ? '0' : ''}${startMonth}-${startDay < 10 ? '0' : ''}${startDay}`,
        end: `${endYear}-${endMonth < 10 ? '0' : ''}${endMonth}-${endDay < 10 ? '0' : ''}${endDay}`
      }
    }
  })

  const result: HistoryBenchmarkDataValuesExtended[] = []
  for (const key in groupedData) {
    const prunedDate = key.replace('W', '')
    const formattedDate = dayjs(prunedDate).format('YYYY-MM-DD')
    result.push({
      date: formattedDate || '',
      less: groupedData[key].less || 0,
      equal: groupedData[key].equal || 0,
      more: groupedData[key].more || 0,
      start: groupedData[key].start || '',
      end: groupedData[key].end || ''
    })
  }

  return result
}

export const findHistoricalUniqueMonths = (
  filteredData: HistoryPerformanceData[],
  timePeriod: CPITimePeriod,
  data: HistoryPerformanceData[]
): string[] => {
  const uniqueMonths: string[] = []

  if (timePeriod === CPITimePeriod.Month) {
    const grouped = groupHistoricalDataByWeeks(data)
    return grouped.map((n) => n.xValue)
  } else {
    filteredData.forEach((item: any) => {
      const date = new Date(item.xValue)
      const year = date.getFullYear()
      const month = date.getMonth() + 1
      const monthKey = `${year}-${month.toString().padStart(2, '0')}`

      if (!uniqueMonths.includes(monthKey)) {
        uniqueMonths.push(monthKey)
      }
    })
  }

  return uniqueMonths.sort((a, b) => (new Date(a).getTime() > new Date(b).getTime() ? 1 : -1))
}

export interface GetHistoricalPathAndPositionsProps {
  uniqueMonths: string[]
  stripeWidth: number
  height: number
  filteredData: HistoryPerformanceData[]
  selectedTimePeriod: CPITimePeriod
  yAxisDomain: number[]
  chartHeightStart?: number
  chartWidthStart?: number
}

export const getHistoricalPathAndPositions = ({
  uniqueMonths,
  stripeWidth,
  height,
  filteredData,
  selectedTimePeriod,
  yAxisDomain,
  chartHeightStart,
  chartWidthStart
}: GetHistoricalPathAndPositionsProps) => {
  let pathStr = ``
  const positions: any = []

  let hasDummyData = false
  let pathStartPoint = { x: 0, y: 0 }

  for (let i = 0; i < uniqueMonths.length; i++) {
    const stripeWidthStart = stripeWidth * i
    const stripeWidthEnd = stripeWidth * (i + 1)

    let groupedMonths: HistoryPerformanceData[] = []
    if (selectedTimePeriod === CPITimePeriod.Week) {
      groupedMonths = filterHistoricalDateByDay(filteredData, uniqueMonths[i])
    } else if (selectedTimePeriod === CPITimePeriod.Month) {
      groupedMonths = filterHistoricalDateByWeek(filteredData, uniqueMonths[i])
    } else {
      groupedMonths = filterHistoricalDateByMonth(filteredData, uniqueMonths[i])
    }

    const stripeMiddle = stripeWidth / (groupedMonths.length * 2)

    const xScaleEach = d3
      .scaleBand()
      .domain(groupedMonths.map((d) => d.xValue))
      .range([
        i === 0 && chartWidthStart
          ? stripeWidthStart + chartWidthStart
          : chartWidthStart
          ? stripeWidthStart + chartWidthStart / (i + 1)
          : stripeWidthStart,
        stripeWidthEnd
      ])
      .padding(0)
    const yScaleEach = d3
      .scaleLinear()
      .domain(yAxisDomain)
      .nice()
      .range([chartHeightStart ? height + chartHeightStart : height, 0])

    groupedMonths.forEach((each, index: number) => {
      let startString = 'L'
      if (i === 0 && index === 0) {
        startString = 'M'
      }
      const position = {
        date: each.xValue,
        x: (xScaleEach(each.xValue) || 0) + stripeMiddle,
        y: yScaleEach(each.yValue),
        hasDummyData
      }
      if (i === 0 && index === 0) {
        startString = 'M'
        pathStartPoint = position
      }

      if (each.dummy) {
        hasDummyData = true
        position.hasDummyData = true
      } else {
        if (hasDummyData) {
          pathStartPoint = position
          startString = 'M'
          hasDummyData = false
          position.hasDummyData = false
        }
        pathStr += `${startString}${position.x} ${position.y - 2}`
      }
      positions.push(position)
    })
  }

  const sortedPositions = positions.sort((a: any, b: any) =>
    new Date(b.date).getTime() > new Date(a.date).getTime() ? -1 : 1
  )

  return [pathStr, sortedPositions, pathStartPoint]
}

export interface GetCategoryPathAndPositionsProps {
  uniqueMonths: string[]
  stripeWidth: number
  height: number
  filteredData: HistoryPerformanceData[]
  yAxisDomain: number[]
  chartHeightStart: number
  chartWidthStart: number
}

export const getCategoryPathAndPositions = ({
  uniqueMonths,
  stripeWidth,
  height,
  filteredData,
  yAxisDomain,
  chartHeightStart,
  chartWidthStart
}: GetCategoryPathAndPositionsProps) => {
  let pathStr = ``
  const positions: any = []

  let hasDummyData = false
  let pathStartPoint = { x: 0, y: 0 }

  for (let i = 0; i < uniqueMonths.length; i++) {
    const stripeWidthStart = stripeWidth * i
    const stripeWidthEnd = stripeWidth * (i + 1)

    const groupedMonths = filterHistoricalDateByWeek(filteredData, uniqueMonths[i])

    const stripeMiddle = stripeWidth / (groupedMonths.length * 2)

    const xScaleEach = d3
      .scaleBand()
      .domain(groupedMonths.map((d) => d.xValue))
      .range([
        i === 0 && chartWidthStart
          ? stripeWidthStart + chartWidthStart
          : chartWidthStart
          ? stripeWidthStart + chartWidthStart / (i + 1)
          : stripeWidthStart,
        stripeWidthEnd
      ])
      .padding(0)
    const yScaleEach = d3.scaleLinear().domain(yAxisDomain).nice().range([height, chartHeightStart])

    groupedMonths.forEach((each, index: number) => {
      let startString = 'L'
      if (i === 0 && index === 0) {
        startString = 'M'
      }
      const position = {
        x: (xScaleEach(each.xValue) || 0) + stripeMiddle,
        y: yScaleEach(each.yValue),
        value: each.yValue,
        hasDummyData
      }

      if (i === 0 && index === 0) {
        startString = 'M'
        pathStartPoint = position
      }

      if (each.dummy) {
        hasDummyData = true
      } else {
        if (hasDummyData) {
          pathStartPoint = position
          startString = 'M'
          hasDummyData = false
        }
        /* -2: Add padding bottom for the line to be visible when all values are 0 */
        pathStr += `${startString}${position.x} ${position.y - 2}`
      }
      positions.push(position)
    })
  }

  return [pathStr, positions, pathStartPoint]
}
