const AuditCPIActivatedIcon = () => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.75 4.5H5.25C2.7675 4.5 0.75 6.5175 0.75 9C0.75 11.4825 2.7675 13.5 5.25 13.5H12.75C15.2325 13.5 17.25 11.4825 17.25 9C17.25 6.5175 15.2325 4.5 12.75 4.5ZM12.75 12H5.25C3.5925 12 2.25 10.6575 2.25 9C2.25 7.3425 3.5925 6 5.25 6H12.75C14.4075 6 15.75 7.3425 15.75 9C15.75 10.6575 14.4075 12 12.75 12ZM12.75 6.75C11.505 6.75 10.5 7.755 10.5 9C10.5 10.245 11.505 11.25 12.75 11.25C13.995 11.25 15 10.245 15 9C15 7.755 13.995 6.75 12.75 6.75Z"
      fill="#FFA9FC"
    />
  </svg>
)

export default AuditCPIActivatedIcon
