import { Box } from '@mui/material'
import styled from '@emotion/styled/macro'

export const Container = styled(Box)<{ isactive: string }>`
  border-radius: 16px;
  background: ${({ isactive }) => (isactive === 'true' ? '#372a36' : '#362E35')};
  width: 100%;
  display: flex;
  padding: 16px;
  align-items: center;
  gap: 16px;
  min-height: 105px;

  .MuiCheckbox-root {
    color: #d0c3cc;
    padding: 0;

    &.Mui-checked {
      color: #ffa9fc;
    }
  }

  .inner {
    display: flex;
    flex-direction: column;
    gap: 16px;

    .inner-header,
    .inner-footer {
      display: flex;
      align-items: center;
    }

    .inner-header {
      gap: 16px;

      .title {
        color: #fff;
        font-family: Quicksand, sans-serif;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }
    }

    .inner-footer {
      gap: 24px;

      .category-chip-group {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 8px;
        position: relative;

        .info-icon {
          width: 24px;
          height: 24px;
          min-width: 24px;
          min-height: 24px;

          &:hover {
            cursor: pointer;
          }

          path {
            fill: #d9bfd4;
          }
        }
      }
    }
  }
`
