import { FC } from 'react'
import { Box, Typography } from '@mui/material'

/* Components */
import { EnvironmentStatsWrapper, StatChip } from './environmentStats.styles'
import { Hashtag } from '../../../../components/svg'

interface Props {
  cpisActivated: number
  dataSources: number
  activeUsers: number
  availableCPIs: number
  isstub?: string
}

const EnvironmentStats: FC<Props> = ({ cpisActivated, dataSources, activeUsers, availableCPIs, isstub }) => (
  <EnvironmentStatsWrapper>
    <Box className="header">
      <Hashtag />
      <Typography>Environment Stats</Typography>
    </Box>
    <StatChip i={0} isstub={isstub}>
      <Box className="inner">
        <Box className="marker" />
        {!isstub && (
          <>
            <Typography className="status-value">{cpisActivated}</Typography>
            <Typography className="status-label">CPIs activated</Typography>
          </>
        )}
      </Box>
    </StatChip>
    <StatChip i={1} isstub={isstub}>
      <Box className="inner">
        <Box className="marker" />
        {!isstub && (
          <>
            <Typography className="status-value">{dataSources}</Typography>
            <Typography className="status-label">Data Sources</Typography>
          </>
        )}
      </Box>
    </StatChip>
    <StatChip i={2} isstub={isstub}>
      <Box className="inner">
        <Box className="marker" />
        {!isstub && (
          <>
            <Typography className="status-value">{activeUsers}</Typography>
            <Typography className="status-label">Active Users</Typography>
          </>
        )}
      </Box>
    </StatChip>
    <StatChip i={3} isstub={isstub}>
      <Box className="inner">
        <Box className="marker" />
        {!isstub && (
          <>
            <Typography className="status-value">{availableCPIs}</Typography>
            <Typography className="status-label">Available CPIs</Typography>
          </>
        )}
      </Box>
    </StatChip>
  </EnvironmentStatsWrapper>
)

export default EnvironmentStats
