import { FC, ReactNode } from 'react'
import { Stepper, Step, StepLabel, Box } from '@mui/material'
import { colors } from '../../../../../theme/'

const steps = ['Account Credentials', 'Select Projects', 'Schema Mapping']

interface Props {
  activeStep: number
}

const JiraIntegrationTitle: FC<Props> = ({ activeStep }) => (
  <Box sx={{ width: '100%' }}>
    <Stepper
      activeStep={activeStep}
      sx={{
        '.MuiStepConnector-root': {
          padding: '0 20px'
        },

        '.MuiStepLabel-label': {
          color: '#FAFAFA !important'
        }
      }}
    >
      {steps.map((label) => {
        const stepProps: { completed?: boolean } = {}
        const labelProps: {
          optional?: ReactNode
        } = {}
        return (
          <Step key={label} {...stepProps}>
            <StepLabel
              {...labelProps}
              sx={{
                '.MuiStepLabel-iconContainer': {
                  svg: {
                    color: 'rgba(255, 179, 0, 0.12)',

                    text: {
                      fill: '#000',
                      fontSize: '12px',
                      lineHeight: '20px',
                      fontFamily: '"Archivo", sans-serif'
                    },

                    '&.Mui-active': {
                      color: colors.rawSienneCore
                    }
                  }
                },

                '.MuiStepLabel-label': {
                  color: '#FAFAFA !important',
                  letterSpacing: '0.5px',
                  fontSize: '16px',
                  lineHeight: '24px'
                }
              }}
            >
              {label}
            </StepLabel>
          </Step>
        )
      })}
    </Stepper>
  </Box>
)

export default JiraIntegrationTitle
