import React, { FC } from 'react'
import { FormControl, Select, CategoryMenuProps } from './reportsTimePeriodSelectField.styles'
import { SelectDropdownIcon } from '../../../../components/svg'
import { IconButton, InputAdornment, ListItemText, MenuItem, OutlinedInput, SelectProps } from '@mui/material'

interface Props extends SelectProps {
  options: string[]
  value: any
  handleChange: any
  minWidth?: string
  disabled: boolean
  handleCustomClick?: () => void
  anchorEl: any
  setAnchorEl: any
  closeCustomPicker: any
  pickerOpen: boolean
}

const ReportsTimePeriodSelectField: FC<Props> = ({
  options,
  handleChange,
  placeholder,
  minWidth,
  value,
  disabled,
  handleCustomClick,
  anchorEl,
  setAnchorEl,
  closeCustomPicker,
  pickerOpen,
  ...rest
}) => {
  const handleClick = (event: any) => {
    if (disabled) return
    setAnchorEl(event.currentTarget)
  }

  const getMenuOptionText = (text: string) => {
    if (text === '1W') {
      return 'Past 1 Week'
    }
    if (text === '1M') {
      return 'Past 1 Month'
    }
    if (text === '3M') {
      return 'Past 3 Months'
    }
    if (text === '6M') {
      return 'Past 6 Months'
    }
    if (text === 'Past Year') {
      return 'Past 1 Year'
    }

    return text
  }

  return (
    <FormControl
      sx={{ minWidth }}
      isselected={
        placeholder !== 'Sort' && (value.length > 1 || (value.length === 1 && value[0] !== '')) ? 'true' : 'false'
      }
      is_opened={anchorEl ? 'true' : 'false'}
      is_disabled={disabled ? 'true' : 'false'}
    >
      <Select
        isselected={
          placeholder !== 'Sort' && (value.length > 1 || (value.length === 1 && value[0] !== '')) ? 'true' : 'false'
        }
        labelId="reports-customize-select-label"
        id="reports-customize-select"
        displayEmpty
        MenuProps={{
          ...CategoryMenuProps,
          PaperProps: {
            ...CategoryMenuProps.PaperProps,
            style: { maxHeight: 'unset' }
          },
          anchorEl,
          open: Boolean(anchorEl),
          onClose: () => setAnchorEl(null)
        }}
        sx={{
          minWidth,
          '& .MuiSelect-icon': { transform: anchorEl ? 'rotate(180deg)' : 'rotate(0deg)' }
        }}
        IconComponent={(props) => <SelectDropdownIcon {...props} />}
        renderValue={(selected) => {
          if (Array.isArray(selected)) {
            const pruned = selected.filter((s: string) => s !== '')
            if (pruned.length === 0) {
              return placeholder
            }
            if (pruned.length === 1) {
              return pruned[0]
            }

            return `${pruned[0]} +${pruned.length - 1}`
          }

          return selected && (selected as string).length > 0 ? selected : 'Select'
        }}
        inputProps={{ 'aria-label': 'Without label' }}
        {...rest}
        placeholder="Select"
        label="Select"
        value={value}
        onChange={handleChange}
        input={
          <OutlinedInput
            onMouseDown={handleClick}
            startAdornment={
              <InputAdornment position="start">
                <IconButton edge="start">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path
                      d="M19 4H18V2H16V4H8V2H6V4H5C3.89 4 3.01 4.9 3.01 6L3 20C3 21.1 3.89 22 5 22H19C20.1 22 21 21.1 21 20V6C21 4.9 20.1 4 19 4ZM19 20H5V10H19V20ZM19 8H5V6H19V8ZM9 14H7V12H9V14ZM13 14H11V12H13V14ZM17 14H15V12H17V14ZM9 18H7V16H9V18ZM13 18H11V16H13V18ZM17 18H15V16H17V18Z"
                      fill="#D0C3CC"
                    />
                  </svg>
                </IconButton>
              </InputAdornment>
            }
          />
        }
      >
        {options.length > 0 && options.map((option) => (
          <MenuItem
            key={option}
            value={option}
            onClick={() => {
              if (!rest.multiple) {
                setAnchorEl(null)
                closeCustomPicker()
              }
            }}
            disableRipple
          >
            <ListItemText primary={getMenuOptionText(option)} />
          </MenuItem>
        ))}
        {/* Custom Picker Option */}
        <MenuItem
          sx={{
            display: 'flex',
            alignItems: 'center',
            backgroundColor: pickerOpen ? 'rgba(208, 188, 255, 0.16) !important' : '',
            justifyContent: 'space-between',

            '.MuiListItemText-root': {
              width: 'fit-content',
              maxWidth: '60px',

              '.MuiTypography-root': {
                width: 'fit-content'
              }
            },

            svg: {
              marginLeft: '8px'
            }
          }}
          onClick={handleCustomClick}
        >
          <ListItemText primary="Custom" />
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10 17L15 12L10 7V17Z" fill="#D0C3CC" />
          </svg>
        </MenuItem>
      </Select>
    </FormControl>
  )
}

export default ReportsTimePeriodSelectField
