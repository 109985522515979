import React, { Dispatch, FC, SetStateAction } from 'react'
import { Box, IconButton, Typography, Divider } from '@mui/material'
import dayjs from 'dayjs'

/* Utils */
import { dateFormat, formatNumberCommas } from '../../../../lib/utils'
import { Pagination } from '../useCoverageAnalyzerData'
import { TableHeaders, defaultTableHeaders, OperatingSystem, LastSeenWithin } from '../../../../models'

/* Components */
import TableSearch from './TableSearch'
import ThemeButton from '../../../components/buttons/primary/ThemeButton'
import ExportCsvIcon from '../../../components/svg/ExportCsvIcon'
import TrendsFilterIcon from '../../../components/svg/coverage-analyzer/TrendsFilterIcon'
import ColumnsSelectField from './ColumnsSelectField'
import { TableToolbarWrapper } from './TableToolbar.styles'
import TrendsFilterIconDefault from '../../../components/svg/coverage-analyzer/TrendsFilterIconDefault'

interface Props {
  search: string
  setSearch: Dispatch<SetStateAction<string>>
  count: number
  isFilterActive: boolean
  showFilter: boolean
  setShowFilter: Dispatch<SetStateAction<boolean>>
  setColumns: Dispatch<SetStateAction<TableHeaders[]>>
  columns: TableHeaders[]
  pagination: Pagination
  handleExport: ({
    integrationIds,
    operatingSystem,
    lastSeenWithin,
    searchTerm,
    filters,
    sortBy,
    direction
  }: {
    integrationIds?: string[]
    operatingSystem?: OperatingSystem
    lastSeenWithin?: LastSeenWithin
    searchTerm?: string
    filters?: boolean[]
    sortBy?: string
    direction?: string
  }) => void
  exporting: boolean
}

const TableToolbar: FC<Props> = ({
  search,
  setSearch,
  count,
  isFilterActive,
  showFilter,
  setShowFilter,
  setColumns,
  columns,
  pagination,
  handleExport,
  exporting
}) => {
  const date = dayjs().format(dateFormat.lastUpdate)

  const handleChange = (e: any, index: number) => {
    const temp = [...columns]
    temp[index].show = e.target.checked
    setColumns(temp)
  }

  const resetColumns = () => {
    const temp = [...defaultTableHeaders]
    temp.map((item) => {
      item.show = true
      return item
    })
    setColumns(temp)
  }

  return (
    <TableToolbarWrapper className="table-toolbar-wrapper">
      <Box className="left-side">
        <Typography className="date">{date}</Typography>
      </Box>
      <Box className="right-side">
        <ThemeButton themevariant="secondary" onClick={() => handleExport({})} disabled={!count || exporting}>
          <>
            <ExportCsvIcon />
            Export CSV
          </>
        </ThemeButton>
        <Divider orientation="vertical" flexItem sx={{ borderColor: '#4D444B', mx: '8px' }} />
        <TableSearch search={search} setSearch={setSearch} />

        <IconButton sx={{ padding: 0 }} onClick={() => setShowFilter(!showFilter)}>
          {showFilter ? (
            <TrendsFilterIcon />
          ) : (
            <Box className="filter-icon-block">
              <TrendsFilterIconDefault />
              {isFilterActive && (
                <Box className="active-circle">
                  <Box className="circle" />
                </Box>
              )}
            </Box>
          )}
        </IconButton>

        <ColumnsSelectField columns={columns} reset={resetColumns} handleChange={handleChange} />

        <Typography className="count">
          {formatNumberCommas(count)} out of {formatNumberCommas(pagination.total_records)} items
        </Typography>
      </Box>
    </TableToolbarWrapper>
  )
}

export default TableToolbar
