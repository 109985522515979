import React, { Dispatch, FC, SetStateAction, SyntheticEvent, useEffect, useState } from 'react'
import { Box, Button, CircularProgress, IconButton, Link as MuiLink, Tab, Typography } from '@mui/material'
import { Link, useNavigate } from 'react-router-dom'

/* Utils */
import { hasPermission, isViewerRole, Permission } from '../../../../../lib/utils'
import { AccountSetting, CategoryFramework, CPILibraryItem, CPIManifest } from '../../../../../models'
import { categoryToUrl } from '../../utils'
import { a11yProps } from './LibraryDetailsTabPanel'
import axios from '../../../../../lib/axios'
import { CustomColors } from '../LibraryDetails'

/* Components */
import { LibraryDetailsHoverTooltip } from './LibraryDetailsTooltip'
import {
  ActiveCPIIcon,
  EditIcon,
  ExportReportIcon,
  FavoriteCPIIcon,
  InactiveCpiLibraryIcon,
  NotFavoriteCPIIcon,
  SidebarAddIcon
} from '../../../../components/svg'
import { IntegrationsTabs, IntegrationsTabsWrapper } from '../../../settings/integrations/integrations.styles'
import {
  HeaderBreadcrumbs,
  LibraryDetailsHeaderContainer,
  LibraryHeaderBackground
} from './libraryDetailsHeader.styles'
import NistFrameworkCategory from '../../../../components/nist-framework-category'
import NavigationSelectField from '../../components/NavigationSelectField'

interface Props {
  cpi: CPILibraryItem | null
  currentSeverityStatus: 'healthy' | 'problematic'
  manifestData: CPIManifest
  accountSettings: AccountSetting | null
  favorite: boolean
  active: boolean
  setDrawerOpen: Dispatch<SetStateAction<boolean>>
  authUser: any
  tabValue: number
  setTabValue: Dispatch<SetStateAction<number>>
  showBenchmarkingTab: boolean
  updateFavorite: () => void
  handleReportExport: () => void
  setInitialLoad: Dispatch<SetStateAction<boolean>>
  setRenderingChart: Dispatch<SetStateAction<boolean>>
  isExportingReport: boolean
  showReport: boolean
  customColors: CustomColors | null
}

const LibraryDetailsHeader: FC<Props> = ({
  currentSeverityStatus,
  manifestData,
  favorite,
  active,
  setDrawerOpen,
  authUser,
  tabValue,
  setInitialLoad,
  setTabValue,
  showBenchmarkingTab,
  updateFavorite,
  setRenderingChart,
  handleReportExport,
  isExportingReport,
  accountSettings,
  showReport,
  cpi,
  customColors
}) => {
  const navigate = useNavigate()
  const [favoriteLoading, setFavoriteLoading] = useState(false)
  const [activeCpis, setActiveCpis] = useState([] as CPILibraryItem[])

  useEffect(() => {
    fetchListOfCPI().catch((e) => e)
  }, [])

  const fetchListOfCPI = async () => {
    try {
      const allCpisRes = await axios.get('/api/v3/cpis')
      const activeList = allCpisRes.data.data.cpis.filter((item: CPILibraryItem) => item.configuration)
      setActiveCpis(activeList)
    } catch (e) {
      console.error(e)
    }
  }

  const handleFavorite = async () => {
    setFavoriteLoading(true)

    try {
      updateFavorite()
    } catch (e) {
      console.error(e)
    } finally {
      setFavoriteLoading(false)
    }
  }

  const handleEdit = () => {
    setDrawerOpen(true)
  }

  const handleActivate = () => {
    setDrawerOpen(true)
  }

  const handleTabChange = (event: SyntheticEvent, newValue: number) => {
    setTabValue(newValue)
    navigate(newValue === 1 && active ? '#about' : newValue === 0 && !active ? '#about' : '#')
  }

  const renderHeaderCpiName = (displayName: string): string => {
    if (displayName.length === 3) {
      return `CPI-${displayName}`
    }
    return displayName
  }

  const handleCategoryClick = () => {
    const cat = manifestData.category?.title ? manifestData.category.title : manifestData.categories[0]
    const url = categoryToUrl(cat)
    navigate(`/library/categories/${url === 'vulnerabilty-management' ? 'vulnerability-management' : url}`)
  }

  const toLink = () => {
    navigate(`/library/categories/${manifestData.category.id}`)
  }

  return (
    <LibraryDetailsHeaderContainer
      severity={currentSeverityStatus}
      category={manifestData.category}
      customColors={customColors || undefined}
    >
      <LibraryHeaderBackground />
      <Box className="breadcrumbs-wrapper">
        <HeaderBreadcrumbs aria-label="breadcrumb">
          <MuiLink component={Link} color="inherit" to="/">
            Home
          </MuiLink>
          <MuiLink component={Link} color="inherit" to="/library" state={{ refetchCpis: true }}>
            Indicator Library
          </MuiLink>
          <Typography className="current-breadcrumb">{renderHeaderCpiName(manifestData.display_name)}</Typography>
        </HeaderBreadcrumbs>
      </Box>
      <Box className="actions-wrapper">
        <NavigationSelectField
          label=""
          options={activeCpis}
          setRenderingChart={setRenderingChart}
          setInitialLoad={setInitialLoad}
          placeholder="Active Cpi"
          value={cpi?.display_name + ' ' + cpi?.title}
          minWidth="160px !important"
          noborder="true"
          version="cpi"
        />

        {showReport && (
          <LibraryDetailsHoverTooltip title="Export PDF Report" arrow>
            <IconButton
              onClick={handleReportExport}
              className="export-report-btn"
              disabled={
                isViewerRole(authUser) ||
                !hasPermission(Permission.CPIConfigIntegrationView, authUser) ||
                isExportingReport
              }
            >
              {isExportingReport ? <CircularProgress size="24px" color="secondary" /> : <ExportReportIcon />}
            </IconButton>
          </LibraryDetailsHoverTooltip>
        )}
        <LibraryDetailsHoverTooltip title={favorite ? 'Favorite' : 'Remove Favorite'} arrow>
          <IconButton onClick={handleFavorite} disabled={favoriteLoading}>
            {favorite ? <FavoriteCPIIcon /> : <NotFavoriteCPIIcon />}
          </IconButton>
        </LibraryDetailsHoverTooltip>
        {active ? (
          <LibraryDetailsHoverTooltip title="Configuration Settings" arrow placement="bottom-end">
            <IconButton
              onClick={handleEdit}
              className="edit-button"
              disabled={isViewerRole(authUser) || !hasPermission(Permission.CPIConfigIntegrationView, authUser)}
            >
              <EditIcon />
            </IconButton>
          </LibraryDetailsHoverTooltip>
        ) : (
          <Button
            className="settings-btn"
            onClick={handleActivate}
            disabled={isViewerRole(authUser) || !hasPermission(Permission.CPIConfigIntegrationView, authUser)}
          >
            <SidebarAddIcon />
            Activate
          </Button>
        )}
      </Box>
      <Box className="title-wrapper">
        <Typography className="header-title">{manifestData.title}</Typography>
      </Box>
      <Box className="chips-wrapper">
        <Box className="cpi-chip">{renderHeaderCpiName(manifestData.display_name)}</Box>
        {accountSettings?.cyFramework === CategoryFramework.NIST ? (
          <NistFrameworkCategory
            toLink={() => toLink()}
            cpiName={manifestData.display_name}
            categoryId={manifestData.category?.id}
            categoryName={manifestData.category?.title}
          />
        ) : accountSettings?.cyFramework === CategoryFramework.Onyxia ? (
          <Box className="cpi-category" onClick={handleCategoryClick}>
            {manifestData.category.title}
          </Box>
        ) : (
          <>
            {manifestData.category && (
              <Box className="cpi-category custom" onClick={handleCategoryClick}>
                {manifestData.category.title}
              </Box>
            )}
          </>
        )}
        {active ? (
          <Box className="cpi-active-button cpi-active">
            <ActiveCPIIcon />
            Active
          </Box>
        ) : (
          <Box className="cpi-active-button cpi-inactive">
            <InactiveCpiLibraryIcon />
            Inactive
          </Box>
        )}

        <Box className="cpi-framework-panel">
          {manifestData.cybersecurity_frameworks &&
            manifestData.cybersecurity_frameworks.map((framework) => {
              return (
                <Box className="cpi-framework" key={framework.logo_path}>
                  <img src={`/${framework.logo_path}`} alt={framework.name} />
                  <Typography className="framework-title">{framework.display_name}</Typography>
                </Box>
              )
            })}
        </Box>
      </Box>
      <IntegrationsTabsWrapper custom={true}>
        {active ? (
          <IntegrationsTabs
            value={tabValue}
            onChange={handleTabChange}
            aria-label="library details tabs"
            TabIndicatorProps={{
              style: { display: 'none' }
            }}
            isdetails="true"
          >
            <Tab label="Data Trends" {...a11yProps(0)} />
            <Tab label="About" {...a11yProps(1)} />
            {showBenchmarkingTab && <Tab label="Benchmarking" {...a11yProps(2)} />}
          </IntegrationsTabs>
        ) : (
          <IntegrationsTabs
            value={tabValue}
            onChange={handleTabChange}
            aria-label="library details tabs"
            TabIndicatorProps={{
              style: { display: 'none' }
            }}
            isdetails="true"
          >
            <Tab label="About" {...a11yProps(0)} />
            {showBenchmarkingTab && <Tab label="Benchmarking" {...a11yProps(1)} />}
          </IntegrationsTabs>
        )}
      </IntegrationsTabsWrapper>
    </LibraryDetailsHeaderContainer>
  )
}

export default LibraryDetailsHeader
