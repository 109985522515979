import React, { FC, useState } from 'react'
import {
  AuditLogFormControl,
  AuditLogSelect,
  CategoryMenuProps
} from 'ui/pages/library/library-details/sections/trends-tab/components/TrendsChartSelectField.styles'
import { SelectDropdownIcon } from 'ui/components/svg'
import { ListItemText, MenuItem, OutlinedInput, SelectProps } from '@mui/material'
import { useField } from 'formik'

interface Props extends SelectProps {
  options: string[]
  minWidth?: string
  noborder?: string
  labeltext?: string
  disabled: boolean
  hideSelected?: boolean
  name: string
}

const ControlledTrendsChartSelectField: FC<Props> = ({
  options,
  labeltext,
  placeholder,
  noborder,
  name,
  minWidth,
  disabled,
  hideSelected,
  ...rest
}) => {
  const [field] = useField(name as string)
  const [anchorEl, setAnchorEl] = useState(null)

  const handleClick = (event: any) => {
    if (disabled) return
    setAnchorEl(event.currentTarget)
  }

  return (
    <AuditLogFormControl
      sx={{ minWidth }}
      isselected={
        hideSelected
          ? 'false'
          : placeholder !== 'Sort' && (field.value.length > 1 || (field.value.length === 1 && field.value[0] !== ''))
          ? 'true'
          : 'false'
      }
      is_opened={anchorEl ? 'true' : 'false'}
      is_disabled={disabled ? 'true' : 'false'}
    >
      <AuditLogSelect
        noborder={noborder}
        isselected={
          hideSelected
            ? 'false'
            : placeholder !== 'Sort' && (field.value.length > 1 || (field.value.length === 1 && field.value[0] !== ''))
            ? 'true'
            : 'false'
        }
        labelId="library-select-label"
        id="library-select"
        displayEmpty
        MenuProps={{
          ...CategoryMenuProps,
          PaperProps: {
            ...CategoryMenuProps.PaperProps,
            style: { maxHeight: 'unset' }
          },
          anchorEl,
          open: Boolean(anchorEl),
          onClose: () => setAnchorEl(null)
        }}
        sx={{
          minWidth,
          '& .MuiSelect-icon': { transform: anchorEl ? 'rotate(180deg)' : 'rotate(0deg)' }
        }}
        IconComponent={(props) => <SelectDropdownIcon {...props} />}
        renderValue={(selected) => {
          if (Array.isArray(selected)) {
            const pruned = selected.filter((s: string) => s !== '')
            if (pruned.length === 0) {
              return placeholder
            }
            if (pruned.length === 1) {
              return pruned[0]
            }

            return `${pruned[0]} +${pruned.length - 1}`
          }

          if (labeltext) {
            return typeof noborder !== 'undefined' ? `${labeltext} ${selected}` : selected
          }
          return typeof noborder !== 'undefined'
            ? `Sort by: ${selected}`
            : (selected as any).length > 0
            ? selected
            : 'Select'
        }}
        inputProps={{ 'aria-label': 'Without label' }}
        {...field}
        {...rest}
        input={<OutlinedInput onMouseDown={handleClick} />}
      >
        {options.map((option) => (
          <MenuItem
            key={option}
            value={option}
            onClick={() => {
              if (!rest.multiple) {
                setAnchorEl(null)
              }
            }}
            disableRipple
          >
            <ListItemText primary={option} />
          </MenuItem>
        ))}
      </AuditLogSelect>
    </AuditLogFormControl>
  )
}

export default ControlledTrendsChartSelectField
