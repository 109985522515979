import { createTheme } from '@mui/material'

const muiTheme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1512
    }
  },
  typography: {
    fontFamily: ['Quicksand', 'Rubik', 'Source Sans Pro', 'sans-serif'].join(','),
    body1: {
      fontSize: '16px',
      color: '#FFFFFF',
      lineHeight: '21px'
    },
    body2: {
      lineHeight: '24px',
      fontSize: '14px',
      fontWeight: 'bold',
      color: '#FFFFFF'
    },
    h5: {
      fontSize: '24px',
      fontWeight: 500,
      lineHeight: '32px',
      color: '#D3CEEF'
    },
    h6: {
      fontSize: '20px',
      fontWeight: 'normal',
      lineHeight: '32px',
      color: '#FFFFFF'
    }
  },
  palette: {
    primary: {
      main: '#3C3854',
      dark: '#2C293D',
      light: '#5E4FB0'
    },
    secondary: {
      main: '#0FD7D7',
      dark: '#DEBDD9',
      light: '#91F7F7'
    },
    error: {
      main: '#DF1B62',
      dark: '#FFB4AB',
      light: '#F13A7C'
    },
    warning: {
      main: '#FBA239',
      dark: '#F98B0B',
      light: '#FBAE55'
    },
    info: {
      main: '#C6C0FB',
      dark: '#B4ACFB',
      light: '#D3CEEF'
    },
    success: {
      main: '#29CA7D',
      dark: '#23B16D',
      light: '#35E18E'
    },
    background: {
      default: '#140E33',
      paper: '#1F1846'
    }
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'Rubik';
          font-style: normal;
          font-display: swap;
          font-weight: 400;
        }
      `
    }
  }
})

export default muiTheme
