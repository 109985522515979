import { Autocomplete, Paper } from '@mui/material'
import styled from '@emotion/styled/macro'

export const StyledAutocomplete = styled(Autocomplete)`
  max-width: 300px;
  height: 40px;
  flex-grow: 1;
  background: transparent;
  border: 1px solid #998D96;
  border-radius: 100px;
  font-family: 'Quicksand', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.1px;
  color: #D0C3CC;

  &.Mui-focused {
    border: 1px solid #FFA9FC;
    border-radius: 16px 16px 0 0 !important;
    border-bottom: none !important;

    fieldset {
      border: none;
      border-bottom: none !important;
    }
  }

  .MuiOutlinedInput-root {
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 0;
    padding-bottom: 0;
    height: 40px;
    color: #e3e3e3;

    .MuiAutocomplete-endAdornment {
      .MuiButtonBase-root {
        padding: 6px;

        &:hover {
          background: rgba(202, 196, 208, 0.08);
        }

        svg {
          path {
            fill: #d0c3cc;
          }
        }
      }
    }

    .MuiOutlinedInput-input {
      padding-left: 10px;
      padding-top: 0;
      padding-bottom: 0;
      height: 40px;
      font-family: 'Quicksand', sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px; /* 142.857% */
      letter-spacing: 0.1px;

      &::placeholder {
        opacity: 1;
        color: #D0C3CC;
        font-family: 'Quicksand', sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px; /* 142.857% */
        letter-spacing: 0.1px;
      }
    }

    &::placeholder {
      opacity: 1;
    }

    fieldset {
      border: none;
    }
`

export const PaperComponent = styled(Paper)`
  width: 268px;
  border-radius: 0 0 16px 16px !important;
  background: linear-gradient(0deg, rgba(255, 169, 252, 0.11), rgba(255, 169, 252, 0.11)), #1e1a1d !important;
  padding: 0;
  border: 1px solid #ffa9fc;
  border-top: none !important;
  margin-top: -1px;
  margin-left: -1px;

  ul {
    li {
      font-family: 'Quicksand', sans-serif;
      font-weight: 500;
      font-size: 14px;
      font-style: normal;
      line-height: 20px;
      letter-spacing: 0.1px;

      svg {
        margin-right: 8px;
      }
    }
  }
`
