import { apiFetchIntegrations, apiFetchConfiguredIntegrations } from '../../network'
import { AppThunk, store } from '../store'
import {
  integrationsLoading,
  integrationsLoaded,
  totalIntegrationsChanged,
  configuredIntegrationsLoaded
} from './integrationsSlice'

export const fetchIntegrations = (): AppThunk<Promise<any>> => async (dispatch) => {
  const accessToken = store.getState().user.token
  if (accessToken === null) {
    return
  }

  dispatch(integrationsLoading(true))
  const integrations = await apiFetchIntegrations()
  dispatch(integrationsLoaded({ integrations }))
  dispatch(integrationsLoading(false))
  dispatch(totalIntegrationsChanged(integrations.length))
}

export const fetchConfiguredIntegrations = (): AppThunk<Promise<any>> => async (dispatch) => {
  const accessToken = store.getState().user.token
  if (accessToken === null) {
    return
  }

  dispatch(integrationsLoading(true))
  const configuredIntegrations = await apiFetchConfiguredIntegrations()
  dispatch(configuredIntegrationsLoaded({ configuredIntegrations }))
  dispatch(integrationsLoading(false))
}
