import { FC } from 'react'
import { Box } from '@mui/material'
import { ThemeButton } from '../../../../components/buttons'
import { FooterContainer } from '../styles/addCpiDrawer.styles'

interface Props {
  handleCancel: () => void
  handleOk: () => void
  actionsDisabled: boolean
}

const Footer: FC<Props> = ({ handleCancel, handleOk, actionsDisabled }) => (
  <FooterContainer className="footer">
    <Box className="footer-actions-wrapper">
      <ThemeButton themevariant="secondary" sx={{ minWidth: '93px' }} onClick={handleCancel} disabled={actionsDisabled}>
        Cancel
      </ThemeButton>
      <ThemeButton themevariant="primary" sx={{ minWidth: '132px' }} onClick={handleOk} disabled={actionsDisabled}>
        OK
      </ThemeButton>
    </Box>
  </FooterContainer>
)

export default Footer
