import * as Yup from 'yup'

export interface FormValues {
  project: string
  issuetype: string
  status: string
}

export const defaultValues = {
  project: '',
  issuetype: '',
  status: ''
}

export const schema = Yup.object({
  project: Yup.string().required('Required'),
  issuetype: Yup.string().required('Required'),
  status: Yup.string().required('Required')
})

export const boldString = (fullString: string, boldPart: string, ignoreSpacing = false) => {
  const regexp = new RegExp(boldPart, 'g')

  return fullString.replace(regexp, ignoreSpacing ? '<b>' + boldPart + '</b>' : '<br><br>' + '<b>' + boldPart + '</b>')
}
