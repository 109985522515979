import React from 'react'
import { useLocation } from 'react-router-dom'
import HeaderComponent from '../header/HeaderComponent'

const Titlebar = () => {
  const currentLocation = useLocation()
  let title = ''

  switch (currentLocation.pathname) {
    case '/settings':
      title = 'Settings'
      break
    case '/settings/vendors':
      title = 'Vendors'
      break
    case '/settings/integrations':
      title = 'Integrations'
      break
    case '/settings/account':
      title = 'Account'
      break
    case '/security-findings':
      title = 'Security Findings'
      break
    case '/risks':
      title = 'Risks'
      break
    case '/products':
      title = 'Products'
      break
    case '/team-management':
      title = 'Team Management'
      break
  }

  return <HeaderComponent breadcrumbs={[{ name: title }]} />
}

export default Titlebar
