import { FC } from 'react'
import { FrameworkCategoryWeightItem, FrameworkType, NistFunction } from '../../../../../../../models'
import { CategoryCircle, NistCategoryCircle, OnyxiaCategoryCircle } from '../../categoryWeightItem.styles'

interface Props {
  framework: FrameworkType
  nistFn: '' | NistFunction
  categoryItem: FrameworkCategoryWeightItem
  thisPath: string
}

const CategoryColor: FC<Props> = ({ framework, nistFn, categoryItem, thisPath }) => {
  if (framework === FrameworkType.Custom || thisPath === 'custom/create/blank') {
    return <CategoryCircle bgcolor={categoryItem.chip_bg_color} />
  }
  if (framework === FrameworkType.Onyxia) {
    return <OnyxiaCategoryCircle className="category-color-circle" categoryid={categoryItem.id} />
  }
  return <NistCategoryCircle className="category-color-circle" fn={nistFn} />
}

export default CategoryColor
