import * as Yup from 'yup'

export interface FormValues {
  jiraUrl: string
  jiraEmail: string
  apiKey: string
}

export const defaultValues = {
  jiraUrl: '',
  jiraEmail: '',
  apiKey: ''
}

export const schema = Yup.object({
  jiraUrl: Yup.string().required('Required'),
  jiraEmail: Yup.string().required('Required'),
  apiKey: Yup.string().required('Required')
})
