import { FC, useRef, useEffect, Dispatch, SetStateAction } from 'react'
import * as d3 from 'd3'

import { Container } from './categoriesDonutChart.styles'
import { FrameworkCategoryWeightItem, FrameworkType, NistFunction } from '../../../../../models'

export interface CategoriesDonutItem {
  name: string
  value: number
  color: string
  id: string
}

interface Props {
  colors: string[]
  data: CategoriesDonutItem[]
  totalValue: number
  framework: FrameworkType
  initialLoad: boolean
  selectedCategoryItem: FrameworkCategoryWeightItem | undefined
  setSelectedCategoryItem: Dispatch<SetStateAction<FrameworkCategoryWeightItem | undefined>>
}

const CategoriesDonutChart: FC<Props> = ({
  colors,
  data,
  initialLoad,
  totalValue,
  framework,
  selectedCategoryItem
  // setSelectedCategoryItem
}) => {
  const chartRef = useRef(null)
  const width = 200 // outer width, in pixels
  const height = 200 // outer height, in pixels
  const outerRadius = Math.min(width, height) / 2 // outer radius of pie, in pixels
  const innerRadius = 65 // inner radius of pie, in pixels (non-zero for donut)
  const boxSize = 200 // graph box size, in pixels

  const nistFn = (categoryName: string) =>
    categoryName.includes('Identify')
      ? NistFunction.Identify
      : categoryName.includes('Respond')
      ? NistFunction.Respond
      : categoryName.includes('Detect')
      ? NistFunction.Detect
      : categoryName.includes('Protect')
      ? NistFunction.Protect
      : categoryName.includes('Recover')
      ? NistFunction.Recover
      : categoryName.includes('Govern')
      ? NistFunction.Govern
      : ''

  const renderChart = (element: any, data: any) => {
    if (!chartRef || !chartRef.current) return

    d3.select(element).select('svg').remove()
    // Create new svg
    const svg = d3
      .select(element)
      .append('svg')
      .attr('preserveAspectRatio', 'xMidYMid meet')
      .attr('height', '100%')
      .attr('width', '100%')
      .attr('viewBox', `0 0 ${boxSize} ${boxSize}`)
      .append('g')
      .attr('transform', `translate(${boxSize / 2}, ${boxSize / 2})`)

    // add first line of text in middle  of doughnut
    svg
      .append('text')
      .attr('text-anchor', 'middle')
      .attr('style', 'font-family')
      .attr('font-family', "'Quicksand',sans-serif")
      .attr('font-size', '20')
      .attr('fill', '#fff')
      .attr('font-size', '32px')
      .attr('font-weight', 500)
      .attr('dy', '0.3em')
      .attr('dx', '-0.3em')
      .text(`${totalValue}`)

    svg
      .append('text')
      .attr('text-anchor', 'middle')
      .attr('style', 'font-family')
      .attr('font-family', "'Quicksand',sans-serif")
      .attr('font-size', '20')
      .attr('fill', '#fff')
      .attr('font-size', '18px')
      .attr('font-weight', 400)
      .attr('dy', '0.5em')
      .attr('dx', '1.40em')
      .text('%')

    const d = data.every((n: any) => n.value === 0)
      ? [
          {
            color: 'rgba(120,26,126,1)',
            id: 'c94be6ac-63d1-4f32-872f-f12f358e7dc4',
            name: 'Training and Awareness123123 1',
            value: 100
          }
        ]
      : data

    const arcGenerator = d3.arc().innerRadius(innerRadius).outerRadius(outerRadius)

    const pieGenerator = d3
      .pie()
      .padAngle(0.02)
      .value((d) => (d as any).value)

    const arcs = svg.selectAll().data(pieGenerator(d)).enter()
    arcs
      .append('path')
      .attr('d', arcGenerator as any)
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      .style('fill', (d, i) => {
        if (!initialLoad) return colors[i]
        if (framework === FrameworkType.Onyxia) {
          return (d as any).data.color
          // const color = getCpiCategoryColors((d as any).data.id)
          //
          // return color.background
        }
        if (framework === FrameworkType.Nist) {
          const nist = nistFn((d as any).data.name)
          switch (nist) {
            case NistFunction.Identify:
              return '#8E9AFF'
            case NistFunction.Protect:
              return '#C77EDA'
            case NistFunction.Detect:
              return '#FFDE6A'
            case NistFunction.Respond:
              return '#FF7F6B'
            case NistFunction.Recover:
              return '#99FDC0'
            case NistFunction.Govern:
            default:
              return '#FF9E47'
          }
        }

        return (d as any).data.color
      })
      .attr('fill-opacity', 1)
      .style('stroke', (d) => {
        const category = (d as any).data.name
        if (selectedCategoryItem?.name === category) {
          return '#fff'
        }
        return 'none'
      })
      .style('stroke-width', (d) => {
        const category = (d as any).data.name
        if (selectedCategoryItem?.name === category) {
          return '2px' // Selected category border width
        }
        return 'none'
      })
    // .on('click', (event, d) => {
    //   setSelectedCategoryItem((d as any).data)
    //   // Re-render to apply active border
    //   renderChart(chartRef.current, data)
    // })

    d3.select('body').append('div').attr('class', 'tooltip-donut').style('opacity', 0)
  }

  useEffect(() => {
    if (data && chartRef?.current) {
      renderChart(chartRef.current, data)
    }
  }, [chartRef, data, selectedCategoryItem, totalValue])

  return (
    <Container className="categories-donut-chart-container">
      <div className="categories-donut-chart" ref={chartRef} />
    </Container>
  )
}

export default CategoriesDonutChart
