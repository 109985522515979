import { Box } from '@mui/material'
import styled from '@emotion/styled/macro'

export const Container = styled(Box)<{ isactive?: string }>`
  display: flex;
  padding: 12px 24px;
  justify-content: space-between;
  align-items: center;
  border-radius: 16px;
  min-height: 72px;
  background: ${({ isactive }) => (isactive && isactive === 'false' ? '#362E35' : '#372a36')};

  &:hover {
    background: #483646;
  }

  .left,
  .right {
    display: flex;
    align-items: center;
    gap: 16px;
    max-width: ${({ isactive }) => (isactive && isactive === 'true' ? 'calc(100% - 156px)' : 'unset')};
  }

  .title {
    color: #fff;
    font-family: Quicksand, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    max-width: 55%;
  }

  .MuiOutlinedInput-root {
    border-radius: 4px;
    border: 1px solid #998d96;
    letter-spacing: 0.5px;
    height: 48px;
    color: #fff;
    text-align: center;
    font-family: Quicksand, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    input {
      padding-left: 22px;
    }

    &:hover {
      border-color: #e9e0e4;
    }

    &.Mui-focused {
      border: 1px solid #ffa9fc !important;
    }

    fieldset {
      border: 0;
    }
  }

  .percentage {
    .MuiTypography-root {
      color: #fff;
      text-align: center;
      font-family: Quicksand, sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }

  .MuiFormControl-root {
    width: 81px;
  }

  .length3 input {
    padding-left: 16px;
  }

  .length1 input {
    padding-left: 32px;
  }

  .Mui-disabled {
    color: #d0c3cc;
    -webkit-text-fill-color: #d0c3cc;
    cursor: not-allowed;
  }

  .Mui-focused {
    border: 1px solid #ffa9fc;

    input {
      width: 100%;
    }
  }

  .close-button {
    color: #ffa9fc;

    &:hover {
      background: #362e35;
    }
  }

  .no-role-weights-wrapper {
    padding-right: 24px;
  }
`
