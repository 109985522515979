import { FC } from 'react'
import { Box, Typography } from '@mui/material'
import moment from 'moment'
import styled from '@emotion/styled/macro'

/* Utils */
import { TableData } from '../../../../../../../models'
import { Vulnerability } from '../../../../../../../models/Vulnerability'

const Wrapper = styled(Box)`
  padding: 19px 0 0 40px;
  display: flex;
  flex-flow: column wrap;
`

const Label = styled(Typography)`
  font-weight: 300;
  font-size: 11px;
  line-height: 16px;
  color: #dbdbdb;
`

const Text = styled(Typography)`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #ededed;
`

const Section = styled(Box)`
  width: 100%;
  display: flex;
  flex-flow: column wrap;
  padding-top: 16px;
`

const SubSection = styled(Box)`
  display: flex;
  margin-top: 4px;

  .MuiTypography-root:first-of-type {
    margin-right: 4px;
  }
`

interface Props {
  task: TableData
  vulnerability: Vulnerability
}

const ViewTicketDescription: FC<Props> = ({ task, vulnerability }) => (
  <Wrapper>
    <Section sx={{ paddingTop: '3px !important' }}>
      <Label>Summary:</Label>
      <Text>{task.summary}</Text>
    </Section>

    <Section>
      <Label>Description:</Label>
      <SubSection>
        <Text>
          <strong>Provider:</strong> {vulnerability.vendorName}
        </Text>
      </SubSection>
      <SubSection>
        <Text>
          <strong>Security Issue:</strong> {vulnerability.vulnerabilityName}
        </Text>
      </SubSection>
      <SubSection>
        <Text>
          <strong>Remediation Details:</strong> {vulnerability.requiredAction}
        </Text>
      </SubSection>
      <SubSection sx={{ marginTop: '16px !important' }}>
        <Text>
          <strong>More Info:</strong> {vulnerability.shortDescription}
        </Text>
      </SubSection>
    </Section>

    <Box sx={{ display: 'flex', maxWidth: '70%' }}>
      <Section>
        <Label>Assignee:</Label>
        <Text>{task.assignee}</Text>
      </Section>
      <Section sx={{ paddingTop: '16px !important' }}>
        <Label>Deadline:</Label>
        <Text>{moment(task.due_date).format('MMMM d, YYYY')}</Text>
      </Section>
    </Box>
  </Wrapper>
)

export default ViewTicketDescription
