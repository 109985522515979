import { FC } from 'react'
import { ThemeButton } from '../../../../components/buttons'
import WeightsIcon from '../../../../components/svg/WeightsIcon'

interface Props {
  onClick: () => void
  disabled?: boolean
}

const EvenWeightButton: FC<Props> = ({ onClick, disabled }) => (
  <ThemeButton
    themevariant="secondary"
    onClick={onClick}
    disabled={disabled}
    sx={{ paddingRight: '24px !important', whiteSpace: 'nowrap' }}
  >
    <>
      <WeightsIcon />
      Even Weights
    </>
  </ThemeButton>
)

export default EvenWeightButton
