import styled from '@emotion/styled'
import { Box } from '@mui/material'

export const ToolbarWrapper = styled(Box)`
  display: flex;
  flex-flow: column wrap;
  width: 100%;
  gap: 24px;
  max-width: 400px;

  .controls-initial-header {
    color: #fff;
    font-size: 16px;
    font-style: normal;
    font-weight: 510;
    line-height: normal;
  }

  &.controls-toolbar-wrapper-main {
    flex-flow: unset;
    max-width: unset;
    align-items: center;

    .action-wrapper {
      margin-top: 0 !important;
      height: 68px;
      align-items: flex-end !important;
      display: flex !important;
    }
  }

  .last-seen {
    display: flex;
    flex-direction: column;
    height: 68px;
    justify-content: space-between;

    .misc-control-title {
      white-space: nowrap;
      color: #fff;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin-bottom: 4px;
    }
  }

  .action-wrapper {
    margin-top: 24px;

    button {
      width: 100%;
    }
  }
`
