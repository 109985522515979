import styled from '@emotion/styled/macro'
import { Box } from '@mui/material'

export const LoadingWrapper = styled(Box)`
  display: block;
  width: 100%;
  height: 100%;
  position: fixed;
  background: #1e1a1d;
  left: 0;
  top: 0;
  z-index: 10000;

  .content {
    position: absolute;
    top: 30%;
    left: 13%;

    .title {
      color: white;
      font-family: Quicksand, sans-serif;
      font-size: 45px;
      font-style: normal;
      font-weight: 400;
      line-height: 52px;
      margin-bottom: 32px;
    }

    .desc {
      color: #e9e0e4;
      font-family: Quicksand, sans-serif;
      font-size: 22px;
      font-style: normal;
      font-weight: 400;
      line-height: 28px;
    }
  }

  .logo {
    position: absolute;
    right: 0;
    bottom: 0;
  }
`

export const FormWrapper = styled(Box)`
  width: 1px;
  height: 1px;
  opacity: 0;
  position: absolute;
`
