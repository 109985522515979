import { FC } from 'react'
import { Box, Typography } from '@mui/material'

import CrowdstrikeInstructions from './CrowdstrikeInstructions'
import CrowdstrikeIntegrationToast from './CrowdstrikeIntegrationToast'
import { TestConfigurationIconButton } from '../../../svg'
import { CrowdstrikeIntegrationStep2Wrapper, CrowdstrikeIntegrationStep2Button } from './crowdstrikeIntegration.styles'

interface Props {
  setupSuccessful: boolean
  toastMessage: string
  showToast: boolean
}

const CrowdstrikeIntegrationStep2: FC<Props> = ({ setupSuccessful, toastMessage, showToast }) => (
  <CrowdstrikeIntegrationStep2Wrapper>
    <CrowdstrikeInstructions
      content={<Typography>Click Test Configuration button to the right to confirm access.</Typography>}
    />
    <Box sx={{ marginLeft: '72px' }}>
      <CrowdstrikeIntegrationStep2Button type="submit">
        <TestConfigurationIconButton />
        Test Configuration
      </CrowdstrikeIntegrationStep2Button>
      {showToast && (
        <CrowdstrikeIntegrationToast
          variant={setupSuccessful ? 'success' : 'error'}
          title={setupSuccessful ? 'The test was successful' : 'Failed to Authenticate'}
          message={toastMessage}
        />
      )}
    </Box>
  </CrowdstrikeIntegrationStep2Wrapper>
)

export default CrowdstrikeIntegrationStep2
