import { FC, useMemo, useState } from 'react'
import { Checkbox, ListItemText, MenuItem, OutlinedInput, SelectProps, Typography } from '@mui/material'

/* Utils */
import useSearchLabel from '../../../hooks/useSearchLabel'

/* Components */
import { SelectDropdownIcon } from '../svg'
import { SelectDropdownMenuProps, LibraryFormControl, LibrarySelect, Wrapper } from './selectDropdown.styles'
import SelectSearchbar from '../form-components/SelectSearchbar'
import ResetSearch from '../form-components/ResetSearch'

export interface LabelOption {
  name: string
  label: string
  icon?: JSX.Element
  action?: () => Promise<void>
}

interface Props extends SelectProps {
  options: LabelOption[]
  value: any
  handleChange: any
  minWidth?: string
  hideSearch?: boolean
  showReset?: boolean
  label?: string
  version?: string
}

const SelectDropdown: FC<Props> = ({
  version,
  label,
  options,
  handleChange,
  placeholder,
  minWidth,
  value,
  hideSearch = false,
  showReset = false,
  ...rest
}) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const { search, setSearch, labelOptions, resetData, highlightMatch } = useSearchLabel(options, () => {
    console.info('reset')
  })

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget)
  }

  const renderValue = (): string => {
    const pruned = value.filter((s: string) => s !== '')
    const option = options.find((n) => n.name === pruned[0])

    if (option) {
      if (pruned.length === 1) {
        return option.label
      }

      if (placeholder && pruned.length === 0) {
        return placeholder
      }

      return `${pruned.length} selected`
    }

    return placeholder || ''
  }

  const svgClassname = useMemo(() => {
    let base = 'MuiSelect-icon MuiSelect-iconOutlined MuiSelect-iconClosed svg-dropdown'
    if (anchorEl) {
      base = base.replaceAll('MuiSelect-iconClosed', 'MuiSelect-iconOpen svg-dropdown')
    } else {
      base = base.replaceAll('MuiSelect-iconOpen', 'MuiSelect-iconClosed svg-dropdown')
    }

    return base
  }, [anchorEl])

  return (
    <Wrapper>
      {label && <Typography className="label-text">{label}</Typography>}

      <LibraryFormControl sx={{ minWidth }} isselected={anchorEl || value.length > 1 ? 'true' : 'false'}>
        <LibrarySelect
          menuopen={anchorEl || value.length > 1 ? 'true' : 'false'}
          isselected={value.length > 1 ? 'true' : 'false'}
          labelId="select-dropdown-label"
          id="select-dropdown"
          displayEmpty
          MenuProps={{
            ...SelectDropdownMenuProps,
            PaperProps: {
              ...SelectDropdownMenuProps.PaperProps,
              sx: {
                ...SelectDropdownMenuProps.PaperProps.sx,
                display: anchorEl ? 'block' : 'none'
              }
            },
            anchorEl,
            disableAutoFocusItem: true,
            autoFocus: false,
            open: Boolean(anchorEl),
            onClose: () => setAnchorEl(null)
          }}
          IconComponent={() => <SelectDropdownIcon className={svgClassname} width={24} height={24} />}
          renderValue={() => renderValue()}
          inputProps={{ 'aria-label': 'Without label' }}
          {...rest}
          value={value}
          onChange={handleChange}
          input={<OutlinedInput onMouseDown={handleClick} />}
          sx={{ minWidth }}
        >
          {!hideSearch && <SelectSearchbar search={search} setSearch={setSearch} placeholder={`Search ${version}`} />}

          {labelOptions.map((option) => (
            <MenuItem
              disableRipple
              autoFocus={false}
              key={option.name}
              value={option.name}
              onClick={() => {
                if (!rest.multiple) {
                  setAnchorEl(null)
                }
                if (option.action) {
                  option.action()
                }
              }}
            >
              {rest.multiple && <Checkbox checked={value.indexOf(option.name) > -1} />}
              <ListItemText primary={highlightMatch(option.label)} />
            </MenuItem>
          ))}

          {showReset && <ResetSearch resetData={resetData} />}
        </LibrarySelect>
      </LibraryFormControl>
    </Wrapper>
  )
}

export default SelectDropdown
