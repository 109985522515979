import { Box, Menu } from '@mui/material'
import styled from '@emotion/styled'

export const Wrapper = styled(Box)``

export const MenuWrapper = styled(Menu)`
  .MuiList-root {
    border-radius: 4px;
    border: 1px solid #4d444b;
    background: #372a36;
    padding: 8px;

    .MuiMenuItem-root {
      border-radius: 4px;
      padding: 8px;

      &:hover {
        background: #573f56;
      }

      .MuiTypography-root {
        padding-left: 8px;
      }
    }
  }

  .action {
    border-top: 1px solid #4d444b;
    padding-bottom: 8px;
    padding-top: 8px;

    button {
      width: 100%;
    }
  }
`
