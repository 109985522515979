import { FC } from 'react'
import { Form, Formik } from 'formik'
import { Box, CircularProgress, Typography } from '@mui/material'
import axios from '../../../../../lib/axios'

/* Utils */
import { useAppDispatch } from '../../../../../store/hooks'
import { defaultValues, FormValues, schema } from './inviteUser.constants'
import type { RadioGroupOption } from '../../../form-components/ControlledRadioGroup'
import ControlledRadioGroup from '../../../form-components/ControlledRadioGroup'
import { closeModal } from '../../../../../store/modals/modalSlice'
import { closeToast, openToast, ToastVariant } from '../../../../../store/toasts/toastSlice'

/* Components */
import { Actions, Content, Header, Wrapper } from './inviteUser.styles'
import { ThemeButton } from '../../../buttons'
import ControlledTextField from '../../../form-components/ControlledTextField'
import { PlusIcon } from '../../../svg'

const renderLabel = (name: string, description: string): JSX.Element => (
  <Box>
    <Typography className="roles-name">{name}</Typography>
    <Typography className="roles-description">{description}</Typography>
  </Box>
)

export const ownerRole: RadioGroupOption = {
  label: renderLabel(
    'Owner',
    'All privileges (there can only be one workspace owner per organization; you will be demoted to Administrator).'
  ),
  name: 'Owner',
  value: 'owner'
}

export const roleOptions: RadioGroupOption[] = [
  {
    label: renderLabel('Administrator', 'All privileges excluding account & subscription management.'),
    name: 'Administrator',
    value: 'admin'
  },
  {
    label: renderLabel('Manager', 'Cybersecurity Performance Indicators read/write privileges'),
    name: 'Manager',
    value: 'cpi_manager'
  },
  {
    label: renderLabel('Viewer', 'Cybersecurity Performance Indicators read privileges'),
    name: 'Viewer',
    value: 'viewer'
  },
  {
    label: renderLabel('Demo', 'Administrator account without write permissions'),
    name: 'Demo',
    value: 'demo'
  }
]

const InviteUser: FC = () => {
  const dispatch = useAppDispatch()

  const handleClose = () => {
    dispatch(closeModal())
  }

  const onSubmit = async (values: FormValues) => {
    const payload = {
      name: values.name,
      email: values.email,
      role: values.role
    }

    try {
      const res = await axios.post('/api/v1/identity/users/invite', payload)

      if (res.status === 200) {
        handleClose()
        dispatch(
          openToast({
            variant: ToastVariant.success,
            props: {
              text: 'Invitation Sent',
              description: `The user account ${values.email} has been invited.`
            }
          })
        )
      }
    } catch (e) {
      dispatch(
        openToast({
          variant: ToastVariant.error,
          props: {
            text: 'Failed sending invitation',
            description: (e as any).response?.data?.message || ''
          }
        })
      )
    } finally {
      setTimeout(() => {
        dispatch(closeToast())
      }, 5000)
    }
  }

  return (
    <Formik initialValues={defaultValues} validationSchema={schema} onSubmit={onSubmit} validateOnMount>
      {({ isSubmitting, isValid, errors }) => (
        <Form autoComplete="off">
          <Wrapper>
            <Header>
              <Typography className="title">Invite New User</Typography>
            </Header>
            <Content>
              <ControlledTextField name="name" label="Name" helperText={errors.name} fullWidth required />
              <ControlledTextField
                data-private
                name="email"
                label="Email"
                helperText={errors.email}
                fullWidth
                required
              />
              <Box className="roles-wrapper">
                <Typography className="roles-title">
                  Assign Role <span>*</span>
                </Typography>
                <ControlledRadioGroup name="role" options={roleOptions} />
              </Box>
            </Content>
            <Actions>
              <ThemeButton themevariant="tertiary" onClick={handleClose}>
                Cancel
              </ThemeButton>
              <ThemeButton
                themevariant="primary"
                type="submit"
                disabled={isSubmitting || !isValid}
                endIcon={isSubmitting && <CircularProgress size="20px" />}
              >
                <>
                  <PlusIcon />
                  Invite New User
                </>
              </ThemeButton>
            </Actions>
          </Wrapper>
        </Form>
      )}
    </Formik>
  )
}

export default InviteUser
