import { Autocomplete } from '@mui/material'
import styled from '@emotion/styled/macro'

export const StyledAutocomplete = styled(Autocomplete)`
  min-width: 300px;
  width: 100%;
  height: 40px;
  flex-grow: 1;
  background: transparent;
  color: #d9bfd4;
  text-overflow: ellipsis;
  font-family: Quicksand, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border-radius: 4px;
  border: 1px solid #998d96;

  @media (max-width: 1330px) {
    min-width: 270px;
  }

  &.Mui-focused {
    border: 1px solid #ffa9fc;
  }

  .MuiOutlinedInput-root {
    padding: 0 8px;
    height: 40px;
    color: #e3e3e3;

    svg {
      path {
        fill: #fff;
      }
    }

    .MuiOutlinedInput-input {
      padding-left: 8px;
      padding-top: 0;
      padding-bottom: 0;
      height: 40px;
      overflow: hidden;
      color: #d9bfd4;
      text-overflow: ellipsis;
      font-family: Quicksand, sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;

      &::placeholder {
        opacity: 1;
        overflow: hidden;
        color: #d9bfd4;
        text-overflow: ellipsis;
        font-family: Quicksand, sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }

    fieldset {
      border: none;
    }
  }
`
