import { useCallback, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useAuth, useLoginWithRedirect } from '@frontegg/react'
import { FronteggContext } from '@frontegg/rest-api'
import { ThemeProvider, Box } from '@mui/material'
import CssBaseline from '@mui/material/CssBaseline'
import axios from '../../lib/axios'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'

/* Utils */
import muiTheme from '../../theme/muiTheme'
import { UserConfiguredIntegration } from '../../models'
import { setAccountSettings, setToken, userTokenSelector } from '../../store/user/userSlice'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import { drawerPropsSelector, DrawerType } from '../../store/drawers/drawerSlice'
import { modalPropsSelector, ModalType, ModalVariant } from '../../store/modals/modalSlice'
import { closeNotification, notificationPropsSelector } from '../../store/notifications/notificationSlice'
import LogRocket from 'logrocket'

/* Components */
import Modal from '../components/modal/Modal'
import MainLayout from '../MainLayout'
import TicketDrawer from '../components/modal/variants/jira-ticket/TicketDrawer'
import Notification from '../components/notification/Notification'
import DeleteTicket from '../components/modal/variants/jira-ticket/components/DeleteTicket'
import JiraIntegration from '../components/modal/variants/jira-integration/JiraIntegration'
import InviteUser from '../components/modal/variants/invite-user/InviteUser'
import DeleteUsers from '../components/modal/variants/delete-users/DeleteUsers'
import DeleteAccount from '../components/modal/variants/delete-account/DeleteAccount'
import ChangeName from '../components/modal/variants/user-profile/sub-modals/ChangeName'
import ChangeEmail from '../components/modal/variants/user-profile/sub-modals/ChangeEmail'
import ChangeRole from '../components/modal/variants/user-profile/sub-modals/ChangeRole'
import TransferOwnership from '../components/modal/variants/user-profile/sub-modals/TransferOwnership'
import Auth0Integration from '../components/modal/variants/auth0-integration/Auth0Integration'
import SentinelIntegration from '../components/modal/variants/sentinel-integration/SentinelIntegration'
import DeleteIntegration from '../components/modal/variants/shared/DeleteIntegration'
import CrowdstrikeIntegration from '../components/modal/variants/crowdstrike-integration/CrowdstrikeIntegration'
import DeleteIntegrationInstance from '../components/modal/variants/delete-integration-instance/DeleteIntegrationInstance'
import DeactivateCpi from '../components/modal/variants/deactivate-cpi/DeactivateCpi'
import DeselectNotification from '../components/modal/variants/deselect-notification/DeselectNotification'
import UserProfile from '../components/modal/variants/user-profile/UserProfile'
import ZoomIn from '../components/modal/variants/formula/ZoomIn'
import DeleteDeactivate from '../components/modal/variants/deactivate-cpi/DeleteDeactivateCPI'
import UpdateSSoModal from '../components/modal/variants/accont-managment/UpdateSSoModal'
import SSoVerificationFailed from '../components/modal/variants/accont-managment/SSoVerificationFailed'
import SSoDeleteNotification from '../components/modal/variants/accont-managment/SSoDeleteNotification'
import CancelChanges from '../components/modal/variants/weights/CancelChanges'
import RevertWeights from '../components/modal/variants/weights/RevertWeights'
import UpdateWeights from '../components/modal/variants/weights/UpdateWeights'

const queryClient = new QueryClient()

const App = () => {
  const dispatch = useAppDispatch()
  const modalProps = useAppSelector(modalPropsSelector)
  const drawerProps = useAppSelector(drawerPropsSelector)
  const accessToken = useAppSelector(userTokenSelector)
  const [configuredIntegrations, setConfiguredIntegrations] = useState<UserConfiguredIntegration[]>([])
  const { message, variant, open, autoHideDelay, title } = useAppSelector(notificationPropsSelector)
  const jiraTicketDrawerOpen = drawerProps && drawerProps.type === DrawerType.jiraTicket
  let content = <></>
  let sx = {}
  let paperStyles = {}
  const disableCloseOnClickOutside = false
  const { user, isAuthenticated, isLoading } = useAuth()
  const loginWithRedirect = useLoginWithRedirect()
  const location = useLocation()
  const showTicketDrawer = false
  const recentlyAddedVendors = window && typeof window !== 'undefined' && localStorage.getItem('recently_added_vendors')
  const costDistributionItems = window && typeof window !== 'undefined' && localStorage.getItem('cost_distribution')

  useEffect(() => {
    if (recentlyAddedVendors) {
      const parsed = JSON.parse(recentlyAddedVendors)
      if (Array.isArray(parsed) && parsed.length > 0) {
        const prunedItems = parsed.filter((n: any) => !!n.display_name && !!n.uuid && !!n.name)
        localStorage.setItem('recently_added_vendors', JSON.stringify(prunedItems))
      }
    }
  }, [])

  useEffect(() => {
    if (costDistributionItems) {
      const parsed = JSON.parse(costDistributionItems)
      if (Array.isArray(parsed) && parsed.length > 0) {
        const prunedCostDistributionItems: any[] = []

        parsed.forEach((item) => {
          const itemExists = prunedCostDistributionItems.find((n) => n.uuid === item.uuid)
          if (!itemExists) {
            prunedCostDistributionItems.push(item)
          }
        })

        localStorage.setItem('cost_distribution', JSON.stringify(prunedCostDistributionItems))
      }
    }
  }, [])

  const fetchConfiguredIntegrations = useCallback(async () => {
    const res = await axios.get('/api/v3/integrations/configured')

    if (res.status === 200) {
      const { data } = res.data
      setConfiguredIntegrations(data.integrations)
    }
  }, [])

  const fetchAccountSettings = async () => {
    try {
      const { data, status } = await axios.get('/api/v3/tenant')
      if (status === 200) {
        dispatch(setAccountSettings(data.data))
        localStorage.setItem('framework', JSON.stringify(data.data.cyFramework))
      }
    } catch (err) {
      console.error(err)
    }
  }

  useEffect(() => {
    if ((modalProps && modalProps.type === 'mssentinel') || (modalProps && modalProps.type === 'crowdstrike')) {
      fetchConfiguredIntegrations().catch((e) => e)
    }
  }, [modalProps])

  useEffect(() => {
    if (open) {
      setTimeout(() => {
        dispatch(closeNotification())
      }, autoHideDelay)
    }
  }, [open])

  useEffect(() => {
    if (!isAuthenticated) {
      loginWithRedirect()
    }
  }, [isAuthenticated, loginWithRedirect])

  useEffect(() => {
    const getUserMetadata = async () => {
      try {
        const token = FronteggContext.getAccessToken()
        dispatch(setToken(token))
      } catch (e) {
        console.error(e)
      }
    }

    if (user && isAuthenticated) {
      if (['production', 'staging'].includes(process.env.REACT_APP_NODE_ENV as string)) {
        LogRocket.identify(user.id, {
          name: user.name,
          email: user.email
        })
      }

      fetchAccountSettings().catch((e) => e)
      getUserMetadata().catch((e) => e)
    }
  }, [isAuthenticated, user])

  if (modalProps) {
    if (modalProps.type === ModalType.auth0) {
      if (modalProps.variant === ModalVariant.delete) {
        sx = { width: '447px' }
        content = <DeleteIntegration type="Auth0" url="/integrations/auth0" />
      } else {
        content = <Auth0Integration variant={modalProps.variant} />
      }
    }
    if (modalProps.type === ModalType.crowdstrike) {
      if (modalProps.variant === ModalVariant.delete) {
        sx = { width: '600px' }
        const integration = configuredIntegrations.find((n) => n.integration_name === 'crowdstrike')
        const url = location.pathname.includes('library')
          ? `/api/v3/cpis/${modalProps?.props?.cpiName || ''}`
          : `/api/v3/integrations/crowdstrike/config/${integration?.id}`
        content = (
          <DeleteIntegration
            type="Crowdstrike"
            url={url}
            description={
              location.pathname.includes('library')
                ? 'If you delete this integration, all information you’ve provided will be deleted and your CPI may be left without a data source. Type “DELETE” to confirm:'
                : undefined
            }
          />
        )
      } else {
        sx = { width: '1128px' }
        content = <CrowdstrikeIntegration />
      }
    }
    if (modalProps.type === ModalType.mssentinel) {
      if (modalProps.variant === ModalVariant.delete) {
        sx = { width: '600px' }
        const integration = configuredIntegrations.find((n) => n.integration_name === 'mssentinel')
        const url = location.pathname.includes('library')
          ? `/api/v3/cpis/${modalProps?.props?.cpiName || ''}`
          : `/api/v3/integrations/mssentinel/config/${integration?.id}`
        content = (
          <DeleteIntegration
            type="Sentinel"
            url={url}
            description={
              location.pathname.includes('library')
                ? 'If you delete this integration, all information you’ve provided will be deleted and your CPI may be left without a data source. Type “DELETE” to confirm:'
                : undefined
            }
          />
        )
      } else {
        sx = { width: '1128px' }
        content = <SentinelIntegration />
      }
    }
    if (modalProps.type === ModalType.jira) {
      if (modalProps.variant === ModalVariant.delete) {
        sx = { width: '447px' }
        content = <DeleteIntegration type="Jira" url="/api/v3/integrations/jira" />
      } else {
        sx = { width: '952px' }
        content = <JiraIntegration variant={modalProps.variant} />
      }
    }
    if (modalProps.type === ModalType.jiraTicket) {
      if (modalProps.variant === ModalVariant.delete) {
        sx = { width: '447px' }
        content = <DeleteTicket />
      }
    }
    if (modalProps.type === ModalType.inviteUser) {
      sx = { width: '560px' }
      paperStyles = {
        background: '#1E1A1D',
        boxShadow: '0 6px 10px 4px rgba(0, 0, 0, 0.15), 0px 2px 3px rgba(0, 0, 0, 0.3)',
        borderRadius: '16px'
      }
      content = <InviteUser />
    }
    if (modalProps.type === ModalType.deleteUsers) {
      sx = { width: '833px' }
      paperStyles = {
        background: '#1E1A1D',
        boxShadow: '0 6px 10px 4px rgba(0, 0, 0, 0.15), 0px 2px 3px rgba(0, 0, 0, 0.3)',
        borderRadius: '16px'
      }
      content = <DeleteUsers />
    }
    if (modalProps.type === ModalType.changeName) {
      sx = { width: '560px' }
      paperStyles = {
        background: '#1E1A1D',
        boxShadow: '0 6px 10px 4px rgba(0, 0, 0, 0.15), 0px 2px 3px rgba(0, 0, 0, 0.3)',
        borderRadius: '16px'
      }
      content = <ChangeName />
    }
    if (modalProps.type === ModalType.changeEmail) {
      sx = { width: '560px' }
      paperStyles = {
        background: '#1E1A1D',
        boxShadow: '0 6px 10px 4px rgba(0, 0, 0, 0.15), 0px 2px 3px rgba(0, 0, 0, 0.3)',
        borderRadius: '16px'
      }
      content = <ChangeEmail />
    }
    if (modalProps.type === ModalType.changeRole) {
      sx = { width: '560px' }
      paperStyles = {
        background: '#1E1A1D',
        boxShadow: '0 6px 10px 4px rgba(0, 0, 0, 0.15), 0px 2px 3px rgba(0, 0, 0, 0.3)',
        borderRadius: '16px'
      }
      content = <ChangeRole />
    }
    if (modalProps.type === ModalType.transferOwnership) {
      sx = { width: '876px' }
      paperStyles = {
        background: '#1E1A1D',
        boxShadow: '0 6px 10px 4px rgba(0, 0, 0, 0.15), 0px 2px 3px rgba(0, 0, 0, 0.3)',
        borderRadius: '16px'
      }
      content = <TransferOwnership />
    }
    if (modalProps.type === ModalType.deleteAccount) {
      sx = { width: '833px' }
      paperStyles = {
        background: '#1E1A1D',
        boxShadow: '0 6px 10px 4px rgba(0, 0, 0, 0.15), 0px 2px 3px rgba(0, 0, 0, 0.3)',
        borderRadius: '16px'
      }
      content = <DeleteAccount />
    }
    if (modalProps.type === ModalType.deleteIntegrationInstance) {
      sx = { width: '784px' }
      paperStyles = {
        background: '#1E1A1D',
        boxShadow: '0 6px 10px 4px rgba(0, 0, 0, 0.15), 0px 2px 3px rgba(0, 0, 0, 0.3)',
        borderRadius: '8px'
      }
      content = <DeleteIntegrationInstance />
    }
    if (modalProps.type === ModalType.deactivateCpi) {
      sx = { width: '582px' }
      paperStyles = {
        background: '#1E1A1D',
        boxShadow: '0 6px 10px 4px rgba(0, 0, 0, 0.15), 0px 2px 3px rgba(0, 0, 0, 0.3)',
        borderRadius: '8px'
      }
      content = <DeactivateCpi />
    }
    if (modalProps.type === ModalType.deleteDeactivation) {
      sx = { width: '784px' }
      paperStyles = {
        background: 'transparent',
        boxShadow: 'none',
        borderRadius: '8px'
      }
      content = <DeleteDeactivate />
    }
    if (modalProps.type === ModalType.ssoSupport) {
      sx = { width: '570px' }
      paperStyles = {
        background: '#1E1A1D',
        boxShadow: '0 6px 10px 4px rgba(0, 0, 0, 0.15), 0px 2px 3px rgba(0, 0, 0, 0.3)',
        borderRadius: '8px',
        padding: '24px',
        border: '1px solid #998D96'
      }
      content = <UpdateSSoModal />
    }
    if (modalProps.type === ModalType.ssoVerificationFailed) {
      sx = { width: '475px' }
      paperStyles = {
        background: '#1E1A1D',
        boxShadow: '0 6px 10px 4px rgba(0, 0, 0, 0.15), 0px 2px 3px rgba(0, 0, 0, 0.3)',
        borderRadius: '8px'
      }
      content = <SSoVerificationFailed />
    }
    if (modalProps.type === ModalType.ssoDelete) {
      sx = { width: '475px' }
      paperStyles = {
        background: '#1E1A1D',
        boxShadow: '0 6px 10px 4px rgba(0, 0, 0, 0.15), 0px 2px 3px rgba(0, 0, 0, 0.3)',
        borderRadius: '8px'
      }
      content = <SSoDeleteNotification />
    }
    if (modalProps.type === ModalType.cancelChangesWeight) {
      sx = { width: '761px' }
      paperStyles = {
        background: '#1E1A1D',
        boxShadow: '0 6px 10px 4px rgba(0, 0, 0, 0.15), 0px 2px 3px rgba(0, 0, 0, 0.3)',
        borderRadius: '8px'
      }
      content = <CancelChanges />
    }
    if (modalProps.type === ModalType.revertDefaultValuesWeight) {
      sx = { width: '761px' }
      paperStyles = {
        background: '#1E1A1D',
        boxShadow: '0 6px 10px 4px rgba(0, 0, 0, 0.15), 0px 2px 3px rgba(0, 0, 0, 0.3)',
        borderRadius: '8px'
      }
      content = <RevertWeights />
    }
    if (modalProps.type === ModalType.updateWeights) {
      sx = { width: '761px' }
      paperStyles = {
        background: '#1E1A1D',
        boxShadow: '0 6px 10px 4px rgba(0, 0, 0, 0.15), 0px 2px 3px rgba(0, 0, 0, 0.3)',
        borderRadius: '8px'
      }
      content = <UpdateWeights />
    }
    if (modalProps.type === ModalType.deselectNotification) {
      sx = { width: '540px' }
      paperStyles = {
        background: '#1E1A1D',
        boxShadow: '0 6px 10px 4px rgba(0, 0, 0, 0.15), 0px 2px 3px rgba(0, 0, 0, 0.3)',
        borderRadius: '8px'
      }
      content = <DeselectNotification />
    }
    if (modalProps.type === ModalType.userProfile) {
      sx = { width: '560px' }
      paperStyles = {
        background: '#1E1A1D',
        boxShadow: '0 6px 10px 4px rgba(0, 0, 0, 0.15), 0px 2px 3px rgba(0, 0, 0, 0.3)',
        borderRadius: '16px',
        scrollBehavior: 'smooth',
        scrollbarWidth: 'none',
        '-ms-overflow-style': 'none',

        '&::-webkit-scrollbar': {
          display: 'none'
        }
      }
      content = <UserProfile />
    }

    if (modalProps.type === ModalType.zoomIn) {
      sx = { width: '1100px' }
      paperStyles = {
        background: '#1E1A1D',
        boxShadow: '0 6px 10px 4px rgba(0, 0, 0, 0.15), 0px 2px 3px rgba(0, 0, 0, 0.3)',
        borderRadius: '16px'
      }
      content = <ZoomIn />
    }
  }

  const pageSize = () => {
    const body = document.getElementsByTagName('body')
    if (location.pathname.includes('/library/cpis') || location.pathname.includes('/settings/integrations/')) {
      body[0].style.overflow = 'auto'
    } else body[0].style.overflow = 'hidden'
  }

  pageSize()

  return (
    <ThemeProvider theme={muiTheme}>
      <QueryClientProvider client={queryClient}>
        <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
        <CssBaseline />
        <Box sx={{ m: 0, p: 0 }}>
          <>
            <Notification message={message} title={title} variant={variant} open={open} autoHideDelay={autoHideDelay} />
            {showTicketDrawer && (
              <TicketDrawer
                open={jiraTicketDrawerOpen}
                isAuthenticated={isAuthenticated && !!user}
                isLoading={isLoading}
              />
            )}
            <Modal
              content={content}
              sx={sx}
              paperStyles={paperStyles}
              disableCloseOnClickOutside={disableCloseOnClickOutside}
            />
            <MainLayout
              user={{ name: user?.name ?? '', profileURL: user?.profileImage ?? '' }}
              accessToken={accessToken}
            />
          </>
        </Box>
      </QueryClientProvider>
    </ThemeProvider>
  )
}

export default App
