import { FC, useMemo, useState } from 'react'
import { Checkbox, ListItemText, MenuItem, OutlinedInput, SelectProps, Typography } from '@mui/material'
import { InstanceCheckedIcon, InstanceUncheckedIcon, SelectDropdownIcon } from '../../../components/svg'
import { CategoryMenuProps, LibraryFormControl, LibrarySelect, Wrapper } from './library-components.styles'
import SelectSearchbar from '../../../components/form-components/SelectSearchbar'
import ResetSearch from '../../../components/form-components/ResetSearch'
import useSearchLabel from '../../../../hooks/useSearchLabel'

export interface LabelOption {
  name: string
  label: string
  icon?: JSX.Element
  action?: () => void
}

interface Props extends SelectProps {
  options: LabelOption[]
  value: any
  handleChange: any
  handleReset: any
  minWidth?: string
  iscustom?: string
  hideSearch?: boolean
  label?: string
  version?: string
}

const LibrarySelectLabelField: FC<Props> = ({
  version,
  label,
  options,
  handleChange,
  iscustom,
  handleReset,
  placeholder,
  minWidth,
  value,
  hideSearch = false,
  ...rest
}) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const { search, setSearch, labelOptions, resetData, highlightMatch } = useSearchLabel(options, handleReset)

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget)
  }

  const renderValue = (): string => {
    const pruned = value.filter((s: string) => s !== '')
    const option = options.find((n) => n.name === pruned[0])

    if (option) {
      if (pruned.length === 1) {
        return option.label
      }

      if (placeholder && pruned.length === 0) {
        return placeholder
      }

      return `${pruned.length} selected`
    }

    return placeholder || ''
  }

  const svgClassname = useMemo(() => {
    let base = 'MuiSelect-icon MuiSelect-iconOutlined MuiSelect-iconClosed svg-dropdown'
    if (anchorEl) {
      base = base.replaceAll('MuiSelect-iconClosed', 'MuiSelect-iconOpen svg-dropdown')
    } else {
      base = base.replaceAll('MuiSelect-iconOpen', 'MuiSelect-iconClosed svg-dropdown')
    }

    return base
  }, [anchorEl])

  return (
    <Wrapper>
      {label && <Typography className="label-text">{label}</Typography>}

      <LibraryFormControl
        sx={{ minWidth }}
        isselected={anchorEl || (placeholder !== 'Sort' && value.length) ? 'true' : 'false'}
        iscustom={iscustom}
      >
        <LibrarySelect
          isselected={placeholder !== 'Sort' && value.length > 1 ? 'true' : 'false'}
          iscustom={iscustom}
          labelId="library-select-label"
          id="library-select"
          displayEmpty
          MenuProps={{
            ...CategoryMenuProps,
            PaperProps: {
              ...CategoryMenuProps.PaperProps,
              sx: {
                ...CategoryMenuProps.PaperProps.sx,
                display: anchorEl ? 'block' : 'none'
              }
            },
            anchorEl,
            disableAutoFocusItem: true,
            autoFocus: false,
            open: Boolean(anchorEl),
            onClose: () => setAnchorEl(null)
          }}
          IconComponent={() => <SelectDropdownIcon className={svgClassname} />}
          renderValue={() => renderValue()}
          inputProps={{ 'aria-label': 'Without label' }}
          {...rest}
          value={value}
          onChange={handleChange}
          input={<OutlinedInput onMouseDown={handleClick} />}
          sx={{ minWidth }}
        >
          {!hideSearch && <SelectSearchbar search={search} setSearch={setSearch} placeholder={`Search ${version}`} />}

          {labelOptions.map((option) => (
            <MenuItem
              disableRipple
              autoFocus={false}
              key={option.name}
              value={option.name}
              onClick={() => {
                if (!rest.multiple) {
                  setAnchorEl(null)
                }
              }}
            >
              {rest.multiple && (
                <Checkbox
                  checked={value.indexOf(option.name) > -1}
                  icon={<InstanceUncheckedIcon />}
                  checkedIcon={<InstanceCheckedIcon />}
                />
              )}
              <ListItemText primary={highlightMatch(option.label)} />
            </MenuItem>
          ))}

          <ResetSearch resetData={resetData} />
        </LibrarySelect>
      </LibraryFormControl>
    </Wrapper>
  )
}

export default LibrarySelectLabelField
