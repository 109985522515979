import { Dispatch, SetStateAction } from 'react'
import * as d3 from 'd3'
import dayjs from 'dayjs'
import {
  fillDatesBetweenRangeStrings,
  findDateRangeForDate,
  getCustomDatesForTimePeriod,
  getUtcDateAndTime,
  sortLabels
} from '../../../../utils'
import { dateFormat } from '../../../../../../../lib/utils'
import {
  ChartDataExtended,
  CPIFrequency,
  CPITimePeriod,
  CustomLabelDate,
  DataStatus,
  Label,
  LabelCount
} from '../../../../../../../models'
import { appendButtonAddEvent, appendButtonCountIndicator } from '.'

interface Props {
  svg: d3.Selection<SVGSVGElement | null, unknown, null, undefined>
  uniqueMonths: string[]
  pinnedLabels?: Label[]
  selectedTimePeriod: CPITimePeriod
  selectedFrequency: CPIFrequency
  filteredData: any[]
  groupedByWeeks: ChartDataExtended | null
  dataDates: LabelCount[]
  height: number
  stripeWidth: number
  setDrawerOpen: Dispatch<SetStateAction<boolean>>
  setMode: Dispatch<SetStateAction<string>>
  offsetLeft: number
  setCustomDate: ({ date, start, end }: CustomLabelDate) => void
  setRedirectToMode: Dispatch<SetStateAction<string>>
  dataStartsAt: string
  status: DataStatus | undefined
}

export const appendEventIndicators = ({
  svg,
  uniqueMonths,
  pinnedLabels,
  selectedTimePeriod,
  selectedFrequency,
  filteredData,
  groupedByWeeks,
  dataDates,
  height,
  stripeWidth,
  setDrawerOpen,
  setMode,
  offsetLeft,
  setCustomDate,
  setRedirectToMode,
  dataStartsAt,
  status
}: Props) => {
  const eventIndicators = svg.selectAll('.event-indicator-count')
  if (!eventIndicators.empty()) eventIndicators.remove()
  const addCustomEventButtons = svg.selectAll('.add-event-button')
  if (!addCustomEventButtons.empty()) addCustomEventButtons.remove()

  const findDateRangeData = () => {
    if (selectedTimePeriod === CPITimePeriod.Month) {
      if (selectedFrequency === CPIFrequency.Weekly) {
        return groupedByWeeks?.values || []
      }
      return filteredData
    }
    return groupedByWeeks ? groupedByWeeks.values : filteredData
  }

  /* Render Event Indicators */
  uniqueMonths.forEach((date, index) => {
    const addEventButton = svg.append('g').attr('class', 'add-event-button')
    const addEventButtonTooltip = svg.append('g').attr('class', 'add-button-tooltip')

    if (pinnedLabels) {
      pinnedLabels.sort(sortLabels)
    }
    const thisDate = getUtcDateAndTime(date)
    const thisDateFormatted = dayjs(thisDate.labelFormatDate).format(dateFormat.customLabel)
    const dateRangeData = findDateRangeData()
    const dateRangeForDate = findDateRangeForDate(
      thisDateFormatted,
      selectedTimePeriod,
      selectedFrequency,
      dateRangeData
    )
    const filledDates = fillDatesBetweenRangeStrings(dateRangeForDate.start, dateRangeForDate.end).map((n) => {
      const d = getUtcDateAndTime(n)

      return d.labelFormatDate
    })
    const filteredFilledDates = dataDates.filter((n) => filledDates.includes(n.date)).map((n) => n.count)
    const foundDataDate = dataDates.find((d) => d.date === date)
    const dateRangeCount = filteredFilledDates.length > 0 ? filteredFilledDates.reduce((a, b) => a + b) : 0
    let indicatorValue = 0

    if (selectedTimePeriod === CPITimePeriod.Week && foundDataDate) {
      indicatorValue = foundDataDate.count
    } else {
      indicatorValue = dateRangeCount || 0
    }

    appendButtonAddEvent({ addEventButton, addEventButtonTooltip, height })

    if (dataDates.some((n) => n.count > 0) && status !== DataStatus.Initializing) {
      const countIndicatorButton = svg.append('g').attr('class', 'event-indicator-count')
      const countIndicatorButtonTooltip = svg.append('g').attr('class', 'count-tooltip')

      appendButtonCountIndicator({
        countIndicatorButton,
        pinnedLabels,
        selectedTimePeriod,
        filledDates,
        indicatorValue,
        stripeWidth,
        index,
        height,
        date: thisDateFormatted
      })

      countIndicatorButton.on('mousemove', () => {
        const text = `Label${indicatorValue === 1 ? '' : 's'}`
        countIndicatorButtonTooltip.style('display', 'block')
        svg.select('.count-rect-tooltip-wrapper').style('display', 'block')
        svg.select('.count-rect-tooltip-text').style('display', 'block').text(`${indicatorValue} ${text}`)
      })
      countIndicatorButton.on('mouseout', () => {
        countIndicatorButtonTooltip.style('display', 'none')
        svg.select('.count-rect-tooltip-wrapper').style('display', 'none')
        svg.select('.count-rect-tooltip-text').style('display', 'none')
      })
      countIndicatorButton.on('click', (e) => {
        setDrawerOpen(true)
        setMode('show-date')
        const { date, start, end } = getCustomDatesForTimePeriod(
          e.x,
          offsetLeft,
          stripeWidth,
          selectedTimePeriod,
          selectedFrequency,
          uniqueMonths,
          groupedByWeeks ? groupedByWeeks.values : filteredData,
          dataStartsAt
        )
        setCustomDate({ date, start, end })
        setRedirectToMode('show-date')
      })
    }

    addEventButton.on('mousemove', () => {
      addEventButtonTooltip.style('display', 'block')
    })
    addEventButton.on('mouseout', () => {
      addEventButtonTooltip.style('display', 'none')
    })
    addEventButton.on('click', (e) => {
      setDrawerOpen(true)
      setMode('create')
      const { date, start, end } = getCustomDatesForTimePeriod(
        e.x,
        offsetLeft,
        stripeWidth,
        selectedTimePeriod,
        selectedFrequency,
        uniqueMonths,
        groupedByWeeks ? groupedByWeeks.values : filteredData,
        dataStartsAt
      )
      setCustomDate({ date, start, end })
      setRedirectToMode('show-date')
    })
  })
}
