import styled from '@emotion/styled/macro'
import { Box, Tabs } from '@mui/material'

export const LabelsShowModeWrapper = styled(Box)`
  margin-top: 8px;
`

export const TabPanelWrapper = styled(Box)`
  border-top: 1px solid rgba(222, 189, 217, 0.25);
  padding-top: 16px;

  .filters-wrapper {
    padding-bottom: 16px;
    border-bottom: 2px solid rgba(222, 189, 217, 0.5);

    .filters-block {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-right: 24px;
      padding-left: 24px;

      .dates {
        font-family: 'Quicksand', sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0.1px;
      }
    }

    .filter-button {
      border-radius: 70px;
      border: 0.7px solid #998d96;
      background: rgba(153, 141, 150, 0.15);
    }
  }

  .info {
    font-family: 'Quicksand', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.1px;
  }

  .content {
    padding-top: 16px;
    padding-right: 24px;
    padding-left: 24px;
    overflow-y: scroll;
    height: calc(100vh - 234px);

    ::-webkit-scrollbar {
      display: none;
    }
  }
`

export const DateLabelPanel = styled(Box)`
  padding-top: 16px;

  .date {
    font-family: 'Quicksand', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
    letter-spacing: 0.25px;
    margin-bottom: 16px;
  }
`

export const LabelsTabs = styled(Tabs)<{ isdetails?: string }>`
  background: transparent;
  height: 24px;
  min-height: 24px;

  .MuiTabs-scroller {
    height: 24px;

    .MuiTabs-flexContainer {
      height: 24px;
    }
  }

  .MuiButtonBase-root {
    border-radius: 0;
    font-family: 'Quicksand', sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.5px;
    color: #debdd9;
    border: none;
    text-transform: none;
    padding: 0;
    width: 94px;
    height: 24px;
    min-height: 24px;

    &.Mui-selected {
      color: #ffa9fc;
      border-bottom: 2px solid #ffa9fc;
    }
  }

  ${({ isdetails }) =>
    isdetails &&
    isdetails === 'true' &&
    `
    height: 30px;
    min-height: 30px;
    
    .MuiTabs-scroller {
      height: 30px;
      
      .MuiTabs-flexContainer {
        height: 30px;
         width: 100%;
         justify-content: space-between;
      }
    }
    
    .MuiButtonBase-root {
      height: 30px;
      white-space: nowrap;
      width: 50%;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: 0.1px;
    }
  `};
`
