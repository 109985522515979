import { FC } from 'react'

interface Props {
  secondBreakpoint?: boolean
}

const StubPerformanceChart: FC<Props> = ({ secondBreakpoint }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={secondBreakpoint ? '216' : '197'}
      height={secondBreakpoint ? '216' : '197'}
      viewBox="0 0 169 169"
      fill="none"
    >
      <mask id="path-1-inside-1_712_4002" fill="white">
        <path d="M169 84.5C169 131.168 131.168 169 84.5 169C37.8319 169 0 131.168 0 84.5C0 37.8319 37.8319 0 84.5 0C131.168 0 169 37.8319 169 84.5ZM67.6 84.5C67.6 93.8336 75.1664 101.4 84.5 101.4C93.8336 101.4 101.4 93.8336 101.4 84.5C101.4 75.1664 93.8336 67.6 84.5 67.6C75.1664 67.6 67.6 75.1664 67.6 84.5Z" />
      </mask>
      <path
        d="M169 84.5C169 131.168 131.168 169 84.5 169C37.8319 169 0 131.168 0 84.5C0 37.8319 37.8319 0 84.5 0C131.168 0 169 37.8319 169 84.5ZM67.6 84.5C67.6 93.8336 75.1664 101.4 84.5 101.4C93.8336 101.4 101.4 93.8336 101.4 84.5C101.4 75.1664 93.8336 67.6 84.5 67.6C75.1664 67.6 67.6 75.1664 67.6 84.5Z"
        stroke="#998D96"
        strokeWidth="2"
        strokeDasharray="2 2"
        mask="url(#path-1-inside-1_712_4002)"
      />
      <mask id="path-2-inside-2_712_4002" fill="white">
        <path d="M169 84.5C169 131.168 131.168 169 84.5 169C37.8319 169 0 131.168 0 84.5C0 37.8319 37.8319 0 84.5 0C131.168 0 169 37.8319 169 84.5ZM50.7 84.5C50.7 103.167 65.8328 118.3 84.5 118.3C103.167 118.3 118.3 103.167 118.3 84.5C118.3 65.8328 103.167 50.7 84.5 50.7C65.8328 50.7 50.7 65.8328 50.7 84.5Z" />
      </mask>
      <path
        d="M169 84.5C169 131.168 131.168 169 84.5 169C37.8319 169 0 131.168 0 84.5C0 37.8319 37.8319 0 84.5 0C131.168 0 169 37.8319 169 84.5ZM50.7 84.5C50.7 103.167 65.8328 118.3 84.5 118.3C103.167 118.3 118.3 103.167 118.3 84.5C118.3 65.8328 103.167 50.7 84.5 50.7C65.8328 50.7 50.7 65.8328 50.7 84.5Z"
        stroke="#998D96"
        strokeWidth="2"
        strokeDasharray="2 2"
        mask="url(#path-2-inside-2_712_4002)"
      />
      <mask id="path-3-inside-3_712_4002" fill="white">
        <path d="M169 84.5C169 131.168 131.168 169 84.5 169C37.8319 169 0 131.168 0 84.5C0 37.8319 37.8319 0 84.5 0C131.168 0 169 37.8319 169 84.5ZM33.8 84.5C33.8 112.501 56.4992 135.2 84.5 135.2C112.501 135.2 135.2 112.501 135.2 84.5C135.2 56.4992 112.501 33.8 84.5 33.8C56.4992 33.8 33.8 56.4992 33.8 84.5Z" />
      </mask>
      <path
        d="M169 84.5C169 131.168 131.168 169 84.5 169C37.8319 169 0 131.168 0 84.5C0 37.8319 37.8319 0 84.5 0C131.168 0 169 37.8319 169 84.5ZM33.8 84.5C33.8 112.501 56.4992 135.2 84.5 135.2C112.501 135.2 135.2 112.501 135.2 84.5C135.2 56.4992 112.501 33.8 84.5 33.8C56.4992 33.8 33.8 56.4992 33.8 84.5Z"
        stroke="#998D96"
        strokeWidth="2"
        strokeDasharray="2 2"
        mask="url(#path-3-inside-3_712_4002)"
      />
      <mask id="path-4-inside-4_712_4002" fill="white">
        <path d="M169 84.5C169 131.168 131.168 169 84.5 169C37.8319 169 0 131.168 0 84.5C0 37.8319 37.8319 0 84.5 0C131.168 0 169 37.8319 169 84.5ZM16.9 84.5C16.9 121.834 47.1656 152.1 84.5 152.1C121.834 152.1 152.1 121.834 152.1 84.5C152.1 47.1656 121.834 16.9 84.5 16.9C47.1656 16.9 16.9 47.1656 16.9 84.5Z" />
      </mask>
      <path
        d="M169 84.5C169 131.168 131.168 169 84.5 169C37.8319 169 0 131.168 0 84.5C0 37.8319 37.8319 0 84.5 0C131.168 0 169 37.8319 169 84.5ZM16.9 84.5C16.9 121.834 47.1656 152.1 84.5 152.1C121.834 152.1 152.1 121.834 152.1 84.5C152.1 47.1656 121.834 16.9 84.5 16.9C47.1656 16.9 16.9 47.1656 16.9 84.5Z"
        stroke="#998D96"
        strokeWidth="2"
        strokeDasharray="2 2"
        mask="url(#path-4-inside-4_712_4002)"
      />
    </svg>
  )
}

export default StubPerformanceChart
