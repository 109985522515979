import { FC, useCallback, useEffect, MouseEvent, useState } from 'react'
import { DialogActions, DialogContent, DialogTitle } from '@mui/material'
import { useFormik } from 'formik'

/* Utils */
import axios from '../../../../../lib/axios'
import {
  schema as integrationSchema,
  FormValues as IntegrationFormValues,
  defaultValues as integrationDefaultValues
} from './jiraIntegration.constants'
import { notification } from '../../../../../store/notifications/notificationSlice'
import { useAppDispatch } from '../../../../../store/hooks'
import { fetchIntegrations } from '../../../../../store/integrations/actions'
import { closeModal, ModalVariant } from '../../../../../store/modals/modalSlice'
import { Project, ProjectMeta, Credentials } from '../../../../../models'
import { FormValues as MappingFormValues, schema as mappingSchema } from './schemaMapping.constants'

/* Components */
import JiraIntegrationTitle from './JiraIntegrationTitle'
import JiraIntegrationContent from './JiraIntegrationContent'
import JiraIntegrationActions from './JiraIntegrationActions'

export interface SelectOption {
  label: string
  value: string
}

interface Props {
  variant: ModalVariant
}

const JiraIntegration: FC<Props> = ({ variant }) => {
  const dispatch = useAppDispatch()
  const [schema, setSchema] = useState<any>(integrationSchema)
  const [defaultValues, setDefaultValues] = useState<IntegrationFormValues | MappingFormValues>(
    integrationDefaultValues
  )
  const [activeStep, setActiveStep] = useState(0)
  const [showPassword, setShowPassword] = useState(false)
  const [success, setSuccess] = useState<boolean | null>(null)
  const [errorMessage, setErrorMessage] = useState('')
  const [options, setOptions] = useState<SelectOption[]>([])
  const [allProjects, setAllProjects] = useState<Project[]>([])
  const [credentials, setCredentials] = useState<Credentials | null>(null)
  const [selectedProjects, setSelectedProjects] = useState<string[]>([])
  const [projectIds, setProjectIds] = useState<string[]>([])
  const [projectsMetadata, setProjectsMetadata] = useState<ProjectMeta[]>([])
  const allProjectsSelected = options.length > 0 && selectedProjects.length === options.length

  const fetchAllProjects = useCallback(async () => {
    const res = await axios.get('/api/v3/integrations/jira/projects', {
      data: { credentials }
    })

    if (res.status === 200) {
      return res.data.projects
    }
  }, [])

  const fetchProjectsMetadata = useCallback(async () => {
    const ids = projectIds.join(',')
    const res = await axios.get(`/api/v3/integrations/jira/projects/getmeta?projectIds=${ids}`)

    if (res.status === 200) {
      return res.data.projects
    }
  }, [projectIds])

  useEffect(() => {
    if (activeStep === 1) {
      fetchAllProjects().then((projects) => {
        setAllProjects(projects)
        setOptions(projects.map((project: Project) => ({ label: project.name, value: project.id })))
      })
    }
  }, [activeStep])

  useEffect(() => {
    if (projectIds) {
      fetchProjectsMetadata().then((metadata) => {
        setProjectsMetadata(metadata)
      })
    }
  }, [projectIds])

  const handleCancel = () => {
    dispatch(closeModal())
    dispatch(
      notification({
        open: true,
        variant: 'warning',
        message: 'Changes were not saved.'
      })
    )
  }

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1)
  }

  const handleBack = () => {
    setSuccess(null)
    setErrorMessage('')
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
  }

  const onSubmit = async (values: any) => {
    /* Account Credentials */
    if (activeStep === 0) {
      const payload = {
        credentials: {
          base_url: (values as IntegrationFormValues).jiraUrl,
          username: (values as IntegrationFormValues).jiraEmail,
          password: (values as IntegrationFormValues).apiKey
        }
      }
      setCredentials({
        auth_type: 'basic',
        ...payload.credentials
      })

      try {
        const res = await axios.post('/api/v3/integrations/jira', payload)

        if (res.status === 201) {
          setSuccess(true)
          handleNext()
        } else {
          setSuccess(false)
        }
      } catch (err) {
        const message = (err as any).response.data.message
        if (message.includes('exists')) {
          setErrorMessage(message)
        }
        setSuccess(false)
      }
    }

    /* Select Projects */
    if (activeStep === 1) {
      const filteredProjects = allProjects.filter((project) => {
        const found = selectedProjects.filter((selected) => project.name === selected)

        if (found.length) {
          return found
        }

        return undefined
      })

      setProjectIds(filteredProjects.map((project) => project.id))
      handleNext()
    }

    /* Schema Mapping */
    if (activeStep === 2) {
      const payload = {
        projects: values.projects.map((meta: ProjectMeta) => ({
          ...meta,
          issueTypes: meta.issueTypes.map((issueType) => ({
            ...issueType,
            fields: issueType.fields.map((field) => ({
              ...field,
              sourceField: field.sourceField
            }))
          }))
        }))
      }
      const res = await axios.post('/api/v3/integrations/jira/projects/meta', payload)

      if (res.status === 200) {
        if (success) {
          dispatch(closeModal())
          dispatch(
            notification({
              open: true,
              variant: 'success',
              message: 'Your Jira integration changes successfully updated.'
            })
          )
          dispatch(fetchIntegrations())
        }
      } else {
        dispatch(
          notification({
            open: true,
            variant: 'error',
            message: 'Failed to save your Jira integration changes.'
          })
        )
      }
      dispatch(closeModal())
    }
  }

  const formik = useFormik({
    initialValues: defaultValues,
    validationSchema: schema,
    onSubmit
  })

  useEffect(() => {
    if (activeStep === 0) {
      setDefaultValues(integrationDefaultValues)
      setSchema(integrationSchema)
    }
    if (projectsMetadata && activeStep === 2) {
      const metaValues = projectsMetadata.map((meta) => ({
        ...meta,
        issueTypes: meta.issueTypes.map((issueType) => ({
          ...issueType,
          fields: issueType.fields.map((field) => ({
            ...field,
            sourceField: field.sourceField
          }))
        }))
      }))
      setDefaultValues({ projects: metaValues })
      formik.setValues({ projects: metaValues })
      setSchema(mappingSchema)
    }
  }, [activeStep, projectsMetadata])

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword as boolean)
  }

  const handleMouseDownPassword = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
  }

  return (
    <>
      <form autoComplete="off" onSubmit={formik.handleSubmit}>
        <DialogTitle id="dialog-title">
          <JiraIntegrationTitle activeStep={activeStep} />
        </DialogTitle>
        <DialogContent
          dividers
          sx={{
            padding: '16px 40px'
          }}
        >
          <JiraIntegrationContent
            showPassword={showPassword}
            handleClickShowPassword={handleClickShowPassword}
            handleMouseDownPassword={handleMouseDownPassword}
            activeStep={activeStep}
            success={success}
            errorMessage={errorMessage}
            variant={variant}
            options={options}
            selected={selectedProjects}
            setSelected={setSelectedProjects}
            allProjectsSelected={allProjectsSelected}
            projectsMetadata={projectsMetadata}
            values={formik.values}
            onChange={formik.handleChange}
            setFieldValue={formik.setFieldValue}
          />
        </DialogContent>
        <DialogActions sx={{ padding: '16px 40px' }}>
          <JiraIntegrationActions
            handleCancel={handleCancel}
            isSubmitting={formik.isSubmitting}
            isValid={formik.isValid}
            handleBack={handleBack}
            activeStep={activeStep}
            hasSelectedProjects={selectedProjects.length > 0}
          />
        </DialogActions>
      </form>
    </>
  )
}

export default JiraIntegration
