import { FC } from 'react'
import { PopperProps, Fade, ClickAwayListener } from '@mui/material'

/* Components */
import {
  TooltipButton,
  TooltipText,
  TooltipPopper,
  TooltipWrapper,
  TooltipContainer
} from './crowdstrikeIntegration.styles'
import { RightIconButton } from '../../../svg'

interface Props extends PopperProps {
  handleClickAway: () => void
  handleDownload: () => void
}

const CrowdstrikeInstructionsTooltip: FC<Props> = ({
  id,
  open,
  handleDownload,
  anchorEl,
  handleClickAway,
  ...rest
}) => (
  <TooltipPopper id={id} open={open} anchorEl={anchorEl} transition {...rest} placement="bottom-start">
    {({ TransitionProps }) => (
      <Fade {...TransitionProps} timeout={350}>
        <TooltipContainer>
          <ClickAwayListener onClickAway={handleClickAway}>
            <TooltipWrapper>
              <TooltipText variant="body1">
                To see these instructions accompanied by a detailed set of screenshots outlining the Crowdstrike
                interface, go to our documentation page.
              </TooltipText>
              <TooltipButton onClick={handleDownload}>
                <RightIconButton />
                Go to Detailed Documentation
              </TooltipButton>
            </TooltipWrapper>
          </ClickAwayListener>
        </TooltipContainer>
      </Fade>
    )}
  </TooltipPopper>
)

export default CrowdstrikeInstructionsTooltip
