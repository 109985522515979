import { FC } from 'react'
import { Box, SelectProps, Typography } from '@mui/material'

/* Utils */
import { IntegrationInstance } from '../../../../../../../models'

/* Components */
import { DataSourceWrapper } from './dataSourceSelect.styles'
import DataSourceSelectField from './DataSourceSelectField'
import DataSourceErrorTooltip from '../../../../../../components/data-source-error-tooltip'
import { getIntegrationLogo } from '../../../../utils'

export interface Option {
  label: string
  value: string
  src: string
}

interface Props extends SelectProps {
  label: string
  order: 1 | 2
  options: Option[]
  value: string
  handleChange: (source: 1 | 2, value: string) => void
  disabled: boolean
  getDisabledOption: (order: 1 | 2, value: string) => boolean
  instances: IntegrationInstance[]
  hasFailed?: boolean
  instance?: IntegrationInstance
  errorMessage?: string
  wrapperSx?: Record<string, unknown>
  placeholder?: string
  numberOfAssets?: number
  fieldWidth?: string
  acaMenuProps?: boolean
}

const DataSourceSelect: FC<Props> = ({
  label,
  order,
  options,
  instances,
  getDisabledOption,
  value,
  handleChange,
  disabled,
  hasFailed,
  instance,
  errorMessage,
  wrapperSx,
  placeholder,
  numberOfAssets,
  fieldWidth,
  acaMenuProps,
  ...rest
}) => {
  const onChange = (e: string) => {
    handleChange(order, e)
  }

  return (
    <DataSourceWrapper sx={wrapperSx} className="data-source-picker-group">
      <Box className="header-wrapper">
        <Typography className="source-selector-title">{label}</Typography>
        {hasFailed && instance && (
          <DataSourceErrorTooltip
            displayName={instance.name}
            errorMessage={errorMessage || ''}
            logoPath={getIntegrationLogo(instance.integration_name)}
            instanceName={instance.integration_name}
            id={instance.id}
            hideBaseIcon
          />
        )}
      </Box>
      <DataSourceSelectField
        options={options}
        instances={instances}
        value={value}
        handleChange={onChange}
        disabled={disabled}
        order={order}
        placeholder={placeholder || 'Select data source...'}
        getDisabledOption={getDisabledOption}
        numberOfAssets={numberOfAssets}
        fieldWidth={fieldWidth}
        acaMenuProps={acaMenuProps}
        {...rest}
      />
    </DataSourceWrapper>
  )
}

export default DataSourceSelect
