import { FC, useCallback, useEffect, useRef, useState } from 'react'
import { useMediaQuery } from '@mui/material'
import * as d3 from 'd3'
import 'd3-selection'
import dayjs from 'dayjs'

/* Utils */
import {
  AnalyzerChartData,
  AnalyzerData,
  CPIFrequency,
  CPITimePeriod,
  IntegrationInstance
} from '../../../../../../models'
import {
  buildEmptyData,
  filterData,
  findNearestXIndex,
  findUniqueMonths,
  formatData,
  formatXLabelCoverage,
  getPathAndPositions
} from '../../../utils'
import { formatTooltipDate, getChartHeight, getChartWidth } from '../../../../library/utils'

/*
 * Colors
 *  */
const yellowStroke = '#F09543'
const blueStroke = '#8E9AFF'
const oddBarColor = '#30262f'
const evenBarColor = 'rgba(208, 188, 255)'

interface Props {
  selectedTimePeriod: CPITimePeriod
  chartData: AnalyzerData | null
  firstSourceId: string
  secondSourceId: string
  instances: IntegrationInstance[]
  chartLoading: boolean
}

const AnalysisChart: FC<Props> = ({
  firstSourceId,
  secondSourceId,
  chartData,
  chartLoading,
  selectedTimePeriod,
  instances
}) => {
  const isSmallHeight = useMediaQuery('(max-height:800px)')
  const isMediumHeight = useMediaQuery('(max-height:900px)')
  const isBigHeight = useMediaQuery('(min-height:901px)')
  const isSmall = useMediaQuery('(max-width:1200px)')
  const isLaptop = useMediaQuery('(max-width:1280px)')
  const isLargeScreen = useMediaQuery('(max-width:1570px)')
  const isExtraLargeScreen = useMediaQuery('(max-width:1770px)')
  const containerRef = useRef<HTMLDivElement | null>(null)
  const svgRef = useRef<SVGSVGElement | null>(null)
  const tooltipRef = useRef<HTMLDivElement | null>(null)
  const [width, setWidth] = useState(getChartWidth(isSmall, isLaptop, isLargeScreen, isExtraLargeScreen))
  const margin = { top: 20, right: 0, bottom: 40, left: 0 }
  const [height, setHeight] = useState(
    getChartHeight(isSmallHeight, isMediumHeight, isBigHeight) - margin.top - margin.bottom
  )
  const chartHeightStart = 16

  useEffect(() => {
    if (!containerRef.current) return
    const observeTarget = containerRef.current
    const resizeObserver = new ResizeObserver((entries) => {
      entries.forEach((entry) => {
        setWidth(entry.contentRect.width)
        setHeight(entry.contentRect.height - 132)
      })
    })
    resizeObserver.observe(observeTarget)

    return () => {
      resizeObserver.unobserve(observeTarget)
    }
  }, [containerRef])

  const getYellowAreaStroke = useCallback(() => {
    if (!chartData) return ''

    return yellowStroke
  }, [chartData])

  const getBlueAreaStroke = useCallback(() => {
    if (!chartData) return ''

    return blueStroke
  }, [chartData])

  const buildChart = (
    data: AnalyzerData,
    timeRange: number,
    firstSource: AnalyzerChartData,
    secondSource: AnalyzerChartData,
    emptyChart?: boolean
  ) => {
    const tooltip = d3.select('.data-point-tooltip')
    const formattedData = formatData(data, firstSource, secondSource)
    let filteredData = filterData(timeRange, formattedData)
    if (emptyChart) {
      filteredData = data.emptyDataPlaceholder
    }
    const svg = d3.select(svgRef.current)
    const everything = svg.selectAll('*')
    everything.remove()
    const dataStartsAt = dayjs(Object.entries(data)[0][1][0].xValue || '').format('YYYY-MM-DD')

    /*
     *  Populate each stripe with data points for that month
     * */
    const yValues = filteredData.map((n: any) => (n.y1Value ? n.y1Value : 0))
    yValues.push(...filteredData.map((n: any) => (n.y2Value ? n.y2Value : 0)))
    const maxYScaleValue = Number(d3.max(yValues))
    const getYAxisDomain = (): [number, number] => {
      const nonPercentRange: [number, number] = [0, maxYScaleValue * 2]

      if (maxYScaleValue === 0) {
        return [0, 100]
      }
      return nonPercentRange
    }
    const yAxisDomain: [number, number] = emptyChart ? [0, 1000] : getYAxisDomain()
    const uniqueMonths = findUniqueMonths(filteredData, selectedTimePeriod, formattedData, true)
    const stripeWidth = width / uniqueMonths.length
    const [yellowPathStr, bluePathStr, positions] = getPathAndPositions(
      uniqueMonths,
      stripeWidth,
      height,
      filteredData,
      selectedTimePeriod,
      yAxisDomain,
      true,
      firstSource,
      secondSource,
      chartHeightStart
    )

    const xScaleGroup = d3.scaleBand().domain(uniqueMonths).range([0, width]).padding(0)
    const yScale = d3.scaleLinear().domain(yAxisDomain).range([height, chartHeightStart]).nice()

    /*
     * Cursor Line
     * */
    const existingline = svg.selectAll('.cursor-line')
    if (!existingline.empty()) existingline.remove()
    const cursorLine = svg
      .append('line')
      .attr('class', 'cursor-line')
      .attr('x1', 0)
      .attr('x2', 0)
      .attr('y1', chartHeightStart)
      .attr('y2', height)
      .attr('stroke', '#fff')
      .attr('stroke-width', 1)
      .style('display', 'none')

    svg.on('mouseout', () => {
      cursorLine.style('display', 'none')
      tooltip.style('display', 'none')
      const circle1 = svg.select('.hover-circle-outer')
      const circle2 = svg.select('.hover-circle-inner')
      circle1.attr('display', 'none')
      circle2.attr('display', 'none')
    })

    /*
     * X-Axis
     * */
    const appendXAxis = () => {
      const xAxis = d3.axisBottom(xScaleGroup).tickFormat((date) =>
        formatXLabelCoverage({
          date,
          selectedTimePeriod,
          selectedFrequency: CPIFrequency.Daily,
          filteredData,
          dataStartsAt
        })
      )
      const existingXAxis = svg.selectAll('.x-axis')
      if (!existingXAxis.empty()) existingXAxis.remove()
      const xAxisGroup = svg
        .append('g')
        .attr('class', 'x-axis')
        .attr('color', '#fff')
        .attr('transform', `translate(0, ${height + 19})`)
        .call(xAxis)
      xAxisGroup
        .selectAll('text')
        .attr('color', '#fff')
        .attr('font-size', '14px')
        .attr('font-weight', '400')
        .attr('letter-spacing', '0.25px')
        .attr('line-height', '20px')
        .attr('font-family', "'Quicksand', sans-serif")
      xAxisGroup.selectAll('path').attr('display', 'none')
      const xAxisTick = svg.selectAll('.tick')
      xAxisTick.selectAll('line').attr('display', 'none')
    }
    appendXAxis()

    /*
     *  Y-Axis
     * */
    const appendYAxis = () => {
      const yAxis = d3
        .axisRight(yScale)
        .ticks(emptyChart ? 2 : 5)
        .tickFormat((d, i) => {
          if (emptyChart) return `${d}`
          return i === 0 ? '' : `${d}`
        })
      const existingYAxis = svg.selectAll('.y-axis')
      if (!existingYAxis.empty()) existingYAxis.remove()
      const yAxisGroup = svg.append('g').attr('class', 'y-axis').attr('color', 'none').call(yAxis)
      yAxisGroup
        .selectAll('text')
        .attr('dy', emptyChart ? '5px' : '18px')
        .attr('color', '#fff')
        .attr('font-size', '14px')
        .attr('font-weight', '500')
        .attr('letter-spacing', '0.1px')
        .attr('line-height', '20px')
        .attr('font-family', "'Quicksand', sans-serif")
      yAxisGroup.selectAll('path').attr('display', 'none')
      yAxisGroup.selectAll('line').attr('display', 'none')
    }
    appendYAxis()

    /*
     * Mouse Move for data point tooltip
     * */
    svg.on('mousemove', (event: any) => {
      if (!positions.length || emptyChart) return
      const [mouseX, mouseY] = d3.pointer(event)
      const barHeight = height + margin.top + margin.bottom
      const xAxisHeight = 80
      const diff = barHeight - xAxisHeight
      const isHoveringXAxis = mouseY >= diff
      const dataPoint = findNearestXIndex(positions, mouseX)
      const matchingDataPoint = filteredData[dataPoint?.index || 0]
      if (dataPoint) mouseMove(event, dataPoint, matchingDataPoint, isHoveringXAxis)
    })

    /*
     * Hover Data Point Circle
     * */
    const appendHoverCircles = () => {
      const hoverCircleInner = svg.selectAll('.hover-circle-inner')
      if (!hoverCircleInner.empty()) hoverCircleInner.remove()
      svg
        .append('circle')
        .attr('class', 'hover-circle-inner')
        .attr('cx', 100)
        .attr('cy', 200)
        .attr('r', 7)
        .attr('fill', '#fff')
        .attr('stroke', '#000')
        .attr('stroke-width', 2)
        .attr('stroke-opacity', 1)
        .attr('display', 'none')
      const hoverCircleOuter = svg.selectAll('.hover-circle-outer')
      if (!hoverCircleOuter.empty()) hoverCircleOuter.remove()
      svg
        .append('circle')
        .attr('class', 'hover-circle-outer')
        .attr('cx', 100)
        .attr('cy', 200)
        .attr('r', 7)
        .attr('fill', 'none')
        .attr('stroke', '#fff')
        .attr('stroke-width', 15)
        .attr('stroke-opacity', 0.5)
        .attr('display', 'none')
    }
    appendHoverCircles()

    /*
     * Cartesian Grid Bars
     * */
    const appendCartesianGrid = () => {
      const existingBars = svg.selectAll('.bar')
      if (!existingBars.empty()) existingBars.remove()
      svg
        .selectAll('.bar')
        .data(uniqueMonths)
        .enter()
        .append('rect')
        .attr('fill', (d, i) => (i % 2 === 0 ? oddBarColor : evenBarColor))
        .attr('fill-opacity', '0.08')
        .attr('class', 'bar')
        .attr('x', (d) => xScaleGroup(d) || 0)
        .attr('y', 0)
        .attr('width', xScaleGroup.bandwidth())
        .attr('height', height + margin.top + margin.bottom)
        .on('mouseout', () => {
          tooltip.style('display', 'none')
          const circle1 = svg.select('.hover-circle-outer')
          const circle2 = svg.select('.hover-circle-inner')
          const addCustomEventButton = svg.select('.add-event-button')
          circle1.attr('display', 'none')
          circle2.attr('display', 'none')
          addCustomEventButton.style('display', 'none')
        })
    }
    appendCartesianGrid()

    /*
     * Hover Tooltip
     * */
    const mouseMove = (event: any, dataPoint: any, d: any, isHoveringXAxis: boolean) => {
      const showHoverElements = () => {
        if (!isHoveringXAxis) {
          tooltip.style('display', 'block')
        }
        const circleInner = d3.select('.hover-circle-inner')
        const circleOuter = d3.select('.hover-circle-outer')
        circleInner.attr('cx', dataPoint.x)
        circleInner.attr('cy', dataPoint.y1)
        circleInner.attr('display', 'block')
        circleOuter.attr('cx', dataPoint.x)
        circleOuter.attr('cy', dataPoint.y1)
        circleOuter.attr('display', 'block')
        const cursorLine = svg.select('.cursor-line')
        cursorLine.attr('x1', dataPoint.x).attr('x2', dataPoint.x).style('display', 'block')
      }
      showHoverElements()

      const formattedDate = formatTooltipDate(d.xValue, d.end, CPIFrequency.Daily, selectedTimePeriod, dataStartsAt)
      const pageX = event.pageX

      tooltip
        .html(
          `${formattedDate}
                  <br />
                  ${
                    firstSource.values && firstSource.values.length > 0
                      ? `<div class='asset-wrapper-coverage'>
                          <div class="asset-wrapper-inner">
                            <svg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='0 0 12 12' fill='none'>
                              <circle cx='6' cy='6' r='6' fill='#8E9AFF' />
                            </svg>
                            <p class='tooltip-performance'>
                              ${firstSource.integrationName}: 
                        </p>
                        </div>
                                             ${
                                               firstSource.integrationName.length < 25
                                                 ? `
                              <p class="tooltip-performance subtext">${d.y1Value} asset${
                                                     d.y1Value === 1 ? '' : 's'
                                                   } covered</p>
                            `
                                                 : `
                              <div class="long">
                                <p class="tooltip-performance subtext long-text">${d.y1Value} asset${
                                                     d.y1Value === 1 ? '' : 's'
                                                   } covered</p>
                              </div>
                            `
                                             }
                      </div>`
                      : ''
                  }
                  ${
                    secondSource.values && secondSource.values.length > 0
                      ? `<div class='asset-wrapper-coverage'>
                          <div class="asset-wrapper-inner">
                        <svg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='0 0 12 12' fill='none'>
                          <circle cx='6' cy='6' r='6' fill='#F09543' />
                        </svg>
                        <p class='tooltip-performance'>
                          ${secondSource.integrationName}:
                        </p>
                        </div>
                                                                      ${
                                                                        secondSource.integrationName.length < 25
                                                                          ? `
                              <p class="tooltip-performance subtext">${d.y2Value} asset${
                                                                              d.y2Value === 1 ? '' : 's'
                                                                            } covered</p>
                            `
                                                                          : `
                              <div class="long">
                                <p class="tooltip-performance subtext long-text">${d.y2Value} asset${
                                                                              d.y2Value === 1 ? '' : 's'
                                                                            } covered</p>
                              </div>
                            `
                                                                      }
                      </div>`
                      : ''
                  }
                  `
        )
        .style('left', pageX < 425 ? pageX - 90 + 'px' : pageX - 415 + 'px')
        .style('top', event.pageY - 300 + 25 + 'px')
    }

    /*
     * Cartesian Grid Horizontal Line
     * */
    const appendGridHorizontalLine = () => {
      svg
        .selectAll('.cartesian-grid-horizontal')
        .data([0, 1, 2, 3, 4, 5])
        .enter()
        .append('line')
        .attr('class', 'cartesian-grid-horizontal')
        .attr('x1', 0)
        .attr('y1', (d) => (d === 0 ? chartHeightStart : d * (height / 5)))
        .attr('x2', width)
        .attr('y2', (d) => (d === 0 ? chartHeightStart : d * (height / 5)))
        .attr('stroke', 'rgba(147, 143, 153, 0.16)')
        .attr('stroke-width', '1px')
    }
    appendGridHorizontalLine()

    /*
     * Yellow Area Stroke
     * */
    const appendYellowAreaStroke = () => {
      const existingFilledAreaStroke = svg.selectAll('.yellow-area-stroke')
      if (!existingFilledAreaStroke.empty()) existingFilledAreaStroke.remove()
      svg
        .append('path')
        .attr('fill', 'none')
        .attr('stroke', `${getYellowAreaStroke()}`)
        .attr('d', `${bluePathStr}`)
        .attr('class', 'yellow-area-stroke')
        .attr('stroke-width', 3)
        .attr('stroke-linejoin', 'bevel')
    }
    appendYellowAreaStroke()

    /*
     * Blue Area Stroke
     * */
    const appendBlueAreaStroke = () => {
      const existingFilledAreaStroke = svg.selectAll('.blue-area-stroke')
      if (!existingFilledAreaStroke.empty()) existingFilledAreaStroke.remove()
      svg
        .append('path')
        .attr('fill', 'none')
        .attr('stroke', `${getBlueAreaStroke()}`)
        .attr('d', `${yellowPathStr}`)
        .attr('class', 'blue-area-stroke')
        .attr('stroke-width', 3)
        .attr('stroke-linejoin', 'bevel')
    }
    appendBlueAreaStroke()

    const raiseElements = () => {
      svg.selectAll('.cartesian-grid-horizontal').raise()
      svg.selectAll('.yellow-area-stroke').raise()
      svg.selectAll('.blue-area-stroke').raise()
      svg.selectAll('.hover-circle-outer').raise()
      svg.selectAll('.hover-circle-inner').raise()
      // svg.selectAll('.cursor-line').raise()
    }
    raiseElements()
  }

  useEffect(() => {
    if (!containerRef.current || chartLoading) return
    /* Empty Chart */
    if (!chartData && !firstSourceId && !secondSourceId) {
      const { data, months, firstSource, secondSource } = buildEmptyData()

      buildChart(data, months, firstSource, secondSource, true)

      return
    }
    if (!chartData) return

    let months = selectedTimePeriod === CPITimePeriod.PastYear ? 12 : parseInt(selectedTimePeriod.split('')[0]) + 1

    const parts = []
    for (const [key, value] of Object.entries(chartData)) {
      parts.push({
        id: key,
        values: value
      })
    }
    if (parts.length > 0) {
      const uniqueMonths: string[] = []
      parts[0].values.forEach((item: any) => {
        const date = new Date(item.xValue)
        const year = date.getFullYear()
        const month = date.getMonth() + 1
        const monthKey = `${year}-${month.toString().padStart(2, '0')}`

        if (!uniqueMonths.includes(monthKey)) {
          uniqueMonths.push(monthKey)
        }
      })
      months = uniqueMonths.length
    }

    const firstSource: any = {
      integrationName: '',
      instanceName: '',
      status: '',
      id: firstSourceId
    }
    const firstSourcePart = parts.find((n) => n.id === firstSourceId)
    if (firstSourcePart) {
      firstSource.values = firstSourcePart.values
    }

    const secondSource: any = {
      id: secondSourceId,
      integrationName: '',
      instanceName: '',
      status: ''
    }
    const secondSourcePart = parts.find((n) => n.id === secondSourceId)
    if (secondSourcePart) {
      secondSource.values = secondSourcePart.values
    }

    if (instances.length > 0) {
      const foundFirst = instances.find(({ id }) => id === firstSourceId)
      if (foundFirst) {
        firstSource.integrationName = foundFirst.name
        firstSource.instanceName = foundFirst.config_name
      }
      if (!foundFirst && firstSourceId) {
        const foundFirst = instances.find(({ id }) => id === firstSourceId)
        if (foundFirst) {
          secondSource.integrationName = foundFirst.name
          secondSource.instanceName = foundFirst.config_name
        }
      }

      const foundSecond = instances.find(({ id }) => id === secondSourceId)
      if (foundSecond) {
        secondSource.integrationName = foundSecond.name
        secondSource.instanceName = foundSecond.config_name
      }
      if (!foundSecond && secondSourceId) {
        const foundSecond = instances.find(({ id }) => id === secondSourceId)
        if (foundSecond) {
          secondSource.integrationName = foundSecond.name
          secondSource.instanceName = foundSecond.config_name
        }
      }
    }

    buildChart(chartData, months, firstSource, secondSource)
  }, [chartData, instances, firstSourceId, secondSourceId, width, height, chartLoading])

  return (
    <div ref={containerRef} className="trends-d3-wrapper">
      <div ref={tooltipRef} className="data-point-tooltip" />
      <svg
        ref={svgRef}
        id="svg-d3"
        className="svg-d3"
        width={'100%' + margin.left + margin.right}
        height={height + margin.top + margin.bottom}
      />
    </div>
  )
}

export default AnalysisChart
