import { Box } from '@mui/material'
import styled from '@emotion/styled/macro'

export const EmptyStateContainer = styled(Box)`
  padding-left: 24px;
  padding-right: 24px;

  h2 {
    font-family: 'Quicksand', sans-serif;
    font-size: 45px;
    font-style: normal;
    font-weight: 400;
    line-height: 52px; /* 115.556% */
  }

  .image-wrapper {
    position: absolute;
    right: 0;
    bottom: 0;
  }
`
