import { useState, useEffect } from 'react'
import { useAppSelector } from '../store/hooks'

/* Utils */
import { IFramework, NistCategory, NistFunction } from '../models'
import { accountSettingsSelector } from '../store/user/userSlice'
import axios from '../lib/axios'

export const useFramework = () => {
  const account = useAppSelector(accountSettingsSelector)
  const framework = account?.cyFramework
  const [customFramework, setCustomFramework] = useState<IFramework | null>(null)

  const fetchCustomFramework = async () => {
    const res = await axios.get('/api/v3/frameworks/custom')

    if (res.status.toString().includes('20')) {
      setCustomFramework(res.data.framework)
    }
  }

  useEffect(() => {
    fetchCustomFramework().catch((e) => e)
  }, [])

  const mapCategoryToNist = (category: string, cpiName: string, onlyFunction = false) => {
    switch (category) {
      case 'Detection & Response':
        if (cpiName === 'CPI-005') return NistFunction.Detect
        return NistFunction.Respond
      case 'Vulnerability Management':
        if (cpiName === 'CPI-014') {
          if (onlyFunction) return NistFunction.Identify
          return `${NistFunction.Identify} / ${NistCategory.AssetManagement}`
        }
        return NistFunction.Respond
      case 'Training & Awareness':
        if (onlyFunction) return NistFunction.Protect
        return `${NistFunction.Protect} / ${NistCategory.AwarenessAndTraining}`
      case 'Cloud Security':
        if (cpiName === 'CPI-035') return NistFunction.Detect
        return NistFunction.Identify
      case 'Identity & Access Management':
        if (onlyFunction) return NistFunction.Protect
        return `${NistFunction.Protect} / ${NistCategory.IdentityManagement}`
      case 'Device Management':
        return NistFunction.Identify
      case 'Network Security':
        return NistFunction.Detect
      case 'Mail & Web Security':
        return NistFunction.Detect
      default:
        return 'N/A'
    }
  }

  return { framework, mapCategoryToNist, customFramework }
}
