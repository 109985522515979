import { Box } from '@mui/material'
import styled from '@emotion/styled/macro'

export const Container = styled(Box)<{ backgroundColor: string; height: number }>`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
  position: relative;
  border: 1px solid #4d444b;
  min-height: 140px;

  height: ${({ height }) => `${height}px`};

  ${({ backgroundColor }) => `
    background-color: ${backgroundColor};
  `};

  .MuiTypography-root {
    color: #fff;
    text-align: center;
    font-family: Quicksand, sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  .edit-icon-button {
    position: absolute;
    top: 0;
    right: 0;
    border-radius: 0 0 0 8px;
    background: #ffa9fc;
    display: flex;
    width: 32px;
    height: 32px;
    justify-content: center;
    align-items: center;
    padding: 0;
    opacity: 0;
  }

  &:hover {
    .edit-icon-button {
      opacity: 1;
    }
  }

  &:hover {
    border-color: #998d96;

    &:not(.with-items) {
      background: #483646;
    }
  }
`
