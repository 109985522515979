import React, { Dispatch, FC, ReactChild, SetStateAction } from 'react'
import { ContentWrapper, FilterWrapper } from './FilterComponent.styles'
import { Box, IconButton, Typography } from '@mui/material'
import CloseFilterIcon from '../../../components/svg/coverage-analyzer/CloseFilterIcon'

interface Props {
  children?: ReactChild
  showFilter: boolean
  setShowFilter: Dispatch<SetStateAction<boolean>>
  resetFilter: () => void
}

const FilterComponent: FC<Props> = ({ showFilter, setShowFilter, resetFilter, children }) => {
  return (
    <FilterWrapper showFilter={showFilter} className="filter-component">
      <Box className="header-wrapper">
        <Typography className="title">Filters</Typography>

        <Box className="actions">
          <Typography className="reset" onClick={resetFilter}>
            Reset All
          </Typography>
          <IconButton onClick={() => setShowFilter(false)}>
            <CloseFilterIcon />
          </IconButton>
        </Box>
      </Box>

      <ContentWrapper>{children}</ContentWrapper>
    </FilterWrapper>
  )
}

export default FilterComponent
