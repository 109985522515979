import { Dialog as MuiDialog } from '@mui/material'
import styled from '@emotion/styled/macro'

export const Dialog = styled(MuiDialog)`
  .MuiPaper-root {
    width: 640px;
    min-width: 640px;
    border-radius: 16px;
  }

  .MuiDialogTitle-root {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 16px 24px;
    height: 72px;
    justify-content: space-between;
    border-radius: 16px 16px 0 0;
    background: #372a36;

    .header {
      display: flex;
      align-items: center;
      gap: 8px;

      .header-title {
        color: #ffa9fc;
        font-family: Quicksand, sans-serif;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }
    }
  }

  .MuiDialogContent-root {
    padding: 24px !important;
    min-height: 336px;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;
    background: #292128;

    .content-title {
      color: #fff;
      font-family: Quicksand, sans-serif;
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;

      span {
        color: #ffa9fc;
        font-family: Quicksand, sans-serif;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }
    }
  }

  .MuiDialogActions-root {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    border-radius: 0 0 16px 16px;
    background: #372a36;
    height: 72px;
    padding: 16px 24px;
    gap: 8px;

    .MuiButtonBase-root {
      margin-left: 0;
    }
  }
`

export const List = styled('ul')`
  list-style-type: none;
  padding-left: 0;
  color: #fff;
  font-family: Quicksand, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0;
  padding-inline-start: 0;

  li {
    color: #fff;
    font-family: Quicksand, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding: 4px 8px 4px 0;
  }
`
