import { FC } from 'react'
import { NewIntegrationIcon } from '../../../../components/svg'
import { NewIntegrationMarkerContainer } from './integrationCard.styles'

interface Props {
  expanded: boolean
}

const NewIntegrationMarker: FC<Props> = ({ expanded }) => (
  <NewIntegrationMarkerContainer expanded={expanded ? 'true' : 'false'}>
    <NewIntegrationIcon />
    NEW
  </NewIntegrationMarkerContainer>
)

export default NewIntegrationMarker
