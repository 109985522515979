import { Box, DialogActions, DialogContent } from '@mui/material'
import styled from '@emotion/styled/macro'

export const Container = styled(Box)``

export const Header = styled(Box)`
  padding: 8px 16px 16px;
  border-bottom: 1px solid #342f32;

  .title {
    font-family: 'Quicksand', sans-serif;
    font-weight: 400;
    font-size: 36px;
    line-height: 44px;
    color: #e9e0e4;
  }
`

export const Content = styled(DialogContent)`
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.3), 0 8px 12px 6px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  background: linear-gradient(0deg, rgba(255, 169, 252, 0.11) 0%, rgba(255, 169, 252, 0.11) 100%), #1e1a1d;
  padding: 24px 24px 16px 24px;
  display: flex;
  flex-direction: column;

  .title {
    font-family: 'Quicksand', sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px;
    color: #e9e0e4;
    margin-bottom: 12px;
  }

  .text {
    color: #e9e0e4;
    font-family: 'Quicksand', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.5px;
  }
`

export const Actions = styled(DialogActions)`
  margin-top: 24px;
`
