import { FC } from 'react'
import { Typography } from '@mui/material'

import { BasicCell } from '../ssm.styles'
import { IdentifierBox } from './nistPillar.styles'

interface Props {
  nistPillarText: string
  nistValueText: string
  background: string
}

const NistPillar: FC<Props> = ({ nistPillarText, nistValueText, background }) => (
  <BasicCell>
    <IdentifierBox background={background} />
    <Typography className="nist-pillar-text">{nistPillarText}</Typography>
    <Typography className="nist-value-text">{nistValueText}</Typography>
  </BasicCell>
)

export default NistPillar
