import { Box } from '@mui/material'
import styled from '@emotion/styled/macro'

export const Container = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 200px;
  height: 200px;

  .categories-donut-chart {
    width: 200px;
    height: 200px;
  }
`
