import { styled } from '@mui/material/styles'
import { tooltipClasses, TooltipProps } from '@mui/material/Tooltip'
import { Tooltip } from '@mui/material'

export const IconTooltipDisabled = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    display: 'flex',
    padding: '8px 16px',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    minWidth: '197px',
    minHeight: '33px',
    borderRadius: '8px',
    border: '1px solid #998D96',
    boxShadow: '0px 1px 2px 0px rgba(0, 0, 0, 0.30), 0px 1px 3px 1px rgba(0, 0, 0, 0.15)',
    background: '#362E35'
  },
  '.tooltip-wrapper.is-disabled-wrapper': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',

    '.arrow-right': {
      position: 'absolute',
      top: '2px',
      right: '-32px'
    },

    '.is-disabled-text': {
      color: '#FFF',
      fontFamily: "'Quicksand', sans-serif",
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: 'normal'
    }
  },
  '.tooltip-text': {
    color: '#FFF',
    fontSize: '11px',
    fontFamily: "'Quicksand', sans-serif",
    lineHeight: '16px',
    fontWeight: 500,
    letterSpacing: '0.5px'
  },
  '.tooltip-subtext': {
    color: '#FFF',
    fontSize: '12px',
    fontFamily: "'Quicksand', sans-serif",
    lineHeight: '16px',
    fontWeight: 400,
    letterSpacing: '0.4px'
  },
  '.tooltip-cpi-text': {
    color: '#FFF',
    fontFamily: '"Quicksand", sans-serif',
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '20px',
    letterSpacing: '0.1px'
  },
  '.tooltip-category': {
    textTransform: 'capitalize',
    color: '#FFF',
    fontFamily: '"Quicksand", sans-serif',
    fontSize: ' 12px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '16px',
    letterSpacing: '0.4px'
  }
})
