/* eslint-disable no-unused-vars */
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../store'
import { IntegrationInstance, Integration } from '../../models'

export interface IntegrationState {
  integrations: Integration[]
  configuredIntegrations: IntegrationInstance[]
  loading: boolean
  totalIntegrations: number
}

const initialState: IntegrationState = {
  integrations: [],
  configuredIntegrations: [],
  loading: false,
  totalIntegrations: -1
}

export const integrationSlice = createSlice({
  name: 'integrations',
  initialState,
  reducers: {
    integrationsLoaded: (state: IntegrationState, action: PayloadAction<{ integrations: Integration[] }>) => {
      state.integrations = action.payload.integrations
    },
    integrationsLoading: (state: IntegrationState, action: PayloadAction<boolean>) => {
      state.loading = action.payload
    },
    totalIntegrationsChanged: (state: IntegrationState, action: PayloadAction<number>) => {
      state.totalIntegrations = action.payload
    },
    configuredIntegrationsLoaded: (
      state: IntegrationState,
      action: PayloadAction<{ configuredIntegrations: IntegrationInstance[] }>
    ) => {
      state.configuredIntegrations = action.payload.configuredIntegrations
    },
    configuredIntegrationsLoading: (state: IntegrationState, action: PayloadAction<boolean>) => {
      state.loading = action.payload
    }
  }
})

export const integrationsSelector = (state: RootState): Integration[] => state.integrations.integrations

export const configuredIntegrationsSelector = (state: RootState): IntegrationInstance[] =>
  state.integrations.configuredIntegrations

export const integrationsLoadingSelector = (state: RootState): boolean => state.integrations.loading

export const { integrationsLoaded, integrationsLoading, totalIntegrationsChanged, configuredIntegrationsLoaded } =
  integrationSlice.actions

export default integrationSlice.reducer
