import { FC, SyntheticEvent } from 'react'
import { Box, Typography } from '@mui/material'

/* Components */
import { SetupTypePickerTabs, SetupTypePickerTab } from './sentinelIntegration.styles'
import { AssistedSetupIcon, ManualSetupIcon } from '../../../svg'

function a11yProps(index: number) {
  return {
    id: `setup-type-tab-${index}`,
    'aria-controls': `setup-type-tabpanel-${index}`
  }
}

interface Props {
  value: number
  handleChangeSetupType: (n: number) => void
}

const SetupTypePicker: FC<Props> = ({ value, handleChangeSetupType }) => {
  const onChange = (event: SyntheticEvent, newValue: number) => {
    handleChangeSetupType(newValue)
  }

  const assistedSetup = (
    <Box className="text-wrapper">
      <AssistedSetupIcon />
      <Typography>Assisted Setup</Typography>
    </Box>
  )

  const manualSetup = (
    <Box className="text-wrapper">
      <ManualSetupIcon />
      <Typography>Manual Setup</Typography>
    </Box>
  )

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 0, mb: '24px' }}>
        <SetupTypePickerTabs value={value} onChange={onChange} aria-label="setup type">
          <SetupTypePickerTab {...a11yProps(0)} active={value === 0} label={assistedSetup} />
          <SetupTypePickerTab {...a11yProps(1)} active={value === 1} label={manualSetup} />
        </SetupTypePickerTabs>
      </Box>
    </Box>
  )
}

export default SetupTypePicker
