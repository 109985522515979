import { Box } from '@mui/material'
import styled from '@emotion/styled/macro'

export const CpiDetailsWrapper = styled(Box)`
  display: flex;
  align-items: center;
  width: 100%;
  margin-left: 2px;
  gap: 12px;

  .cpi-title {
    color: #e9e0e4;
    font-family: Quicksand, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.1px;
  }
`

export const InstanceLogo = styled(Box)<{ bg: string }>`
  border-radius: 50%;
  width: 16px;
  height: 16px;
  background: ${({ bg }) => `url(${bg}) no-repeat, #7c7579`};
  background-size: cover;
`

export const CpiChip = styled(Box)`
  display: flex;
  height: 29px;
  padding: 2px 8px;
  align-items: center;
  border-radius: 18px;
  background: #debdd9;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.3), 0 1px 3px 1px rgba(0, 0, 0, 0.15);

  color: #3f293e;
  text-align: center;
  font-family: Quicksand, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.5px;
`

export const SeverityChip = styled(Box)`
  display: flex;
  align-items: center;
  border-radius: 8px;
  background: linear-gradient(0deg, rgba(255, 169, 252, 0.11) 0%, rgba(255, 169, 252, 0.11) 100%), #1e1a1d;
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.15), 0 1px 2px 0 rgba(0, 0, 0, 0.3);
  padding: 6px 16px;
  color: #e9e0e4;
  text-align: center;
  font-family: Quicksand, sans-serif;
  font-size: 11px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.5px;
  text-transform: capitalize;
`
