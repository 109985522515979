import * as d3 from 'd3'
import { appendFilledAreaStroke } from '../chart-components-shared'

interface Props {
  svg: d3.Selection<SVGSVGElement | null, unknown, null, undefined>
  tooltip: d3.Selection<d3.BaseType, unknown, HTMLElement, any>
  positions: any[]
  pathStr: string
  height: number
  filteredData: any[]
  areaFill: string
  areaStroke: string
}

/*
 *  Filled Area and Stroke
 * */
export const appendFilledAreaAndStroke = ({
  svg,
  tooltip,
  positions,
  pathStr,
  height,
  filteredData,
  areaFill,
  areaStroke
}: Props) => {
  const existingFilledArea = svg.selectAll('.filled-area')
  if (!existingFilledArea.empty()) existingFilledArea.remove()

  const dValue =
    positions && positions.length
      ? `${pathStr}` + `L${positions[positions.length - 1].x} ${height} L ${positions[0].x} ${height} Z`
      : `${pathStr}`
  /*
   *  Filled Area
   * */
  svg
    .append('path')
    .datum(filteredData)
    .attr('d', dValue)
    .attr('class', 'filled-area')
    .attr('fill', `url(${areaFill})`)
    .attr('stroke', 'none')
    .on('mouseout', () => {
      tooltip.style('display', 'none')
      svg.select('.add-event-button').style('display', 'none')
      const circleOuter = svg.select('.hover-circle-outer')
      const circleInner = svg.select('.hover-circle-inner')
      circleOuter.attr('display', 'none')
      circleInner.attr('display', 'none')
    })

  /*
   * Filled Area Stroke
   * */
  appendFilledAreaStroke({ svg, areaStroke, pathStr })
}
