export const baseUnit = 8

export const iconSize = {
  small: baseUnit * 2,
  medium: baseUnit * 3,
  large: baseUnit * 4,
  xLarge: baseUnit * 5
}

/*
 * padding & margin
 * */
export const spacing = {
  xSmall: baseUnit,
  small: baseUnit * 2,
  medium: baseUnit * 3,
  large: baseUnit * 4,
  xLarge: baseUnit * 5
}
