import React, { Dispatch, FC, SetStateAction, useState } from 'react'
import { Box, TextField, Typography } from '@mui/material'
import { Form, Formik } from 'formik'
import dayjs from 'dayjs'

/* Utils */
import { getUtcDateAndTime } from '../../../../../utils'
import { CustomLabelDate, DateRangeI } from '../../../../../../../../models'
import axios from '../../../../../../../../lib/axios'

/* Components */
import { StyledInputWrapper, Wrapper } from './CreateLabels.styles'
import { ThemeButton } from '../../../../../../../components/buttons'
import { PlusIcon } from '../../../../../../../components/svg'
import CalendarDatePicker from '../components/CalendarDatePicker'

interface Props {
  redirectToMode: string
  setLoading: Dispatch<SetStateAction<boolean>>
  customDate: CustomLabelDate
  cpiName: string | undefined
  cpiConfId: string | undefined
  updateMode: (newMode: string) => void
  updateLabels: () => void
  dateRange: DateRangeI
}

const CreateLabels: FC<Props> = ({
  redirectToMode,
  cpiName,
  cpiConfId,
  dateRange,
  updateMode,
  updateLabels,
  setLoading,
  customDate
}) => {
  const [form, setForm] = useState({
    title: '',
    description: '',
    cpi_configuration_id: cpiConfId,
    date: customDate.start ? dayjs(customDate.start) : dayjs().format('YYYY-MM-DD')
  })

  const onInputChange = (e: any) => {
    setForm((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value
    }))
  }

  const isValid = () => {
    return form.title.length && form.title.length <= 20 && form.description.length && form.description.length <= 50
  }

  const onSubmit = async () => {
    try {
      setLoading(true)
      if (!form.title) form.title = 'Custom Label'

      const utcDate = getUtcDateAndTime(dayjs(form.date).format('YYYY-MM-DD'))
      const d = utcDate?.isoFormattedDate || ''

      await axios.post(`/api/v1/labels/cpis/custom/${cpiName}`, {
        ...form,
        date: d || dayjs(form.date).format('YYYY-MM-DD')
      })

      setTimeout(async () => {
        await updateLabels()
        await updateMode(redirectToMode)
        await setLoading(false)
      }, 1500)
    } catch (e) {
      console.error(e)
      setLoading(false)
    }
  }

  return (
    <Wrapper>
      <Typography className="sub-title">Pick the date where you would like to add your label : </Typography>

      <Formik initialValues={form} onSubmit={onSubmit}>
        {({ isSubmitting }) => (
          <Form autoComplete="off">
            <CalendarDatePicker
              dateRange={dateRange}
              setForm={setForm}
              editDate={customDate.start || null}
              format="MMM DD YYYY"
            />

            <Box className="line" />

            <Typography className="sub-title">Add a title and description to your label:</Typography>

            <StyledInputWrapper>
              <TextField value={form.title} placeholder="Title" name="title" onInput={(e) => onInputChange(e)} />
              <Typography className={`help ${form.title.length > 20 ? 'text-danger' : ''}`}>
                {form.title.length}/20
              </Typography>
            </StyledInputWrapper>

            <StyledInputWrapper>
              <TextField
                value={form.description}
                placeholder="Add a description"
                multiline
                rows={2}
                name="description"
                onInput={(e) => onInputChange(e)}
              />
              <Typography className={`help ${form.description.length > 50 ? 'text-danger' : ''}`}>
                {form.description.length}/50
              </Typography>
            </StyledInputWrapper>

            <Box className="drawer-actions line-actions">
              <ThemeButton
                themevariant="secondary"
                type="submit"
                className={`${!isValid() || isSubmitting ? 'disabled-btn' : ''} save-button`}
                disabled={!isValid() || isSubmitting}
              >
                <>
                  <PlusIcon />
                  Create Label
                </>
              </ThemeButton>
            </Box>
          </Form>
        )}
      </Formik>
    </Wrapper>
  )
}

export default CreateLabels
