import { Box } from '@mui/material'
import styled from '@emotion/styled/macro'

export const CPIsContainer = styled(Box)`
  width: 100%;
  flex-basis: 50%;
  gap: 12px;
  display: flex;
  flex-direction: column;
`

export const CPIsHeader = styled(Box)`
  gap: 16px;
  display: flex;
  flex-direction: column;
  height: 40px;
  padding-right: 24px;

  .cpis-header-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 40px;

    .header-text {
      color: #fff;
      font-family: Quicksand, sans-serif;
      font-size: 24px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      flex-grow: 1;
    }
  }
`

export const Content = styled(Box)<{ haseditrole?: string }>`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  max-height: calc(100vh - 216px);
  padding-right: 8px;

  .categories-weight-header {
    background: #292128;
    border-radius: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 43px;
    padding: 12px 24px;

    > .MuiTypography-root {
      color: #fff;
      font-family: Quicksand, sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;

      &:last-of-type {
        &:last-of-type {
          margin-right: ${({ haseditrole }) => (haseditrole && haseditrole === 'true' ? '80px' : '24px')};
        }
      }
    }
  }
`

export const CpisList = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 12px;
  width: 100%;
`

export const NothingFound = styled(Box)`
  flex-direction: column;
  gap: 16px;
  width: 100%;
  max-width: 250px;
  display: flex;
  justify-content: center;
  align-items: center;

  .nothing-found-title {
    color: #fff;
    font-family: Quicksand, sans-serif;
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .nothing-found-text {
    color: #fff;
    font-family: Quicksand, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
`

export const Divider = styled(Box)`
  width: 100%;
  height: 1px;
  background: #998d96;
  margin: 4px 0;
`

export const CpiListSummary = styled(Box)<{ iserrored: string; haseditrole?: string }>`
  border-radius: 16px;
  background: #372a36;
  display: flex;
  padding: 12px 24px;
  justify-content: ${({ haseditrole }) => (haseditrole && haseditrole === 'true' ? 'space-between' : 'flex-end')};
  align-items: center;
  min-height: 80px;

  .summary-start,
  .summary-end {
    display: flex;
    align-items: center;
  }

  .summary-start {
    gap: 24px;
  }

  .summary-end {
    gap: 8px;

    .total-text {
      color: #d0c3cc;
      font-family: Quicksand, sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0.5px;
    }

    .total-value {
      color: ${({ iserrored }) => (iserrored === 'true' ? '#FF8A80' : '#fff')};
      font-family: Quicksand, sans-serif;
      font-size: 22px;
      font-style: normal;
      font-weight: 400;
      line-height: 28px;
    }
  }
`
