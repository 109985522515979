import { FC, useState } from 'react'
import { Box, Typography } from '@mui/material'

/* Utils */
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks'
import { closeModal, modalPropsSelector } from '../../../../../store/modals/modalSlice'

/* Components */
import { ThemeButton } from '../../../buttons'
import { DeleteIcon } from '../../../svg'
import {
  CircleStatus,
  DeleteInstanceActionsWrapper,
  DeleteInstanceSubtitle,
  DeleteInstanceTitle,
  DeleteInstanceWrapper,
  ImgWrapper
} from './deleteDeactivate.styles'

const DeleteDeactivate: FC = () => {
  const modalProps = useAppSelector(modalPropsSelector)
  const dispatch = useAppDispatch()
  const [step, setStep] = useState(1)

  const handleClose = () => {
    if (step === 2) return setStep(1)

    dispatch(closeModal())
  }

  const handlerAccept = () => {
    // if (step === 1) return setStep(2)

    if (modalProps?.props?.callback) {
      dispatch(closeModal())
      modalProps.props.callback()
    }
  }

  const getIntegrationLogo = (integrationName: string) => {
    if (!modalProps?.props?.manifestData) return ''

    const supportedIntegration = modalProps?.props?.manifestData.supported_integrations.find(
      (n) => n.name === integrationName
    )

    if (supportedIntegration) {
      return `/icons/rounded/${supportedIntegration.logo_url}`
    }

    return '/icons/rounded/crowdstrike_falcon_icon.svg'
  }

  return (
    <DeleteInstanceWrapper step={step}>
      {step === 1 ? (
        <>
          <DeleteInstanceTitle>CPI Deactivation & Instance Deletion</DeleteInstanceTitle>
          <DeleteInstanceSubtitle>
            “{modalProps?.props?.integrationName}” is the only active source connected with{' '}
            {modalProps?.props?.manifestData?.display_name}. Deselecting this source will affect this CPI & following
            instances :
          </DeleteInstanceSubtitle>
          <Box className="cpi-wrapper">
            <Box className="cpi-name">{modalProps?.props?.manifestData?.display_name}</Box>
            <Box className="cpi-title">{modalProps?.props?.manifestData?.title}</Box>
            <Box className="cpi-desc">CPI will be deactivated </Box>
          </Box>

          <Box className="instance-wrapper">
            {modalProps?.props?.cpiSupportedAndUserConfiguredList &&
              modalProps?.props?.cpiSupportedAndUserConfiguredList.map((instance) => {
                return (
                  <Box className="instance-block" key={instance.id}>
                    <Box className="instance-name">
                      <ImgWrapper isActive={!instance.deleted}>
                        <img
                          src={getIntegrationLogo(instance.integration_name)}
                          alt={`${instance.name} logo`}
                          width="18px"
                          height="18px"
                        />
                      </ImgWrapper>

                      {instance.integration_name}
                    </Box>
                    <Box className="instance-status">
                      <CircleStatus isActive={!instance.deleted} />
                      {instance.deleted ? 'Non active' : 'Active'}
                    </Box>
                    <Typography className="instance-desc">
                      {instance.deleted
                        ? 'Previously  preserved data will be deleted. '
                        : 'Instance and collected data will be deleted.'}
                    </Typography>
                  </Box>
                )
              })}
          </Box>
        </>
      ) : (
        <>
          <DeleteInstanceTitle>Are You Sure?</DeleteInstanceTitle>
          <Box className="input-wrapper">
            You won’t be able to view or access this data anymore, even if you activate the source again. Do you still
            want to move forward and save your selection?
          </Box>
        </>
      )}

      <DeleteInstanceActionsWrapper>
        <ThemeButton themevariant="tertiary" onClick={handleClose}>
          Go Back
        </ThemeButton>
        <ThemeButton themevariant="primary" type="button" onClick={handlerAccept}>
          <>
            <DeleteIcon />
            Save Selection
          </>
        </ThemeButton>
      </DeleteInstanceActionsWrapper>
    </DeleteInstanceWrapper>
  )
}

export default DeleteDeactivate
