import styled from '@emotion/styled/macro'
import { Box } from '@mui/material'

export const Wrapper = styled(Box)`
  margin-bottom: 16px;

  .MuiMultiInputDateRangeField-separator {
    display: none;
  }

  .MuiFormControl-root {
    height: 56px;
    width: 100%;

    .MuiInputLabel-root {
      color: #998d96;
      text-align: center;
      font-family: Quicksand, sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: 0.1px;
      top: 2px;
      background: #141218;
    }

    .MuiOutlinedInput-root {
      height: 56px;
      border: 1px solid #998d96;
      border-radius: 12px;
      background: transparent;
      text-align: center;
      font-family: Quicksand, sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: 0.1px;
      color: #fff;

      &.Mui-focused {
        color: #998d96;
        border: 1px solid #998d96;
      }

      input {
        height: 56px;
      }

      svg {
        path {
          fill: #998d96;
        }
      }
    }
  }
`
