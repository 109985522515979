import styled from '@emotion/styled/macro'
import { Box, TableCell, TableContainer } from '@mui/material'
import { DataStatus } from '../../../../models'

export const CategoryCPIsWrapper = styled(Box)`
  width: 80%;
  margin: 40px auto 0;
  display: flex;
  flex-direction: column;
  align-items: center;

  .title-wrapper {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    width: 100%;
    max-width: 746px;

    .title {
      color: #e9e0e4;
      font-family: Quicksand, sans-serif;
      font-size: 24px;
      font-style: normal;
      font-weight: 400;
      line-height: 32px;
    }

    .subtitle {
      margin-top: 8px;
      color: #d0c3cc;
      font-family: Quicksand, sans-serif;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;
      letter-spacing: 0.4px;
    }
  }
`

export const CPITableContainer = styled(TableContainer)`
  margin-top: 16px;
  border-radius: 16px;
  filter: drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.3)) drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.15));
  overflow: hidden;

  .score-block {
    display: flex;
    align-items: center;
    gap: 16px;
  }

  .over-perform {
    display: flex;
    align-items: center;
    gap: 2px;
  }

  .icons-wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-flow: row nowrap;
    cursor: pointer;
    gap: 4px;

    .icons-text {
      font-family: Quicksand, sans-serif;
      color: #d0c3cc;
      font-size: 11px;
      font-style: normal;
      font-weight: 500;
      line-height: 16px;
      letter-spacing: 0.5px;
    }

    .MuiBox-root {
      position: relative;
      width: 12px;
      overflow: hidden;
      border-radius: 50%;
      box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.3), 0 2px 6px 2px rgba(0, 0, 0, 0.15);

      img {
        width: 100%;
        height: auto;
      }
    }
  }

  .last-tr {
    padding: 16px;
    border-top: 1px solid #e9e0e4;
  }

  .last-tr-big {
    padding: 22px;
    border-top: 1px solid #e9e0e4;
  }

  .MuiTableHead-root {
    background: linear-gradient(0deg, rgba(255, 169, 252, 0.11) 0%, rgba(255, 169, 252, 0.11) 100%), #1e1a1d;

    .MuiTableCell-root {
      border-bottom: 1px solid #4d444b;
      color: #e9e0e4;
      font-family: Quicksand, sans-serif;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 16px;
      letter-spacing: 0.5px;
      vertical-align: baseline;
    }
  }

  .MuiTableBody-root {
    .MuiTableRow-root {
      background: linear-gradient(0deg, rgba(255, 169, 252, 0.05) 0%, rgba(255, 169, 252, 0.05) 100%), #1e1a1d;

      .MuiTableCell-root {
        border-bottom: 1px solid #342f32;
        color: #e9e0e4;
        font-family: Quicksand, sans-serif;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
        letter-spacing: 0.4px;
      }

      .no-border {
        border-bottom: none;
      }
    }
  }
`

export const CPICell = styled(TableCell)`
  vertical-align: baseline !important;

  .cpi-cell-wrapper {
    display: flex;
    align-items: center;

    .cpi-chip {
      cursor: pointer;
      color: #3f293e;
      text-align: center;
      font-family: Quicksand, sans-serif;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 16px;
      letter-spacing: 0.5px;
      border-radius: 18px;
      background: #debdd9;
      box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.15), 0 1px 2px 0 rgba(0, 0, 0, 0.3);
      padding: 2px 8px;
      white-space: nowrap;
    }

    &.is-failed {
      .cpi-chip {
        position: relative;
        border: 1px solid #ff8a80;

        svg {
          position: absolute;
          bottom: -3px;
          right: -6px;

          &.error-content {
            right: -4px;
            bottom: -1px;
          }
        }
      }
    }

    .cpi-title {
      color: #e9e0e4;
      font-family: Quicksand, sans-serif;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;
      letter-spacing: 0.4px;
      margin-left: 35px;
      width: 230px;
    }
  }
`

export const RecentFindingCell = styled(TableCell)<{ status: DataStatus.Initializing | 'healthy' | 'problematic' }>`
  .MuiTypography-root {
    color: #ff8a80;
    text-align: center;
    font-family: Quicksand, sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0.5px;

    padding: 2px 8px;
    gap: 3px;
    border-radius: 18px;
    background: rgba(255, 138, 128, 0.2);
    display: inline-flex;
    white-space: break-spaces;

    ${({ status }) =>
      status === 'healthy' &&
      `
      color: #AED581;
      background: rgba(174, 213, 129, 0.20);
    `};

    ${({ status }) =>
      status === DataStatus.Initializing &&
      `
      color: #FFE082;
      background: rgba(255, 224, 130, 0.20);
    `};
  }
`

export const SLACell = styled(TableCell)<{ type: 'less' | 'same' | 'more' }>`
  width: 100px;

  .MuiTypography-root {
    color: #c7ccff;
    text-align: center;
    font-family: Quicksand, sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0.5px;

    border-radius: 18px;
    background: rgba(142, 154, 255, 0.4);
    padding: 2px 8px;
    gap: 3px;

    ${({ type }) =>
      type === 'same' &&
      `
      color: #FFD2AA;
      background: rgba(240, 149, 67, 0.40);
    `};

    ${({ type }) =>
      type === 'more' &&
      `
      color: #FFD6F9;
      background: rgba(176, 81, 179, 0.30);
    `};
  }
`

export const DataSourcesCell = styled(TableCell)`
  .data-sources-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    gap: 4px;
  }
`

export const CategorySeparator = styled(Box)`
  width: 80%;
  height: 1px;
  background: #998d96;
  margin-left: auto;
  margin-right: auto;

  @media (max-width: 1440px) {
    margin-top: 40px;
    max-width: 580px;
  }
`

export const CategoryPerformanceScoreWrapper = styled(Box)<{ issolo?: string }>`
  display: flex;
  flex-direction: column;
  border-radius: 16px;
  border: 1px solid #998d96;
  background: linear-gradient(0deg, rgba(255, 169, 252, 0.05) 0%, rgba(255, 169, 252, 0.05) 100%), #1e1a1d;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.3), 0 1px 3px 1px rgba(0, 0, 0, 0.15);
  padding: 16px 24px 16px;
  margin-bottom: 40px;
  width: 100%;

  ${({ issolo }) =>
    issolo &&
    issolo === 'true' &&
    `
    width: 100%;
  `};

  @media (max-width: 1440px) {
    margin-right: 0;
  }

  .over-perform {
    display: flex;
    align-items: center;
    gap: 4px;

    b {
      color: white;
      font-family: 'Quicksand', sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: 0.1px;
    }

    .value {
      color: white;
      font-family: 'Quicksand', sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0.25px;
    }
  }

  .single-item-header-wrapper {
    display: flex;
    flex-direction: column;
    //align-items: center;
    //justify-content: space-between;
    gap: 16px;
    margin: 16px 0 24px;
  }

  .header-block {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;

    .title {
      color: #e9e0e4;
      font-family: Quicksand, sans-serif;
      font-size: 22px;
      font-style: normal;
      font-weight: 400;
      line-height: 28px;
    }

    .date {
      color: #998d96;
      font-family: Quicksand, sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: 0.1px;
    }
  }

  .chips-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 16px;

    .chip {
      display: flex;
      align-items: center;

      svg {
        margin-right: 4px;
      }

      .chip-circle {
        margin-right: 4px;
        border-radius: 12px;
        box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.3), 0 8px 12px 6px rgba(0, 0, 0, 0.15);
        width: 12px;
        height: 12px;

        &.pink {
          background: #ffa9fc;
        }

        &.blue {
          background: #3754fc;
        }

        &.less {
          background: #8e9aff;
        }

        &.same {
          background: #f09543;
        }

        &.more {
          background: #b051b3;
        }
      }

      .MuiTypography-root {
        color: #d0c3cc;
        font-family: Quicksand, sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0.1px;

        &.chip-sla {
          font-size: 12px;
          line-height: 16px;
          letter-spacing: 0.5px;
        }
      }

      &:not(:first-of-type) {
        margin-left: 24px;
      }
    }
  }

  .table-title {
    color: #e9e0e4;
    font-family: Quicksand, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.25px;
    margin-bottom: 9px;
    margin-top: 29px;
  }

  .pie-charts {
    display: flex;
    justify-content: center;
    width: 100%;
    flex-grow: 1;

    .pie-chart-wrapper {
      display: flex;
      justify-content: center;
      width: 200px;
    }
  }

  .select-wrapper {
    .MuiFormControl-root {
      .MuiOutlinedInput-root {
        outline: 0 !important;
        background: transparent !important;
        border-radius: 8px !important;
        border: 1px solid #998d96 !important;
      }
    }
  }

  .no-category {
    border-radius: 2px;
    background: #4d444b;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.3), 0 2px 6px 2px rgba(0, 0, 0, 0.15);
    font-family: 'Quicksand', sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.4px;
    color: white;
    padding: 6px 7px;
    display: flex;
    align-items: center;
    gap: 8px;
    margin: 80px 0 80px;
  }
`

export const HistoricalBenchmarkingChartWrapper = styled(Box)`
  display: flex;
  justify-content: center;
  height: 470px;
  position: relative;
  pointer-events: none;

  .benchmarking-chart-y-axis {
    overflow: visible;
  }

  .benchmarking-y-axis-g {
    .tick:nth-child(1) {
      transform: translate(0px, 390px);
    }
  }

  .benchmarking-chart-svg {
    height: 470px;
    overflow: visible;
    margin: 0;

    .border-outline {
      outline: 1px solid #998d96;
      border-radius: 16px;
      fill: transparent;
    }
  }

  .benchmarking-tooltip {
    display: none;
    position: absolute;
    opacity: 1;
    padding: 16px;
    gap: 10px;
    border-radius: 8px;
    border: 1px solid #998d96;
    background: #4d444b;
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.3), 0 8px 12px 6px rgba(0, 0, 0, 0.15);
    color: #fff;
    font-family: Quicksand, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.1px;
    width: 210px;
    z-index: 9999;

    .tooltip-performance,
    .tooltip-units {
      color: #fff;
      font-family: Quicksand, sans-serif;
      font-style: normal;
      font-weight: 400;
    }

    .tooltip-performance {
      font-size: 24px;
      line-height: 32px;
      margin: 0 0 4px 0;
    }

    .tooltip-units {
      font-size: 12px;
      line-height: 16px;
      letter-spacing: 0.4px;
      margin: 0;
    }

    .severity-text {
      text-transform: capitalize;
    }
  }

  //@media (max-width: 1440px) {
  //  height: 358px;
  //
  //  .benchmarking-chart-svg {
  //    height: 358px;
  //  }
  //}
`

export const CategoryCurrentFindingsWrapper = styled(Box)`
  width: 862px;
  gap: 24px;
  display: flex;
  flex-direction: column;
  margin: 40px auto 0;

  @media (max-width: 1440px) {
    gap: 16px;
    align-items: center;
  }

  .title-wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;

    @media (max-width: 1440px) {
      max-width: 624px;
    }

    .title {
      color: #e9e0e4;
      font-family: Quicksand, sans-serif;
      font-size: 24px;
      font-style: normal;
      font-weight: 400;
      line-height: 32px;
      margin-right: 24px;
    }
  }

  .container {
    display: flex;
    flex-direction: column;
    width: 100%;

    .container-inner {
      width: 862px;
      display: flex;
      justify-content: center;
      margin: 0 auto;
      gap: 24px;
      flex-direction: column;
      align-items: flex-start;

      .container-inner-2 {
        width: 100%;
        display: flex;
        flex-direction: column;

        @media (max-width: 1440px) {
          align-items: center;
        }
      }

      .container-header {
        margin-top: 38px;
        width: 100%;
        max-width: 1280px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
      }
    }

    .container-inner-large {
      width: 943px;
    }

    .title-wrapper {
      width: 100%;
      max-width: 1280px;

      @media (max-width: 1440px) {
        max-width: 536px;
      }
    }

    .performance-score-container {
      display: flex;

      .performance-score-chart-container {
        margin-top: 13px;
        width: 100%;
        display: flex;
        justify-content: center;
      }
    }

    .performance-score-chart::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 0 rgba(0, 0, 0, 0);
      background: #2c242c;
    }

    .performance-score-chart::-webkit-scrollbar-thumb {
      height: 5px;
      background-color: #524256;
    }

    .performance-score-chart::-webkit-scrollbar-thumb:hover {
      background-color: #524256;
    }

    .performance-score-chart::-webkit-scrollbar:vertical {
      display: none;
    }
  }
`

export const FooterCell = styled(TableCell)`
  border-top: 1px solid #998d96;
  color: #e9e0e4;
  text-align: right;
  font-family: Quicksand, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.4px;
  padding: 0;

  .MuiTypography-root {
    color: #e9e0e4;
    font-family: Quicksand, sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.4px;

    &.less,
    &.same,
    &.more {
      text-align: right;
      font-family: Quicksand, sans-serif;
      font-size: 22px;
      font-style: normal;
      font-weight: 400;
      line-height: 28px;
    }

    &.less {
      color: #c7ccff;
    }

    &.same {
      color: #ffd2aa;
    }

    &.more {
      color: #ffd6f9;
    }

    &.performance-footer-score {
      font-family: Quicksand, sans-serif;
      font-size: 22px;
      font-style: normal;
      font-weight: 400;
      line-height: 28px;
      text-align: right;
      margin-left: 16px;
      color: #fff;
    }
  }
`

export const CategoryFooterCarouselWrapper = styled(Box)`
  width: 80%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto 90px;

  .container {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 27px;
    width: 100%;

    .carousel-item {
      padding: 16px 24px;
      border-radius: 16px;
      background: linear-gradient(0deg, rgba(255, 169, 252, 0.11) 0%, rgba(255, 169, 252, 0.11) 100%), #1e1a1d;
      display: flex;
      flex-direction: column;
      cursor: pointer;

      @media (min-width: 768px) {
        min-width: 380px;
      }

      @media (min-width: 1440px) {
        min-width: 453px;
      }

      &.carousel-prev {
        align-items: flex-start;
      }

      &.carousel-next {
        align-items: flex-end;
      }

      .text-wrapper {
        display: flex;
        align-items: center;
        margin-bottom: 8px;

        .text {
          color: #d0c3cc;
          text-align: center;
          font-family: Quicksand, sans-serif;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px;
          letter-spacing: 0.5px;
          margin-left: 8px;

          &.text-right {
            margin-left: 0;
            margin-right: 8px;
          }
        }
      }

      .title {
        color: #e9e0e4;
        text-align: center;
        font-family: Quicksand, sans-serif;
        font-size: 22px;
        font-style: normal;
        font-weight: 400;
        line-height: 28px;
        text-transform: capitalize;
      }
    }
  }
`

export const BenchmarkValueWrapper = styled(Box)<{
  one: string | number
  two: string | number
  three: string | number
}>`
  width: 100%;
  height: 47px;
  display: flex;
  align-items: center;
  cursor: pointer;
  box-sizing: content-box;
  margin-top: 4px;
  color: #fff;
  text-align: center;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.3), 0 1px 3px rgba(0, 0, 0, 0.15);
  font-family: Quicksand, sans-serif;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;

  &:hover {
    .one {
      opacity: 0.38;
    }

    .two {
      opacity: 0.38;
    }

    .three {
      opacity: 0.38;
    }
  }

  .one,
  .two,
  .three {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .one {
    width: ${({ one }) => `${one}%`};
    border-radius: 24px 0 0 24px;
    border-top: 1px solid #8e9aff;
    border-bottom: 1px solid #8e9aff;
    border-left: 1px solid #8e9aff;
    background: linear-gradient(180deg, #8e9aff 0%, rgba(142, 154, 255, 0.4) 100%);
    height: 47px;

    &:hover {
      border: 1px solid #fff;
      box-shadow: 0 0 16px 0 rgba(255, 255, 255, 0.25);
      opacity: 1;
    }
  }

  .two {
    width: ${({ two }) => `${two}%`};
    border-top: 1px solid #f09543;
    border-bottom: 1px solid #f09543;
    background: linear-gradient(180deg, rgba(240, 149, 67, 0.94) 0%, rgba(240, 149, 67, 0.4) 100%);
    height: 47px;
    margin: 0 auto;

    &:hover {
      border: 1px solid #fff;
      box-shadow: 0 0 16px 0 rgba(255, 255, 255, 0.25);
      opacity: 1;
    }
  }

  .three {
    width: ${({ three }) => `${three}%`};
    border-radius: 0 24px 24px 0;
    border-top: 1px solid #b051b3;
    border-right: 1px solid #b051b3;
    border-bottom: 1px solid #b051b3;
    background: linear-gradient(180deg, #781a7e 0%, rgba(120, 26, 126, 0.4) 100%);
    color: #fff;
    height: 47px;

    &:hover {
      border: 1px solid #fff;
      box-shadow: 0 0 16px 0 rgba(255, 255, 255, 0.25);
      opacity: 1;
    }
  }
`
