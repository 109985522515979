import { Box } from '@mui/material'
import styled from '@emotion/styled/macro'

export const DeselectNotificationWrapper = styled(Box)`
  padding: 28px;
  background: linear-gradient(0deg, rgba(255, 169, 252, 0.05) 0%, rgba(255, 169, 252, 0.05) 100%), #1e1a1d;

  .title {
    font-family: 'Quicksand', sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px;
    color: #e9e0e4;
    margin-bottom: 12px;
  }

  .description {
    font-family: 'Quicksand', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.5px;
    color: #e9e0e4;
    max-width: 422px;
  }
`

export const ActionWrapper = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 8px;
  margin-top: 28px;
`
