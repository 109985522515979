import React, { FC } from 'react'
import { FormControl, Select, CategoryMenuProps } from './reportsTimePeriodSelectField.styles'
import { SelectDropdownIcon } from '../../../../components/svg'
import { ListItemText, MenuItem, SelectProps } from '@mui/material'

interface Props extends SelectProps {
  options: string[]
  value: any
  handleChange: any
  minWidth?: string
  disabled?: boolean
}

const ReportsSelectField: FC<Props> = ({ options, handleChange, placeholder, minWidth, value, disabled, ...rest }) => {
  return (
    <FormControl
      sx={{ minWidth }}
      isselected={
        placeholder !== 'Sort' && (value.length > 1 || (value.length === 1 && value[0] !== '')) ? 'true' : 'false'
      }
      is_disabled={disabled ? 'true' : 'false'}
    >
      <Select
        isselected={
          placeholder !== 'Sort' && (value.length > 1 || (value.length === 1 && value[0] !== '')) ? 'true' : 'false'
        }
        labelId="reports-customize-select-label"
        id="reports-customize-select"
        displayEmpty
        MenuProps={{
          ...CategoryMenuProps,
          PaperProps: {
            ...CategoryMenuProps.PaperProps,
            style: { maxHeight: 'unset' }
          }
        }}
        sx={{ minWidth }}
        IconComponent={(props) => <SelectDropdownIcon {...props} />}
        renderValue={(selected) => {
          if (Array.isArray(selected)) {
            const pruned = selected.filter((s: string) => s !== '')
            if (pruned.length === 0) {
              return placeholder
            }
            if (pruned.length === 1) {
              return pruned[0]
            }

            return `${pruned[0]} +${pruned.length - 1}`
          }

          return selected && (selected as string).length > 0 ? selected : 'Select'
        }}
        inputProps={{ 'aria-label': 'Without label' }}
        {...rest}
        placeholder="Select"
        label="Select"
        value={value}
        onChange={handleChange}
      >
        {options.map((option) => (
          <MenuItem key={option} value={option} disableRipple>
            <ListItemText primary={option} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

export default ReportsSelectField
