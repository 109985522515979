import { Dispatch, FC, SetStateAction } from 'react'
import { CircularProgress } from '@mui/material'
import { IntegrationsListWrapper } from '../integrations.styles'
import { IntegrationInstance, ManifestIntegration } from '../../../../../models'
import IntegrationCard from './IntegrationCard'

interface Props {
  integrations: ManifestIntegration[]
  instances: IntegrationInstance[]
  handleOpenDetails: () => void
  detailsOpen: boolean
  setTabValue: Dispatch<SetStateAction<number>>
  setSelectedInstance: Dispatch<SetStateAction<IntegrationInstance | null>>
  resultsLoading: boolean
}

const IntegrationsList: FC<Props> = ({
  integrations,
  setSelectedInstance,
  detailsOpen,
  handleOpenDetails,
  setTabValue,
  instances,
  resultsLoading
}) => (
  <IntegrationsListWrapper>
    {resultsLoading ? (
      <CircularProgress size="40px" color="primary" />
    ) : (
      !!integrations &&
      integrations.map((n, index) => (
        <IntegrationCard
          key={index}
          integration={n}
          setTabValue={setTabValue}
          handleOpenDetails={handleOpenDetails}
          detailsOpen={detailsOpen}
          instances={instances}
          setSelectedInstance={setSelectedInstance}
        />
      ))
    )}
  </IntegrationsListWrapper>
)

export default IntegrationsList
