import { Typography, Drawer as MuiDrawer, Box, IconButton, Button } from '@mui/material'
import styled from '@emotion/styled/macro'
import { colors } from '../../../../../theme/'

export const Drawer = styled(MuiDrawer)`
  .MuiPaper-root {
    max-width: 523px;
    overflow-x: hidden;

    > .MuiBox-root {
      padding: 0;

      > .MuiButtonBase-root:first-of-type {
        top: 28px;
        right: 25px;
      }
    }

    background-color: ${colors.backgroundDark};
  }
`

export const Container = styled(Box)`
  width: 100%;
  height: 100%;
  min-width: 523px;
  background-color: ${colors.backgroundDark};
  overflow-x: hidden;
`

export const FormWrapper = styled(Box)`
  padding-left: 27px;
  margin-top: 12px;
  overflow-x: hidden;
  position: relative;
  min-height: calc(100vh - 104px);
  height: auto;
`

export const InnerForm = styled(Box)`
  display: flex;
  flex-flow: column wrap;
  height: 100%;
  padding-bottom: 100px;
  overflow-x: hidden;
`

export const Header = styled(Box)`
  padding: 24px 24px 0;
`

export const HeaderIconButton = styled(IconButton)`
  position: absolute;
  top: 24px;
  right: 24px;
`

export const Title = styled(Typography)`
  text-transform: capitalize;
  font-size: 36px;
  line-height: 44px;
  font-weight: 800;
  color: ${colors.white};
  margin-bottom: 4px;
`

export const Subtitle = styled(Typography)`
  font-size: 14px;
  line-height: 20px;
  font-weight: 300;
  color: #dbdbdb;
`

export const FinishButton = styled(Button)<{ disabled?: boolean }>`
  position: absolute;
  bottom: 40px;
  right: 40px;
  width: 200px;
  height: 36px;
  color: #23053a;
  background: #f4ba94;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  text-transform: capitalize;
  font-size: 16px;
  line-height: 24px;
  font-weight: 700;

  ${({ disabled }) =>
    disabled &&
    `
    background-color: rgba(255, 255, 255, 0.4);
  `};
`

export const inputLabelStyles = {
  color: '#DBDBDB',
  fontSize: '12px',
  lineHeight: '16px',
  mt: '16px',
  mb: '2px'
}

export const textFieldStyles = {
  width: '441px',
  height: '32px',
  backgroundColor: '#fff'
}

export const textAreaStyles = {
  width: '441px',
  backgroundColor: '#fff',
  border: '1px solid #ACACAC',
  borderRadius: '4px',
  fontFamily: '"Rubik", "Source Sans Pro", sans-serif',
  outline: 'none'
}

export const drawerContainer = { width: '100%', minWidth: '523px', backgroundColor: colors.backgroundDark }

export const ViewTicketActionWrapper = styled(Box)`
  display: flex;
  justify-content: space-between;
  position: absolute;
  bottom: 18px;
  left: 40px;
  width: calc(100% - 80px);
`

export const DeleteTicketWrapper = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-flow: column wrap;
  padding: 16px 16px 8px;
`

export const DeleteTicketButtonWrapper = styled(Box)`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-top: 24px;

  .MuiButtonBase-root:first-of-type {
    margin-right: 40px !important;
  }

  .MuiButtonBase-root:nth-of-type(2) {
    margin: 0 !important;
  }
`

export const DeleteTicketTitle = styled(Typography)`
  font-size: 22px;
  line-height: 28px;
  font-weight: 800;
  color: #f5f5f5;
`

export const DeleteTicketText = styled(Typography)`
  font-size: 14px;
  line-height: 22px;
  font-weight: 400;
  color: #f5f5f5;
  margin-top: 16px;
`
