import { FC } from 'react'
import {
  MenuItem,
  FormControl,
  ListItemText,
  Select,
  OutlinedInput,
  SelectProps,
  SelectChangeEvent
} from '@mui/material'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import { FormikErrors } from 'formik'
import { FormValues } from '../modal/variants/jira-integration/schemaMapping.constants'
import { formControlSx, menuItemSx, MenuProps, selectSx, selectInputSx } from './styles/uncontrolledSelectField.styles'
import '../modal/variants/jira-integration/select.module.css'

interface Props extends SelectProps {
  options: string[]
  placeholder: string
  value: any
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => Promise<FormikErrors<FormValues>> | Promise<void>
}

const UncontrolledSelectField: FC<Props> = ({ options, name, value, setFieldValue, ...rest }) => {
  const handleChange = (event: SelectChangeEvent<any>) => {
    const {
      target: { value }
    } = event
    setFieldValue(name as string, Array.isArray(value) ? value.join(',') : value)
  }

  return (
    <div>
      <FormControl sx={formControlSx}>
        <Select
          labelId="demo-multiple-checkbox-label"
          id="demo-multiple-checkbox"
          displayEmpty
          MenuProps={MenuProps}
          IconComponent={(props) => <ExpandLessIcon {...props} />}
          renderValue={(selected: string) => selected}
          inputProps={{ 'aria-label': 'Without label' }}
          sx={selectSx}
          {...rest}
          value={value}
          onChange={handleChange}
          input={<OutlinedInput sx={selectInputSx} />}
        >
          {options.map((option) => (
            <MenuItem key={option} value={option} sx={menuItemSx}>
              <ListItemText primary={option} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  )
}

export default UncontrolledSelectField
