import { FC, MouseEvent } from 'react'
import { Box, Grid, IconButton, InputAdornment, Link, Typography } from '@mui/material'

/* Components */
import ControlledTextField from '../../../form-components/ControlledTextField'
import CrowdstrikeInstructions from './CrowdstrikeInstructions'
import { CrowdstrikeInputWrapper } from './crowdstrikeIntegration.styles'
import { Visibility, VisibilityOff } from '@mui/icons-material'

const instructionsContent = (
  <ol>
    <li>
      <Typography>
        Go to your{' '}
        <Link href="https://www.crowdstrike.com" target="_blank" rel="noopener noreferrer">
          Crowdstrike Portal.
        </Link>
      </Typography>
    </li>
    <li>
      <Typography>
        In your portal, open the menu (top left corner), select “Support and Resources”, then click “API Clients and
        Keys”.
      </Typography>
    </li>
    <li>
      <Typography>
        Name the new API client and give it a description. Check off all the boxes in the “Read” column, the click
        “Add”.
      </Typography>
    </li>
    <li>
      <Typography>
        Your Client ID, Secret, and Base URL will display. Copy these, and past them in the fields in this wizard.
      </Typography>
    </li>
  </ol>
)

interface Props {
  activeStep: number
  showPassword: boolean
  handleClickShowPassword: () => void
  handleMouseDownPassword: (event: MouseEvent<HTMLButtonElement>) => void
}

const CrowdstrikeIntegrationStep1: FC<Props> = ({
  activeStep,
  showPassword,
  handleClickShowPassword,
  handleMouseDownPassword
}) => (
  <Box sx={{ display: 'flex' }}>
    <CrowdstrikeInstructions content={instructionsContent} />
    {activeStep === 0 && (
      <>
        <Grid container spacing={0} sx={{ pl: '72px' }}>
          <Grid item xs={12}>
            <Grid container spacing={0}>
              <Grid item xs={12}>
                <CrowdstrikeInputWrapper>
                  <ControlledTextField
                    data-private
                    id="client-id"
                    name="clientId"
                    label="Client ID *"
                    type="text"
                    variant="filled"
                  />
                </CrowdstrikeInputWrapper>
              </Grid>
              <Grid item xs={12}>
                <CrowdstrikeInputWrapper>
                  <ControlledTextField
                    data-private
                    id="secret"
                    name="secret"
                    label="Secret *"
                    variant="filled"
                    type={showPassword ? 'text' : 'password'}
                    autoComplete="current-password"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle Client Secret visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                  />
                </CrowdstrikeInputWrapper>
              </Grid>
              <Grid item xs={12}>
                <CrowdstrikeInputWrapper>
                  <ControlledTextField
                    data-private
                    id="base-url"
                    name="baseUrl"
                    label="Base URL *"
                    type="text"
                    variant="filled"
                  />
                </CrowdstrikeInputWrapper>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </>
    )}
  </Box>
)

export default CrowdstrikeIntegrationStep1
