import { Box, DialogActions, DialogContent } from '@mui/material'
import styled from '@emotion/styled/macro'

export const Wrapper = styled(Box)``

export const TransferOwnershipWrapper = styled(Box)`
  .content {
    padding: 8px 16px;
    margin-bottom: 40px;

    .title {
      font-size: 36px;
      font-family: Quicksand, sans-serif;
      line-height: 44px;
      color: #e9e0e4;
    }

    .text {
      color: #e9e0e4;
      font-size: 14px;
      font-family: Quicksand, sans-serif;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: 0.1px;
      margin-top: 8px;
    }
  }
`

export const Header = styled(Box)`
  padding: 8px 16px 16px;
  border-bottom: 1px solid #342f32;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .title {
    font-family: 'Quicksand', sans-serif;
    font-weight: 400;
    font-size: 36px;
    line-height: 44px;
    color: #e9e0e4;
  }
`

export const Content = styled(DialogContent)<{ userrole: string }>`
  padding: 24px 40px 0;
  max-width: 700px;
  margin: 0 auto;

  .info-wrapper {
    display: flex;
    flex-flow: column wrap;
    justify-content: center;
    align-items: flex-start;

    &:not(:last-of-type) {
      border-bottom: 1px solid #342f32;
    }

    .info-item {
      display: flex;
      flex-flow: column wrap;
      justify-content: center;
      align-items: flex-start;
      margin: 20px 0;

      &:first-of-type {
        margin-top: 0;
      }

      .title {
        font-family: 'Quicksand', sans-serif;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.1px;
        color: #d0c3cc;
        margin-right: 24px;
        margin-bottom: 4px;
      }

      .description-box {
        display: flex;
        align-items: center;

        .permissions-container {
          min-width: 100px;
        }

        button {
          white-space: nowrap;
        }

        .permissions-title {
          width: fit-content;
          min-width: 64px;
          height: 32px;
          border-radius: 8px;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 6px 12px;
          gap: 8px;
          font-family: 'Quicksand', sans-serif;
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
          letter-spacing: 0.1px;
          text-transform: capitalize;

          ${({ userrole }) =>
            userrole === 'owner' &&
            `
              background: #FFA9FC;
              opacity: 0.9;
              color: #590060;
          `};

          ${({ userrole }) =>
            userrole === 'admin' &&
            `
              background: #B2EBF2;
              color: #1E1A1D;
          `};

          ${({ userrole }) =>
            ['cpi manager', 'manager', 'cpi_manager'].includes(userrole) &&
            `
              background: #AED581;
              color: #1E1A1D;
          `};

          ${({ userrole }) =>
            userrole === 'viewer' &&
            `
              background: #FFE082;
              color: #1E1A1D;
          `};

          ${({ userrole }) =>
            userrole === 'default' &&
            `
              background: #3754FC;
              color: #FFFFFF;
          `};

          ${({ userrole }) =>
            userrole === 'demo' &&
            `
              background: #DEBDD9;
              color: #3F293E;
          `};
        }

        .status-container {
        }

        .user-status {
          width: fit-content;
          height: 32px;
          padding: 6px 16px;
          gap: 8px;
          border: 1px solid #998d96;
          border-radius: 8px;
          background: transparent;
          font-family: 'Quicksand', sans-serif;
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
          display: flex;
          justify-content: center;
          align-items: center;
          text-align: center;
          letter-spacing: 0.1px;
          color: #e9e0e4;
        }

        .description {
          font-family: 'Quicksand', sans-serif;
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          letter-spacing: 0.25px;
          color: #e9e0e4;
          margin-right: 24px;
          width: 66%;
          word-break: break-all;
        }

        .MuiButtonBase-root {
          padding: 6px 20px;
        }
      }
    }
  }

  .input-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;

    .MuiFormControl-root {
      width: 250px;

      label {
        color: #d0c3cc;

        .MuiInputLabel-asterisk {
          color: #fe455d;
        }
      }

      .MuiOutlinedInput-root {
        border-radius: 4px;
        color: #d0c3cc;
        font-family: 'Quicksand', sans-serif;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.5px;

        fieldset {
          border: 1px solid #998d96 !important;
        }
      }
    }

    .MuiFormHelperText-root {
      margin-left: 0;
    }
  }

  .error-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 2px;

    .MuiTypography-root {
      width: 250px;
      font-family: 'Quicksand', sans-serif;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      letter-spacing: 0.4px;
      color: #ffb4ab;
    }
  }

  .roles-wrapper {
    padding-bottom: 78px;

    .roles-title {
      font-family: 'Quicksand', sans-serif;
      font-weight: 400;
      font-size: 22px;
      line-height: 28px;
      color: #e9e0e4;

      span {
        color: #fe455d;
      }
    }

    .MuiFormGroup-root {
      .MuiFormControlLabel-root {
        display: flex;
        align-items: flex-start;
        margin-left: 8px;

        &:not(:last-of-type) {
          margin-bottom: 16px;
        }

        &:first-of-type {
          margin-top: 8px;
        }

        .MuiRadio-root {
          padding: 2px 0 0;

          svg {
            width: 20px;
            height: 20px;

            path {
              fill: #d0c3cc;
            }
          }
        }

        .roles-name {
          font-family: 'Quicksand', sans-serif;
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          letter-spacing: 0.5px;
          color: #e9e0e4;
          margin-left: 18px;
        }

        .roles-description {
          font-family: 'Quicksand', sans-serif;
          font-weight: 400;
          font-size: 12px;
          line-height: 16px;
          letter-spacing: 0.4px;
          color: #d0c3cc;
          margin-left: 18px;
        }
      }
    }
  }
`

export const Actions = styled(DialogActions)`
  background: linear-gradient(0deg, rgba(255, 169, 252, 0.05), rgba(255, 169, 252, 0.05)), #1e1a1d;
  box-shadow: 0 2px 6px 2px rgba(0, 0, 0, 0.15), 0 1px 2px rgba(0, 0, 0, 0.3);
  border-radius: 0 0 16px 16px;
  padding: 16px 36px;
  border-top: 1px solid #342f32;
`
