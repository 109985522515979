import { PerformanceStatus } from './'

export interface HistoryPerformanceData {
  xValue: string
  yValue: number
  dummy?: boolean
}

export interface HistoryPerformanceDataExtended {
  xValue: string
  yValue: number
  start: string
  end: string
  dummy?: boolean
}

export interface HistoryBenchmarkDataValues {
  less: number
  equal: number
  more: number
  date: string
  dummy?: boolean
}

export interface HistoryBenchmarkDataValuesExtended extends HistoryBenchmarkDataValues {
  start: string
  end: string
  month?: string
  dummy?: boolean
}

export interface HistoryBenchmarkData {
  industry_id: string
  industry_name: string
  values: HistoryBenchmarkDataValues[]
}

export interface CategoryDataPayload {
  timePeriod: string
  category: string
}

export interface InactiveCPI {
  name: string
  title: string
  potentialSources: string[]
}

export enum NistFunction {
  Respond = 'Respond',
  Detect = 'Detect',
  Identify = 'Identify',
  Protect = 'Protect',
  Recover = 'Recover',
  Govern = 'Govern'
}

export enum OnyxiaFunction {
  DetectionAndRespond = 'detection-and-response',
  VulnerabilityManagement = 'vulnerability-management',
  TrainingAndAwareness = 'training-and-awareness',
  CloudSecurity = 'cloud-security',
  IAM = 'iam',
  NetworkSecurity = 'network-security',
  DeviceManagement = 'device-management',
  MailAndWebSecurity = 'mail-and-web-security',
  ApplicationSecurity = 'application-security'
}

export enum NistCategory {
  AssetManagement = 'Asset Management',
  AwarenessAndTraining = 'Awareness and Training',
  IdentityManagement = 'Identity Management, Authentication, and Access Control'
}

export type IChartDataCategory = {
  category: string
  status: PerformanceStatus
  score: number
}
