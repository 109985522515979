import { FC } from 'react'
import { Box, Typography } from '@mui/material'

/* Components */
import { ScoreWrapper } from './OverallPerformanceScore.styles'
import { OverPerformIcon } from '../../../../components/svg'
import OverPerformWidget from './OverPerformWidget'

interface Props {
  performanceScore: number
}

const OverallPerformanceScore: FC<Props> = ({ performanceScore }) => (
  <ScoreWrapper>
    <Box className="title-wrapper">
      <img src="/title-vector.svg" alt="title-vector" />
      <Typography className="chart-title">Overall Performance Score</Typography>
    </Box>

    <Box className="content">
      <OverPerformWidget value={+performanceScore} />

      {+performanceScore - 100 > 0 && (
        <Box className="over-perform">
          <OverPerformIcon />
          <Typography className="value">
            <b>{(+performanceScore - 100).toFixed(1)}%</b> Over Performing
          </Typography>
        </Box>
      )}
    </Box>
  </ScoreWrapper>
)

export default OverallPerformanceScore
