import { FC, SVGProps } from 'react'

const IntegrationsDeleteIcon: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <rect width="40" height="40" rx="20" fill="#FFB4AB" />
    <path
      d="M12.75 27C12.75 28.1 13.7625 29 15 29H24C25.2375 29 26.25 28.1 26.25 27V15H12.75V27ZM15 17H24V27H15V17ZM23.4375 12L22.3125 11H16.6875L15.5625 12H11.625V14H27.375V12H23.4375Z"
      fill="#690005"
    />
  </svg>
)

export default IntegrationsDeleteIcon
