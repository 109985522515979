import { forwardRef, useState } from 'react'
import { CircularProgress, IconButton } from '@mui/material'
import dayjs from 'dayjs'

/* Utils */
import { Label, SystemEventName, VisibleLabel } from '../../../../../../../models'
import { getSystemEventValue, getUtcDateAndTime } from '../../../../utils'

/* Components */
import { Container } from './trendsLabelCard.styles'
import { LibraryDetailsHoverTooltip } from '../../../components/LibraryDetailsTooltip'
import { LabelEditIcon, LabelPinIcon } from '../../../../../../components/svg'

type Ref = HTMLDivElement | null

interface Props {
  width: number
  isCustomLabel: boolean
  label: VisibleLabel
  handlePin: (labelId: string) => void
  handleEdit: (label: Label) => void
  yPosition: number
  getCardColors: (action: SystemEventName) => {
    background: string
    border: string
    titleColor: string
    icon: JSX.Element
  }
}

const TrendsLabelCard = forwardRef<Ref, Props>(
  ({ getCardColors, width, isCustomLabel, label, handlePin, handleEdit, yPosition }, ref) => {
    const description = isCustomLabel ? label.data.description : label.description
    const title = label.data.title || ''
    const { background, border, titleColor, icon } = getCardColors(label.action)
    const [pinning, setPinning] = useState(false)

    const onPin = async (labelId: string) => {
      setPinning(true)

      try {
        await handlePin(labelId)
      } catch (e) {
        console.error(e)
      } finally {
        setPinning(false)
      }
    }

    return (
      <Container
        ref={ref}
        className="label-card"
        xPosition={label.x}
        yPosition={yPosition}
        containerWidth={width}
        isLastStripe={label.x >= width - 258}
        short={description.length > 25 ? 'false' : isCustomLabel && title.length > 19 ? 'false' : 'true'}
        sx={{
          background,
          border,
          alignItems: isCustomLabel ? 'center' : 'flex-start',

          '.label-card__header-text': {
            color: titleColor
          }
        }}
      >
        <div className="label-card__content">
          <div className="label-card__header">
            {icon}
            <p className="label-card__header-text">
              {isCustomLabel ? label.data.title : getSystemEventValue(label.action)}
            </p>
          </div>
          <p className="label-card__text">{isCustomLabel ? label.data.description : label.description}</p>
          <p className="label-card__subtext">
            {isCustomLabel
              ? dayjs(getUtcDateAndTime(label.data.date || '').labelFormatDate).format('MMM DD YYYY')
              : dayjs(getUtcDateAndTime(label.inserted_at).labelFormatDate).format('MMM DD YYYY')}
          </p>
        </div>
        <div className="label-card__actions">
          <LibraryDetailsHoverTooltip title="Unpin Label" arrow placement="bottom-end">
            <IconButton onClick={() => onPin(label.id)} className="action-icon-button">
              {pinning ? <CircularProgress size={16} color="secondary" /> : <LabelPinIcon />}
            </IconButton>
          </LibraryDetailsHoverTooltip>
          {isCustomLabel && (
            <LibraryDetailsHoverTooltip title="Edit Label" arrow placement="bottom-end">
              <IconButton onClick={() => handleEdit(label)} className="action-icon-button">
                <LabelEditIcon />
              </IconButton>
            </LibraryDetailsHoverTooltip>
          )}
        </div>
      </Container>
    )
  }
)

export default TrendsLabelCard
