const CustomLabelIcon = () => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.8475 4.38C12.5775 3.9975 12.1275 3.75 11.625 3.75L3.375 3.7575C2.55 3.7575 1.875 4.425 1.875 5.25V12.75C1.875 13.575 2.55 14.2425 3.375 14.2425L11.625 14.25C12.1275 14.25 12.5775 14.0025 12.8475 13.62L16.125 9L12.8475 4.38ZM11.625 12.75H3.375V5.25H11.625L14.2875 9L11.625 12.75Z"
        fill="#FFE082"
      />
    </svg>
  )
}

export default CustomLabelIcon
