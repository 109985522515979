import React from 'react'

const SearchWhiteIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      d="M16.2939 14.5786H15.3905L15.0703 14.2699C16.191 12.9663 16.8656 11.2739 16.8656 9.43282C16.8656 5.32762 13.538 2 9.43282 2C5.32762 2 2 5.32762 2 9.43282C2 13.538 5.32762 16.8656 9.43282 16.8656C11.2739 16.8656 12.9663 16.191 14.2699 15.0703L14.5786 15.3905V16.2939L20.2962 22L22 20.2962L16.2939 14.5786ZM9.43282 14.5786C6.58548 14.5786 4.28702 12.2802 4.28702 9.43282C4.28702 6.58548 6.58548 4.28702 9.43282 4.28702C12.2802 4.28702 14.5786 6.58548 14.5786 9.43282C14.5786 12.2802 12.2802 14.5786 9.43282 14.5786Z"
      fill="white"
    />
  </svg>
)


export default SearchWhiteIcon