import { Drawer as MuiDrawer } from '@mui/material'
import styled from '@emotion/styled/macro'

export const Drawer = styled(MuiDrawer)`
  .MuiPaper-root {
    background: #292128;
    min-width: 351px;
    width: 512px;
    border-left: 1px solid #4d444b;

    ::-webkit-scrollbar {
      display: none;
    }

    .wrapper-inner {
      width: 100%;

      .header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        padding: 24px;
        border-bottom: 1px solid #4d444b;

        .title {
          font-family: 'Quicksand', sans-serif;
          font-size: 24px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          color: #ffffff;
        }
      }

      .content {
        padding: 24px;
        overflow-y: scroll;
        height: calc(100vh - 89px);

        &::-webkit-scrollbar {
          width: 4px;
          background: transparent;
        }

        &::-webkit-scrollbar-track {
          box-shadow: none;
          background: #4d444b;
        }

        &::-webkit-scrollbar-thumb {
          background-color: #ffa9fc;
          border-radius: 16px;
        }

        .row-item {
          border-bottom: 1px solid #4d444b;
          padding-bottom: 24px;
          margin-bottom: 32px;

          .row-name {
            color: #fff;
            font-family: 'Quicksand', sans-serif;
            font-size: 16px;
            font-style: normal;
            font-weight: 510;
            line-height: normal;
            margin-bottom: 16px;
          }

          .row-data {
            display: flex;
            flex-direction: column;
            gap: 16px;

            .data-item {
              display: flex;
              align-items: center;
              gap: 8px;

              .name {
                color: #fff;
                font-family: 'Quicksand', sans-serif;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
              }
            }
          }
        }
      }
    }
  }
`
