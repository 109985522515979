import { FC, useState, useEffect, useMemo, Dispatch, SetStateAction } from 'react'
import { Box, Skeleton, Typography } from '@mui/material'

import { FrameworkCategoryCpi, FrameworkCategoryWeightItem, FrameworkType } from '../../../../models'

import {
  CPIsContainer,
  CPIsHeader,
  Content,
  CpisList,
  NothingFound,
  Divider,
  CpiListSummary
} from './styles/cpis.styles'
import CpiItem from './components/CpiItem'
import { ThemeButton } from '../../../components/buttons'
import { PlusIcon } from '../../../components/svg'
import TotalMustEqual from './components/TotalMustEqual'
import EvenWeightButton from './components/EvenWeightButton'
import { CategorySkeleton, CategorySkeletonWrapper } from './styles/categories.styles'

interface Props {
  handleAddCpis: () => void
  cpis: FrameworkCategoryCpi[]
  handleDeleteCpi: (cpiName: string) => void
  selectedCategoryItem: FrameworkCategoryWeightItem | undefined
  categoriesForm: any
  setCategoriesForm: any
  setSelectedCategoryItem: any
  evenWeights: (categoryId: string, type: 'categories' | 'cpis') => void
  hasEditRole: boolean
  initialLoad: boolean
  framework: FrameworkType
  setEdited: Dispatch<SetStateAction<boolean>>
  setDiscardDisabled: Dispatch<SetStateAction<boolean>>
}

const CPIs: FC<Props> = ({
  handleAddCpis,
  cpis,
  handleDeleteCpi,
  selectedCategoryItem,
  categoriesForm,
  setCategoriesForm,
  setSelectedCategoryItem,
  evenWeights,
  hasEditRole,
  initialLoad,
  framework,
  setEdited,
  setDiscardDisabled
}) => {
  const [theseCpis, setTheseCpis] = useState<any>(null)

  useEffect(() => {
    if (categoriesForm && categoriesForm.categories.length > 0 && selectedCategoryItem) {
      const cpiList = categoriesForm.categories.find((n: any) => n.id === selectedCategoryItem.id)
      setTheseCpis(cpiList || null)
    }
  }, [categoriesForm, selectedCategoryItem])

  const cpiWeightsTotal = useMemo(
    () =>
      (theseCpis &&
        theseCpis.cpis.length > 0 &&
        theseCpis.cpis.reduce((acc: number, obj: any) => acc + (obj.weight ? obj.weight : 0), 0)) ||
      0,
    [theseCpis]
  )
  const cpisWeightsAreValid = useMemo(() => {
    if (theseCpis && theseCpis.cpis && theseCpis.cpis.length > 0 && theseCpis.cpis.every((n: any) => !n.isActive))
      return true

    return !!(
      theseCpis &&
      theseCpis.cpis &&
      theseCpis.cpis.length > 0 &&
      theseCpis.cpis.reduce((acc: number, obj: any) => acc + (obj.weight ? obj.weight : 0), 0) === 100
    )
  }, [theseCpis])

  return (
    <CPIsContainer className="cpis-container">
      <CPIsHeader className="cpis-header">
        <Box className="cpis-header-wrapper">
          <Typography className="header-text">CPIs</Typography>
          {hasEditRole &&
            theseCpis &&
            theseCpis.cpis &&
            theseCpis.cpis.length > 0 &&
            framework === FrameworkType.Custom && (
              <>
                <ThemeButton
                  themevariant="tertiary"
                  sx={{ paddingRight: '16px !important' }}
                  onClick={() => {
                    if (selectedCategoryItem) {
                      handleAddCpis()
                    }
                  }}
                >
                  <>
                    <PlusIcon />
                    Add CPI
                  </>
                </ThemeButton>
              </>
            )}
        </Box>
      </CPIsHeader>
      <Content
        className="cpis-content"
        sx={{ alignItems: !initialLoad ? 'flex-start' : cpis.length > 0 ? 'flex-start' : 'center' }}
        haseditrole={hasEditRole ? 'true' : 'false'}
      >
        {!initialLoad ? (
          <CpisList>
            <Box className="categories-weight-header">
              <Typography>Cyber Performance Indicator</Typography>
              <Typography sx={{ mr: '66px !important' }}>Weight</Typography>
            </Box>
            <CategorySkeletonWrapper className="category-skeleton-wrapper">
              {Array.from(Array(4).keys()).map((key) => (
                <CategorySkeleton key={key}>
                  <Skeleton
                    variant="rectangular"
                    component="div"
                    animation="wave"
                    width="80%"
                    height="100%"
                    className="card-skeleton-inner"
                  />
                  <Skeleton
                    variant="rectangular"
                    component="div"
                    animation="wave"
                    width="20%"
                    height="100%"
                    className="card-skeleton-inner"
                  />
                </CategorySkeleton>
              ))}
            </CategorySkeletonWrapper>
          </CpisList>
        ) : cpis.length > 0 ? (
          <>
            <CpisList>
              <Box className="categories-weight-header">
                <Typography>Cyber Performance Indicator</Typography>
                <Typography sx={framework !== FrameworkType.Custom ? { mr: '26px !important' } : undefined}>
                  Weight
                </Typography>
              </Box>
              {theseCpis &&
                theseCpis.cpis &&
                (theseCpis as any).cpis.map((cpi: any, index: any) => (
                  <CpiItem
                    cpi={cpi}
                    key={index}
                    handleDeleteCpi={handleDeleteCpi}
                    categoriesForm={categoriesForm}
                    setCategoriesForm={setCategoriesForm}
                    selectedCategoryItem={selectedCategoryItem}
                    setSelectedCategoryItem={setSelectedCategoryItem}
                    hasEditRole={hasEditRole}
                    framework={framework}
                    setEdited={setEdited}
                    setDiscardDisabled={setDiscardDisabled}
                  />
                ))}
              {theseCpis?.cpis.some((n: any) => n.isActive) && (
                <>
                  <Divider />
                  <CpiListSummary
                    iserrored={cpiWeightsTotal !== 100 ? 'true' : 'false'}
                    haseditrole={hasEditRole ? 'true' : 'false'}
                  >
                    {hasEditRole && (
                      <Box className="summary-start">
                        <TotalMustEqual type={cpisWeightsAreValid ? 'info' : 'error'} />
                        <EvenWeightButton onClick={() => evenWeights(selectedCategoryItem?.id || '', 'cpis')} />
                      </Box>
                    )}
                    <Box className="summary-end">
                      <Typography className="total-text">Total:</Typography>
                      <Typography className="total-value">{cpiWeightsTotal}%</Typography>
                    </Box>
                  </CpiListSummary>
                </>
              )}
            </CpisList>
          </>
        ) : (
          <NothingFound className="nothing-found-wrapper">
            <img src="/framework-empty-cpis.svg" alt="no-cpis-found" />
            <Typography className="nothing-found-title">No CPIs Selected</Typography>

            {hasEditRole ? (
              <Typography className="nothing-found-text">
                Select the CPIs you’d like to add{' '}
                {selectedCategoryItem && `to the ${selectedCategoryItem?.name || ''} category`}
              </Typography>
            ) : (
              <Typography className="nothing-found-text">
                No CPIs added yet {selectedCategoryItem && `to "${selectedCategoryItem?.name || ''}"`}
              </Typography>
            )}
            {hasEditRole && (
              <>
                <ThemeButton
                  themevariant="tertiary"
                  onClick={() => {
                    if (selectedCategoryItem) {
                      handleAddCpis()
                    }
                  }}
                >
                  <>
                    <PlusIcon />
                    Add CPIs
                  </>
                </ThemeButton>
              </>
            )}
          </NothingFound>
        )}
      </Content>
    </CPIsContainer>
  )
}

export default CPIs
