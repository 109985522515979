import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../store'
import { AccountSetting } from '../../models'

export interface UserState {
  token: string | null
  account: AccountSetting | null
}

const initialState: UserState = {
  token: null,
  account: null
}

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setToken: (state, action: PayloadAction<string | null>) => {
      state.token = action.payload
    },
    setAccountSettings: (state, action: PayloadAction<AccountSetting>) => {
      state.account = action.payload
    }
  }
})

export const { setToken, setAccountSettings } = userSlice.actions

export const userTokenSelector = (state: RootState): string | null => state.user.token

export const accountSettingsSelector = (state: RootState): AccountSetting | null => state.user.account

export default userSlice.reducer
