import { Dispatch, FC, SetStateAction } from 'react'
import { SearchBarWrapper } from './SelectDataSearch.styles'
import { Box, IconButton, TextField } from '@mui/material'
import SearchWhiteIcon from '../../../components/svg/SearchWhiteIcon'
import ClearSvg from '../../../components/svg/ClearSvg'

interface Props {
  search: string
  setSearch: Dispatch<SetStateAction<string>>
}

const SelectDataSearch: FC<Props> = ({ search, setSearch }) => {
  const inputHandler = (e: any) => {
    e.preventDefault()
    e.stopPropagation()

    setSearch(e.target.value)
  }

  return (
    <SearchBarWrapper val={search}>
      <Box
        className="search-icon"
        sx={{
          width: search.length < 1 ? 'fit-content' : 0,

          svg: {
            width: search.length < 1 ? '24px' : 0
          }
        }}
      >
        <SearchWhiteIcon />
      </Box>

      <TextField
        className="search-bar"
        value={search}
        placeholder="Search..."
        onChange={(e) => inputHandler(e)}
        autoFocus={true}
      />

      <IconButton
        className="clear-btn"
        onClick={() => setSearch('')}
        sx={{
          width: search.length > 1 ? 'fit-content' : 0,

          svg: {
            width: search.length > 1 ? '24px' : 0
          }
        }}
      >
        <ClearSvg />
      </IconButton>
    </SearchBarWrapper>
  )
}

export default SelectDataSearch
