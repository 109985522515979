import { FC } from 'react'
import { useField } from 'formik'
import { FormControlLabel, Radio, RadioGroup, RadioGroupProps } from '@mui/material'

export interface RadioGroupOption {
  name: string
  label: string | JSX.Element
  value: string
}

type Props = RadioGroupProps & {
  name: string
  options: RadioGroupOption[]
}

const ControlledRadioGroup: FC<Props> = ({ name, options, ...rest }) => {
  const [field] = useField(name)

  return (
    <RadioGroup {...field} name={name} {...rest}>
      {options.map((n: any, index: number) => (
        <FormControlLabel value={n.value} control={<Radio />} label={n.label} key={index} />
      ))}
    </RadioGroup>
  )
}

export default ControlledRadioGroup
