import { FC } from 'react'

/* Utils */
import { IntegrationInstance } from '../../../../models'

/* Components */
import EnvironmentStats from './EnvironmentStats'
import StubPerformanceScore from './DashboardLoading/components/StubPerformanceScore'
import OverallPerformanceScore from './OverallPerformanceScore/OverallPerformanceScore'

interface Props {
  performanceScore: number
  configuredIntegrations: IntegrationInstance[]
  activeUsers: number
  availableCpis: string[]
  cpisActivated: number
}

const DashboardHeroEmpty: FC<Props> = ({
  performanceScore,
  configuredIntegrations,
  activeUsers,
  availableCpis,
  cpisActivated
}) => (
  <>
    <OverallPerformanceScore performanceScore={performanceScore} />
    <StubPerformanceScore />
    <EnvironmentStats
      cpisActivated={cpisActivated}
      dataSources={configuredIntegrations.length}
      activeUsers={activeUsers}
      availableCPIs={availableCpis.length}
    />
  </>
)

export default DashboardHeroEmpty
