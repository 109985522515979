import React, { Dispatch, FC, SetStateAction, useEffect, useState } from 'react'
import { Box, CircularProgress, IconButton, Typography } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import dayjs from 'dayjs'

/* Utils */
import {
  CPIFrequency,
  CPITimePeriod,
  CustomLabelCountI,
  CustomLabelDate,
  CustomLabelI,
  CustomLabelsListI,
  DateRangeI
} from '../../../../../../../models'
import { dateFormat } from '../../../../../../../lib/utils'
import { getLabelsDateRange } from '../../../../utils'

/* Components */
import { Drawer } from './LabelsDrawer.styles'
import BackSvg from '../../../../../../components/svg/labels/BackSvg'
import LabelsShowMode from './components/LabelsShowMode'
import CreateLabels from './components/CreateLabels'
import EditLabel from './components/EditLabel'
import FilterLabels from '../components/components/FilterLabels'

interface Props {
  chartDateRange: DateRangeI
  redirectToMode: string
  editedLabel: any
  loading: boolean
  drawerOpen: boolean
  appliedFilter: any
  cpiName: string | undefined
  cpiConfId: string | undefined
  customDate: CustomLabelDate
  mode: string
  setDrawerOpen: Dispatch<SetStateAction<boolean>>
  setLoading: Dispatch<SetStateAction<boolean>>
  setMode: Dispatch<SetStateAction<string>>
  customLabels: CustomLabelsListI
  selectedTimePeriod: CPITimePeriod
  selectedFrequency: CPIFrequency
  customLabelsCount: CustomLabelCountI
  updateLabels: () => void
  filterLabelsData: (filters: any) => void
}

const LabelsDrawer: FC<Props> = ({
  chartDateRange,
  redirectToMode,
  drawerOpen,
  appliedFilter,
  setLoading,
  setDrawerOpen,
  customDate,
  cpiName,
  cpiConfId,
  customLabels,
  customLabelsCount,
  updateLabels,
  selectedTimePeriod,
  selectedFrequency,
  setMode,
  mode,
  loading,
  filterLabelsData,
  editedLabel
}) => {
  const [editLabel, setEditLabel] = useState({} as CustomLabelI)
  const [dateRange, setDateRange] = useState({} as DateRangeI)

  useEffect(() => {
    if (editedLabel) setEditLabel(editedLabel)
  }, [editedLabel])

  useEffect(() => {
    if (['show-0', 'show-1'].includes(mode)) {
      setDateRange(chartDateRange)
    }
  }, [mode, chartDateRange])

  useEffect(() => {
    if (customDate.start && customDate.end) {
      setDateRange({
        minDate: customDate.start,
        maxDate: customDate.end
      })
    } else if (customDate.start && !customDate.end) {
      setDateRange({
        minDate: customDate.start,
        maxDate: customDate.start
      })
    } else {
      const range = getLabelsDateRange({ selectedTimePeriod, selectedFrequency })
      setDateRange(range)
    }
  }, [drawerOpen, selectedTimePeriod, selectedFrequency, customDate])

  const changeMode = (newMode: string) => {
    setMode(newMode)
  }

  const updateEditLabel = (label: CustomLabelI) => {
    setEditLabel(label)
  }

  const drawerTitle = () => {
    if (mode === 'create') return 'Create Label'
    if (mode === 'edit') return 'Edit Label'
    if (mode === 'filter') return 'Filter Labels'

    return 'Labels'
  }

  return (
    <Drawer anchor="right" open={drawerOpen} onClose={() => setDrawerOpen(false)}>
      <Box sx={{ width: 320 }} role="presentation">
        <Box className="wrapper-inner">
          {loading && (
            <Box className="loading-wrapper">
              <Box display="flex" justifyContent="center" style={{ marginTop: '15px' }}>
                <CircularProgress size="36px" color="primary" />
              </Box>
            </Box>
          )}

          <Box className="header-block">
            <Box className={`header ${mode === 'filter' ? 'custom-header' : ''}`}>
              {mode === 'filter' && (
                <IconButton className="close-icon" onClick={() => setMode(redirectToMode || 'show-0')}>
                  <BackSvg />
                </IconButton>
              )}

              <Typography className="title">{drawerTitle()}</Typography>

              {['edit', 'create'].includes(mode) ? (
                <IconButton className="close-icon" onClick={() => setMode('show-0')}>
                  <CloseIcon />
                </IconButton>
              ) : (
                mode !== 'filter' && (
                  <IconButton className="close-icon" onClick={() => setDrawerOpen(false)}>
                    <CloseIcon />
                  </IconButton>
                )
              )}
            </Box>

            {!mode?.includes('show') && mode !== 'filter' && (
              <Box>
                <Typography className="dates">
                  {dateRange.maxDate === '' ? (
                    dateRange.minDate
                  ) : dateRange.minDate === dateRange.maxDate ? (
                    dayjs(dateRange.minDate).format(dateFormat.shortUnComma)
                  ) : (
                    <>
                      {dayjs(dateRange.minDate).format(dateFormat.shortUnComma)} -{' '}
                      {dayjs(dateRange.maxDate).format(dateFormat.shortUnComma)}
                    </>
                  )}
                </Typography>
              </Box>
            )}
          </Box>

          <Box className="content">
            {mode?.includes('show') && (
              <LabelsShowMode
                mode={mode}
                appliedFilter={appliedFilter}
                dateRange={dateRange}
                setLoading={setLoading}
                loading={loading}
                updateMode={changeMode}
                customLabels={customLabels}
                customLabelsCount={customLabelsCount}
                updateEditLabel={updateEditLabel}
                updateLabels={updateLabels}
                customDate={customDate}
              />
            )}
            {mode === 'create' && (
              <CreateLabels
                redirectToMode={redirectToMode}
                setLoading={setLoading}
                cpiName={cpiName}
                customDate={customDate}
                cpiConfId={cpiConfId}
                dateRange={dateRange}
                updateMode={changeMode}
                updateLabels={updateLabels}
              />
            )}
            {mode === 'edit' && Object.keys(editLabel).length && (
              <EditLabel
                setLoading={setLoading}
                editLabel={editLabel}
                dateRange={dateRange}
                updateMode={changeMode}
                updateLabels={updateLabels}
              />
            )}
            {mode === 'filter' && (
              <FilterLabels
                appliedFilter={appliedFilter}
                updateLabels={updateLabels}
                updateMode={changeMode}
                dateRange={dateRange}
                filterLabelsData={filterLabelsData}
                redirectToMode={redirectToMode}
              />
            )}
          </Box>
        </Box>
      </Box>
    </Drawer>
  )
}

export default LabelsDrawer
