import { ReactNode } from 'react'
import { DrawerTabPanelWrapper } from './drawerTabs.styles'

interface TabPanelProps {
  children?: ReactNode
  index: number
  value: number
}

export function a11yProps(index: number) {
  return {
    id: `library-details-tab-${index}`,
    'aria-controls': `library-details-tabpanel-${index}`
  }
}

export default function DrawerTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <DrawerTabPanelWrapper
      role="tabpanel"
      hidden={value !== index}
      id={`framework-drawer-tabpanel-${index}`}
      aria-labelledby={`framework-drawer-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </DrawerTabPanelWrapper>
  )
}
