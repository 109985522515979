import { Box } from '@mui/material'
import styled from '@emotion/styled/macro'

export const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 24px;
  border-radius: 8px;
  background: #372a36;
  width: 420px;
  padding: 16px;
  border: 1px solid #4d444b;

  .close-popper-icon {
    padding: 0;
  }

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0;
    border: none;
    height: auto;

    svg {
      path {
        fill: #fff;
      }
    }

    .header-text {
      color: #fff;
      white-space: nowrap;
      font-family: Quicksand, sans-serif;
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
  }

  .inputs-container {
    display: flex;
    flex-direction: column;
    gap: 16px;

    .form-control {
      gap: 2px;
      width: 100%;

      .MuiOutlinedInput-root {
        height: 40px;
        overflow: hidden;
        color: #fff;
        text-overflow: ellipsis;
        font-family: Quicksand, sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        border-radius: 4px;
        border: 1px solid #998d96;
        padding-right: 0;

        & .MuiOutlinedInput-input {
          padding: 0 8px;
          overflow: hidden;
          color: #fff;
          text-overflow: ellipsis;
          font-family: Quicksand, sans-serif;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;

          &::placeholder {
            overflow: hidden;
            color: #d9bfd4;
            text-overflow: ellipsis;
            font-family: Quicksand, sans-serif;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            opacity: 1;
          }
        }

        & .end-adornment-close-icon {
          color: #fff;
          padding: 4px;
          margin-right: 4px;

          &:hover {
            background: rgba(255, 255, 255, 0.05);
          }
        }

        &.Mui-focused > fieldset {
          border-color: #998d96;
          border-width: 1px;
        }

        &:hover {
          border-color: #998d96;
        }
      }

      .MuiTypography-root {
        overflow: hidden;
        color: #fff;
        text-overflow: ellipsis;
        font-family: Quicksand, sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;

        &.Mui-focused {
          color: #fff !important;
        }
      }
    }
  }

  .divider-actions {
    background: #4d444b;
    height: 1px;
    width: 100%;
  }

  .action-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 16px;
  }
`
