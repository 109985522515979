import { FC } from 'react'
import { Box, Typography } from '@mui/material'
import styled from '@emotion/styled/macro'
import { OverPerformIcon } from '../../../../components/svg'

const TooltipWrapper = styled(Box)<{ x: number; y: number; height: number }>`
  position: fixed;
  top: ${({ y }) => y + 10}px;
  left: ${({ x }) => x + 10}px;
  color: #fff;
  padding: 9px 11px;
  z-index: 9999;
  border-radius: 8px;
  background: #4d444b;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.3), 0 8px 12px 6px rgba(0, 0, 0, 0.15);
  width: 200px;
  height: ${({ height }) => `${height}px`};

  .value {
    color: #fff;
    font-family: Quicksand, sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px;
  }

  .category {
    font-family: Quicksand, sans-serif;
    color: #fff;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    letter-spacing: 0.4px;
    margin-top: 3px;
    line-height: 16px;

    &.solo {
      margin-top: 18px;
    }
  }

  .overperforming-wrapper {
    display: flex;
    align-items: center;
    gap: 4px;
    margin-top: 2px;

    svg {
      width: 15px;
    }

    .overperforming-text {
      color: #fff;
      font-family: Quicksand, sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0.5px;

      span.percent {
        color: #fff;
        font-family: Quicksand, sans-serif;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
        letter-spacing: 0.4px;
      }

      span.text {
        color: #fff;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
        letter-spacing: 0.5px;
      }
    }
  }
`

export interface PerformanceScoreTooltipContent {
  category: string
  score: number
}

export interface PerformanceScoreTooltipProps {
  x: number
  y: number
  content: PerformanceScoreTooltipContent | null
}

const PerformanceScoreTooltip: FC<PerformanceScoreTooltipProps> = ({ x, y, content }) => {
  const titleLength = content && content.category ? content.category.length : 0
  const baseHeight = 68
  const rowHeight = 20

  const getHeight = () => {
    if (content && content.score) {
      if (content.score > 100) {
        if (titleLength > 25) {
          return baseHeight + rowHeight * 2
        } else {
          return baseHeight + rowHeight + 5
        }
      } else {
        if (titleLength > 25) {
          return baseHeight + rowHeight
        } else {
          return baseHeight
        }
      }
    }

    return baseHeight
  }

  return (
    <TooltipWrapper id="tooltip-pie" x={x} y={y} height={getHeight()}>
      {content && (
        <>
          {content.score > 0 && <Typography className="value">{Math.round(content.score)}%</Typography>}
          <Typography className={`category ${content.score === 0 ? 'solo' : ''}`}>{content.category}</Typography>
          {content.score > 100 && (
            <Box className="overperforming-wrapper">
              <OverPerformIcon />
              <Typography className="overperforming-text">
                {content.score - 100}
                <Typography component="span" className="percent">
                  %
                </Typography>{' '}
                <Typography component="span" className="text">
                  Over Performing
                </Typography>
              </Typography>
            </Box>
          )}
        </>
      )}
    </TooltipWrapper>
  )
}

export default PerformanceScoreTooltip
