import { Box } from '@mui/material'
import styled from '@emotion/styled/macro'

export const Container = styled(Box)`
  width: 100%;
`

export const Wrapper = styled(Box)`
  padding-left: 24px;
  padding-right: 24px;
`
