import { Box, Drawer as MuiDrawer } from '@mui/material'
import styled from '@emotion/styled/macro'

export const Drawer = styled(MuiDrawer)`
  > .MuiPaper-root {
    width: 33.33%;
    border-left: 1px solid #4d444b;
    background: #292128;
    overflow: hidden;
  }
`

export const Wrapper = styled(Box)`
  height: 100%;

  .header {
    height: 100px;
    border-bottom: 1px solid #4d444b;
    padding: 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .MuiTypography-root {
      color: #fff;
      font-family: Quicksand, sans-serif;
      font-size: 24px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }

    .close-icon {
      width: 40px;
      height: 40px;
      color: #ffa9fc;

      &:hover {
        background: rgba(255, 255, 255, 0.05);
      }
    }
  }

  .sub-header {
    padding: 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .add-button {
      height: 40px;
      color: #590060;
      text-align: center;
      font-family: Quicksand, sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 510;
      line-height: normal;
      gap: 8px;
      padding: 8px 24px 8px 16px;

      svg {
        width: 24px;
        height: 24px;
      }
    }

    .disabled-add-button {
      padding: 10px 22px 10px 14px;
      min-width: 91px;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 8px;
      border-radius: 100px;
      background: #483646;
      cursor: default;
      color: #7f747c;
      text-align: center;
      font-family: Quicksand, sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 510;
      line-height: normal;
      white-space: nowrap;

      svg {
        width: 24px;
        height: 24px;

        path {
          fill: #7f747c;
        }
      }
    }

    .items-wrapper {
      display: flex;
      align-items: center;
      gap: 8px;

      .items-text {
        color: #867083;
        text-align: center;
        font-family: Quicksand, sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }

      .more-icon {
        color: #ffa9fc;

        &:hover {
          background: rgba(255, 255, 255, 0.05);
        }
      }
    }
  }
`

export const Content = styled(Box)`
  display: flex;
  padding: 24px;
  flex-direction: column;
  gap: 10px;
  height: calc(100vh - 188px);
  overflow-y: auto;

  .empty-box-wrapper {
    border: 1px solid #4d444b;
    gap: 32px;
    padding: 24px;

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 100%;

    .text-content {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      gap: 8px;

      .empty-box-header {
        color: #fff;
        font-family: Quicksand, sans-serif;
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }

      .empty-box-text {
        color: #fff;
        font-family: Quicksand, sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;

        span {
          color: #ffa9fc;
          font-family: Quicksand, sans-serif;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          cursor: pointer;
        }
      }
    }
  }

  ::-webkit-scrollbar {
    background: #4d444b;
    width: 12px !important;
    border: solid 6px transparent;
    border-radius: 8px;
  }

  ::-webkit-scrollbar-track {
    box-shadow: none;
    border: solid 6px #4d444b;
    background: #fff;
  }

  ::-webkit-scrollbar-thumb {
    border: 4px solid #4d444b;
    background-clip: padding-box;
    border-radius: 9999px;
    background-color: #ffa9fc;
  }
`
