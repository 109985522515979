import React from 'react'

const ComingSoonSvg = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <path
      d="M11.9997 7.33366C11.9997 7.78033 11.9997 8.22032 11.9997 8.66699C12.7997 8.66699 13.8397 8.66699 14.6663 8.66699C14.6663 8.22032 14.6663 7.78033 14.6663 7.33366C13.8397 7.33366 12.7997 7.33366 11.9997 7.33366Z"
      fill="white"
    />
    <path
      d="M10.6663 11.7403C11.3063 12.2137 12.1397 12.8403 12.7997 13.3337C13.0663 12.9803 13.333 12.6203 13.5997 12.267C12.9397 11.7737 12.1063 11.147 11.4663 10.667C11.1997 11.027 10.933 11.387 10.6663 11.7403Z"
      fill="white"
    />
    <path
      d="M13.5997 3.73366C13.333 3.38033 13.0663 3.02033 12.7997 2.66699C12.1397 3.16033 11.3063 3.78699 10.6663 4.26699C10.933 4.62033 11.1997 4.98033 11.4663 5.33366C12.1063 4.85366 12.9397 4.23366 13.5997 3.73366Z"
      fill="white"
    />
    <path
      d="M2.66634 6.00033C1.93301 6.00033 1.33301 6.60033 1.33301 7.33366V8.66699C1.33301 9.40033 1.93301 10.0003 2.66634 10.0003H3.33301V12.667H4.66634V10.0003H5.33301L8.66634 12.0003V4.00033L5.33301 6.00033H2.66634ZM6.01967 7.14033L7.33301 6.35366V9.64699L6.01967 8.86033L5.69967 8.66699H2.66634V7.33366H5.69967L6.01967 7.14033Z"
      fill="white"
    />
    <path
      d="M10.333 8.00033C10.333 7.11366 9.94634 6.31366 9.33301 5.76699V10.227C9.94634 9.68699 10.333 8.88699 10.333 8.00033Z"
      fill="white"
    />
  </svg>
)

export default ComingSoonSvg