import { Box } from '@mui/material'
import styled from '@emotion/styled/macro'

export const SSMContainer = styled(Box)`
  background: #211920;
  min-height: 100vh;
`

export const SSMHeaderContainer = styled(Box)`
  width: 100%;
  min-height: 104px;
  border-bottom: 1px solid #4d444b;
  background: radial-gradient(155.07% 155.07% at 50% 100%, rgba(255, 169, 252, 0.15) 0%, rgba(255, 169, 252, 0) 66.2%),
    #1e1a1d;
  padding: 15px 80px 11px 16px;
  display: flex;
  align-items: center;
  justify-content: center;

  .header-wrapper {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    width: 100%;
    max-width: 1752px;

    .header-main-wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .title-wrapper {
        display: flex;
        align-items: center;

        .header-title {
          color: #fff;
          font-family: Quicksand, sans-serif;
          font-size: 32px;
          font-style: normal;
          font-weight: 400;
          line-height: 40px;
        }
      }
    }

    .breadcrumbs-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      margin-bottom: 26px;
      max-width: 1920px;
    }
  }
`

export const SSMContentContainer = styled(Box)`
  padding: 40px 80px;
  width: 100%;
  display: flex;
  justify-content: center;

  @media (max-width: 1280px) {
    padding: 32px 24px 26px 28px;
  }
`

export const SSMGrid = styled(Box)`
  display: grid;
  grid-template-columns: 141px 1fr 1fr 1fr 1fr 1fr;
  grid-template-rows: 132px 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-column-gap: 2px;
  grid-row-gap: 2px;
  position: relative;
  max-width: 1686px;

  @media (min-width: 1440px) {
    grid-template-columns: 141px 1fr 1fr 1fr 1fr 1fr;
    grid-template-rows: 142px 1fr 1fr 1fr 1fr 1fr 1fr;
  }

  @media (min-width: 1920px) {
    grid-template-columns: 141px 1fr 1fr 1fr 1fr 1fr;
    grid-template-rows: 181px 1fr 1fr 1fr 1fr 1fr 1fr;
  }
`

export const SSMNistPillarWrapper = styled(Box)<{ index: number }>`
  border-radius: 16px 0 0 16px;
  border: 0.5px solid #998d96;
  background: #8e9aff;
  display: flex;
  align-items: center;
  justify-self: end;
  align-self: center;
  width: 141px;
  height: 36px;
  padding: 6px 0 6px 24px;
  margin-right: 1px;

  &:first-of-type {
    margin-top: 36px;
  }

  ${({ index }) =>
    index === 1 &&
    `
      background: #C77EDA;
  `};
  ${({ index }) =>
    index === 2 &&
    `
      background: #FFDE6A;
  `};
  ${({ index }) =>
    index === 3 &&
    `
      background: #FF7F6B;
  `};
  ${({ index }) =>
    index === 4 &&
    `
      background: #99FDC0;
  `};
  ${({ index }) =>
    index === 5 &&
    `
      background: #FF9E47;
  `};

  .MuiTypography-root {
    color: #000;
    text-align: center;
    font-family: Quicksand, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.1px;
    margin-left: 8px;
  }

  &.vendor-details-nist {
    border-radius: 16px;
    min-width: 120px;
    height: 32px;

    &:first-of-type {
      margin-top: 0;
    }
  }
`

export const SSMGridHeader = styled(Box)`
  background: #483646;
  border-radius: 12px 12px 0 0;
  border: 0.365px solid #998d96;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px 0;
  height: 44px;
  width: 205px;
  margin-bottom: 2px;

  @media (min-width: 1440px) {
    width: 230px;
  }

  @media (min-width: 1920px) {
    width: 310px;
    height: 61px;
  }

  .MuiTypography-root {
    color: #e9e0e4;
    text-align: center;
    font-family: Quicksand, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.1px;
  }
`

export const SSMCardContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 205px;
  cursor: pointer;

  @media (min-width: 1440px) {
    width: 230px;
  }

  @media (min-width: 1920px) {
    width: 310px;
  }

  .add-vendor-button {
    visibility: hidden;
    position: absolute;
    bottom: -10px;
    right: 0;

    .add-vendor-icon {
      position: absolute;
      bottom: 0;
      right: 0;
    }
  }

  &:hover {
    .add-vendor-button {
      visibility: visible;
    }
  }
`

export const SSMCardInner = styled(Box)<{ itemslength?: number; isediting?: string; isCurrentEdit?: boolean }>`
  border-radius: 1.098px;
  border: 0.272151px solid #998d96;
  background: #362e35;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-column-gap: 24px;
  grid-row-gap: 12px;
  width: 205px;
  height: 86px;
  position: relative;
  padding: 7px 26px;
  cursor: pointer;

  @media (min-width: 1440px) {
    width: 230px;
    height: 96px;
    padding: 12px 45px;
  }

  @media (min-width: 1920px) {
    width: 310px;
    height: 120px;
  }

  ${({ isCurrentEdit }) =>
    isCurrentEdit &&
    `
     background: #573F56;
    `};

  ${({ isediting }) =>
    isediting &&
    isediting === 'true' &&
    `
      &:hover {
        border-radius: 2px;
        border: 1px solid #DEBDD9;
        background: #573F56;
        box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.30), 0 8px 12px 6px rgba(0, 0, 0, 0.15);
        cursor: pointer;
      }
    `};

  ${({ itemslength }) =>
    itemslength &&
    itemslength > 3 &&
    `
    &:hover {
      cursor: default;
    }
  `};

  .text {
    color: #e9e0e4;
    text-align: center;
    font-family: Quicksand, sans-serif;
    font-size: 11px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0.5px;
  }
`

export const SSMCardIntegration = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
`

export const IntegrationLogoWrapper = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  .cancel-icon-button {
    position: absolute;
    top: -14px;
    right: -20px;
    color: #ff8a80;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.3)) drop-shadow(0px 8px 12px rgba(0, 0, 0, 0.15));

    &:hover {
      cursor: pointer;
    }
  }
`

export const IntegrationLogo = styled(Box)<{ logo: string }>`
  border: 1px solid #342f32;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.3), 0 1px 3px 1px rgba(0, 0, 0, 0.15);
  width: 24px;
  height: 24px;
  border-radius: 24px;
  background: ${({ logo }) => `url("${logo}") no-repeat center`};
  background-size: cover;
  cursor: pointer;
  margin-right: 8px;
`

export const NoIntegrationLogo = styled(Box)`
  border: 1px solid #342f32;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.3), 0 1px 3px 1px rgba(0, 0, 0, 0.15);
  width: 41px;
  height: 41px;
  border-radius: 50%;
  background: #d0c3cc;
  background-size: cover;
  margin-left: 4px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 1280px) {
    width: 22px;
    height: 22px;
  }

  @media (max-width: 1600px) and (max-height: 1079px) {
    width: 22px;
    height: 22px;
  }

  .no-integration-logo-letter {
    color: #342f32;
    text-align: center;
    font-family: Quicksand, sans-serif;
    font-size: 11px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0.5px;
  }
`

export const EmptyStateWrapper = styled(Box)`
  width: calc(100% - 205px);
  right: 32px;
  top: 66px;
  position: absolute;
  display: flex;
  align-items: center;
  gap: 16px;
  background: #4d444b;
  padding: 8px 16px;
  height: 72px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.3), 0 2px 6px 2px rgba(0, 0, 0, 0.15);

  @media (max-width: 1920px) {
    top: 67px;
  }

  @media (max-width: 1280px) {
    top: 46px;
  }

  .icon-wrapper {
    display: flex;
    align-items: center;
  }

  .empty-state-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 4px;

    .empty-text-title {
      color: #e9e0e4;
      font-family: Quicksand, sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: 0.1px;
    }

    .empty-text-description {
      color: #e9e0e4;
      font-family: Quicksand, sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0.25px;

      .MuiLink-root {
        color: inherit;
        text-decoration-line: underline;
        cursor: pointer;
      }
    }
  }
`
