import { useEffect } from 'react'
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'
import { loadRssArticles } from './rss/actions'
import type { RootState, AppDispatch } from './store'

export const useAppDispatch = () => useDispatch<AppDispatch>()
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector

export const useArticles = () => {
  const dispatch = useAppDispatch()
  const articles = useAppSelector((state) => state.rss.articles)

  useEffect(() => {
    if (!articles) {
      dispatch(loadRssArticles())
    }

    const interval = setInterval(() => {
      dispatch(loadRssArticles())
    }, 5000)

    return () => {
      clearInterval(interval)
    }
  }, [dispatch, loadRssArticles])

  return articles
}
