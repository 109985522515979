import { FC } from 'react'
import { Box } from '@mui/material'

/* Components */
import StubPerformanceScore from './components/StubPerformanceScore'
import EnvironmentStats from '../EnvironmentStats'
import StubCPIsSection from './components/StubCPIsSection'
import StubFeedSection from './components/StubFeedSection'
import {
  DashboardContainer,
  DashboardWrapper,
  HeroSectionWrapper,
  Main,
  MainScrollSection
} from '../../dashboard.styles'
import StubBenchmarking from './components/StubBenchmarking'
import StubOverPerformance from './components/StubOverPerformance'
import HeaderComponent from '../../../../components/header/HeaderComponent'
import { breadcrumbs } from '../../../../../lib/utils'

interface Props {
  feedHeight: number
}

const DashboardLoading: FC<Props> = ({ feedHeight }) => {
  return (
    <DashboardContainer>
      <HeaderComponent breadcrumbs={breadcrumbs.dashboard} type={'dashboard'} />

      <Main>
        <DashboardWrapper>
          <HeroSectionWrapper>
            <StubOverPerformance />
            <StubPerformanceScore />
            <EnvironmentStats cpisActivated={0} dataSources={0} activeUsers={0} availableCPIs={0} isstub="true" />
          </HeroSectionWrapper>

          <Box className="main-content-container">
            <MainScrollSection>
              <StubCPIsSection />
            </MainScrollSection>

            <Box className="second-breakpoint-feed-wrapper">
              <StubBenchmarking />
              <StubFeedSection feedHeight={feedHeight} />
            </Box>
          </Box>
        </DashboardWrapper>
      </Main>
    </DashboardContainer>
  )
}

export default DashboardLoading
