export interface CustomLabelsListI {
  [key: string]: CustomLabelI[]
}

export interface CustomLabelI {
  action: SystemEventName
  created_at: string
  description: string | null
  email: string
  id: string
  inserted_at: string
  role: string
  tenant_id: string
  username: string
  vendor_id: string
  data: CustomLabelDataI

  map(arg0: (label: CustomLabelI) => JSX.Element): import('react').ReactNode
}

export interface CustomLabelDataI {
  cpi_configuration_id?: string
  cpi_id: string
  cpi_title: string
  description: string
  title: string
  deleted: boolean
  date: string
}

export interface CustomLabelCountI {
  pinned_labels: CustomLabelI[]
  labels_counts: LabelsCountI[]
}

export interface LabelsCountI {
  count: number
  date: string
}

export interface DateRangeI {
  minDate: string
  maxDate: string
}

export interface Label {
  id: string
  tenant_id: string
  vendor_id: string
  username: string
  role: string
  email: string
  action: SystemEventName
  description: string
  data: {
    title?: string
    date?: string
    cpi_id: string
    cpi_title: string
    cpi_source?: {
      id: string
      name: string
    }
    cpi_sla: any
    deleted: boolean
    description: string
  }
  created_at: string // The date where label is shown on Trends Chart
  inserted_at: string

  map(arg0: (label: CustomLabelI) => JSX.Element): import('react').ReactNode
}

export interface VisibleLabel extends Label {
  x: number
  isShortLabel: boolean
}

export interface LabelData {
  [key: string]: Label[]
}

export interface LabelCount {
  date: string
  count: number
}

export interface LabelCountData {
  labels_counts: LabelCount[]
  pinned_labels: Label[]
}

export enum SystemEventName {
  CpiActivated = 'io_onyxia_events_cpi_activated',
  CpiInstanceAdded = 'io_onyxia_events_cpi_instance_added',
  CpiInstanceRemoved = 'io_onyxia_events_cpi_instance_removed',
  SlaChangedEvent = 'io_onyxia_events_cpi_sla_changed',
  CpiCustom = 'io_onyxia_events_cpi_custom'
}

export interface CustomLabelDate {
  date: string
  start: string
  end: string
}
