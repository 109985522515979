import { FC, SyntheticEvent } from 'react'
import { HeaderContainer } from './tabsHeader.styles'
import { Tab } from '@mui/material'
import { useLocation } from 'react-router-dom'
import { IntegrationsTabs } from '../../pages/settings/integrations/integrations.styles'
import { a11yProps } from '../../pages/library/library-details/components/LibraryDetailsTabPanel'
import { LibraryDetailsHoverTooltip } from '../../pages/library/library-details/components/LibraryDetailsTooltip'

interface Props {
  tabValue: number
  handleTabChange: (event: SyntheticEvent, newValue: number) => void
  title: string
  tabLabels: string[]
  parents?: string[]
  hideIntegrationsTabs?: boolean
  loading?: boolean
  sx?: Record<string, unknown>
}

const TabsHeader: FC<Props> = ({ tabValue, handleTabChange, title, loading, sx, tabLabels, hideIntegrationsTabs }) => {
  const location = useLocation()

  return (
    <HeaderContainer sx={sx}>
      {!hideIntegrationsTabs && (
        <IntegrationsTabs
          value={tabValue}
          onChange={handleTabChange}
          aria-label={`${title} tabs`}
          TabIndicatorProps={{
            style: { display: 'none' }
          }}
          isdetails="true"
        >
          {tabLabels.map((label, index) => {
            if (label === 'Data Sources' && location.pathname === '/asset-coverage-analyzer') {
              return (
                <LibraryDetailsHoverTooltip key={index} title="Enabled data sources to analyze" arrow>
                  <Tab label={label} {...a11yProps(index)} />
                </LibraryDetailsHoverTooltip>
              )
            }

            return <Tab label={label} {...a11yProps(index)} key={index} disabled={loading} />
          })}
        </IntegrationsTabs>
      )}
    </HeaderContainer>
  )
}

export default TabsHeader
