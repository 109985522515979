import { Box, Button } from '@mui/material'
import styled from '@emotion/styled/macro'

export const LibraryContainer = styled(Box)`
  width: 100%;
`

export const LoadingWrapper = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
`

export const ToolbarWrapper = styled(Box)`
  display: flex;
  justify-content: space-between;
  width: 100%;

  .line {
    display: flex;
    align-items: center;
    gap: 64px;

    @media (max-width: 1400px) {
      gap: 16px;
    }
  }
`

export const BreadcrumbsWrapper = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  max-width: 70%;
  padding: 18px 0 0 24px;

  .header-actions {
    display: flex;
    align-items: center;

    .favorite-icon {
      margin-right: 34px;

      .MuiButtonBase-root {
        &:hover,
        &:focus,
        &:active {
          background: rgba(202, 196, 208, 0.08);
        }
      }
    }
  }
`

export const LibraryContentWrapper = styled(Box)`
  height: auto;
  padding-left: 100px;
  padding-top: 16px;
  z-index: 10;

  display: flex;
  width: 100%;
  flex-flow: column wrap;
  justify-content: center;
  align-items: center;

  @media (min-width: 1280px) {
    padding-left: 0;
    display: flex;
    flex-flow: column wrap;
    justify-content: center;
    align-items: center;
  }
`

export const LibraryTabPanel = styled(Box)`
  margin-top: 12px;
`

export const SelectorsWrapper = styled(Box)`
  display: flex;
  align-items: center;
  gap: 24px;
  z-index: 10;

  @media (max-width: 1360px) {
    gap: 12px;
  }
`

export const ResultsWrapper = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 24px;

  @media (max-width: 1510px) {
    flex-direction: column;
    gap: 0;
  }

  @media (min-width: 1280px) {
    padding: 0;
  }

  .results-text {
    color: #d0c3cc;
    font-family: Quicksand, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 142.857% */
    letter-spacing: 0.1px;
    z-index: 10;
    white-space: nowrap;
  }
`

export const ListWrapper = styled(Box)`
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 16px;
  width: 920px;

  @media (min-width: 1280px) {
    justify-content: flex-start;
  }

  .no-underline {
    text-decoration: none;
  }
`

export const EmptyList = styled(Box)`
  display: flex;
  justify-content: center;
  flex-flow: column wrap;
  width: 100%;
  z-index: 10;

  .heading {
    color: #fff;
    font-family: Quicksand, sans-serif;
    font-size: 45px;
    font-weight: 400;
    line-height: 52px;
    margin-bottom: 24px;
  }

  .MuiButton-root {
    width: fit-content !important;
    font-weight: 500 !important;
  }
`

export const EmptyLogo = styled(Box)`
  position: fixed;
  bottom: -5px;
  right: 0;
  z-index: 1;

  svg {
    z-index: 1;

    path {
      fill: #4d444b;
    }
  }
`

export const LoadingContainer = styled(Box)`
  display: flex;
  width: 920px;
  justify-content: center;
  align-items: center;
  gap: 24px;
  flex-flow: column wrap;
`

export const ClearFilters = styled(Button)`
  background: transparent;
  border-radius: 8px;
  gap: 8px;
  min-width: 78px;
  height: 32px;
  font-family: 'Quicksand', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 510;
  line-height: normal;
  display: flex;
  align-items: center;
  letter-spacing: 0.5px;
  text-transform: none;
  z-index: 10;
  color: rgba(255, 169, 252, 1) !important;

  &.Mui-disabled {
    opacity: 0.38;
    color: #debdd9;
  }
`
